import axios from "axios";

const apiUrl = `${process.env.REACT_APP_API_URL}/terminal`;
const CODE_API_URL = `${process.env.REACT_APP_API_URL}/comcode`;

export function getTerminalData(key) {
  return axios.get(apiUrl + `/${key}`);
}

export function createTerminal(data) {
  return axios.post(apiUrl, data);
}

export function updateTerminal(key, data) {
  return axios.put(apiUrl, { key, data });
}

export function deleteRow(key) {
  return axios.delete(apiUrl + `/${key}`);
}

export function getCode(mcode) {
  return axios.get(`${CODE_API_URL}/lookup/${mcode}`);
}
