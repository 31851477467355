import React, { useRef } from "react";
import {
  PanelBody,
  PanelHeader,
  PanelInit,
} from "../../../components/Layout/Panel/panel.style";
import { useDatagrid } from "../../../hooks/useDatagrid";

import HeaderTitle from "../../../components/Layout/Header/HeaderTitle";
import BerthStatusMaster from "./BerthStatusMaster";

function BerthStatus(props) {
  const dataGridRef = useRef();
  useDatagrid(dataGridRef);

  // 부산항선석 api 있음
  // 인천항선석 https://scon.icpa.or.kr/vescall/list.do
  // 광양항선석 http://www.gwct.co.kr/e-service2/?m=B&s=2
  // 포항항선석 http://www.pohangport.com/info/ShipBerthT.pict
  // 울산항선석 http://www.unct.co.kr/eservice/#/ES010_100
  // 평택항선석 http://www.pctc21.com/esvc/vessel/berthScheduleT

  return (
    <>
      <PanelInit>
        <PanelHeader>
          <HeaderTitle />
        </PanelHeader>
        <PanelBody row left="100%" right="0">
          <BerthStatusMaster dataGridRef={dataGridRef} />
        </PanelBody>
      </PanelInit>
    </>
  );
}

export default BerthStatus;
