import styled from "styled-components";

export const ContentWrapper = styled.div`
  display: grid;
  width: 100vw;
  height: 100vh;
  grid-template-rows: 40px auto;
  grid-template-areas:
    "AS MH"
    "AS CT";

  &.aside-open {
    grid-template-columns: 220px calc(100vw - 220px);
    transition: all 0.2s ease-in-out;
  }

  &.default {
    grid-template-columns: 40px calc(100vw - 40px);
    transition: all 0.2s ease-in-out;
  }
`;

export const ContentInit = styled.section`
  grid-area: CT;
  height: calc(100vh - 40px);
`;
