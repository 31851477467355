import React from "react";
import { Modal } from "react-bootstrap";
import { ModalWrapper } from "./modal.style";

const ModalContainer = ({
  onClose,
  maskClosable,
  className,
  visible,
  size,
  children,
}) => {
  const onMaskClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };
  return (
    <>
      <div className={"modal-overlay " + (visible ? "active" : "")} />
      <ModalWrapper
        className={`modal-wrap user-list-modal ${visible &&
          "active"} ${className && `${className}`}`}
        onClick={maskClosable ? onMaskClick : null}
        tabIndex="-1"
        visible={`${visible}`}
      >
        <Modal.Dialog centered tabIndex="0" size={size} animation="true">
          <Modal.Body>{children}</Modal.Body>
        </Modal.Dialog>
      </ModalWrapper>
    </>
  );
};

export default ModalContainer;
