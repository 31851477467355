import { DataGrid } from "devextreme-react";
import {
  Column,
  ColumnChooser,
  FilterRow,
  Format,
  HeaderFilter,
  Scrolling,
  Selection,
  StateStoring,
  Summary,
  TotalItem,
} from "devextreme-react/data-grid";
import CustomStore from "devextreme/data/custom_store";
import React, { useCallback, useMemo, useState } from "react";
import { sendRequest } from "../../../../../_metronic/_helpers/DataSoureHelpers";
import {
  DataCount,
  PanelGridInit,
} from "../../../../components/Layout/Panel/PanelGrid/panelGrid.style";
import StateStoringSaveAndDeleteButton from "../../../../components/StateStoring";

const FinishManageUpper = ({
  search,
  finishUpperRef,
  setSelectedRow,
  onRowDblClick,
}) => {
  const storageKey = "finishManageUpper";
  const [totalCount, setTotalCount] = useState(0);
  const dataSource = useMemo(() => {
    const apiUrl = `${process.env.REACT_APP_API_URL}/manage-all-finish`;

    return new CustomStore({
      key: "completeNo",
      load: () => sendRequest(apiUrl, "GET", search),
    });
  }, [search]);

  const onFocusedRowChanged = useCallback((e) => {
    if (!e.row) return;
    setSelectedRow(e.row.data);
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <PanelGridInit gridItems column>
        <StateStoringSaveAndDeleteButton
          dataGridRef={finishUpperRef}
          storageKey={storageKey}
        />
        <DataCount>{`총 ${totalCount}건`}</DataCount>
        <DataGrid
          className="datagrid-wrap"
          id="FinishManageUpper"
          dataSource={dataSource}
          ref={finishUpperRef}
          showRowLines={true}
          showBorders={true}
          hoverStateEnabled={true}
          allowColumnResizing={true}
          allowColumnReordering={true}
          columnResizingMode="widget"
          keyExpr="completeNo"
          focusedRowEnabled={true}
          onFocusedRowChanged={onFocusedRowChanged}
          onRowDblClick={onRowDblClick}
          onContentReady={(e) => setTotalCount(e.component.totalCount())}
        >
          <Selection
            mode="multiple"
            allowSelectAll={false}
            showCheckBoxesMode="always"
          />
          <FilterRow visible={true} />
          <HeaderFilter visible={true} />
          <Scrolling
            columnRenderingMode="virtual"
            mode="virtual"
            showScrollbar="always"
            useNative="false"
          />
          <StateStoring
            enabled={true}
            type="custom"
            customLoad={useCallback(() => {
              return JSON.parse(localStorage.getItem(storageKey));
            }, [])}
          />
          <ColumnChooser enabled={true} />
          <Column dataField="division" caption="구분" width="47" />
          <Column
            dataField="companyName"
            caption="청구처/용차사/차량번호"
            width="134"
          />
          {/* 접수법인 */}
          <Column dataField="receiveCoName" caption="접수법인" width="134" />
          {/* 마감번호 */}
          <Column dataField="completeNo" caption="마감번호" width="134" />
          {/* 마감일자 */}
          <Column dataField="completeDate" caption="마감일자" width="134" />
          {/* 상세건수 */}
          <Column dataField="detailTotal" caption="상세건수" width="100" />
          {/* 정산상태 */}
          <Column dataField="closeStatus" caption="발행상태" width="100" />
          {/* 정산예정일자 */}
          <Column dataField="taxSchedule" caption="정산일자" width="100" />
          {/* 세금계산서번호 */}
          <Column dataField="finishNo" caption="세금계산서번호" width="150" />
          {/* 계산서상태 */}
          <Column dataField="taxStatus" caption="계산서상태" width="100" />
          {/* 금액 */}
          <Column
            dataField="price"
            caption="금액"
            dataType="number"
            width="130"
          >
            <Format type="fixedPoint" />
          </Column>
          {/* 세액 */}
          <Column
            dataField="taxTotal"
            caption="세액"
            dataType="number"
            width="130"
          >
            <Format type="fixedPoint" />
          </Column>
          {/* 합계금액 */}
          <Column dataField="priceTotal" caption="합계금액" dataType="number">
            <Format type="fixedPoint" />
          </Column>
          <Summary>
            <TotalItem
              column="price"
              summaryType="sum"
              valueFormat="fixedPoint"
              displayFormat="총 금액 : {0}"
            />
            <TotalItem
              column="taxTotal"
              summaryType="sum"
              valueFormat="fixedPoint"
              displayFormat="총 세액 : {0}"
            />
            <TotalItem
              column="priceTotal"
              summaryType="sum"
              valueFormat="fixedPoint"
              displayFormat="총 합계 : {0}"
            />
          </Summary>
        </DataGrid>
      </PanelGridInit>
    </>
  );
};

export default FinishManageUpper;
