import React from "react";
import { useForm } from "react-hook-form";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";
import SVG from "react-inlinesvg";
import InputText from "../../../../components/InputForm/InputText";
import { useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { createForwarder } from "../ForwarderCrud";
import { ModalContainer } from "../../../../components/Modal";

const NewForwarderModal = ({ onClose, maskClosable, visible }) => {
  const [isLoading, setIsLoading] = useState(false);

  const initialState = {
    code: "",
    forwarderNameEn: "",
    forwarderNameKo: "",
    remark: "",
  };

  const schema = yup
    .object({
      code: yup
        .string()
        .required("선사코드를 입력하세요")
        .max(20, "최대 20자리까지 입력 가능"),
      forwarderNameEn: yup
        .string()
        .required("선사명을 입력하세요")
        .max(50, "최대 50자리까지 입력 가능"),
      forwarderNameKo: yup.string().max(50, "최대 50자리까지 입력 가능"),
      remark: yup.string().max(100, "최대 100자리까지 입력 가능"),
    })
    .required();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: initialState,
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data) => {
    setIsLoading(true);
    try {
      await createForwarder(data);
      onClose();
    } catch (err) {
      console.log(err);
      alert("오류가 생겼습니다", "오류");
    } finally {
      setIsLoading(false);
    }
  };

  const onError = (err) => {
    // console.log(err);
  };

  return (
    <ModalContainer
      onClose={onClose}
      maskClosable={maskClosable}
      visible={visible}
      size="lg"
    >
      <form onSubmit={handleSubmit(onSubmit, onError)} className="modal-form">
        <div className="modal-form-header">
          <div className="modal-form-tit">
            <span className="modal-tit">선사 관리</span>
          </div>
          <div className="d-flex flex-row">
            <button
              type="button"
              className="btn-modal btn-modal-close"
              onClick={onClose}
            >
              <SVG
                src={toAbsoluteUrl(
                  "/media/svg/icons/Custom/close_black_24dp.svg"
                )}
                className="close-icon"
              />
            </button>
          </div>
        </div>

        <div className="input-wrap">
          <div className="input-grid">
            <div className="dx-field">
              <InputText
                control={control}
                errors={errors}
                name="code"
                label="코드"
                required={true}
                flex={true}
                onInitialized={(e) => {
                  setTimeout(() => {
                    e.component.focus();
                  }, 0);
                }}
              />
            </div>
            <div className="dx-field">
              <InputText
                control={control}
                errors={errors}
                name="forwarderNameEn"
                label="선사명(영어)"
                required={true}
              />
            </div>
            <div className="dx-field">
              <InputText
                control={control}
                errors={errors}
                name="forwarderNameKo"
                label="선사명(한국어)"
              />
            </div>
            <div className="dx-field">
              <InputText
                control={control}
                errors={errors}
                name="remark"
                label="비고"
              />
            </div>
          </div>
        </div>

        <div className="modal-form-footer order-modal">
          <button
            type="submit"
            className={`btn btn-modal btn-save  ${isLoading &&
              "spinner spinner-white spinner-right"}`}
            disabled={isLoading}
          >
            저장
          </button>
        </div>
      </form>
    </ModalContainer>
  );
};

export default NewForwarderModal;
