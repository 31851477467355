import { createSlice } from "@reduxjs/toolkit";

const initialMenuState = {
  listLoading: false,
  actionsLoading: false,
  menuAll: [],
  menuItems: [],
  selectedItem: {},
  lastError: null,
  openItem: {},
};
export const callTypes = {
  list: "list",
  action: "action",
};

export const menuSlice = createSlice({
  name: "menu",
  initialState: initialMenuState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // findMenu
    menuFetched: (state, action) => {
      const { menuAll } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.menuAll = menuAll;
      state.menuItems = [];
      state.selectedItem = {};
    },
    selectedItem: (state, action) => {
      const { item } = action.payload;

      state.listLoading = false;
      state.error = null;
      if (item) {
        state.selectedItem = item;
        state.openItem = item;
      } else {
        state.selectedItem = {};
        state.openItem = {};
      }
    },
    openItem: (state, action) => {
      const { item } = action.payload;
      state.openItem = item;
      state.selectedItem = item;
      state.error = null;
    },
    logoutMenu: (state, action) => {
      state.selectedItem = {};
      state.openItem = {};
    },
  },
});
