import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";
import InputText from "../../../../components/InputForm/InputText";
import InputLookup from "../../../../components/InputForm/InputLookup";
import InputTel from "../../../../components/InputForm/InputTel";
import InputColorBox from "../../../../components/InputForm/InputColorBox";
import { createTerminal, getCode } from "../TerminalCrud";
import InputAddress from "../../../../components/InputForm/InputAddress";
import { alert } from "devextreme/ui/dialog";
import { ModalContainer } from "../../../../components/Modal";

const NewTerminalModal = ({ onClose, maskClosable, visible }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [lookupData, setLookupData] = useState({
    areaList: [],
    divisionList: [],
  });

  const initialState = {
    code: "",
    division: null,
    twName: "",
    area: "",
    tel: "",
    Fax: "",
    color: undefined,
    remark: "",
  };

  const schema = yup
    .object({
      code: yup
        .string()
        .nullable()
        .required("코드를 입력하세요.")
        .max(20, "최대 20자리 까지 입력 가능"),
      division: yup
        .string()
        .nullable()
        .required("구분을 선택하세요."),
      twName: yup
        .string()
        .nullable()
        .required("터미널이름을 입력하세요.")
        .max(50, "최대 50자리 까지 입력 가능"),
      area: yup
        .string()
        .nullable()
        .required("지역을 선택하세요."),
      color: yup
        .string()
        .nullable()
        .nullable()
        .max(7, "최대 7자리 까지 입력 가능"),
      remark: yup
        .string()
        .nullable()
        .nullable(true)
        .max(100, "최대 100자리 까지 입력 가능"),
    })
    .required();

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: initialState,
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    getLookupData();
  }, []);

  const onSubmit = async (data) => {
    setIsLoading(true);
    try {
      const {
        data: { status, message },
      } = await createTerminal(data);

      if (status === -1) {
        alert(message, "오류");
      } else {
        onClose();
      }
    } catch (err) {
      alert("오류가 생겼습니다", "오류");
    } finally {
      setIsLoading(false);
    }
  };

  const onError = (err) => {
    console.log(err);
  };

  const openPost = () => {
    window.daum.postcode.load(() => {
      const postcode = new window.daum.Postcode({
        oncomplete: function(data) {
          let addr = "";
          let extraAddr = "";

          if (data.userSelectedType === "R") {
            addr = data.address;
          } else {
            addr = data.jibunAddress;
          }

          if (data.userSelectedType === "R") {
            if (data.bname !== "" && /[동|로|가]$/g.test(data.bname)) {
              extraAddr += data.bname;
            }
            if (data.buildingName !== "" && data.apartment === "Y") {
              extraAddr +=
                extraAddr !== "" ? ", " + data.buildingName : data.buildingName;
            }
            if (extraAddr !== "") {
              extraAddr = " (" + extraAddr + ")";
            }
            addr += extraAddr;
          }

          setValue("zipCode", data.zonecode);
          setValue("address", addr);
        },
      });
      postcode.open();
    });
  };

  async function getLookupData() {
    const areaListData = await getCode("50100");
    const divisionData = await getCode("30100");

    setLookupData({
      areaList: areaListData.data,
      divisionList: divisionData.data,
    });
  }

  return (
    <ModalContainer
      onClose={onClose}
      maskClosable={maskClosable}
      visible={visible}
      size="lg"
    >
      <form onSubmit={handleSubmit(onSubmit, onError)} className="modal-form">
        <div className="modal-form-header">
          <div className="modal-form-tit">
            <span className="modal-tit">작업지 관리</span>
          </div>
          <div className="d-flex flex-row">
            <button
              type="button"
              className="btn-modal btn-modal-close"
              onClick={onClose}
            >
              <SVG
                src={toAbsoluteUrl(
                  "/media/svg/icons/Custom/close_black_24dp.svg"
                )}
                className="close-icon"
              />
            </button>
          </div>
        </div>

        <div className="input-wrap">
          <div className="input-grid">
            <div className="dx-field">
              <InputText
                control={control}
                errors={errors}
                name="code"
                label="터미널코드"
                required={true}
                onInitialized={(e) => {
                  setTimeout(() => {
                    e.component.focus();
                  }, 0);
                }}
              />
            </div>
            <div className="dx-field">
              <InputLookup
                control={control}
                errors={errors}
                name="division"
                label="구분"
                dataSource={lookupData.divisionList}
                displayExpr="subName"
                valueExpr="id"
                required={true}
              />
            </div>

            <div className="dx-field">
              <InputText
                control={control}
                errors={errors}
                name="twName"
                label="이름"
                required={true}
              />
            </div>
            <div className="dx-field">
              <InputLookup
                control={control}
                errors={errors}
                name="area"
                label="지역"
                dataSource={lookupData.areaList}
                displayExpr="subName"
                valueExpr="id"
                required={true}
              />
            </div>
            <div className="dx-field">
              <InputTel
                control={control}
                errors={errors}
                name="tel"
                label="대표번호"
              />
            </div>
            <div className="dx-field">
              <InputTel
                control={control}
                errors={errors}
                name="Fax"
                label="팩스번호"
              />
            </div>
            <div className="dx-field">
              <InputColorBox
                control={control}
                errors={errors}
                name="color"
                label="색상"
              />
            </div>
            <div className="dx-field">
              <InputText
                control={control}
                errors={errors}
                name="remark"
                label="비고"
              />
            </div>
          </div>

          <div className="input-grid grid-1">
            <div className="dx-field">
              <InputAddress
                control={control}
                errors={errors}
                flexDirection="row"
                openPost={openPost}
                disabled={false}
              />
            </div>
          </div>
        </div>

        <div className="modal-form-footer order-modal">
          <button
            type="submit"
            className={`btn btn-modal btn-save  ${isLoading &&
              "spinner spinner-white spinner-right"}`}
            disabled={isLoading}
          >
            저장
          </button>
        </div>
      </form>
    </ModalContainer>
  );
};

export default NewTerminalModal;
