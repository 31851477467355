import { yupResolver } from "@hookform/resolvers/yup";

import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import SVG from "react-inlinesvg";
import * as yup from "yup";

import * as requestServer from "./ReturnListManagaCrud";

import { alert } from "devextreme/ui/dialog";
import moment from "moment";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";
import InputDate from "../../../../components/InputForm/InputDate";
import InputLookup from "../../../../components/InputForm/InputLookup";
import InputSelect from "../../../../components/InputForm/InputSelect";
import InputText from "../../../../components/InputForm/InputText";
import { InputDateTimePart } from "../../../../components/InputForm";
import ModalContainer from "../../../../components/Modal/ModalContainer";
import { ButtonGroup } from "../../../../components/FunctionButton/CustomButton/customButton.style";
import CustomButton from "../../../../components/FunctionButton/CustomButton";

export function WarehouseArriveModal({
  onClose,
  maskClosable,
  closable,
  visible,
  rowData,
  dataGridRef,
}) {
  const schema = yup
    .object({
      warehouseArrive: yup.string().required("창고입고날짜를 입력하세요"),
    })
    .required();

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    reset({
      warehouseArrive: rowData.warehouseArrive || moment().format("YYYY-MM-DD"),
    });
    // eslint-disable-next-line
  }, []);

  const onSubmit = async (data) => {
    const key = rowData?.detailId;
    try {
      await requestServer.setWarehouseArrive({ key, data });
      dataGridRef.current.instance.refresh();
      onClose();
    } catch (err) {
      alert(err.response.data.message, "오류");
    }
  };

  const onMaskClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  return (
    <>
      <div className={"modal-overlay " + (visible ? "active" : "")} />
      <div
        className={"modal-wrap user-list-modal " + (visible ? "active" : "")}
        onClick={maskClosable ? onMaskClick : null}
        tabIndex="-1"
        visible={`${visible}`}
      >
        <Modal.Dialog centered tabIndex="0" size="sm" animation="true">
          <Modal.Body>
            <form className="modal-form" onSubmit={handleSubmit(onSubmit)}>
              <div className="modal-form-header">
                <div className="modal-form-tit">
                  <span className="modal-tit">창고입고날짜</span>
                </div>
                <button
                  type="button"
                  className="btn-modal btn-modal-close"
                  onClick={onClose}
                >
                  <SVG
                    src={toAbsoluteUrl(
                      "/media/svg/icons/Custom/close_black_24dp.svg"
                    )}
                    className="close-icon"
                  />
                </button>
              </div>
              <div className="modal-form-body">
                <div className="d-flex flex-row mb-1">
                  <div className="dx-field">
                    <InputDate
                      control={control}
                      errors={errors}
                      name="warehouseArrive"
                      label="창고입고 날짜"
                      flex={true}
                      onInitialized={(e) => {
                        e.component.open();
                        setTimeout(() => {
                          e.component.focus();
                        }, 0);
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="modal-form-footer">
                <button type="submit" className="btn btn-modal btn-save">
                  등록
                </button>
              </div>
            </form>
          </Modal.Body>
        </Modal.Dialog>
      </div>
    </>
  );
}

export function ContainerModal({
  onClose,
  maskClosable,
  closable,
  visible,
  rowData,
  dataGridRef,
}) {
  const [conType, setConType] = useState();

  const schema = yup
    .object({
      containerType: yup.string(),
      containerNo: yup.string().required("컨테이너 번호를 입력하세요"),
      sealNo: yup.string(),
    })
    .required();

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    reset({
      containerType: rowData?.containerType || undefined,
      containerNo: rowData?.containerNo || undefined,
      sealNo: rowData?.sealNo || undefined,
    });
    getLookup();
    // eslint-disable-next-line
  }, []);

  async function getLookup() {
    const conTypeData = await requestServer.getLookupDatas(
      "comcode/detail/10400"
    );

    setConType(conTypeData.data);
  }

  const onSubmit = async (data) => {
    const key = rowData?.detailId;
    try {
      await requestServer.changeContainerNo({ key, data });
      dataGridRef.current.instance.refresh();
      onClose();
    } catch (err) {
      console.log(err);
    }
  };

  const onMaskClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  return (
    <>
      <div className={"modal-overlay " + (visible ? "active" : "")} />
      <div
        className={"modal-wrap user-list-modal " + (visible ? "active" : "")}
        onClick={maskClosable ? onMaskClick : null}
        tabIndex="-1"
        visible={`${visible}`}
      >
        <Modal.Dialog centered tabIndex="0" size="sm" animation="true">
          <Modal.Body>
            <form className="modal-form" onSubmit={handleSubmit(onSubmit)}>
              <div className="modal-form-header">
                <div className="modal-form-tit">
                  <span className="modal-tit">컨테이너 정보변경</span>
                </div>
                <button
                  type="button"
                  className="btn-modal btn-modal-close"
                  onClick={onClose}
                >
                  <SVG
                    src={toAbsoluteUrl(
                      "/media/svg/icons/Custom/close_black_24dp.svg"
                    )}
                    className="close-icon"
                  />
                </button>
              </div>
              <div className="modal-form-body">
                <div className="d-flex flex-row mb-1">
                  <div className="dx-field">
                    <InputLookup
                      control={control}
                      errors={errors}
                      name="containerType"
                      label="컨테이너 규격"
                      flex={true}
                      dataSource={conType}
                      valueExpr="subCode"
                      displayExpr="subName"
                      onInitialized={(e) => {
                        e.component.open();
                        setTimeout(() => {
                          e.component.focus();
                        }, 0);
                      }}
                    />
                  </div>
                </div>

                <div className="d-flex flex-row mb-1">
                  <div className="dx-field">
                    <InputText
                      control={control}
                      errors={errors}
                      name="containerNo"
                      label="컨테이너 번호"
                      flex={true}
                    />
                  </div>
                </div>

                <div className="d-flex flex-row mb-1">
                  <div className="dx-field">
                    <InputText
                      control={control}
                      errors={errors}
                      name="sealNo"
                      label="실(seal) 넘버"
                      flex={true}
                    />
                  </div>
                </div>
              </div>
              <div className="modal-form-footer">
                <button type="submit" className="btn btn-modal btn-save">
                  등록
                </button>
              </div>
            </form>
          </Modal.Body>
        </Modal.Dialog>
      </div>
    </>
  );
}

export function ShipModal({
  onClose,
  maskClosable,
  closable,
  visible,
  rowData,
  dataGridRef,
}) {
  const [forwarder, setForwarder] = useState([]);

  const schema = yup
    .object({
      forwarder: yup.string().nullable(),
      shipName: yup.string().nullable(),
    })
    .required();

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    reset({
      forwarder: rowData.forwarder || "",
      shipName: rowData.shipName || "",
    });
    getLookup();
    // eslint-disable-next-line
  }, []);

  const onSubmit = async (data) => {
    const key = rowData.code;
    try {
      await requestServer.setShip({ key, data });
      dataGridRef.current.instance.refresh();
      onClose();
    } catch (err) {
      alert(err.response.data.message, "오류");
    }
  };

  async function getLookup() {
    const conTypeData = await requestServer.getLookupDatas("forwarder/lookup");

    setForwarder(conTypeData.data);
  }

  const onMaskClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  return (
    <>
      <div className={"modal-overlay " + (visible ? "active" : "")} />
      <div
        className={"modal-wrap user-list-modal " + (visible ? "active" : "")}
        onClick={maskClosable ? onMaskClick : null}
        tabIndex="-1"
        visible={`${visible}`}
      >
        <Modal.Dialog centered tabIndex="0" size="sm" animation="true">
          <Modal.Body>
            <form className="modal-form" onSubmit={handleSubmit(onSubmit)}>
              <div className="modal-form-header">
                <div className="modal-form-tit">
                  <span className="modal-tit">선사/선명 변경</span>
                </div>
                <button
                  type="button"
                  className="btn-modal btn-modal-close"
                  onClick={onClose}
                >
                  <SVG
                    src={toAbsoluteUrl(
                      "/media/svg/icons/Custom/close_black_24dp.svg"
                    )}
                    className="close-icon"
                  />
                </button>
              </div>
              <div className="modal-form-body">
                <div className="d-flex flex-row mb-1">
                  <div className="dx-field">
                    <InputSelect
                      searchEnabled={true}
                      control={control}
                      errors={errors}
                      name="forwarder"
                      label="선사"
                      flex={true}
                      dataSource={forwarder}
                      valueExpr="code"
                      displayExpr="forwarder_Name"
                      onInitialized={(e) => {
                        e.component.open();
                        setTimeout(() => {
                          e.component.focus();
                        }, 0);
                      }}
                    />
                  </div>
                </div>

                <div className="d-flex flex-row mb-1">
                  <div className="dx-field">
                    <InputText
                      control={control}
                      errors={errors}
                      name="shipName"
                      label="선명"
                      flex={true}
                    />
                  </div>
                </div>
              </div>

              <div className="modal-form-footer">
                <button type="submit" className="btn btn-modal btn-save">
                  등록
                </button>
              </div>
            </form>
          </Modal.Body>
        </Modal.Dialog>
      </div>
    </>
  );
}

export function SailModal({
  onClose,
  maskClosable,
  closable,
  visible,
  rowData,
  dataGridRef,
}) {
  const initialState = {
    enterDay: null,
    sailDay: null,
  };

  const schema = yup
    .object({
      enterDay: yup.date().nullable(),
      sailDay: yup.string().nullable(),
    })
    .required();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: initialState,
  });

  const onSubmit = async (data) => {
    const dataArr = dataGridRef.current.instance
      .getSelectedRowsData()
      ?.map((cur) => cur.code);

    try {
      await requestServer.setSailDate({ key: dataArr, data });
      dataGridRef.current.instance.refresh();
      onClose();
    } catch (err) {
      alert(err.response.data.message, "오류");
    }
  };

  const onMaskClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  return (
    <>
      <div className={"modal-overlay " + (visible ? "active" : "")} />
      <div
        className={"modal-wrap user-list-modal " + (visible ? "active" : "")}
        onClick={maskClosable ? onMaskClick : null}
        tabIndex="-1"
        visible={`${visible}`}
      >
        <Modal.Dialog centered tabIndex="0" size="sm" animation="true">
          <Modal.Body>
            <form className="modal-form" onSubmit={handleSubmit(onSubmit)}>
              <div className="modal-form-header">
                <div className="modal-form-tit">
                  <span className="modal-tit">입/출항 변경</span>
                </div>
                <button
                  type="button"
                  className="btn-modal btn-modal-close"
                  onClick={onClose}
                >
                  <SVG
                    src={toAbsoluteUrl(
                      "/media/svg/icons/Custom/close_black_24dp.svg"
                    )}
                    className="close-icon"
                  />
                </button>
              </div>
              <div className="modal-form-body">
                <div className="d-flex flex-row mb-1">
                  <div className="dx-field">
                    <InputDateTimePart
                      name="enterDay"
                      control={control}
                      errors={errors}
                      label="입항"
                      flex={true}
                    />
                  </div>
                </div>

                <div className="d-flex flex-row mb-1">
                  <div className="dx-field">
                    <InputDateTimePart
                      control={control}
                      errors={errors}
                      name="sailDay"
                      label="출항"
                      flex={true}
                    />
                  </div>
                </div>
              </div>
              <div className="modal-form-footer">
                <button type="submit" className="btn btn-modal btn-save">
                  등록
                </button>
              </div>
            </form>
          </Modal.Body>
        </Modal.Dialog>
      </div>
    </>
  );
}

export function FridgeDangerModal({
  onClose,
  maskClosable,
  closable,
  visible,
  rowData,
  dataGridRef,
}) {
  const schema = yup
    .object({
      temperature: yup.string().nullable(),
      unNo: yup.string().nullable(),
      class: yup.string().nullable(),
      cargoWeight: yup.string().nullable(),
    })
    .required();

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    reset({
      temperature: rowData?.temperature || undefined,
      unNo: rowData?.unNo || undefined,
      class: rowData?.class || undefined,
      cargoWeight: rowData?.cargoWeight || undefined,
    });
    // eslint-disable-next-line
  }, []);

  const onSubmit = async (data) => {
    const key = rowData?.detailId;
    try {
      await requestServer.setFridgeDanger({ key, data });
      dataGridRef.current.instance.refresh();
      onClose();
    } catch (err) {
      alert(err.response.data.message, "오류");
    }
  };

  const onMaskClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  return (
    <>
      <div className={"modal-overlay " + (visible ? "active" : "")} />
      <div
        className={"modal-wrap user-list-modal " + (visible ? "active" : "")}
        onClick={maskClosable ? onMaskClick : null}
        tabIndex="-1"
        visible={`${visible}`}
      >
        <Modal.Dialog centered tabIndex="0" size="sm" animation="true">
          <Modal.Body>
            <form className="modal-form" onSubmit={handleSubmit(onSubmit)}>
              <div className="modal-form-header">
                <div className="modal-form-tit">
                  <span className="modal-tit">냉동/위험물 정보변경</span>
                </div>
                <button
                  type="button"
                  className="btn-modal btn-modal-close"
                  onClick={onClose}
                >
                  <SVG
                    src={toAbsoluteUrl(
                      "/media/svg/icons/Custom/close_black_24dp.svg"
                    )}
                    className="close-icon"
                  />
                </button>
              </div>
              <div className="modal-form-body">
                <div className="d-flex flex-row mb-1">
                  <div className="dx-field">
                    <InputText
                      control={control}
                      errors={errors}
                      name="temperature"
                      label="온도"
                      flex={true}
                    />
                  </div>
                </div>
                <div className="d-flex flex-row mb-1">
                  <div className="dx-field">
                    <InputText
                      control={control}
                      errors={errors}
                      name="unNo"
                      label="un번호"
                      flex={true}
                    />
                  </div>
                </div>
                <div className="d-flex flex-row mb-1">
                  <div className="dx-field">
                    <InputText
                      control={control}
                      errors={errors}
                      name="class"
                      label="클래스"
                      flex={true}
                    />
                  </div>
                </div>
                <div className="d-flex flex-row mb-1">
                  <div className="dx-field">
                    <InputText
                      control={control}
                      errors={errors}
                      name="cargoWeight"
                      label="총중량"
                      flex={true}
                    />
                  </div>
                </div>
              </div>
              <div className="modal-form-footer">
                <button type="submit" className="btn btn-modal btn-save">
                  등록
                </button>
              </div>
            </form>
          </Modal.Body>
        </Modal.Dialog>
      </div>
    </>
  );
}

export function SetReturnDate({
  onClose,
  maskClosable,
  closable,
  visible,
  rowData,
  dataGridRef,
}) {
  const schema = yup.object({
    returnDate: yup.string().nullable(),
    returnFinishDate: yup.string().nullable(),
  });

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
    // eslint-disable-next-line react-hooks/rules-of-hooks
  } = useForm({
    resolver: yupResolver(schema),
  });

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    reset({
      returnDate: rowData?.returnDate || undefined,
      returnFinishDate: rowData?.returnFinishDate || undefined,
    });
    // eslint-disable-next-line
  }, []);

  const onSubmit = async (data) => {
    const key = rowData?.detailId;
    try {
      await requestServer.setReturnDate({ key, data });
      dataGridRef.current.instance.refresh();
      onClose();
    } catch (err) {
      alert(err.response.data.message, "오류");
    }
  };

  const onMaskClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  return (
    <>
      <div className={"modal-overlay " + (visible ? "active" : "")} />
      <div
        className={"modal-wrap user-list-modal " + (visible ? "active" : "")}
        onClick={maskClosable ? onMaskClick : null}
        tabIndex="-1"
        visible={`${visible}`}
      >
        <Modal.Dialog centered tabIndex="0" size="sm" animation="true">
          <Modal.Body>
            <form className="modal-form" onSubmit={handleSubmit(onSubmit)}>
              <div className="modal-form-header">
                <div className="modal-form-tit">
                  <span className="modal-tit">반납일자/반납마감일자 변경</span>
                </div>
                <button
                  type="button"
                  className="btn-modal btn-modal-close"
                  onClick={onClose}
                >
                  <SVG
                    src={toAbsoluteUrl(
                      "/media/svg/icons/Custom/close_black_24dp.svg"
                    )}
                    className="close-icon"
                  />
                </button>
              </div>
              <div className="modal-form-body">
                <div className="d-flex flex-row mb-1">
                  <div className="dx-field">
                    <InputDate
                      control={control}
                      errors={errors}
                      name="returnDate"
                      label="반납일자"
                      flex={true}
                    />
                  </div>
                </div>
                <div className="d-flex flex-row mb-1">
                  <div className="dx-field">
                    <InputDate
                      control={control}
                      errors={errors}
                      name="returnFinishDate"
                      label="반납마감일자"
                      flex={true}
                    />
                  </div>
                </div>
              </div>
              <div className="modal-form-footer">
                <button type="submit" className="btn btn-modal btn-save">
                  등록
                </button>
              </div>
            </form>
          </Modal.Body>
        </Modal.Dialog>
      </div>
    </>
  );
}

export function SetChassisInfo({
  onClose,
  maskClosable,
  closable,
  visible,
  rowData,
  dataGridRef,
}) {
  const [lookupData, setLookupData] = useState({
    chassis: [],
  });
  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    resetData(rowData);
    initLookupData();
    // eslint-disable-next-line
  }, [rowData]);

  const onSubmit = async (data) => {
    const instance = dataGridRef.current.instance;

    const sendData = {
      detailId: rowData.detailId,
      list: [rowData],
      chassis: data.chassis || null,
    };

    try {
      const {
        data: { result },
      } = await requestServer.setChassisInfo(sendData);

      instance.refresh();

      if (result?.message) {
        alert(result?.message, "오류");
      } else {
        onClose();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const resetData = (data) => {
    console.log(rowData);
    reset({ chassis: data.chassis });
  };

  const initLookupData = async () => {
    try {
      const chassisData = await requestServer.getLookupDatas("chassis/lookup");

      setLookupData({
        chassis: chassisData.data,
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <ModalContainer
        onClose={onClose}
        maskClosable={maskClosable}
        visible={visible}
        size="sm"
      >
        <form
          onSubmit={handleSubmit(onSubmit, (err) => {
            console.log(err);
          })}
          className="modal-form"
        >
          <div className="modal-form-header">
            <div className="modal-form-tit">
              <span className="modal-tit">샤시입력</span>
            </div>
            <button
              type="button"
              className="btn-modal btn-modal-close"
              onClick={onClose}
            >
              <SVG
                src={toAbsoluteUrl(
                  "/media/svg/icons/Custom/close_black_24dp.svg"
                )}
                className="close-icon"
              />
            </button>
          </div>

          <div className="modal-form-body">
            <div className="d-flex flex-row mb-1">
              <div className="dx-field">
                <InputSelect
                  control={control}
                  errors={errors}
                  dataSource={lookupData.chassis}
                  valueExpr="chassisNo"
                  displayExpr="chassisNo"
                  name="chassis"
                  label="샤시"
                  flex={true}
                />
              </div>
            </div>
          </div>
          <ButtonGroup justifyContent="flex-end">
            <CustomButton
              type="submit"
              height={36}
              layout="solid"
              color="common"
              label="저장"
            />
          </ButtonGroup>
        </form>
      </ModalContainer>
    </>
  );
}
