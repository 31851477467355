import axios from "axios";
const apiUrlLookup = `${process.env.REACT_APP_API_URL}`;
const apiUrl = `${process.env.REACT_APP_API_URL}/simple-sell-finish`;

export function getOrderDataByCode(code) {
  return axios.get(apiUrl + `/modal`, { params: { code } });
}

export function createSimpleFinish(data) {
  return axios.post(apiUrl, data);
}

export function updateSimpleFinish(data) {
  return axios.put(apiUrl, data);
}

export function deleteSimpleFinish(code) {
  return axios.delete(apiUrl, { params: { key: code } });
}

export function getLookup(param) {
  return axios.get(apiUrlLookup + `/${param}`);
}

export async function initLookupFetch() {
  const [
    companyData,
    billingData,
    ordercoData,
    forwarderData,
    terminalData,
    cargoownerData,
    conTypeData,
    carData,
    yongchasaData,
    workPlaceData,
    colorData,
    dispatchNameData,
    amountUnitData,
  ] = await Promise.all([
    getLookup("company"),
    getLookup("companyManage/lookup/isBilling"),
    getLookup("companyManage/lookup/isOrderCo"),
    getLookup("forwarder/lookup"),
    getLookup("terminal/terminal"),
    getLookup("companyManage/lookup/isCargoOwner"),
    getLookup("comcode/detail/10400"),
    getLookup("carManage/lookup"),
    getLookup("companyManage/lookup/isYongchasa"),
    getLookup("terminal/workplace"),
    getLookup("comcode/detail/10900"),
    getLookup("department/isDispatch"),
    getLookup("comcode/detail/10450"),
  ]);

  return {
    company: companyData.data,
    billing: billingData.data,
    orderCo: ordercoData.data,
    forwarder: forwarderData.data,
    terminal: terminalData.data,
    cargoOwner: cargoownerData.data,
    conType: conTypeData.data,
    car: carData.data,
    yongchasa: yongchasaData.data,
    workPlace: workPlaceData.data,
    color: [{ subCode: null, subName: "" }, ...colorData.data],

    dispatchName: dispatchNameData.data,
    amountUnit: amountUnitData.data,
  };
}
