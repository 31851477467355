/**
 *  "~을/를 입력해주세요"를 리턴
 * @param {string} field
 * @returns
 */
export const stringRequired = (field) => {
  return `${field}을/를 입력해주세요`;
};

/**
 *  "~을/를 선택해주세요"를 리턴
 * @param {string} field
 * @returns
 */
export const selectRequired = (field) => {
  return `${field}을/를 선택해주세요`;
};
