import * as yup from "yup";
import { containerValidationCallback } from "../../../utils/orderFunctions";

export const dispatchSchema = yup.object({
  containerNo: yup
    .string()
    .nullable()
    .test("containerNo", "컨테이너 번호가 올바르지 않습니다", (value) =>
      containerValidationCallback({ value })
    ),
  containerType: yup.string().nullable(),
  sealNo: yup.string().nullable(),

  loadPlaceFiles: yup.array().nullable(),
  loadPlace: yup
    .string()
    .nullable()
    .required("선택해주세요"),
  dispatchStatus_01: yup
    .number()
    .nullable()
    .when("loadPlace", validateDispatchStatus),
  deliverDate_01: yup
    .string()
    .nullable()
    .when("dispatchStatus_01", validateDeliverDate),
  Habul_01: yup.string().nullable(),
  toggle_01: yup
    .string()
    .nullable()
    .when("dispatchStatus_01", validateToggle),
  carNo_01: yup
    .string()
    .nullable()
    .when(["loadPlace", "toggle_01", "dispatchStatus_01"], validateCarNo),
  yongchasa_01: yup
    .string()
    .nullable()
    .when(["loadPlace", "toggle_01", "dispatchStatus_01"], validateYongcha),
  yongchaCarNo_01: yup
    .string()
    .nullable()
    .when(["loadPlace", "toggle_01", "dispatchStatus_01"], validateYongcha),
  dispatchComment_01: yup.string().nullable(),

  workWaypoint_01Files: yup.array().nullable(),
  workWaypoint_01: yup.string().nullable(),
  dispatchStatus_02: yup
    .number()
    .nullable()
    .when("workWaypoint_01", validateDispatchStatus),
  deliverDate_02: yup
    .string()
    .nullable()
    .when("dispatchStatus_02", validateDeliverDate),
  Habul_02: yup.string().nullable(),
  toggle_02: yup
    .string()
    .nullable()
    .when("dispatchStatus_02", validateToggle),
  carNo_02: yup
    .string()
    .nullable()
    .when(["workWaypoint_01", "toggle_02", "dispatchStatus_02"], validateCarNo),
  yongchasa_02: yup
    .string()
    .nullable()
    .when(
      ["workWaypoint_01", "toggle_02", "dispatchStatus_02"],
      validateYongcha
    ),
  yongchaCarNo_02: yup
    .string()
    .nullable()
    .when(
      ["workWaypoint_01", "toggle_02", "dispatchStatus_02"],
      validateYongcha
    ),
  dispatchComment_02: yup.string().nullable(),

  workWaypoint_02Files: yup.array().nullable(),
  workWaypoint_02: yup.string().nullable(),
  dispatchStatus_03: yup
    .number()
    .nullable()
    .when("workWaypoint_02", validateDispatchStatus),
  deliverDate_03: yup
    .string()
    .nullable()
    .when("dispatchStatus_03", validateDeliverDate),
  Habul_03: yup.string().nullable(),
  toggle_03: yup
    .string()
    .nullable()
    .when("dispatchStatus_03", validateToggle),
  carNo_03: yup
    .string()
    .nullable()
    .when(["workWaypoint_02", "toggle_03", "dispatchStatus_03"], validateCarNo),
  yongchasa_03: yup
    .string()
    .nullable()
    .when(
      ["workWaypoint_02", "toggle_03", "dispatchStatus_03"],
      validateYongcha
    ),
  yongchaCarNo_03: yup
    .string()
    .nullable()
    .when(
      ["workWaypoint_02", "toggle_03", "dispatchStatus_03"],
      validateYongcha
    ),
  dispatchComment_03: yup.string().nullable(),

  workWaypoint_03Files: yup.array().nullable(),
  workWaypoint_03: yup.string().nullable(),
  dispatchStatus_04: yup
    .number()
    .nullable()
    .when("workWaypoint_03", validateDispatchStatus),
  deliverDate_04: yup
    .string()
    .nullable()
    .when("dispatchStatus_04", validateDeliverDate),
  Habul_04: yup.string().nullable(),
  toggle_04: yup
    .string()
    .nullable()
    .when("dispatchStatus_04", validateToggle),
  carNo_04: yup
    .string()
    .nullable()
    .when(["workWaypoint_03", "toggle_04", "dispatchStatus_04"], validateCarNo),
  yongchasa_04: yup
    .string()
    .nullable()
    .when(
      ["workWaypoint_03", "toggle_04", "dispatchStatus_04"],
      validateYongcha
    ),
  yongchaCarNo_04: yup
    .string()
    .nullable()
    .when(
      ["workWaypoint_03", "toggle_04", "dispatchStatus_04"],
      validateYongcha
    ),
  dispatchComment_04: yup.string().nullable(),

  workPlaceFiles: yup.array().nullable(),
  workPlace: yup.string().nullable(),
  dispatchStatus_05: yup
    .number()
    .nullable()
    .when("workPlace", validateDispatchStatus),
  deliverDate_05: yup
    .string()
    .nullable()
    .when("dispatchStatus_05", validateDeliverDate),
  Habul_05: yup.string().nullable(),
  toggle_05: yup
    .string()
    .nullable()
    .when("dispatchStatus_05", validateToggle),
  carNo_05: yup
    .string()
    .nullable()
    .when(["workPlace", "toggle_05", "dispatchStatus_05"], validateCarNo),
  yongchasa_05: yup
    .string()
    .nullable()
    .when(["workPlace", "toggle_05", "dispatchStatus_05"], validateYongcha),
  yongchaCarNo_05: yup
    .string()
    .nullable()
    .when(["workPlace", "toggle_05", "dispatchStatus_05"], validateYongcha),
  dispatchComment_05: yup.string().nullable(),

  collectWaypoint_01Files: yup.array().nullable(),
  collectWaypoint_01: yup.string().nullable(),
  dispatchStatus_06: yup
    .number()
    .nullable()
    .when("collectWaypoint_01", validateDispatchStatus),
  deliverDate_06: yup
    .string()
    .nullable()
    .when("dispatchStatus_06", validateDeliverDate),
  Habul_06: yup.string().nullable(),
  toggle_06: yup
    .string()
    .nullable()
    .when("dispatchStatus_06", validateToggle),
  carNo_06: yup
    .string()
    .nullable()
    .when(
      ["collectWaypoint_01", "toggle_06", "dispatchStatus_06"],
      validateCarNo
    ),
  yongchasa_06: yup
    .string()
    .nullable()
    .when(
      ["collectWaypoint_01", "toggle_06", "dispatchStatus_06"],
      validateYongcha
    ),
  yongchaCarNo_06: yup
    .string()
    .nullable()
    .when(
      ["collectWaypoint_01", "toggle_06", "dispatchStatus_06"],
      validateYongcha
    ),
  dispatchComment_06: yup.string().nullable(),

  collectWaypoint_02Files: yup.array().nullable(),
  collectWaypoint_02: yup.string().nullable(),
  dispatchStatus_07: yup
    .number()
    .nullable()
    .when("collectWaypoint_02", validateDispatchStatus),
  deliverDate_07: yup
    .string()
    .nullable()
    .when("dispatchStatus_07", validateDeliverDate),
  Habul_07: yup.string().nullable(),
  toggle_07: yup
    .string()
    .nullable()
    .when("dispatchStatus_07", validateToggle),
  carNo_07: yup
    .string()
    .nullable()
    .when(
      ["collectWaypoint_02", "toggle_07", "dispatchStatus_07"],
      validateCarNo
    ),
  yongchasa_07: yup
    .string()
    .nullable()
    .when(
      ["collectWaypoint_02", "toggle_07", "dispatchStatus_07"],
      validateYongcha
    ),
  yongchaCarNo_07: yup
    .string()
    .nullable()
    .when(
      ["collectWaypoint_02", "toggle_07", "dispatchStatus_07"],
      validateYongcha
    ),
  dispatchComment_07: yup.string().nullable(),

  collectWaypoint_03Files: yup.array().nullable(),
  collectWaypoint_03: yup.string().nullable(),
  dispatchStatus_08: yup
    .number()
    .nullable()
    .when("collectWaypoint_03", validateDispatchStatus),
  deliverDate_08: yup
    .string()
    .nullable()
    .when("dispatchStatus_08", validateDeliverDate),
  Habul_08: yup.string().nullable(),
  toggle_08: yup
    .string()
    .nullable()
    .when("dispatchStatus_08", validateToggle),
  carNo_08: yup
    .string()
    .nullable()
    .when(
      ["collectWaypoint_03", "toggle_08", "dispatchStatus_08"],
      validateCarNo
    ),
  yongchasa_08: yup
    .string()
    .nullable()
    .when(
      ["collectWaypoint_03", "toggle_08", "dispatchStatus_08"],
      validateYongcha
    ),
  yongchaCarNo_08: yup
    .string()
    .nullable()
    .when(
      ["collectWaypoint_03", "toggle_08", "dispatchStatus_08"],
      validateYongcha
    ),
  dispatchComment_08: yup.string().nullable(),

  unloadPlace: yup.string().nullable(),
});

export const fileListSchema = yup.object({
  dispatchComment: yup
    .string()
    .nullable()
    .required("메세지를 입력해주세요"),
});

function validateDispatchStatus(start) {
  if (!start) return yup.string().nullable();

  return yup
    .string()
    .nullable()
    .required("선택해주세요");
}

function validateDeliverDate(status) {
  if (status === null || status?.toString() === "62")
    return yup.string().nullable();

  return yup
    .string()
    .nullable()
    .required("입력해주세요");
}

function validateCarNo(start, toggle, status) {
  if (status === null || status?.toString() === "62")
    return yup.string().nullable();
  if (!start) return yup.string().nullable();
  if (toggle !== "1") return yup.string().nullable();

  return yup
    .string()
    .nullable()
    .required("선택해주세요");
}

function validateYongcha(start, toggle, status) {
  if (status === null || status?.toString() === "62")
    return yup.string().nullable();
  if (!start) return yup.string().nullable();
  if (toggle !== "2") return yup.string().nullable();

  return yup
    .string()
    .nullable()
    .required("입력해주세요");
}

function validateToggle(status) {
  if (status === null || status?.toString() === "62")
    return yup.string().nullable();

  return yup
    .string()
    .nullable()
    .required("선택해주세요");
}
