import { DateBox } from "devextreme-react";
import React from "react";
import { Controller } from "react-hook-form";
import InputLayout from "./InputLayout";

function InputDateTimePart({
  control,
  errors,
  name,
  label,
  labelWidth,
  flex,
  disable = false,
  required,
  onValueChanged = () => {},
}) {
  return (
    <InputLayout
      label={label}
      required={required}
      errors={errors}
      name={name}
      labelWidth={labelWidth}
    >
      <Controller
        render={({ field: { onChange, value } }) => (
          <DateBox
            type="datetime"
            displayFormat="yyyy-MM-dd HH:mm"
            onValueChange={(value) => {
              if (value) onChange(value);
            }}
            value={value}
            disabled={disable}
            onValueChanged={onValueChanged}
          />
        )}
        control={control}
        name={name}
        defaultValue=""
      />
    </InputLayout>
  );
}

export default InputDateTimePart;
