import { Button } from "devextreme-react";
import React, { useCallback, useState } from "react";
import { ButtonGroup } from "./FunctionButton/CustomButton/customButton.style";

function StateStoringSaveAndDeleteButton({
  dataGridRef,
  storageKey,
  gridRightItems,
  gridItems,
}) {
  const [isLoading, setIsLoading] = useState(false);

  const saveState = useCallback(() => {
    setIsLoading(true);
    const state = { ...dataGridRef.current.instance.state() };
    delete state.focusedRowKey;
    if (state) {
      for (let i = 0; i < state.columns.length; i++) {
        state.columns[i].filterValue = null;
      }
    }
    localStorage.setItem(storageKey, JSON.stringify(state));

    setTimeout(() => setIsLoading(false), 100);
  }, [dataGridRef, storageKey]);

  const deleteState = useCallback(() => {
    setIsLoading(true);
    localStorage.removeItem(storageKey);
    setTimeout(() => setIsLoading(false), 100);
  }, [storageKey]);

  const exportExcel = useCallback(() => {
    dataGridRef.current.instance.exportToExcel();
    // eslint-disable-next-line
  }, []);

  return (
    <ButtonGroup
      marginLeft={`${gridRightItems ? 41 : 31}`}
      zIndex={10}
      gridBtn={true}
      top={`${gridItems ? 0 : 10}`}
    >
      <Button
        icon="save"
        onClick={saveState}
        disabled={isLoading}
        hint="그리드 저장"
      />
      <Button
        icon="undo"
        onClick={deleteState}
        disabled={isLoading}
        hint="그리드 초기화"
      />

      <Button
        icon="exportxlsx"
        onClick={exportExcel}
        disabled={isLoading}
        hint="엑셀다운로드"
      />
    </ButtonGroup>
  );
}

export default StateStoringSaveAndDeleteButton;
