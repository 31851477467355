import { alert, confirm } from "devextreme/ui/dialog";
import moment from "moment";
import { useCallback, useEffect, useReducer } from "react";
import { useForm } from "react-hook-form";
import { shallowEqual, useSelector } from "react-redux";
import * as api from "./SuttleExcelCrud";
import { useState } from "react";

const initialState = {
  user: null,
  menuAll: null,
  openItem: null,
  lookupCompany: [],
  lookupDisaptchName: [],
  dataSource: [],
  totalCount: 0,
  search: {
    receiveCo: "",
    importExport: "",
    firstDay: moment().format("YYYY-MM-DD"),
    lastDay: moment().format("YYYY-MM-DD"),
    containerNo: "",
    blNo: "",
    doNo: "",
    bkNo: "",
    company: "",
    dispatchTeam: "",
    searchType: 2,
  },
};

const shuttleExcelReducer = (state, action) => {
  switch (action.type) {
    case "INITIAL_STATE":
      return {
        ...state,
        ...action.payload,
      };
    case "SET_GRID_COUNT":
      return {
        ...state,
        totalCount: action.payload,
      };
    case "SET_SEARCH":
      return {
        ...state,
        search: action.payload,
      };
    case "SEARCH":
      return {
        ...state,
        dataSource: action.payload,
      };
    default:
      throw new Error("Unhandled action");
  }
};

export const useShuttleExcel = ({ dataGridRef }) => {
  const [state, dispatch] = useReducer(shuttleExcelReducer, initialState);
  const [isLoading, setIsLoading] = useState(false);
  const { user } = useSelector(
    (state) => ({
      user: state.auth.user.user,
    }),
    shallowEqual
  );

  const form = useForm({ defaultValues: initialState.search });
  const { reset } = form;

  const { search } = state;

  useEffect(() => {
    setInitialState();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    _setDataGrid();
    // eslint-disable-next-line
  }, [search]);

  const _setDataGrid = async () => {
    try {
      setIsLoading(true);
      const { data } = await api.searchShuttle({ search });

      dispatch({
        type: "SEARCH",
        payload: data,
      });
    } catch (error) {
      console.log(error);
      alert(error);
    } finally {
      setIsLoading(false);
    }
  };

  const setInitialState = async () => {
    const search = {
      ...initialState.search,
      receiveCo: user.companyCode,
      firstDay: moment().format("YYYY-MM-DD"),
      lastDay: moment().format("YYYY-MM-DD"),
      company: user.companyCode,
      dispatchTeam: user?.deptCode || "",
    };

    const data = await api.initialData({ search });
    dispatch({
      type: "INITIAL_STATE",
      payload: { ...data, search, user },
    });
    reset(search);
  };

  const setGridTotalCount = (count) =>
    dispatch({
      type: "SET_GRID_COUNT",
      payload: count,
    });

  const onSearch = async (params) => {
    dispatch({
      type: "SET_SEARCH",
      payload: params,
    });
    // const { data } = await api.searchShuttle({ search: params });
  };

  const onRowDelete = async () => {
    const CHUNK_SIZE = 200;
    const resultData = [];
    const gridInstance = dataGridRef.current.instance;
    const selected = gridInstance.getSelectedRowsData();

    if (selected.length === 0) {
      alert("데이터를 선택해주세요", "오류");
      return;
    }

    const keys = selected.map((curr) => {
      return curr.code;
    });

    for (let i = 0; i < keys.length; i += CHUNK_SIZE) {
      let tempArray;
      tempArray = keys.slice(i, i + CHUNK_SIZE);
      resultData.push(tempArray);
    }

    let response = await confirm("<i>삭제하시겠습니까?</i>", "삭제 메세지");
    if (!response) return;

    try {
      let result = { code: 1, message: "삭제성공" };
      for (const splitedArray of resultData) {
        const {
          data: { resultData },
        } = await api.deleteShuttle(splitedArray);
        result = resultData;
      }
      // eslint-disable-next-line
      const { code, message } = result;

      await alert(message, "확인");

      _setDataGrid();
    } catch (error) {
      alert("ERROR-01", "오류");
      console.log(error);
    }
  };

  const importExportCellRender = useCallback((e) => {
    if (e.value === 1) return "수입";
    if (e.value === 2) return "수출";
    return;
  }, []);

  return {
    isLoading,
    state,
    form,
    dataGridRef,
    setGridTotalCount,
    onSearch,
    onRowDelete,
    importExportCellRender,
  };
};
