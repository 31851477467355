import axios from "axios";

const apiUrl = `${process.env.REACT_APP_API_URL}/app-push`;
const apiUrlLookup = `${process.env.REACT_APP_API_URL}`;

export function getLookup(param) {
  return axios.get(apiUrlLookup + `/${param}`);
}

export function postAppPush(data) {
  return axios.post(apiUrl, data);
}

export function postNofication(data) {
  return axios.post(apiUrl + `/nofication`, data);
}

export function updateAppPush(key, data) {
  return axios.put(apiUrl, { key, data });
}

export function deleteAppPush(key) {
  return axios.delete(apiUrl + `/${key}`);
}
