import axios from "axios";

const apiUrl = `${process.env.REACT_APP_API_URL}/forwarder`;

export function getForwarder(key) {
  return axios.get(apiUrl + `/${key}`);
}

export function createForwarder(data) {
  return axios.post(apiUrl, data);
}

export function updateForwarder(key, data) {
  return axios.put(apiUrl, { key, data });
}

export function deleteRow(key) {
  return axios.delete(apiUrl + `/${key}`);
}
