import { DataGrid } from "devextreme-react";
import { Column, Format } from "devextreme-react/data-grid";
import React, { useCallback } from "react";

function OrderRefDetail({
  data,
  dataGridRef,
  detailGridRef,
  detail,
  setDetailRowData,
  onFocusedRowChanged,
}) {
  const onDetailCellClick = useCallback((e) => {
    if (!e.row) return;
    dataGridRef.current.instance.option("focusedRowIndex", -1);
    // setDetailRowData(e.row.data);
    // eslint-disable-next-line
  }, []);

  const onRowPrepared = useCallback((e) => {
    if (e.rowType !== "data") return;

    // 색깔 조정하기
    if (e.data.dispatchStatus === 62) {
      // 접수
      e.rowElement.style.background = "#B3E8E5";
    } else if (e.data.dispatchStatus === 78) {
      // 배차등록
      e.rowElement.style.background = "#82DBD8";
    } else if (e.data.dispatchStatus === 79) {
      // 배차확정
      e.rowElement.style.background = "#3BACB6";
    } else if (e.data.dispatchStatus === 80) {
      // 운송완료
      e.rowElement.style.background = "#2F8F9D";
    }
  }, []);

  return (
    <DataGrid
      id="OrderRefDetail"
      showBorders={true}
      columnAutoWidth={true}
      dataSource={detail}
      ref={detailGridRef}
      focusedRowEnabled={true}
      onFocusedRowChanged={onFocusedRowChanged}
      allowColumnResizing={true}
      columnResizingMode="widget"
      onCellClick={onDetailCellClick}
      onRowPrepared={onRowPrepared}
      keyExpr="id"
      width="70vw"
    >
      <Column dataField="dispatchStatus" caption="상태" alignment="center" />
      <Column
        dataField="deliverDate"
        caption="운송일자"
        alignment="center"
        dataType="date"
        format="yyyy-MM-dd"
      />
      <Column dataField="car" caption="배차차량" />
      <Column dataField="yongchasaName" caption="용차사" />
      <Column dataField="startCode" caption="출발지코드" />
      <Column dataField="startPoint" caption="출발지" />
      <Column dataField="endPoint" caption="도착지" />
      <Column
        dataField="habul"
        caption="하불금액"
        alignment="right"
        dataType="number"
      >
        <Format type="fixedPoint" />
      </Column>
      {/* <Column dataField="freight" caption="청구금액" />
      <Column dataField="" caption="하불금액" />
      <Column dataField="extraCharge" caption="할증금액" /> */}
    </DataGrid>
  );
}

export default OrderRefDetail;
