import axios from "axios";

export function sendRequest(url, method, data) {
  method = method || "GET";
  data = data || {};

  if (method === "GET") {
    return axios
      .get(url, { params: data })
      .then(({ data }) => {
        return data;
      })
      .catch((error) => {
        console.log("[error]", error);
        if (error.response) {
          throw error.response.data.message || "서버 요청 에러";
        } else if (error.request) {
          throw error.request;
        } else {
          throw error.message;
        }
      });
  }

  if (method === "POST") {
    return axios
      .post(url, data)
      .then(({ data }) => {
        return data;
      })
      .catch((error) => {
        console.log("[error]", error);
        if (error.response) {
          throw error.response.data.message || "서버 요청 에러";
        } else if (error.request) {
          throw error.request;
        } else {
          throw error.message;
        }
      });
  }

  if (method === "PUT") {
    return axios
      .put(url, data)
      .then(({ data }) => {
        return data;
      })
      .catch((error) => {
        if (error.response) {
          throw error.response.data.message || "서버 요청 에러";
        } else if (error.request) {
          throw error.request;
        } else {
          throw error.message;
        }
      });
  }

  if (method === "DELETE") {
    return axios
      .delete(url, { params: data })
      .then(({ data }) => {
        return data;
      })
      .catch((error) => {
        if (error.response) {
          throw error.response.data.message || "서버 요청 에러";
        } else if (error.request) {
          throw error.request;
        } else {
          throw error.message;
        }
      });
  }

  const params = Object.keys(data)
    .map((key) => {
      return `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`;
    })
    .join("&");

  return fetch(url, {
    method: method,
    body: params,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
    },
    credentials: "include",
  }).then((result) => {
    if (result.ok) {
      return result.text().then((text) => text && JSON.parse(text));
    } else {
      return result.json().then((json) => {
        throw json.Message;
      });
    }
  });
}
