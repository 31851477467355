import { alert, confirm } from "devextreme/ui/dialog";
import React, { useEffect, useRef, useState } from "react";

import { useCallback } from "react";
import { SelectModal } from "../../../../_metronic/_partials/modals/SelectModal";
import InputDate from "../../../components/InputForm/InputDate";
import InputLookup from "../../../components/InputForm/InputLookup";
import InputNumber from "../../../components/InputForm/InputNumber";
import InputRadioGroup from "../../../components/InputForm/InputRadioGroup";
import InputSelect from "../../../components/InputForm/InputSelect";
import InputText from "../../../components/InputForm/InputText";

import CustomButton from "../../../components/FunctionButton/CustomButton";
import { ButtonGroup } from "../../../components/FunctionButton/CustomButton/customButton.style";
import { InputColorSelectBox } from "../../../components/InputForm";
import HeaderTitle from "../../../components/Layout/Header/HeaderTitle";
import {
  InputDivide,
  InputFullRow,
  InputRadioSet,
  PanelInput,
  PanelInputTitle,
  PanelInputWrapper,
} from "../../../components/Layout/Panel/PanelInput/panelInput.style";
import {
  PanelBody,
  PanelHeader,
  PanelInit,
} from "../../../components/Layout/Panel/panel.style";
import { useShuttleForm } from "../../../hooks";
import * as requestServer from "./ImportPickupCrud";
import ImportPickupMaster from "./ImportPickupMaster";

const ImportPickupPage = () => {
  /* #region useState, useRef -----------------------------------------------------------------------*/
  const [selectedRowKey, setSelectedRowKey] = useState(null);
  const [selectModalVisible, setSelectModalVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isOrderStatus, setIsOrderStatus] = useState(false);
  const [lookupData, setLookupData] = useState({
    company: [],
    billing: [],
    conType: [],
    terminal: [],
    workPlace: [],
    cargoOwner: [],
    car: [],
    user: [],
    yongchasa: [],
    forwarder: [],
    color: [],
  });
  const [openInputBar, setOpenInputBar] = useState(false);
  const dataGridRef = useRef();
  const conNoRef = useRef();

  /* #endregion */

  /* #region initialState, react-hook-form, yup ---------------------------------------------------- */
  const [useForm, resetInitialState] = useShuttleForm();

  const {
    watch,
    control,
    trigger,
    setValue,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm;

  /* #endregion */

  /* #region useEffect ----------------------------------------------------------------------------- */
  useEffect(() => {
    getLookup();
    resetInitialState();
    // eslint-disable-next-line
  }, []);
  /* #endregion */

  /* #region 기타함수 ----------------------------------------------------------------------------- */

  const closeModal = () => {
    setSelectModalVisible(false);
    dataGridRef.current.instance.option("focusedRowIndex", -1);
  };

  const getOpenInputBar = (status) => {
    setOpenInputBar(status);
  };

  const onSubmit = async (data) => {
    // if (!selectedRowKey) return;

    try {
      setIsLoading(true);

      if (data.toggle1 === 1) {
        // 차량번호 선택시 용차사, 용차사차량번호 제거
        data.yongchasa_01 = "";
        data.yongchaCarNo_01 = "";
      } else if (data.toggle1 === 2) {
        // 반대로
        data.carNo_01 = "";
      }

      if (data.toggle2 === 1) {
        // 차량번호 선택시 용차사, 용차사차량번호 제거
        data.yongchasa_02 = "";
        data.yongchaCarNo_02 = "";
      } else if (data.toggle2 === 2) {
        // 반대로
        data.carNo_02 = "";
      }

      if (selectedRowKey) {
        await requestServer.updateImportPickup(selectedRowKey, data);
      }
      // else {
      //   await requestServer.createShuttleSimple(data);
      // }

      dataGridRef.current.instance.refresh();
      setSelectedRowKey(null);
      dataGridRef.current.instance.option("focusedRowIndex", -1);
      resetInitialState();
    } catch (err) {
      alert(err.response?.data?.message || "ERROR", "오류");
    } finally {
      setIsLoading(false);
    }
  };

  const deliverComplete = async () => {
    const result = await trigger();
    if (!result) return;

    const check = await confirm("운송완료하시겠습니까?", "확인");
    if (!check) return;

    const data = { ...watch() };

    try {
      setIsLoading(true);

      if (data.toggle1 === 1) {
        // 차량번호 선택시 용차사, 용차사차량번호 제거
        data.yongchasa_01 = "";
        data.yongchaCarNo_01 = "";
      } else if (data.toggle1 === 2) {
        // 반대로
        data.carNo_01 = "";
      }

      if (data.toggle2 === 1) {
        // 차량번호 선택시 용차사, 용차사차량번호 제거
        data.yongchasa_02 = "";
        data.yongchaCarNo_02 = "";
      } else if (data.toggle2 === 2) {
        // 반대로
        data.carNo_02 = "";
      }

      if (selectedRowKey) {
        await requestServer.updateImportPickupConfirm(selectedRowKey, data);
      }

      dataGridRef.current.instance.refresh();
      setSelectedRowKey(null);
      dataGridRef.current.instance.option("focusedRowIndex", -1);
      resetInitialState();
    } catch (err) {
      alert(err.response?.data?.message || "ERROR", "오류");
    } finally {
      setIsLoading(false);
    }
  };

  const onFocusedRowChanged = useCallback((e) => {
    if (!e.row) return;
    const data = e.row.data;
    if (data.orderStatus === 80) setIsOrderStatus(true);
    else setIsOrderStatus(false);
    const rowData = {
      ...data,
      toggle1: data.yongchasa_01 ? 2 : 1,
      toggle2: data.yongchasa_02 ? 2 : 1,
      containerNo: data.containerNo || "",
      sealNo: data.sealNo || "",
      yongchaCarNo_01: data.yongchaCarNo_01 || "",
      yongchaCarNo_02: data.yongchaCarNo_02 || "",
      blNo: data.blNo || "",
      bkNo: data.bkNo || "",
      doNo: data.doNo || "",
      putTime: data.putTime || "",
      sailTime: data.sailTime || "",
      motherShip: data.motherShip || "",
      realCargoOwner: data.realCargoOwner || "",
      remark: data.remark || "",
    };
    setSelectedRowKey(e.row.key);
    reset(rowData);
    setTimeout(() => {
      conNoRef.current.instance.focus();
    }, 0);
    // eslint-disable-next-line
  }, []);

  const onSelect = (rowData) => {
    setValue("importExport", rowData.importExport);
    setValue("cargoOwner", rowData.cargoOwner);
    setValue("billingPlace", rowData.billingPlace);
    setValue("containerType", rowData.containerType);
    setValue("loadPlace", rowData.loadPlace);
    setValue("workPlace", rowData.workPlace);
    setValue("unloadPlace", rowData.unloadPlace);
    setValue("freight", rowData.freight);
    setValue("habul_01", rowData.habul_01);
    setValue("habul_02", rowData.habul_02);

    closeModal();

    setTimeout(() => {
      conNoRef.current.instance.focus();
    }, 0);
  };

  async function getLookup() {
    try {
      const [
        billingPlaceData,
        terminalData,
        conTypeData,
        workPlaceData,
        companyData,
        cargoOwnerData,
        carData,
        userData,
        yongchasaData,
        forwarderData,
        colorData,
      ] = await Promise.all([
        requestServer.getLookup("companyManage/lookup/isBilling"),
        requestServer.getLookup("terminal/terminal"),
        requestServer.getLookup("comcode/detail/10400"),
        requestServer.getLookup("terminal/workplace"),
        requestServer.getLookup("company"),
        requestServer.getLookup("companyManage/lookup/isCargoOwner"),
        requestServer.getLookup("carManage/lookup"),
        requestServer.getLookup("user-list/master"),
        requestServer.getLookup("companyManage/lookup/isYongchasa"),
        requestServer.getLookup("forwarder/lookup"),
        requestServer.getLookup("comcode/detail/10900"),
      ]);

      setLookupData({
        company: companyData.data,
        billing: billingPlaceData.data,
        conType: conTypeData.data,
        terminal: terminalData.data,
        workPlace: workPlaceData.data,
        cargoOwner: cargoOwnerData.data,
        car: carData.data,
        user: userData.data,
        yongchasa: yongchasaData.data,
        forwarder: forwarderData.data,
        color: [{ subCode: null, subName: "" }, ...colorData.data],
      });
    } catch (err) {
      console.log(err);
    }
  }

  /* #endregion */

  return (
    <>
      <PanelInit>
        <PanelHeader>
          <HeaderTitle />
        </PanelHeader>
        <PanelBody
          row
          left="400px"
          right="calc(100% - 400px)"
          openInputBar={openInputBar}
        >
          <PanelInputWrapper
            onSubmit={handleSubmit(onSubmit)}
            openInputBar={openInputBar}
          >
            <PanelInputTitle>수입픽업</PanelInputTitle>
            <PanelInput grid={2}>
              <InputText
                control={control}
                errors={errors}
                name="code"
                label="접수번호"
                disabled={true}
              />
              <InputDate
                control={control}
                errors={errors}
                name="receiveDate"
                label="접수일자"
                disable={true}
              />
              <InputLookup
                control={control}
                errors={errors}
                dataSource={lookupData.company}
                valueExpr="id"
                displayExpr="companyName"
                name="receiveCo"
                label="접수법인"
                required={true}
                disable={true}
              />
              <InputDate
                control={control}
                errors={errors}
                name="deliverDate"
                label="운송일자"
              />
              <InputDate
                control={control}
                errors={errors}
                name="returnDate"
                label="반납일자"
              />
              {/* <div className="form-row row-button"> */}
              <InputLookup
                control={control}
                errors={errors}
                dataSource={lookupData.cargoOwner}
                valueExpr="oid"
                displayExpr="company"
                name="cargoOwner"
                label="화주"
                required={true}
                disable={true}
              />
              {/* <Button
                onClick={() => setSelectModalVisible(true)}
                className="button-dis"
                icon="find"
              ></Button> */}
              {/* </div> */}
              <InputSelect
                control={control}
                errors={errors}
                name="importExport"
                label="수출/수입"
                dataSource={[
                  { id: 1, name: "수입" },
                  { id: 2, name: "수출" },
                ]}
                valueExpr="id"
                displayExpr="name"
                disable={true}
              />
              <InputLookup
                control={control}
                errors={errors}
                name="billingPlace"
                label="청구처"
                dataSource={lookupData.billing}
                displayExpr="company"
                valueExpr="oid"
                required={true}
                disable={true}
              />
              <InputLookup
                control={control}
                errors={errors}
                name="containerType"
                label="컨규격"
                dataSource={lookupData.conType}
                valueExpr="subCode"
                displayExpr="subName"
                // required={true}
                // disable={true}
              />
              <InputText
                control={control}
                errors={errors}
                name="containerNo"
                label="컨테이너No"
                refs={conNoRef}
                // required={true}
                // disabled={true}
              />
              <InputText
                control={control}
                errors={errors}
                name="sealNo"
                label="씰넘버"
              />
              <InputNumber
                control={control}
                errors={errors}
                name="cargoWeight"
                label="화물중량(ton)"
              />
              {watch("importExport") === 1 && (
                <InputText
                  control={control}
                  errors={errors}
                  name="blNo"
                  label="BL NO"
                />
              )}
              {watch("importExport") === 1 && (
                <InputText
                  control={control}
                  errors={errors}
                  name="doNo"
                  label="DO NO"
                />
              )}

              {watch("importExport") === 2 && (
                <InputText
                  control={control}
                  errors={errors}
                  name="motherShip"
                  label="모선항차"
                  disabled={true}
                />
              )}
              {watch("importExport") === 2 && (
                <InputText
                  control={control}
                  errors={errors}
                  name="bkNo"
                  label="BK NO"
                />
              )}
              <InputSelect
                searchEnabled={true}
                control={control}
                errors={errors}
                name="forwarder"
                label="선사"
                dataSource={lookupData.forwarder}
                valueExpr="code"
                displayExpr="forwarder_Name"
              />
              <InputText
                control={control}
                errors={errors}
                name="realCargoOwner"
                label="실화주"
              />
              <InputFullRow grid={2}>
                <InputDivide />
              </InputFullRow>
              <InputLookup
                control={control}
                errors={errors}
                name="loadPlace"
                label="상차지"
                dataSource={lookupData.terminal}
                valueExpr="code"
                displayExpr="twName"
                required={true}
              />
              <InputRadioSet>
                <InputRadioGroup
                  control={control}
                  errors={errors}
                  name="toggle1"
                  dataSource={[
                    { key: 1, value: "차량번호" },
                    { key: 2, value: "용차사" },
                  ]}
                  valueExpr="key"
                  displayExpr="value"
                />
              </InputRadioSet>

              {watch("toggle1") === 1 && (
                <InputLookup
                  control={control}
                  errors={errors}
                  name="carNo_01"
                  label="배차차량"
                  dataSource={lookupData.car}
                  valueExpr="oid"
                  displayExpr="carNo_Name"
                  disable={watch("toggle") === 2}
                />
              )}
              {watch("toggle1") === 2 && (
                <>
                  <InputLookup
                    control={control}
                    errors={errors}
                    name="yongchasa_01"
                    label="용차사"
                    dataSource={lookupData.yongchasa}
                    valueExpr="oid"
                    displayExpr="company"
                    disable={watch("toggle1") === 1}
                  />
                  <InputText
                    control={control}
                    errors={errors}
                    name="yongchaCarNo_01"
                    label="차량번호"
                    disable={watch("toggle1") === 1}
                  />
                </>
              )}
              <InputFullRow grid={2}>
                <InputDivide />
              </InputFullRow>
              <InputLookup
                control={control}
                errors={errors}
                name="workPlace"
                label="작업지"
                dataSource={lookupData.workPlace}
                valueExpr="code"
                displayExpr="twName"
              />
              <InputRadioSet>
                <InputRadioGroup
                  control={control}
                  errors={errors}
                  name="toggle2"
                  dataSource={[
                    { key: 1, value: "차량번호" },
                    { key: 2, value: "용차사" },
                  ]}
                  valueExpr="key"
                  displayExpr="value"
                  disable={true}
                />
              </InputRadioSet>
              <InputText
                control={control}
                errors={errors}
                mask={"00:00:00"}
                name="workPlaceArrive"
                label="작업지 도착시간"
              />

              {watch("toggle2") === 1 && (
                <InputLookup
                  control={control}
                  errors={errors}
                  name="carNo_02"
                  label="배차차량"
                  dataSource={lookupData.car}
                  valueExpr="oid"
                  displayExpr="carNo_Name"
                  disable={
                    true ||
                    watch("workPlace") === "" ||
                    watch("workPlace") === undefined ||
                    watch("toggle2") === 2
                  }
                />
              )}
              {watch("toggle2") === 2 && (
                <>
                  <InputLookup
                    control={control}
                    errors={errors}
                    name="yongchasa_02"
                    label="용차사"
                    dataSource={lookupData.yongchasa}
                    valueExpr="oid"
                    displayExpr="company"
                    disable={
                      true ||
                      watch("workPlace") === "" ||
                      watch("workPlace") === undefined ||
                      watch("toggle2") === 1
                    }
                  />
                  <InputText
                    control={control}
                    errors={errors}
                    name="yongchaCarNo_02"
                    label="차량번호"
                    disabled={
                      true ||
                      watch("workPlace") === "" ||
                      watch("workPlace") === undefined ||
                      watch("toggle2") === 1
                    }
                  />
                </>
              )}
              <InputFullRow grid={2}>
                <InputDivide />
              </InputFullRow>
              <InputLookup
                control={control}
                errors={errors}
                name="unloadPlace"
                label="하차지"
                dataSource={lookupData.terminal}
                valueExpr="code"
                displayExpr="twName"
                required={true}
              />

              <InputColorSelectBox
                control={control}
                errors={errors}
                dataSource={lookupData.color}
                label="색상"
                name="color"
                valueExpr="subCode"
                displayExpr="subName"
                flex={true}
              />
              <InputFullRow grid={2}>
                <InputDivide />
              </InputFullRow>
              <InputNumber
                control={control}
                errors={errors}
                name="freight"
                label="청구금액"
                required={true}
                disabled={true}
              />
              <InputNumber
                control={control}
                errors={errors}
                name="habul_01"
                label="상차지 하불금액"
              />
              <InputNumber
                control={control}
                errors={errors}
                name="habul_02"
                label="하차지 하불금액"
                disabled={
                  true ||
                  watch("workPlace") === "" ||
                  watch("workPlace") === undefined
                }
              />
              {/* <div className="form-row">
              {watch("importExport") === 1 && (
                <InputText
                  control={control}
                  errors={errors}
                  name="blNo"
                  label="BL NO"
                />
              )}
              {watch("importExport") === 1 && (
                <InputText
                  control={control}
                  errors={errors}
                  name="doNo"
                  label="DO NO"
                />
              )}

              {watch("importExport") === 2 && (
                <InputText
                  control={control}
                  errors={errors}
                  name="motherShip"
                  label="모선항차"
                  disabled={true}
                />
              )}
              {watch("importExport") === 2 && (
                <InputText
                  control={control}
                  errors={errors}
                  name="bkNo"
                  label="BK NO"
                />
              )}
            </div> */}

              {watch("importExport") === 2 && (
                <>
                  <InputDate
                    control={control}
                    errors={errors}
                    name="sailDay"
                    label="출항일"
                  />
                  <InputText
                    control={control}
                    errors={errors}
                    mask={"00:00:00"}
                    name="sailTime"
                    label="출항시간"
                  />
                </>
              )}
              {watch("importExport") === 2 && (
                <>
                  <InputDate
                    control={control}
                    errors={errors}
                    name="putDirect"
                    label="접안일"
                  />
                  <InputText
                    control={control}
                    errors={errors}
                    mask={"00:00:00"}
                    name="putTime"
                    label="접안시간"
                  />
                </>
              )}

              <InputText
                control={control}
                errors={errors}
                name="manager"
                label="담당자"
              />
              <InputText
                control={control}
                errors={errors}
                name="extraInfo"
                label="특이사항"
              />
              <InputText
                control={control}
                errors={errors}
                name="remark"
                label="비고"
              />
            </PanelInput>

            <ButtonGroup marginTop={20}>
              <CustomButton
                type="submit"
                width="100%"
                height={36}
                layout="solid"
                color="common"
                label="저장"
                className={`${isLoading &&
                  "spinner spinner-white spinner-right"}`}
                disabled={selectedRowKey === null || isOrderStatus === true}
              />
              <CustomButton
                type="button"
                width="100%"
                height={36}
                layout="solid"
                color="common"
                label="운송완료"
                className={`${isLoading &&
                  "spinner spinner-white spinner-right"}`}
                onClick={deliverComplete}
                disabled={selectedRowKey === null || isOrderStatus === true}
              />
            </ButtonGroup>
          </PanelInputWrapper>
          {/* 페이지 메인그리드 */}
          <ImportPickupMaster
            onFocusedRowChanged={onFocusedRowChanged}
            dataGridRef={dataGridRef}
            openInputBar={openInputBar}
            getOpenInputBar={getOpenInputBar}
            lookupDataMaster={lookupData}
          />
        </PanelBody>
      </PanelInit>
      {selectModalVisible && (
        <SelectModal
          onClose={closeModal}
          closable={true}
          visible={true}
          maskClosable={false}
          dataGrid={dataGridRef}
          onSelect={onSelect}
          param="/shuttlebasic"
          keyExpr="Oid"
        />
      )}
    </>
  );
};

export default ImportPickupPage;
