import { useFormik } from "formik";
import React, { useState } from "react";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import * as Yup from "yup";
import { login } from "../_redux/authCrud";
import * as auth from "../_redux/authRedux";

import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputBase,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import { alert } from "devextreme/ui/dialog";

/*
  INTL (i18n) docs:
  https://github.com/formatjs/react-intl/blob/master/docs/Components.md#formattedmessage
*/

/*
  Formik+YUP:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
*/

function Login(props) {
  // const { intl } = props;
  const [loading, setLoading] = useState(false);

  const BootstrapInput = withStyles((theme) => ({
    root: {},
    input: {
      borderRadius: 4,
      position: "relative",
      border: "none",
      fontSize: 16,
      color: "#3693FF",
      letterSpacing: "-.5px",
      fontWeight: "500",
      textAlign: "center",
      padding: "12px 20px",
      boxShadow: "0 2px 10px 0 rgba(52, 60, 89,.15)",
      transition: theme.transitions.create(["border-color", "box-shadow"]),
      // Use the system font instead of the default Roboto font.
      fontFamily: [
        "-apple-system",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(","),
      "&:focus": {
        borderRadius: 4,
        borderColor: "#80bdff",
        boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
      },
    },
  }))(InputBase);

  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .min(3, "아이디를 3글자 이상 입력해주세요")
      .max(50, "최대 50자 입니다")
      .required(
        // intl.formatMessage({
        //   id: "AUTH.VALIDATION.REQUIRED_FIELD",
        // })
        "아이디를 입력해주세요"
      ),
    password: Yup.string()
      .max(50, "최대 50자 입니다")
      .required(
        // intl.formatMessage({
        //   id: "AUTH.VALIDATION.REQUIRED_FIELD",
        // })
        "비밀번호를 입력해주세요"
      ),
    companySelect: Yup.string(),
    checkbox: Yup.boolean(),
  });

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const formik = useFormik({
    initialValues: localStorage.getItem("login")
      ? JSON.parse(localStorage.getItem("login"))
      : {
          email: "",
          password: "",
          companySelect: "",
          checkbox: false,
        },
    validationSchema: LoginSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      enableLoading();
      setTimeout(() => {
        login(values.email, values.password, values.companySelect)
          .then(({ data: { authToken } }) => {
            disableLoading();
            props.login(authToken);
          })
          .catch((err) => {
            if (err.response.data?.message) {
              alert(err.response.data?.message);
            } else {
              alert("아이디나 비밀번호, 사업자가 올바르지 않습니다.");
            }
          })
          .finally(() => {
            disableLoading();
            setSubmitting(false);
          });
      }, 1000);

      if (values.checkbox) {
        let loginProfile = {
          email: values.email,
          // password: values.password,
          companySelect: values.companySelect,
          checkbox: values.checkbox,
        };
        localStorage.setItem("login", JSON.stringify(loginProfile)); // JSON으로 파싱해서 넣어줌
      } else {
        localStorage.removeItem("login");
      }
    },
  });

  return (
    <div className="login-form login-signin" id="kt_login_signin_form">
      {/* begin::Head */}
      <div className="text-left mb-12">
        <h3 className="display3">Login</h3>
      </div>
      {/* end::Head */}

      {/*begin::Form*/}
      <form
        onSubmit={formik.handleSubmit}
        className="form fv-plugins-bootstrap fv-plugins-framework"
      >
        {/* {formik.status ? (
          <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
            <div className="alert-text font-weight-bold">{formik.status}</div>
          </div>
        ) : (
          <div className="mb-10 alert alert-custom alert-light-info alert-dismissible">
            <div className="alert-text ">로그인 정보를 입력하세요</div>
          </div>
        )} */}
        <div className="form-group fv-plugins-icon-container">
          <FormControl required className="form-control h-auto">
            <Select
              value={formik.getFieldProps("companySelect").value || ""}
              name="companySelect"
              onChange={formik.getFieldProps("companySelect").onChange}
              displayEmpty
              input={
                <BootstrapInput name="companySelect" id="company-select" />
              }
            >
              <MenuItem value="" disabled>
                사업자 선택
              </MenuItem>
              <MenuItem value={"9"}>동륜</MenuItem>
              <MenuItem value={"5"}>동륜물류</MenuItem>
              <MenuItem value={"7"}>HU</MenuItem>
            </Select>
          </FormControl>
          {formik.touched.companySelect && formik.errors.companySelect ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">사업자를 선택해 주세요</div>
            </div>
          ) : null}
        </div>

        <div className="form-group fv-plugins-icon-container login-input">
          <TextField
            variant="outlined"
            label="아이디"
            placeholder="아이디"
            type="text"
            className={`form-control h-auto ${getInputClasses("email")}`}
            // {...formik.getFieldProps("email")}
            name="email"
            onChange={formik.getFieldProps("email").onChange}
            onBlur={formik.getFieldProps("email").onBlur}
            value={formik.getFieldProps("email").value || ""}
          />
          {formik.touched.email && formik.errors.email ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.email}</div>
            </div>
          ) : null}
        </div>
        <div className="form-group fv-plugins-icon-container login-input">
          <TextField
            variant="outlined"
            label="비밀번호"
            placeholder="비밀번호"
            type="password"
            className={`form-control h-auto ${getInputClasses("password")}`}
            name="password"
            onChange={formik.getFieldProps("password").onChange}
            onBlur={formik.getFieldProps("password").onBlur}
            value={formik.getFieldProps("password").value || ""}
          />
          {formik.touched.password && formik.errors.password ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.password}</div>
            </div>
          ) : null}
        </div>
        <div className="form-group d-flex flex-wrap justify-content-between align-items-center login-input">
          <FormGroup className="save-id-info">
            <FormControlLabel
              control={<Checkbox />}
              checked={formik.getFieldProps("checkbox").value}
              value={true}
              onChange={formik.handleChange}
              name="checkbox"
              label="아이디 저장"
            />
          </FormGroup>
          {/* <div className="btn-line-wrap" id="kt_login_forgot">
            <button className="btn btn-line" type="button">
              <Link to="/auth/forgot-password">비밀번호 찾기</Link>
            </button>
          </div> */}
        </div>
        <div>
          <button
            id="kt_login_signin_submit"
            type="submit"
            disabled={formik.isSubmitting}
            className={`btn btn-login font-weight-bold px-9 py-4 my-3`}
          >
            <span>로그인</span>
            {loading && <span className="ml-3 spinner spinner-white"></span>}
          </button>
        </div>
      </form>
      {/* <div className="register-wrap">
        아직 회원이 아니시라면
        <div className="btn-line-wrap">
          <button type="button" className="btn btn-line">
            <Link to="/auth/registration">회원가입</Link>
          </button>
        </div>
      </div> */}
      {/*end::Form*/}
    </div>
  );
}

export default injectIntl(connect(null, auth.actions)(Login));
