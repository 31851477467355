import { yupResolver } from "@hookform/resolvers/yup";
import {
  Column,
  DataGrid,
  Editing,
  Lookup,
  RequiredRule,
  Format,
} from "devextreme-react/data-grid";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import SVG from "react-inlinesvg";
import * as yup from "yup";
import * as requestServer from "../SimpleDispatchCrud";

import moment from "moment";

import CustomStore from "devextreme/data/custom_store";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";
import { sendRequest } from "../../../../../_metronic/_helpers/DataSoureHelpers";

import InputDate from "../../../../components/InputForm/InputDate";
import InputLookup from "../../../../components/InputForm/InputLookup";
import InputRadioGroup from "../../../../components/InputForm/InputRadioGroup";
import InputText from "../../../../components/InputForm/InputText";

import { alert } from "devextreme/ui/dialog";
import InputNumber from "../../../../components/InputForm/InputNumber";

export function ExtraChargeModal({
  onClose,
  maskClosable,
  closable,
  visible,
  rowData,
  setValue,
  datas,
  dataGridRef,
}) {
  const [lookupData, setLookupData] = useState({
    division: [],
    habulDivision: [],
    billingPlace: [],
  });
  const extraChargeRef = useRef();

  const dataSource = useMemo(() => {
    const apiUrl = `${process.env.REACT_APP_API_URL}/extraCharge`;

    return new CustomStore({
      key: "id",
      load: () =>
        sendRequest(apiUrl, "GET", {
          orderDetailCode: rowData?.data.id ? rowData.data.id : "000000000",
        }),
    });
  }, [rowData]);

  useEffect(() => {
    getLookup();

    setTimeout(() => {
      datas && setExtraCharge();
    }, 500);
    // eslint-disable-next-line
  }, []);

  const { handleSubmit } = useForm();

  const onSubmit = async () => {
    const DRIVER = 4305; // habulDivision
    const COMPANY = 4304;

    const detailIsValid = await extraChargeRef.current.instance
      .getController("validating")
      .validate(true);
    if (!detailIsValid) return false;

    const rows = extraChargeRef.current.instance.getVisibleRows();

    for (const obj of rows) {
      if (obj.removed) continue;

      // 기사이면서 하불처가 있다. alert
      // 업체이면서 하불처가 없다. alert
      if (
        (obj.data.habulDivision === DRIVER && obj.data.habulPlace) ||
        (obj.data.habulDivision === COMPANY && !obj.data.habulPlace)
      ) {
        alert("하불처가 올바르지 않습니다.", "오류");
        return;
      }
    }

    if (datas) {
      setValue(
        "extraChargeObj",
        extraChargeRef.current.instance.option("editing.changes")
      );
      onClose();
    } else {
      const orderCode = rowData.key;
      const orderDetailCode = rowData.data.id;
      const extraCharge = extraChargeRef.current.instance.option(
        "editing.changes"
      );

      try {
        await requestServer.updateExtraCharge({
          orderCode,
          orderDetailCode,
          extraCharge,
        });

        dataGridRef.current.instance.refresh();
        onClose();
      } catch (err) {
        alert("실패", "오류");
      }
    }
  };

  const onMaskClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  const onToolbarPreparing = (e) => {
    e.toolbarOptions.items.forEach((item) => {
      if (item.name === "saveButton") {
        item.visible = false;
      }
    });
  };

  const onInitNewRow = (e) => {
    e.data.isCharged = true;
    e.data.isHabul = true;
    // e.data.chargedRate = "%";
  };

  async function getLookup() {
    const divisionData = await requestServer.getLookup("comcode/detail/10300");
    const habulDivisionData = await requestServer.getLookup(
      "comcode/detail/10700"
    );
    const billingPlaceData = await requestServer.getLookup("billingplace");

    setLookupData({
      division: divisionData.data,
      habulDivision: habulDivisionData.data,
      billingPlace: billingPlaceData.data,
    });
  }

  function setExtraCharge() {
    const changes = datas.extraChargeObj;
    const extraGrid = extraChargeRef.current.instance;
    if (!changes) return;

    for (const obj of changes) {
      if (obj.type === "insert") {
        extraGrid.addRow();
        extraGrid.cellValue(0, "division", obj.data.division);
        extraGrid.cellValue(0, "chargedRate", obj.data.chargedRate);
        extraGrid.cellValue(0, "chargedAmount", obj.data.chargedAmount);
        extraGrid.cellValue(0, "isCharged", obj.data.isCharged);
        extraGrid.cellValue(0, "isHabul", obj.data.isHabul);
        extraGrid.cellValue(0, "habulDivision", obj.data.habulDivision);
        extraGrid.cellValue(0, "habulPlace", obj.data.habulPlace);
      } else if (obj.type === "update") {
        const rowIndex = extraGrid.getRowIndexByKey(obj.key);
        for (const tag in obj.data) {
          extraGrid.cellValue(rowIndex, `${tag}`, obj.data[tag]);
        }
      } else {
        const key = extraGrid.getRowIndexByKey(obj.key);
        extraGrid.deleteRow(key);
      }
    }
  }

  // per를 통해 할증금액 계산
  const setPerCellValue = (newData, amount, currentRowData) => {
    newData.chargedRate = amount;

    const modalFreight = datas ? datas.freight : "";
    const gridFreight = rowData ? rowData.data.freight : "";

    if (modalFreight) {
      let result = Math.round(
        (parseInt(amount) * parseInt(modalFreight)) / 100
      ); // 계산

      newData.chargedAmount = result;
    } else if (gridFreight) {
      let result = Math.round((parseInt(amount) * parseInt(gridFreight)) / 100); // 계산

      newData.chargedAmount = result;
    }
  };

  const setAmountCellValue = (newData, amount, currentRowData) => {
    newData.chargedAmount = amount;

    const modalFreight = datas ? datas.freight : "";
    const gridFreight = rowData ? rowData.data.freight : "";

    if (modalFreight) {
      let result = Math.round(
        (parseInt(amount) / parseInt(modalFreight)) * 100
      ); // 계산

      newData.chargedRate = result;
    } else if (gridFreight) {
      let result = Math.round((parseInt(amount) / parseInt(gridFreight)) * 100); // 계산

      newData.chargedRate = result;
    }
  };

  return (
    <>
      <div className={"modal-overlay " + (visible ? "active" : "")} />
      <div
        className={"modal-wrap user-list-modal " + (visible ? "active" : "")}
        onClick={maskClosable ? onMaskClick : null}
        tabIndex="-1"
        visible={`${visible}`}
      >
        <Modal.Dialog centered tabIndex="0" size="lg" animation="true">
          <Modal.Body>
            <form className="modal-form" onSubmit={handleSubmit(onSubmit)}>
              <div className="modal-form-header">
                <div className="modal-form-tit">
                  <span className="modal-tit">할증등록</span>
                </div>
                <button
                  type="button"
                  className="btn-modal btn-modal-close"
                  onClick={onClose}
                >
                  <SVG
                    src={toAbsoluteUrl(
                      "/media/svg/icons/Custom/close_black_24dp.svg"
                    )}
                    className="close-icon"
                  />
                </button>
              </div>
              <div className="modal-form-body">
                <DataGrid
                  dataSource={dataSource}
                  ref={extraChargeRef}
                  showRowLines={true}
                  showBorders={true}
                  allowColumnResizing={true}
                  onToolbarPreparing={onToolbarPreparing}
                  onInitNewRow={onInitNewRow}
                  keyExpr="id"
                >
                  <Editing
                    mode="batch"
                    allowAdding={true}
                    allowDeleting={true}
                    allowUpdating={true}
                  />
                  <Column dataField="division" caption="구분">
                    <Lookup
                      dataSource={lookupData.division}
                      valueExpr="id"
                      displayExpr="subName"
                    />
                    <RequiredRule message="구분을 선택해주세요" />
                  </Column>
                  {/* <Column caption="%" /> */}
                  <Column
                    dataField="chargedRate"
                    caption="%"
                    setCellValue={setPerCellValue}
                  >
                    <Format type="fixedPoint" />
                  </Column>
                  <Column
                    dataField="chargedAmount"
                    caption="발생금액"
                    dataType="number"
                    setCellValue={setAmountCellValue}
                  >
                    <Format type="fixedPoint" />
                    <RequiredRule message="할증 금액을 입력해주세요" />
                  </Column>
                  <Column
                    dataField="isCharged"
                    caption="청구"
                    dataType="boolean"
                    width="35"
                  />
                  <Column
                    dataField="isHabul"
                    caption="하불"
                    dataType="boolean"
                    width="35"
                  />
                  <Column
                    dataField="habulDivision"
                    caption="하불구분"
                    width="70"
                  >
                    <Lookup
                      dataSource={lookupData.habulDivision}
                      valueExpr="id"
                      displayExpr="subName"
                    />
                    <RequiredRule message="하불구분을 선택해주세요" />
                  </Column>
                  <Column dataField="habulPlace" caption="하불처">
                    <Lookup
                      dataSource={lookupData.billingPlace}
                      valueExpr="code"
                      displayExpr="companyName"
                    />
                  </Column>
                </DataGrid>
              </div>
              <div className="modal-form-footer">
                <div>
                  <button type="submit" className="btn btn-modal btn-save">
                    적용
                  </button>
                </div>
              </div>
            </form>
          </Modal.Body>
        </Modal.Dialog>
      </div>
    </>
  );
}

export function MakeDispatch({
  onClose,
  maskClosable,
  closable,
  visible,
  detailRowData,
  dataGridRef,
}) {
  const [car, setCar] = useState();
  const [yongchasa, setYongchasa] = useState();
  const schema = yup
    .object({
      deliverDate: yup.string(),
      carNo: yup.string(),
      yongchasa: yup.string(),
      yongchaCarNo: yup.string(),
    })
    .required();

  const {
    control,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    function resetInput(detailRowData) {
      const toggle = detailRowData.yongchasa ? "2" : "1";
      reset({
        carNo: detailRowData.carCode || undefined,
        yongchasa: detailRowData.yongchasaCode || undefined,
        yongchaCarNo: detailRowData.yongchaCarNo || undefined,
        toggle,
        deliverDate: moment().format("YYYY-MM-DD") || undefined,
      });
    }

    if (detailRowData) resetInput(detailRowData);
    getLookup();
    // eslint-disable-next-line
  }, []);

  const onSubmit = async (data) => {
    if (!data.deliverDate) {
      alert("운송일자를 입력해주세요", "오류");
      return;
    }
    if (data.toggle === "1") {
      if (!data.carNo) {
        alert("차량번호를 입력해주세요", "오류");
        return;
      }

      data.yongchasa = null;
      data.yongchaCarNo = null;
    } else {
      if (!data.yongchasa) {
        alert("용차사를 선택해주세요", "오류");
        return;
      }
      data.carNo = null;
    }
    delete data.toggle;

    try {
      await requestServer.changeCarNo(detailRowData.id, data);

      dataGridRef.current.instance.refresh();
      onClose();
    } catch (err) {
      alert(err.response.data.message, "오류");
    }
  };

  const onMaskClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  async function getLookup() {
    try {
      const carData = await requestServer.getLookup("carManage/lookup");
      const yongchasaData = await requestServer.getLookup(
        "companyManage/lookup/isYongchasa"
      );

      console.log(carData.data);

      setCar(carData.data);
      setYongchasa(yongchasaData.data);
    } catch (err) {
      console.log(err);
    }
  }

  return (
    <>
      <div className={"modal-overlay " + (visible ? "active" : "")} />
      <div
        className={"modal-wrap user-list-modal " + (visible ? "active" : "")}
        onClick={maskClosable ? onMaskClick : null}
        tabIndex="-1"
        visible={`${visible}`}
      >
        <Modal.Dialog centered tabIndex="0" size="sm" animation="true">
          <Modal.Body>
            <form onSubmit={handleSubmit(onSubmit)} className="modal-form">
              <div className="modal-form-header">
                <div className="modal-form-tit">
                  <span className="modal-tit">배차등록</span>
                </div>
                <button
                  type="button"
                  className="btn-modal btn-modal-close"
                  onClick={onClose}
                >
                  <SVG
                    src={toAbsoluteUrl(
                      "/media/svg/icons/Custom/close_black_24dp.svg"
                    )}
                    className="close-icon"
                  />
                </button>
              </div>
              <div className="modal-form-body">
                {/* <div className="d-flex flex-row mb-1">
                  <div className="dx-field">
                    <InputDate control={control} errors={errors} name="original" label="기존일자" flex={true} disable={true} />
                  </div>
                </div> */}

                <div className="d-flex flex-row mb-1">
                  <div className="dx-field">
                    <InputDate
                      control={control}
                      errors={errors}
                      name="deliverDate"
                      label="운송일자"
                      flex={true}
                    />
                  </div>
                </div>

                <div className="d-flex flex-row mb-1">
                  <div className="dx-field">
                    <InputRadioGroup
                      control={control}
                      errors={errors}
                      flex={true}
                      name="toggle"
                      dataSource={[
                        { id: "1", name: "차량번호" },
                        { id: "2", name: "용차사" },
                      ]}
                    />
                  </div>
                </div>

                {watch("toggle") === "1" && (
                  <div className="d-flex flex-row mb-1">
                    <div className="dx-field">
                      <InputLookup
                        control={control}
                        errors={errors}
                        name="carNo"
                        label="차량번호"
                        flex={true}
                        dataSource={car}
                        valueExpr="oid"
                        displayExpr="carNo_Name"
                        disable={watch("toggle") === "2"}
                        onInitialized={(e) => {
                          e.component.open();
                          setTimeout(() => {
                            e.component.focus();
                          }, 0);
                        }}
                      />
                    </div>
                  </div>
                )}

                {watch("toggle") === "2" && (
                  <>
                    <div className="d-flex flex-row mb-1">
                      <div className="dx-field">
                        <InputLookup
                          control={control}
                          errors={errors}
                          name="yongchasa"
                          label="용차사"
                          flex={true}
                          dataSource={yongchasa}
                          valueExpr="oid"
                          displayExpr="company"
                          disable={watch("toggle") === "1"}
                          onInitialized={(e) => {
                            e.component.open();
                            setTimeout(() => {
                              e.component.focus();
                            }, 0);
                          }}
                        />
                      </div>
                    </div>
                    <div className="d-flex flex-row mb-1">
                      <div className="dx-field">
                        <InputText
                          control={control}
                          errors={errors}
                          name="yongchaCarNo"
                          label="차량번호"
                          flex={true}
                          disable={watch("toggle") === "1"}
                        />
                      </div>
                    </div>
                  </>
                )}
              </div>

              <div className="modal-form-footer">
                <button type="submit" className="btn btn-modal btn-save">
                  수정
                </button>
              </div>
            </form>
          </Modal.Body>
        </Modal.Dialog>
      </div>
    </>
  );
}

export function HabulModal({
  onClose,
  maskClosable,
  closable,
  visible,
  detailRowData,
  dataGridRef,
}) {
  const schema = yup
    .object({
      ex: yup.string(),
    })
    .required();

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onMaskClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  useEffect(() => {
    setValue("habul", detailRowData ? detailRowData.habul : "0");
    // eslint-disable-next-line
  }, []);

  const onSubmit = async (data) => {
    try {
      data.id = detailRowData.id;

      await requestServer.setHabul(data);

      dataGridRef.current.instance.refresh();
      onClose();
    } catch (err) {
      alert(err.response.data.message, "오류");
      return;
    }
  };

  return (
    <>
      <div className={"modal-overlay " + (visible ? "active" : "")} />
      <div
        className={"modal-wrap user-list-modal " + (visible ? "active" : "")}
        onClick={maskClosable ? onMaskClick : null}
        tabIndex="-1"
        visible={`${visible}`}
      >
        <Modal.Dialog centered tabIndex="0" size="sm" animation="true">
          <Modal.Body>
            <form className="modal-form" onSubmit={handleSubmit(onSubmit)}>
              <div className="modal-form-header">
                <div className="modal-form-tit">
                  <span className="modal-tit">하불등록</span>
                </div>
                <button
                  type="button"
                  className="btn-modal btn-modal-close"
                  onClick={onClose}
                >
                  <SVG
                    src={toAbsoluteUrl(
                      "/media/svg/icons/Custom/close_black_24dp.svg"
                    )}
                    className="close-icon"
                  />
                </button>
              </div>
              <div className="modal-form-body">
                <div className="d-flex flex-row mb-1">
                  <div className="dx-field">
                    <InputNumber
                      control={control}
                      errors={errors}
                      name="habul"
                      label="하불금액"
                      flex={true}
                      onInitialized={(e) => {
                        setTimeout(() => {
                          e.component.focus();
                        }, 0);
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="modal-form-footer">
                <button type="submit" className="btn btn-modal btn-save">
                  저장
                </button>
              </div>
            </form>
          </Modal.Body>
        </Modal.Dialog>
      </div>
    </>
  );
}

// export default ExtraChargeModal;
