import { yupResolver } from "@hookform/resolvers/yup";
import dayjs from "dayjs";
import { useEffect, useMemo, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import * as requestServer from "./EtcCostCrud";
import { alert, confirm } from "devextreme/ui/dialog";
import CustomStore from "devextreme/data/custom_store";
import { sendRequest } from "../../../../_metronic/_helpers/DataSoureHelpers";

const initialInputState = {
  companyOid: "",
  regDate: dayjs().format("YYYY-MM-DD"),
  companyName: "",
  sales: 0,
  purchase: 0,
};

const initialSearchState = {
  companyOid: "",
  firstDay: dayjs().format("YYYY-MM-DD"),
  lastDay: dayjs().format("YYYY-MM-DD"),
};

// useForm을 통한 유효성 검사
const schema = yup.object({
  companyOid: yup
    .string()
    .nullable()
    .required("업체를 선택해주세요"),
  regDate: yup
    .string()
    .nullable()
    .required("등록일을 입력해주세요"),
  companyName: yup
    .string()
    .nullable()
    .required("업체명을 입력해주세요"),
  sales: yup
    .number()
    .nullable()
    .required("매출을 입력해주세요"),
  purchase: yup
    .number()
    .nullable()
    .required("매입을 입력해주세요"),
});

const useEtcCost = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [selectedRowKey, setSelectedRowKey] = useState();
  const [inputDisable, setInputDisable] = useState(false);
  const [search, setSearch] = useState(initialSearchState);
  const [lookupData, setLookupData] = useState({
    company: [],
    inputCompany: [],
  });

  const dataGridRef = useRef();

  const inputForm = useForm({
    defaultValues: initialInputState,
    resolver: yupResolver(schema),
  });
  const { reset: inputReset } = inputForm;

  const searchForm = useForm({
    defaultValues: initialSearchState,
  });
  const { setValue: searchSetValue } = searchForm;

  const dataSource = useMemo(() => {
    const apiUrl = `${process.env.REACT_APP_API_URL}/etccost`;

    return new CustomStore({
      key: "Oid",
      load: () => sendRequest(apiUrl, "GET", search),
    });
  }, [search]);

  useEffect(() => {
    _initLookupData();
  }, []);

  useEffect(() => {
    initInputData(selectedRowKey);
    // eslint-disable-next-line
  }, [selectedRowKey]);

  const _initLookupData = async () => {
    const companyData = await requestServer.getLookup("company");

    setLookupData({
      company: [
        {
          id: "",
          companyName: "전체",
        },
        ...companyData.data,
      ],
      inputCompany: companyData.data,
    });
  };

  // 신규버튼 핸들러
  const onClickAdd = () => {
    if (!dataGridRef.current) return;
    inputReset(initialInputState);
    setSelectedRowKey(null);
    setInputDisable(false);
    dataGridRef.current.instance.option("focusedRowIndex", -1);
  };

  // 삭제버튼 핸들러
  const onClickDelete = () => {
    if (!dataGridRef.current || !selectedRowKey) return;
    let response = confirm("<i>삭제하시겠습니까?</i>", "삭제 메세지");

    response.then((res) => {
      if (!res) return;
      requestServer
        .deleteEtcCostRow(selectedRowKey)
        .then(() => {
          dataGridRef.current.instance.refresh();
        })
        .catch((err) => {
          alert(err.response.data.message, "오류");
        });
    });
  };

  // 테이블 클릭시 인풋에 데이터 값 넣기
  const initInputData = async (key) => {
    try {
      const { data } = await requestServer.getEtcCodeData(key);
      //react-hook-form 데이터 불러와서 form에 set
      if (data.companyOid) data.companyOid = Number(data.companyOid);
      inputReset(data);
    } catch (error) {
      console.log(error);
    }
  };

  // 저장
  const onSubmit = async (data) => {
    setIsLoading(true);
    try {
      if (selectedRowKey) {
        await requestServer.updateEtcCode(selectedRowKey, data);
      } else {
        await requestServer.createEtcCode(data);
      }
      dataGridRef.current.instance.refresh();

      setSelectedRowKey(null);

      dataGridRef.current.instance.option("focusedRowIndex", -1);
    } catch (err) {
      alert(err.response.data.message, "오류");
    } finally {
      setIsLoading(false);
      inputReset(initialInputState);
    }
  };

  const onSearchSubmit = (data) => {
    for (const obj in data) {
      if (data[obj] === "") {
        data[obj] = null;
      }
    }
    setSearch(data);
  };

  const onSelectionChanged = (e) => {
    const { selectedRowsData } = e;

    let 매출 = 0;
    let 매입 = 0;

    for (const obj of selectedRowsData) {
      const { sales, purchase } = obj;
      매출 += sales;
      매입 += purchase;
    }

    searchSetValue("test1", 매출);
    searchSetValue("test2", 매입);
    searchSetValue("test3", 매출 - 매입);
  };

  const onFocusedRowChanged = (e) => {
    if (!e.row) return;
    setSelectedRowKey(e.row.key);
    setInputDisable(true);
  };

  const inputProps = {
    lookupData: lookupData,
    isLoading: isLoading,
    inputDisable: inputDisable,
    inputForm: inputForm,
    onSubmit: onSubmit,
  };

  const gridProps = {
    lookupData: lookupData,
    dataGridRef: dataGridRef,
    searchForm: searchForm,
    dataSource: dataSource,
    onSubmit: onSearchSubmit,
    onSelectionChanged: onSelectionChanged,
    onFocusedRowChanged: onFocusedRowChanged,
  };

  return { onClickAdd, onClickDelete, inputProps, gridProps };
};

export default useEtcCost;
