import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import AuthGroupPage from "./AuthGroup/AuthGroupPage";
import ComCodePage from "./ComCode.js/ComCodePage";
import CompanyPage from "./Company/CompanyPage";
import EmployeePage from "./Employee/EmployeePage";
import MenuPage from "./Menu/MenuPage";
import UserPage from "./User/UserPage";

export default function SystemPage() {
  return (
    <Switch>
      <Redirect from="/system" exact={true} to="/system/comcode" />
      <Route path="/system/comcode" component={() => <ComCodePage />} />
      <Route path="/system/employee" component={() => <EmployeePage />} />
      <Route path="/system/user" component={() => <UserPage />} />
      <Route path="/system/authgroup" component={() => <AuthGroupPage />} />
      <Route path="/system/memu" component={() => <MenuPage />} />
      <Route path="/system/comapny" component={() => <CompanyPage />} />
    </Switch>
  );
}
