import React, { useCallback, useRef, useState } from "react";
import HeaderTitle from "../../../components/Layout/Header/HeaderTitle";
import {
  PanelBody,
  PanelHeader,
  PanelInit,
} from "../../../components/Layout/Panel/panel.style";
import AppContainerMaster from "./AppContainerMaster";
import AppContainerModal from "./components/AppContainerModal";

const AppContainerPage = () => {
  const [containerModalVisible, setContainerModalVisible] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});

  const dataGridRef = useRef();

  /* #region initialState, react-hook-form, yup ---------------------------------------------------- */

  // const initialState = {
  //   FileList_Code: "",
  //   File_Name: "",
  //   File_Info: "",
  //   Use_Yn: "",
  //   Remark: undefined,
  // };

  // const schema = yup.object({}).required();

  // const {
  //   watch,
  //   control,
  //   handleSubmit,
  //   reset,
  //   setValue,
  //   formState: { errors },
  // } = useForm({
  //   resolver: yupResolver(schema),
  //   defaultValues: initialState,
  // });
  /* #endregion */

  const onFocusedRowChanged = useCallback((e) => {
    setSelectedRow(e.row.data);
  }, []);

  return (
    <>
      <PanelInit>
        <PanelHeader>
          <HeaderTitle />
        </PanelHeader>
        <PanelBody row left="100%" right="0">
          <AppContainerMaster
            onFocusedRowChanged={onFocusedRowChanged}
            dataGridRef={dataGridRef}
            setContainerModalVisible={setContainerModalVisible}
          />
        </PanelBody>
      </PanelInit>
      {containerModalVisible && (
        <AppContainerModal
          onClose={() => setContainerModalVisible(false)}
          closable={true}
          visible={true}
          maskClosable={false}
          dataGridRef={dataGridRef}
          selectedRow={selectedRow}
        />
      )}
    </>
  );
};

export default AppContainerPage;
