import React from "react";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";
import DragDrop from "../../../../components/DragDrop";
import CustomButton from "../../../../components/FunctionButton/CustomButton";
import Loading from "../../../../components/Loading/Loading";
import { UploadFileWrapper } from "../../../../components/Modal/modal.style";
import { ModalContainer } from "../../../../components/Modal";

export const SuttleExcelUploadModal = ({
  onClose,
  visible,
  maskClosable,
  processExcelData,
  onSaveExcelData,
  isLoading,
}) => {
  return (
    <>
      <ModalContainer
        onClose={onClose}
        maskClosable={maskClosable}
        visible={visible}
        size="md"
      >
        <div className="modal-form">
          <div className="modal-form-header">
            <div className="modal-form-tit">
              <span className="modal-tit">엑셀 업로드</span>
            </div>
            <div className="d-flex flex-row">
              <button
                type="button"
                className="btn-modal btn-modal-close"
                onClick={onClose}
              >
                <SVG
                  src={toAbsoluteUrl(
                    "/media/svg/icons/Custom/close_black_24dp.svg"
                  )}
                  className="close-icon"
                />
              </button>
            </div>
          </div>
          {isLoading ? (
            <Loading type="page" text="파일 업로드 중입니다..." />
          ) : (
            <UploadFileWrapper>
              <DragDrop setFiles={processExcelData} />
            </UploadFileWrapper>
          )}

          <div className="modal-form-footer">
            <CustomButton
              type="button"
              height={36}
              layout="solid"
              color="common"
              label="저장"
              disabled={isLoading}
              onClick={onSaveExcelData}
            />
          </div>
        </div>
      </ModalContainer>
    </>
  );
};
