import { DataGrid } from "devextreme-react";
import {
  Column,
  ColumnChooser,
  FilterRow,
  Format,
  HeaderFilter,
  Scrolling,
  StateStoring,
} from "devextreme-react/data-grid";
import React, { useCallback, useState } from "react";
import CustomButton from "../../../../components/FunctionButton/CustomButton";
import { InputDate } from "../../../../components/InputForm";
import {
  DataCount,
  PanelGridInit,
  PanelGridSearchBar,
  PanelGridWrapper,
  SearchItems,
} from "../../../../components/Layout/Panel/PanelGrid/panelGrid.style";
import StateStoringSaveAndDeleteButton from "../../../../components/StateStoring";
// import NumberColumn from "../../../../components/GridColumn/NumberColumn";

const CarDeadlineMaster = ({
  state,
  dataGridRef,
  searchForm,
  masterFunctions,
}) => {
  const [totalCount, setTotalCount] = useState(0);
  const storageKey = "carDeadlineMaster";

  const { onSubmit, onFocusedRowChanged } = masterFunctions;
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = searchForm;

  const { dataSource } = state;

  return (
    <>
      <PanelGridWrapper>
        <PanelGridSearchBar onSubmit={handleSubmit(onSubmit)}>
          <SearchItems>
            <label>하불마감일자</label>
            <InputDate
              control={control}
              errors={errors}
              name="firstDay"
              flex={true}
            />
            <div className="to-mark">~</div>
            <InputDate
              control={control}
              errors={errors}
              name="lastDay"
              flex={true}
            />
          </SearchItems>

          <CustomButton
            type="submit"
            height={24}
            layout="solid"
            color="search"
            label="검색"
          />
        </PanelGridSearchBar>

        <PanelGridInit>
          <StateStoringSaveAndDeleteButton
            dataGridRef={dataGridRef}
            storageKey={storageKey}
          />
          <DataCount>{`총 ${totalCount}건`}</DataCount>
          <DataGrid
            id="EtcCostMaster"
            className="datagrid-wrap"
            ref={dataGridRef}
            dataSource={dataSource}
            showRowLines={true}
            showBorders={true}
            hoverStateEnabled={true}
            allowColumnResizing={true}
            allowColumnReordering={true}
            columnResizingMode="widget"
            keyExpr="oid"
            focusedRowEnabled={true}
            onFocusedRowChanged={onFocusedRowChanged}
            onContentReady={(e) => setTotalCount(e.component.totalCount())}
          >
            <FilterRow visible={true} />
            <HeaderFilter visible={true} />
            <ColumnChooser enabled={true} allowSearch={true} />
            <StateStoring
              enabled={true}
              type="custom"
              customLoad={useCallback(() => {
                return JSON.parse(localStorage.getItem(storageKey));
              }, [])}
            />
            <Scrolling mode="virtual" />
            <Column caption="하불마감 기본정보">
              <Column
                dataField="carDeadlineDate"
                caption="하불마감일자"
                width="95"
                dataType="date"
                format="yyyy-MM"
              />
              <Column dataField="carNo" caption="차량번호" width="95" />
              <Column dataField="bizName" caption="사업자상호" width="95" />
              <Column dataField="ceoName" caption="대표자" width="95" />
              <Column
                dataField="carDivisionName"
                caption="차량구분"
                width="95"
              />
            </Column>
            <Column caption="운송료">
              <Column
                dataField="money01"
                caption="공급가액"
                width="95"
                dataType="number"
              >
                <Format type="fixedPoint" />
              </Column>
              <Column
                dataField="money02"
                caption="세액"
                width="95"
                dataType="number"
              >
                <Format type="fixedPoint" />
              </Column>
              <Column
                dataField="money03"
                caption="소계"
                width="95"
                dataType="number"
              >
                <Format type="fixedPoint" />
              </Column>
            </Column>
            <Column caption="업무대행수수료">
              <Column
                dataField="manageFee"
                caption="관리비"
                width="95"
                dataType="number"
              >
                <Format type="fixedPoint" />
              </Column>
              <Column
                dataField="chassisManageFee"
                caption="샤시관리비"
                width="95"
                dataType="number"
              >
                <Format type="fixedPoint" />
              </Column>
              <Column
                dataField="chassisRental"
                caption="샤시임대료"
                width="95"
                dataType="number"
              >
                <Format type="fixedPoint" />
              </Column>
              <Column
                dataField="parking"
                caption="차량주차비"
                width="95"
                dataType="number"
              >
                <Format type="fixedPoint" />
              </Column>
              <Column
                dataField="hireInsurance"
                caption="고용보험료"
                width="95"
                dataType="number"
              >
                <Format type="fixedPoint" />
              </Column>
              <Column
                dataField="occupationalInsurance"
                caption="산재보험료"
                width="95"
                dataType="number"
              >
                <Format type="fixedPoint" />
              </Column>
              <Column
                dataField="money04"
                caption="공급가액"
                width="95"
                dataType="number"
              >
                <Format type="fixedPoint" />
              </Column>
              <Column
                dataField="money05"
                caption="세액"
                width="95"
                dataType="number"
              >
                <Format type="fixedPoint" />
              </Column>
              <Column
                dataField="money06"
                caption="소계"
                width="95"
                dataType="number"
              >
                <Format type="fixedPoint" />
              </Column>
            </Column>
            <Column caption="기타공제">
              <Column
                dataField="advancePaymentDeduction"
                caption="가불공제"
                width="95"
                dataType="number"
              >
                <Format type="fixedPoint" />
              </Column>
              <Column
                dataField="advancePayment"
                caption="대납"
                width="95"
                dataType="number"
              >
                <Format type="fixedPoint" />
              </Column>
              <Column
                dataField="prepayment"
                caption="선결재"
                width="95"
                dataType="number"
              >
                <Format type="fixedPoint" />
              </Column>
              <Column
                dataField="money07"
                caption="기타항목합계"
                width="95"
                dataType="number"
              >
                <Format type="fixedPoint" />
              </Column>
            </Column>
            <Column caption="결산">
              <Column
                dataField="money09"
                caption="지출계"
                width="95"
                dataType="number"
              >
                <Format type="fixedPoint" />
              </Column>
              <Column
                dataField="money10"
                caption="미지급금"
                width="95"
                dataType="number"
              >
                <Format type="fixedPoint" />
              </Column>
            </Column>
          </DataGrid>
        </PanelGridInit>
      </PanelGridWrapper>
    </>
  );
};

export default CarDeadlineMaster;
