import React from "react";
import { cancelOrder, deleteOrder } from "../YongchaDispatchCrud";
import { shallowEqual, useSelector } from "react-redux";
import { alert, confirm } from "devextreme/ui/dialog";
import { PanelBtn } from "../../../../components/Layout/Panel/panel.style";
import CustomButton from "../../../../components/FunctionButton/CustomButton";
import { getInitialInputState } from "../YongchaOrderInitialState";

const YongchaOrderHeader = ({
  dataGridRef,
  detailGrid,
  inputForm,
  isConfirm,
  selectedRowKey,
  setSelectedRowKey,
  initInputData,
}) => {
  const { user } = useSelector(
    (state) => ({ user: state.auth.user.user }),
    shallowEqual
  );
  const initialInputState = getInitialInputState(user);

  const { watch, reset } = inputForm;

  /**
   * 오더복사버튼
   *
   */
  const handleCopyButtonClick = () => {
    if (!selectedRowKey) return;
    setSelectedRowKey(null);

    const grid = detailGrid.current.instance;

    // 1. detailGrid Row 저장
    grid.option("editing.changes", []);
    const rows = [...grid.getVisibleRows()];

    // 2. rowData에서 code, parentOrder 초기화
    const data = watch();

    // 3. 1에서 저장한 detail들을 컨테이너 씰번호 초기화 후 다시 집어넣기
    reset({
      ...data,
      code: undefined,
      parentOrder: undefined,
      confirm: false,
    });

    setTimeout(() => {
      for (const obj of rows) {
        grid.addRow();
        for (const key in obj.data) {
          if (key === "containerNo" || key === "sealNo") continue;
          grid.cellValue(0, key, obj.data[key]);
        }
      }
    }, 10);
  };

  /**
   * 오더취소버튼
   *
   */
  const onClickOrderCancel = async () => {
    if (!selectedRowKey) {
      await alert("데이터를 선택해주세요", "오류");
      return;
    }

    const result = await confirm("정말 취소하시겠습니까?", "확인");
    if (!result) return;

    try {
      const result = await cancelOrder(selectedRowKey);
      const { data } = result;

      if (data.result === 1) {
        await alert(data?.message, "확인");
      } else {
        await alert(data?.message, "오류");
      }

      await initInputData(selectedRowKey);
      // setSelectedRowKey(null);
      // dataGridRef && dataGridRef.current.instance.option("focusedRowIndex", -1);
      dataGridRef && dataGridRef.current.instance.refresh();
    } catch (err) {
      console.log(err);
      alert(err?.response?.data?.message || "ERROR-01", "오류");
    }
  };

  /**
   * 오더삭제버튼
   *
   */
  const onClickOrderDelete = async () => {
    if (!selectedRowKey) {
      await alert("데이터를 선택해주세요", "오류");
      return;
    }

    if (isConfirm) {
      await alert("오더확정된 데이터입니다", "오류");
      return;
    }

    if (watch("code") !== watch("parentOrder")) {
      const otherCoConfirm = await confirm(
        "타법인배차로 생성된 데이터입니다 삭제하시겠습니까?",
        "확인"
      );
      if (!otherCoConfirm) return;
    }

    const result = await confirm(
      "정말 삭제하시겠습니까? 삭제된 데이터는 복구되지 않습니다.",
      "확인"
    );
    if (!result) return;

    try {
      const result = await deleteOrder(selectedRowKey);
      const { data } = result;

      if (data.result === 1) {
        await alert(data?.message, "확인");
      } else {
        await alert(data?.message, "오류");
      }

      setSelectedRowKey(null);
      dataGridRef && dataGridRef.current.instance.option("focusedRowIndex", -1);
      dataGridRef && dataGridRef.current.instance.refresh();

      reset(initialInputState);
    } catch (err) {
      console.log(err);
      alert(err?.response?.data?.message || "ERROR-01", "오류");
    }
  };

  return (
    <PanelBtn>
      <CustomButton
        type="button"
        height={24}
        layout="solid"
        color="new"
        label="신규"
        onClick={() => {
          setSelectedRowKey(null);
          dataGridRef.current.instance.option("focusedRowIndex", -1);
          detailGrid.current.instance.option("editing.changes", []);
          reset(initialInputState);
        }}
      />
      <CustomButton
        type="button"
        height={24}
        layout="solid"
        color="new"
        label="복사"
        onClick={handleCopyButtonClick}
      />

      <CustomButton
        type="button"
        height={24}
        layout="solid"
        color="esc"
        label="취소"
        onClick={onClickOrderCancel}
      />

      <CustomButton
        type="button"
        height={24}
        layout="solid"
        color="esc"
        label="삭제"
        onClick={onClickOrderDelete}
      />
    </PanelBtn>
  );
};

export default YongchaOrderHeader;
