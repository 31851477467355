import { NumberBox } from "devextreme-react";
import React from "react";
import { Controller } from "react-hook-form";
import InputLayout from "./InputLayout";

function InputNumber({
  control,
  errors,
  name,
  label,
  labelWidth,
  flex,
  height,
  width,
  required,
  disabled = false,
  onValueChange = () => {},
  onInitialized = () => {},
  tabIndex,
  className,
}) {
  return (
    <InputLayout
      label={label}
      required={required}
      errors={errors}
      name={name}
      labelWidth={labelWidth}
    >
      <Controller
        render={({ field: { onChange, value } }) => {
          return (
            <NumberBox
              format="#,##0"
              className={className}
              onValueChanged={(value) => {
                onChange(value.value > 0 ? value.value : 0);
                onValueChange(value);
              }}
              value={parseInt(value)}
              disabled={disabled}
              height={height}
              width={width}
              max={1000000000}
              tabIndex={tabIndex}
              onInitialized={onInitialized}
              onFocusIn={(e) => {
                const target = e.event.target;
                setTimeout(() => (target.selectionEnd = 0), 0);
              }}
            />
          );
        }}
        control={control}
        name={name}
        defaultValue=""
      />
    </InputLayout>
  );
}

export default InputNumber;
