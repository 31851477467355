import { DataGrid } from "devextreme-react";
import {
  Column,
  FilterRow,
  Format,
  HeaderFilter,
  Scrolling,
  Selection,
} from "devextreme-react/data-grid";
import CustomStore from "devextreme/data/custom_store";
import moment from "moment";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";
import { sendRequest } from "../../../../../_metronic/_helpers/DataSoureHelpers";
import { InputDate, InputSelect } from "../../../../components/InputForm";
import {
  PanelGridSearchBar,
  SearchItems,
} from "../../../../components/Layout/Panel/PanelGrid/panelGrid.style";
import ModalContainer from "../../../../components/Modal/ModalContainer";
import { connectEtcCost } from "../AccountHistoryCrud";
import { getLookup } from "../AccountHistoryCrud";

const EtcCostModal = ({ onClose, maskClosable, visible, dataGrid }) => {
  const initialSearchState = {
    companyOid: "",
    firstDay: moment()
      .subtract(7, "days")
      .format("YYYY-MM-DD"),
    lastDay: moment().format("YYYY-MM-DD"),
  };
  const [lookupData, setLookupData] = useState({
    company: [],
  });
  const [search, setSearch] = useState(initialSearchState);
  const [totalCount, setTotalCount] = useState(0);
  const dataGridRef = useRef();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: initialSearchState,
  });

  useEffect(() => {
    const _initLookupData = async () => {
      const companyData = await getLookup("company");
      setLookupData({
        company: [
          {
            id: "",
            companyName: "전체",
          },
          ...companyData.data,
        ],
        inputCompany: companyData.data,
      });
    };

    _initLookupData();
  }, []);

  const dataSource = useMemo(() => {
    const apiUrl = `${process.env.REACT_APP_API_URL}/account-list/etc-cost`;

    return new CustomStore({
      key: "oid",
      load: () => sendRequest(apiUrl, "GET", search),
    });
  }, [search]);

  const onSubmit = (data) => {
    setSearch(data);
  };

  const onClickSend = async () => {
    const TransRefKey = dataGrid.current.instance.option("focusedRowKey");
    const selectedEtcCost = dataGridRef.current.instance.getSelectedRowsData();

    try {
      await connectEtcCost({
        TransRefKey: TransRefKey,
        selectedEtcCost: selectedEtcCost,
      });

      onClose();
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      <ModalContainer
        onClose={onClose}
        maskClosable={maskClosable}
        visible={visible}
        size="xl"
      >
        <div className="modal-form">
          <div className="modal-form-header">
            <div className="modal-form-tit">
              <span className="modal-tit">기타비용</span>
            </div>
            <button
              type="button"
              className="btn-modal btn-modal-close"
              onClick={onClose}
            >
              <SVG
                src={toAbsoluteUrl(
                  "/media/svg/icons/Custom/close_black_24dp.svg"
                )}
                className="close-icon"
              />
            </button>
          </div>

          <PanelGridSearchBar onSubmit={handleSubmit(onSubmit)}>
            <SearchItems>
              <label>업체구분</label>
              <InputSelect
                className="label-select"
                control={control}
                errors={errors}
                name="companyOid"
                displayExpr="companyName"
                valueExpr="id"
                dataSource={lookupData.company}
                flex={true}
              />
            </SearchItems>
            <SearchItems>
              <label>등록일</label>
              <InputDate
                className="label-select"
                control={control}
                errors={errors}
                name="firstDay"
                flex={true}
              />
              ~
              <InputDate
                className="label-select"
                control={control}
                errors={errors}
                name="lastDay"
                flex={true}
              />
            </SearchItems>
            <button type="submit" className="btn btn-grid-search single-line">
              검색
            </button>
          </PanelGridSearchBar>

          <div className="modal-form-body">
            <div className="grid-total">{`총 ${totalCount}건`}</div>
            <DataGrid
              className="datagrid-wrap single-line has-toolbar"
              id="AccountHistoryMaster"
              ref={dataGridRef}
              dataSource={dataSource}
              showRowLines={true}
              showBorders={true}
              hoverStateEnabled={true}
              allowColumnResizing={true}
              allowColumnReordering={true}
              columnResizingMode="widget"
              keyExpr="oid"
              focusedRowEnabled={true}
              onContentReady={(e) => setTotalCount(e.component.totalCount())}
            >
              <FilterRow visible={true} />
              <HeaderFilter visible={true} />
              <Scrolling
                columnRenderingMode="virtual"
                mode="virtual"
                showScrollbar="always"
                useNative="false"
              />
              <Selection
                mode="multiple"
                allowSelectAll={false}
                showCheckBoxesMode="always"
              />
              <Column dataField="companyName" caption="업체구분" />
              <Column
                dataField="regDate"
                caption="등록일"
                alignment="center"
                dataType="date"
                format="yyyy-MM-dd"
              />
              <Column
                dataField="companyName"
                caption="업체명"
                alignment="center"
              />
              <Column dataField="sales" caption="매출" alignment="center">
                <Format type="fixedPoint" />
              </Column>
              <Column dataField="purchase" caption="매입" alignment="center">
                <Format type="fixedPoint" />
              </Column>
              <Column dataField="remark01" caption="비고1" alignment="center" />
              <Column dataField="remark02" caption="비고2" alignment="center" />
              <Column
                dataField="AccountConection"
                caption="계좌연결여부"
                alignment="center"
              />
              <Column
                dataField="ActualPaymentDate"
                caption="실지급일"
                alignment="center"
              />
            </DataGrid>
          </div>
        </div>
        <div className="modal-form-footer order-modal">
          <button
            onClick={onClickSend}
            type="button"
            className="btn btn-modal btn-save"
          >
            확인
          </button>
        </div>
      </ModalContainer>
    </>
  );
};

export default EtcCostModal;
