import dayjs from "dayjs";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { shallowEqual, useSelector } from "react-redux";
import {
  PanelGridBtnBar,
  PanelGridSearchBar,
  PanelGridTopBar,
  SearchItems,
} from "../../../../components/Layout/Panel/PanelGrid/panelGrid.style";
import CustomButton from "../../../../components/FunctionButton/CustomButton";
import { ReactComponent as IconEdit } from "../../../../../style/icons/edit-note.svg";
import { InputDate, InputSelect } from "../../../../components/InputForm";

const LCLOrderSearch = ({ setSearch, lookupData, onClickToggle }) => {
  const { user } = useSelector(
    (state) => ({ user: state.auth.user.user }),
    shallowEqual
  );

  const initialState = {
    firstDay: dayjs(new Date())
      .subtract(30, "days")
      .format("YYYY-MM-DD"),
    lastDay: dayjs().format("YYYY-MM-DD"),
    receiveCo: user.companyCode,
    searchType: "3",
    authGroupId: `${user.authGroupId}`,
    dispatchTeam: `${user?.deptCode}` || "",
  };

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    reset(initialState);
    setSearch(initialState);
    // eslint-disable-next-line
  }, []);

  const onSubmit = (data) => {
    for (const obj in data) {
      if (data[obj] === "") {
        data[obj] = null;
      }
    }

    const submit = {
      ...data,
      authGroupId: user.authGroupId,
    };

    setSearch(submit);
  };

  return (
    <PanelGridTopBar>
      <PanelGridBtnBar>
        <CustomButton
          type="button"
          height={36}
          width="full"
          layout="icon"
          color="transp"
          onClick={onClickToggle}
        >
          <IconEdit />
        </CustomButton>
      </PanelGridBtnBar>
      <PanelGridSearchBar onSubmit={handleSubmit(onSubmit)}>
        <SearchItems>
          <label>접수법인</label>
          <InputSelect
            control={control}
            errors={errors}
            name="receiveCo"
            dataSource={lookupData.company}
            valueExpr="id"
            displayExpr="companyName"
            flex={true}
          />
        </SearchItems>
        <SearchItems>
          <label>배차팀</label>
          <InputSelect
            control={control}
            errors={errors}
            displayExpr="deptName"
            valueExpr="code"
            name="dispatchTeam"
            dataSource={lookupData.dispatch}
            flex={true}
          />
        </SearchItems>
        <SearchItems>
          <label>일정검색</label>
          <InputSelect
            control={control}
            errors={errors}
            name="searchType"
            displayExpr="name"
            valueExpr="id"
            dataSource={[
              { id: "1", name: "접수일자" },
              { id: "2", name: "선사반출기한" },
              { id: "3", name: "운송일자" },
            ]}
            flex={true}
          />
          <InputDate
            control={control}
            errors={errors}
            name="firstDay"
            flex={true}
          />
          <div className="to-mark">~</div>
          <InputDate
            control={control}
            errors={errors}
            name="lastDay"
            flex={true}
          />
        </SearchItems>

        <CustomButton
          type="submit"
          height={24}
          layout="solid"
          color="search"
          label="검색"
        />
      </PanelGridSearchBar>
    </PanelGridTopBar>
  );
};

export default LCLOrderSearch;
