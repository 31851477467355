import { DataGrid } from "devextreme-react";
import {
  Column,
  ColumnChooser,
  FilterRow,
  Format,
  HeaderFilter,
  Scrolling,
  StateStoring,
  Summary,
  TotalItem,
} from "devextreme-react/data-grid";
import CustomStore from "devextreme/data/custom_store";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { sendRequest } from "../../../../../_metronic/_helpers/DataSoureHelpers";
import { InputText } from "../../../../components/InputForm";
import {
  DataCount,
  PanelGridInit,
  PanelGridInput,
} from "../../../../components/Layout/Panel/PanelGrid/panelGrid.style";
import { PanelInput } from "../../../../components/Layout/Panel/PanelInput/panelInput.style";
import StateStoringSaveAndDeleteButton from "../../../../components/StateStoring";
import { getCompanyData } from "../ClosingCrud";

const ClosingMaster = ({
  search,
  closingLeftRef,
  selectedRow,
  setSelectedRow,
}) => {
  const storageKey = "closingMaster";
  const [totalCount, setTotalCount] = useState(0);

  const {
    control,
    setValue,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    getCompany(selectedRow);
    // eslint-disable-next-line
  }, [setValue, selectedRow]);

  const dataSource = useMemo(() => {
    const apiUrl = `${process.env.REACT_APP_API_URL}/finish-sell-manage`;

    return new CustomStore({
      key: "oid",
      load: () => sendRequest(apiUrl, "GET", search),
    });
  }, [search]);

  // 선택한 행의 회사 정보를 가져오는 비동기 함수
  async function getCompany(selectedRow) {
    try {
      const { comOid, division } = selectedRow;
      // 회사 데이터를 가져오는 비동기 함수 호출
      const { data } = await getCompanyData({ comOid, division });
      // 부서가 '기사'인 경우
      if (division === "기사") {
        // 회사명, 사업자 등록번호, 주소, 대표자명을 입력
        setValue("companyName", data[0]?.bizName);
        setValue("bizNo", data[0]?.bizNo);
        setValue("address", data[0]?.bizAddress);
        setValue("ceoName", data[0]?.ceoName);
      } else {
        // 부서가 '기사'가 아닌 경우
        // 회사명, 사업자 등록번호, 주소, 대표자명을 입력
        setValue("companyName", data[0]?.company);
        setValue("bizNo", data[0]?.bizNo);
        setValue("address", data[0]?.address);
        setValue("ceoName", data[0]?.ceoName);
      }
    } catch (error) {
      console.log(error);
    }
  }

  const onFocusedRowChanged = useCallback((e) => {
    if (!e.row) return;
    setSelectedRow(e.row.data);
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <PanelGridInit gridItems row gridHeight={89}>
        <StateStoringSaveAndDeleteButton
          dataGridRef={closingLeftRef}
          storageKey={storageKey}
        />
        <DataCount>{`총 ${totalCount}건`}</DataCount>
        <DataGrid
          className="datagrid-wrap multi-line"
          id="ClosingLeft"
          dataSource={dataSource}
          ref={closingLeftRef}
          showRowLines={true}
          showBorders={true}
          hoverStateEnabled={true}
          allowColumnResizing={true}
          allowColumnReordering={true}
          columnResizingMode="widget"
          keyExpr="oid"
          focusedRowEnabled={true}
          onFocusedRowChanged={onFocusedRowChanged}
          onContentReady={(e) => setTotalCount(e.component.totalCount())}
        >
          <FilterRow visible={true} />
          <HeaderFilter visible={true} />
          <ColumnChooser enabled={true} />
          <Scrolling mode="virtual" showScrollbar="always" useNative="false" />
          <StateStoring
            enabled={true}
            type="custom"
            customLoad={useCallback(() => {
              return JSON.parse(localStorage.getItem(storageKey));
            }, [])}
          />
          {/* <Column dataField="" caption="No" /> */}
          <Column dataField="division" caption="구분" width="50" />
          <Column
            dataField="companyName"
            caption="청구처/용차사/차량번호"
            width="134"
          />
          {/* 접수법인 */}
          {/* <Column dataField="receiveCoName" caption="접수법인" width="100" /> */}
          <Column
            dataField="total"
            caption="총건수"
            width="59"
            dataType="number"
          />
          <Column
            dataField="isNotFinish"
            caption="미마감"
            width="59"
            dataType="number"
          />
          <Column
            dataField="isFinish"
            caption="마감"
            width="59"
            dataType="number"
          />
          {/* 숫자포맷 */}
          <Column
            dataField="priceTotal"
            caption="합계금액"
            width="100"
            dataType="number"
          >
            <Format type="fixedPoint" />
          </Column>
          <Summary>
            <TotalItem
              column="priceTotal"
              summaryType="sum"
              valueFormat="fixedPoint"
              displayFormat="총 합계 : {0}"
            />
          </Summary>
        </DataGrid>
        <PanelGridInput height={11}>
          <PanelInput grid={2}>
            <InputText
              control={control}
              errors={errors}
              label="정산회사"
              name="companyName"
              disabled={true}
            />

            <InputText
              control={control}
              errors={errors}
              label="사업자등록번호"
              name="bizNo"
              disabled={true}
            />

            <InputText
              control={control}
              errors={errors}
              label="주소"
              name="address"
              disabled={true}
            />

            <InputText
              control={control}
              errors={errors}
              label="대표자"
              name="ceoName"
              disabled={true}
            />
          </PanelInput>
        </PanelGridInput>
      </PanelGridInit>
    </>
  );
};

export default ClosingMaster;
