import { yupResolver } from "@hookform/resolvers/yup";
import CustomStore from "devextreme/data/custom_store";
import React, { useMemo, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { shallowEqual, useSelector } from "react-redux";
import { sendRequest } from "../../../../_metronic/_helpers/DataSoureHelpers";
import ExtraChargeModal from "../../../components/ExtraChargeModal";
import HeaderTitle from "../../../components/Layout/Header/HeaderTitle";
import { PanelGridWrapper } from "../../../components/Layout/Panel/PanelGrid/panelGrid.style";
import { PanelSubInputWrapper } from "../../../components/Layout/Panel/PanelInput/panelInput.style";
import {
  PanelBody,
  PanelBtn,
  PanelHeader,
  PanelInit,
} from "../../../components/Layout/Panel/panel.style";
import NewCompanyModal from "../../Management/ComCompany/components/NewCompanyModal";
import NewForwarderModal from "../../Management/Forwarder/components/NewForwarderModal";
import NewTerminalModal from "../../Management/Terminal/components/NewTerminalModal";
import OrderHeader from "../OrderPage/components/OrderHeader";
import useExtraCharge from "../OrderPage/useExtraCharge";
import { getOrderDataByCode } from "./LCLOrderCrud";
import { getInitialInputState, schema } from "./LCLOrderInitialState";
import CreateLCLOrderModalDetail from "./component/CreateLCLOrderModalDetail";
import LCLOrderGrid from "./component/LCLOrderGrid";
import LCLOrderInput from "./component/LCLOrderInput";
import LCLOrderSearch from "./component/LCLOrderSearch";
import useLCLOrderPageLookup from "./useLCLOrderPageLookup";

const LCLOrderPage = () => {
  // 로딩여부
  const [isLoading, setLoading] = useState(false);
  // 확정여부
  const [isConfirm, setIsConfirm] = useState(false);
  // 클릭한 row 키값
  const [selectedRowKey, setSelectedRowKey] = useState(null);
  // input펼치기 여부
  const [openInputBar, setOpenInputBar] = useState(false);
  // mainGrid 검색
  const [search, setSearch] = useState({});

  // 마감된 데이터 수정
  const [isOrderEditable, setIsOrderEditable] = useState(false);

  // modal
  // 업체등록 모달
  const [companyCreateModal, setCompanyCreateModal] = useState(false);
  // 선사등록 모달
  const [forwarderCreateModal, setForwarderCreateModal] = useState(false);
  // 터미널/창고/작업지 등록 모달
  const [terminalCreateModal, setTerminalCreateModal] = useState(false);
  // 일괄적용 모달
  const [changeAllModal, setChangeAllModal] = useState(false);

  // mainGrid
  const dataGridRef = useRef();
  // inputDetailGrid
  const detailGrid = useRef();

  const { user } = useSelector(
    (state) => ({ user: state.auth.user.user }),
    shallowEqual
  );

  const initialState = getInitialInputState(user);

  const inputForm = useForm({
    resolver: yupResolver(schema),
    defaultValues: initialState,
  });

  const { reset } = inputForm;

  // lookupData 커스텀 훅
  const {
    // search
    orderSearchLookupData,

    // input
    conType,
    orderInputLookupData,
    initOrderInputLookupData,

    // input detail
    orderInputDetailLookup,
    initOrderInputDetailLookupData,
  } = useLCLOrderPageLookup();

  // 할증등록 관련 변수, 함수 커스텀 훅
  const extraChargeFunctions = useExtraCharge(detailGrid, isConfirm);
  const {
    state,
    extraChargeModal,
    extraChargeRef,
    etcChargeHabulRef,
    onExtraChargeSubmit,
    onClose,
  } = extraChargeFunctions;

  const initInputData = async (key) => {
    setIsOrderEditable(false);
    try {
      // db에서 데이터 불러오기
      setLoading(true);
      if (key) {
        const { data: result } = await getOrderDataByCode(key);

        const { data, finishInfoDetail } = result;

        // 여기서 클릭한 오더가 확정인지 아닌지 확인
        if (data?.confirm === true) setIsConfirm(true);
        else setIsConfirm(false);

        if (finishInfoDetail.completeNo) setIsOrderEditable(true);

        reset(data);
      } else {
        setIsConfirm(false);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const orderDetailDataSource = useMemo(() => {
    const apiUrl = `${process.env.REACT_APP_API_URL}/lclorder/modal/detail`;
    return new CustomStore({
      key: "id",
      load: () =>
        sendRequest(apiUrl, "GET", {
          orderCode: selectedRowKey ? selectedRowKey : "999999999",
        }),
    });
  }, [selectedRowKey]);

  return (
    <>
      <PanelInit>
        <PanelHeader>
          <HeaderTitle />
          <PanelBtn>
            {openInputBar && (
              <OrderHeader
                dataGridRef={dataGridRef}
                detailGrid={detailGrid}
                inputForm={inputForm}
                isConfirm={isConfirm}
                selectedRowKey={selectedRowKey}
                setSelectedRowKey={setSelectedRowKey}
                initInputData={initInputData}
              />
            )}
          </PanelBtn>
        </PanelHeader>

        <PanelBody
          row
          left="30%"
          right="70%"
          column
          top="65%"
          bottom="35%"
          openInputBar={openInputBar}
        >
          <LCLOrderInput
            selectedRowKey={selectedRowKey}
            openInputBar={openInputBar}
            isLoading={isLoading}
            dataGridRef={dataGridRef}
            detailGrid={detailGrid}
            inputForm={inputForm}
            conType={conType}
            lookupData={orderInputLookupData}
            initInputData={initInputData}
            setLoading={setLoading}
            isConfirm={isConfirm}
            setCompanyCreateModal={setCompanyCreateModal}
            setForwarderCreateModal={setForwarderCreateModal}
            setTerminalCreateModal={setTerminalCreateModal}
            isOrderEditable={isOrderEditable}
          />

          <PanelGridWrapper>
            {/* 페이지 메인그리드 검색 */}
            <LCLOrderSearch
              setSearch={setSearch}
              lookupData={orderSearchLookupData}
              onClickToggle={() => setOpenInputBar(!openInputBar)}
            />

            {/* 페이지 메인그리드 */}

            <LCLOrderGrid
              search={search}
              dataGridRef={dataGridRef}
              detailGrid={detailGrid}
              detailDataSource={orderDetailDataSource}
              setSelectedRowKey={setSelectedRowKey}
            />
          </PanelGridWrapper>

          <PanelSubInputWrapper fullsize openInputBar={openInputBar}>
            <CreateLCLOrderModalDetail
              dataSource={orderDetailDataSource}
              inputForm={inputForm}
              isConfirm={isConfirm}
              detailGrid={detailGrid}
              extraChargeFunctions={extraChargeFunctions}
              setTerminalCreateModal={setTerminalCreateModal}
              setChangeAllModal={setChangeAllModal}
              lookupData={orderInputDetailLookup}
            />
          </PanelSubInputWrapper>
        </PanelBody>
      </PanelInit>
      {extraChargeModal && (
        <ExtraChargeModal
          onClose={onClose}
          visible={true}
          maskClosable={false}
          state={state}
          extraChargeRef={extraChargeRef}
          etcChargeHabulRef={etcChargeHabulRef}
          onExtraChargeSubmit={onExtraChargeSubmit}
        />
      )}
      {companyCreateModal && (
        <NewCompanyModal
          onClose={() => {
            setCompanyCreateModal(false);
            initOrderInputLookupData();
          }}
          closable={true}
          visible={true}
          maskClosable={false}
        />
      )}
      {forwarderCreateModal && (
        <NewForwarderModal
          onClose={() => {
            setForwarderCreateModal(false);
            initOrderInputLookupData();
          }}
          closable={true}
          visible={true}
          maskClosable={false}
        />
      )}
      {terminalCreateModal && (
        <NewTerminalModal
          onClose={() => {
            setTerminalCreateModal(false);
            initOrderInputLookupData();
            initOrderInputDetailLookupData();
          }}
          closable={true}
          visible={true}
          maskClosable={false}
        />
      )}
    </>
  );
};

export default LCLOrderPage;
