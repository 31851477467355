import React from "react";
import { shallowEqual, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { PanelTitle } from "../Panel/panel.style";

const HeaderTitle = () => {
  const { menuAll, openItem } = useSelector(
    (state) => ({
      menuAll: state.menu.menuAll,
      openItem: state.menu.openItem,
    }),
    shallowEqual
  );

  return (
    <>
      <PanelTitle>
        <div>{openItem?.menuName}</div>
        <ul>
          <li>
            <Link to="/">
              <i className="flaticon2-shelter icon-1x" />
            </Link>
          </li>
          {openItem?.parentId && (
            <li>
              {
                menuAll.find((menu) => {
                  return menu.menuId === openItem?.parentId;
                })?.menuName
              }
            </li>
          )}
          {openItem?.menuName && <li>{openItem?.menuName}</li>}
        </ul>
      </PanelTitle>
    </>
  );
};

export default HeaderTitle;
