import axios from "axios";

const apiUrl = `${process.env.REACT_APP_API_URL}/non-payment/memo`;

export async function postMemo(data) {
  return await axios.post(apiUrl, data);
}

export async function putMemo(data) {
  return await axios.put(apiUrl, data);
}

export async function deleteMemo(key) {
  return await axios.delete(apiUrl + `/${key}`);
}
