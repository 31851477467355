import { DataGrid } from "devextreme-react";
import { Column, Scrolling } from "devextreme-react/data-grid";
import CustomStore from "devextreme/data/custom_store";
import React, { useMemo, useRef } from "react";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import { sendRequest } from "../../../_metronic/_helpers/DataSoureHelpers";
import { ModalContainer } from "../Modal";

const LogModal = ({ onClose, type, visible, maskClosable, rowData = {} }) => {
  if (!(rowData.key || rowData.id)) {
    return (
      <ModalContainer
        onClose={onClose}
        maskClosable={maskClosable}
        visible={visible}
        size="sm"
      >
        <form className="modal-form">
          <div className="modal-form-header">
            <div className="modal-form-tit">
              <span className="modal-tit">데이터를 선택해주세요</span>
            </div>
            <button className="btn-modal btn-modal-close" onClick={onClose}>
              <SVG
                type="button"
                src={toAbsoluteUrl(
                  "/media/svg/icons/Custom/close_black_24dp.svg"
                )}
                className="close-icon"
              />
            </button>
          </div>
        </form>
      </ModalContainer>
    );
  }
  return (
    <ModalContainer
      onClose={onClose}
      maskClosable={maskClosable}
      visible={visible}
      size="xl"
    >
      <form className="modal-form">
        <div className="modal-form-header">
          <div className="modal-form-tit">
            <span className="modal-tit">내역확인</span>
          </div>
          <button className="btn-modal btn-modal-close" onClick={onClose}>
            <SVG
              type="button"
              src={toAbsoluteUrl(
                "/media/svg/icons/Custom/close_black_24dp.svg"
              )}
              className="close-icon"
            />
          </button>
        </div>

        <div className="modal-form-body">
          {type === "master" && <OrderMasterGrid rowData={rowData} />}
          {type === "detail" && <OrderDetailGrid rowData={rowData} />}
          {type === "dispatch" && <DispatchGrid rowData={rowData} />}
        </div>
      </form>
    </ModalContainer>
  );
};

const OrderMasterGrid = ({ rowData }) => {
  const { key } = rowData;
  const dataSource = useMemo(() => {
    const apiUrl = `${process.env.REACT_APP_API_URL}/logger/master`;

    return new CustomStore({
      key: "oid",
      load: () => sendRequest(apiUrl, "GET", { key }),
    });
  }, [key]);
  const gridRef = useRef();
  return (
    <DataGrid
      ref={gridRef}
      dataSource={dataSource}
      showRowLines={true}
      showBorders={true}
      allowColumnResizing={true}
      columnAutoWidth={true}
      height={500}
    >
      <Scrolling mode="virtual" showScrollbar="always" useNative="false" />
      <Column dataField="crudDivision" caption="구분" />
      <Column
        dataField="time"
        caption="시간"
        dataType="datetime"
        format="yyyy-MM-dd HH:mm:ss"
      />
      <Column dataField="subjectUser" caption="사용자" />
      <Column dataField="code" caption="접수번호" />
      <Column dataField="importExport" caption="수입/수출" />
      <Column dataField="receiveCo" caption="접수법인" />
      <Column dataField="receiveDate" caption="접수일자" />
      <Column dataField="dispatchTeam" caption="배차팀" />
      <Column dataField="billingPlace" caption="청구처" />
      <Column dataField="orderCo" caption="발주처" />
      <Column dataField="forwarder" caption="선사" />
      <Column dataField="shipName" caption="선명" />
      <Column dataField="motherShip" caption="모선/항차" />
      <Column dataField="dem" caption="선사반출기한" />
      <Column dataField="manager" caption="청구처담당자" />
      <Column
        dataField="enterDay"
        caption="입항일자"
        dataType="datetime"
        format="yyyy-MM-dd HH:mm:ss"
      />
      <Column
        dataField="sailDay"
        caption="출항일자"
        dataType="datetime"
        format="yyyy-MM-dd HH:mm:ss"
      />
      <Column dataField="doNo" caption="DO No" />
      <Column dataField="blNo" caption="BL No" />
      <Column dataField="blType" caption="BL 구분" />
      <Column dataField="loadPlace" caption="상차지" />
      <Column dataField="unloadPlace" caption="하차지" />
      <Column dataField="cargoOwner" caption="화주" />
      <Column dataField="realCargoOwner" caption="실화주" />
      <Column dataField="otherDeliver" caption="타법인 배차" />
      <Column dataField="color" caption="색상" />
      <Column dataField="fridge" caption="냉동" />
      <Column dataField="danger" caption="위험물" />
      <Column dataField="tank" caption="탱크" />
      <Column dataField="direct" caption="직반입" />
      <Column dataField="toxic" caption="유해화학물질" />
      <Column dataField="keep" caption="보관" />
      <Column dataField="remark" caption="비고" />
      <Column dataField="innerMemo" caption="내부메모" />
      <Column dataField="confirm" caption="오더확정" />
    </DataGrid>
  );
};

const OrderDetailGrid = ({ rowData }) => {
  const { key } = rowData;
  const dataSource = useMemo(() => {
    const apiUrl = `${process.env.REACT_APP_API_URL}/logger/detail`;

    return new CustomStore({
      key: "oid",
      load: () => sendRequest(apiUrl, "GET", { key }),
    });
  }, [key]);

  const gridRef = useRef();
  return (
    <DataGrid
      ref={gridRef}
      dataSource={dataSource}
      showRowLines={true}
      showBorders={true}
      allowColumnResizing={true}
      columnAutoWidth={true}
      height={500}
    >
      <Scrolling mode="virtual" showScrollbar="always" useNative="false" />
      <Column
        dataField="deliverDate"
        caption="운송(요청)일자"
        dataType="date"
        format="yyyy-MM-dd"
      />
      <Column dataField="containerNo" caption="컨테이너번호" />
      <Column dataField="containerType" caption="컨/규격" />
      <Column dataField="sealNo" caption="Seal No" />
      <Column dataField="workWaypoint" caption="작업경유지" />
      <Column dataField="collectWaypoint" caption="회수경유지" />
      <Column dataField="workPlace" caption="작업지" />
      <Column dataField="workTime" caption="작업시간" />
      <Column dataField="manager" caption="도착지담당자" />
      <Column dataField="pointManageContact" caption="도착지담당자연락처" />
      <Column dataField="freight" caption="청구금액" />
      <Column dataField="cargoWeight" caption="화물중량" />
      <Column dataField="returnDate" caption="반납마감일자" />
      <Column dataField="freightDate" caption="청구일자" />
      <Column dataField="bobtail" caption="밥테일" />
      <Column dataField="extraInfo" caption="특이사항" />
      <Column dataField="temperature" caption="온도" />
      <Column dataField="humidity" caption="습도" />
      <Column dataField="vent" caption="환풍구" />
      <Column dataField="class" caption="클래스" />
      <Column dataField="unNo" caption="UN넘버" />
    </DataGrid>
  );
};
const DispatchGrid = () => {
  const gridRef = useRef();
  return (
    <DataGrid
      ref={gridRef}
      dataSource={[]}
      showRowLines={true}
      showBorders={true}
      allowColumnResizing={true}
      columnAutoWidth={true}
      height={500}
    >
      <Scrolling mode="virtual" showScrollbar="always" useNative="false" />
      <Column dataField="preset" caption="DispatchGrid" />
    </DataGrid>
  );
};

export default LogModal;
