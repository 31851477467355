import React, { useState, useRef } from "react";
import { useForm } from "react-hook-form";
import DriverMaster from "./DriverMaster";
import * as requestServer from "./DriverCrud";
import { Link } from "react-router-dom";
import { confirm, alert } from "devextreme/ui/dialog";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { shallowEqual, useSelector } from "react-redux";
import { Button } from "devextreme-react/button";
import { SelectModal } from "../../../../_metronic/_partials/modals/SelectModal";

import InputTel from "../../../components/InputForm/InputTel";
import InputText from "../../../components/InputForm/InputText";
import InputDate from "../../../components/InputForm/InputDate";
import InputCheckBox from "../../../components/InputForm/InputCheckBox";
import FunctionButton from "../../../components/FunctionButton/FunctionButton";

function DriverPage(props) {
  // max값 checkbox, yup, date validation, 등등
  const initialState = {
    driverId: "",
    driverName: "",
    birth: "",
    tel: "",
    fax: "",
    isLeave: false,
    remark: "",
  };
  const [selectedRowKey, setSelectedRowKey] = useState();
  const [selectModal, setSelectModal] = useState(false);
  // const [inputDisable, setInputDisable] = useState(false);
  const dataGridRef = useRef();
  const { menuAll, openItem } = useSelector(
    (state) => ({
      menuAll: state.menu.menuAll,
      openItem: state.menu.openItem,
    }),
    shallowEqual
  );

  const schema = yup
    .object({
      driverName: yup
        .string()
        .required("기사명을 입력하세요.")
        .max(20, "최대 20자리 까지 입력 가능"),
      birth: yup
        .string()
        // .required("생일을 입력하세요")
        .max(8, "최대 8자리 까지 입력 가능"),
      tel: yup
        .string()
        .required("생일을 입력하세요")
        .max(13, "최대 13자리 까지 입력 가능"),
      fax: yup.string().max(13, "최대 13자리 까지 입력 가능"),
      isLeave: yup.bool(),
      remark: yup
        .string()
        .nullable()
        .max(100, "최대 100자리 까지 입력 가능"),
    })
    .required();

  const {
    handleSubmit,
    reset,
    control,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onFocusedRowChanged = (e) => {
    if (!e.row) return;
    console.log(e.row.data);
    const data = e.row.data;
    const joinData = data.Biz_No_Sys_CompanyTax_Info;
    setSelectedRowKey(e.row.key);
    // setInputDisable(true);
    reset({ ...data, ...joinData });
  };

  const onClickAdd = () => {
    if (!dataGridRef.current) return;
    reset(initialState);
    setSelectedRowKey(null);
    // setInputDisable(false);
    dataGridRef.current.instance.option("focusedRowIndex", -1);
  };

  const onSubmit = async (data) => {
    delete data.id;
    delete data.password;
    delete data.createIP;
    delete data.createUserId;
    delete data.createdAt;
    delete data.updateIP;
    delete data.updateUserId;
    delete data.updatedAt;
    data.birth = data.birth.replaceAll("-", "");

    try {
      if (selectedRowKey) {
        await requestServer.updateDriver(selectedRowKey, data);
      } else {
        await requestServer.createDriver(data);
      }
      dataGridRef.current.instance.refresh();
      reset(initialState);
      setSelectedRowKey(null);
      dataGridRef.current.instance.option("focusedRowIndex", -1);
    } catch (err) {
      alert(err.response.data.message, "오류");
    }
  };

  const onClickDelete = () => {
    if (!dataGridRef.current || !selectedRowKey) return;
    let response = confirm("<i>삭제하시겠습니까?</i>", "삭제 메세지");

    response.then((res) => {
      if (!res) return;
      requestServer
        .deleteRow(selectedRowKey)
        .then(() => {
          dataGridRef.current.instance.refresh();
        })
        .catch((err) => {
          alert(err.response.data.message, "오류");
        });
    });
  };

  const closeModal = () => {
    setSelectModal(false);
    dataGridRef.current.instance.refresh();
  };

  const onSelect = (rowData) => {
    setValue("Company_Name", rowData.Company_Name);
    setValue("Biz_No", rowData.Biz_No);

    setSelectModal(false);
  };

  return (
    <>
      <div className="page-wrap">
        {/* 왼쪽 사이드 섹션 */}
        <div className="column-left fluid-left">
          {/* 페이지 헤더 */}
          <div className="panel-header flex-row">
            <div className="panel-header-init">
              <div className="panel-header-tit">{openItem?.menu?.menuName}</div>
              <ul className="breadcrumb-list">
                <li className="breadcrumb-item">
                  <Link to="/">
                    <i className="flaticon2-shelter icon-1x" />
                  </Link>
                </li>
                {openItem?.menu?.parentId && (
                  <li className="breadcrumb-item">
                    {
                      menuAll.find((menu) => {
                        return menu.menuId === openItem?.menu?.parentId;
                      })?.menu?.menuName
                    }
                  </li>
                )}
                {openItem?.menu?.menuName && (
                  <li className="breadcrumb-item current">
                    {openItem?.menu?.menuName}
                  </li>
                )}
              </ul>
            </div>
            <FunctionButton
              onClickAdd={onClickAdd}
              onClickDelete={onClickDelete}
            />
          </div>

          {/* 페이지 메인그리드 */}
          <DriverMaster
            onFocusedRowChanged={onFocusedRowChanged}
            dataGridRef={dataGridRef}
          />
        </div>

        {/* 그리드 인풋 너비 고정 */}
        <div className="column-right fixed-right">
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="form-container column-input"
          >
            <div className="form-tit">기사 등록</div>
            {/* <InputText control={control} errors={errors} name="driverId" label="기사 ID" required={true} disabled={inputDisable} /> */}
            <InputText
              control={control}
              errors={errors}
              name="driverName"
              label="기사명"
              required={true}
            />
            <InputDate
              control={control}
              errors={errors}
              name="birth"
              label="생년월일"
            />
            <InputTel
              control={control}
              errors={errors}
              name="tel"
              label="연락처"
              required={true}
            />
            <InputTel
              control={control}
              errors={errors}
              name="fax"
              label="팩스번호"
            />
            <InputCheckBox
              control={control}
              errors={errors}
              name="isLeave"
              label="퇴사여부"
              text="퇴사"
            />

            {/* 세금계산서 정보 */}
            <div className="form-row row-button">
              <InputText
                control={control}
                errors={errors}
                name="Company_Name"
                label="회사명"
                disabled={true}
              />
              <Button
                onClick={() => setSelectModal(true)}
                className="button-dis"
                icon="find"
              />
            </div>
            <InputText
              control={control}
              errors={errors}
              name="Biz_No"
              label="사업자번호"
              mask="999-99-99999"
              disabled={true}
            />

            <InputText
              control={control}
              errors={errors}
              name="remark"
              label="비고"
            />
            <button type="submit" className="btn btn-form-save">
              저장
            </button>
          </form>
        </div>
      </div>
      {selectModal && (
        <SelectModal
          onClose={closeModal}
          closable={true}
          visible={true}
          maskClosable={false}
          onSelect={onSelect}
          param="/Sys_CompanyTax_Info"
          keyExpr="Biz_No"
        />
      )}
    </>
  );
}

export default DriverPage;
