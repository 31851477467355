import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import {
  PanelBody,
  PanelBtn,
  PanelHeader,
  PanelInit,
} from "../../../components/Layout/Panel/panel.style";
import InputDate from "../../../components/InputForm/InputDate";
import InputText from "../../../components/InputForm/InputText";
import InputSelect from "../../../components/InputForm/InputSelect";
import HeaderTitle from "../../../components/Layout/Header/HeaderTitle";
import CustomButton from "../../../components/FunctionButton/CustomButton";
import {
  PanelGridSearchBar,
  PanelGridWrapper,
  PanelMultiGrid,
  SearchItems,
} from "../../../components/Layout/Panel/PanelGrid/panelGrid.style";
import { useForm } from "react-hook-form";
import { getLookup } from "./CarClosingCrud";
import CarClosingUpper from "./components/CarClosingUpper";
import CarClosingLower from "./components/CarClosingLower";
import TaxIssueModal from "./components/TaxIssueModal";
import TaxIssueModalDriver from "./components/TaxIssueModalDriver";
import CarDeadlineInput from "./components/CarDeadlineInput";
import useCarDeadline from "./useCarDeadline";
import PrintModal from "./components/PrintModal";
import MailingModal from "./components/MailingModal";
import * as requestServer from "./CarClosingCrud";
import { shallowEqual, useSelector } from "react-redux";

const CarClosingPage = () => {
  const carClosingUpperRef = useRef();
  const carClosingLowerRef = useRef();
  const {
    state,

    dataGridRef,
    inputGridRef,
    inputEtcGridRef,

    inputForm,
    searchForm,
    selectedRow,
    setSelectedRow,

    pageFunctions,
    inputFunctions,
    masterFunctions,
  } = useCarDeadline(carClosingUpperRef);

  const { onClickAdd } = pageFunctions;

  const [search, setSearch] = useState({});
  const [lookupData, setLookupData] = useState({
    company: [],
  });

  const [taxIssueModalVisible, setTaxIssueModalVisible] = useState(false);
  const [taxIssueModalDriverVisible, setTaxIssueModalDriverVisible] = useState(
    false
  );
  const [printModalVisible, setPrintModalVisible] = useState(false);
  const [openMailingModal, setOpenMailingModal] = useState(false);
  const [mailPdf, setMailPdf] = useState();

  /* #region useForm */
  const initialState = {
    receiveCo: "0",
    firstDay: moment()
      .subtract(14, "days")
      .format("YYYY-MM-DD"),
    lastDay: moment().format("YYYY-MM-DD"),
    carNo: "",
  };

  const {
    watch,
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: initialState,
  });
  /* #endregion */

  /* #region useEffect */
  useEffect(() => {
    getLookupData();
    setSearch(initialState);
    // eslint-disable-next-line
  }, []);
  /* #endregion */

  async function getLookupData() {
    try {
      const dispatchTeamData = await getLookup("department/isDispatch");
      const companyData = await getLookup("company");

      setLookupData({
        company: [
          {
            id: "0",
            companyName: "전체",
          },
          ...companyData.data,
        ],
        disaptchName: [
          {
            code: "0",
            deptName: "전체",
          },
          ...dispatchTeamData.data,
        ],
      });
    } catch (err) {
      console.log(err);
    }
  }

  const openModal = () => {
    if (!selectedRow.completeNo) {
      alert("데이터를 선택해주세요", "오류");
      return;
    }

    setTaxIssueModalVisible(true);
  };

  const openModalDriver = () => {
    if (!selectedRow.completeNo) {
      alert("데이터를 선택해주세요", "오류");
      return;
    }
    setTaxIssueModalDriverVisible(true);
  };

  const { user } = useSelector(
    (state) => ({ user: state.auth.user.user }),
    shallowEqual
  );

  const userName = user.userName;

  const openPrintModal = () => {
    if (!selectedRow.completeNo) {
      alert("데이터를 선택해주세요", "오류");
      return;
    }

    const selectedRowsData = carClosingUpperRef.current.instance.getSelectedRowsData();

    for (var i = 0; i < selectedRowsData.length; i++) {
      requestServer.exportExcelRowsData(selectedRowsData[i], userName);
    }

    // if (
    //   selectedRow.closeStatus === "정산" ||
    //   selectedRow.closeStatus === "예약발행"
    // ) {
    //   // 정산된 데이터
    //   setPrintModalVisible(true);
    // } else {
    //   // 정산되지않은 데이터
    //   setPrintModalVisible(true);
    // }
  };

  const resetSelectedRow = () => {
    setSelectedRow({});
    carClosingUpperRef.current.instance.option("focusedRowIndex", -1);
  };

  return (
    <>
      <PanelInit>
        <PanelHeader>
          <HeaderTitle />
          <PanelBtn>
            <CustomButton
              type="button"
              height={24}
              layout="solid"
              color="common"
              label="기사 발행"
              onClick={openModalDriver}
            />
            <CustomButton
              type="button"
              height={24}
              layout="solid"
              color="common"
              label="업체 발행"
              onClick={openModal}
            />
            <CustomButton
              type="button"
              height={24}
              layout="solid"
              color="common"
              label="출력"
              onClick={openPrintModal}
            />
          </PanelBtn>
        </PanelHeader>

        <PanelBody row left="550px" right="calc(100% - 550px)">
          {/* 왼쪽 사이드 섹션 */}
          <CarDeadlineInput
            state={state}
            selectedRow={selectedRow}
            inputGridRef={inputGridRef}
            inputEtcGridRef={inputEtcGridRef}
            inputForm={inputForm}
            inputFunctions={inputFunctions}
          />

          {/* 오른쪽 섹션 */}
          <PanelGridWrapper>
            {/* 검색 섹션 */}
            <PanelGridSearchBar
              onSubmit={handleSubmit((data) => setSearch(data))}
            >
              <SearchItems>
                <label>마감일자</label>
                <InputDate
                  className="label-select"
                  control={control}
                  errors={errors}
                  name="firstDay"
                  flex={true}
                />
                ~
                <InputDate
                  className="label-select"
                  control={control}
                  errors={errors}
                  name="lastDay"
                  flex={true}
                />
              </SearchItems>

              <SearchItems>
                <label>접수법인</label>
                <InputSelect
                  className="label-select"
                  control={control}
                  errors={errors}
                  name="receiveCo"
                  displayExpr="companyName"
                  valueExpr="id"
                  dataSource={lookupData.company}
                  flex={true}
                />
              </SearchItems>

              <SearchItems>
                <label>차량번호</label>
                <InputText
                  className="label-select"
                  control={control}
                  errors={errors}
                  name="carNo"
                  flex={true}
                />
              </SearchItems>
              <CustomButton
                type="submit"
                height={24}
                layout="solid"
                color="search"
                label="검색"
              />
            </PanelGridSearchBar>

            {/* 중앙 그리드 */}
            <PanelMultiGrid column top="60%" bottom="40%">
              {/* 상위 그리드 */}
              <CarClosingUpper
                search={search}
                carClosingUpperRef={carClosingUpperRef}
                masterFunctions={masterFunctions}
              />
              {/* 하위 그리드 */}
              <CarClosingLower
                search={search}
                carClosingLowerRef={carClosingLowerRef}
                selectedRow={selectedRow}
              />
            </PanelMultiGrid>
          </PanelGridWrapper>
        </PanelBody>
      </PanelInit>
      {/* 세금계산서 모달 업체발행 */}
      {taxIssueModalVisible && (
        <TaxIssueModal
          onClose={() => {
            resetSelectedRow();
            setTaxIssueModalVisible(false);
          }}
          closable={true}
          visible={true}
          maskClosable={false}
          selectedRow={selectedRow}
          finishUpperRef={carClosingUpperRef}
        />
      )}
      {/* 세금계산서 모달 기사발행 */}
      {taxIssueModalDriverVisible && (
        <TaxIssueModalDriver
          onClose={() => {
            resetSelectedRow();
            setTaxIssueModalDriverVisible(false);
          }}
          closable={true}
          visible={true}
          maskClosable={false}
          selectedRow={selectedRow}
          finishUpperRef={carClosingUpperRef}
        />
      )}
      {printModalVisible && (
        <PrintModal
          onClose={() => {
            resetSelectedRow();
            setPrintModalVisible(false);
          }}
          closable={true}
          visible={true}
          maskClosable={false}
          searchObj={watch()}
          selectedRow={selectedRow}
          finishUpperRef={carClosingUpperRef}
          setOpenMailingModal={setOpenMailingModal}
          setMailPdf={setMailPdf}
        />
      )}
      {openMailingModal && (
        <MailingModal
          onClose={() => setOpenMailingModal(false)}
          selectedRowData={selectedRow}
          searchObj={watch()}
          closable={true}
          visible={true}
          maskClosable={false}
          mailPdf={mailPdf}
        />
      )}
    </>
  );
};

export default CarClosingPage;
