import { yupResolver } from "@hookform/resolvers/yup";
import { alert } from "devextreme/ui/dialog";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import SVG from "react-inlinesvg";
import * as yup from "yup";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";
import InputAddress from "../../../../components/InputForm/InputAddress";
import InputDate from "../../../../components/InputForm/InputDate";
import InputNumber from "../../../../components/InputForm/InputNumber";
import InputTel from "../../../../components/InputForm/InputTel";
import InputText from "../../../../components/InputForm/InputText";
import InputRadioGroup from "../../../../components/InputForm/InputRadioGroup";
import InputSelect from "../../../../components/InputForm/InputSelect";
import {
  createRowData,
  getLookup,
  getRowDataById,
  updateRowData,
} from "../ComCompanyCrud";
import InputCheckBox from "../../../../components/InputForm/InputCheckBox";
import InputTextArea from "../../../../components/InputForm/InputTextArea";
import { ModalContainer } from "../../../../components/Modal";

const NewCompanyModal = ({
  onClose,
  maskClosable,
  visible,
  rowData,
  dataGrid,
  oid,
  type,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [lookupData, setLookupData] = useState({
    custoDivision: [],
    creditClass: [],
    actStatus: [],
    company: [],
    agency: [],
    department: [],
    assignWorker: [],
    kindBiz: [],
    bank: [],
    payCycle: [],
  });

  /* #region useForm */

  const initialState = {
    company: "",
    custoDivision: "",
    tel: "",
    remark: "",

    agencyPrice: null,
    viewRank: null,
    nCollectPrice: null,
    nGivenPrice: null,

    creditClass: null,
    assignWorker: null,
    agency: null,
    actStatus: null,
    kindBiz: null,
    bank: null,
    payCycle: null,
  };

  const schema = yup.object().shape({
    company: yup.string().required("회사명을 입력해주세요"),
    custoDivision: yup.string().required("거래처 분류를 선택해주세요"),
    // conCompany: yup.string().required("계약회사를 선택해주세요"),
    tel: yup.string().required("대표전화를 입력해주세요"),
  });

  const {
    control,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: initialState,
  });

  useEffect(() => {
    if (oid) {
      // 여기서 oid를 이용해서 dbFetch
      getData(oid);
    }
    reset();
    type && setValue(type, true);

    getLookupData();
    // eslint-disable-next-line
  }, [type]);

  /* #endregion */

  /* #region 함수 */
  const onSubmit = async (data) => {
    setIsLoading(true);
    try {
      if (oid) {
        // update
        const response = await updateRowData(oid, data);
        if (response?.data?.message) alert(response?.data?.message, "오류");
      } else {
        // create
        const response = await createRowData(data);
        if (response?.data?.message) alert(response?.data?.message, "오류");
      }

      dataGrid && dataGrid.current.instance.refresh();
      onClose();
    } catch (err) {
      console.log(err);
      alert("오류가 생겼습니다", "오류");
    } finally {
      setIsLoading(false);
    }
  };
  const onError = (err) => {
    console.log(err);
  };

  const openPost = () => {
    window.daum.postcode.load(() => {
      const postcode = new window.daum.Postcode({
        oncomplete: function(data) {
          let addr = "";
          let extraAddr = "";

          if (data.userSelectedType === "R") {
            addr = data.address;
          } else {
            addr = data.jibunAddress;
          }

          if (data.userSelectedType === "R") {
            if (data.bname !== "" && /[동|로|가]$/g.test(data.bname)) {
              extraAddr += data.bname;
            }
            if (data.buildingName !== "" && data.apartment === "Y") {
              extraAddr +=
                extraAddr !== "" ? ", " + data.buildingName : data.buildingName;
            }
            if (extraAddr !== "") {
              extraAddr = " (" + extraAddr + ")";
            }
            addr += extraAddr;
          }

          setValue("zipCode", data.zonecode);
          setValue("address", addr);
        },
      });
      postcode.open();
    });
  };

  const getData = async (oid) => {
    try {
      const data = await getRowDataById(oid);

      const resetData = data.data[0];
      for (const key in resetData) {
        if (resetData[key] === null) resetData[key] = undefined;
      }

      reset(resetData);
    } catch (err) {
      console.log(err);
    }
  };

  const getLookupData = async () => {
    try {
      const creditClassData = await getLookup("comcode/detail/20100");
      const custoDivisionData = await getLookup("comcode/detail/20200");
      const actStatusData = await getLookup("comcode/detail/20600");
      const companyData = await getLookup("company");
      const agencyData = await getLookup("comcode/detail/20500");
      const departmentData = await getLookup("department/lookup");
      const assignWorkerData = await getLookup("user-list/master");
      const kindBizData = await getLookup("comcode/detail/20700");
      const bankData = await getLookup("comcode/detail/60100");
      const payCycleData = await getLookup("comcode/detail/10650");

      setLookupData({
        custoDivision: [
          { subCode: "", subName: "(선택)" },
          ...custoDivisionData.data,
        ],
        creditClass: [
          { subCode: null, subName: "없음" },
          ...creditClassData.data,
        ],
        actStatus: [
          { subCode: null, subName: "(선택)" },
          ...actStatusData.data,
        ],
        company: companyData.data,
        agency: [{ subCode: null, subName: "없음" }, ...agencyData.data],
        department: [{ code: "", deptName: "(전체)" }, ...departmentData.data],
        assignWorker: [
          { id: null, userName: "(미정)" },
          ...assignWorkerData.data,
        ],
        kindBiz: [{ subCode: null, subName: "(선택)" }, ...kindBizData.data],
        bank: [{ subCode: null, subName: "(선택)" }, ...bankData.data],
        payCycle: [{ subCode: null, subName: "(선택)" }, ...payCycleData.data],
      });
    } catch (err) {
      console.log(err);
    }
  };
  /* #endregion */

  return (
    <ModalContainer
      onClose={onClose}
      maskClosable={maskClosable}
      visible={visible}
      size="xl"
    >
      <form onSubmit={handleSubmit(onSubmit, onError)} className="modal-form">
        <div className="modal-form-header">
          <div className="modal-form-tit">
            <span className="modal-tit">업체 관리</span>
          </div>
          <div className="d-flex flex-row">
            <button
              type="button"
              className="btn-modal btn-modal-close"
              onClick={onClose}
            >
              <SVG
                src={toAbsoluteUrl(
                  "/media/svg/icons/Custom/close_black_24dp.svg"
                )}
                className="close-icon"
              />
            </button>
          </div>
        </div>

        <div className="input-wrap">
          <div className="input-grid">
            <div className="dx-field">
              <InputText
                control={control}
                errors={errors}
                name="company"
                label="회사명"
                required={true}
                flex={true}
                onInitialized={(e) => {
                  setTimeout(() => {
                    e.component.focus();
                  }, 0);
                }}
              />
            </div>

            <div className="dx-field">
              <InputSelect
                control={control}
                errors={errors}
                name="manageAssign"
                label="관리소속"
                flex={true}
                dataSource={lookupData.department}
                displayExpr="deptName"
                valueExpr="code"
              />
            </div>

            <div className="dx-field">
              <InputSelect
                control={control}
                errors={errors}
                name="custoDivision"
                label="거래처분류"
                dataSource={lookupData.custoDivision}
                valueExpr="subCode"
                displayExpr="subName"
                required={true}
                flex={true}
              />
            </div>
          </div>

          <div className="input-grid">
            <div className="dx-field">
              <InputSelect
                control={control}
                errors={errors}
                name="agency"
                label="업무대행"
                flex={true}
                dataSource={lookupData.agency}
                valueExpr="subCode"
                displayExpr="subName"
              />
            </div>

            <div className="dx-field">
              <InputNumber
                control={control}
                errors={errors}
                name="agencyPrice"
                label="업무대행료"
                flex={true}
              />
            </div>

            <div className="dx-field">
              <InputTel
                control={control}
                errors={errors}
                name="reportTel"
                label="도착보고전화"
                flex={true}
              />
            </div>
            <div className="dx-field">
              <InputSelect
                control={control}
                errors={errors}
                name="payCycle"
                label="결제일자"
                dataSource={lookupData.payCycle}
                valueExpr="subCode"
                displayExpr="subName"
                required={true}
                flex={true}
              />
            </div>
          </div>

          <div className="input-grid">
            <div className="dx-field">
              <InputTel
                control={control}
                errors={errors}
                name="tel"
                label="대표전화"
                required={true}
                flex={true}
              />
            </div>

            <div className="dx-field">
              <InputTel
                control={control}
                errors={errors}
                name="fax"
                label="팩스번호"
                flex={true}
              />
            </div>

            <div className="dx-field">
              <InputText
                control={control}
                errors={errors}
                name="route"
                label="전문노선"
                flex={true}
              />
            </div>

            <div className="dx-field">
              <InputSelect
                control={control}
                errors={errors}
                name="creditClass"
                label="신용등급"
                dataSource={lookupData.creditClass}
                valueExpr="subCode"
                displayExpr="subName"
                flex={true}
              />
            </div>
          </div>

          <div className="input-grid">
            <div className="dx-field">
              <InputSelect
                control={control}
                errors={errors}
                name="assignWorker"
                label="담당직원"
                flex={true}
                dataSource={lookupData.assignWorker}
                valueExpr="id"
                displayExpr="userName"
              />
            </div>

            <div className="dx-field">
              <InputNumber
                control={control}
                errors={errors}
                name="viewRank"
                label="조회우선순위"
                flex={true}
              />
            </div>

            <div className="dx-field">
              <InputDate
                control={control}
                errors={errors}
                name="finDay"
                label="마감일자"
                flex={true}
              />
            </div>

            <div className="dx-field">
              <InputSelect
                control={control}
                errors={errors}
                name="actStatus"
                label="활동상태"
                dataSource={lookupData.actStatus}
                valueExpr="subCode"
                displayExpr="subName"
                flex={true}
              />
            </div>
          </div>

          <div className="input-grid">
            <div className="dx-field">
              <InputNumber
                control={control}
                errors={errors}
                name="nCollectPrice"
                label="미수금"
                flex={true}
              />
            </div>

            <div className="dx-field">
              <InputNumber
                control={control}
                errors={errors}
                name="nGivenPrice"
                label="미지급금"
                flex={true}
              />
            </div>

            <div className="dx-field">
              <InputDate
                control={control}
                errors={errors}
                name="editDay"
                label="변경일자"
                flex={true}
              />
            </div>

            <div className="dx-field">
              <InputText
                control={control}
                errors={errors}
                name="editWorker"
                label="변경직원"
                flex={true}
              />
            </div>
          </div>

          <div className="input-grid grid-1">
            <div className="dx-field checkbox-group">
              <InputCheckBox
                control={control}
                errors={errors}
                name="isShuttleDepot"
                text="셔틀창고"
                flex={true}
              />
              <InputCheckBox
                control={control}
                errors={errors}
                name="isBilling"
                text="업체"
                flex={true}
              />
              <InputCheckBox
                control={control}
                errors={errors}
                name="isOrderCo"
                text="발주처"
                flex={true}
              />
              <InputCheckBox
                control={control}
                errors={errors}
                name="isCargoOwner"
                text="화주"
                flex={true}
              />
              <InputCheckBox
                control={control}
                errors={errors}
                name="isYongchasa"
                text="용차사"
                flex={true}
              />
            </div>
          </div>

          <div className="input-grid grid-1">
            <div className="dx-field">
              <InputAddress
                control={control}
                errors={errors}
                flexDirection="row"
                openPost={openPost}
              />
            </div>
          </div>

          <div className="input-grid grid-1">
            <div className="dx-field">
              <InputTextArea
                control={control}
                errors={errors}
                name="remark"
                label="비고"
                flex={true}
              />
            </div>
          </div>
        </div>

        {/* 여기 아래에 탭이 들어갈 수도 있음 */}

        <div className="input-wrap">
          <div className="input-grid">
            <div className="dx-field">
              <InputText
                control={control}
                errors={errors}
                name="bizName"
                label="사업자상호"
                flex={true}
              />
            </div>
            <div className="dx-field">
              <InputText
                control={control}
                errors={errors}
                name="bizNo"
                label="사업자번호"
                mask="999-99-99999"
                flex={true}
              />
            </div>

            <div className="dx-field">
              <InputRadioGroup
                control={control}
                errors={errors}
                name="bizType"
                label="휴폐업조회"
                dataSource={[
                  { id: 0, name: "개인" },
                  { id: 1, name: "법인" },
                ]}
                valueExpr="id"
                displayExpr="name"
                flex={true}
              />
            </div>

            <div className="dx-field">
              <InputText
                control={control}
                errors={errors}
                name="coporNo"
                label="법인번호"
                flex={true}
              />
            </div>
          </div>

          <div className="input-grid">
            <div className="dx-field">
              <InputText
                control={control}
                errors={errors}
                name="ceoName"
                label="대표자"
                flex={true}
              />
            </div>
            <div className="dx-field">
              <InputSelect
                control={control}
                errors={errors}
                name="kindBiz"
                label="사업자종류"
                flex={true}
                dataSource={lookupData.kindBiz}
                valueExpr="subCode"
                displayExpr="subName"
              />
            </div>

            <div className="dx-field">
              <InputText
                control={control}
                errors={errors}
                name="bizCondition"
                label="업태"
                flex={true}
              />
            </div>

            <div className="dx-field">
              <InputText
                control={control}
                errors={errors}
                name="item"
                label="종목"
                flex={true}
              />
            </div>
          </div>

          <div className="input-grid">
            <div className="dx-field">
              <InputText
                control={control}
                errors={errors}
                name="bizPlaceNo"
                label="종사업장번호"
                flex={true}
              />
            </div>
            <div className="dx-field">
              <InputSelect
                control={control}
                errors={errors}
                name="bank"
                label="거래은행"
                flex={true}
                dataSource={lookupData.bank}
                valueExpr="subCode"
                displayExpr="subName"
              />
            </div>

            <div className="dx-field">
              <InputText
                control={control}
                errors={errors}
                name="acountNo"
                label="계좌번호"
                flex={true}
              />
            </div>

            <div className="dx-field">
              <InputText
                control={control}
                errors={errors}
                name="depositOwner"
                label="예금주"
                flex={true}
              />
            </div>
          </div>
          <div className="input-grid">
            <div className="dx-field">
              <InputText
                control={control}
                errors={errors}
                name="billManage"
                label="계산서담당자"
                flex={true}
              />
            </div>
            <div className="dx-field">
              <InputText
                control={control}
                errors={errors}
                name="billEmail"
                label="계산서이메일"
                flex={true}
              />
            </div>

            <div className="dx-field">
              <InputTel
                control={control}
                errors={errors}
                name="companyTel"
                label="회사전화"
                flex={true}
              />
            </div>

            <div className="dx-field">
              <InputTel
                control={control}
                errors={errors}
                name="moveTel"
                label="이동전화"
                flex={true}
              />
            </div>
          </div>
        </div>
        <div className="modal-form-footer order-modal">
          <button
            type="submit"
            className={`btn btn-modal btn-save  ${isLoading &&
              "spinner spinner-white spinner-right"}`}
            disabled={isLoading}
          >
            저장
          </button>
        </div>
      </form>
    </ModalContainer>
  );
};

export default NewCompanyModal;
