import { yupResolver } from "@hookform/resolvers/yup";
import dayjs from "dayjs";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { InputDate } from "../../../../components/InputForm";
import ModalContainer from "../../../../components/Modal/ModalContainer";
import ModalHeader from "../../../../components/Modal/ModalHeader";
import { finishConfirm } from "../ClosingCrud";
import { alert } from "devextreme/ui/dialog";

const ClosingDateModal = ({
  onClose,
  maskClosable,
  closable,
  visible,
  closingRightRef,
  closingLeftRef,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const schema = yup.object({
    divisionDate: yup
      .string()
      .nullable()
      .required("마감일자를 선택해주세요"),
  });
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      divisionDate: dayjs().format("YYYY-MM-DD"),
    },
  });

  const onSubmit = async (data) => {
    const instanceRight = closingRightRef.current.instance;
    const instanceLeft = closingLeftRef.current.instance;
    const selected = instanceRight.getSelectedRowsData();

    try {
      setIsLoading(true);

      await finishConfirm(data, selected);

      instanceRight.deselectAll();
      instanceRight.refresh();
      instanceLeft.refresh();
      onClose();
    } catch (error) {
      alert("ERROR-01", "오류");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <ModalContainer
        onClose={onClose}
        maskClosable={maskClosable}
        visible={visible}
        size="sm"
      >
        <form className="modal-form" onSubmit={handleSubmit(onSubmit)}>
          <ModalHeader title="마감" onClose={onClose} />
          <div className="modal-form-body">
            <div className="d-flex flex-row mb-1">
              <div className="dx-field">
                <InputDate
                  control={control}
                  errors={errors}
                  name="divisionDate"
                  label="마감일자"
                  flex={true}
                />
              </div>
            </div>
          </div>
          <div className="modal-form-footer">
            <button
              type="submit"
              className={`btn btn-modal btn-save ${isLoading &&
                "spinner spinner-white spinner-right"}`}
              disabled={isLoading}
            >
              저장
            </button>
          </div>
        </form>
      </ModalContainer>
    </>
  );
};

export default ClosingDateModal;
