import React from "react";
import CustomButton from "../../../components/FunctionButton/CustomButton";
import InputMonth from "../../../components/InputForm/InputMonth";
import HeaderTitle from "../../../components/Layout/Header/HeaderTitle";
import {
  PanelGridSearchBar,
  PanelGridWrapper,
  PanelMultiGrid,
  SearchItems,
} from "../../../components/Layout/Panel/PanelGrid/panelGrid.style";
import {
  PanelBody,
  PanelBtn,
  PanelHeader,
  PanelInit,
} from "../../../components/Layout/Panel/panel.style";
import NonPaymentLower from "./components/NonPaymentLower";
import NonPaymentUpper from "./components/NonPaymentUpper";
import useNonPayment from "./useNonPayment";

const NonPaymentPage = () => {
  const {
    state,
    searchForm,
    manageInfoForm,

    dataGridRef,

    detailGridRef,
    accountHistoryRef,
    manageInfoRef,

    pageFunction,
    masterFunction,
  } = useNonPayment();

  const { onSearch, excelExport } = pageFunction;

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = searchForm;

  return (
    <>
      <PanelInit>
        <PanelHeader>
          <HeaderTitle />
          <PanelBtn>
            <CustomButton
              type="button"
              height={24}
              layout="solid"
              color="common"
              label="미수금 엑셀다운"
              onClick={excelExport}
            />
          </PanelBtn>
        </PanelHeader>

        <PanelBody row left="100%" right="auto">
          <PanelGridWrapper>
            <PanelGridSearchBar onSubmit={handleSubmit(onSearch)}>
              <SearchItems>
                <label>년월</label> {/* 세금계산서 작성일자 */}
                <InputMonth
                  className="label-select"
                  control={control}
                  errors={errors}
                  name="firstMonth"
                  flex={true}
                />
                ~
                <InputMonth
                  className="label-select"
                  control={control}
                  errors={errors}
                  name="lastMonth"
                  flex={true}
                />
              </SearchItems>
              <CustomButton
                type="submit"
                height={24}
                layout="solid"
                color="search"
                label="검색"
              />
            </PanelGridSearchBar>

            <PanelMultiGrid column top="50%" bottom="50%">
              <NonPaymentUpper
                state={state}
                masterFunction={masterFunction}
                dataGridRef={dataGridRef}
              />
              <NonPaymentLower
                state={state}
                manageInfoForm={manageInfoForm}
                detailGridRef={detailGridRef}
                accountHistoryRef={accountHistoryRef}
                manageInfoRef={manageInfoRef}
              />
            </PanelMultiGrid>
          </PanelGridWrapper>
        </PanelBody>
      </PanelInit>
    </>
  );
};

export default NonPaymentPage;
