import axios from "axios";

const apiUrl = `${process.env.REACT_APP_API_URL}/comcode/master`;

export function updateComCodeMaster(data) {
  return axios.put(apiUrl, data);
}

export function createComCodeMaster(data) {
  return axios.post(apiUrl, data);
}

export function updateComCodeDetail(data) {
  return axios.put(apiUrl, data);
}

export function createComCodeDetail(data) {
  return axios.post(apiUrl, data);
}

export function deleteRow(key) {
  return axios.delete(apiUrl + `/${key}`);
}
