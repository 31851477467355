import React from "react";
import HeaderTitle from "../Header/HeaderTitle";
import { PanelBtn, PanelHeader } from "../Panel/panel.style";

const PageHeader = ({ children }) => {
  return (
    <PanelHeader>
      <HeaderTitle />

      <PanelBtn>{children}</PanelBtn>
    </PanelHeader>
  );
};

export default PageHeader;
