import axios from "axios";

const apiUrl = `${process.env.REACT_APP_API_URL}/order`;
const apiUrlLookup = `${process.env.REACT_APP_API_URL}`;
const apiUrlOrder = `${process.env.REACT_APP_API_URL}/order/master`;

export function createOrder(data) {
  return axios.post(apiUrl, data);
}

export function updateOrder(data) {
  return axios.put(apiUrl, data);
}

export function getUnipassInfo(data) {
  return axios.post(`${apiUrl}/unipass`, data);
}

export function onClickConfirmCreate(data) {
  return axios.post(apiUrl + `/confirmcreate`, data);
}

export function onClickConfirmUpdate(data) {
  return axios.put(apiUrl + `/confirmupdate`, data);
}

export function getOrderDataByCode(code) {
  return axios.get(apiUrlOrder + `/${code}`);
}

export function getOrderData(search) {
  return axios.get(apiUrlOrder, { params: search });
}

export function getLookup(param) {
  return axios.get(apiUrlLookup + `/${param}`);
}

export function cancelOrder(code) {
  return axios.put(apiUrlOrder, { code });
}

export function deleteOrder(code) {
  return axios.delete(apiUrlOrder, { params: { code } });
}
