import dayjs from "dayjs";
import HtmlEditor, {
  Item,
  TableContextMenu,
  TableResizing,
  Toolbar,
} from "devextreme-react/html-editor";
import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import CustomButton from "../../../../components/FunctionButton/CustomButton/CustomButton";
import { ButtonGroup } from "../../../../components/FunctionButton/CustomButton/customButton.style";
import { InputText } from "../../../../components/InputForm";
import Loading from "../../../../components/Loading/Loading";
import ModalContainer from "../../../../components/Modal/ModalContainer";
import ModalHeader from "../../../../components/Modal/ModalHeader";
import {
  ModalMailingAttach,
  ModalMailingWrapper,
} from "../../../../components/Modal/modal.style";
import { getDefaultMessage } from "../../../../utils/emailFunctions";
import { sendMail } from "../../Closing/ClosingCrud";
import { alert } from "devextreme/ui/dialog";

const MailingModal = ({
  onClose,
  maskClosable,
  closable,
  visible,
  mailPdf,
  searchObj,
  selectedRowData,
}) => {
  const { user } = useSelector((state) => state.auth.user);
  const markup = getDefaultMessage(user, selectedRowData, searchObj);
  const editorRef = useRef();
  const pdfName = `${selectedRowData?.companyName || ""}_거래명세서_${dayjs(
    searchObj?.firstDay
  ).format("YYYY년 MM월 DD일")}~${dayjs(searchObj?.lastDay).format(
    "YYYY년 MM월 DD일"
  )}.pdf`;
  const [isLoading, setIsLoading] = useState(false);

  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm();

  useEffect(() => {
    setValue(
      "subject",
      `[${user?.companyCode_company?.companyName ||
        ""}] ${selectedRowData?.companyName || ""} ${dayjs(
        searchObj?.firstDay
      ).format("M월")} 명세서 첨부`
    );
    // eslint-disable-next-line
  }, []);

  const onSubmit = async (data) => {
    try {
      const body = editorRef.current.instance
        .option("value")
        .replace(
          /<td style="/gi,
          `<td style="border: 1px solid #ddd; padding: 2px 5px; `
        );
      setIsLoading(true);
      await sendMail({
        from: "drhu_account@hulogistics.co.kr",
        to: data.to,
        subject: data.subject,
        html: body,
        pdf: mailPdf,
        filename: pdfName,
      });
      onClose();
      alert("메일 발송 완료!");
    } catch (e) {
      alert("메일 발송 실패!");
    } finally {
      setIsLoading(false);
    }
  };
  const onError = (err) => {
    console.log(err);
  };

  return (
    <>
      <ModalContainer
        onClose={onClose}
        maskClosable={maskClosable}
        visible={visible}
        size="xl"
      >
        {isLoading && <Loading type="page" text="메일 발송 중입니다..." />}
        <form onSubmit={handleSubmit(onSubmit, onError)} className="modal-form">
          <ModalHeader title="거래명세서 메일 전송" onClose={onClose} />
          <ModalMailingWrapper>
            <InputText
              control={control}
              errors={errors}
              name="subject"
              label="제목"
            />

            <InputText
              control={control}
              errors={errors}
              name="to"
              label="받는 사람"
            />

            <ModalMailingAttach>
              <label>첨부파일</label>
              <div>{pdfName}</div>
            </ModalMailingAttach>

            <label>내용</label>
            {/* <QuillEditor quillRef={quillRef} defaultValue={markup} /> */}

            <HtmlEditor
              ref={editorRef}
              height={"675px"}
              defaultValue={markup}
              valueType="html"
            >
              <TableContextMenu enabled={true} />
              <TableResizing enabled={true} />
              <Toolbar multiline={true}>
                <Item name="undo" />
                <Item name="redo" />
                <Item name="separator" />
                <Item
                  name="size"
                  acceptedValues={[
                    "8pt",
                    "10pt",
                    "12pt",
                    "14pt",
                    "18pt",
                    "24pt",
                    "36pt",
                  ]}
                />
                <Item
                  name="font"
                  acceptedValues={[
                    "Arial",
                    "Courier New",
                    "Georgia",
                    "Impact",
                    "Lucida Console",
                    "Tahoma",
                    "Times New Roman",
                    "Verdana",
                  ]}
                />
                <Item name="separator" />
                <Item name="bold" />
                <Item name="italic" />
                <Item name="strike" />
                <Item name="underline" />
                <Item name="separator" />
                <Item name="alignLeft" />
                <Item name="alignCenter" />
                <Item name="alignRight" />
                <Item name="alignJustify" />
                <Item name="separator" />
                <Item name="orderedList" />
                <Item name="bulletList" />
                <Item name="separator" />
                <Item name="header" acceptedValues={[false, 1, 2, 3, 4, 5]} />
                <Item name="separator" />
                <Item name="color" />
                <Item name="background" />
                <Item name="separator" />
                <Item name="clear" />
                <Item name="codeBlock" />
                <Item name="blockquote" />
                <Item name="separator" />
                <Item name="insertTable" />
                <Item name="insertRowAbove" />
                <Item name="insertRowBelow" />
                <Item name="insertColumnLeft" />
                <Item name="insertColumnRight" />
                <Item name="deleteTable" />
                <Item name="deleteRow" />
                <Item name="deleteColumn" />
              </Toolbar>
            </HtmlEditor>
          </ModalMailingWrapper>
          <ButtonGroup justifyContent="flex-end">
            <CustomButton
              type="submit"
              height={36}
              layout="solid"
              color="common"
              label="메일발송"
              disabled={isLoading}
            />
          </ButtonGroup>
        </form>
      </ModalContainer>
    </>
  );
};

export default MailingModal;
