import axios from "axios";

const apiUrl = `${process.env.REACT_APP_API_URL}`;
const apiUrlReturn = `${process.env.REACT_APP_API_URL}/returnListManage`;

export function getLookupDatas(param) {
  return axios.get(apiUrl + `/${param}`);
}

export function setWarehouseArrive(data) {
  return axios.put(apiUrlReturn + `/warehouseArrive`, data);
}

export function changeContainerNo(data) {
  return axios.put(apiUrlReturn + `/container`, data);
}

export function setShip(data) {
  return axios.put(apiUrlReturn + `/forwarder`, data);
}

export function setSailDate(data) {
  return axios.put(apiUrlReturn + `/sail`, data);
}

export function setFridgeDanger(data) {
  return axios.put(apiUrlReturn + `/danger-fridge`, data);
}

export function setReturnDate(data) {
  return axios.put(apiUrlReturn + `/return-date`, data);
}

export function setChassisInfo(data) {
  return axios.put(apiUrlReturn + `/chassis`, data);
}
