import { TextBox } from "devextreme-react";
import React from "react";
import { Controller } from "react-hook-form";
import InputLayout from "./InputLayout";

const InputTel = ({
  control,
  errors,
  name,
  label,
  labelWidth,
  flex,
  required,
  disabled = false,
  tabIndex,
  className,
}) => {
  const getHypenNumber = (value) => {
    const number = value.replace(/[^0-9]/g, "");

    return number
      .replace(/[^0-9]/g, "")
      .replace(
        /(^02|^0505|^1[0-9]{3}|^0[0-9]{2})([0-9]+)?([0-9]{4})$/,
        "$1-$2-$3"
      )
      .replace("--", "-");
  };

  return (
    <InputLayout
      label={label}
      required={required}
      errors={errors}
      name={name}
      labelWidth={labelWidth}
    >
      <Controller
        render={({ field: { onChange, value } }) => {
          return (
            <TextBox
              mode="text"
              className={className}
              maxLength={13}
              format="#"
              onKeyUp={(e) => {
                const val = e.event.target.value;
                onChange(getHypenNumber(val));
              }}
              value={value}
              disabled={disabled}
              tabIndex={tabIndex}
            />
          );
        }}
        control={control}
        name={name}
      />
    </InputLayout>
  );
};

export default InputTel;
