import React from "react";

const AppContainerImageViewer = ({ data }) => {
  return (
    <>
      <div>{data.name}</div>
      <img
        src="https://via.placeholder.com/200x150"
        width="200"
        height="150"
        alt="샘플이미지"
      />
      <div>{data.date}</div>
    </>
  );
};

export default AppContainerImageViewer;
