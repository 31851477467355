import moment from "moment";
import { useEffect, useReducer, useRef } from "react";
import { useForm } from "react-hook-form";
import { sendRequest } from "../../../../_metronic/_helpers/DataSoureHelpers";

const initialSearch = {
  firstMonth: moment().format("YYYY-MM"),
  lastMonth: moment().format("YYYY-MM"),
};

const initialState = {
  search: initialSearch,
  rowData: {},

  lookupData: [],

  dataSource: [],

  detailGridDataSource: [],
  accountHistoryDataSource: [],
  manageInfoDataSource: [],
};

const nonPaymentReducer = (state, action) => {
  switch (action.type) {
    case "SET_SEARCH":
      return {
        ...state,
        search: action.payload,
      };

    case "SET_LOOKUP_DATA":
      return {
        ...state,
        lookupData: action.payload,
      };

    case "SET_DATA_SOURCE":
      return {
        ...state,
        dataSource: action.payload,
      };

    case "SET_ROW_DATA":
      return {
        ...state,
        rowData: action.payload,
      };

    case "SET_DETAIL_DATAS":
      const {
        detailGridDataSource,
        accountHistoryDataSource,
        manageInfoDataSource,
      } = action.payload;
      return {
        ...state,
        detailGridDataSource,
        accountHistoryDataSource,
        manageInfoDataSource,
      };

    default:
      throw new Error("Unhandled action");
  }
};

const useNonPayment = () => {
  const [state, dispatch] = useReducer(nonPaymentReducer, initialState);
  const { search, rowData } = state;

  const dataGridRef = useRef();

  const detailGridRef = useRef();
  const accountHistoryRef = useRef();
  const manageInfoRef = useRef();

  const searchForm = useForm({
    defaultValues: initialSearch,
  });

  const manageInfoForm = useForm({
    defaultValues: {
      content: "",
    },
  });

  useEffect(() => {
    _initLookupData();
  }, []);

  useEffect(() => {
    _getDataGrid(search);
  }, [search]);

  useEffect(() => {
    _getDetailDataSource(rowData, search);
    // eslint-disable-next-line
  }, [rowData]);

  // _initLookupData 함수 선언
  const _initLookupData = async () => {
    // initLookupData 함수를 호출하고 반환된 데이터를 변수 data에 할당
    // const data = await initLookupData();
    const data = [];

    // dispatch 함수를 호출하여 SET_LOOKUP_DATA 액션을 실행하고 데이터를 전달
    dispatch({
      type: "SET_LOOKUP_DATA",
      payload: data,
    });
  };

  const _getDataGrid = async (search) => {
    // apiUrl 변수에 API 주소를 할당
    const apiUrl = `${process.env.REACT_APP_API_URL}/non-payment`;

    // sendRequest 함수를 호출하여 GET 요청을 보내고 반환된 데이터를 변수 data에 할당
    const data = await sendRequest(apiUrl, "GET", search);

    // dispatch 함수를 호출하여 SET_DATA_SOURCE 액션을 실행하고 데이터를 전달
    dispatch({
      type: "SET_DATA_SOURCE",
      payload: data,
    });
  };

  const _getDetailDataSource = async (rowData, search) => {
    const { id, division } = rowData;

    if (!id) {
      dispatch({
        type: "SET_DETAIL_DATAS",
        payload: {
          detailGridDataSource: [],
          accountHistoryDataSource: [],
          manageInfoDataSource: [],
        },
      });
      return;
    }

    let detailApiUrl = `${process.env.REACT_APP_API_URL}/non-payment`;

    if (division === "청구처") detailApiUrl += "/sub/billing";
    else if (division === "용차사") detailApiUrl += "/sub/yongchasa";
    else if (division === "기사") detailApiUrl += "/sub/driver";
    else if (division === "할증") detailApiUrl += "/sub/extra";
    else if (division === "기타 하불 기사") detailApiUrl += "/sub/charge-dri";
    else if (division === "기타 하불 업체") detailApiUrl += "/sub/charge-com";

    let accountHistoryApiUrl = `${process.env.REACT_APP_API_URL}/non-payment/bank`;
    let manageInfoApiUrl = `${process.env.REACT_APP_API_URL}/non-payment/memo`;

    const [
      detailGridData,
      accountHistoryData,
      manageInfoData,
    ] = await Promise.all([
      sendRequest(detailApiUrl, "GET", {
        search,
        rowData,
      }),
      sendRequest(accountHistoryApiUrl, "GET", {
        search,
        rowData,
      }),
      sendRequest(manageInfoApiUrl, "GET", {
        search,
        rowData,
      }),
    ]);

    // console.log(detailGridData, accountHistoryData, manageInfoData);

    dispatch({
      type: "SET_DETAIL_DATAS",
      payload: {
        detailGridDataSource: detailGridData,
        accountHistoryDataSource: accountHistoryData,
        manageInfoDataSource: manageInfoData,
      },
    });
  };

  const pageFunction = {
    onSearch: async (data) => {
      const instance = dataGridRef.current.instance;
      dispatch({
        type: "SET_SEARCH",
        payload: data,
      });

      const key = instance.option("focusedRowKey");
      if (key) {
        await instance.option("focusedRowIndex", -1);
        await instance.option("focusedRowKey", key);
      }
    },

    excelExport: () => {
      dataGridRef.current.instance.exportToExcel();
    },
  };

  const masterFunction = {
    onFocusedRowChanged: (e) => {
      const { row } = e;
      if (!row) return;

      dispatch({ type: "SET_ROW_DATA", payload: row.data });
    },
  };

  return {
    state,
    searchForm,
    manageInfoForm,

    dataGridRef,

    detailGridRef,
    accountHistoryRef,
    manageInfoRef,

    pageFunction,
    masterFunction,
  };
};

export default useNonPayment;
