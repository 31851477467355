import axios from "axios";
import { DataGrid } from "devextreme-react";
import { Column, Editing, Format, Scrolling } from "devextreme-react/data-grid";
import { alert } from "devextreme/ui/dialog";
import html2canvas from "html2canvas";
import moment from "moment";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import SVG from "react-inlinesvg";
import { useReactToPrint } from "react-to-print";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";
import InputNumber from "../../../../components/InputForm/InputNumber";
import InputTel from "../../../../components/InputForm/InputTel";
import InputText from "../../../../components/InputForm/InputText";
import InputTextArea from "../../../../components/InputForm/InputTextArea";
import Loading from "../../../../components/Loading/Loading";
import ModalContainer from "../../../../components/Modal/ModalContainer";
import {
  AdditionalGrid,
  GridHeader,
  GridHeaderItem,
  GridItem,
  AdditionalWrapper,
  ModalTaxIssueBody,
  ModalTaxIssueFooter,
  ModalTaxIssueHeader,
  ModalTaxIssueSubtit,
  ModalTaxIssueTitle,
  ModalTaxIssueWrapper,
  TotalWrapper,
  Tradeset,
  TradesetBody,
  TradesetItems,
  TradesetRows,
  TradesetWrapper,
  GridTr,
} from "../../../../components/Modal/modal.style";
import { convertPdf } from "../../../../utils/emailFunctions";
import {
  excelExport,
  faxExport,
  getTempleteSubByOid,
  updatePrtState,
} from "../ClosingCrud";

const sleep = (delay) => new Promise((resolve) => setTimeout(resolve, delay));

const ClosingModal = ({
  onClose,
  maskClosable,
  closable,
  searchObj,
  closingRightRef,
  visible,
  selectedLeftRow,
  selectedRightRow,
  templeteInfo,
  setOpenMailingModal,
  setMailPdf,
}) => {
  // a4용지 전체비율;
  const MAX_WIDTH = 1060;

  const [isLoading, setIsLaoding] = useState(false);
  const [gridColumn, setGridColumn] = useState([]);
  const [totalWidth, setTotalWidth] = useState(MAX_WIDTH);

  const [invoicerData, setInvoicerData] = useState({});
  const [invoiceeData, setInvoiceeData] = useState({});
  const modalRef = useRef();
  const printRef = useRef();

  const {
    watch,
    control,
    formState: { errors },
    setValue,
  } = useForm({});

  useEffect(() => {
    // setBankInfo(templeteInfo);
    onTempleteChanged(templeteInfo.templete);
  }, [templeteInfo]);

  useEffect(() => {
    modalRef.current.instance.addColumn(
      gridColumn.map((cur) => {
        if (cur.sortText === "좌측") {
          cur.sortText = "left";
        } else if (cur.sortText === "가운데") {
          cur.sortText = "center";
        } else if (cur.sortText === "우측") {
          cur.sortText = "right";
        }

        if (cur.printName === "금액") {
          return (
            <Column
              key={cur.oid}
              dataField={cur.columnName}
              caption={cur.printName}
              width={cur.width || undefined}
              alignment={cur.sortText}
              dataType="number"
            >
              <Format type="fixedPoint" />
            </Column>
          );
        } else {
          return (
            <Column
              key={cur.oid}
              dataField={cur.columnName}
              caption={cur.printName}
              width={cur.width || undefined}
              alignment={cur.sortText}
            />
          );
        }
      })
    );
    modalRef.current.instance.refresh();
    calculateTotalWidth(gridColumn);
  }, [gridColumn]);

  useEffect(() => {
    // 청구처/용차사/차량번호
    // left 접수법인, 용차사, 기사 공급받는자에 집어넣기
    // 업체관리에서 이름으로 찾기

    selectedLeftRow && getCompanyName(selectedLeftRow);

    // 접수법인
    // right receiveCo를 구분
    // 회사정보관리에서 이름이로 찾기
    selectedRightRow && getReceiveCo(selectedRightRow, selectedLeftRow);

    selectedRightRow &&
      selectedRightRow.sort(
        (a, b) => new Date(a.deliverDate) - new Date(b.deliverDate)
      );

    // 내역 집어넣기
    let Amount = 0;
    let Tax = 0;
    for (const obj of selectedRightRow) {
      Amount += Number(obj.price);
      Tax += Number(obj.tax);
    }

    let TotalAmount = Amount + Tax;

    setValue("Amount", Number(Amount));
    setValue("Tax", Number(Tax));
    setValue("TotalAmount", Number(TotalAmount));
    // eslint-disable-next-line
  }, [selectedLeftRow, selectedRightRow]);

  /* #region 기타함수 */

  const calculateTotalWidth = (gridColumn) => {
    const totalWidth = gridColumn.reduce((acc, cur) => {
      return acc + cur.width;
    }, 0);
    setTotalWidth(totalWidth);
  };

  async function getCompanyName(target) {
    let apiUrl = `${process.env.REACT_APP_API_URL}`;
    let searchQuery = {};
    if (target.division === "기사" && target.companyName) {
      apiUrl += `/carManage/${target?.comOid}`;
      searchQuery["carNo"] = target.companyName?.split("(")[1]?.slice(0, -1);
    } else {
      apiUrl += `/companyManage/${target?.comOid}`;
    }

    const result = await axios.get(apiUrl, {
      params: searchQuery,
    });

    const companyData = result.data[0];

    if (target.division === "기사") {
      // 기사일경우 공급자, 공급받는자 위치 변경
      setInvoicerData({
        Biz_No: companyData?.bizNo || "",
        Invoicer_CorpName: companyData?.bizName || "",
        Invoicer_CEOName: companyData?.ceoName || "",
        Invoicer_Addr:
          `${companyData?.bizAddress || ""} ${companyData?.bizDetailAddress ||
            ""}` !== " "
            ? `${companyData?.bizAddress ||
                ""} ${companyData?.bizDetailAddress || ""}`
            : `${companyData?.address} ${companyData?.address1}` !== " "
            ? `${companyData?.address} ${companyData?.address1}`
            : "",
        Invoicer_BizClass: companyData?.bizCondition || "",
        Invoicer_BizType: companyData?.item || "",
        Invoicer_Tel: companyData?.tel || "",
        Invoicer_Fax: companyData?.fax || "",
      });

      setValue("Biz_No", companyData?.bizNo || "");
      setValue("Invoicer_CorpName", companyData?.bizName || "");
      setValue("Invoicer_CEOName", companyData?.ceoName || "");
      `${companyData?.bizAddress || ""} ${companyData?.bizDetailAddress ||
        ""}` !== " " &&
        setValue(
          "Invoicer_Addr",
          `${companyData?.bizAddress || ""} ${companyData?.bizDetailAddress ||
            ""}`
        );
      `${companyData?.address} ${companyData?.address1}` !== " " &&
        setValue(
          "Invoicer_Addr",
          `${companyData?.address} ${companyData?.address1}`
        );
      setValue("Invoicer_BizClass", companyData?.bizCondition || "");
      setValue("Invoicer_BizType", companyData?.item || "");
      setValue("Invoicer_Tel", companyData?.tel || "");
      setValue("Invoicer_Fax", companyData?.fax || "");
    } else {
      setInvoiceeData({
        Biz_No_Return: companyData?.bizNo || "",
        Invoicee_CorpName: companyData?.bizName || "",
        Invoicee_CEOName: companyData?.ceoName || "",
        Invoicee_Addr:
          `${companyData?.bizAddress || ""} ${companyData?.bizDetailAddress ||
            ""}` !== " "
            ? `${companyData?.bizAddress ||
                ""} ${companyData?.bizDetailAddress || ""}`
            : `${companyData?.address} ${companyData?.address1}` !== " "
            ? `${companyData?.address} ${companyData?.address1}`
            : "",
        Invoicee_BizClass: companyData?.bizCondition || "",
        Invoicee_BizType: companyData?.item || "",
        Invoicee_Tel: companyData?.tel || "",
        Fax_No: companyData?.fax || "",
      });

      setValue("Biz_No_Return", companyData?.bizNo || "");
      setValue("Invoicee_CorpName", companyData?.bizName || "");
      setValue("Invoicee_CEOName", companyData?.ceoName || "");
      `${companyData?.bizAddress || ""} ${companyData?.bizDetailAddress ||
        ""}` !== " " &&
        setValue(
          "Invoicee_Addr",
          `${companyData?.bizAddress || ""} ${companyData?.bizDetailAddress ||
            ""}`
        );
      `${companyData?.address} ${companyData?.address1}` !== " " &&
        setValue(
          "Invoicee_Addr",
          `${companyData?.address} ${companyData?.address1}`
        );
      setValue("Invoicee_BizClass", companyData?.bizCondition || "");
      setValue("Invoicee_BizType", companyData?.item || "");
      setValue("Invoicee_Tel", companyData?.tel || "");
      setValue("Fax_No", companyData?.fax || "");
    }
  }

  async function getReceiveCo(target, division) {
    const apiUrl = `${process.env.REACT_APP_API_URL}/company`;

    const result = await axios.get(apiUrl, {
      params: { companyName: target[0]?.companyName },
    });

    const receiveCo = result.data[0];

    if (division.division === "기사") {
      // 기사일경우 공급자, 공급받는자 위치 변경
      setInvoiceeData({
        Biz_No_Return: receiveCo?.bizNo || "",
        Invoicee_CorpName: receiveCo?.companyName || "",
        Invoicee_CEOName: receiveCo?.ceoName || "",
        Invoicee_Addr:
          `${receiveCo?.address} ${receiveCo?.addressDetail}` || "",
        Invoicee_BizClass: receiveCo?.bizType || "",
        Invoicee_BizType: receiveCo?.bizItem || "",
        Invoicee_Tel: receiveCo?.tel || "",
        Fax_No: receiveCo?.fax || "",
      });
      setValue("Biz_No_Return", receiveCo?.bizNo || "");
      setValue("Invoicee_CorpName", receiveCo?.companyName || "");
      setValue("Invoicee_CEOName", receiveCo?.ceoName || "");
      setValue(
        "Invoicee_Addr",
        `${receiveCo?.address} ${receiveCo?.addressDetail}` || ""
      );
      setValue("Invoicee_BizClass", receiveCo?.bizType || "");
      setValue("Invoicee_BizType", receiveCo?.bizItem || "");
      setValue("Invoicee_Tel", receiveCo?.tel || "");
      setValue("Fax_No", receiveCo?.fax || "");
      // setValue("ContactID", receiveCo?.contactId || "");
      // setValue("ContactPassword", receiveCo?.contactPassword || "");
    } else {
      setInvoicerData({
        Biz_No: receiveCo?.bizNo || "",
        Invoicer_CorpName: receiveCo?.companyName || "",
        Invoicer_CEOName: receiveCo?.ceoName || "",
        Invoicer_Addr:
          `${receiveCo?.address} ${receiveCo?.addressDetail}` || "",
        Invoicer_BizClass: receiveCo?.bizType || "",
        Invoicer_BizType: receiveCo?.bizItem || "",
        Invoicer_Tel: receiveCo?.tel || "",
        Invoicer_Fax: receiveCo?.fax || "",
      });
      setValue("Biz_No", receiveCo?.bizNo || "");
      setValue("Invoicer_CorpName", receiveCo?.companyName || "");
      setValue("Invoicer_CEOName", receiveCo?.ceoName || "");
      setValue(
        "Invoicer_Addr",
        `${receiveCo?.address} ${receiveCo?.addressDetail}` || ""
      );
      setValue("Invoicer_BizClass", receiveCo?.bizType || "");
      setValue("Invoicer_BizType", receiveCo?.bizItem || "");
      setValue("Invoicer_Tel", receiveCo?.tel || "");
      setValue("Invoicer_Fax", receiveCo?.fax || "");
      setValue("ContactID", receiveCo?.contactId || "");
      setValue("ContactPassword", receiveCo?.contactPassword || "");
    }
  }
  /* #endregion */

  const onToolbarPreparing = useCallback((e) => {
    e.toolbarOptions.items.forEach((item) => {
      item.visible = false;
    });
  }, []);

  const onTempleteChanged = async (Oid) => {
    // fetch 후
    const list = await getTempleteSubByOid(Oid);
    const dataArr = list.data.sort((a, b) => {
      return a.sortNo - b.sortNo;
    });
    setGridColumn(dataArr.filter((cur) => cur.useYn === "1"));
  };

  const openMailModal = async () => {
    try {
      setIsLaoding(true);
      await sleep(500);
      const blob = await convertPdf();
      setMailPdf(blob);
      setOpenMailingModal(true);
    } catch (e) {
      alert("pdf변환 오류!");
    } finally {
      setIsLaoding(false);
    }
  };

  //거래명세서 출력 상태 값 업데이트
  const onPrtState = async () => {
    await updatePrtState(selectedRightRow);
    closingRightRef.current.instance.refresh();
  };
  //거래명세서 엑셀 전환
  const onExcelExport = async () => {
    try {
      const searchDate = searchObj; //거래기간
      const companyInfo = watch(); //공급자 정보 //공급받는자 정보
      // const receiveInfo = (await getCompanyData(selectedLeftRow)).data; //공급받는자 정보
      const dataColumn = gridColumn; //거래명세서 컬럼
      // const tempList = modalRef.current.instance.getVisibleRows(); //거래명세서 데이터;
      // const rows = tempList.map((cur) => cur.values); //거래명세서 출력된 데이터 정보
      const dataList = selectedRightRow; //거래명세서 전체 데이터 정보
      const etc = templeteInfo; //거래명세서 기타정보

      let rowIndex = 1;

      const rows = selectedRightRow.map((row) => {
        //거래명세서 출력된 데이터 정보
        const returnArr = [];
        for (const obj of dataColumn) {
          if (obj.printName === "No") {
            returnArr.push(rowIndex);
            rowIndex++;
          } else {
            returnArr.push(row[obj.columnName]);
          }
        }

        return returnArr;
      });

      // 엑셀저장 return 값  성공 = 파일명 , 실패 = 공백
      const { data } = await excelExport(
        searchDate,
        companyInfo,
        dataColumn,
        rows,
        dataList,
        etc
      );

      if (data === "") {
        alert("저장실패", "엑셀저장");
      } else {
        fileDownload(data);
        alert("저장완료", "엑셀저장");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fileDownload = (fileName) => {
    const atag = document.createElement("a");
    atag.href = `${process.env.REACT_APP_API_URL}/excel-export/excel/${fileName}`;

    setTimeout(() => {
      atag.click();
      atag.remove();
    }, 800);
  };

  //팩스전송
  const onFaxExport = async () => {
    try {
      console.log(printRef.current);
      console.log(document.querySelector("#pdf-form"));
      const canvas = await html2canvas(document.querySelector("#pdf-form"), {
        //  canvas: canvas,
        // scale: 1,
        height: 1200,
        // width: 1280,
        windowHeight: 1200,
        // windowWidth: 1280,
      });
      const imageDataURL = canvas.toDataURL("image/png");
      const companyInfo = watch(); //공급자 정보 //공급받는자 정보
      // const etc = templeteInfo; //거래명세서 기타정보

      //수신거래처 팩스 정보 체크
      if (companyInfo["Fax_No"] === "") {
        alert("거래처 수신 팩스번호정보가 없습니다.");
        //발신거래처 팩스 정보 체크
      } else if (companyInfo["Invoicer_Fax"] === "") {
        alert("회사 발신 팩스번호정보가 없습니다.");
      } else {
        const result = await faxExport(companyInfo, imageDataURL);
        alert(result.data["result"]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  //거래명세서 프린트 출력
  const onClickPrint = () => {
    onPrtState();
    handlePrint();
  };

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    documentTitle: "거래명세서",
  });

  return (
    <>
      <ModalContainer
        onClose={onClose}
        maskClosable={maskClosable}
        visible={visible}
        size="xl"
        className="w-1100px closing-modal"
      >
        {isLoading && <Loading type="page" text="파일 변환 중입니다..." />}
        <form className="modal-form" ref={printRef}>
          <div className="modal-form-header">
            <div className="modal-form-tit">
              <span className="modal-tit">거래명세서</span>
            </div>
            <button
              type="button"
              className="btn-modal btn-modal-close no-print"
              onClick={onClose}
            >
              <SVG
                src={toAbsoluteUrl(
                  "/media/svg/icons/Custom/close_black_24dp.svg"
                )}
                className="close-icon"
              />
            </button>
          </div>
          <div className="modal-form-body" id="pdf-form">
            <ModalTaxIssueWrapper
            // ref={printRef}
            >
              <ModalTaxIssueHeader modalHeader>거래명세서</ModalTaxIssueHeader>
              <ModalTaxIssueHeader tradeset className="taxissue-header-print">
                <ModalTaxIssueTitle tradeset>
                  거래기간:{" "}
                  {moment(searchObj.firstDay).format("YYYY년 MM월 DD일")} ~{" "}
                  {moment(searchObj.lastDay).format("YYYY년 MM월 DD일")}
                </ModalTaxIssueTitle>
                <ModalTaxIssueSubtit tradeset>
                  발행일자: {moment().format("YYYY-MM-DD")}
                </ModalTaxIssueSubtit>
              </ModalTaxIssueHeader>
              <ModalTaxIssueBody>
                <TradesetWrapper>
                  <Tradeset>
                    <TradesetBody>
                      <TradesetRows>
                        <TradesetItems
                          width="30px"
                          rowSpan={5}
                          className="tradeset-tit"
                        >
                          공<br />급<br />자
                        </TradesetItems>
                        <TradesetItems className="item-tit">
                          등록번호
                        </TradesetItems>
                        <TradesetItems colSpan={3}>
                          {/* <InputText
                            control={control}
                            errors={errors}
                            disabled={true}
                            className="readonly a_c valignm form-s"
                            name="Biz_No"
                            maxLength={14}
                            tabIndex={4}
                          /> */}
                          <div className="item-input">
                            {invoicerData.Biz_No}
                          </div>
                        </TradesetItems>
                      </TradesetRows>

                      <TradesetRows>
                        <TradesetItems className="item-tit">상호</TradesetItems>
                        <TradesetItems>
                          {/* <InputTextArea
                            control={control}
                            errors={errors}
                            disabled={true}
                            name="Invoicer_CorpName"
                            className="readonly scroll"
                            maxLength={200}
                            tabIndex={6}
                          /> */}
                          <div className="item-input">
                            {invoicerData.Invoicer_CorpName}
                          </div>
                        </TradesetItems>
                        <TradesetItems className="item-tit">성명</TradesetItems>
                        <TradesetItems>
                          {/* <InputTextArea
                            control={control}
                            errors={errors}
                            disabled={true}
                            name="Invoicer_CEOName"
                            className="readonly scroll"
                            maxLength={100}
                            tabIndex={7}
                          /> */}
                          <div className="item-input">
                            {invoicerData.Invoicer_CEOName}
                          </div>
                        </TradesetItems>
                      </TradesetRows>

                      <TradesetRows>
                        <TradesetItems className="item-tit">주소</TradesetItems>
                        <TradesetItems colSpan={3}>
                          {/* <InputTextArea
                            control={control}
                            errors={errors}
                            name="Invoicer_Addr"
                            disabled={true}
                            className="readonly scroll"
                            maxLength={300}
                            tabIndex={8}
                          /> */}
                          <div className="item-input">
                            {invoicerData.Invoicer_Addr}
                          </div>
                        </TradesetItems>
                      </TradesetRows>

                      <TradesetRows>
                        <TradesetItems className="item-tit">업태</TradesetItems>
                        <TradesetItems>
                          {/* <InputTextArea
                            control={control}
                            errors={errors}
                            name="Invoicer_BizClass"
                            disabled={true}
                            className="readonly scroll"
                            maxLength={100}
                            tabIndex={9}
                          /> */}
                          <div className="item-input">
                            {invoicerData.Invoicer_BizClass}
                          </div>
                        </TradesetItems>
                        <TradesetItems className="item-tit">종목</TradesetItems>
                        <TradesetItems>
                          {/* <InputTextArea
                            control={control}
                            errors={errors}
                            name="Invoicer_BizType"
                            disabled={true}
                            className="readonly scroll"
                            maxLength={300}
                            tabIndex={10}
                          /> */}
                          <div className="item-input">
                            {invoicerData.Invoicer_BizType}
                          </div>
                        </TradesetItems>
                      </TradesetRows>

                      <TradesetRows>
                        <TradesetItems className="item-tit">
                          전화번호
                        </TradesetItems>
                        <TradesetItems>
                          {/* <InputTel
                            control={control}
                            errors={errors}
                            name="Invoicer_Tel"
                            disabled={true}
                            className="readonly form-s"
                            tabIndex={11}
                          /> */}
                          <div className="item-input">
                            {invoicerData.Invoicer_Tel}
                          </div>
                        </TradesetItems>
                        <TradesetItems className="item-tit">FAX</TradesetItems>
                        <TradesetItems>
                          {/* <InputText
                            control={control}
                            errors={errors}
                            name="Invoicer_Fax"
                            disabled={true}
                            className="readonly form-s"
                            tabIndex={12}
                          /> */}
                          <div className="item-input">
                            {invoicerData.Invoicer_Fax}
                          </div>
                        </TradesetItems>
                      </TradesetRows>
                    </TradesetBody>
                  </Tradeset>

                  <Tradeset>
                    <TradesetBody>
                      <TradesetRows>
                        <TradesetItems
                          width="30px"
                          rowSpan={5}
                          className="tradeset-tit"
                        >
                          공<br />급<br />받<br />는<br />자
                        </TradesetItems>
                        <TradesetItems className="item-tit">
                          등록번호
                        </TradesetItems>
                        <TradesetItems colSpan={3}>
                          {/* <InputText
                            control={control}
                            errors={errors}
                            className="readonly a_c valignm form-s"
                            name="Biz_No_Return"
                            disabled={true}
                            maxLength={14}
                            tabIndex={14}
                          /> */}
                          <div className="item-input">
                            {invoiceeData.Biz_No_Return}
                          </div>
                        </TradesetItems>
                      </TradesetRows>

                      <TradesetRows>
                        <TradesetItems className="item-tit">상호</TradesetItems>
                        <TradesetItems>
                          {/* <InputTextArea
                            control={control}
                            errors={errors}
                            name="Invoicee_CorpName"
                            className="readonly scroll"
                            disabled={true}
                            maxLength={200}
                            tabIndex={16}
                          /> */}
                          <div className="item-input">
                            {invoiceeData.Invoicee_CorpName}
                          </div>
                        </TradesetItems>
                        <TradesetItems className="item-tit">성명</TradesetItems>
                        <TradesetItems>
                          {/* <InputTextArea
                            control={control}
                            errors={errors}
                            name="Invoicee_CEOName"
                            disabled={true}
                            className="readonly scroll"
                            maxLength={100}
                            tabIndex={17}
                          /> */}
                          <div className="item-input">
                            {invoiceeData.Invoicee_CEOName}
                          </div>
                        </TradesetItems>
                      </TradesetRows>

                      <TradesetRows>
                        <TradesetItems className="item-tit">주소</TradesetItems>
                        <TradesetItems colSpan={3}>
                          {/* <InputTextArea
                            control={control}
                            errors={errors}
                            name="Invoicee_Addr"
                            disabled={true}
                            className="readonly scroll"
                            maxLength={300}
                            tabIndex={18}
                          /> */}
                          <div className="item-input">
                            {invoiceeData.Invoicee_Addr}
                          </div>
                        </TradesetItems>
                      </TradesetRows>

                      <TradesetRows>
                        <TradesetItems className="item-tit">업태</TradesetItems>
                        <TradesetItems>
                          {/* <InputTextArea
                            control={control}
                            errors={errors}
                            name="Invoicee_BizClass"
                            className="readonly scroll"
                            disabled={true}
                            maxLength={100}
                            tabIndex={19}
                          /> */}
                          <div className="item-input">
                            {invoiceeData.Invoicee_BizClass}
                          </div>
                        </TradesetItems>
                        <TradesetItems className="item-tit">종목</TradesetItems>
                        <TradesetItems>
                          {/* <InputTextArea
                            control={control}
                            errors={errors}
                            name="Invoicee_BizType"
                            disabled={true}
                            className="readonly scroll"
                            maxLength={300}
                            tabIndex={10}
                          /> */}
                          <div className="item-input">
                            {invoiceeData.Invoicee_BizType}
                          </div>
                        </TradesetItems>
                      </TradesetRows>

                      <TradesetRows>
                        <TradesetItems className="item-tit">
                          전화번호
                        </TradesetItems>
                        <TradesetItems>
                          {/* <InputTel
                            control={control}
                            errors={errors}
                            name="Invoicee_Tel"
                            disabled={true}
                            className="readonly form-s"
                            tabIndex={21}
                          /> */}
                          <div className="item-input">
                            {invoiceeData.Invoicee_Tel}
                          </div>
                        </TradesetItems>
                        <TradesetItems className="item-tit">FAX</TradesetItems>
                        <TradesetItems>
                          <InputText
                            control={control}
                            errors={errors}
                            name="Fax_No"
                            className="form-s item-input"
                            tabIndex={22}
                          />
                        </TradesetItems>
                      </TradesetRows>
                    </TradesetBody>
                  </Tradeset>
                </TradesetWrapper>

                <AdditionalWrapper tradeset>
                  <DataGrid
                    className="datagrid-wrap multi-line no-print"
                    id="ClosingModal"
                    dataSource={selectedRightRow}
                    ref={modalRef}
                    showRowLines={true}
                    showBorders={true}
                    hoverStateEnabled={true}
                    allowColumnResizing={true}
                    allowColumnReordering={true}
                    columnResizingMode="widget"
                    keyExpr="id"
                    columnAutoWidth={true}
                    onToolbarPreparing={onToolbarPreparing}
                  >
                    <Scrolling
                      mode="virtual"
                      showScrollbar="always"
                      useNative="false"
                    />
                    <Editing mode="batch" allowUpdating={true} />
                    {gridColumn.map((cur) => {
                      if (cur.sortText === "좌측") {
                        cur.sortText = "left";
                      } else if (cur.sortText === "가운데") {
                        cur.sortText = "center";
                      } else if (cur.sortText === "우측") {
                        cur.sortText = "right";
                      }
                      if (cur.printName === "No") {
                        return (
                          <Column
                            caption="No"
                            width="28"
                            cellRender={(e) => e.row.loadIndex + 1}
                          />
                        );
                      } else if (
                        cur.printName === "금액" ||
                        cur.printName === "부가세" ||
                        cur.printName === "합계금액"
                      ) {
                        return (
                          <Column
                            key={cur.oid}
                            dataField={cur.columnName}
                            caption={cur.printName}
                            width={cur.width || undefined}
                            alignment={cur.sortText}
                            dataType="number"
                          >
                            <Format type="fixedPoint" />
                          </Column>
                        );
                      } else if (
                        cur.printName === "입항일" ||
                        cur.printName === "출항일"
                      ) {
                        return (
                          <Column
                            key={cur.oid}
                            dataField={cur.columnName}
                            caption={cur.printName}
                            width={cur.width || undefined}
                            alignment={cur.sortText}
                            dataType="date"
                            format="yyyy-MM-dd"
                          />
                        );
                      } else if (cur.printName === "수입/수출") {
                        return (
                          <Column
                            key={cur.oid}
                            dataField={cur.columnName}
                            caption={cur.printName}
                            width={cur.width || undefined}
                            alignment={cur.sortText}
                            // cellRender={(e) => {
                            //   if (e.value === 1) {
                            //     return "수입";
                            //   } else {
                            //     return "수출";
                            //   }
                            // }}
                          />
                        );
                      } else {
                        return (
                          <Column
                            key={cur.oid}
                            dataField={cur.columnName}
                            caption={cur.printName}
                            width={cur.width || undefined}
                            alignment={cur.sortText}
                          />
                        );
                      }
                    })}
                  </DataGrid>

                  <AdditionalGrid
                    className="print-out"
                    gridColumn={gridColumn.length}
                  >
                    <GridHeader>
                      {gridColumn.map((cur, idx) => {
                        // width 비율
                        let widthPercentage;

                        if (totalWidth >= MAX_WIDTH) {
                          // 전체 column width가 a4용지보다 크면? 비율로 줄이기
                          widthPercentage = (cur.width / totalWidth) * 100;
                          // widthPercentage = ((cur.width / totalWidth) * 100).toFixed(2); 소수점 둘째자리
                        } else {
                          // 전체 column width의 합이 a4용지너비보다 작으면 MAX_WIDTH으로 나누고 빈칸하나 추가
                          widthPercentage = (cur.width / MAX_WIDTH) * 100;
                        }

                        return (
                          <GridHeaderItem
                            className="item-content"
                            width={widthPercentage}
                            alignTxt={cur.sortText}
                          >
                            {cur.printName}
                          </GridHeaderItem>
                        );
                      })}

                      {totalWidth < MAX_WIDTH && (
                        <>
                          <GridHeaderItem
                            key={"blankGrid"}
                            className="last-cell"
                          />
                        </>
                      )}
                    </GridHeader>
                    {selectedRightRow.map((item, idx) => {
                      return (
                        <GridTr
                          key={idx}
                          className="print-item"
                          alignTxt={item.sortText}
                        >
                          {gridColumn.map((cur, col) => {
                            const data = cur.columnName;

                            // width 비율
                            let widthPercentage;

                            if (totalWidth >= MAX_WIDTH) {
                              // 전체 column width가 a4용지보다 크면? 비율로 줄이기
                              widthPercentage = (cur.width / totalWidth) * 100;
                              // widthPercentage = ((cur.width / totalWidth) * 100).toFixed(2); 소수점 둘째자리
                            } else {
                              // 전체 column width의 합이 a4용지너비보다 작으면 MAX_WIDTH으로 나누고 빈칸하나 추가
                              widthPercentage = (cur.width / MAX_WIDTH) * 100;
                            }

                            if (cur.printName === "No") {
                              return (
                                <GridItem
                                  width={widthPercentage}
                                  key={col}
                                  alignTxt={cur.sortText}
                                >
                                  {idx + 1}
                                </GridItem>
                              );
                            } else if (
                              data === "enterDay" ||
                              data === "sailDay"
                            ) {
                              const date = item[data]
                                ? moment(item[data]).format("YYYY-MM-DD")
                                : "";
                              return (
                                <GridItem
                                  width={widthPercentage}
                                  key={col}
                                  alignTxt={cur.sortText}
                                >
                                  {date}
                                </GridItem>
                              );
                            } else if (data === "price") {
                              const number = item[data]
                                .toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                              return (
                                <GridItem
                                  width={widthPercentage}
                                  key={col}
                                  alignTxt={cur.sortText}
                                >
                                  {number}
                                </GridItem>
                              );
                            } else if (data === "importExport") {
                              const importExport =
                                item[data] === 1 ? "수입" : "수출";
                              return (
                                <GridItem
                                  width={widthPercentage}
                                  key={col}
                                  alignTxt={cur.sortText}
                                >
                                  {importExport}
                                </GridItem>
                              );
                            } else {
                              return (
                                <GridItem
                                  width={widthPercentage}
                                  key={col}
                                  alignTxt={cur.sortText}
                                >
                                  {item[data]}
                                </GridItem>
                              );
                            }
                          })}
                          {totalWidth < MAX_WIDTH && (
                            <>
                              <GridItem
                                key={"blankGrid"}
                                className="last-cell"
                              />
                            </>
                          )}
                        </GridTr>
                      );
                    })}
                  </AdditionalGrid>
                </AdditionalWrapper>
              </ModalTaxIssueBody>

              <TotalWrapper>
                <div className="total-tit">합계금액</div>
                <div className="total-items">
                  <div className="item-tit">합계</div>
                  <div>
                    {/* {watch().Amount} */}
                    <InputNumber
                      control={control}
                      errors={errors}
                      disabled={true}
                      name="Amount"
                      className="readonly"
                    />
                  </div>
                </div>
                <div className="total-items">
                  <div className="item-tit">부가가치세</div>
                  <div>
                    {/* {watch().Tax} */}
                    <InputNumber
                      control={control}
                      errors={errors}
                      disabled={true}
                      name="Tax"
                      className="readonly"
                    />
                  </div>
                </div>
                <div className="total-items">
                  <div className="item-tit">총계</div>
                  <div>
                    {/* {watch().TotalAmount} */}
                    <InputNumber
                      control={control}
                      errors={errors}
                      disabled={true}
                      name="TotalAmount"
                      className="readonly"
                    />
                  </div>
                </div>
              </TotalWrapper>

              <ModalTaxIssueFooter tradeset className="taxissue-header-print">
                <ModalTaxIssueTitle tradeset>
                  은행명: {templeteInfo.bank}
                </ModalTaxIssueTitle>
                <ModalTaxIssueSubtit tradeset>
                  담당자 : {templeteInfo.manager}
                </ModalTaxIssueSubtit>
              </ModalTaxIssueFooter>
              <ModalTaxIssueFooter tradeset className="taxissue-header-print">
                <ModalTaxIssueTitle tradeset>
                  계좌번호: {templeteInfo.accountNo}
                </ModalTaxIssueTitle>
                <ModalTaxIssueSubtit tradeset>
                  Tel: {templeteInfo.tel}
                </ModalTaxIssueSubtit>
              </ModalTaxIssueFooter>
              <ModalTaxIssueFooter tradeset className="taxissue-header-print">
                <ModalTaxIssueTitle tradeset>
                  예금주:
                  {templeteInfo.owner}
                </ModalTaxIssueTitle>
                <ModalTaxIssueSubtit tradeset>
                  Fax: {templeteInfo.fax}
                </ModalTaxIssueSubtit>
              </ModalTaxIssueFooter>
            </ModalTaxIssueWrapper>
          </div>
          <div className="modal-form-footer">
            <button
              type="button"
              onClick={openMailModal}
              className="btn btn-modal btn-save"
              disabled={isLoading}
            >
              메일보내기
            </button>
            <button
              type="button"
              onClick={() => onFaxExport()}
              className="btn btn-modal btn-save"
            >
              팩스
            </button>
            <button
              type="button"
              onClick={() => onExcelExport()}
              className="btn btn-modal btn-save"
            >
              엑셀
            </button>
            <button
              type="button"
              onClick={onClickPrint}
              className="btn btn-modal btn-save"
            >
              출력
            </button>
          </div>
        </form>
      </ModalContainer>
    </>
  );
};

export default ClosingModal;
