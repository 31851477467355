import React, { useState } from "react";
import { useForm } from "react-hook-form";

import { alert, confirm } from "devextreme/ui/dialog";
import { Modal, ModalBody } from "react-bootstrap";

import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";
import { createTaxIssue } from "../FinishManageCrud";

// import * as requestServer from "./TaxIssueCrud";

function TaxissueSubmitModal({
  onClose,
  onCloseParentModal,
  modalData,
  closable,
  visible,
  maskClosable,
  rowData,
}) {
  // states
  const [isConfirm, setIsConfirm] = useState();
  const { handleSubmit } = useForm({});

  // *functions*
  // modals
  const onMaskClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  // hook form
  const onSubmit = async () => {
    try {
      const check = await confirm("세금계산서를 발행하시겠습니까?", "확인");
      if (!check) return;

      if (isConfirm === 0) {
        // 즉시발행일 때
        modalData.main["taxStatus"] = "Publish-100";
        modalData.main["sendType"] = 1;

        const data = await createTaxIssue(modalData);
        console.log("즉시발행", data);

        await alert(data.data.message, "알림");
      } else if (isConfirm === 1) {
        // 예약발행일 때
        modalData.main["taxStatus"] = "Publish-150";
        modalData.main["sendType"] = 2;

        const data = await createTaxIssue(modalData);
        console.log("예약발행", data);

        await alert(data.data.message, "알림");
      }
      onCloseParentModal();
    } catch (err) {
      alert(err?.response?.data?.message, "오류");
    }
  };

  return (
    <>
      <div className={"modal-overlay " + (visible ? "active" : "")} />
      <div
        className={"modal-wrap user-list-modal " + (visible ? "active" : "")}
        onClick={maskClosable ? onMaskClick : null}
        tabIndex="-1"
        visible={`${visible}`}
      >
        <Modal.Dialog centered tabIndex="0" size="sm" animation="true">
          <ModalBody>
            <form className="modal-form" onSubmit={handleSubmit(onSubmit)}>
              <div className="modal-form-header">
                <div className="modal-form-tit">
                  <span className="modal-tit">발급 옵션 선택</span>
                </div>
                <button type="button" className="btn-modal btn-modal-close">
                  <SVG
                    src={toAbsoluteUrl(
                      "/media/svg/icons/Custom/close_black_24dp.svg"
                    )}
                    className="close-icon"
                    onClick={onClose}
                  />
                </button>
              </div>

              <div className="modal-form-body body-btn-set">
                <button
                  type="submit"
                  onClick={() => {
                    setIsConfirm(0);
                  }}
                  className="btn btn-modal btn-select-adjust"
                >
                  즉시발행하기
                </button>
                <button
                  type="submit"
                  onClick={() => {
                    setIsConfirm(1);
                  }}
                  className="btn btn-modal btn-select-adjust"
                >
                  예약발행하기
                </button>
              </div>
              <div className="modal-form-footer"></div>
            </form>
          </ModalBody>
        </Modal.Dialog>
      </div>
    </>
  );
}

export default TaxissueSubmitModal;
