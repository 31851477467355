import CustomStore from "devextreme/data/custom_store";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { shallowEqual, useSelector } from "react-redux";
import { sendRequest } from "../../../../_metronic/_helpers/DataSoureHelpers";

import { DataGrid } from "devextreme-react";
import {
  Column,
  ColumnChooser,
  FilterRow,
  HeaderFilter,
  Scrolling,
  Selection,
  StateStoring,
} from "devextreme-react/data-grid";
import moment from "moment";

import { alert } from "devextreme/ui/dialog";
import { useCallback } from "react";
import InputCheckBox from "../../../components/InputForm/InputCheckBox";
import InputDate from "../../../components/InputForm/InputDate";
import InputSelect from "../../../components/InputForm/InputSelect";
import StateStoringSaveAndDeleteButton from "../../../components/StateStoring";

import CustomButton from "../../../components/FunctionButton/CustomButton";
import { InputText } from "../../../components/InputForm";
import HeaderTitle from "../../../components/Layout/Header/HeaderTitle";
import {
  DataCount,
  PanelGridInit,
  PanelGridSearchBar,
  PanelGridWrapper,
  SearchItems,
} from "../../../components/Layout/Panel/PanelGrid/panelGrid.style";
import {
  PanelBody,
  PanelBtn,
  PanelHeader,
  PanelInit,
} from "../../../components/Layout/Panel/panel.style";
import { useDatagrid } from "../../../hooks/useDatagrid";
import * as Modals from "./components/Modal";
import { getLookupDatas } from "./components/ReturnListManagaCrud";

function ReturnListManagePage(props) {
  /* #region useState --------------------------------------------------------------------- */

  const [warehouseArriveModal, setWarehouseArriveModal] = useState(false);
  const [containerModal, setContainerModal] = useState(false);
  const [shipModal, setShipModal] = useState(false);
  const [sailModal, setSailModal] = useState(false);
  const [fridgeDangerModal, setFridgeDangerModal] = useState(false);
  const [returnDateModal, setReturnDateModal] = useState(false);
  const [chassisInfoModal, setChassisInfoModal] = useState(false);

  const [lookupData, setLookupData] = useState({
    dispatch: [],
    color: [],
    chassisType: [],
  });

  const [totalCount, setTotalCount] = useState(0);
  const dataGridRef = useRef();

  useDatagrid(dataGridRef);

  const [rowData, setRowData] = useState();

  const storageKey = "returnListManageMaster";

  const [search, setSearch] = useState({});
  /* #endregion */

  /* #region useSelectors --------------------------------------------------------------------- */

  const { user } = useSelector(
    (state) => ({ user: state.auth.user.user }),
    shallowEqual
  );

  /* #endregion */

  const initialState = {
    firstDay: moment().format("YYYY-MM-DD"),
    lastDay: moment().format("YYYY-MM-DD"),
    authGroupId: user.authGroupId,
    importExport: 0,
    isFinish: false,
    isDanger: false,
    dispatchTeam: user?.deptCode || "",
    motherShip: "",
  };

  // states
  // const [focusedComponent, setFocusedComponent] = useState();

  const dataSource = useMemo(() => {
    const apiUrl = `${process.env.REACT_APP_API_URL}/returnListManage/`;

    return new CustomStore({
      key: "detailId",
      load: () => sendRequest(apiUrl, "GET", search),
    });
  }, [search]);

  // hook form
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  // useEffects
  useEffect(() => {
    reset(initialState);
    setSearch(initialState);
    getLookup();
    // eslint-disable-next-line
  }, []);

  /* #region datagrid function --------------------------------------------------------------------- */
  // *functions*

  const onSubmit = (data) => {
    for (const obj in data) {
      if (data[obj] === "") {
        data[obj] = null;
      }
    }
    const submit = {
      ...data,
      authGroupId: user.authGroupId,
      // dispatchTeam: user?.deptCode,
    };

    setSearch(submit);
  };

  /* #endregion */

  /* #region datagrid function --------------------------------------------------------------------- */

  // dataGrids
  const onCellClick = useCallback((e) => {
    if (!e.row) return;

    const row = e.row;
    if (row.rowType === "data") {
      setRowData(row.data);
    }
  }, []);

  const onCellPrepared = useCallback(
    (e) => {
      if (e.rowType !== "data") return;
      if (
        e.column.caption !== "반입지" &&
        e.column.caption !== "하차지" &&
        e.column.caption !== "샤시종류"
      )
        return;

      if (e.column.caption === "반입지") {
        e.cellElement.style.background = e.data.loadPlaceColor || "transparant";
      } else if (e.column.caption === "하차지") {
        e.cellElement.style.background =
          e.data.unloadPlaceColor || "transparant";
      } else if (
        e.column.caption === "샤시종류" &&
        e.data.chassisType === lookupData.chassisType[0]?.subName
      ) {
        const green = lookupData.color.filter(
          (cur) => cur.subCode === "CLOR-GREEN"
        )[0];

        e.cellElement.style.background = green?.extra1 || "transparant";
      }
    },
    [lookupData]
  );

  const importExportCellRender = useCallback((e) => {
    if (e.data.importExport === 1) {
      return <div>수입</div>;
    } else {
      return <div>수출</div>;
    }
  }, []);

  /* #endregion */

  /* #region modal function --------------------------------------------------------------------- */

  const setWarehouseArrive = () => {
    if (!rowData) {
      alert("데이터를 선택해주세요", "오류");
      return;
    }

    setWarehouseArriveModal(true);
  };

  const setContainerInfo = () => {
    if (!rowData) {
      alert("데이터를 선택해주세요", "오류");
      return;
    }

    setContainerModal(true);
  };

  const setShipInfo = () => {
    if (!rowData) {
      alert("데이터를 선택해주세요", "오류");
      return;
    }

    setShipModal(true);
  };

  const setSailDate = () => {
    if (!rowData) {
      alert("데이터를 선택해주세요", "오류");
      return;
    }

    setSailModal(true);
  };

  const setFridgeDangerInfo = () => {
    if (!rowData) {
      alert("데이터를 선택해주세요", "오류");
      return;
    }

    setFridgeDangerModal(true);
  };

  const setReturnDateInfo = () => {
    if (!rowData) {
      alert("데이터를 선택해주세요", "오류");
      return;
    }

    setReturnDateModal(true);
  };

  const setChassisInfo = () => {
    if (!rowData) {
      alert("데이터를 선택해주세요", "오류");
      return;
    }

    setChassisInfoModal(true);
  };

  const getLookup = async () => {
    try {
      const [dispatchData, colorData, chassisTypeData] = await Promise.all([
        getLookupDatas("department/isDispatch"),
        getLookupDatas("comcode/detail/10900"),
        getLookupDatas("comcode/detail/30300"),
      ]);

      setLookupData({
        dispatch: [{ code: "", deptName: "전체" }, ...dispatchData.data],
        color: [{ subCode: null, subName: "" }, ...colorData.data],
        chassisType: chassisTypeData.data.filter((cur) => cur.id === 101),
      });
    } catch (error) {
      console.log(error);
    }
  };

  /* #endregion */

  return (
    <>
      <PanelInit>
        <PanelHeader>
          <HeaderTitle />
          <PanelBtn>
            <CustomButton
              type="button"
              height={24}
              layout="solid"
              color="common"
              label="입/출항 변경"
              onClick={setSailDate}
            />
            <CustomButton
              type="button"
              height={24}
              layout="solid"
              color="new"
              label="창고입고날짜"
              onClick={setWarehouseArrive}
            />
            <CustomButton
              type="button"
              height={24}
              layout="solid"
              color="new"
              label="컨테이너 정보변경"
              onClick={setContainerInfo}
            />
            <CustomButton
              type="button"
              height={24}
              layout="solid"
              color="new"
              label="선사/선명 변경"
              onClick={setShipInfo}
            />
            <CustomButton
              type="button"
              height={24}
              layout="solid"
              color="new"
              label="냉동/위험물 정보변경"
              onClick={setFridgeDangerInfo}
            />
            <CustomButton
              type="button"
              height={24}
              layout="solid"
              color="new"
              label="반납일자/반납마감일자 변경"
              onClick={setReturnDateInfo}
            />
            <CustomButton
              type="button"
              height={24}
              layout="solid"
              color="new"
              label="샤시입력"
              onClick={setChassisInfo}
            />
          </PanelBtn>
        </PanelHeader>
        <PanelBody row left="100%" right="0">
          <PanelGridWrapper>
            <PanelGridSearchBar onSubmit={handleSubmit(onSubmit)}>
              <SearchItems>
                <label>기준일자</label>
                <InputDate
                  control={control}
                  errors={errors}
                  name="firstDay"
                  flex={true}
                />
                <div className="to-mark">~</div>
                <InputDate
                  control={control}
                  errors={errors}
                  name="lastDay"
                  flex={true}
                />
              </SearchItems>
              <SearchItems>
                <label>배차팀</label>
                <InputSelect
                  control={control}
                  errors={errors}
                  displayExpr="deptName"
                  valueExpr="code"
                  name="dispatchTeam"
                  dataSource={lookupData.dispatch}
                  flex={true}
                />
              </SearchItems>
              <SearchItems>
                <label>수입/수출</label>
                <InputSelect
                  control={control}
                  errors={errors}
                  dataSource={[
                    { id: 0, name: "전체" },
                    { id: 1, name: "수입" },
                    { id: 2, name: "수출" },
                  ]}
                  valueExpr="id"
                  displayExpr="name"
                  name="importExport"
                  flex={true}
                />
              </SearchItems>
              <SearchItems>
                <label>모선/항차</label>
                <InputText
                  control={control}
                  errors={errors}
                  name="motherShip"
                />
              </SearchItems>

              <SearchItems>
                <label>완료건 검색</label>
                <InputCheckBox
                  control={control}
                  errors={errors}
                  name="isFinish"
                />
              </SearchItems>
              <SearchItems>
                <label>위험물 검색</label>
                <InputCheckBox
                  control={control}
                  errors={errors}
                  name="isDanger"
                />
              </SearchItems>
              <CustomButton
                type="submit"
                height={24}
                layout="solid"
                color="search"
                label="검색"
              />
            </PanelGridSearchBar>

            <PanelGridInit>
              <StateStoringSaveAndDeleteButton
                dataGridRef={dataGridRef}
                storageKey={storageKey}
              />
              <DataCount>{`총 ${totalCount}건`}</DataCount>
              <DataGrid
                className="datagrid-wrap"
                id="ReturnListManage"
                keyExpr="detailId"
                dataSource={dataSource}
                ref={dataGridRef}
                showRowLines={true}
                showBorders={true}
                hoverStateEnabled={true}
                allowColumnResizing={true}
                allowColumnReordering={true}
                columnAutoWidth={true}
                columnResizingMode="widget"
                focusedRowEnabled={true}
                onCellPrepared={onCellPrepared}
                onCellClick={onCellClick}
                onContentReady={(e) => setTotalCount(e.component.totalCount())}
              >
                <Selection
                  mode="multiple"
                  allowSelectAll={false}
                  showCheckBoxesMode="always"
                />
                <FilterRow visible={true} />
                <HeaderFilter visible={true} />
                <ColumnChooser enabled={true} />
                <StateStoring
                  enabled={true}
                  type="custom"
                  customLoad={useCallback(() => {
                    return JSON.parse(localStorage.getItem(storageKey));
                  }, [])}
                />
                <Scrolling
                  columnRenderingMode="virtual"
                  mode="virtual"
                  showScrollbar="always"
                  useNative="false"
                />
                <Column
                  dataField="code"
                  caption="접수번호"
                  alignment="center"
                />
                <Column
                  dataField="importExport"
                  caption="수출/수입"
                  alignment="center"
                  width="96"
                  cellRender={importExportCellRender}
                />
                <Column
                  dataField="cargoOwnerName"
                  caption="화주"
                  alignment="center"
                />
                <Column
                  dataField="warehouseArrive"
                  caption="창고입고날짜"
                  alignment="center"
                />
                <Column
                  dataField="returnDate"
                  caption="반납일자"
                  alignment="center"
                />
                <Column
                  dataField="returnFinishDate"
                  caption="반납마감일자"
                  alignment="center"
                />
                <Column
                  dataField="containerNo"
                  caption="컨테이너 No"
                  alignment="center"
                />
                <Column
                  dataField="sealNo"
                  caption="Seal No"
                  alignment="center"
                />
                <Column
                  dataField="containerType"
                  caption="컨/규격"
                  alignment="center"
                />
                <Column dataField="chassis" caption="샤시" alignment="center" />
                <Column
                  dataField="chassisType"
                  caption="샤시종류"
                  alignment="center"
                />
                <Column
                  dataField="forwarder"
                  caption="선사"
                  alignment="center"
                />
                <Column
                  dataField="shipName"
                  caption="선명"
                  alignment="center"
                />
                <Column
                  dataField="loadPlaceName"
                  caption="반입지"
                  alignment="center"
                />
                <Column
                  dataField="enterDay"
                  caption="입항"
                  alignment="center"
                  dataType="date"
                  format="yyyy-MM-dd hh:mm:ss"
                />
                <Column
                  dataField="sailDay"
                  caption="출항"
                  alignment="center"
                  dataType="date"
                  format="yyyy-MM-dd hh:mm:ss"
                />
                <Column
                  dataField="temperature"
                  caption="온도"
                  alignment="center"
                />
                <Column
                  dataField="humidity"
                  caption="습도"
                  alignment="center"
                />
                <Column dataField="unNo" caption="un번호" alignment="center" />
                <Column dataField="class" caption="클래스" alignment="center" />
                <Column
                  dataField="unloadPlaceName"
                  caption="하차지"
                  alignment="center"
                />
                {/* <Column
                dataField="orderDetails[0].unloadPlaceName"
                caption="하차지"
                alignment="center"
              /> */}
                <Column
                  dataField="cargoWeight"
                  caption="총중량"
                  alignment="center"
                />
                <Column
                  dataField="carNo"
                  caption="차량번호"
                  alignment="center"
                />
                <Column
                  dataField="motherShip"
                  caption="모선항차"
                  alignment="center"
                />
              </DataGrid>
            </PanelGridInit>
          </PanelGridWrapper>
        </PanelBody>
      </PanelInit>
      {warehouseArriveModal && (
        <Modals.WarehouseArriveModal
          onClose={() => setWarehouseArriveModal(false)}
          closable={true}
          visible={true}
          maskClosable={true}
          rowData={rowData}
          dataGridRef={dataGridRef}
        />
      )}
      {containerModal && (
        <Modals.ContainerModal
          onClose={() => setContainerModal(false)}
          closable={true}
          visible={true}
          maskClosable={true}
          rowData={rowData}
          dataGridRef={dataGridRef}
        />
      )}
      {shipModal && (
        <Modals.ShipModal
          onClose={() => setShipModal(false)}
          closable={true}
          visible={true}
          maskClosable={true}
          rowData={rowData}
          dataGridRef={dataGridRef}
        />
      )}
      {sailModal && (
        <Modals.SailModal
          onClose={() => setSailModal(false)}
          closable={true}
          visible={true}
          maskClosable={true}
          rowData={rowData}
          dataGridRef={dataGridRef}
        />
      )}
      {fridgeDangerModal && (
        <Modals.FridgeDangerModal
          onClose={() => setFridgeDangerModal(false)}
          closable={true}
          visible={true}
          maskClosable={true}
          rowData={rowData}
          dataGridRef={dataGridRef}
        />
      )}
      {returnDateModal && (
        <Modals.SetReturnDate
          onClose={() => setReturnDateModal(false)}
          closable={true}
          visible={true}
          maskClosable={true}
          rowData={rowData}
          dataGridRef={dataGridRef}
        />
      )}
      {chassisInfoModal && (
        <Modals.SetChassisInfo
          onClose={() => setChassisInfoModal(false)}
          closable={true}
          visible={true}
          maskClosable={true}
          rowData={rowData}
          dataGridRef={dataGridRef}
        />
      )}
    </>
  );
}

export default ReturnListManagePage;
