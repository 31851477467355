import React from "react";
import HeaderTitle from "../../../components/Layout/Header/HeaderTitle";
import {
  PanelGridWrapper,
  PanelMultiGrid,
} from "../../../components/Layout/Panel/PanelGrid/panelGrid.style";
import {
  PanelBody,
  PanelHeader,
  PanelInit,
} from "../../../components/Layout/Panel/panel.style";
import CalculateProfitInput from "./components/CalculateProfitInput";
import CalculateProfitLowerGrid from "./components/CalculateProfitLowerGrid";
import CalculateProfitSearch from "./components/CalculateProfitSearch";
import CalculateProfitUpperGrid from "./components/CalculateProfitUpperGrid";
import useCalculateProfit from "./useCalculateProfit";
import Loading from "../../../components/Loading/Loading";

const CalculateProfitPage = () => {
  const {
    state,
    isLoading,

    upperGridRef,
    lowerGridRef,

    inputForm,
    searchForm,

    // pageFunctions,
    // inputFunctions,
    searchFunctions,
    // masterFunctions,
  } = useCalculateProfit();

  return (
    <>
      {isLoading && (
        <Loading type="page" text="손익계산서 페이지를 불러오는 중 입니다..." />
      )}
      <PanelInit>
        <PanelHeader>
          <HeaderTitle />
        </PanelHeader>
        <PanelBody row left="300px" right="calc(100% - 300px)">
          {/* 합계창 */}
          <CalculateProfitInput inputForm={inputForm} />

          <PanelGridWrapper>
            {/* 검색 */}
            <CalculateProfitSearch
              state={state}
              searchForm={searchForm}
              searchFunctions={searchFunctions}
            />
            <PanelMultiGrid column top="50%" bottom="50%">
              {/* 위쪽 grid */}
              <CalculateProfitUpperGrid
                state={state}
                upperGridRef={upperGridRef}
              />
              {/* 아래쪽 grid */}
              <CalculateProfitLowerGrid
                state={state}
                lowerGridRef={lowerGridRef}
              />
            </PanelMultiGrid>
          </PanelGridWrapper>
        </PanelBody>
      </PanelInit>
    </>
  );
};

export default CalculateProfitPage;
