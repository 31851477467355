import { yupResolver } from "@hookform/resolvers/yup";
import DataGrid, { Column } from "devextreme-react/data-grid";
import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { InputSelect, InputText } from "../../../../components/InputForm";
import ModalContainer from "../../../../components/Modal/ModalContainer";
import ModalHeader from "../../../../components/Modal/ModalHeader";
import {
  getBankAccountAndTel,
  getLookup,
  getTempleteSubByOid,
} from "../ClosingCrud";
import EditTempleteModal from "./EditTempleteModal";
import { shallowEqual, useSelector } from "react-redux";
import { ButtonGroup } from "../../../../components/FunctionButton/CustomButton/customButton.style";
import CustomButton from "../../../../components/FunctionButton/CustomButton/CustomButton";
import { alert } from "devextreme/ui/dialog";

const SpecificationTempleteModal = ({
  onClose,
  maskClosable,
  visible,
  search,
  selectedRow,
  selectedRightRow,
  onFinish,
}) => {
  const [newTempleteModal, setNewTempleteModal] = useState(false);
  const [editTempleteModal, setEditTempleteModal] = useState(false);
  const [gridColumn, setGridColumn] = useState([]);
  const [lookupData, setLookupData] = useState({ templete: [] });

  const previewRef = useRef();

  const schema = yup.object({
    templete: yup.string().required("거래명세서 양식을 선택해주세요"),
  });

  const { user } = useSelector(
    (state) => ({ user: state.auth.user.user }),
    shallowEqual
  );

  const {
    control,
    handleSubmit,
    setValue,
    watch,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    getLookupData();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getCompanyInfo(selectedRow, selectedRightRow);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    previewRef.current.instance.addColumn(
      gridColumn.map((cur) => {
        if (cur.sortText === "좌측") {
          cur.sortText = "left";
        } else if (cur.sortText === "가운데") {
          cur.sortText = "center";
        } else if (cur.sortText === "우측") {
          cur.sortText = "right";
        }
        return (
          <Column
            key={cur.oid}
            dataField={cur.columnName}
            caption={cur.printName}
            width={cur.width || 100}
            alignment={cur.sortText}
          />
        );
      })
    );
  }, [gridColumn]);

  useEffect(() => {
    // 검색창의 날짜구분을 똑같이 적용
    reset(search);
    // eslint-disable-next-line
  }, [search]);

  const onSubmit = async (data) => {
    onFinish(data);
  };

  const getCompanyInfo = async (rowData, selectedRightRow) => {
    try {
      const { data } = await getBankAccountAndTel({
        ...rowData,
        receiveCoName: selectedRightRow[0].companyName,
      });

      setValue("bank", data[0].bank);
      setValue("accountNo", data[0].acountNo);
      setValue("owner", data[0].depositOwner);
      setValue("manager", user.userName);
      setValue("tel", data[0].tel);
      setValue("fax", data[0].fax);
    } catch (error) {
      console.log(error);
    }
  };

  const onTempleteChanged = async (value) => {
    // fetch 후
    if (value.value === null) return;
    const list = await getTempleteSubByOid(value.value);
    const dataArr = list.data.sort((a, b) => {
      return a.sortNo - b.sortNo;
    });

    setGridColumn(dataArr.filter((cur) => cur.useYn === "1"));
  };

  const onFinishEditTemplete = async () => {
    // 닫고, refresh
    setNewTempleteModal(false);
    setEditTempleteModal(false);
    getLookupData();
    setValue("templete", undefined);
    previewRef.current.instance.addColumn([]);
  };

  async function getLookupData() {
    try {
      const templeteData = await getLookup("trade-sheet");

      setValue("templete", undefined);

      setLookupData({
        templete: templeteData.data,
      });
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <>
      <ModalContainer
        onClose={onClose}
        maskClosable={maskClosable}
        visible={visible}
        size="xl"
      >
        <form className="modal-form" onSubmit={handleSubmit(onSubmit)}>
          <ModalHeader title="템플릿 선택" onClose={onClose} />
          <div className="input-wrap">
            <div className="input-grid">
              <div className="dx-field">
                <InputSelect
                  control={control}
                  errors={errors}
                  dataSource={[
                    { id: 1, name: "접수일자" },
                    { id: 0, name: "운송일자" },
                  ]}
                  valueExpr="id"
                  displayExpr="name"
                  name="searchType"
                  label="날짜구분"
                  flex={true}
                />
              </div>
            </div>
            <div className="input-grid grid-1">
              <div className="dx-field">
                <InputSelect
                  control={control}
                  errors={errors}
                  dataSource={lookupData.templete}
                  valueExpr="oid"
                  displayExpr="title"
                  name="templete"
                  label="거래명세서양식"
                  onValueChanged={onTempleteChanged}
                  flex={true}
                />
                <ButtonGroup>
                  <CustomButton
                    type="button"
                    height={24}
                    layout="solid"
                    color="new"
                    label="신규양식"
                    onClick={() => {
                      setNewTempleteModal(true);
                    }}
                  />
                  <CustomButton
                    type="button"
                    height={24}
                    layout="solid"
                    color="common"
                    label="양식수정"
                    onClick={() => {
                      if (!watch("templete")) {
                        alert("거래명세서 양식을 선택해주세요", "오류");
                        return;
                      }
                      setEditTempleteModal(true);
                    }}
                  />
                </ButtonGroup>
              </div>
            </div>

            <div className="input-grid-tit">입금계좌/연락처</div>
            <div className="input-grid">
              <div className="dx-field">
                <InputText
                  control={control}
                  errors={errors}
                  name="bank"
                  label="은행명"
                  flex={true}
                />
              </div>

              <div className="dx-field">
                <InputText
                  control={control}
                  errors={errors}
                  name="accountNo"
                  label="계좌번호"
                  flex={true}
                />
              </div>
              <div className="dx-field">
                <InputText
                  control={control}
                  errors={errors}
                  name="owner"
                  label="예금주"
                  flex={true}
                />
              </div>
            </div>

            <div className="input-grid">
              <div className="dx-field">
                <InputText
                  control={control}
                  errors={errors}
                  name="manager"
                  label="담당자"
                  flex={true}
                />
              </div>

              <div className="dx-field">
                <InputText
                  control={control}
                  errors={errors}
                  name="tel"
                  label="전화번호"
                  flex={true}
                />
              </div>
              <div className="dx-field">
                <InputText
                  control={control}
                  errors={errors}
                  name="fax"
                  label="팩스번호"
                  flex={true}
                />
              </div>
            </div>
          </div>

          <DataGrid
            className="datagrid-wrap multi-line"
            ref={previewRef}
            showRowLines={true}
            showBorders={true}
            hoverStateEnabled={true}
            columnResizingMode="widget"
            keyExpr="id"
          >
            {gridColumn.map((cur) => {
              return (
                <Column
                  key={cur.oid}
                  dataField={cur.columnName}
                  caption={cur.printName}
                  width={cur.width || 100}
                  alignment={cur.sortText}
                />
              );
            })}
          </DataGrid>

          <div className="modal-form-footer">
            <button type="submit" className="btn btn-modal btn-save">
              명세서 출력
            </button>
          </div>
        </form>
      </ModalContainer>
      {newTempleteModal && (
        <EditTempleteModal
          onClose={() => setNewTempleteModal(false)}
          closable={true}
          visible={true}
          maskClosable={false}
          templeteData={lookupData.templete}
          onFinishEditTemplete={onFinishEditTemplete}
        />
      )}
      {editTempleteModal && (
        <EditTempleteModal
          onClose={() => setEditTempleteModal(false)}
          closable={true}
          visible={true}
          maskClosable={false}
          templeteData={lookupData.templete}
          templeteOid={watch("templete")}
          onFinishEditTemplete={onFinishEditTemplete}
        />
      )}
    </>
  );
};

export default SpecificationTempleteModal;
