import { SelectBox, TextBox } from "devextreme-react";
import React from "react";
import { Controller } from "react-hook-form";
import InputLayout from "./InputLayout";

const InputColorSelectBox = ({
  control,
  errors,
  name,
  label,
  labelWidth,
  flex,
  dataSource,
  displayExpr,
  valueExpr,
  required,
  disable,
  searchEnabled = true,
  onValueChanged = () => {},
  onInitialized = () => {},
  width,
}) => {
  const fieldRender = (data) => {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            width: "20px",
            height: "10px",
            backgroundColor: `${data && data.extra1}`,
          }}
        />
        <TextBox defaultValue={data && data.subName} readOnly={true} />
      </div>
    );
  };

  const itemRender = (data) => {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            width: "20px",
            height: "10px",
            backgroundColor: `${data.extra1}`,
          }}
        />
        <div className="product-name">{data.subName}</div>
      </div>
    );
  };

  return (
    <InputLayout label={label} required={required} errors={errors} name={name}>
      <Controller
        render={({ field: { onChange, value } }) => (
          <SelectBox
            items={dataSource}
            itemTemplate
            valueExpr={valueExpr}
            displayExpr={displayExpr}
            searchEnabled={searchEnabled}
            disabled={disable}
            onValueChanged={(value) => {
              onChange(value.value);
              onValueChanged(value);
            }}
            onInitialized={onInitialized}
            value={value}
            width={width}
            fieldRender={fieldRender}
            itemRender={itemRender}
          />
        )}
        control={control}
        name={name}
        defaultValue={null}
      />
    </InputLayout>
  );
};

export default InputColorSelectBox;
