import { yupResolver } from "@hookform/resolvers/yup";
import React from "react";
import { Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";
import InputDate from "../../../../components/InputForm/InputDate";
import * as yup from "yup";

const TaxIssueDateModal = ({
  onClose,
  maskClosable,
  closable,
  visible,
  selectedRow,
  setSelectedRow,
  setTaxIssueDateModalVisible,
}) => {
  const schema = yup.object({
    taxSchedule: yup
      .string()
      .nullable()
      .required("정산예정일자를 선택해주세요"),
  });
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onMaskClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  const onSubmit = async (data) => {
    setSelectedRow({ ...selectedRow, ...data });
    onClose();
  };

  return (
    <>
      <div className={"modal-overlay " + (visible ? "active" : "")} />
      <div
        className={"modal-wrap user-list-modal " + (visible ? "active" : "")}
        onClick={maskClosable ? onMaskClick : null}
        tabIndex="-1"
        visible={`${visible}`}
      >
        <Modal.Dialog centered tabIndex="0" size="sm" animation="true">
          <Modal.Body>
            <form className="modal-form" onSubmit={handleSubmit(onSubmit)}>
              <div className="modal-form-header">
                <div className="modal-form-tit">
                  <span className="modal-tit">정산</span>
                </div>
                <button
                  type="button"
                  className="btn-modal btn-modal-close"
                  onClick={() => setTaxIssueDateModalVisible(false)}
                >
                  <SVG
                    src={toAbsoluteUrl(
                      "/media/svg/icons/Custom/close_black_24dp.svg"
                    )}
                    className="close-icon"
                  />
                </button>
              </div>
              <div className="modal-form-body">
                <div className="d-flex flex-row mb-1">
                  <div className="dx-field">
                    <InputDate
                      control={control}
                      errors={errors}
                      name="taxSchedule"
                      label="정산예정일자"
                      flex={true}
                    />
                  </div>
                </div>
              </div>
              <div className="modal-form-footer">
                <button type="submit" className="btn btn-modal btn-save">
                  저장
                </button>
              </div>
            </form>
          </Modal.Body>
        </Modal.Dialog>
      </div>
    </>
  );
};

export default TaxIssueDateModal;
