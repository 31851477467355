import React, { useEffect, useState } from "react";
import { ModalContainer, ModalHeader } from "../Modal";
import { useForm } from "react-hook-form";
import { InputNumber, InputSelect } from "../InputForm";
import { getLookup } from "./ExtraChargeModalCrud";

const ExtraChangeAllModal = ({
  onClose,
  visible,
  maskClosable,
  extraChargeRef,
}) => {
  const [lookupData, setLookupData] = useState({
    division: [],
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    // orderInputDetail 룩업데이터
    initOrderInputDetailLookupData();
  }, []);

  const initOrderInputDetailLookupData = async () => {
    try {
      const [divisionData] = await Promise.all([
        getLookup("comcode/detail/10300"),
      ]);

      setLookupData({
        division: divisionData.data,
      });
    } catch (err) {
      console.log(err);
    }
  };

  const onSubmit = (data) => {
    const instance = extraChargeRef.current.instance;
    const rows = instance.getVisibleRows();

    for (const obj of rows) {
      const { rowIndex } = obj;

      for (const key in data) {
        if (!data[key]) continue;
        instance.cellValue(rowIndex, key, data[key]);
      }
    }

    onClose();
  };

  return (
    <ModalContainer
      onClose={onClose}
      maskClosable={maskClosable}
      visible={visible}
      size="sm"
    >
      <form className="modal-form" onSubmit={handleSubmit(onSubmit)}>
        <ModalHeader title="일괄적용" onClose={onClose} />
        <div className="modal-form-body">
          <div className="dx-field">
            <InputSelect
              control={control}
              errors={errors}
              dataSource={lookupData.division}
              valueExpr="id"
              displayExpr="subName"
              name="division"
              label="청구구분"
            />

            <InputNumber
              control={control}
              errors={errors}
              name="chargedAmount"
              label="할증금액"
            />
          </div>
        </div>
        <div className="modal-form-footer">
          <button type="submit" className="btn btn-modal btn-save">
            적용
          </button>
        </div>
      </form>
    </ModalContainer>
  );
};

export default ExtraChangeAllModal;
