import React from "react";
import { useDropzone } from "react-dropzone";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../_metronic/_helpers";

const DragDrop = ({ props, setFiles }) => {
  const {
    acceptedFiles,
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    maxFiles: 1,
    accept: ".csv, .xls, .xlsx",
    onDrop: (file) => {},
    onDropAccepted: (file) => {
      setFiles(file);
    },
    onDropRejected: (e) => {
      alert(".csv, .xls, .xlsx파일 1개만 업로드 가능 합니다.");
    },
  });

  return (
    <section className="container">
      <div
        {...getRootProps({
          className: "dropzone",
        })}
      >
        <input {...getInputProps()} />
        {isDragAccept && <p>All files will be accepted</p>}
        {isDragReject && <p>Some files will be rejected</p>}
        {!isDragActive && (
          <p className="dropZone-tit">
            <SVG
              src={toAbsoluteUrl(
                "/media/svg/icons/Custom/icon-upload-files.svg"
              )}
              className="close-icon"
            />
            <span>엑셀 파일을 선택하거나 드래그하여 이곳에 놓으십시오.</span>
          </p>
        )}
      </div>
      <aside>
        <div>파일명</div>
        <p>{acceptedFiles.length > 0 && acceptedFiles[0].path}</p>
      </aside>
    </section>
  );
};
export default DragDrop;
