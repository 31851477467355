import { Button } from "devextreme-react";
import React from "react";
import { InputAddBtnWrapper } from "../InputForm/InputLayout/inputLayout.style";
import InputSelect from "../InputForm/InputSelect";

const CompanySelect = ({
  control,
  errors,
  dataSource,
  onClick,
  name,
  label,
  disable,
}) => {
  return (
    <InputAddBtnWrapper>
      <InputSelect
        searchEnabled={true}
        control={control}
        errors={errors}
        name={name}
        label={label}
        flex={true}
        dataSource={dataSource}
        displayExpr="company"
        valueExpr="oid"
        disable={disable}
      />
      <Button icon="plus" onClick={onClick} disabled={disable} />
    </InputAddBtnWrapper>
  );
};

export default CompanySelect;
