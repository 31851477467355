import { TextBox } from "devextreme-react";
import React from "react";
import { Controller } from "react-hook-form";
import CustomButton from "../FunctionButton/CustomButton";
import InputLayout from "./InputLayout";
import { InputAddressWrapper } from "./InputLayout/inputLayout.style";
// import InputMask from "react-input-mask";

const InputBizAddress = ({
  control,
  errors,
  name,
  label,
  labelWidth,
  flex,
  width,
  height,
  maxLength,
  flexDirection,
  required,
  openPost,
  zipCode = "zipCode",
  address = "address",
  address1 = "address1",
}) => {
  return (
    <InputLayout
      label={label}
      required={required}
      errors={errors}
      name={name}
      labelWidth={labelWidth}
      zipCode={zipCode}
      address={address}
      address1={address1}
      addressInput
    >
      <InputAddressWrapper flexDirection={flexDirection}>
        <CustomButton
          type="button"
          height={25}
          layout="solid"
          color="search"
          label="주소검색"
          onClick={openPost}
        />
        <Controller
          render={({ field: { onChange, value } }) => {
            return (
              <TextBox
                onValueChange={(value) => {
                  onChange(value);
                }}
                height={height}
                width={width}
                maxLength={maxLength}
                value={`${value}`}
                disabled={true}
              />
            );
          }}
          control={control}
          name={address}
          defaultValue=""
        />

        <Controller
          render={({ field: { onChange, value } }) => {
            return (
              <TextBox
                onValueChange={(value) => {
                  onChange(value);
                }}
                height={height}
                width={width}
                maxLength={maxLength}
                placeholder="상세주소 입력"
                value={`${value}`}
              />
            );
          }}
          control={control}
          name={address1}
          defaultValue=""
        />
      </InputAddressWrapper>
    </InputLayout>
  );
};

export default InputBizAddress;
