import { Button } from "devextreme-react";
import React, { useCallback } from "react";
import { Controller } from "react-hook-form";
import InputLayout from "./InputLayout";

const InputFile = ({
  control,
  errors,
  name,
  fileName,
  label,
  labelWidth,
  required,
  disabled = false,
  onFileRemove = () => {},
  onFileSelected = () => {},
  fileTitle,
  fileUrl,
  fileInput,
}) => {
  // const fileInput = useRef();

  const onClickUpload = useCallback(() => {
    fileInput.current.click();
  }, [fileInput]);

  const onClickRemove = () => {
    fileInput.current.value = null;
    onFileRemove();
  };

  const fileDownload = useCallback(() => {
    const atag = document.createElement("a");
    atag.href = `${process.env.REACT_APP_API_URL}/img/${fileUrl}`;
    atag.click();

    atag.remove();
  }, [fileUrl]);

  // console.log(path);
  // useEffect(() => {
  //   console.log(fileUrl);
  // }, [fileUrl]);

  return (
    <InputLayout
      label={label}
      required={required}
      errors={errors}
      name={name}
      labelWidth={labelWidth}
    >
      <Controller
        render={({ field: { onChange, value } }) => {
          return (
            <input
              style={{ display: "none" }}
              type="file"
              id={fileName}
              name={fileName}
              ref={fileInput}
              accept="application/pdf"
              onChange={({ target }) => {
                onChange(target?.files);
                onFileSelected(target.files[0]?.name);
                // setDocName(target.files[0]?.name);
              }}
            />
          );
        }}
        control={control}
        name={fileName}
        defaultValue=""
      />
      <div className="file-tit">{fileTitle}</div>

      <div className="file-btn-wrap">
        <Button
          icon="upload"
          hint="파일등록"
          onClick={onClickUpload}
          disabled={disabled}
        />
        <Button
          disabled={fileUrl ? false : true}
          onClick={fileDownload}
          icon="download"
          hint="파일다운"
        />
        <Button
          icon="remove"
          hint="파일삭제"
          onClick={onClickRemove}
          disabled={disabled}
        />
      </div>
    </InputLayout>
  );
};

export default InputFile;
