import axios from "axios";

const apiUrl = `${process.env.REACT_APP_API_URL}`;
const apiUrlManage = `${process.env.REACT_APP_API_URL}/manage-all-finish`;

export function getLookup(param) {
  return axios.get(apiUrl + `/${param}`);
}

export function getIssuedData(finishNo, params) {
  return axios.get(apiUrlManage + `/is-issue/${finishNo}`, { params });
}

export function getNotIssuedData(completeNo, params) {
  return axios.get(apiUrlManage + `/is-not-issue/${completeNo}`, { params });
}

export function createTaxIssue(data) {
  return axios.post(apiUrlManage, data);
}

export function updateDeliverDate(data) {
  return axios.put(apiUrlManage + `/deliver-date`, data);
}

export function cancelTax(data) {
  return axios.put(apiUrlManage + `/cancel`, data);
}

export function test(data) {
  return axios.post(apiUrlManage + "/test", data);
}

export function minusTaxIssue(data) {
  return axios.post(apiUrlManage + `/minus-issue`, data);
}

export function editingTaxIssue(data) {
  return axios.post(apiUrlManage + `/edit`, data);
}
