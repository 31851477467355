import axios from "axios";

const apiUrl = `${process.env.REACT_APP_API_URL}`;
const apiUrlFinish = `${process.env.REACT_APP_API_URL}/finish-sell-manage`;
const apiUrlTrade = `${process.env.REACT_APP_API_URL}/trade-sheet`;
const apiUrlMail = `${process.env.REACT_APP_API_URL}/mail`;
const apiUrlExcel = `${process.env.REACT_APP_API_URL}/excel-export`;
const apiUrlFax = `${process.env.REACT_APP_API_URL}/fax-export`;

export function getLookup(param) {
  return axios.get(apiUrl + `/${param}`);
}

export function finishConfirm(date, selected) {
  return axios.put(apiUrlFinish, { date, selected });
}

export function finishCancel(data) {
  return axios.put(apiUrlFinish + `/cancel`, data);
}

export function getOrderDetailData(id, division, extraDivision) {
  return axios.get(apiUrlFinish + `/billing-modal`, {
    params: {
      id,
      division,
      extraDivision,
    },
  });
}

export function nextMonthUpdate(data) {
  return axios.put(apiUrlFinish + "/deliver-date", data);
}

export function cancelNextMonthUpdate(data) {
  return axios.put(apiUrlFinish + "/cancel-deliver-date", data);
}

export function getTempleteSubByOid(Oid) {
  return axios.get(apiUrlTrade + `/${Oid}`);
}

export function createTemplete(data) {
  return axios.post(apiUrlTrade, data);
}

export function updateTemplete(data) {
  return axios.put(apiUrlTrade, data);
}
export function updatePrtState(data) {
  return axios.put(apiUrlTrade + "/prt-state", data);
}

export function deleteTemplete(Oid) {
  return axios.delete(apiUrlTrade + `/${Oid}`);
}

export function getCompanyData(data) {
  return axios.post(apiUrlFinish + `/company-info`, data);
}

export function getBankAccountAndTel(data) {
  return axios.get(apiUrlFinish + `/trade-modal`, { params: data });
}

export function updateClosingDetailData(data) {
  return axios.put(apiUrlFinish + `/billing-modal`, data);
}

export function sendMail(data) {
  const formData = new FormData();
  formData.append("from", data.from);
  formData.append("to", data.to);
  formData.append("subject", data.subject);
  formData.append("html", data.html);
  formData.append("pdf", data.pdf);
  formData.append("filename", data.filename);

  return axios.post(apiUrlMail + `/closing/send`, formData, {
    headers: { "Content-Type": "multipart/form-data" },
  });
}

export function updateAllTaxSellManage(data) {
  return axios.put(apiUrlFinish + `/tax`, data);
}

export function updateZeroTaxSellManage(data) {
  return axios.put(apiUrlFinish + `/zero-tax`, data);
}
export function excelExport(date, cominfor, column, rows, data, etc) {
  return axios.put(apiUrlExcel, { date, cominfor, column, rows, data, etc });
}
export function faxExport(data, image) {
  return axios.put(apiUrlFax, { data, image });
}
