import { CheckBox } from "devextreme-react";
import React from "react";
import { Controller } from "react-hook-form";
import InputLayout from "./InputLayout";

const InputCheckBox = ({
  control,
  errors,
  name,
  label,
  labelWidth,
  mask,
  flex,
  text,
  required,
  disabled = false,
  onValueChanged = () => {},
}) => {
  return (
    <InputLayout
      label={label}
      required={required}
      errors={errors}
      name={name}
      labelWidth={labelWidth}
      checkbox
    >
      <Controller
        render={({ field: { onChange, value } }) => {
          if (value === 1) value = true;
          if (value === 0) value = false;
          return (
            <CheckBox
              mask={mask}
              onValueChange={(value) => {
                onChange(value);
              }}
              value={value}
              text={text}
              disabled={disabled}
              onValueChanged={onValueChanged}
            />
            // <InputMask mask={mask} value={value} onChange={onChange}>
            //   {(inputProps) => <input type="text" {...inputProps} value={value} />}
            //   {/* <input type="text" {...field} /> */}
            // </InputMask>
          );
        }}
        control={control}
        name={name}
        defaultValue={false}
      />
    </InputLayout>
  );
};

export default InputCheckBox;
