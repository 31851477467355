import React, { useEffect, useMemo, useRef, useState } from "react";
import { Modal } from "react-bootstrap";

import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import SVG from "react-inlinesvg";
import * as yup from "yup";
import * as requestServer from "./DispatchCrud";

import { alert } from "devextreme/ui/dialog";

import InputDate from "../../../components/InputForm/InputDate";

import InputDateTime from "../../../components/InputForm/InputDateTime";
import InputLookup from "../../../components/InputForm/InputLookup";
import InputRadioGroup from "../../../components/InputForm/InputRadioGroup";
import InputText from "../../../components/InputForm/InputText";

import {
  Column,
  DataGrid,
  Editing,
  Format,
  HeaderFilter,
  Lookup,
  RequiredRule,
} from "devextreme-react/data-grid";
import CustomStore from "devextreme/data/custom_store";
import moment from "moment";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { sendRequest } from "../../../../_metronic/_helpers/DataSoureHelpers";
import InputNumber from "../../../components/InputForm/InputNumber";

import { SelectModal } from "../../../../_metronic/_partials/modals/SelectModal";
import InputTextArea from "../../../components/InputForm/InputTextArea";

export function DepartureModal({
  onClose,
  maskClosable,
  closable,
  visible,
  rowData,
}) {
  // const [position, setPosition] = useState({ x: 0, y: 0 });

  const schema = yup
    .object({
      ex: yup.string(),
    })
    .required();

  const {
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onMaskClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  // const trackPos = (data) => {
  //   setPosition({ x: data.x, y: data.y });
  // };

  return (
    <>
      <div className={"modal-overlay " + (visible ? "active" : "")} />
      <div
        className={"modal-wrap user-list-modal " + (visible ? "active" : "")}
        onClick={maskClosable ? onMaskClick : null}
        tabIndex="-1"
        visible={`${visible}`}
      >
        <Modal.Dialog centered tabIndex="0" size="sm" animation="true">
          <Modal.Body>
            <form className="modal-form">
              <div className="modal-form-header">
                <div className="modal-form-tit">
                  <span className="modal-tit">운송일자 지정</span>
                </div>
                <button
                  type="button"
                  className="btn-modal btn-modal-close"
                  onClick={onClose}
                >
                  <SVG
                    src={toAbsoluteUrl(
                      "/media/svg/icons/Custom/close_black_24dp.svg"
                    )}
                    className="close-icon"
                  />
                </button>
              </div>
              <div className="modal-form-body">
                <div className="d-flex flex-row mb-1">
                  <div className="dx-field">
                    <InputDate
                      control={control}
                      errors={errors}
                      name="departure"
                      label="운송일자"
                      flex={true}
                      onInitialized={(e) => {
                        setTimeout(() => {
                          e.component.focus();
                        }, 0);
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="modal-form-footer"></div>
            </form>
          </Modal.Body>
        </Modal.Dialog>
      </div>
    </>
  );
}

// 배차등록
export function DispatchModal({
  onClose,
  maskClosable,
  closable,
  visible,
  detailRowData,
  dataGridRef,
  fileVisible,
  focusedComponent,
}) {
  // const [position, setPosition] = useState({ x: 0, y: 0 });
  const [car, setCar] = useState();
  const [yongchasa, setYongchasa] = useState();
  const [fileModalVisible, setFileModalVisible] = useState(false);
  const [appFile, setAppFile] = useState([]);

  const fileGridRef = useRef();

  const schema = yup
    .object({
      deliverDate: yup.string().nullable(),
      carNo: yup.string(),
      yongchasa: yup.string(),
      yongchaCarNo: yup.string(),
      dispatchComment: yup
        .string()
        .nullable()
        .required("배차관련메세지를 입력해주세요"),
    })
    .required();

  const {
    control,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    resetInput(detailRowData);
    getLookup();
    // eslint-disable-next-line
  }, []);

  const onSubmit = async (data) => {
    if (!data.deliverDate) {
      alert("운송일자를 입력해주세요", "오류");
      return;
    }

    if (data.toggle === "1") {
      if (!data.carNo) {
        alert("차량번호를 입력해주세요", "오류");
        return;
      }
      data.yongchasa = null;
      data.yongchaCarNo = null;
    } else {
      if (!data.yongchasa) {
        alert("용차사를 선택해주세요", "오류");
        return;
      }
      data.carNo = null;
    }
    delete data.toggle;

    try {
      await requestServer.changeCarNo(
        detailRowData.id,
        data,
        appFile.map((cur) => cur.Oid)
      );

      dataGridRef.current.instance.refresh();
      onClose();
    } catch (err) {
      console.log(err);
      alert(err?.response?.data?.message, "오류");
    }
  };

  const onMaskClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  // const trackPos = (data) => {
  //   setPosition({ x: data.x, y: data.y });
  // };

  const onSelect = (data) => {
    const duple = appFile.filter((cur) => cur.Oid === data.Oid);
    if (duple.length === 0) setAppFile([...appFile, data]);

    setFileModalVisible(false);
  };

  async function getLookup() {
    try {
      const carData = await requestServer.getLookupDatas("carManage/lookup");
      const yongchasaData = await requestServer.getLookupDatas(
        "companyManage/lookup/isYongchasa"
      );

      setCar(carData.data);
      setYongchasa(yongchasaData.data);
    } catch (err) {
      console.log(err);
    }
  }

  async function resetInput(detailRowData) {
    if (!detailRowData) return;

    const result = await requestServer.getDispatchData(detailRowData.id);

    setAppFile(result.data);

    const toggle = detailRowData.yongchasa ? "2" : "1";

    reset({
      carNo: detailRowData.carCode || undefined,
      yongchasa: detailRowData.yongchasaCode || undefined,
      yongchaCarNo: detailRowData.yongchaCarNo || undefined,
      dispatchComment: detailRowData.dispatchComment || undefined,
      toggle,
      deliverDate: moment().format("YYYY-MM-DD") || undefined,
    });
  }

  // const cellRenderDeleteBtn = useCallback((row) => {
  //   return (
  //     <button
  //       type="button"
  //       className="btn btn-grid btn-grid-change pw-change"
  //       onClick={() => {
  //         const filteredAppFile = appFile.filter((cur) => {
  //           console.log(cur);
  //           console.log(row.data);
  //           return cur.Oid !== row?.data?.Oid;
  //         });
  //         setAppFile(filteredAppFile);
  //       }}
  //     >
  //       삭제
  //     </button>
  //   );
  // }, []);

  return (
    <>
      <div className={"modal-overlay " + (visible ? "active" : "")} />
      <div
        className={"modal-wrap user-list-modal " + (visible ? "active" : "")}
        onClick={maskClosable ? onMaskClick : null}
        tabIndex="-1"
        visible={`${visible}`}
      >
        <Modal.Dialog centered tabIndex="0" size="xl" animation="true">
          <Modal.Body>
            <form onSubmit={handleSubmit(onSubmit)} className="modal-form">
              <div className="modal-form-header">
                <div className="modal-form-tit">
                  <span className="modal-tit">배차등록</span>
                </div>
                <button
                  type="button"
                  className="btn-modal btn-modal-close"
                  onClick={onClose}
                >
                  <SVG
                    src={toAbsoluteUrl(
                      "/media/svg/icons/Custom/close_black_24dp.svg"
                    )}
                    className="close-icon"
                  />
                </button>
              </div>
              <div className="modal-form-body">
                {/* <div className="d-flex flex-row mb-1">
                  <div className="dx-field">
                    <InputDate control={control} errors={errors} name="original" label="기존일자" flex={true} disable={true} />
                  </div>
                </div> */}

                <div className="d-flex flex-row mb-1">
                  <div className="dx-field">
                    <InputDate
                      control={control}
                      errors={errors}
                      name="deliverDate"
                      label="운송일자"
                      flex={true}
                    />
                  </div>
                </div>

                <div className="d-flex flex-row mb-1">
                  <div className="dx-field">
                    <InputRadioGroup
                      control={control}
                      errors={errors}
                      flex={true}
                      name="toggle"
                      dataSource={[
                        { id: "1", name: "차량번호" },
                        { id: "2", name: "용차사" },
                      ]}
                    />
                  </div>
                </div>

                {watch("toggle") === "1" && (
                  <>
                    <div className="d-flex flex-row mb-1">
                      <div className="dx-field">
                        <InputLookup
                          control={control}
                          errors={errors}
                          name="carNo"
                          label="차량번호"
                          flex={true}
                          dataSource={car}
                          valueExpr="oid"
                          displayExpr="carNo_Name"
                          disable={watch("toggle") === "2"}
                          onInitialized={(e) => {
                            e.component.open();
                            setTimeout(() => {
                              e.component.focus();
                            }, 0);
                          }}
                        />
                      </div>
                    </div>
                  </>
                )}

                {watch("toggle") === "2" && (
                  <>
                    <div className="d-flex flex-row mb-1">
                      <div className="dx-field">
                        <InputLookup
                          control={control}
                          errors={errors}
                          name="yongchasa"
                          label="용차사"
                          flex={true}
                          dataSource={yongchasa}
                          valueExpr="oid"
                          displayExpr="company"
                          disable={watch("toggle") === "1"}
                          onInitialized={(e) => {
                            e.component.open();
                            setTimeout(() => {
                              e.component.focus();
                            }, 0);
                          }}
                        />
                      </div>
                    </div>
                    <div className="d-flex flex-row mb-1">
                      <div className="dx-field">
                        <InputText
                          control={control}
                          errors={errors}
                          name="yongchaCarNo"
                          label="차량번호"
                          flex={true}
                          disable={watch("toggle") === "1"}
                        />
                      </div>
                    </div>
                  </>
                )}
                <div className="d-flex flex-row mb-1">
                  <div className="dx-field">
                    <div>관련파일 첨부</div>
                    <button
                      type="button"
                      onClick={() => {
                        setFileModalVisible(true);
                      }}
                    >
                      파일관리
                    </button>
                  </div>
                </div>

                <DataGrid
                  className="datagrid-wrap single-line has-toolbar"
                  id="AppFileManageModal"
                  ref={fileGridRef}
                  dataSource={appFile}
                  showRowLines={true}
                  showBorders={true}
                  hoverStateEnabled={true}
                  allowColumnResizing={true}
                  allowColumnReordering={true}
                  columnResizingMode="widget"
                  keyExpr="Oid"
                  focusedRowEnabled={true}
                >
                  <HeaderFilter visible={true} />
                  <Column
                    caption="No"
                    cellRender={(e) => e.row.loadIndex + 1}
                  />
                  <Column dataField="fileListCodeName" caption="구분" />
                  <Column dataField="File_Name" caption="관리명" />
                  <Column dataField="File_Info" caption="파일" />
                  <Column dataField="Reg_Date" caption="등록일" />
                  <Column dataField="useYnName" caption="사용여부" />
                  <Column dataField="Remark" caption="비고" />
                  {/* <Column
                    dataField=""
                    caption="삭제"
                    cellRender={cellRenderDeleteBtn}
                  /> */}
                </DataGrid>

                <div className="d-flex flex-row mb-1">
                  <div className="dx-field">
                    <InputTextArea
                      control={control}
                      errors={errors}
                      required={true}
                      name="dispatchComment"
                      label="배차 관련 메세지"
                      flex={true}
                    />
                  </div>
                </div>
              </div>

              <div className="modal-form-footer">
                <button type="submit" className="btn btn-modal btn-save">
                  저장
                </button>
              </div>
            </form>
            {fileModalVisible && (
              <>
                <SelectModal
                  onClose={() => setFileModalVisible(false)}
                  closable={true}
                  visible={true}
                  maskClosable={false}
                  dataGrid={dataGridRef}
                  onSelect={onSelect}
                  param="/app-file-manage"
                  keyExpr="Oid"
                />
              </>
            )}
          </Modal.Body>
        </Modal.Dialog>
      </div>
    </>
  );
}

// 작업경유지등록
export function WorkWaypointModal({
  onClose,
  maskClosable,
  closable,
  visible,
  rowData,
  dataGridRef,
}) {
  const [wayPoint, setWayPoint] = useState();
  const schema = yup
    .object({
      workWaypoint: yup.string().nullable(),
      workWaypoint2: yup.string().nullable(),
      workWaypoint3: yup.string().nullable(),
    })
    .required();

  const {
    watch,
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    getLookup();
    resetInput(rowData);
    // eslint-disable-next-line
  }, []);

  const onMaskClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  const onSubmit = async (data) => {
    data.workWaypoint = data.workWaypoint || null;
    data.workWaypoint2 = data.workWaypoint2 || null;
    data.workWaypoint3 = data.workWaypoint3 || null;

    try {
      await requestServer.changeWorkWaypoint(
        { id: rowData.id, orderCode: rowData.orderCode },
        data
      );

      dataGridRef.current.instance.refresh();
      onClose();
    } catch (err) {
      alert(err.response.data.message, "오류");
    }
  };

  function resetInput(rowData) {
    reset({
      toggle: "1",
      workWaypoint: rowData.workWaypointCode || undefined,
      workWaypoint2: rowData.workWaypointCode2 || undefined,
      workWaypoint3: rowData.workWaypointCode3 || undefined,
    });
  }

  async function getLookup() {
    try {
      const waypointData = await requestServer.getLookupDatas(
        "terminal/waypoint"
      );

      setWayPoint([
        { code: null, twName: "경유지 없음" },
        ...waypointData.data,
      ]);
    } catch (err) {
      console.log(err);
    }
  }

  return (
    <>
      <div className={"modal-overlay " + (visible ? "active" : "")} />
      <div
        className={"modal-wrap user-list-modal " + (visible ? "active" : "")}
        onClick={maskClosable ? onMaskClick : null}
        tabIndex="-1"
        visible={`${visible}`}
      >
        <Modal.Dialog centered tabIndex="0" size="sm" animation="true">
          <Modal.Body>
            <form onSubmit={handleSubmit(onSubmit)} className="modal-form">
              <div className="modal-form-header">
                <div className="modal-form-tit">
                  <span className="modal-tit">작업경유지등록</span>
                </div>
                <button
                  type="button"
                  className="btn-modal btn-modal-close"
                  onClick={onClose}
                >
                  <SVG
                    src={toAbsoluteUrl(
                      "/media/svg/icons/Custom/close_black_24dp.svg"
                    )}
                    className="close-icon"
                  />
                </button>
              </div>
              <div className="modal-form-body">
                <div className="d-flex flex-row mb-1">
                  <div className="dx-field">
                    <InputRadioGroup
                      control={control}
                      errors={errors}
                      flex={true}
                      name="toggle"
                      dataSource={[
                        { id: "1", name: "1" },
                        { id: "2", name: "2" },
                        { id: "3", name: "3" },
                      ]}
                    />
                  </div>
                </div>
                {watch("toggle") === "1" && (
                  <div className="d-flex flex-row mb-1">
                    <div className="dx-field">
                      <InputLookup
                        control={control}
                        errors={errors}
                        name="workWaypoint"
                        label="작업경유지1"
                        flex={true}
                        dataSource={wayPoint}
                        valueExpr="code"
                        displayExpr="twName"
                        onInitialized={(e) => {
                          e.component.open();
                          setTimeout(() => {
                            e.component.focus();
                          }, 0);
                        }}
                      />
                    </div>
                  </div>
                )}
                {watch("toggle") === "2" && (
                  <div className="d-flex flex-row mb-1">
                    <div className="dx-field">
                      <InputLookup
                        control={control}
                        errors={errors}
                        name="workWaypoint2"
                        label="작업경유지2"
                        flex={true}
                        dataSource={wayPoint}
                        valueExpr="code"
                        displayExpr="twName"
                        onInitialized={(e) => {
                          e.component.open();
                          setTimeout(() => {
                            e.component.focus();
                          }, 0);
                        }}
                      />
                    </div>
                  </div>
                )}
                {watch("toggle") === "3" && (
                  <div className="d-flex flex-row mb-1">
                    <div className="dx-field">
                      <InputLookup
                        control={control}
                        errors={errors}
                        name="workWaypoint3"
                        label="작업경유지3"
                        flex={true}
                        dataSource={wayPoint}
                        valueExpr="code"
                        displayExpr="twName"
                        onInitialized={(e) => {
                          e.component.open();
                          setTimeout(() => {
                            e.component.focus();
                          }, 0);
                        }}
                      />
                    </div>
                  </div>
                )}
              </div>
              <div className="modal-form-footer">
                <button type="submit" className="btn btn-modal btn-save">
                  등록
                </button>
              </div>
            </form>
          </Modal.Body>
        </Modal.Dialog>
      </div>
    </>
  );
}

// 작업지등록
export function WorkPlaceModal({
  onClose,
  maskClosable,
  closable,
  visible,
  rowData,
  dataGridRef,
}) {
  const [workPlace, setWorkPlace] = useState();
  const schema = yup
    .object({
      workPlace: yup.string().nullable(),
    })
    .required();

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    getLookup();
    resetInput(rowData);
    // eslint-disable-next-line
  }, []);

  const onMaskClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  const onSubmit = async (data) => {
    data.workPlace = data.workPlace || null;

    try {
      await requestServer.changeWorkPlace(
        { id: rowData.id, orderCode: rowData.orderCode },
        data
      );

      dataGridRef.current.instance.refresh();
      onClose();
    } catch (err) {
      alert(err.response.data.message, "오류");
    }
  };

  function resetInput(rowData) {
    reset({
      workPlace: rowData.workPlaceCode || undefined,
    });
  }

  async function getLookup() {
    try {
      const workPlaceData = await requestServer.getLookupDatas(
        "terminal/workplace"
      );

      setWorkPlace(workPlaceData.data);
    } catch (err) {
      console.log(err);
    }
  }

  return (
    <>
      <div className={"modal-overlay " + (visible ? "active" : "")} />
      <div
        className={"modal-wrap user-list-modal " + (visible ? "active" : "")}
        onClick={maskClosable ? onMaskClick : null}
        tabIndex="-1"
        visible={`${visible}`}
      >
        <Modal.Dialog centered tabIndex="0" size="sm" animation="true">
          <Modal.Body>
            <form onSubmit={handleSubmit(onSubmit)} className="modal-form">
              <div className="modal-form-header">
                <div className="modal-form-tit">
                  <span className="modal-tit">작업지 등록</span>
                </div>
                <button
                  type="button"
                  className="btn-modal btn-modal-close"
                  onClick={onClose}
                >
                  <SVG
                    src={toAbsoluteUrl(
                      "/media/svg/icons/Custom/close_black_24dp.svg"
                    )}
                    className="close-icon"
                  />
                </button>
              </div>
              <div className="modal-form-body">
                <div className="d-flex flex-row mb-1">
                  <div className="dx-field">
                    <InputLookup
                      control={control}
                      errors={errors}
                      name="workPlace"
                      label="작업지"
                      flex={true}
                      dataSource={workPlace}
                      valueExpr="code"
                      displayExpr="twName"
                      onInitialized={(e) => {
                        e.component.open();
                        setTimeout(() => {
                          e.component.focus();
                        }, 0);
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="modal-form-footer">
                <button type="submit" className="btn btn-modal btn-save">
                  등록
                </button>
              </div>
            </form>
          </Modal.Body>
        </Modal.Dialog>
      </div>
    </>
  );
}

// 회수경유지등록
export function CollectWaypointModal({
  onClose,
  maskClosable,
  closable,
  visible,
  rowData,
  dataGridRef,
}) {
  const [wayPoint, setWayPoint] = useState();
  const schema = yup
    .object({
      collectWaypoint: yup.string().nullable(),
      collectWaypoint2: yup.string().nullable(),
      collectWaypoint3: yup.string().nullable(),
    })
    .required();

  const {
    watch,
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    getLookup();
    resetInput(rowData);
    // eslint-disable-next-line
  }, []);

  const onMaskClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  const onSubmit = async (data) => {
    data.collectWaypoint = data.collectWaypoint || null;
    data.collectWaypoint2 = data.collectWaypoint2 || null;
    data.collectWaypoint3 = data.collectWaypoint3 || null;

    try {
      await requestServer.changeCollectWaypoint(
        { id: rowData.id, orderCode: rowData.orderCode },
        data
      );

      dataGridRef.current.instance.refresh();
      onClose();
    } catch (err) {
      alert(err.response.data.message, "오류");
    }
  };

  function resetInput(rowData) {
    reset({
      toggle: "1",
      collectWaypoint: rowData.collectWaypointCode || undefined,
      collectWaypoint2: rowData.collectWaypointCode2 || undefined,
      collectWaypoint3: rowData.collectWaypointCode3 || undefined,
    });
  }
  async function getLookup() {
    try {
      const waypointData = await requestServer.getLookupDatas(
        "terminal/waypoint"
      );

      setWayPoint([
        { code: null, twName: "경유지 없음" },
        ...waypointData.data,
      ]);
    } catch (err) {
      console.log(err);
    }
  }

  return (
    <>
      <div className={"modal-overlay " + (visible ? "active" : "")} />
      <div
        className={"modal-wrap user-list-modal " + (visible ? "active" : "")}
        onClick={maskClosable ? onMaskClick : null}
        tabIndex="-1"
        visible={`${visible}`}
      >
        <Modal.Dialog centered tabIndex="0" size="sm" animation="true">
          <Modal.Body>
            <form onSubmit={handleSubmit(onSubmit)} className="modal-form">
              <div className="modal-form-header">
                <div className="modal-form-tit">
                  <span className="modal-tit">회수경유지등록</span>
                </div>
                <button
                  type="button"
                  className="btn-modal btn-modal-close"
                  onClick={onClose}
                >
                  <SVG
                    src={toAbsoluteUrl(
                      "/media/svg/icons/Custom/close_black_24dp.svg"
                    )}
                    className="close-icon"
                  />
                </button>
              </div>
              <div className="modal-form-body">
                <div className="d-flex flex-row mb-1">
                  <div className="dx-field">
                    <InputRadioGroup
                      control={control}
                      errors={errors}
                      flex={true}
                      name="toggle"
                      dataSource={[
                        { id: "1", name: "1" },
                        { id: "2", name: "2" },
                        { id: "3", name: "3" },
                      ]}
                    />
                  </div>
                </div>

                {watch("toggle") === "1" && (
                  <div className="d-flex flex-row mb-1">
                    <div className="dx-field">
                      <InputLookup
                        control={control}
                        errors={errors}
                        name="collectWaypoint"
                        label="회수경유지1"
                        flex={true}
                        dataSource={wayPoint}
                        valueExpr="code"
                        displayExpr="twName"
                        onInitialized={(e) => {
                          e.component.open();
                          setTimeout(() => {
                            e.component.focus();
                          }, 0);
                        }}
                      />
                    </div>
                  </div>
                )}

                {watch("toggle") === "2" && (
                  <div className="d-flex flex-row mb-1">
                    <div className="dx-field">
                      <InputLookup
                        control={control}
                        errors={errors}
                        name="collectWaypoint2"
                        label="회수경유지2"
                        flex={true}
                        dataSource={wayPoint}
                        valueExpr="code"
                        displayExpr="twName"
                        onInitialized={(e) => {
                          e.component.open();
                          setTimeout(() => {
                            e.component.focus();
                          }, 0);
                        }}
                      />
                    </div>
                  </div>
                )}

                {watch("toggle") === "3" && (
                  <div className="d-flex flex-row mb-1">
                    <div className="dx-field">
                      <InputLookup
                        control={control}
                        errors={errors}
                        name="collectWaypoint3"
                        label="회수경유지3"
                        flex={true}
                        dataSource={wayPoint}
                        valueExpr="code"
                        displayExpr="twName"
                        onInitialized={(e) => {
                          e.component.open();
                          setTimeout(() => {
                            e.component.focus();
                          }, 0);
                        }}
                      />
                    </div>
                  </div>
                )}
              </div>
              <div className="modal-form-footer">
                <button type="submit" className="btn btn-modal btn-save">
                  등록
                </button>
              </div>
            </form>
          </Modal.Body>
        </Modal.Dialog>
      </div>
    </>
  );
}

// 하차지등록
export function UnloadPlaceModal({
  onClose,
  maskClosable,
  closable,
  visible,
  rowData,
  dataGridRef,
}) {
  const [terminal, setTerminal] = useState();
  const schema = yup
    .object({
      unloadPlace: yup.string().nullable(),
    })
    .required();

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    getLookup();
    resetInput(rowData);
    // eslint-disable-next-line
  }, []);

  const onMaskClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  const onSubmit = async (data) => {
    data.unloadPlace = data.unloadPlace || null;

    try {
      await requestServer.changeUnloadPlace(
        { id: rowData.id, orderCode: rowData.orderCode },
        data
      );

      dataGridRef.current.instance.refresh();
      onClose();
    } catch (err) {
      alert(err.response.data.message, "오류");
    }
  };

  function resetInput(rowData) {
    reset({
      unloadPlace: rowData.unloadPlaceCode || undefined,
    });
  }

  async function getLookup() {
    try {
      const terminalData = await requestServer.getLookupDatas(
        "terminal/terminal"
      );

      setTerminal(terminalData.data);
    } catch (err) {
      console.log(err);
    }
  }

  return (
    <>
      <div className={"modal-overlay " + (visible ? "active" : "")} />
      <div
        className={"modal-wrap user-list-modal " + (visible ? "active" : "")}
        onClick={maskClosable ? onMaskClick : null}
        tabIndex="-1"
        visible={`${visible}`}
      >
        <Modal.Dialog centered tabIndex="0" size="sm" animation="true">
          <Modal.Body>
            <form onSubmit={handleSubmit(onSubmit)} className="modal-form">
              <div className="modal-form-header">
                <div className="modal-form-tit">
                  <span className="modal-tit">하차지 등록</span>
                </div>
                <button
                  type="button"
                  className="btn-modal btn-modal-close"
                  onClick={onClose}
                >
                  <SVG
                    src={toAbsoluteUrl(
                      "/media/svg/icons/Custom/close_black_24dp.svg"
                    )}
                    className="close-icon"
                  />
                </button>
              </div>
              <div className="modal-form-body">
                <div className="d-flex flex-row mb-1">
                  <div className="dx-field">
                    <InputLookup
                      control={control}
                      errors={errors}
                      name="unloadPlace"
                      label="하차지"
                      flex={true}
                      dataSource={terminal}
                      valueExpr="code"
                      displayExpr="twName"
                      onInitialized={(e) => {
                        e.component.open();
                        setTimeout(() => {
                          e.component.focus();
                        }, 0);
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="modal-form-footer">
                <button type="submit" className="btn btn-modal btn-save">
                  등록
                </button>
              </div>
            </form>
          </Modal.Body>
        </Modal.Dialog>
      </div>
    </>
  );
}

export function FreightModal({
  onClose,
  maskClosable,
  closable,
  visible,
  rowData,
  dataGridRef,
}) {
  const schema = yup
    .object({
      freight: yup.string().nullable(),
    })
    .required();

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    resetInput(rowData);
    // eslint-disable-next-line
  }, []);

  const onMaskClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  const onSubmit = async (data) => {
    try {
      await requestServer.changeFreight(
        { id: rowData.id, orderCode: rowData.orderCode },
        data
      );

      dataGridRef.current.instance.refresh();
      onClose();
    } catch (err) {
      alert(err.response.data.message, "오류");
    }
  };

  function resetInput(rowData) {
    reset({
      freight: rowData.freight || undefined,
    });
  }

  return (
    <>
      <div className={"modal-overlay " + (visible ? "active" : "")} />
      <div
        className={"modal-wrap user-list-modal " + (visible ? "active" : "")}
        onClick={maskClosable ? onMaskClick : null}
        tabIndex="-1"
        visible={`${visible}`}
      >
        <Modal.Dialog centered tabIndex="0" size="sm" animation="true">
          <Modal.Body>
            <form onSubmit={handleSubmit(onSubmit)} className="modal-form">
              <div className="modal-form-header">
                <div className="modal-form-tit">
                  <span className="modal-tit">청구금액 등록</span>
                </div>
                <button
                  type="button"
                  className="btn-modal btn-modal-close"
                  onClick={onClose}
                >
                  <SVG
                    src={toAbsoluteUrl(
                      "/media/svg/icons/Custom/close_black_24dp.svg"
                    )}
                    className="close-icon"
                  />
                </button>
              </div>
              <div className="modal-form-body">
                <div className="d-flex flex-row mb-1">
                  <div className="dx-field">
                    <InputNumber
                      control={control}
                      errors={errors}
                      name="freight"
                      label="청구금액"
                      flex={true}
                      onInitialized={(e) => {
                        setTimeout(() => {
                          e.component.focus();
                        }, 0);
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="modal-form-footer">
                <button type="submit" className="btn btn-modal btn-save">
                  등록
                </button>
              </div>
            </form>
          </Modal.Body>
        </Modal.Dialog>
      </div>
    </>
  );
}

// 샤시등록
export function ChassisModal({
  onClose,
  maskClosable,
  closable,
  visible,
  rowData,
  dataGridRef,
}) {
  const NO_CHASSIS = "샤시 없음";
  const [chassis, setChassis] = useState();
  const schema = yup
    .object({
      chassis: yup.string().nullable(),
    })
    .required();

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    getLookup();
    resetInput(rowData);
    // eslint-disable-next-line
  }, []);

  const onMaskClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  const onSubmit = async (data) => {
    data.chassis = data.chassis === NO_CHASSIS ? null : data.chassis;

    try {
      await requestServer.changeChassis(
        { id: rowData.id, orderCode: rowData.orderCode },
        data
      );

      dataGridRef.current.instance.refresh();
      onClose();
    } catch (err) {
      alert(err.response.data.message, "오류");
    }
  };

  function resetInput(rowData) {
    reset({
      chassis: rowData.chassis || undefined,
    });
  }

  async function getLookup() {
    try {
      const terminalData = await requestServer.getLookupDatas("chassis");

      setChassis([{ chassisNo: NO_CHASSIS }, ...terminalData.data]);
    } catch (err) {
      console.log(err);
    }
  }

  return (
    <>
      <div className={"modal-overlay " + (visible ? "active" : "")} />
      <div
        className={"modal-wrap user-list-modal " + (visible ? "active" : "")}
        onClick={maskClosable ? onMaskClick : null}
        tabIndex="-1"
        visible={`${visible}`}
      >
        <Modal.Dialog centered tabIndex="0" size="sm" animation="true">
          <Modal.Body>
            <form onSubmit={handleSubmit(onSubmit)} className="modal-form">
              <div className="modal-form-header">
                <div className="modal-form-tit">
                  <span className="modal-tit">샤시 등록</span>
                </div>
                <button
                  type="button"
                  className="btn-modal btn-modal-close"
                  onClick={onClose}
                >
                  <SVG
                    src={toAbsoluteUrl(
                      "/media/svg/icons/Custom/close_black_24dp.svg"
                    )}
                    className="close-icon"
                  />
                </button>
              </div>
              <div className="modal-form-body">
                <div className="d-flex flex-row mb-1">
                  <div className="dx-field">
                    <InputLookup
                      control={control}
                      errors={errors}
                      name="chassis"
                      label="샤시"
                      flex={true}
                      dataSource={chassis}
                      valueExpr="chassisNo"
                      displayExpr="chassisNo"
                      onInitialized={(e) => {
                        e.component.open();
                        setTimeout(() => {
                          e.component.focus();
                        }, 0);
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="modal-form-footer">
                <button type="submit" className="btn btn-modal btn-save">
                  등록
                </button>
              </div>
            </form>
          </Modal.Body>
        </Modal.Dialog>
      </div>
    </>
  );
}

// 위험물/냉동정보
export function FridgeDangerModal({
  onClose,
  maskClosable,
  closable,
  visible,
  rowData,
  dataGridRef,
}) {
  const schema = yup
    .object({
      temperature: yup.string(),
      vent: yup.string(),
      unNo: yup.string(),
      class: yup.string(),
      extraInfo: yup.string(),
    })
    .required();

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    function resetInput(rowData) {
      reset({
        temperature: rowData.temperature || undefined,
        vent: rowData.vent || undefined,
        unNo: rowData.unNo || undefined,
        class: rowData.class || undefined,
        extraInfo: rowData.extraInfo || undefined,
      });
    }

    resetInput(rowData);
    // eslint-disable-next-line
  }, []);

  const onMaskClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  const onSubmit = async (data) => {
    if (!rowData) return;
    const key = rowData.id;
    try {
      await requestServer.changeFridgeDanger(key, data);

      dataGridRef.current.instance.refresh();
      onClose();
    } catch (err) {
      alert(err.response.data.message, "오류");
    }
  };

  return (
    <>
      <div className={"modal-overlay " + (visible ? "active" : "")} />
      <div
        className={"modal-wrap user-list-modal " + (visible ? "active" : "")}
        onClick={maskClosable ? onMaskClick : null}
        tabIndex="-1"
        visible={`${visible}`}
      >
        <Modal.Dialog centered tabIndex="0" size="sm" animation="true">
          <Modal.Body>
            <form onSubmit={handleSubmit(onSubmit)} className="modal-form">
              <div className="modal-form-header">
                <div className="modal-form-tit">
                  <span className="modal-tit">위험물/냉동정보</span>
                </div>
                <button
                  type="button"
                  className="btn-modal btn-modal-close"
                  onClick={onClose}
                >
                  <SVG
                    src={toAbsoluteUrl(
                      "/media/svg/icons/Custom/close_black_24dp.svg"
                    )}
                    className="close-icon"
                  />
                </button>
              </div>
              <div className="modal-form-body">
                <div className="d-flex flex-row mb-1">
                  <div className="dx-field">
                    <InputText
                      control={control}
                      errors={errors}
                      name="temperature"
                      label="온도"
                      flex={true}
                      onInitialized={(e) => {
                        setTimeout(() => {
                          e.component.focus();
                        }, 0);
                      }}
                    />
                  </div>
                </div>

                <div className="d-flex flex-row mb-1">
                  <div className="dx-field">
                    <InputText
                      control={control}
                      errors={errors}
                      name="vent"
                      label="환풍구"
                      flex={true}
                    />
                  </div>
                </div>

                <div className="d-flex flex-row mb-1">
                  <div className="dx-field">
                    <InputText
                      control={control}
                      errors={errors}
                      name="unNo"
                      label="UN넘버"
                      flex={true}
                    />
                  </div>
                </div>

                <div className="d-flex flex-row mb-1">
                  <div className="dx-field">
                    <InputText
                      control={control}
                      errors={errors}
                      name="class"
                      label="클래스"
                      flex={true}
                    />
                  </div>
                </div>

                <div className="d-flex flex-row mb-1">
                  <div className="dx-field">
                    <InputText
                      control={control}
                      errors={errors}
                      name="extraInfo"
                      label="기타정보"
                      flex={true}
                    />
                  </div>
                </div>
              </div>
              <div className="modal-form-footer">
                <button type="submit" className="btn btn-modal btn-save">
                  등록
                </button>
              </div>
            </form>
          </Modal.Body>
        </Modal.Dialog>
      </div>
    </>
  );
}

// 밥테일로 변경
export function BobtailModal({
  onClose,
  maskClosable,
  closable,
  visible,
  rowData,
  dataGridRef,
}) {
  const onMaskClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  const bobtailOn = async (rowData) => {
    const key = rowData.id;
    const bobtail = !rowData.bobtail;
    if (rowData.bobtail) {
      alert("밥테일화물입니다", "오류");
      return;
    }
    console.log(rowData);

    try {
      await requestServer.toggleBobtail(key, bobtail, rowData);

      dataGridRef.current.instance.refresh();
      onClose();
    } catch (err) {
      alert(err.response.data.message, "오류");
      onClose();
    }
  };

  const bobtailOff = async (rowData) => {
    const key = rowData.id;
    const bobtail = !rowData.bobtail;
    if (!rowData.bobtail) {
      alert("밥테일화물이 아닙니다", "오류");
      return;
    }

    try {
      await requestServer.toggleBobtail(key, bobtail, rowData);

      dataGridRef.current.instance.refresh();
      onClose();
    } catch (err) {
      alert(err.response.data.message, "오류");
      onClose();
    }
  };

  return (
    <>
      <div className={"modal-overlay " + (visible ? "active" : "")} />
      <div
        className={"modal-wrap user-list-modal " + (visible ? "active" : "")}
        onClick={maskClosable ? onMaskClick : null}
        tabIndex="-1"
        visible={`${visible}`}
      >
        <Modal.Dialog centered tabIndex="0" size="sm" animation="true">
          <Modal.Body>
            <form className="modal-form">
              <div className="modal-form-header">
                <div className="modal-form-tit">
                  <span className="modal-tit">밥테일로 변경</span>
                </div>
                <button
                  type="button"
                  className="btn-modal btn-modal-close"
                  onClick={onClose}
                >
                  <SVG
                    src={toAbsoluteUrl(
                      "/media/svg/icons/Custom/close_black_24dp.svg"
                    )}
                    className="close-icon"
                  />
                </button>
              </div>
              <div className="modal-form-body body-btn-set">
                <button
                  type="button"
                  className="btn btn-modal btn-select-adjust"
                  onClick={() => {
                    bobtailOn(rowData);
                  }}
                >
                  변경 적용
                </button>
                <button
                  type="button"
                  className="btn btn-modal btn-select-clear"
                  onClick={() => {
                    bobtailOff(rowData);
                  }}
                >
                  밥테일 해제
                </button>
              </div>
            </form>
          </Modal.Body>
        </Modal.Dialog>
      </div>
    </>
  );
}

// 하불등록
export function HabulModal({
  onClose,
  maskClosable,
  closable,
  visible,
  detailRowData,
  dataGridRef,
}) {
  const schema = yup
    .object({
      ex: yup.string(),
    })
    .required();

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onMaskClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  useEffect(() => {
    setValue("habul", detailRowData.habul);
    // eslint-disable-next-line
  }, []);

  const onSubmit = async (data) => {
    try {
      data.id = detailRowData.id;

      await requestServer.setHabul(data);

      dataGridRef.current.instance.refresh();
      onClose();
    } catch (err) {
      alert(err.response.data.message, "오류");
      return;
    }
  };

  return (
    <>
      <div className={"modal-overlay " + (visible ? "active" : "")} />
      <div
        className={"modal-wrap user-list-modal " + (visible ? "active" : "")}
        onClick={maskClosable ? onMaskClick : null}
        tabIndex="-1"
        visible={`${visible}`}
      >
        <Modal.Dialog centered tabIndex="0" size="sm" animation="true">
          <Modal.Body>
            <form className="modal-form" onSubmit={handleSubmit(onSubmit)}>
              <div className="modal-form-header">
                <div className="modal-form-tit">
                  <span className="modal-tit">하불등록</span>
                </div>
                <button
                  type="button"
                  className="btn-modal btn-modal-close"
                  onClick={onClose}
                >
                  <SVG
                    src={toAbsoluteUrl(
                      "/media/svg/icons/Custom/close_black_24dp.svg"
                    )}
                    className="close-icon"
                  />
                </button>
              </div>
              <div className="modal-form-body">
                <div className="d-flex flex-row mb-1">
                  <div className="dx-field">
                    <InputNumber
                      control={control}
                      errors={errors}
                      name="habul"
                      label="하불금액"
                      flex={true}
                      onInitialized={(e) => {
                        setTimeout(() => {
                          e.component.focus();
                        }, 0);
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="modal-form-footer">
                <button type="submit" className="btn btn-modal btn-save">
                  저장
                </button>
              </div>
            </form>
          </Modal.Body>
        </Modal.Dialog>
      </div>
    </>
  );
}

// 할증료 등록
export function ExtraChargeModal({
  onClose,
  maskClosable,
  closable,
  visible,
  rowData,
  dataGridRef,
}) {
  const [lookupData, setLookupData] = useState({
    division: [],
    habulDivision: [],
    billingPlace: [],
  });
  const extraChargeRef = useRef();

  const dataSource = useMemo(() => {
    const apiUrl = `${process.env.REACT_APP_API_URL}/extraCharge`;

    return new CustomStore({
      key: "id",
      load: () => sendRequest(apiUrl, "GET", { orderDetailCode: rowData.id }),
    });
    // eslint-disable-next-line
  }, []);

  const { handleSubmit } = useForm();

  useEffect(() => {
    getLookup();
  }, []);

  const onMaskClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  const onToolbarPreparing = (e) => {
    e.toolbarOptions.items.forEach((item) => {
      if (item.name === "saveButton") {
        item.visible = false;
      }
    });
  };

  // const rateCellRender = (e) => {
  //   const freight = rowData.freight;
  //   const chargedAmount = e.data.chargedAmount;
  //   if (freight && chargedAmount) {
  //     return <div>{`${Math.round((parseInt(chargedAmount) / parseInt(freight)) * 100)}`}%</div>;
  //   }
  //   return <div>{e.text}%</div>;
  // };

  const onSubmit = async (data) => {
    const DRIVER = 4305; // habulDivision
    const COMPANY = 4304;
    const detail = await extraChargeRef.current.instance.option(
      "editing.changes"
    );
    const detailIsValid = await extraChargeRef.current.instance
      .getController("validating")
      .validate(true);
    if (!detailIsValid) return false;

    const rows = extraChargeRef.current.instance.getVisibleRows();

    for (const obj of rows) {
      if (obj.removed) continue;

      // 기사이면서 하불처가 있다. alert
      // 업체이면서 하불처가 없다. alert
      if (
        (obj.data.habulDivision === DRIVER && obj.data.habulPlace) ||
        (obj.data.habulDivision === COMPANY && !obj.data.habulPlace)
      ) {
        alert("하불처가 올바르지 않습니다.", "오류");
        return;
      }
    }

    try {
      await requestServer.changeExtraCharge({
        master: rowData,
        detail: detail,
      });

      dataGridRef.current.instance.refresh();
      onClose();
    } catch (err) {
      alert(err.response.data.message, "오류");
    }
  };

  const onInitNewRow = (e) => {
    e.data.isCharged = true;
    e.data.isHabul = true;
    // e.data.chargedRate = "%";
  };

  async function getLookup() {
    const divisionData = await requestServer.getLookupDatas(
      "comcode/detail/10300"
    );
    const habulDivisionData = await requestServer.getLookupDatas(
      "comcode/detail/10700"
    );
    const billingPlaceData = await requestServer.getLookupDatas(
      "companyManage/lookup/isBilling"
    );

    setLookupData({
      division: divisionData.data,
      habulDivision: habulDivisionData.data,
      billingPlace: billingPlaceData.data,
    });
  }

  // chargedRate를 통해 할증금액 계산
  const setCellValue = (newData, amount, currentRowData) => {
    newData.chargedRate = amount;
    const freight = rowData.freight;

    if (freight) {
      let result = Math.round((parseInt(amount) * parseInt(freight)) / 100); // 계산

      newData.chargedAmount = result;
    }
  };

  // chargedAmount 통해 chargedRate 계산
  function setAmountCellValue(newData, amount, currentRowData) {
    newData.chargedAmount = amount;
    const freight = rowData.freight;

    if (freight) {
      let result = Math.round((parseInt(amount) / parseInt(freight)) * 100); // 계산

      newData.chargedRate = result;
    }
  }

  return (
    <>
      <div className={"modal-overlay " + (visible ? "active" : "")} />
      <div
        className={"modal-wrap user-list-modal " + (visible ? "active" : "")}
        onClick={maskClosable ? onMaskClick : null}
        tabIndex="-1"
        visible={`${visible}`}
      >
        <Modal.Dialog centered tabIndex="0" size="lg" animation="true">
          <Modal.Body>
            <form className="modal-form" onSubmit={handleSubmit(onSubmit)}>
              <div className="modal-form-header">
                <div className="modal-form-tit">
                  <span className="modal-tit">할증등록</span>
                </div>
                <button
                  type="button"
                  className="btn-modal btn-modal-close"
                  onClick={onClose}
                >
                  <SVG
                    src={toAbsoluteUrl(
                      "/media/svg/icons/Custom/close_black_24dp.svg"
                    )}
                    className="close-icon"
                  />
                </button>
              </div>
              <div className="modal-form-body">
                <DataGrid
                  dataSource={dataSource}
                  onToolbarPreparing={onToolbarPreparing}
                  ref={extraChargeRef}
                  onInitNewRow={onInitNewRow}
                >
                  <Editing
                    mode="batch"
                    allowAdding={true}
                    allowDeleting={true}
                    allowUpdating={true}
                  />
                  <Column dataField="division" caption="구분">
                    <Lookup
                      dataSource={lookupData.division}
                      valueExpr="id"
                      displayExpr="subName"
                    />
                    <RequiredRule message="구분을 선택해주세요" />
                  </Column>
                  <Column
                    dataField="chargedRate"
                    caption="%"
                    setCellValue={setCellValue}
                  >
                    <Format type="fixedPoint" />
                    <RequiredRule message="할증 금액을 입력해주세요" />
                  </Column>
                  <Column
                    dataField="chargedAmount"
                    caption="할증금액"
                    dataType="number"
                    setCellValue={setAmountCellValue}
                  >
                    <Format type="fixedPoint" />
                  </Column>
                  <Column
                    dataField="isCharged"
                    caption="청구"
                    dataType="boolean"
                    width="35"
                  />
                  <Column
                    dataField="isHabul"
                    caption="하불"
                    dataType="boolean"
                    width="35"
                  />
                  <Column
                    dataField="habulDivision"
                    caption="하불구분"
                    width="70"
                  >
                    <Lookup
                      dataSource={lookupData.habulDivision}
                      valueExpr="id"
                      displayExpr="subName"
                    />
                    <RequiredRule message="하불구분을 선택해주세요" />
                  </Column>
                  <Column dataField="habulPlace" caption="하불처">
                    <Lookup
                      dataSource={lookupData.billingPlace}
                      valueExpr="oid"
                      displayExpr="company"
                    />
                  </Column>
                </DataGrid>
              </div>
              <div className="modal-form-footer">
                <button type="submit" className="btn btn-modal btn-save">
                  저장
                </button>
              </div>
            </form>
          </Modal.Body>
        </Modal.Dialog>
      </div>
    </>
  );
}

export function WareHouseModal({
  onClose,
  maskClosable,
  closable,
  visible,
  rowData,
}) {
  // const schema = yup
  //   .object({
  //     ex: yup.string(),
  //   })
  //   .required();

  // const {
  //   control,
  //   register,
  //   handleSubmit,
  //   watch,
  //   setValue,
  //   reset,
  //   formState: { errors },
  // } = useForm({
  //   resolver: yupResolver(schema),
  // });

  const onMaskClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  return (
    <>
      <div className={"modal-overlay " + (visible ? "active" : "")} />
      <div
        className={"modal-wrap user-list-modal " + (visible ? "active" : "")}
        onClick={maskClosable ? onMaskClick : null}
        tabIndex="-1"
        visible={`${visible}`}
      >
        <Modal.Dialog centered tabIndex="0" size="sm" animation="true">
          <Modal.Body>
            <form className="modal-form">
              <div className="modal-form-header">
                <div className="modal-form-tit">
                  <span className="modal-tit">창고작업확인</span>
                </div>
                <button
                  type="button"
                  className="btn-modal btn-modal-close"
                  onClick={onClose}
                >
                  <SVG
                    src={toAbsoluteUrl(
                      "/media/svg/icons/Custom/close_black_24dp.svg"
                    )}
                    className="close-icon"
                  />
                </button>
              </div>
              <div className="modal-form-body"></div>
              <div className="modal-form-footer"></div>
            </form>
          </Modal.Body>
        </Modal.Dialog>
      </div>
    </>
  );
}

// 작업지 도착시간 등록
export function ArrivalModal({
  onClose,
  maskClosable,
  closable,
  visible,
  rowData,
  dataGridRef,
}) {
  const schema = yup
    .object({
      workPlaceArrive: yup.string().required("반납일자를 선택해주세요."),
    })
    .required();

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    reset({
      original: rowData.workPlaceArrive || "",
      workPlaceArrive: moment(new Date()).format("yyyy-MM-DDTHH:mm:SS"),
    });
    // eslint-disable-next-line
  }, []);

  const onMaskClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  const onSubmit = async (data) => {
    const key = rowData.id;
    const workPlaceArrive = data.workPlaceArrive;

    try {
      await requestServer.changeArrival(key, workPlaceArrive);

      dataGridRef.current.instance.refresh();
      onClose();
    } catch (err) {
      alert(err.response.data.message, "오류");
    }
  };

  return (
    <>
      <div className={"modal-overlay " + (visible ? "active" : "")} />
      <div
        className={"modal-wrap user-list-modal " + (visible ? "active" : "")}
        onClick={maskClosable ? onMaskClick : null}
        tabIndex="-1"
        visible={`${visible}`}
      >
        <Modal.Dialog centered tabIndex="0" size="sm" animation="true">
          <Modal.Body>
            <form onSubmit={handleSubmit(onSubmit)} className="modal-form">
              <div className="modal-form-header">
                <div className="modal-form-tit">
                  <span className="modal-tit">작업지 도착시간 등록</span>
                </div>
                <button
                  type="button"
                  className="btn-modal btn-modal-close"
                  onClick={onClose}
                >
                  <SVG
                    src={toAbsoluteUrl(
                      "/media/svg/icons/Custom/close_black_24dp.svg"
                    )}
                    className="close-icon"
                  />
                </button>
              </div>
              <div className="modal-form-body">
                <div className="d-flex flex-row mb-1">
                  <div className="dx-field">
                    <InputDateTime
                      control={control}
                      errors={errors}
                      name="original"
                      label="기존일자"
                      flex={true}
                      disable={true}
                    />
                  </div>
                </div>

                <div className="d-flex flex-row mb-1">
                  <div className="dx-field">
                    <InputDateTime
                      control={control}
                      errors={errors}
                      name="workPlaceArrive"
                      label="변경일자"
                      flex={true}
                      onInitialized={(e) => {
                        setTimeout(() => {
                          e.component.focus();
                        }, 0);
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="modal-form-footer">
                <button type="submit" className="btn btn-modal btn-save">
                  적용
                </button>
              </div>
            </form>
          </Modal.Body>
        </Modal.Dialog>
      </div>
    </>
  );
}

// 반납일자 변경
export function ReturnModal({
  onClose,
  maskClosable,
  closable,
  visible,
  rowData,
  dataGridRef,
}) {
  const schema = yup
    .object({
      dem: yup.string().nullable(),
      returnDate: yup.string().nullable(),
    })
    .required();

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    reset({
      returnDate: rowData.returnDate || undefined,
      dem: rowData.dem || undefined,
    });
    // eslint-disable-next-line
  }, [rowData]);

  const onMaskClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  const onSubmit = async (data) => {
    const masterKey = rowData.orderCode;
    const detailKey = rowData.id;

    try {
      await requestServer.changeReturnDate({ masterKey, detailKey }, data);
      dataGridRef.current.instance.refresh();
      onClose();
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <div className={"modal-overlay " + (visible ? "active" : "")} />
      <div
        className={"modal-wrap user-list-modal " + (visible ? "active" : "")}
        onClick={maskClosable ? onMaskClick : null}
        tabIndex="-1"
        visible={`${visible}`}
      >
        <Modal.Dialog centered tabIndex="0" size="sm" animation="true">
          <Modal.Body>
            <form onSubmit={handleSubmit(onSubmit)} className="modal-form">
              <div className="modal-form-header">
                <div className="modal-form-tit">
                  <span className="modal-tit">반납일자 변경</span>
                </div>
                <button
                  type="button"
                  className="btn-modal btn-modal-close"
                  onClick={onClose}
                >
                  <SVG
                    src={toAbsoluteUrl(
                      "/media/svg/icons/Custom/close_black_24dp.svg"
                    )}
                    className="close-icon"
                  />
                </button>
              </div>
              <div className="modal-form-body">
                <div className="d-flex flex-row mb-1">
                  <div className="dx-field">
                    <InputDate
                      control={control}
                      errors={errors}
                      name="returnDate"
                      label="반납일자"
                      flex={true}
                      onInitialized={(e) => {
                        setTimeout(() => {
                          e.component.focus();
                        }, 0);
                      }}
                    />
                  </div>
                </div>
                <div className="d-flex flex-row mb-1">
                  <div className="dx-field">
                    <InputDate
                      control={control}
                      errors={errors}
                      name="dem"
                      label="선사반출기한"
                      flex={true}
                    />
                  </div>
                </div>
              </div>
              <div className="modal-form-footer">
                <button type="submit" className="btn btn-modal btn-save">
                  적용
                </button>
              </div>
            </form>
          </Modal.Body>
        </Modal.Dialog>
      </div>
    </>
  );
}

// 장기보관/취소
export function LongtermModal({
  onClose,
  maskClosable,
  closable,
  visible,
  rowData,
  dataGridRef,
}) {
  const onMaskClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  const onClick = async () => {
    const key = rowData.id;
    if (rowData.longStorage === true) {
      alert("장기보관 중인 화물입니다", "오류");
      return;
    }

    try {
      await requestServer.toggleLongterm(key, true);

      dataGridRef.current.instance.refresh();
      onClose();
    } catch (err) {
      console.log(err);
    }
  };

  const onCancel = async () => {
    console.log(rowData);
    const key = rowData.id;
    if (rowData.longStorage === false) {
      alert("장기보관 화물이 아닙니다", "오류");
      return;
    }
    try {
      await requestServer.toggleLongterm(key, false);

      dataGridRef.current.instance.refresh();
      onClose();
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <div className={"modal-overlay " + (visible ? "active" : "")} />
      <div
        className={"modal-wrap user-list-modal " + (visible ? "active" : "")}
        onClick={maskClosable ? onMaskClick : null}
        tabIndex="-1"
        visible={`${visible}`}
      >
        <Modal.Dialog centered tabIndex="0" size="sm" animation="true">
          <Modal.Body>
            <form className="modal-form">
              <div className="modal-form-header">
                <div className="modal-form-tit">
                  <span className="modal-tit">장기보관/취소</span>
                </div>
                <button
                  type="button"
                  className="btn-modal btn-modal-close"
                  onClick={onClose}
                >
                  <SVG
                    src={toAbsoluteUrl(
                      "/media/svg/icons/Custom/close_black_24dp.svg"
                    )}
                    className="close-icon"
                  />
                </button>
              </div>
              <div className="modal-form-body body-btn-set">
                <button
                  type="button"
                  onClick={onClick}
                  className="btn btn-modal btn-select-adjust"
                >
                  장기보관 적용
                </button>

                <button
                  type="button"
                  onClick={onCancel}
                  className="btn btn-modal btn-select-clear"
                >
                  장기보관 해제
                </button>
              </div>
            </form>
          </Modal.Body>
        </Modal.Dialog>
      </div>
    </>
  );
}

// 컨번호, 실번호 등록
export function ContainerNoModal({
  onClose,
  maskClosable,
  closable,
  visible,
  rowData,
  dataGridRef,
}) {
  const [conType, setConType] = useState();
  const schema = yup
    .object({
      containerNo: yup.string().required("컨테이너 번호를 입력하세요"),
      // sealNo: yup.string().required("실 번호를 입력하세요"),
    })
    .required();

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    reset({
      containerType: rowData.containerType || undefined,
      containerNo: rowData.containerNo || undefined,
      sealNo: rowData.sealNo || undefined,
    });
    getLookup();
    // eslint-disable-next-line
  }, []);

  const onMaskClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  const onSubmit = async (data) => {
    const key = rowData.id;
    try {
      await requestServer.changeContainerNo(key, data);
      dataGridRef.current.instance.refresh();
      onClose();
    } catch (err) {
      console.log(err);
    }
  };

  async function getLookup() {
    const conTypeData = await requestServer.getLookupDatas(
      "comcode/detail/10400"
    );

    setConType(conTypeData.data);
  }

  return (
    <>
      <div className={"modal-overlay " + (visible ? "active" : "")} />
      <div
        className={"modal-wrap user-list-modal " + (visible ? "active" : "")}
        onClick={maskClosable ? onMaskClick : null}
        tabIndex="-1"
        visible={`${visible}`}
      >
        <Modal.Dialog centered tabIndex="0" size="sm" animation="true">
          <Modal.Body>
            <form onSubmit={handleSubmit(onSubmit)} className="modal-form">
              <div className="modal-form-header">
                <div className="modal-form-tit">
                  <span className="modal-tit">컨번호, 실번호 등록</span>
                </div>
                <button
                  type="button"
                  className="btn-modal btn-modal-close"
                  onClick={onClose}
                >
                  <SVG
                    src={toAbsoluteUrl(
                      "/media/svg/icons/Custom/close_black_24dp.svg"
                    )}
                    className="close-icon"
                  />
                </button>
              </div>
              <div className="modal-form-body">
                <div className="d-flex flex-row mb-1">
                  <div className="dx-field">
                    <InputLookup
                      control={control}
                      errors={errors}
                      name="containerType"
                      label="컨테이너 규격"
                      flex={true}
                      dataSource={conType}
                      valueExpr="subCode"
                      displayExpr="subName"
                      onInitialized={(e) => {
                        e.component.open();
                        setTimeout(() => {
                          e.component.focus();
                        }, 0);
                      }}
                    />
                  </div>
                </div>

                <div className="d-flex flex-row mb-1">
                  <div className="dx-field">
                    <InputText
                      control={control}
                      errors={errors}
                      name="containerNo"
                      label="컨테이너 번호"
                      flex={true}
                    />
                  </div>
                </div>

                <div className="d-flex flex-row mb-1">
                  <div className="dx-field">
                    <InputText
                      control={control}
                      errors={errors}
                      name="sealNo"
                      label="실(seal) 넘버"
                      flex={true}
                    />
                  </div>
                </div>
              </div>
              <div className="modal-form-footer">
                <button type="submit" className="btn btn-modal btn-save">
                  등록
                </button>
              </div>
            </form>
          </Modal.Body>
        </Modal.Dialog>
      </div>
    </>
  );
}

export function WarehouseTimeModal({
  onClose,
  maskClosable,
  closable,
  visible,
  rowData,
  dataGridRef,
}) {
  const schema = yup
    .object({
      warehouseArrive: yup.string().required("창고입고날짜를 입력하세요"),
    })
    .required();

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onMaskClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  useEffect(() => {
    reset({
      warehouseArrive: moment().format("YYYY-MM-DD"),
    });
    // eslint-disable-next-line
  }, []);

  const onSubmit = async (data) => {
    const key = rowData.id;
    try {
      await requestServer.setWarehouseArrive(key, data);
      dataGridRef.current.instance.refresh();
      onClose();
    } catch (err) {
      alert(err.response.data.message, "오류");
    }
  };

  return (
    <>
      <div className={"modal-overlay " + (visible ? "active" : "")} />
      <div
        className={"modal-wrap user-list-modal " + (visible ? "active" : "")}
        onClick={maskClosable ? onMaskClick : null}
        tabIndex="-1"
        visible={`${visible}`}
      >
        <Modal.Dialog centered tabIndex="0" size="sm" animation="true">
          <Modal.Body>
            <form onSubmit={handleSubmit(onSubmit)} className="modal-form">
              <div className="modal-form-header">
                <div className="modal-form-tit">
                  <span className="modal-tit">창고 입고 날짜 등록</span>
                </div>
                <button
                  type="button"
                  className="btn-modal btn-modal-close"
                  onClick={onClose}
                >
                  <SVG
                    src={toAbsoluteUrl(
                      "/media/svg/icons/Custom/close_black_24dp.svg"
                    )}
                    className="close-icon"
                  />
                </button>
              </div>
              <div className="modal-form-body">
                <div className="d-flex flex-row mb-1">
                  <div className="dx-field">
                    <InputDate
                      control={control}
                      errors={errors}
                      name="warehouseArrive"
                      label="창고입고 날짜"
                      flex={true}
                    />
                  </div>
                </div>
              </div>
              <div className="modal-form-footer">
                <button type="submit" className="btn btn-modal btn-save">
                  등록
                </button>
              </div>
            </form>
          </Modal.Body>
        </Modal.Dialog>
      </div>
    </>
  );
}

export function ExtraInfoModal({
  onClose,
  maskClosable,
  closable,
  visible,
  rowData,
  dataGridRef,
}) {
  const schema = yup
    .object({
      extraInfo: yup.string().nullable(),
    })
    .required();

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onMaskClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  useEffect(() => {
    reset({
      extraInfo: rowData.extraInfo || "",
    });
    // eslint-disable-next-line
  }, []);

  const onSubmit = async (data) => {
    const key = rowData.id;
    try {
      await requestServer.setExtraInfo(key, data);
      dataGridRef.current.instance.refresh();
      onClose();
    } catch (err) {
      alert(err.response.data.message, "오류");
    }
  };

  return (
    <>
      <div className={"modal-overlay " + (visible ? "active" : "")} />
      <div
        className={"modal-wrap user-list-modal " + (visible ? "active" : "")}
        onClick={maskClosable ? onMaskClick : null}
        tabIndex="-1"
        visible={`${visible}`}
      >
        <Modal.Dialog centered tabIndex="0" size="sm" animation="true">
          <Modal.Body>
            <form onSubmit={handleSubmit(onSubmit)} className="modal-form">
              <div className="modal-form-header">
                <div className="modal-form-tit">
                  <span className="modal-tit">특이사항 등록</span>
                </div>
                <button
                  type="button"
                  className="btn-modal btn-modal-close"
                  onClick={onClose}
                >
                  <SVG
                    src={toAbsoluteUrl(
                      "/media/svg/icons/Custom/close_black_24dp.svg"
                    )}
                    className="close-icon"
                  />
                </button>
              </div>
              <div className="modal-form-body">
                <div className="d-flex flex-row mb-1">
                  <div className="dx-field">
                    <InputText
                      control={control}
                      errors={errors}
                      name="extraInfo"
                      label="특이사항"
                      flex={true}
                    />
                  </div>
                </div>
              </div>
              <div className="modal-form-footer">
                <button type="submit" className="btn btn-modal btn-save">
                  등록
                </button>
              </div>
            </form>
          </Modal.Body>
        </Modal.Dialog>
      </div>
    </>
  );
}

// functionWarehouseTimeModal
