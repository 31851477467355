import { DataGrid } from "devextreme-react";
import {
  Column,
  Editing,
  Format,
  RequiredRule,
} from "devextreme-react/data-grid";
import React from "react";

const CarDeadlineInputGrid = ({
  state,
  inputGridRef,
  inputEtcGridRef,
  inputFunctions,
}) => {
  const {
    onInitNewRow,
    onEditingStart,
    onGridToolbarPreparing,
    onEtcToolbarPreparing,
    cellRender,
    onGridOptionChanged,
    onEtcGridOptionChanged,
  } = inputFunctions;

  const { detailDataSource, detailEtcDataSource } = state;

  return (
    <>
      <DataGrid
        className="datagrid-wrap"
        ref={inputGridRef}
        dataSource={detailDataSource}
        showRowLines={true}
        showBorders={true}
        allowColumnResizing={true}
        onEditingStart={onEditingStart}
        onToolbarPreparing={onGridToolbarPreparing}
        onOptionChanged={onGridOptionChanged}
        keyExpr="oid"
      >
        <Editing mode="batch" allowUpdating={true} />
        <Column dataField="chargeCode" caption="구분" allowEditing={false} />
        <Column dataField="title" caption="항목명" />
        <Column
          dataField="money"
          caption="금액"
          alignment="right"
          dataType="number"
        >
          <Format type="fixedPoint" />
        </Column>
        <Column dataField="Remark" caption="비고" />
      </DataGrid>

      <DataGrid
        className="datagrid-wrap"
        ref={inputEtcGridRef}
        dataSource={detailEtcDataSource}
        showRowLines={true}
        showBorders={true}
        allowColumnResizing={true}
        onInitNewRow={onInitNewRow}
        onEditingStart={onEditingStart}
        onToolbarPreparing={onEtcToolbarPreparing}
        onOptionChanged={onEtcGridOptionChanged}
        keyExpr="oid"
      >
        <Editing mode="batch" allowUpdating={true} allowAdding={true} />
        <Column alignment="center" caption="삭제" cellRender={cellRender} />
        <Column dataField="chargeCode" caption="구분" allowEditing={false} />
        <Column dataField="title" caption="항목명">
          <RequiredRule message="항목명을 입력해주세요" />
        </Column>
        <Column
          dataField="money"
          caption="금액"
          alignment="right"
          dataType="number"
        >
          <Format type="fixedPoint" />
        </Column>
        <Column dataField="Remark" caption="비고" />
      </DataGrid>
    </>
  );
};

export default CarDeadlineInputGrid;
