import axios from "axios";

const apiUrl = `${process.env.REACT_APP_API_URL}/app-sheet`;
const apiUrlLookup = `${process.env.REACT_APP_API_URL}`;

export function getLookup(param) {
  return axios.get(apiUrlLookup + `/${param}`);
}

export function createAppSheet(data) {
  return axios.post(apiUrl, data);
}

export function updateAppSheet(data) {
  return axios.put(apiUrl, data);
}

export function deleteAppSheet(key) {
  return axios.delete(apiUrl + `/${key}`);
}
