import { alert, confirm } from "devextreme/ui/dialog";
import React, { useEffect, useMemo, useState } from "react";
import CustomButton from "../../../../components/FunctionButton/CustomButton";
import { ButtonGroup } from "../../../../components/FunctionButton/CustomButton/customButton.style";
import {
  InputCheckBox,
  InputColorSelectBox,
  InputDate,
  InputRadioGroup,
  InputSelect,
  InputText,
  InputTextArea,
} from "../../../../components/InputForm";
import {
  InputFullRow,
  PanelInput,
  PanelInputTitle,
  PanelInputWrapper,
} from "../../../../components/Layout/Panel/PanelInput/panelInput.style";
import {
  CompanySelect,
  ForwarderSelect,
  TerminalSelect,
} from "../../../../components/OrderModalSelects";
import {
  getUnipassInfos,
  handleOtherDeliver,
  makeOtherCoList,
  submitConfirm,
  trimOrderMain,
} from "../../../../utils/orderFunctions";
import {
  createOrder,
  onClickConfirmCreate,
  onClickConfirmUpdate,
  updateOrder,
} from "../LCLOrderCrud";

const LCLOrderInput = ({
  selectedRowKey,
  openInputBar,
  isLoading,
  dataGridRef,
  detailGrid,
  inputForm,
  conType,
  lookupData,
  initInputData,
  setLoading,
  isConfirm,
  setCompanyCreateModal,
  setForwarderCreateModal,
  setTerminalCreateModal,

  isOrderEditable,
}) => {
  const [otherCo, setOtherCo] = useState([]);

  const {
    control,
    handleSubmit,
    watch,
    setValue,
    trigger,
    formState: { errors },
  } = inputForm;

  const makeOtherCo = useMemo(
    () => makeOtherCoList(lookupData, watch("receiveCo")),
    // eslint-disable-next-line
    [lookupData.company, watch("receiveCo")]
  );

  useEffect(() => {
    setOtherCo(makeOtherCo);
    if (!isLoading) {
      handleOtherDeliver(
        { data: watch() },
        watch("receiveCo"),
        watch("otherDeliver"),
        setValue
      );
    }
    // eslint-disable-next-line
  }, [lookupData.company, watch("receiveCo"), otherCo, handleOtherDeliver]);

  useEffect(() => {
    initInputData(selectedRowKey);
    // eslint-disable-next-line
  }, [selectedRowKey]);

  // 저장
  const onClickSubmit = async (data) => {
    const detail = detailGrid.current.instance.option("editing.changes");
    const rows = detailGrid.current.instance.getVisibleRows();

    const detailValid = await submitConfirm(detail, rows, detailGrid.current);

    if (!detailValid) return;

    trimOrderMain(data, detail, "lclorder");

    try {
      setLoading(true);
      let code = null;

      if (selectedRowKey) {
        code = selectedRowKey;
        if (data.confirm) {
          const res = await onClickConfirmUpdate({
            master: data,
            detail,
          });

          if (res.data?.message) {
            alert(res.data?.message, "오류");
            return;
          }
        } else {
          await updateOrder({ master: data, detail });
        }
      } else {
        const {
          data: { result },
        } = await createOrder({
          master: data,
          detail,
        });
        code = result?.orderMasterCreateDataCode;
      }

      refetchInputData(code);
    } catch (err) {
      console.log(err);
      alert(err?.response?.data?.message || "ERROR-01", "오류");
    } finally {
      setLoading(false);
    }
  };

  // 오더확정
  const onClickConfirmOrder = async () => {
    if (!(await trigger())) return;
    const data = watch();

    const detail = detailGrid.current.instance.option("editing.changes");
    const rows = detailGrid.current.instance.getVisibleRows();

    const detailValid = await submitConfirm(detail, rows, detailGrid.current);

    if (!detailValid) return;

    if (!(await confirm("오더확정하시겠습니까?", "확인"))) return;

    trimOrderMain(data, detail, "lclorder");

    try {
      setValue("confirm", true);
      setLoading(true);
      let code = null;

      if (selectedRowKey) {
        await onClickConfirmUpdate({ master: data, detail });

        code = selectedRowKey;
      } else {
        const {
          data: { result },
        } = await onClickConfirmCreate({ master: data, detail });

        code = result?.orderMasterCreateDataCode;
      }

      refetchInputData(code);
    } catch (err) {
      setValue("confirm", false);
      alert(err?.response?.data?.message || "ERROR-01", "오류");
    } finally {
      setLoading(false);
    }
  };

  const refetchInputData = async (code) => {
    // 세부정보 reset
    await detailGrid.current.instance.option("editing.changes", []);
    // focus시키기
    await dataGridRef.current.instance.option("focusedRowKey", code);

    // refresh
    dataGridRef.current.instance.refresh();
    detailGrid.current.instance.refresh();
  };

  return (
    <PanelInputWrapper
      onSubmit={handleSubmit(onClickSubmit)}
      openInputBar={openInputBar}
    >
      <PanelInputTitle>신규오더 접수 및 수정</PanelInputTitle>
      <PanelInput grid={2}>
        <InputText
          control={control}
          errors={errors}
          label="접수번호"
          name="code"
          flex={true}
          disabled={true}
        />
        <InputSelect
          control={control}
          errors={errors}
          label="수입/수출"
          name="importExport"
          displayExpr="name"
          valueExpr="id"
          dataSource={[
            { id: 1, name: "수입" },
            { id: 2, name: "수출" },
          ]}
          flex={true}
          disable={isOrderEditable}
        />
        <InputSelect
          searchEnabled={true}
          control={control}
          errors={errors}
          name="receiveCo"
          label="접수법인"
          flex={true}
          dataSource={lookupData.company}
          valueExpr="id"
          displayExpr="companyName"
          disable={isOrderEditable}
        />
        <InputDate
          control={control}
          errors={errors}
          name="receiveDate"
          label="접수일자"
          flex={true}
          onInitialized={(e) => {
            setTimeout(() => {
              e.component.focus();
            }, 0);
          }}
          disable={isOrderEditable}
        />
        <InputSelect
          searchEnabled={true}
          control={control}
          errors={errors}
          dataSource={lookupData.isDispatch}
          displayExpr="deptName"
          valueExpr="code"
          name="dispatchTeam"
          label="배차팀"
          flex={true}
          disable={isOrderEditable}
        />
        <CompanySelect
          control={control}
          errors={errors}
          name="billingPlace"
          label="청구처"
          dataSource={lookupData.billing}
          onClick={() => {
            setCompanyCreateModal(true);
          }}
          disable={isOrderEditable}
        />
        <CompanySelect
          control={control}
          errors={errors}
          name="orderCo"
          label="발주처"
          dataSource={lookupData.orderCo}
          onClick={() => {
            setCompanyCreateModal(true);
          }}
          disable={isOrderEditable}
        />
        <ForwarderSelect
          control={control}
          errors={errors}
          name="forwarder"
          label="선사"
          dataSource={lookupData.forwarder}
          onClick={() => {
            setForwarderCreateModal(true);
          }}
          disable={isOrderEditable}
        />
        <InputText
          control={control}
          errors={errors}
          name="shipName"
          label="선명"
          flex={true}
          disabled={isOrderEditable}
        />
        <InputText
          control={control}
          errors={errors}
          name="motherShip"
          label="모선/항차"
          flex={true}
          disabled={isOrderEditable}
        />

        {watch("importExport") === 1 && (
          <>
            <InputDate
              control={control}
              errors={errors}
              name="dem"
              label="선사반출기한"
              flex={true}
              disable={isOrderEditable}
            />
          </>
        )}
        <InputText
          control={control}
          errors={errors}
          name="manager"
          label="청구처담당자"
          flex={true}
          disabled={isOrderEditable}
        />
        <InputDate
          name="enterDay"
          control={control}
          errors={errors}
          label="입항일자"
          flex={true}
          disable={isOrderEditable}
        />
        <InputDate
          name="sailDay"
          control={control}
          errors={errors}
          label="출항일자"
          flex={true}
          disable={isOrderEditable}
        />
        {watch("importExport") === 2 && (
          <>
            <InputText
              control={control}
              errors={errors}
              name="bkNo"
              label="B/K No"
              flex={true}
              disabled={isOrderEditable}
            />
          </>
        )}
        {watch("importExport") === 1 && (
          <>
            <InputText
              control={control}
              errors={errors}
              name="doNo"
              label="DO No"
              flex={true}
              disabled={isOrderEditable}
            />
            <InputText
              control={control}
              errors={errors}
              name="blNo"
              label="B/L No"
              flex={true}
              disabled={isOrderEditable}
            />
            <InputRadioGroup
              control={control}
              errors={errors}
              name="blType"
              dataSource={[
                { id: 1, name: "M" },
                { id: 2, name: "H" },
              ]}
              label="B/L 구분"
              flex={true}
              disable={isOrderEditable}
            />
            <CustomButton
              type="button"
              width="full"
              height={30}
              layout="line"
              color="common"
              label="UNI-PASS 연동"
              isLoading={isLoading}
              className={`
                ${isLoading && "spinner spinner-white spinner-right"}
                `}
              onClick={() =>
                getUnipassInfos(
                  watch,
                  setLoading,
                  setValue,
                  detailGrid.current,
                  conType
                )
              }
              disabled={isLoading || isConfirm || isOrderEditable}
            />
          </>
        )}
        <InputText
          control={control}
          errors={errors}
          name="loadPlaceName"
          label="상차지텍스트"
          flex={true}
          disabled={isOrderEditable}
        />
        <InputText
          control={control}
          errors={errors}
          name="unloadPlaceName"
          label="하차지텍스트"
          flex={true}
          disabled={isOrderEditable}
        />
        <TerminalSelect
          control={control}
          errors={errors}
          name="loadPlace"
          label="상차지"
          dataSource={lookupData.terminal}
          onClick={() => {
            setTerminalCreateModal(true);
          }}
          disable={isConfirm}
        />
        <TerminalSelect
          control={control}
          errors={errors}
          name="unloadPlace"
          label="하차지"
          dataSource={lookupData.terminal}
          onClick={() => {
            setTerminalCreateModal(true);
          }}
          disable={isConfirm}
        />
        <CompanySelect
          control={control}
          errors={errors}
          name="cargoOwner"
          label="화주"
          dataSource={lookupData.cargoOwner}
          onClick={() => {
            setCompanyCreateModal(true);
          }}
          disable={isOrderEditable}
        />
        <InputText
          control={control}
          errors={errors}
          name="realCargoOwner"
          label="실화주"
          flex={true}
          disabled={isOrderEditable}
        />
        <InputSelect
          control={control}
          errors={errors}
          dataSource={otherCo}
          name="otherDeliver"
          label="타법인 배차"
          valueExpr="id"
          displayExpr="companyName"
          flex={true}
        />
        <InputColorSelectBox
          control={control}
          errors={errors}
          dataSource={lookupData.color}
          label="색상"
          name="color"
          valueExpr="subCode"
          displayExpr="subName"
          flex={true}
          disable={isOrderEditable}
        />
        <InputFullRow grid={2}>
          <InputCheckBox
            control={control}
            errors={errors}
            name="fridge"
            text="냉동"
            flex={true}
            disabled={isOrderEditable}
          />
          <InputCheckBox
            control={control}
            errors={errors}
            name="danger"
            text="위험물"
            flex={true}
            disabled={isOrderEditable}
          />
          <InputCheckBox
            control={control}
            errors={errors}
            name="tank"
            text="탱크"
            flex={true}
            disabled={isOrderEditable}
          />
          <InputCheckBox
            control={control}
            errors={errors}
            name="direct"
            text="직반입"
            flex={true}
            disabled={isOrderEditable}
          />
          <InputCheckBox
            control={control}
            errors={errors}
            name="toxic"
            text="유해화학물질"
            flex={true}
            disabled={isOrderEditable}
          />
          <InputCheckBox
            control={control}
            errors={errors}
            name="keep"
            text="보관"
            flex={true}
            disabled={isOrderEditable}
          />
        </InputFullRow>
        <InputFullRow grid={2}>
          <InputTextArea
            control={control}
            errors={errors}
            name="remark"
            label="비고"
            flex={true}
            disabled={isOrderEditable}
          />
        </InputFullRow>
        <InputFullRow grid={2}>
          <InputTextArea
            control={control}
            errors={errors}
            name="innerMemo"
            label="내부메모"
            flex={true}
            disabled={isOrderEditable}
          />
        </InputFullRow>
      </PanelInput>
      <ButtonGroup marginTop={20}>
        <CustomButton
          type="submit"
          width="100%"
          height={36}
          layout="solid"
          color="common"
          label={isConfirm ? "저장" : "임시저장"}
          className={`${isLoading && "spinner spinner-white spinner-right"}`}
          disabled={isLoading}
        />

        {!isConfirm && (
          <CustomButton
            type="button"
            width="100%"
            height={36}
            layout="solid"
            color="common"
            label="오더확정"
            onClick={() => onClickConfirmOrder()}
            className={`${isLoading && "spinner spinner-white spinner-right"}`}
            disabled={isLoading}
          />
        )}
      </ButtonGroup>
    </PanelInputWrapper>
  );
};

export default LCLOrderInput;
