import DataGrid, {
  Button,
  Column,
  CustomRule,
  Editing,
  Format,
  Lookup,
  RequiredRule,
  Scrolling,
} from "devextreme-react/data-grid";
import React, { useCallback } from "react";
import CustomButton from "../../../../components/FunctionButton/CustomButton";
import { ButtonGroup } from "../../../../components/FunctionButton/CustomButton/customButton.style";
import { PanelSubGridInit } from "../../../../components/Layout/Panel/PanelGrid/panelGrid.style";
import { PanelInputTitle } from "../../../../components/Layout/Panel/PanelInput/panelInput.style";
import { containerValidationCallback } from "../../../../utils/orderFunctions";
import dayjs from "dayjs";

const CreateLCLOrderModalDetail = ({
  dataSource,
  inputForm,
  isConfirm,
  detailGrid,
  extraChargeFunctions,
  setTerminalCreateModal,
  setChangeAllModal,
  lookupData,
}) => {
  const {
    extraChargeCellRender,
    etcChargeHabulCellRender,
    extraChargeCellButtonRender,
  } = extraChargeFunctions;

  const { watch } = inputForm;
  const { fridge, danger, importExport } = watch();

  // 모두 밥테일로 변경
  const onClickAllBobtail = () => {
    const rows = detailGrid.current.instance.getVisibleRows();

    for (const obj of rows) {
      detailGrid.current.instance.cellValue(obj.rowIndex, "bobtail", true);
    }
  };

  const setCellValue = (newData, deliverDate) => {
    if (deliverDate) {
      newData.deliverDate = dayjs(deliverDate).format("YYYY-MM-DD");
    }
  };

  const onInitNewRow = (e) => {
    e.data.bobtail = false;
    e.data.deliverDate = dayjs().format("YYYY-MM-DD");
    e.data.extraChargeObj = [];
    e.data.etcChargeHabulObj = [];
  };

  const onToolbarPreparing = (e) => {
    e.toolbarOptions.items.forEach((item) => {
      if (item.name === "saveButton") {
        item.visible = false;
      }
    });
  };

  const onEditorPreparing = useCallback((e) => {
    // eslint-disable-next-line
    if (e.parentType == "dataRow" && e.dataField == "sealNo") {
      e.editorOptions.maxLength = 20;
    }
  }, []);

  const onEditingStart = (e) => {
    if (e.data.completeNo) {
      e.cancel = true;
    }
  };
  return (
    <>
      <PanelInputTitle position="absolute">
        <span>오더 세부정보 등록</span>
        <ButtonGroup gridBtn={true} right={93} zIndex={5}>
          <CustomButton
            type="button"
            height={26}
            layout="line"
            color="new"
            label="작업지 추가"
            onClick={() => setTerminalCreateModal(true)}
          />
          {!isConfirm && (
            <CustomButton
              type="button"
              height={26}
              layout="line"
              color="common"
              label="밥테일로 변경"
              onClick={onClickAllBobtail}
            />
          )}
        </ButtonGroup>
      </PanelInputTitle>

      <PanelSubGridInit>
        <DataGrid
          id="CreateLCLOrderDetail"
          className="datagrid-wrap"
          dataSource={dataSource}
          ref={detailGrid}
          keyExpr="id"
          showRowLines={true}
          showBorders={true}
          hoverStateEnabled={true}
          allowColumnResizing={true}
          columnResizingMode="widget"
          wordWrapEnabled={true}
          onInitNewRow={onInitNewRow}
          onToolbarPreparing={onToolbarPreparing}
          onEditorPreparing={onEditorPreparing}
          onEditingStart={onEditingStart}
        >
          <Scrolling
            columnRenderingMode="virtual"
            mode="virtual"
            showScrollbar="always"
            useNative="false"
          />
          <Editing
            mode="batch"
            allowAdding={true}
            allowUpdating={true}
            allowDeleting={true}
            startEditAction="click"
          />
          <Column type="buttons" width={55}>
            <Button name="delete" text="삭제" />
          </Column>
          <Column
            width={70}
            caption="No"
            cellRender={(e) => e.row.loadIndex + 1}
          />
          <Column
            dataField="deliverDate"
            caption="운송(요청)일자"
            dataType="date"
            format="yyyy-MM-dd"
            width={100}
          >
            <RequiredRule />
          </Column>
          <Column dataField="containerNo" caption="컨테이너번호" width={140}>
            <CustomRule
              message="컨테이너번호가 올바르지 않습니다."
              validationCallback={containerValidationCallback}
            />
          </Column>
          <Column dataField="containerType" caption="컨/규격" width={60}>
            <Lookup
              dataSource={lookupData.conType}
              valueExpr="subCode"
              displayExpr="subName"
            />
          </Column>

          <Column dataField="sealNo" caption="Seal No" width={140} />
          <Column dataField="workWaypoint" caption="작업경유지" width={75}>
            <Lookup
              dataSource={lookupData.waypoint}
              valueExpr="code"
              displayExpr="twName"
            />
          </Column>
          <Column dataField="collectWaypoint" caption="회수경유지" width={75}>
            <Lookup
              dataSource={lookupData.waypoint}
              valueExpr="code"
              displayExpr="twName"
            />
          </Column>
          <Column dataField="workPlace" caption="작업지" width={70}>
            <Lookup
              dataSource={lookupData.workPlace}
              valueExpr="code"
              displayExpr="twName"
            />
          </Column>
          <Column
            dataField="workTime"
            caption="작업시간"
            format="HH:mm:ss"
            dataType="datetime"
            editorOptions={{
              displayFormat: "HH:mm:ss",
              useMaskBehavior: true,
              type: "time",
            }}
            width="100"
          />
          <Column
            caption="작업지주소"
            calculateCellValue={(e) =>
              lookupData.workPlace?.find((obj) => obj.code === e.workPlace)
                ?.address
            }
            allowEditing={false}
            width={120}
          />
          <Column
            dataField="manager"
            caption="도착지담당자"
            width={100}
            alignment="center"
          />
          <Column
            dataField="pointManageContact"
            caption="도착지담당자연락처"
            width={100}
            alignment="center"
          />
          <Column
            dataField="freight"
            caption="청구금액"
            width={75}
            alignment="right"
            dataType="number"
          >
            <RequiredRule />
            <Format type="fixedPoint" />
          </Column>
          <Column
            dataField="extraCharge"
            caption="할증금액"
            width={100}
            alignment="right"
            dataType="number"
            cellRender={extraChargeCellRender}
            allowEditing={false}
          >
            <Format type="fixedPoint" />
          </Column>
          <Column
            dataField="etcChargeHabul"
            caption="기타하불"
            width={100}
            alignment="right"
            dataType="number"
            cellRender={etcChargeHabulCellRender}
            allowEditing={false}
          >
            <Format type="fixedPoint" />
          </Column>
          <Column
            caption="할증등록"
            width={100}
            cellRender={extraChargeCellButtonRender}
            allowEditing={false}
            alignment="center"
          />
          <Column dataField="amount" caption="수량" width="90" />
          <Column dataField="cargoWeight" caption="화물중량(ton)" width={90} />
          <Column dataField="spec" caption="규격" width="90" />
          <Column
            dataField="returnDate"
            caption="반납마감일자"
            width={100}
            dataType="date"
            format="yyyy-MM-dd"
          />
          <Column
            dataField="freightDate"
            caption="청구일자"
            width={100}
            dataType="date"
            format="yyyy-MM-dd"
          />

          {(fridge || danger) && (
            <Column dataField="temperature" caption="온도" width={75} />
          )}
          {(fridge || danger) && (
            <Column dataField="humidity" caption="습도" width={75} />
          )}
          {(fridge || danger) && (
            <Column dataField="vent" caption="환풍구" width={75} />
          )}
          {(fridge || danger) && (
            <Column dataField="class" caption="클래스" width={75} />
          )}
          {(fridge || danger) && (
            <Column dataField="unNo" caption="UN넘버" width={75} />
          )}
          {(fridge || danger) && (
            <Column dataField="extraInfo" caption="기타정보" width={75} />
          )}
          <Column
            dataField="bobtail"
            caption="밥테일"
            dataType="boolean"
            width={50}
          />
        </DataGrid>
      </PanelSubGridInit>
    </>
  );
};

export default CreateLCLOrderModalDetail;
