import React, { useMemo, useEffect, useState, useCallback } from "react";
import { DataGrid } from "devextreme-react";
import {
  Column,
  ColumnChooser,
  HeaderFilter,
  Lookup,
  Scrolling,
  StateStoring,
} from "devextreme-react/data-grid";
import CustomStore from "devextreme/data/custom_store";
import { sendRequest } from "../../../../_metronic/_helpers/DataSoureHelpers";
import { useForm } from "react-hook-form";
import * as requestServer from "./CarCrud";
import StateStoringSaveAndDeleteButton from "../../../components/StateStoring";

function CarMaster({ onFocusedRowChanged, dataGridRef }) {
  const [totalCount, setTotalCount] = useState(0);
  const [dataSourceDriver, setDataSourceDriver] = useState();
  const [carKind, setCarKind] = useState();
  const [search, setSearch] = useState({});
  const storageKey = "carMaster";

  const dataSource = useMemo(() => {
    const apiUrl = `${process.env.REACT_APP_API_URL}/car`;

    return new CustomStore({
      key: "code",
      load: () => sendRequest(apiUrl, "GET", search),
      insert: (values) => sendRequest(apiUrl, "POST", values),
      update: (key, values) => sendRequest(apiUrl, "PUT", { key, values }),
      remove: (key) => sendRequest(apiUrl, "DELETE", key),
    });
  }, [search]);

  useEffect(() => {
    async function getLookup() {
      try {
        const driverLookup = await requestServer.getLookup("driver");
        const carKindLookup = await requestServer.getLookup(
          "comcode/detail/30500"
        );

        setDataSourceDriver(driverLookup.data);
        setCarKind(carKindLookup.data);
      } catch (err) {
        console.log(err);
      }
    }
    getLookup();
  }, []);

  const { register, handleSubmit } = useForm();

  const onSubmit = (data) => {
    for (const obj in data) {
      if (data[obj] === "") {
        data[obj] = null;
      }
    }
    setSearch(data);
  };

  return (
    <>
      <div className="grid-search-wrap">
        <form onSubmit={handleSubmit(onSubmit)}>
          <span className="search-group">
            <label>차량번호</label>
            <input type="text" {...register("carNo")} />
          </span>
          <button type="submit" className="btn btn-grid-search single-line">
            검색
          </button>
        </form>
      </div>
      <div className="grid-component has-toolbar">
        <StateStoringSaveAndDeleteButton
          dataGridRef={dataGridRef}
          storageKey={storageKey}
        />
        <div className="grid-total">{`총 ${totalCount}건`}</div>
        <DataGrid
          className="datagrid-wrap"
          id="CarMaster"
          dataSource={dataSource}
          ref={dataGridRef}
          showRowLines={true}
          showBorders={true}
          hoverStateEnabled={true}
          allowColumnResizing={true}
          allowColumnReordering={true}
          columnResizingMode="widget"
          keyExpr="code"
          focusedRowEnabled={true}
          onFocusedRowChanged={onFocusedRowChanged}
          onContentReady={(e) => setTotalCount(e.component.totalCount())}
        >
          <HeaderFilter visible={true} />
          <ColumnChooser enabled={true} />
          <StateStoring
            enabled={true}
            type="custom"
            customLoad={useCallback(() => {
              return JSON.parse(localStorage.getItem(storageKey));
            }, [])}
          />
          <Scrolling mode="virtual" />
          <Column dataField="code" caption="차량코드" alignment="center" />
          <Column dataField="carNo" caption="차량번호" />
          <Column dataField="carName" caption="차량명" alignment="center" />
          <Column dataField="catType" caption="차량종류" alignment="center">
            <Lookup dataSource={carKind} displayExpr="subName" valueExpr="id" />
          </Column>{" "}
          {/* conCodeDetail id */}
          <Column dataField="isPaidEntry" caption="지입여부" />
          <Column dataField="driverId" caption="기사" alignment="center">
            <Lookup
              dataSource={dataSourceDriver}
              valueExpr="id"
              displayExpr="driverName"
            />
          </Column>
          <Column dataField="chassisNo" caption="샤시" />
          <Column
            dataField="carPark_comCodeDetail.subName"
            caption="주차장"
            alignment="center"
          />
          <Column dataField="remark" caption="비고" alignment="center" />
        </DataGrid>
      </div>
    </>
  );
}

export default CarMaster;
