import React, { useRef, useState } from "react";
import { useForm } from "react-hook-form";
import CustomButton from "../../../components/FunctionButton/CustomButton";
import FunctionButton from "../../../components/FunctionButton/FunctionButton";
import HeaderTitle from "../../../components/Layout/Header/HeaderTitle";
import {
  PanelGridInit,
  PanelGridSearchBar,
  PanelGridWrapper,
  SearchItems,
} from "../../../components/Layout/Panel/PanelGrid/panelGrid.style";
import {
  PanelBody,
  PanelBtn,
  PanelHeader,
  PanelInit,
} from "../../../components/Layout/Panel/panel.style";
import { useDatagrid } from "../../../hooks/useDatagrid";
import ComCodeMasterList from "./ComCdoeMasterList";
import ComCodeDetailList from "./ComCodeDetailList";

export default function ComCodePage() {
  const [rowData, setRowData] = useState();
  const dataGridRef = useRef();
  const dataGridDetailRef = useRef();

  useDatagrid(dataGridRef);
  const { register, handleSubmit, watch } = useForm();

  const onClickRead = () => {
    if (!dataGridRef.current) return;

    const data = watch();

    const search = [];
    const { groupCode, groupName } = data;

    if (groupCode) search.push(["groupCode", "contains", groupCode]);
    if (groupName) {
      if (search.length !== 0 && search[search.length - 1] !== "and")
        search.push("and");
      search.push(["groupName", "contains", groupName]);
    }

    if (search[0]) {
      dataGridRef.current.instance.filter(search);
    } else {
      dataGridRef.current.instance.clearFilter();
    }

    dataGridRef.current.instance.cancelEditData();
    dataGridRef.current.instance.refresh();
  };

  const onClickAdd = () => {
    if (!dataGridRef.current) return;

    dataGridRef.current.instance.option("focusedRowIndex", -1);

    setTimeout(() => {
      setRowData(null);
    }, 100);
  };

  const onClickDelete = async () => {
    if (!dataGridRef.current || !rowData) return;
    // const result = await confirm("정말로 삭제 하시겠습니까?", "경고");
    // if (!result) return;

    dataGridRef.current.instance.deleteRow(rowData.rowIndex);
    dataGridRef.current.instance.option("focusedRowIndex", -1);
    setRowData(null);
  };

  const onFocusedRowChanged = (e) => {
    const data = e.row;
    if (!data) return;
    if (!rowData || rowData.rowIndex !== data.rowIndex) setRowData(data);

    dataGridDetailRef.current.instance.cancelEditData();
  };

  return (
    <>
      <PanelInit>
        <PanelHeader>
          <HeaderTitle />
          <PanelBtn>
            <FunctionButton
              onClickAdd={onClickAdd}
              onClickDelete={onClickDelete}
            />
          </PanelBtn>
        </PanelHeader>

        <PanelBody row left="50%" right="50%">
          <ComCodeDetailList
            rowData={rowData}
            dataGridRef={dataGridRef}
            dataGridDetailRef={dataGridDetailRef}
          />
          <PanelGridWrapper>
            <PanelGridSearchBar onSubmit={handleSubmit(onClickRead)}>
              <SearchItems>
                <label>그룹코드</label>
                <input type="text" {...register("groupCode")} />
              </SearchItems>
              <SearchItems>
                <label>그룹코드명</label>
                <input type="text" {...register("groupName")} />
              </SearchItems>
              <CustomButton
                type="submit"
                height={24}
                layout="solid"
                color="search"
                label="검색"
              />
            </PanelGridSearchBar>

            {/* 페이지 메인그리드 */}
            <PanelGridInit>
              <ComCodeMasterList
                onFocusedRowChanged={onFocusedRowChanged}
                dataGridRef={dataGridRef}
              />
            </PanelGridInit>
          </PanelGridWrapper>
        </PanelBody>
      </PanelInit>
    </>
  );
}
