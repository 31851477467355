import { DataGrid } from "devextreme-react";
import {
  Column,
  ColumnChooser,
  FilterRow,
  HeaderFilter,
  Scrolling,
} from "devextreme-react/data-grid";
import React, { useMemo, useState } from "react";

import CustomStore from "devextreme/data/custom_store";
import { useForm } from "react-hook-form";
import { sendRequest } from "../../../../_metronic/_helpers/DataSoureHelpers";
import {
  DataCount,
  PanelGridInit,
  PanelGridSearchBar,
  PanelGridWrapper,
  SearchItems,
} from "../../../components/Layout/Panel/PanelGrid/panelGrid.style";
import CustomButton from "../../../components/FunctionButton/CustomButton";

function DepartmentMaster({ onFocusedRowChanged, dataGridRef }) {
  const [totalCount, setTotalCount] = useState(0);
  const [search, setSearch] = useState({});
  const { register, handleSubmit } = useForm();

  const dataSource = useMemo(() => {
    const apiUrl = `${process.env.REACT_APP_API_URL}/department`;

    return new CustomStore({
      key: "oid",
      load: () => sendRequest(apiUrl, "GET", search),
    });
  }, [search]);

  const onSubmit = (data) => {
    for (const obj in data) {
      if (data[obj] === "") {
        data[obj] = null;
      }
    }
    setSearch(data);
  };

  return (
    <>
      <PanelGridWrapper>
        <PanelGridSearchBar onSubmit={handleSubmit(onSubmit)}>
          <SearchItems>
            <label>부서명</label>
            <input type="text" {...register("deptName")} />
          </SearchItems>
          <CustomButton
            type="submit"
            height={24}
            layout="solid"
            color="search"
            label="검색"
          />
        </PanelGridSearchBar>
        <PanelGridInit>
          <DataCount>{`총 ${totalCount}건`}</DataCount>
          <DataGrid
            id="department"
            className="datagrid-wrap"
            dataSource={dataSource}
            ref={dataGridRef}
            showRowLines={true}
            showBorders={true}
            hoverStateEnabled={true}
            allowColumnResizing={true}
            columnResizingMode="widget"
            keyExpr="oid"
            focusedRowEnabled={true}
            onFocusedRowChanged={onFocusedRowChanged}
            onContentReady={(e) => setTotalCount(e.component.totalCount())}
          >
            <FilterRow visible={true} />
            <HeaderFilter visible={true} />
            <ColumnChooser enabled={true} />
            <Scrolling mode="virtual" />
            <Column dataField="code" caption="부서코드" alignment="center" />
            <Column dataField="deptName" caption="부서명" alignment="center" />
            <Column dataField="sortRef" caption="정렬순서" alignment="center" />
            <Column dataField="isDispatch" caption="배차팀 여부" />
            <Column dataField="remark" caption="비고" alignment="center" />
          </DataGrid>
        </PanelGridInit>
      </PanelGridWrapper>
    </>
  );
}

export default DepartmentMaster;
