import dayjs from "dayjs";
import { useEffect, useReducer, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { sendRequest } from "../../../../_metronic/_helpers/DataSoureHelpers";
import { getLookup } from "./CalculateProfitCrud";

const initialSearch = {
  receiveCo: "0",
  baseMonth: dayjs().format("YYYY-MM"),
};

const initialInput = {};

const initialState = {
  search: initialSearch,

  lookupData: {
    company: [],
  },

  upperDataSource: [],
  lowerDataSource: [],
};

const reducer = (state, action) => {
  switch (action.type) {
    case "SET_DATASOURCE":
      return {
        ...state,
        upperDataSource: action.payload.upperDataSource,
        lowerDataSource: action.payload.lowerDataSource,
      };
    case "SET_SEARCH":
      return {
        ...state,
        search: action.payload,
      };
    case "SET_LOOKUP_DATA":
      return {
        ...state,
        lookupData: action.payload,
      };
    default:
      throw new Error("Unhandled action");
  }
};

const useCalculateProfit = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [state, dispatch] = useReducer(reducer, initialState);
  const { search } = state;
  const upperGridRef = useRef();
  const lowerGridRef = useRef();

  const inputForm = useForm({ defaultValues: initialInput });
  const { reset: inputReset } = inputForm;
  const searchForm = useForm({
    defaultValues: initialSearch,
  });

  useEffect(() => {
    _initLookupData();
  }, []);

  useEffect(() => {
    _getGridData(search);
    // eslint-disable-next-line
  }, [search]);

  // private
  const _initLookupData = async () => {
    try {
      const companyData = await getLookup("company");

      dispatch({
        type: "SET_LOOKUP_DATA",
        payload: {
          company: [
            {
              id: "0",
              companyName: "전체",
            },
            ...companyData.data,
          ],
        },
      });
    } catch (error) {}
  };

  const _getGridData = async (search) => {
    try {
      setIsLoading(true);
      const apiUrl = `${process.env.REACT_APP_API_URL}/calculate-profit`;
      const { calculate, etc } = await sendRequest(apiUrl, "GET", search);

      _calculateNetProfit(calculate, etc);

      dispatch({
        type: "SET_DATASOURCE",
        payload: {
          upperDataSource: calculate || [],
          lowerDataSource: etc || [],
        },
      });
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const _calculateNetProfit = (calculate = [], etc = []) => {
    let profit = 0; // 매출액
    let purchase = 0; // 매입액
    let income = 0; // 이익금
    let etcProfit = 0; // 기타매출
    let etcPurchase = 0; // 기타매입
    let netProfit = 0; // 순수익

    calculate.forEach((cur) => {
      const { totalPriceBilling, totalPriceOther } = cur;

      profit += Number(totalPriceBilling);
      purchase += Number(totalPriceOther);
    });

    etc.forEach((cur) => {
      const { sales, purchase } = cur;

      etcProfit += Number(sales);
      etcPurchase += Number(purchase);
    });

    income = profit - purchase;
    netProfit = income + etcProfit - etcPurchase;

    inputReset({
      profit,
      purchase,
      income,
      etcProfit,
      etcPurchase,
      netProfit,
    });
  };

  /*
    search Function
   */

  const onSubmitSearch = (data) => {
    dispatch({
      type: "SET_SEARCH",
      payload: data,
    });
  };

  const pageFunctions = {};
  const inputFunctions = {};
  const searchFunctions = {
    onSubmit: onSubmitSearch,
  };
  const masterFunctions = {};

  return {
    state,
    isLoading,

    upperGridRef,
    lowerGridRef,

    inputForm,
    searchForm,

    pageFunctions,
    inputFunctions,
    searchFunctions,
    masterFunctions,
  };
};

export default useCalculateProfit;
