import * as requestFromServer from "./menuCrud";
import { menuSlice, callTypes } from "./menuSlice";

const { actions } = menuSlice;

export const fetchMenu = () => async (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  try {
    const response = await requestFromServer.findMenu();
    const menuAll = response.data;
    // const menuAll = response.data.filter((obj) => obj.menu.menuType === "2");
    // cons
    dispatch(actions.menuFetched({ menuAll }));
  } catch (error) {
    error.clientMessage = "Can't find menu";
    dispatch(actions.catchError({ error, callType: callTypes.list }));
  }
};

export const setSelectedItem = (item) => async (dispatch) => {
  dispatch(actions.selectedItem({ item }));
};

export const openItem = (item) => async (dispatch) => {
  dispatch(actions.openItem({ item }));
};

export const logout = (item) => async (dispatch) => {
  dispatch(actions.logoutMenu());
};
