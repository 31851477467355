import { yupResolver } from "@hookform/resolvers/yup";
import { alert, confirm } from "devextreme/ui/dialog";
import React, {
  useCallback,
  useEffect,
  useReducer,
  useRef,
  useState,
} from "react";
import { useForm } from "react-hook-form";
import { sendRequest } from "../../../../_metronic/_helpers/DataSoureHelpers";
import { fetchLookupData } from "../CarDeadline/CarDeadlineCrud";
import { getDriverFee } from "./CarClosingCrud";
import {
  getInitialInput,
  initialDataSource,
  initialSearch,
  initialState,
  schema,
} from "./CarDeadlineData";
import { shallowEqual, useSelector } from "react-redux";

const apiUrl = `${process.env.REACT_APP_API_URL}/car-deadline`;
const carClosing = `${process.env.REACT_APP_API_URL}/car-closing`;

const carDeadlineReducer = (state, action) => {
  switch (action.type) {
    case "SET_SEARCH":
      return {
        ...state,
        search: action.payload,
      };

    case "SET_LOOKUP_DATA":
      return {
        ...state,
        lookupData: action.payload,
      };

    case "SET_DATA_SOURCE":
      return {
        ...state,
        dataSource: action.payload,
      };

    case "SET_ROW_DATA":
      return {
        ...state,
        rowData: action.payload,
      };

    case "SET_INPUT_DATA_SOURCE":
      return {
        ...state,
        detailDataSource: action.payload,
      };

    case "SET_INPUT_GRID_DATA":
      return {
        ...state,
        detailDataSource: action.payload.detailDataSource,
        detailEtcDataSource: action.payload.detailEtcDataSource,
      };

    case "SET_IS_LOADING":
      return {
        ...state,
        isLoading: action.payload,
      };

    case "SET_IS_UPDATE":
      return {
        ...state,
        isUpdate: action.payload,
      };
    default:
      throw new Error("Unhandled action");
  }
};

const useCarDeadline = (carClosingUpperRef) => {
  const [state, dispatch] = useReducer(carDeadlineReducer, initialState);

  const { user } = useSelector(
    (state) => ({ user: state.auth.user.user }),
    shallowEqual
  );

  const initialInput = getInitialInput(user);

  const { search, rowData, detailEtcDataSource, isLoading } = state;

  const dataGridRef = useRef();
  const inputGridRef = useRef();
  const inputEtcGridRef = useRef();

  const inputForm = useForm({
    resolver: yupResolver(schema),
    defaultValues: initialInput,
  });
  const searchForm = useForm({
    defaultValues: initialSearch,
  });

  const { watch: InputWatch, reset: InputReset } = inputForm;

  useEffect(() => {
    _initLookupData();
  }, []);

  useEffect(() => {
    _getDataSource(search);
  }, [search]);

  useEffect(() => {
    _getInputData(rowData);
    // eslint-disable-next-line
  }, [rowData]);

  // input reset, inputGrid changes 초기화, inputEtcGrid changes 초기화
  const _resetInputAndGrid = (type) => {
    const dataGridInstance = carClosingUpperRef.current.instance;
    const inputGridInstance = inputGridRef.current.instance;
    const inputEtcGridInstance = inputEtcGridRef.current.instance;

    if (type !== "focus") dataGridInstance.option("focusedRowIndex", -1);
    inputGridInstance.option("editing.changes", []);
    inputEtcGridInstance.option("editing.changes", []);
    InputReset(initialInput);

    dispatch({
      type: "SET_IS_UPDATE",
      payload: false,
    });
    dispatch({
      type: "SET_ROW_DATA",
      payload: {},
    });
    dispatch({
      type: "SET_INPUT_GRID_DATA",
      payload: {
        detailDataSource: initialDataSource,
        detailEtcDataSource: [],
      },
    });
  };

  const _initLookupData = async () => {
    const lookupData = await fetchLookupData();

    dispatch({
      type: "SET_LOOKUP_DATA",
      payload: lookupData,
    });
  };

  const _getDataSource = async (search) => {
    try {
      dispatch({
        type: "SET_IS_LOADING",
        payload: true,
      });
      const data = await sendRequest(apiUrl, "GET", search);

      dispatch({
        type: "SET_DATA_SOURCE",
        payload: data,
      });
    } catch (error) {
      console.log(error);
    } finally {
      dispatch({
        type: "SET_IS_LOADING",
        payload: false,
      });
    }
  };

  const _getInputData = async (rowData) => {
    if (rowData && Object.keys(rowData).length === 0) {
      // input 리셋하기
      InputReset(initialInput);
      return;
    }

    try {
      dispatch({
        type: "SET_IS_LOADING",
        payload: true,
      });

      const result = await sendRequest(
        carClosing + "/deadline",
        "GET",
        rowData
      );

      const { data, inputGridData, inputEtcData } = result;

      // input에 data뿌려주기
      InputReset(data[0]);

      dispatch({
        type: "SET_INPUT_GRID_DATA",
        payload: {
          detailDataSource: inputGridData,
          detailEtcDataSource: inputEtcData,
        },
      });
      // 수정하지 못하도록 차량번호, 하불마감일자 disable true
      dispatch({
        type: "SET_IS_UPDATE",
        payload: true,
      });
    } catch (error) {
      console.log(error);
    } finally {
      dispatch({
        type: "SET_IS_LOADING",
        payload: false,
      });
    }
  };

  const _sendCreateUpdateRequest = async (
    Oid,
    data,
    inputGridData,
    inputEtcGridData,
    inputGridChanges,
    inputEtcGridChanges
  ) => {
    if (!Oid) {
      // create
      return await sendRequest(carClosing + "/deadline", "POST", {
        data,
        inputGridData,
        inputEtcGridData,
      });
    } else {
      // update
      return await sendRequest(carClosing + "/deadline", "PUT", {
        data,
        inputGridChanges,
        inputEtcGridChanges,
      });
    }
  };

  const _calculateEtcTotalCost = (value, rows, detailEtcDataSource) => {
    if (value.length === 0) {
      return detailEtcDataSource.reduce(
        (acc, cur) => acc + (cur.money || 0),
        0
      );
    }
    return rows.reduce((acc, cur) => acc + (cur.data?.money || 0), 0);
  };

  const _setMoney08InputValue = (value, detailEtcDataSource) => {
    const InputData = InputWatch();
    const { money03, money06, money07 } = InputData;

    const instance = inputEtcGridRef.current.instance;
    const rows = instance.getVisibleRows();
    let etcTotalCost = _calculateEtcTotalCost(value, rows, detailEtcDataSource);

    InputReset({
      ...InputData,
      money08: Number(etcTotalCost),
      money09: Number(money06) + Number(money07),
      money10: money03 - (Number(money06) + Number(money07)),
    });
  };

  /** 상세정보의 수수료 배열을 return 하는 함수 */
  const _calculateCommission = (rows) => {
    return rows
      .filter((cur) => {
        const { data } = cur;
        return data.chargeCode === "수수료" && data.money;
      })
      .map((cur) => cur.data);
  };

  /** 상세정보의 기타공제의 배열을 return 하는 함수 */
  const _calculateEtcDeduction = (rows) => {
    return rows
      .filter((cur) => {
        const { data } = cur;
        return data.chargeCode === "기타공제" && data.money;
      })
      .map((cur) => cur.data);
  };

  /** 수수료, 기타공제의 합계를 통해 Input에 맵핑하는 함수 */
  const _setInputFieldValues = (commission, etcDeduction, type) => {
    const InputData = InputWatch();
    const {
      money03,
      // money08
    } = InputData;

    const 기타공제_공급가액 = commission.reduce((acc, cur) => {
      return acc + cur.money;
    }, 0);
    const 기타공제_기타공제합계 = etcDeduction.reduce((acc, cur) => {
      return acc + cur.money;
    }, 0);

    InputReset({
      ...InputData,
      money04: 기타공제_공급가액,
      money05: Math.round(기타공제_공급가액 * 0.1),
      money06: Math.round(기타공제_공급가액 * 1.1),
      money07: 기타공제_기타공제합계,
      money09: Math.round(기타공제_공급가액 * 1.1) + 기타공제_기타공제합계,
      money10:
        money03 - (Math.round(기타공제_공급가액 * 1.1) + 기타공제_기타공제합계),
    });
  };

  const pageFunctions = {
    onClickAdd: () => {
      _resetInputAndGrid();
      dispatch({
        type: "SET_INPUT_DATA_SOURCE",
        payload: initialDataSource,
      });
    },

    onClickDelete: async () => {
      const masterInstance = dataGridRef.current.instance;

      const Oid = masterInstance.option("focusedRowKey");

      if (!Oid) {
        await alert("데이터를 선택해주세요.", "오류");
        return;
      }

      const res = await confirm("데이터를 삭제하시겠습니까?", "확인");
      if (!res) return;

      try {
        dispatch({
          type: "SET_IS_LOADING",
          payload: true,
        });

        const result = await sendRequest(apiUrl, "DELETE", { Oid });
        const { status, message } = result;

        await alert(message, "확인");
        // masterGrid 새로고침
        await _getDataSource(search);
        if (status === 1) {
          await masterInstance.option("focusedRowIndex", -1);
          _resetInputAndGrid();
        }
      } catch (error) {
        console.log(error);
      } finally {
        dispatch({
          type: "SET_IS_LOADING",
          payload: false,
        });
      }
    },
  };

  const inputFunctions = {
    onSubmit: async (data) => {
      const { oid } = data;
      const carClosingUpperRefInstance = carClosingUpperRef.current.instance;
      const inputGridInstance = inputGridRef.current.instance;
      const inputEtcGridInstance = inputEtcGridRef.current.instance;
      const inputGridData = inputGridInstance
        .getVisibleRows()
        .map((cur) => cur.data);
      const inputEtcGridData = inputEtcGridInstance
        .getVisibleRows()
        .map((cur) => cur.data);
      const inputGridChanges = inputGridInstance.option("editing.changes");
      const inputEtcGridChanges = inputEtcGridInstance.option(
        "editing.changes"
      );

      try {
        dispatch({
          type: "SET_IS_LOADING",
          payload: true,
        });

        const result = await _sendCreateUpdateRequest(
          oid,
          data,
          inputGridData,
          inputEtcGridData,
          inputGridChanges,
          inputEtcGridChanges
        );

        const { status, completeNo } = result;
        // input 전부 reset
        // grid refresh 코드로 재조회
        carClosingUpperRefInstance.refresh();
        _resetInputAndGrid();
        if (status === 1) {
          console.log(completeNo);
          carClosingUpperRefInstance.option("focusedRowKey", completeNo);
        }
      } catch (error) {
        console.log(error);
      } finally {
        dispatch({
          type: "SET_IS_LOADING",
          payload: false,
        });
      }
    },

    /** 차량번호 선택했을 경우 실행 */
    onComCarOidValueChanged: async (e) => {
      if (isLoading) return;
      const instance = inputGridRef.current.instance;
      const rows = instance.getVisibleRows();

      const { value } = e;
      const inputData = InputWatch();

      const { receiveCo, carDeadlineDate, money09 } = inputData;

      // api에서 차량번호 데이터 fetch
      const result = await getDriverFee({
        receiveCo,
        carDeadlineDate,
        oid: value,
      });
      const { data } = result;

      const { comCarData, money01, money02 } = data;
      const { bizName, ceoName, carDivision, carCommission } = comCarData;

      // input에 데이터 바인딩
      InputReset({
        ...inputData,
        bizName: bizName,
        ceoName: ceoName || null,
        carDivision: carDivision || null,
        carCommission: carCommission || 0,
        money01: money01 || 0,
        money02: money02 || 0,
        money03: money01 + money02 || 0,

        money10: money01 + money02 - money09,
      });

      // 수수료 * 운송료 공급가액
      const commissionTimesFreight =
        (money01 * Number(carCommission || 0)) / 100;

      // grid에 추가
      await instance.cellValue(0, "money", commissionTimesFreight);

      const commission = _calculateCommission(rows);
      const etcDeduction = _calculateEtcDeduction(rows);

      _setInputFieldValues(commission, etcDeduction, "onComCarOidValueChanged");
    },

    // grid

    onInitNewRow: (e) => {
      e.data.chargeCode = "기타";
    },

    onEditingStart: (e) => {
      if (e.column.caption !== "항목명") return;

      const checkArr = initialDataSource.filter(
        (cur) =>
          cur.chargeCode === e.data.chargeCode && cur.title === e.data.title
      );

      if (checkArr.length !== 0) {
        e.cancel = true;
      }
    },

    onGridToolbarPreparing: (e) => {
      e.toolbarOptions.visible = false;
    },

    onEtcToolbarPreparing: (e) => {
      var toolbarItems = e.toolbarOptions.items;

      toolbarItems.forEach((cur) => {
        if (cur.name === "saveButton") {
          cur.visible = false;
        }

        if (cur.name === "revertButton") {
          cur.options.onClick = () => {
            const instance = inputEtcGridRef.current.instance;
            instance.option("editing.changes", []);
          };
        }
      });
    },

    cellRender: useCallback(
      (e) => {
        if (["수수료", "기타공제"].includes(e.data.chargeCode)) {
          return "";
        }

        const {
          row: { removed },
        } = e;
        const instance = inputEtcGridRef.current.instance;
        const rowIndex = instance.getRowIndexByKey(e.key);

        const handleDelete = () => {
          instance.deleteRow(rowIndex);
        };

        const handleUndelete = () => {
          instance.undeleteRow(rowIndex);
        };

        if (removed) {
          return (
            <button
              type="button"
              className="btn btn-grid btn-grid-change pw-change"
              onClick={handleUndelete}
            >
              삭제취소
            </button>
          );
        } else {
          return (
            <button
              type="button"
              className="btn btn-grid btn-grid-change pw-change"
              onClick={handleDelete}
            >
              삭제
            </button>
          );
        }
      },
      [inputEtcGridRef]
    ),

    onGridOptionChanged: useCallback((e) => {
      const { fullName, value } = e;
      if (fullName !== "editing.changes" || !Array.isArray(value)) {
        return;
      }

      const instance = inputGridRef.current.instance;
      const rows = instance.getVisibleRows();

      // 여기서 수수료 계산

      const commission = _calculateCommission(rows);
      const etcDeduction = _calculateEtcDeduction(rows);

      _setInputFieldValues(commission, etcDeduction, "onGridOptionChanged");
      // eslint-disable-next-line
    }, []),

    onEtcGridOptionChanged: useCallback(
      (e) => {
        const { fullName, value } = e;
        if (fullName !== "editing.changes" || !Array.isArray(value)) {
          return;
        }

        _setMoney08InputValue(value, detailEtcDataSource);
      },
      // eslint-disable-next-line
      [detailEtcDataSource]
    ),

    /** 관리비 계산 */
    calculateMaintenanceCharge: () => {
      const carAmount = Number(rowData.money01);
      const carCommission = Number(rowData.carCommission);

      if (isNaN(carAmount) || isNaN(carCommission)) {
        alert("공급가액 또는 수수료의 값이 없습니다.");
        return;
      }

      // 관리비
      const maintenanceCharge = rowData.money01 * (rowData.carCommission / 100);

      const updatedDetailDataSource = [...state.detailDataSource];

      const maintenanceChargeIndex = updatedDetailDataSource.findIndex(
        (item) => item.title === "관리비"
      );

      if (maintenanceChargeIndex === -1) {
        alert("관리비 항목이 없습니다.");
        return;
      }

      updatedDetailDataSource[maintenanceChargeIndex] = {
        ...updatedDetailDataSource[maintenanceChargeIndex],
        money: maintenanceCharge,
      };

      dispatch({
        type: "SET_INPUT_DATA_SOURCE",
        payload: updatedDetailDataSource,
      });
    },
  };

  const [selectedRow, setSelectedRow] = useState({});

  const masterFunctions = {
    onSubmit: (data) => {
      _resetInputAndGrid();

      dispatch({
        type: "SET_SEARCH",
        payload: data,
      });
    },

    onFocusedRowChanged: useCallback((e) => {
      const { row } = e;
      if (!row) return;
      // _resetInputAndGrid("focus");

      setSelectedRow(row.data);
      // 행 데이터를 액션으로 전달하여 상태를 업데이트함
      dispatch({ type: "SET_ROW_DATA", payload: row.data });
      // eslint-disable-next-line
    }, []),
  };

  return {
    state,

    dataGridRef,
    inputGridRef,
    inputEtcGridRef,
    selectedRow,
    setSelectedRow,

    inputForm,
    searchForm,

    pageFunctions,
    inputFunctions,
    masterFunctions,
  };
};

export default useCarDeadline;
