import { yupResolver } from "@hookform/resolvers/yup";
import { DataGrid } from "devextreme-react";
import {
  Column,
  Editing,
  Lookup,
  Paging,
  Scrolling,
} from "devextreme-react/data-grid";
import { confirm } from "devextreme/ui/dialog";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { InputText } from "../../../../components/InputForm";
import ModalContainer from "../../../../components/Modal/ModalContainer";
import ModalHeader from "../../../../components/Modal/ModalHeader";
import {
  createTemplete,
  deleteTemplete,
  getTempleteSubByOid,
  updateTemplete,
} from "../ClosingCrud";

const EditTempleteModal = ({
  onClose,
  maskClosable,
  closable,
  visible,
  templeteData,
  templeteOid,
  onFinishEditTemplete,
}) => {
  const [dataSource, setDataSource] = useState([
    {
      oid: "34",
      useYn: false,
      columnName: "",
      printName: "No",
      width: 30,
      sortText: "가운데",
    },
    {
      oid: "1",
      useYn: false,
      columnName: "deliverDate",
      printName: "운송일자",
      width: 70,
      sortText: "가운데",
    },
    {
      oid: "2",
      useYn: false,
      columnName: "price",
      printName: "공급가액",
      width: 80,
      sortText: "우측",
    },
    {
      oid: "3",
      useYn: false,
      columnName: "containerNo",
      printName: "컨테이너NO",
      width: 80,
      sortText: "가운데",
    },
    {
      oid: "4",
      useYn: false,
      columnName: "containerType",
      printName: "컨규격",
      width: 80,
      sortText: "가운데",
    },
    {
      oid: "420",
      useYn: false,
      columnName: "sealNo",
      printName: "씰넘버",
      width: 80,
      sortText: "가운데",
    },
    {
      oid: "5",
      useYn: false,
      columnName: "remark",
      printName: "비고",
      width: 100,
      sortText: "좌측",
    },
    {
      oid: "6",
      useYn: false,
      columnName: "dispatchTeamName",
      printName: "배차팀",
      width: 100,
      sortText: "좌측",
    },
    {
      oid: "7",
      useYn: false,
      columnName: "billingPlaceName",
      printName: "청구처",
      width: 100,
      sortText: "좌측",
    },
    {
      oid: "8",
      useYn: false,
      columnName: "orderCompany",
      printName: "발주처",
      width: 100,
      sortText: "좌측",
    },
    {
      oid: "9",
      useYn: false,
      columnName: "forwarder",
      printName: "선사",
      width: 100,
      sortText: "좌측",
    },
    {
      oid: "10",
      useYn: false,
      columnName: "shipName",
      printName: "선명",
      width: 100,
      sortText: "좌측",
    },
    {
      oid: "11",
      useYn: false,
      columnName: "motherShip",
      printName: "모선항차",
      width: 100,
      sortText: "좌측",
    },
    {
      oid: "12",
      useYn: false,
      columnName: "cargoOwnerName",
      printName: "화주",
      width: 100,
      sortText: "좌측",
    },
    {
      oid: "13",
      useYn: false,
      columnName: "dem",
      printName: "선사반출기한",
      width: 70,
      sortText: "가운데",
    },
    {
      oid: "14",
      useYn: false,
      columnName: "enterDay",
      printName: "입항일",
      width: 70,
      sortText: "가운데",
    },
    {
      oid: "15",
      useYn: false,
      columnName: "sailDay",
      printName: "출항일",
      width: 70,
      sortText: "가운데",
    },
    {
      oid: "16",
      useYn: false,
      columnName: "putDirect",
      printName: "접안일",
      width: 70,
      sortText: "가운데",
    },
    {
      oid: "17",
      useYn: false,
      columnName: "loadPlaceName",
      printName: "상차지",
      width: 100,
      sortText: "좌측",
    },
    {
      oid: "18",
      useYn: false,
      columnName: "unloadPlaceName",
      printName: "하차지",
      width: 100,
      sortText: "좌측",
    },
    {
      oid: "19",
      useYn: false,
      columnName: "blNo",
      printName: "BL NO",
      width: 70,
      sortText: "가운데",
    },
    {
      oid: "20",
      useYn: false,
      columnName: "bkNo",
      printName: "BK NO",
      width: 70,
      sortText: "가운데",
    },
    {
      oid: "21",
      useYn: false,
      columnName: "doNo",
      printName: "DO NO",
      width: 70,
      sortText: "가운데",
    },
    {
      oid: "22",
      useYn: false,
      columnName: "blNoM",
      printName: "마스터B/L",
      width: 100,
      sortText: "가운데",
    },
    {
      oid: "23",
      useYn: false,
      columnName: "blNoH",
      printName: "하우스B/L",
      width: 100,
      sortText: "가운데",
    },
    {
      oid: "24",
      useYn: false,
      columnName: "carNo",
      printName: "차량번호",
      width: 100,
      sortText: "가운데",
    },
    {
      oid: "25",
      useYn: false,
      columnName: "tax",
      printName: "부가세",
      width: 80,
      sortText: "우측",
    },
    {
      oid: "26",
      useYn: false,
      columnName: "totalPrice",
      printName: "합계금액",
      width: 80,
      sortText: "우측",
    },
    {
      oid: "27",
      useYn: false,
      columnName: "amount",
      printName: "수량",
      width: 50,
      sortText: "가운데",
    },
    {
      oid: "28",
      useYn: false,
      columnName: "importExportName",
      printName: "수입/수출",
      width: 50,
      sortText: "가운데",
    },
    {
      oid: "29",
      useYn: false,
      columnName: "extraRemark",
      printName: "청구비고",
      width: 100,
      sortText: "가운데",
    },
    {
      oid: "30",
      useYn: false,
      columnName: "etcHabulRemark",
      printName: "하불비고",
      width: 100,
      sortText: "가운데",
    },
    {
      oid: "31",
      useYn: false,
      columnName: "manager",
      printName: "청구담당자",
      width: 100,
      sortText: "가운데",
    },
    {
      oid: "32",
      useYn: false,
      columnName: "extraInfo",
      printName: "특이사항",
      width: 100,
      sortText: "가운데",
    },
    {
      oid: "33",
      useYn: false,
      columnName: "cargoWeight",
      printName: "중량",
      width: 100,
      sortText: "우측",
    },
    {
      oid: "350",
      useYn: false,
      columnName: "realCargoOwner",
      printName: "실화주",
      width: 100,
      sortText: "우측",
    },
    {
      oid: "360",
      useYn: false,
      columnName: "workPlace",
      printName: "작업지",
      width: 100,
      sortText: "우측",
    },
    {
      oid: "370",
      useYn: false,
      columnName: "workPlaceAdd",
      printName: "작업지주소",
      width: 100,
      sortText: "우측",
    },
    {
      oid: "380",
      useYn: false,
      columnName: "extraChargeDivision",
      printName: "청구구분",
      width: 100,
      sortText: "우측",
    },
    {
      oid: "390",
      useYn: false,
      columnName: "deliverCompleteDate",
      printName: "운송마감일자",
      width: 100,
      sortText: "우측",
    },
    {
      oid: "400",
      useYn: false,
      columnName: "loadPlaceText",
      printName: "LCL 상차지",
      width: 100,
      sortText: "가운데",
    },
    {
      oid: "410",
      useYn: false,
      columnName: "unloadPlaceText",
      printName: "LCL 하차지",
      width: 100,
      sortText: "가운데",
    },
    {
      oid: "420",
      useYn: false,
      columnName: "innerMemo",
      printName: "내부 메모",
      width: 100,
      sortText: "가운데",
    },
  ]);

  const gridRef = useRef();
  const schema = yup.object({
    title: yup.string().required("거래명세서 양식명을 입력해주세요"),
  });
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    const filteredTempleteData = templeteData.filter(
      (cur) => cur.oid === templeteOid
    );
    reset({ oid: templeteOid, title: filteredTempleteData[0]?.title });
    templeteOid && getTempleteSubData(templeteOid);
    // eslint-disable-next-line
  }, [templeteOid, templeteData]);

  const onSubmit = async (data) => {
    const gridInstance = gridRef.current.instance;

    try {
      if (templeteOid) {
        // update

        await updateTemplete({
          data: data,
          rows: gridInstance.getVisibleRows().map((cur) => cur.data),
        });
      } else {
        // create

        const rows = gridInstance.getVisibleRows().map((cur) => cur.data);

        await createTemplete({
          data: data,
          rows: rows,
        });
      }

      onFinishEditTemplete();
    } catch (error) {
      console.log(error);
    }
  };

  const onClickDeleteTemplete = async () => {
    const result = await confirm("삭제하시겠습니까?", "확인");
    if (!result) return;

    await deleteTemplete(templeteOid);

    // delete되면 이전 모달에서 거래명세서양식 refetch
    onFinishEditTemplete();
  };

  const onEditingStart = useCallback((e) => {
    if (e.column.caption === "컬럼명" || e.column.caption === "출력명") {
      e.cancel = true;
    }
  }, []);

  const onToolbarPreparing = (e) => {
    e.toolbarOptions.items.forEach((item) => {
      item.visible = false;
    });
  };

  async function getTempleteSubData(templeteOid) {
    const list = await getTempleteSubByOid(templeteOid);
    const result = dataSource.map((item) => {
      const matchingItem = list.data.find(
        (templateItem) => templateItem.columnName === item.columnName
      );

      item.sortNo = matchingItem?.sortNo;
      item.width = matchingItem?.width || item.width;
      item.sortText = matchingItem?.sortText || item.sortText;
      item.oid = matchingItem?.oid || item.oid;
      item.useYn = matchingItem?.useYn === "1" ? true : false;
      return item;
    });
    setDataSource(result);
  }

  return (
    <>
      <ModalContainer
        onClose={onClose}
        maskClosable={maskClosable}
        visible={visible}
        size="lg"
      >
        <form className="modal-form" onSubmit={handleSubmit(onSubmit)}>
          <ModalHeader
            title={`거래명세서 양식 ${templeteOid ? "수정" : "신규등록"}`}
            onClose={onClose}
          />
          <div className="input-wrap">
            <div className="input-grid grid-1">
              <div className="dx-field">
                <InputText
                  control={control}
                  errors={errors}
                  name="title"
                  label="양식명"
                  flex={true}
                />

                <button type="submit" className="btn btn-grid btn-grid-save">
                  저장
                </button>
                {templeteOid && (
                  <button
                    type="button"
                    className="btn btn-grid btn-grid-delete"
                    onClick={onClickDeleteTemplete}
                  >
                    삭제
                  </button>
                )}
              </div>
            </div>
          </div>

          <DataGrid
            className="datagrid-wrap toolbar-hidden"
            dataSource={dataSource}
            ref={gridRef}
            showRowLines={true}
            showBorders={true}
            hoverStateEnabled={true}
            columnResizingMode="widget"
            keyExpr="oid"
            onEditingStart={onEditingStart}
            onToolbarPreparing={onToolbarPreparing}
            height={600}
          >
            <Scrolling showScrollbar="always" useNative="false" />
            <Paging enabled={true} pageSize={1000} />
            <Editing mode="batch" allowUpdating={true} />
            <Column dataField="useYn" caption="사용체크" dataType="boolean" />
            <Column dataField="sortNo" caption="순서" />
            <Column dataField="columnName" caption="컬럼명" visible={false} />
            <Column dataField="printName" caption="컬럼명" />
            <Column dataField="printName" caption="출력명" />
            <Column dataField="width" caption="넓이" />
            <Column dataField="sortText" caption="정렬">
              <Lookup
                dataSource={[
                  { name: "좌측" },
                  { name: "가운데" },
                  { name: "우측" },
                ]}
                displayExpr="name"
                valueExpr="name"
              />
            </Column>
          </DataGrid>
          <div className="modal-form-footer"></div>
        </form>
      </ModalContainer>
    </>
  );
};

export default EditTempleteModal;
