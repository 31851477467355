import { yupResolver } from "@hookform/resolvers/yup";
import { alert, confirm } from "devextreme/ui/dialog";
import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import * as requestServer from "./UserCrud";
import UserMaster from "./UserMaster";

import CustomButton from "../../../components/FunctionButton/CustomButton";
import { ButtonGroup } from "../../../components/FunctionButton/CustomButton/customButton.style";
import FunctionButton from "../../../components/FunctionButton/FunctionButton";
import InputLookup from "../../../components/InputForm/InputLookup";
import InputSelect from "../../../components/InputForm/InputSelect";
import InputTel from "../../../components/InputForm/InputTel";
import InputText from "../../../components/InputForm/InputText";
import InputTextArea from "../../../components/InputForm/InputTextArea";
import HeaderTitle from "../../../components/Layout/Header/HeaderTitle";
import {
  InputFullRow,
  PanelInput,
  PanelInputTitle,
  PanelInputWrapper,
} from "../../../components/Layout/Panel/PanelInput/panelInput.style";
import {
  PanelBody,
  PanelBtn,
  PanelHeader,
  PanelInit,
} from "../../../components/Layout/Panel/panel.style";
import { useDatagrid } from "../../../hooks/useDatagrid";

const userStat = {
  사용: "UA-100",
  휴면: 4324,
  미사용: 4323,
};

export default function UserPage() {
  const initialState = {
    userId: "",
    userName: "",
    password: "",
    passwordConfirm: "",
    userStatus: userStat.사용,
    companyCode: null,
    dutyCode: "",
    deptCode: "",
    authGroupId: "",
    driverId: "",
    remark: "",
    englishName: "",
    mail: "",
    hp: "",
    tel: "",
    fax: "",
  };
  const [selectedRowKey, setSelectedRowKey] = useState();
  const [lookupData, setLookupData] = useState({
    company: [],
    department: [],
    auth: [],
    duty: [],
    driver: [],
    userStatus: [],
  });

  const [isNewUser, setIsNewUser] = useState(true);

  const dataGridRef = useRef();

  useDatagrid(dataGridRef);

  const schema = yup.object({
    userId: yup
      .string()
      .required("아이디를 입력하세요")
      .max(20, "최대 20자리까지 입력가능"),
    password: yup
      .string()
      .min(3, "3자 이상 입력하세요")
      .max(50, "50자 미만으로 입력하세요")
      .required("비밀번호를 입력하세요"),
    passwordConfirm: yup.string().when("password", {
      is: (val) => (val && val.length > 0 ? true : false),
      then: yup
        .string()
        .oneOf([yup.ref("password")], "비밀번호가 일치하지 않습니다"),
    }),
    userName: yup
      .string()
      .max(20, "20자 미만으로 입력하세요")
      .required("사용자명을 입력하세요"),
    englishName: yup
      .string()
      .nullable()
      .max(20, "20자 미만으로 입력하세요"),
    mail: yup
      .string()
      .nullable()
      .max(50, "50자 미만으로 입력하세요"),
    hp: yup.string().nullable(),
    tel: yup.string().nullable(),
    fax: yup.string().nullable(),
    companyCode: yup.string().nullable(),
    deptCode: yup.string().nullable(),
    authGroupId: yup
      .string()
      .nullable()
      .required("권한을 선택하세요"),
    driverId: yup.string().nullable(),
    remark: yup
      .string()
      .nullable()
      .max(100, "최대 100자리까지 입력 가능"),
  });

  useEffect(() => {
    getLookup();
    reset(initialState);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    initInputData(selectedRowKey);
    // eslint-disable-next-line
  }, [selectedRowKey]);

  const {
    control,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data) => {
    delete data.id;
    delete data.authToken;
    delete data.refreshToken;
    delete data.createIP;
    delete data.createUserId;
    delete data.updateIP;
    delete data.updateUserId;
    delete data.createdAt;
    delete data.updatedAt;
    delete data.employeeId;
    delete data.isDev;
    delete data.isMonitor;
    delete data.isUse;

    try {
      let code;
      if (selectedRowKey) {
        await requestServer.updateUser(selectedRowKey, data);
      } else {
        const {
          data: { result, message },
        } = await requestServer.createUser(data);

        code = result?.id;
        if (!result && message) {
          alert(message, "오류");
        }
      }

      dataGridRef.current.instance.refresh();
      selectedRowKey && initInputData(selectedRowKey);
      code && dataGridRef.current.instance.option("focusedRowKey", code);
    } catch (err) {
      alert(err.response?.data?.message, "오류");
    }
  };

  const onClickAdd = () => {
    if (!dataGridRef.current) return;
    reset(initialState);
    setSelectedRowKey(null);
    dataGridRef.current.instance.option("focusedRowIndex", -1);
    setIsNewUser(true);
  };

  const onClickDelete = () => {
    if (!dataGridRef.current || !selectedRowKey) return;
    let response = confirm("<i>삭제하시겠습니까?</i>", "삭제 메세지");

    response.then((res) => {
      if (!res) return;
      requestServer
        .deleteRow(selectedRowKey)
        .then(() => {
          dataGridRef.current.instance.refresh();
        })
        .catch((err) => {
          alert(err.response.data.message, "오류");
        });
    });
  };

  const onFocusedRowChanged = (e) => {
    if (!e.row) return;
    setIsNewUser(false);
    setSelectedRowKey(e.row.key);
  };

  const initInputData = async (key) => {
    try {
      const { data } = await requestServer.getUserData(key);

      reset({
        ...data,
        remark: data.remark || undefined,
        englishName: data.englishName || undefined,
        mail: data.mail || undefined,
      });
    } catch (error) {
      console.log(error);
    }
  };

  async function getLookup() {
    try {
      const [
        authGroup,
        companyLookup,
        departmentLookup,
        dutyData,
        userStatusData,
        driverData,
      ] = await Promise.all([
        requestServer.getLookup("authgroup/lookup"),
        requestServer.getLookup("company"),
        requestServer.getLookup("department"),
        requestServer.getLookup("comcode/detail/11600"),
        requestServer.getLookup("comcode/detail/20600"),
        requestServer.getLookup("carManage/lookup"),
      ]);

      // console.log(
      //   authGroup.data,
      //   companyLookup.data,
      //   departmentLookup.data,
      //   dutyData.data,
      //   userStatusData.data,
      //   driverData.data
      // );

      setLookupData({
        company: companyLookup.data,
        department: departmentLookup.data,
        auth: authGroup.data,
        duty: dutyData.data,
        driver: driverData.data,
        userStatus: userStatusData.data,
      });
    } catch (err) {
      console.log(err);
    }
  }

  return (
    <>
      <PanelInit>
        <PanelHeader>
          <HeaderTitle />

          <PanelBtn>
            <FunctionButton
              onClickAdd={onClickAdd}
              onClickDelete={onClickDelete}
            />
          </PanelBtn>
        </PanelHeader>

        <PanelBody row left="400px" right="calc(100% - 400px)">
          <PanelInputWrapper
            onSubmit={handleSubmit(onSubmit, (err) => console.log(err))}
            openInputBar={true}
          >
            <PanelInputTitle>사용자 등록</PanelInputTitle>
            <PanelInput grid={2}>
              <InputText
                control={control}
                errors={errors}
                name="userId"
                label="사용자ID"
                required={true}
              />
              {isNewUser && (
                <>
                  <InputText
                    control={control}
                    errors={errors}
                    name="password"
                    label="비밀번호"
                    required={true}
                  />
                  <InputText
                    control={control}
                    errors={errors}
                    name="passwordConfirm"
                    label="비밀번호 확인"
                    required={true}
                  />
                </>
              )}
              <InputSelect
                control={control}
                errors={errors}
                name="userStatus"
                label="사용상태"
                dataSource={lookupData.userStatus}
                displayExpr="subName"
                valueExpr="subCode"
                required={true}
              />
              <InputText
                control={control}
                errors={errors}
                name="userName"
                label="사용자명"
                required={true}
              />
              <InputText
                control={control}
                errors={errors}
                name="englishName"
                label="영문이름"
              />
              <InputText
                control={control}
                errors={errors}
                name="mail"
                label="이메일"
              />
              <InputTel
                control={control}
                errors={errors}
                name="hp"
                label="휴대전화"
              />
              <InputTel
                control={control}
                errors={errors}
                name="tel"
                label="직통번호"
              />
              <InputTel
                control={control}
                errors={errors}
                name="fax"
                label="팩스번호"
              />
              <InputLookup
                control={control}
                errors={errors}
                name="companyCode"
                label="소속법인"
                dataSource={lookupData.company}
                displayExpr="companyName"
                valueExpr="id"
              />
              <InputLookup
                control={control}
                errors={errors}
                name="dutyCode"
                label="직책"
                dataSource={lookupData.duty}
                displayExpr="subName"
                valueExpr="id"
                // required={true}
              />
              <InputLookup
                control={control}
                errors={errors}
                name="deptCode"
                label="소속부서"
                dataSource={lookupData.department}
                displayExpr="deptName"
                valueExpr="code"
                // required={true}
              />
              <InputLookup
                control={control}
                errors={errors}
                name="authGroupId"
                label="화면그룹"
                dataSource={lookupData.auth}
                displayExpr="authGroupName"
                valueExpr="id"
                required={true}
              />
              <InputLookup
                control={control}
                errors={errors}
                name="driverId"
                label="기사"
                dataSource={lookupData.driver}
                displayExpr="carNo_Name"
                valueExpr="oid"
              />
              <InputFullRow grid={2}>
                <InputTextArea
                  control={control}
                  errors={errors}
                  name="remark"
                  label="비고"
                />
              </InputFullRow>
            </PanelInput>
            <ButtonGroup marginTop={20}>
              <CustomButton
                type="submit"
                width="100%"
                height={36}
                layout="solid"
                color="common"
                label="저장"
              />
            </ButtonGroup>
          </PanelInputWrapper>

          <UserMaster
            onFocusedRowChanged={onFocusedRowChanged}
            dataGridRef={dataGridRef}
            selectedRowKey={selectedRowKey}
          />
        </PanelBody>
      </PanelInit>
    </>
  );
}
