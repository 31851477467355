import { alert, confirm } from "devextreme/ui/dialog";
import React, { useRef, useState } from "react";
import { useForm } from "react-hook-form";
import CustomButton from "../../../components/FunctionButton/CustomButton";
import FunctionButton from "../../../components/FunctionButton/FunctionButton";
import HeaderTitle from "../../../components/Layout/Header/HeaderTitle";
import {
  PanelGridInit,
  PanelGridSearchBar,
  PanelGridWrapper,
  SearchItems,
} from "../../../components/Layout/Panel/PanelGrid/panelGrid.style";
import {
  PanelBody,
  PanelBtn,
  PanelHeader,
  PanelInit,
} from "../../../components/Layout/Panel/panel.style";
import { useDatagrid } from "../../../hooks/useDatagrid";
import AuthGroup from "./AuthGroup";
import { deleteAuthGroup } from "./AuthGroupCrud";
import AuthGroupDetail from "./AuthGroupDetail";

export default function AuthGroupPage() {
  const initialSearchState = { groupName: "" };
  const [search, setSearch] = useState(initialSearchState);
  const [rowData, setRowData] = useState();
  const dataGridRef = useRef();
  const dataGridDetailRef = useRef();
  useDatagrid(dataGridRef, dataGridDetailRef);

  const { register, handleSubmit } = useForm({
    defaultValues: initialSearchState,
  });

  const onClickRead = (data) => {
    if (!dataGridRef.current) return;
    setSearch(data);

    dataGridRef.current.instance.cancelEditData();
    dataGridRef.current.instance.refresh();
  };

  const onClickAdd = () => {
    if (!dataGridRef.current) return;

    dataGridRef.current.instance.option("focusedRowIndex", -1);
    // dataGridRef.current.instance.addRow();

    setTimeout(() => {
      setRowData(null);
    }, 100);
  };

  const onClickDelete = async () => {
    const check = await confirm("삭제하시겠습니까?", "확인");
    if (!check) return;
    if (!dataGridRef.current) return;

    try {
      const instance = dataGridRef.current.instance;
      const result = await deleteAuthGroup(rowData.data.id);
      alert(result.data.message, "확인");
      instance.refresh();
      instance.option("focusedRowIndex", -1);
      setRowData(null);
    } catch (error) {
      alert(error.response.data.message, "오류");
    }
  };

  const onFocusedRowChanged = (e) => {
    const data = e.row;
    if (!data) return;
    if (!rowData || rowData.rowIndex !== data.rowIndex) setRowData(data);

    dataGridDetailRef.current.instance.cancelEditData();
  };

  return (
    <>
      <PanelInit>
        <PanelHeader>
          <HeaderTitle />

          <PanelBtn>
            <FunctionButton
              onClickAdd={onClickAdd}
              onClickDelete={onClickDelete}
            />
          </PanelBtn>
        </PanelHeader>

        <PanelBody row left="50%" right="50%">
          <PanelGridWrapper>
            <PanelGridSearchBar onSubmit={handleSubmit(onClickRead)}>
              <SearchItems>
                <label>그룹명</label>
                <input type="text" {...register("groupName")} />
              </SearchItems>
              <CustomButton
                type="submit"
                height={24}
                layout="solid"
                color="search"
                label="검색"
              />
            </PanelGridSearchBar>
            <PanelGridInit>
              {/* 페이지 메인그리드 */}
              <AuthGroup
                onFocusedRowChanged={onFocusedRowChanged}
                dataGridRef={dataGridRef}
                search={search}
              />
            </PanelGridInit>
          </PanelGridWrapper>

          <AuthGroupDetail
            rowData={rowData}
            dataGridRef={dataGridRef}
            dataGridDetailRef={dataGridDetailRef}
          />
        </PanelBody>
      </PanelInit>
    </>
  );
}
