import { yupResolver } from "@hookform/resolvers/yup";
import { alert, confirm } from "devextreme/ui/dialog";
import React, { useRef, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import * as requestServer from "./DepartmentCrud";

import { useEffect } from "react";
import CustomButton from "../../../components/FunctionButton/CustomButton";
import { ButtonGroup } from "../../../components/FunctionButton/CustomButton/customButton.style";
import FunctionButton from "../../../components/FunctionButton/FunctionButton";
import InputCheckBox from "../../../components/InputForm/InputCheckBox";
import InputNumber from "../../../components/InputForm/InputNumber";
import InputText from "../../../components/InputForm/InputText";
import InputTextArea from "../../../components/InputForm/InputTextArea";
import HeaderTitle from "../../../components/Layout/Header/HeaderTitle";
import {
  InputFullRow,
  PanelInput,
  PanelInputTitle,
  PanelInputWrapper,
} from "../../../components/Layout/Panel/PanelInput/panelInput.style";
import {
  PanelBody,
  PanelBtn,
  PanelHeader,
  PanelInit,
} from "../../../components/Layout/Panel/panel.style";
import { useDatagrid } from "../../../hooks/useDatagrid";
import DepartmentMaster from "./DepartmentMaster";

export default function DepartmentPage() {
  const initialState = {
    code: "",
    deptName: "",
    remark: "",
    sortRef: 0,
    isDispatch: false,
  };
  const [selectedRowKey, setSelectedRowKey] = useState();
  const [inputDisable, setInputDisable] = useState(false);

  const dataGridRef = useRef();
  useDatagrid(dataGridRef);

  const schema = yup
    .object({
      code: yup
        .string()
        .required("부서코드를 입력하세요.")
        .max(20, "최대 20자리 까지 입력 가능"),
      deptName: yup
        .string()
        .required("부서명을 입력하세요.")
        .max(20, "최대 20자리 까지 입력 가능"),
      sortRef: yup.number().required("정렬순서를 입력해주세요"),
      remark: yup.string().max(100, "최대 100자리 까지 입력 가능"),
    })
    .required();

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    reset(initialState);
    // eslint-disable-next-line
  }, []);

  const onClickAdd = () => {
    if (!dataGridRef.current) return;
    reset(initialState);
    setSelectedRowKey(null);
    setInputDisable(false);
    dataGridRef.current.instance.option("focusedRowIndex", -1);
  };

  const onClickDelete = () => {
    if (!dataGridRef.current || !selectedRowKey) return;
    let response = confirm("<i>삭제하시겠습니까?</i>", "삭제 메세지");

    response.then((res) => {
      if (!res) return;
      requestServer
        .deleteRow(selectedRowKey)
        .then(() => {
          dataGridRef.current.instance.refresh();
        })
        .catch((err) => {
          alert(err.response.data.message, "오류");
        });
    });
  };

  const onFocusedRowChanged = (e) => {
    if (!e.row) return;
    setSelectedRowKey(e.row.key);
    setInputDisable(true);
    reset({
      ...e.row.data,
      sortRef: e.row.data.sortRef || 0,
      remark: e.row.data.remark || undefined,
    });
  };

  const onSubmit = async (datas) => {
    const data = { ...datas };
    delete data.password;
    delete data.createIP;
    delete data.createUserId;
    delete data.createdAt;
    delete data.updateIP;
    delete data.updateUserId;
    delete data.updatedAt;

    try {
      if (selectedRowKey) {
        await requestServer.updateDept(selectedRowKey, data);
      } else {
        await requestServer.createDept(data);
      }
      dataGridRef.current.instance.refresh();
      reset(initialState);
      setSelectedRowKey(null);

      dataGridRef.current.instance.option("focusedRowIndex", -1);
    } catch (err) {
      alert(err.response.data.message, "오류");
    }
  };

  return (
    <>
      <PanelInit>
        <PanelHeader>
          <HeaderTitle />
          <PanelBtn>
            <FunctionButton
              onClickAdd={onClickAdd}
              onClickDelete={onClickDelete}
            />
          </PanelBtn>
        </PanelHeader>

        <PanelBody row left="400px" right="calc(100% - 400px)">
          <PanelInputWrapper
            onSubmit={handleSubmit(onSubmit)}
            openInputBar={true}
          >
            <PanelInputTitle>부서 등록</PanelInputTitle>

            <PanelInput grid={2}>
              <InputText
                control={control}
                errors={errors}
                name="code"
                label="부서코드"
                required={true}
                disabled={inputDisable}
              />
              <InputText
                control={control}
                errors={errors}
                name="deptName"
                label="부서명"
                required={true}
              />
              <InputNumber
                control={control}
                errors={errors}
                name="sortRef"
                label="정렬순서"
                required={true}
              />
              <InputCheckBox
                control={control}
                errors={errors}
                name="isDispatch"
                label="배차팀"
              />
              <InputFullRow grid={2}>
                <InputTextArea
                  control={control}
                  errors={errors}
                  name="remark"
                  label="비고"
                />
              </InputFullRow>
            </PanelInput>

            <ButtonGroup marginTop={20}>
              <CustomButton
                type="submit"
                width="100%"
                height={36}
                layout="solid"
                color="common"
                label="저장"
              />
            </ButtonGroup>
          </PanelInputWrapper>

          <DepartmentMaster
            onFocusedRowChanged={onFocusedRowChanged}
            dataGridRef={dataGridRef}
          />
        </PanelBody>
      </PanelInit>
    </>
  );
}
