import { DataGrid } from "devextreme-react";
import {
  Column,
  ColumnChooser,
  FilterRow,
  Format,
  HeaderFilter,
  Scrolling,
  Selection,
  StateStoring,
  Summary,
  TotalItem,
} from "devextreme-react/data-grid";
import CustomStore from "devextreme/data/custom_store";
import React, { useCallback, useMemo, useState } from "react";
import { sendRequest } from "../../../../../_metronic/_helpers/DataSoureHelpers";
import {
  DataCount,
  PanelGridInit,
} from "../../../../components/Layout/Panel/PanelGrid/panelGrid.style";
import StateStoringSaveAndDeleteButton from "../../../../components/StateStoring";

const CarClosingUpper = ({ search, carClosingUpperRef, masterFunctions }) => {
  const storageKey = "carClosingUpper";
  const [totalCount, setTotalCount] = useState(0);
  const dataSource = useMemo(() => {
    const apiUrl = `${process.env.REACT_APP_API_URL}/car-closing`;

    return new CustomStore({
      key: "completeNo",
      load: () => sendRequest(apiUrl, "GET", search),
    });
  }, [search]);

  const { onFocusedRowChanged } = masterFunctions;

  return (
    <>
      <PanelGridInit gridItems column>
        <StateStoringSaveAndDeleteButton
          dataGridRef={carClosingUpperRef}
          storageKey={storageKey}
        />
        <DataCount>{`총 ${totalCount}건`}</DataCount>
        <DataGrid
          className="datagrid-wrap"
          id="CarClosingUpper"
          dataSource={dataSource}
          ref={carClosingUpperRef}
          showRowLines={true}
          showBorders={true}
          hoverStateEnabled={true}
          allowColumnResizing={true}
          allowColumnReordering={true}
          columnResizingMode="widget"
          keyExpr="completeNo"
          focusedRowEnabled={true}
          onFocusedRowChanged={onFocusedRowChanged}
          onContentReady={(e) => setTotalCount(e.component.totalCount())}
        >
          <Selection
            mode="multiple"
            allowSelectAll={false}
            showCheckBoxesMode="always"
          />
          <FilterRow visible={true} />
          <HeaderFilter visible={true} />
          <Scrolling
            columnRenderingMode="virtual"
            mode="virtual"
            showScrollbar="always"
            useNative="false"
          />
          <StateStoring
            enabled={true}
            type="custom"
            customLoad={useCallback(() => {
              return JSON.parse(localStorage.getItem(storageKey));
            }, [])}
          />
          <ColumnChooser enabled={true} />

          <Column caption="하불마감 기본정보">
            <Column dataField="carNo" caption="차량번호" width="95" />
            <Column dataField="bizName" caption="사업자상호" width="95" />
            <Column dataField="ceoName" caption="대표자" width="95" />
            <Column dataField="carDivisionName" caption="차량구분" width="95" />
            {/* 접수법인 */}
            <Column dataField="receiveCoName" caption="접수법인" width="134" />
          </Column>
          <Column caption="세금계산서 정보">
            {/* 마감번호 */}
            <Column dataField="completeNo" caption="마감번호" width="134" />
            {/* 마감일자 */}
            <Column dataField="completeDate" caption="마감일자" width="134" />
            {/* 상세건수 */}
            <Column dataField="detailTotal" caption="상세건수" width="100" />
            {/* 정산상태 */}
            <Column dataField="closeStatus" caption="발행상태" width="100" />
            {/* 정산예정일자 */}
            <Column dataField="taxSchedule" caption="정산일자" width="100" />
            <Column
              dataField="taxSchedule02"
              caption="법인 정산일자"
              width="100"
            />
            {/* 세금계산서번호 */}
            <Column dataField="finishNo" caption="세금계산서번호" width="150" />
            <Column
              dataField="finishNo02"
              caption="법인 세금계산서번호"
              width="150"
            />
            {/* 계산서상태 */}
            <Column dataField="taxStatus" caption="계산서상태" width="100" />
          </Column>
          <Column caption="운송료">
            <Column
              dataField="money01"
              caption="공급가액"
              width="95"
              dataType="number"
            >
              <Format type="fixedPoint" />
            </Column>
            <Column
              dataField="money02"
              caption="세액"
              width="95"
              dataType="number"
            >
              <Format type="fixedPoint" />
            </Column>
            <Column
              dataField="money03"
              caption="소계"
              width="95"
              dataType="number"
            >
              <Format type="fixedPoint" />
            </Column>
          </Column>
          <Column caption="업무대행수수료">
            <Column
              dataField="manageFee"
              caption="관리비"
              width="95"
              dataType="number"
            >
              <Format type="fixedPoint" />
            </Column>
            <Column
              dataField="chassisManageFee"
              caption="샤시관리비"
              width="95"
              dataType="number"
            >
              <Format type="fixedPoint" />
            </Column>
            <Column
              dataField="chassisRental"
              caption="샤시임대료"
              width="95"
              dataType="number"
            >
              <Format type="fixedPoint" />
            </Column>
            <Column
              dataField="parking"
              caption="차량주차비"
              width="95"
              dataType="number"
            >
              <Format type="fixedPoint" />
            </Column>
            <Column
              dataField="hireInsurance"
              caption="고용보험료"
              width="95"
              dataType="number"
            >
              <Format type="fixedPoint" />
            </Column>
            <Column
              dataField="occupationalInsurance"
              caption="산재보험료"
              width="95"
              dataType="number"
            >
              <Format type="fixedPoint" />
            </Column>
            <Column
              dataField="money04"
              caption="공급가액"
              width="95"
              dataType="number"
            >
              <Format type="fixedPoint" />
            </Column>
            <Column
              dataField="money05"
              caption="세액"
              width="95"
              dataType="number"
            >
              <Format type="fixedPoint" />
            </Column>
            <Column
              dataField="money06"
              caption="소계"
              width="95"
              dataType="number"
            >
              <Format type="fixedPoint" />
            </Column>
          </Column>
          <Column caption="기타공제">
            <Column
              dataField="advancePaymentDeduction"
              caption="가불공제"
              width="95"
              dataType="number"
            >
              <Format type="fixedPoint" />
            </Column>
            <Column
              dataField="advancePayment"
              caption="대납"
              width="95"
              dataType="number"
            >
              <Format type="fixedPoint" />
            </Column>
            <Column
              dataField="prepayment"
              caption="선결재"
              width="95"
              dataType="number"
            >
              <Format type="fixedPoint" />
            </Column>
            <Column
              dataField="money07"
              caption="기타항목합계"
              width="95"
              dataType="number"
            >
              <Format type="fixedPoint" />
            </Column>
          </Column>
          <Column caption="결산">
            <Column
              dataField="money09"
              caption="지출계"
              width="95"
              dataType="number"
            >
              <Format type="fixedPoint" />
            </Column>
            <Column
              dataField="money10"
              caption="미지급금"
              width="95"
              dataType="number"
            >
              <Format type="fixedPoint" />
            </Column>
          </Column>
          {/* 합계금액 */}
          <Column dataField="priceTotal" caption="합계금액" dataType="number">
            <Format type="fixedPoint" />
          </Column>
          <Summary>
            <TotalItem
              column="price"
              summaryType="sum"
              valueFormat="fixedPoint"
              displayFormat="총 금액 : {0}"
            />
            <TotalItem
              column="taxTotal"
              summaryType="sum"
              valueFormat="fixedPoint"
              displayFormat="총 세액 : {0}"
            />
            <TotalItem
              column="priceTotal"
              summaryType="sum"
              valueFormat="fixedPoint"
              displayFormat="총 합계 : {0}"
            />
          </Summary>
        </DataGrid>
      </PanelGridInit>
    </>
  );
};
export default CarClosingUpper;
