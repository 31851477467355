import moment from "moment";
import * as yup from "yup";

// import { containerValidationCallback } from "../../../../utils/orderFunctions";

export const initialState = {
  rowData: null,

  search: {},
  lookupData: {
    company: [],
    billing: [],
    orderCo: [],
    forwarder: [],
    terminal: [],
    cargoOwner: [],
    conType: [],
    car: [],
    yongchasa: [],
    workPlace: [],
    color: [],

    dispatchName: [],

    amountUnit: [],
  },
  otherCo: [],

  openTerminalCreateModal: false,
  openCompanyCreateModal: false,
  openForwarderCreateModal: false,
  openInputBar: false,

  type: "",

  masterDataSource: [],

  isLoading: false,
  isConfirm: false,
  importExport: 1,
};

export const SimpleSellFinishReducer = (state, action) => {
  switch (action.type) {
    case "SET_ORDER_MASTER_GRID_DATA":
      return {
        ...state,
        masterDataSource: action.payload,
      };

    case "SET_ROW_DATA":
      return {
        ...state,
        rowData: action.payload,
      };

    case "SET_SEARCH":
      return {
        ...state,
        search: action.payload,
      };

    case "SET_OTHERCO_DATA":
      return {
        ...state,
        otherCo: action.payload,
      };

    case "SET_LOADING":
      return {
        ...state,
        isLoading: action.payload,
      };

    case "SET_TOGGLE_INPUT_BAR":
      return {
        ...state,
        openInputBar: action.payload,
      };

    case "SET_LOOKUP_DATA":
      return {
        ...state,
        lookupData: action.payload,
      };

    case "SET_IMPORT_EXPORT_VALUE":
      return {
        ...state,
        importExport: action.payload,
      };

    case "SET_OPEN_COMPANY_MODAL":
      return {
        ...state,
        ...action.payload,
      };

    case "SET_OPEN_FORWARDER_MODAL":
      return {
        ...state,
        ...action.payload,
      };

    case "SET_OPEN_TERMINAL_MODAL":
      return {
        ...state,
        ...action.payload,
      };

    default:
      throw new Error("Unhandled action");
  }
};

export const inputSchema = yup.object({
  code: yup.string().nullable(),
  importExport: yup.string().nullable(),
  receiveCo: yup
    .string()
    .nullable()
    .required("접수법인을 선택해주세요"),
  receiveDate: yup.string().nullable(),
  dispatchTeam: yup.string().nullable(),
  billingPlace: yup
    .string()
    .nullable()
    .required("청구처를 선택해주세요"),
  orderCo: yup.string().nullable(),
  forwarder: yup.string().nullable(),
  shipName: yup.string().nullable(),
  motherShip: yup.string().nullable(),
  dem: yup.string().nullable(),
  manager: yup.string().nullable(),
  enterDay: yup.string().nullable(),
  sailDay: yup.string().nullable(),
  bkNo: yup.string().nullable(),
  containerNo: yup.string().nullable(),
  // .required("컨테이너 번호를 입력해주세요")
  // .test("containerNo", "컨테이너 번호가 올바르지 않습니다", (value) =>
  //   containerValidationCallback({ value })
  // ),
  containerType: yup.string().nullable(),
  // .required("컨테이너 번호를 입력해주세요"),
  containerStatus01: yup.string().nullable(),
  containerStatus02: yup.string().nullable(),
  blNo: yup.string().nullable(),
  blType: yup.string().nullable(),
  loadPlace: yup.string().nullable(),
  unloadPlace: yup.string().nullable(),
  cargoOwner: yup.string().nullable(),
  realCargoOwner: yup.string().nullable(),
  otherDeliver: yup.string().nullable(),
  color: yup.string().nullable(),
  fridge: yup.bool(),
  danger: yup.bool(),
  tank: yup.bool(),
  direct: yup.bool(),
  toxic: yup.bool(),
  keep: yup.bool(),
  remark: yup.string().nullable(),
  innerMemo: yup.string().nullable(),
  amount: yup.number().nullable(),
  weight: yup.number().nullable(),
  spec: yup.number().nullable(),
  freight: yup
    .number()
    .nullable()
    .required("청구금액을 입력해주세요"),
  habul: yup.number().nullable(),
  toggle1: yup.string().nullable(),
  carNo_01: yup.string().nullable(),
  yongchasa_01: yup.string().nullable(),
  yongchaCarNo_01: yup.string().nullable(),
  pointManager: yup.string().nullable(),
  pointManageContact: yup.string().nullable(),
});

export const getInputInitialState = (user) => {
  return {
    code: "",
    importExport: 1,
    receiveCo: user.companyCode,
    receiveDate: moment().format("YYYY-MM-DD"),
    dispatchTeam: user?.deptCode,
    billingPlace: null,
    orderCo: null,
    forwarder: null,
    shipName: "",
    motherShip: "",
    dem: null,
    manager: "",
    enterDay: null,
    sailDay: null,
    bkNo: "",
    containerStatus01: 1,
    containerStatus02: 1,
    blNo: "",
    blType: 1,
    loadPlace: null,
    unloadPlace: null,
    cargoOwner: null,
    realCargoOwner: "",
    otherDeliver: null,
    color: null,
    fridge: false,
    danger: false,
    tank: false,
    direct: false,
    toxic: false,
    keep: false,
    remark: "",
    innerMemo: "",
    amount: 0,
    amountUnit: "CUNIT-800",
    cargoWeight: 0,
    spec: 0,
    freight: 0,
    habul: 0,
    carTypeCode: "",
    carNo: null,
    yongchasa: null,
    yongchaCarNo: "",
    pointManager: "",
    pointManageContact: "",
  };
};

export const getSearchInitialState = (user) => {
  return {
    receiveCo: user.companyCode,
    importExport: "",
    firstDay: moment().format("YYYY-MM-DD"),
    lastDay: moment().format("YYYY-MM-DD"),
    containerNo: "",
    blNo: "",
    doNo: "",
    bkNo: "",
    company: user.companyCode,
    dispatchTeam: user?.deptCode || "",
    searchType: 2,
  };
};
