import { DataGrid } from "devextreme-react";
import {
  Column,
  ColumnChooser,
  FilterRow,
  HeaderFilter,
  Scrolling,
  StateStoring,
} from "devextreme-react/data-grid";
import CustomStore from "devextreme/data/custom_store";
import moment from "moment";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useForm } from "react-hook-form";
import { shallowEqual, useSelector } from "react-redux";
import { sendRequest } from "../../../../_metronic/_helpers/DataSoureHelpers";
import StateStoringSaveAndDeleteButton from "../../../components/StateStoring";

import { alert } from "devextreme/ui/dialog";
import CustomButton from "../../../components/FunctionButton/CustomButton";
import { InputSelect } from "../../../components/InputForm";
import InputCheckBox from "../../../components/InputForm/InputCheckBox";
import InputDate from "../../../components/InputForm/InputDate";
import HeaderTitle from "../../../components/Layout/Header/HeaderTitle";
import {
  DataCount,
  PanelGridInit,
  PanelGridSearchBar,
  PanelGridWrapper,
  SearchItems,
} from "../../../components/Layout/Panel/PanelGrid/panelGrid.style";
import {
  PanelBody,
  PanelHeader,
  PanelInit,
} from "../../../components/Layout/Panel/panel.style";
import { useDatagrid } from "../../../hooks/useDatagrid";
import { getLookupDatas } from "../DispatchNew/DispatchNewCrud";

function DangerManagePage(props) {
  const { user } = useSelector(
    (state) => ({ user: state.auth.user.user }),
    shallowEqual
  );

  const initialState = {
    receiveCo: user.companyCode,
    dispatchTeam: user?.deptCode || "",
    firstDay: moment().format("YYYY-MM-DD"),
    lastDay: moment().format("YYYY-MM-DD"),
    complete: false,
  };
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  // other state
  const [totalCount, setTotalCount] = useState(0);
  // const [rowData, setRowData] = useState();
  const [search, setSearch] = useState({});
  const [focusedComponent, setFocusedComponent] = useState();
  const [lookupData, setLookupData] = useState({
    company: [],
    dispatch: [],
  });
  const dataGridRef = useRef();

  useDatagrid(dataGridRef);

  const storageKey = "dangerManageMaster";

  const dataSource = useMemo(() => {
    const apiUrl = `${process.env.REACT_APP_API_URL}/dispatch/danger`;

    return new CustomStore({
      key: "id",
      load: () => sendRequest(apiUrl, "GET", search),
    });
  }, [search]);

  useEffect(() => {
    getLookup();
    reset(initialState);
    setSearch(initialState);
    // eslint-disable-next-line
  }, []);

  const onCellClick = (e) => {
    if (!e.row) return;
    // detailGridRef.current && detailGridRef.current.instance.option("focusedRowIndex", -1);
    const row = e.row;
    if (row.rowType === "data") {
      // setRowData(row.data);
    }
  };

  const onFocusedRowChanged = (e) => {
    if (e.rowIndex === -1) return;
    // eslint-disable-next-line
    if (focusedComponent && focusedComponent != e.component) {
      focusedComponent.option("focusedRowIndex", -1);
      if (e.element.id === "DangerManage") {
        // setRowData(null);
      }
    }
    setFocusedComponent(e.component);
  };

  const onRowDblClick = (e) => {
    if (e.isExpanded) {
      e.component.collapseRow(e.key);
    } else {
      e.component.expandRow(e.key);
    }
  };

  const onSubmit = (data) => {
    for (const obj in data) {
      if (data[obj] === "") {
        data[obj] = null;
      }
    }
    // const submit = {
    //   ...data
    // };

    setSearch(data);
  };

  const onRowPrepared = (e) => {
    if (e.rowType !== "data") return;

    // 색깔 조정하기
    if (e.data.longStorage === true) {
      // 접수
      e.rowElement.style.background = "#eaeaea";
    }
  };

  const onCellPrepared = (e) => {
    if (e.rowType !== "data") return;
    if (e.column.caption !== "반입지" && e.column.caption !== "하차지") return;

    if (e.column.caption === "반입지") {
      e.cellElement.style.background = e.data.loadPlaceColor || "transparant";
    } else if (e.column.caption === "하차지") {
      e.cellElement.style.background = e.data.unloadPlaceColor || "transparant";
    }
  };

  async function getLookup() {
    try {
      const companyData = await getLookupDatas("company");
      const disaptchTeamData = await getLookupDatas("department/isDispatch");

      console.log(disaptchTeamData.data);

      setLookupData({
        company: [{ id: "", companyName: "전체" }, ...companyData.data],
        dispatch: [{ code: "", deptName: "전체" }, ...disaptchTeamData.data],
      });
    } catch (err) {
      alert(err.response?.data?.message, "오류");
    }
  }

  return (
    <>
      <PanelInit>
        <PanelHeader>
          <HeaderTitle />
        </PanelHeader>
        <PanelBody row left="100%" right="0">
          <PanelGridWrapper>
            <PanelGridSearchBar onSubmit={handleSubmit(onSubmit)}>
              <SearchItems>
                <label>접수법인</label>
                <InputSelect
                  control={control}
                  errors={errors}
                  name="receiveCo"
                  dataSource={lookupData.company}
                  valueExpr="id"
                  displayExpr="companyName"
                  flex={true}
                />
              </SearchItems>
              <SearchItems>
                <label>배차팀</label>
                <InputSelect
                  control={control}
                  errors={errors}
                  name="dispatchTeam"
                  dataSource={lookupData.dispatch}
                  valueExpr="code"
                  displayExpr="deptName"
                  flex={true}
                />
              </SearchItems>
              <SearchItems>
                <label>기준일자</label>
                <InputDate
                  control={control}
                  errors={errors}
                  name="firstDay"
                  flex={true}
                />
                <div className="to-mark">~</div>
                <InputDate
                  control={control}
                  errors={errors}
                  name="lastDay"
                  flex={true}
                />
              </SearchItems>

              <SearchItems>
                <label>완료건 검색</label>
                <InputCheckBox
                  control={control}
                  errors={errors}
                  name="complete"
                  flex={true}
                />
              </SearchItems>
              <CustomButton
                type="submit"
                height={24}
                layout="solid"
                color="search"
                label="검색"
              />
            </PanelGridSearchBar>

            <PanelGridInit>
              <StateStoringSaveAndDeleteButton
                dataGridRef={dataGridRef}
                storageKey={storageKey}
              />
              <DataCount>{`총 ${totalCount}건`}</DataCount>
              <DataGrid
                className="datagrid-wrap"
                id="DangerManage"
                dataSource={dataSource}
                ref={dataGridRef}
                showRowLines={true}
                showBorders={true}
                hoverStateEnabled={true}
                allowColumnResizing={true}
                allowColumnReordering={true}
                columnAutoWidth={true}
                columnResizingMode="widget"
                onRowDblClick={onRowDblClick}
                focusedRowEnabled={true}
                onCellPrepared={onCellPrepared}
                onRowPrepared={onRowPrepared}
                onCellClick={onCellClick}
                onFocusedRowChanged={onFocusedRowChanged}
                onContentReady={(e) => setTotalCount(e.component.totalCount())}
              >
                <FilterRow visible={true} />
                <HeaderFilter visible={true} />
                <ColumnChooser enabled={true} />
                <StateStoring
                  enabled={true}
                  type="custom"
                  customLoad={useCallback(() => {
                    return JSON.parse(localStorage.getItem(storageKey));
                  }, [])}
                />
                <Scrolling
                  columnRenderingMode="virtual"
                  mode="virtual"
                  showScrollbar="always"
                  useNative="false"
                />
                <Column
                  dataField="code"
                  caption="접수번호"
                  alignment="center"
                />
                <Column
                  dataField="receiveCoName"
                  caption="접수법인"
                  alignment="center"
                />
                <Column
                  dataField="cargoOwnerName"
                  caption="화주"
                  alignment="center"
                />
                <Column
                  dataField="billingPlaceName"
                  caption="청구처"
                  alignment="center"
                />
                <Column
                  dataField="orderCoName"
                  caption="발주처"
                  alignment="center"
                />
                <Column
                  dataField="deliverDate"
                  caption="창고입고날짜"
                  alignment="center"
                />
                <Column
                  dataField="bookingNo"
                  caption="부킹넘버"
                  alignment="center"
                />
                <Column
                  dataField="containerNo"
                  caption="컨테이너 No"
                  alignment="center"
                />
                <Column
                  dataField="sealNo"
                  caption="Seal No"
                  alignment="center"
                />
                <Column
                  dataField="containerType"
                  caption="컨/규격"
                  alignment="center"
                />
                <Column
                  dataField="forwarder"
                  caption="선사"
                  alignment="center"
                />
                <Column
                  dataField="shipName"
                  caption="선명"
                  alignment="center"
                />
                <Column
                  dataField="loadPlace"
                  caption="반입지"
                  alignment="center"
                />
                <Column
                  dataField="enterDay"
                  caption="입항"
                  alignment="center"
                  dataType="date"
                  format="yyyy-MM-dd"
                />
                <Column
                  dataField="sailDay"
                  caption="출항"
                  alignment="center"
                  dataType="date"
                  format="yyyy-MM-dd"
                />
                <Column
                  dataField="temperature"
                  caption="온도"
                  alignment="center"
                />
                <Column dataField="humidity" caption="습도" />
                <Column dataField="vent" caption="환풍구" />
                <Column dataField="unNo" caption="un번호" alignment="center" />
                <Column dataField="class" caption="클래스" alignment="center" />
                <Column
                  dataField="unloadPlace"
                  caption="하차지"
                  alignment="center"
                />
                <Column
                  dataField="cargoWeight"
                  caption="총중량"
                  alignment="center"
                />
                <Column
                  dataField="carNo"
                  caption="차량번호"
                  alignment="center"
                />
                <Column
                  dataField="motherShip"
                  caption="모선항차"
                  alignment="center"
                />
              </DataGrid>
            </PanelGridInit>
          </PanelGridWrapper>
        </PanelBody>
      </PanelInit>
    </>
  );
}

export default DangerManagePage;
