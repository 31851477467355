export function validateContainerNo(conNo) {
  if (!conNo) return true;
  if (conNo.length !== 11) return false;
  const conRegex = /^[A-Z]{4}\d{7}$/;
  const result = conRegex.test(conNo);
  if (!result) return false;

  let sum = calculateCheckDigit(conNo);

  let mod = sum % 11;
  if (mod === 10) mod = 0;

  if (parseInt(conNo[conNo.length - 1]) !== mod) return false; // CheckDigit이 다르면 return

  return true;
}

function calculateCheckDigit(value) {
  let sum = 0;

  for (let i = 0; i < 10; i++) {
    if (value[i].charCodeAt(0) === 65) {
      // A일때
      let num = value[i].charCodeAt(0) - 55;

      sum += parseInt(num) * Math.pow(2, i);
    } else if (value[i].charCodeAt(0) > 65 && value[i].charCodeAt(0) <= 75) {
      // B-K일때
      let num = value[i].charCodeAt(0) - 54;

      sum += parseInt(num) * Math.pow(2, i);
    } else if (value[i].charCodeAt(0) > 75 && value[i].charCodeAt(0) <= 85) {
      // L-U일떄
      let num = value[i].charCodeAt(0) - 53;

      sum += parseInt(num) * Math.pow(2, i);
    } else if (value[i].charCodeAt(0) > 85 && value[i].charCodeAt(0) <= 90) {
      // V-Z일때
      let num = value[i].charCodeAt(0) - 52;

      sum += parseInt(num) * Math.pow(2, i);
    } else {
      // 숫자일때
      sum += parseInt(value[i]) * Math.pow(2, i);
    }
  }

  return sum;
}
