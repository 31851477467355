import { Column, DataGrid, Format } from "devextreme-react/data-grid";
import CustomStore from "devextreme/data/custom_store";
import React, { useCallback, useMemo } from "react";
import { sendRequest } from "../../../../_metronic/_helpers/DataSoureHelpers";

function SimpleDispatchDetail({
  data,
  setDetailRowData,
  dataGridRef,
  detailGridRef,
  onFocusedRowChanged,
}) {
  const dataSource = useMemo(() => {
    const apiUrl = `${process.env.REACT_APP_API_URL}/simpledispatch/detail`;

    return new CustomStore({
      key: "id",
      load: () => sendRequest(apiUrl + `/${data}`, "GET"),
    });
    // eslint-disable-next-line
  }, []);

  const onDetailCellClick = useCallback((e) => {
    if (!e.row) return;
    dataGridRef.current.instance.option("focusedRowIndex", -1);
    setDetailRowData(e.row.data);
    // eslint-disable-next-line
  }, []);

  const onRowPrepared = useCallback((e) => {
    if (e.rowType !== "data") return;

    // 색깔 조정하기
    if (e.data.dispatchStatus === 62) {
      // 접수
      e.rowElement.style.background = "#B3E8E5";
    } else if (e.data.dispatchStatus === 78) {
      // 배차등록
      e.rowElement.style.background = "#82DBD8";
    } else if (e.data.dispatchStatus === 79) {
      // 배차확정
      e.rowElement.style.background = "#3BACB6";
    } else if (e.data.dispatchStatus === 80) {
      // 운송완료
      e.rowElement.style.background = "#2F8F9D";
    }
  }, []);

  return (
    <DataGrid
      id="SimpleDispatchDetail"
      showBorders={true}
      columnAutoWidth={true}
      dataSource={dataSource}
      ref={detailGridRef}
      focusedRowEnabled={true}
      allowColumnResizing={true}
      columnResizingMode="widget"
      onFocusedRowChanged={onFocusedRowChanged}
      onCellClick={onDetailCellClick}
      onRowPrepared={onRowPrepared}
      onContentReady={(e) => {
        if (e.component.getDataSource().items().length === 0)
          e.component.option("height", "54px");
        else e.component.option("height", null);
      }}
      width="70vw"
    >
      <Column dataField="dispatchStatus" caption="상태" alignment="center" />
      <Column dataField="deliverDate" caption="운송일자" alignment="center" />
      <Column dataField="car" caption="배차차량" />
      <Column dataField="yongchasaName" caption="용차사" />
      <Column dataField="startCode" caption="출발지코드" />
      <Column dataField="startPoint" caption="출발지" />
      <Column dataField="endPoint" caption="도착지" />
      <Column
        dataField="habul"
        caption="하불금액"
        alignment="right"
        dataType="number"
      >
        <Format type="fixedPoint" />
      </Column>
    </DataGrid>
  );
}

export default SimpleDispatchDetail;
