import React from "react";
import { Controller } from "react-hook-form";
import RadioGroup from "devextreme-react/radio-group";
import InputLayout from "./InputLayout";

const InputRadioGroup = ({
  control,
  errors,
  name,
  label,
  labelWidth,
  dataSource,
  flex,
  displayExpr = "name",
  valueExpr = "id",
  required,
  disable,
  onValueChanged = () => {},
}) => {
  // dataSource
  return (
    <InputLayout
      label={label}
      reqruied={required}
      errors={errors}
      name={name}
      labelWidth={labelWidth}
      radio
    >
      <Controller
        render={({ field: { onChange, value } }) => (
          <RadioGroup
            onValueChanged={(value) => {
              onValueChanged(value);
            }}
            disabled={disable}
            dataSource={dataSource}
            displayExpr={displayExpr}
            valueExpr={valueExpr}
            layout="horizontal"
            onValueChange={(value) => {
              onChange(value);
            }}
            value={value}
          />
        )}
        control={control}
        name={name}
        // defaultValue={items[0]}
      />
    </InputLayout>
  );
};

export default InputRadioGroup;
