import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Modal } from "react-bootstrap";
import * as yup from "yup";
import SVG from "react-inlinesvg";
import { yupResolver } from "@hookform/resolvers/yup";
import * as requestServer from "../../../app/pages/System/User/UserCrud";
import { toAbsoluteUrl } from "../../_helpers";

import InputPassword from "../../../app/components/InputForm/InputPassword";

import { alert } from "devextreme/ui/dialog";

export function ChangePasswordModal({
  onClose,
  maskClosable,
  closable,
  visible,
  selectedRowKey,
}) {
  // const [isError, setIsError] = useState();
  const [isLoading, setIsLoading] = useState();
  const schema = yup.object({
    password: yup
      .string()
      .required("비밀번호를 입력하세요")
      .matches(
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[$@$!%*#?&])[A-Za-z\d$@$!%*#?&]{8,}$/,
        "8자 이상, 하나의 문자, 하나의 숫자, 하나의 특수문자"
      ),
    passwordConfirm: yup.string().when("password", {
      is: (val) => (val && val.length > 0 ? true : false),
      then: yup.string().oneOf([yup.ref("password")], "비밀번호가 다릅니다"),
    }),
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = (data) => {
    setIsLoading(true);
    requestServer
      .changePassword({ key: selectedRowKey, password: data.password })
      .then(({ data }) => {
        setTimeout(() => {
          onClose();
        }, 500);
      })
      .catch((err) => {
        alert(err.response.data.message, "오류");
      })
      .finally(() => {
        setIsLoading(false);
      });
    /*
    rowData.id로 password 변경
    이후에 password변경하기
    Token 수정하기
    */
  };

  const onError = (error) => {
    // setIsError(error);
  };

  const onMaskClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  return (
    <>
      <div className={"modal-overlay " + (visible ? "active" : "")} />
      <div
        className={"modal-wrap user-list-modal " + (visible ? "active" : "")}
        onClick={maskClosable ? onMaskClick : null}
        tabIndex="-1"
        visible={`${visible}`}
      >
        <Modal.Dialog centered tabIndex="0" size="sm" animation="true">
          <Modal.Body>
            <form
              onSubmit={handleSubmit(onSubmit, onError)}
              className="modal-form"
            >
              <div className="modal-form-header">
                <div className="modal-form-tit">
                  <span className="modal-tit">비밀번호 변경</span>
                </div>
                <button
                  type="button"
                  className="btn-modal btn-modal-close"
                  onClick={onClose}
                >
                  <SVG
                    src={toAbsoluteUrl(
                      "/media/svg/icons/Custom/close_black_24dp.svg"
                    )}
                    className="close-icon"
                  />
                </button>
              </div>
              <div className="modal-form-body">
                <div className="d-flex flex-row mb-3">
                  <div className="dx-field">
                    <InputPassword
                      control={control}
                      errors={errors}
                      name="password"
                      label="새 비밀번호"
                    />
                  </div>
                </div>
                <div className="d-flex-flex-row">
                  <div className="dx-field">
                    <InputPassword
                      control={control}
                      errors={errors}
                      name="passwordConfirm"
                      label="비밀번호 확인"
                    />
                  </div>
                </div>
              </div>
              <div className="modal-form-footer">
                <button
                  type="submit"
                  disabled={isLoading}
                  className={`btn btn-modal btn-save ${isLoading &&
                    "spinner spinner-white spinner-right"}`}
                >
                  저장
                </button>
              </div>
            </form>
          </Modal.Body>
        </Modal.Dialog>
      </div>
    </>
  );
}
