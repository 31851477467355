import axios from "axios";

const API_URL = `${process.env.REACT_APP_API_URL}`;
const SHUTTLE_EXCEL_URL = `${API_URL}/shuttle/excel`;
export function getLookups() {
  return axios
    .all([
      axios.get(`${API_URL}/companyManage/lookup/isBilling`),
      axios.get(`${API_URL}/terminal/terminal`),
      axios.get(`${API_URL}/comcode/detail/10400`),
      axios.get(`${API_URL}/terminal/workplace`),
      axios.get(`${API_URL}/company`),
      axios.get(`${API_URL}/companyManage/lookup/isCargoOwner`),
      axios.get(`${API_URL}/carManage/lookup`),
      axios.get(`${API_URL}/companyManage/lookup/isYongchasa`),
      axios.get(`${API_URL}/forwarder/lookup`),
      axios.get(`${API_URL}/comcode/detail/40300`),
    ])
    .then(
      axios.spread(
        (
          billingPlace,
          terminal,
          conType,
          workPlace,
          company,
          cargoOwner,
          car,
          yongchasa,
          forwarder,
          carType
        ) => {
          return {
            billingPlace: billingPlace.data.map((obj) => {
              return {
                name: obj.company,
                value: obj.oid,
              };
            }),
            terminal: terminal.data.map((obj) => {
              return {
                name: obj.twName,
                value: obj.code,
              };
            }),
            conType: conType.data.map((obj) => {
              return {
                name: obj.subName,
                value: obj.subCode,
              };
            }),
            workPlace: workPlace.data.map((obj) => {
              return {
                name: obj.twName,
                value: obj.code,
              };
            }),
            company: company.data.map((obj) => {
              return {
                name: obj.companyName,
                value: obj.id,
              };
            }),
            cargoOwner: cargoOwner.data.map((obj) => {
              return {
                name: obj.company,
                value: obj.oid,
              };
            }),
            car: car.data.map((obj) => {
              return {
                name: obj.carNo,
                value: obj.oid,
              };
            }),
            yongchasa: yongchasa.data.map((obj) => {
              return {
                name: obj.company,
                value: obj.oid,
              };
            }),
            forwarder: forwarder.data.map((obj) => {
              return {
                name: obj.code,
                value: obj.code,
              };
            }),
            carType: carType.data.map((obj) => {
              return {
                name: obj.subName,
                value: obj.subCode,
              };
            }),
            importExport: [
              { value: 1, name: "수입" },
              { value: 2, name: "수출" },
            ],
            toggle: [
              { value: 1, name: "차량번호" },
              { value: 2, name: "용차사" },
            ],
          };
        }
      )
    );
}

export async function createShuttleByExcel(data) {
  try {
    await axios.post(SHUTTLE_EXCEL_URL, data);
    return true;
  } catch (error) {
    return false;
  }
}
