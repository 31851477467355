import { DateBox } from "devextreme-react";
import React from "react";
import { Controller } from "react-hook-form";
import moment from "moment";
import InputLayout from "./InputLayout";

const InputMonth = ({
  control,
  errors,
  name,
  label,
  labelWidth,
  flex,
  disable = false,
  required,
  defaultValue,
  onValueChanged = () => {},
}) => {
  return (
    <InputLayout
      label={label}
      required={required}
      errors={errors}
      name={name}
      labelWidth={labelWidth}
    >
      <Controller
        render={({ field: { onChange, value } }) => (
          <DateBox
            type="date"
            mask={"0000-00"}
            displayFormat="yyyy-MM"
            onValueChange={(value) => {
              if (value) onChange(moment(value).format("YYYY-MM"));
            }}
            value={value}
            disabled={disable}
            calendarOptions={{
              maxZoomLevel: "year",
              minZoomLevel: "century",
            }}
            onValueChanged={onValueChanged}
          />
        )}
        control={control}
        name={name}
        defaultValue=""
      />
    </InputLayout>
  );
};

export default InputMonth;
