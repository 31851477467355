import { DataGrid } from "devextreme-react";
import {
  Column,
  ColumnChooser,
  FilterRow,
  HeaderFilter,
  Scrolling,
  StateStoring,
} from "devextreme-react/data-grid";
import CustomStore from "devextreme/data/custom_store";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { shallowEqual, useSelector } from "react-redux";
import { sendRequest } from "../../../../_metronic/_helpers/DataSoureHelpers";

import moment from "moment";
import { useCallback } from "react";
import CustomButton from "../../../components/FunctionButton/CustomButton";
import InputDate from "../../../components/InputForm/InputDate";
import InputSelect from "../../../components/InputForm/InputSelect";
import HeaderTitle from "../../../components/Layout/Header/HeaderTitle";
import {
  DataCount,
  PanelGridInit,
  PanelGridSearchBar,
  PanelGridWrapper,
  SearchItems,
} from "../../../components/Layout/Panel/PanelGrid/panelGrid.style";
import {
  PanelBody,
  PanelHeader,
  PanelInit,
} from "../../../components/Layout/Panel/panel.style";
import StateStoringSaveAndDeleteButton from "../../../components/StateStoring";
import { useDatagrid } from "../../../hooks/useDatagrid";

function DispatchStatusPage(props) {
  // hook-form
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  // state
  const [totalCount, setTotalCount] = useState(0);
  const [search, setSearch] = useState({});

  const storageKey = "dispatchStatusMaster";

  // ref
  const dataGridRef = useRef();

  useDatagrid(dataGridRef);

  // redux
  const { user } = useSelector(
    (state) => ({ user: state.auth.user.user }),
    shallowEqual
  );

  // initialState
  const initialState = {
    firstDay: moment().format("YYYY-MM-DD"),
    lastDay: moment().format("YYYY-MM-DD"),
    searchType: 2,
    authGroupId: user.authGroupId,
    dispatchTeam: user?.deptCode,
  };

  // useEffect
  useEffect(() => {
    reset(initialState);
    setSearch(initialState);
    // eslint-disable-next-line
  }, []);

  // dataSource
  const dataSource = useMemo(() => {
    const apiUrl = `${process.env.REACT_APP_API_URL}/dispatchstatus`;

    return new CustomStore({
      key: "id",
      load: () => sendRequest(apiUrl, "GET", search),
    });
  }, [search]);

  // submit
  const onSubmit = (data) => {
    for (const obj in data) {
      if (data[obj] === "") {
        data[obj] = null;
      }
    }
    setSearch(data);
  };

  // dataGrid 함수
  const onRowDblClick = useCallback(() => {}, []);

  const onCellPrepared = useCallback((e) => {
    if (e.rowType !== "data") return;
    if (
      e.column.caption !== "상차지" &&
      e.column.caption !== "작업경유지1" &&
      e.column.caption !== "작업경유지2" &&
      e.column.caption !== "작업경유지3" &&
      e.column.caption !== "작업지" &&
      e.column.caption !== "회수경유지1" &&
      e.column.caption !== "회수경유지2" &&
      e.column.caption !== "회수경유지3" &&
      e.column.caption !== "하차지"
    )
      return;

    if (e.column.caption === "상차지") {
      e.cellElement.style.background = e.data.loadPlaceColor;
    } else if (e.column.caption === "작업경유지1") {
      e.cellElement.style.background = e.data.workWaypointColor;
    } else if (e.column.caption === "작업경유지2") {
      e.cellElement.style.background = e.data.workWaypointColor2;
    } else if (e.column.caption === "작업경유지3") {
      e.cellElement.style.background = e.data.workWaypointColor3;
    } else if (e.column.caption === "작업지") {
      e.cellElement.style.background = e.data.workPlaceColor;
    } else if (e.column.caption === "회수경유지1") {
      e.cellElement.style.background = e.data.collectWaypointColor;
    } else if (e.column.caption === "회수경유지2") {
      e.cellElement.style.background = e.data.collectWaypointColor2;
    } else if (e.column.caption === "회수경유지3") {
      e.cellElement.style.background = e.data.collectWaypointColor3;
    } else if (e.column.caption === "하차지") {
      e.cellElement.style.background = e.data.unloadPlaceColor;
    }
  }, []);
  const onCellClick = useCallback(() => {}, []);
  const onFocusedRowChanged = useCallback((e) => {
    console.log(e);
  }, []);

  // cellRender

  const divisionRender = useCallback((e) => {
    if (e.value === "order") return <div>오더접수</div>;
    if (e.value === "lclorder") return <div>LCL오더</div>;
    if (e.value === "simple") return <div>간편배차</div>;
    if (e.value === "shuttle") return <div>셔틀배차</div>;
    if (e.value === "otherCo") return <div>용차배차</div>;
  }, []);

  const orderStatusRender = useCallback((e) => {
    if (e.data.longStorage === 1) {
      return <div>장기보관</div>;
    } else {
      return <div>{e.text}</div>;
    }
  }, []);
  const importExportRender = useCallback((e) => {
    if (e.row.data.importExport === 1) {
      return <div>수입</div>;
    } else {
      return <div>수출</div>;
    }
  }, []);

  return (
    <>
      <PanelInit>
        <PanelHeader>
          <HeaderTitle />
        </PanelHeader>
        <PanelBody row left="100%" right="0">
          <PanelGridWrapper>
            <PanelGridSearchBar onSubmit={handleSubmit(onSubmit)}>
              <SearchItems>
                <label>일정검색</label>
                <InputSelect
                  className="label-select"
                  control={control}
                  errors={errors}
                  name="searchType"
                  displayExpr="name"
                  valueExpr="id"
                  dataSource={[
                    { id: 2, name: "운송요청일자" },
                    { id: 1, name: "접수일자" },
                    { id: 3, name: "작업지도착시간" },
                  ]}
                  flex={true}
                />
                <InputDate
                  control={control}
                  errors={errors}
                  name="firstDay"
                  flex={true}
                />
                <div className="to-mark">~</div>
                <InputDate
                  control={control}
                  errors={errors}
                  name="lastDay"
                  flex={true}
                />
                {/* <input type="text" {...register("managerName")} /> */}
              </SearchItems>
              <CustomButton
                type="submit"
                height={24}
                layout="solid"
                color="search"
                label="검색"
              />
            </PanelGridSearchBar>
            <PanelGridInit>
              <StateStoringSaveAndDeleteButton
                dataGridRef={dataGridRef}
                storageKey={storageKey}
              />
              <DataCount>{`총 ${totalCount}건`}</DataCount>
              <DataGrid
                className="datagrid-wrap"
                id="DispatchMaster"
                dataSource={dataSource}
                ref={dataGridRef}
                showRowLines={true}
                showBorders={true}
                hoverStateEnabled={true}
                allowColumnResizing={true}
                allowColumnReordering={true}
                columnAutoWidth={true}
                columnResizingMode="widget"
                onRowDblClick={onRowDblClick}
                focusedRowEnabled={true}
                onCellPrepared={onCellPrepared}
                onCellClick={onCellClick}
                onFocusedRowChanged={onFocusedRowChanged}
                onContentReady={(e) => setTotalCount(e.component.totalCount())}
              >
                <FilterRow visible={true} />
                <HeaderFilter visible={true} />
                <ColumnChooser enabled={true} />
                <StateStoring
                  enabled={true}
                  type="custom"
                  customLoad={useCallback(() => {
                    return JSON.parse(localStorage.getItem(storageKey));
                  }, [])}
                />
                <Scrolling
                  columnRenderingMode="virtual"
                  mode="virtual"
                  showScrollbar="always"
                  useNative="false"
                />

                <Column dataField="orderCode" caption="접수번호" width="110" />
                <Column
                  dataField="containerNo"
                  caption="컨테이너 번호"
                  width="98"
                />
                <Column
                  dataField="division"
                  caption="구분"
                  cellRender={divisionRender}
                  alignment="center"
                  width="70"
                />
                <Column
                  dataField="orderStatus"
                  caption="상태"
                  cellRender={orderStatusRender}
                  alignment="center"
                  width="75"
                />
                <Column
                  dataField="importExport"
                  caption="수입/수출"
                  cellRender={importExportRender}
                  alignment="center"
                  width="95"
                />
                <Column
                  dataField="dispatchTeam"
                  caption="배차팀"
                  alignment="center"
                  width="80"
                />
                <Column
                  dataField="deliverDate"
                  caption="운송요청일자"
                  alignment="center"
                  dataType="date"
                  format="yyyy-MM-dd"
                  width="80"
                />
                <Column
                  dataField="loadPlace"
                  caption="상차지"
                  alignment="center"
                />
                <Column
                  dataField="workWaypoint"
                  caption="작업경유지1"
                  alignment="center"
                />
                <Column
                  dataField="workWaypoint2"
                  caption="작업경유지2"
                  alignment="center"
                />
                <Column
                  dataField="workWaypoint3"
                  caption="작업경유지3"
                  alignment="center"
                />
                <Column
                  dataField="workPlace"
                  caption="작업지"
                  alignment="center"
                />
                <Column
                  dataField="collectWaypoint"
                  caption="회수경유지1"
                  alignment="center"
                />
                <Column
                  dataField="collectWaypoint2"
                  caption="회수경유지2"
                  alignment="center"
                />
                <Column
                  dataField="collectWaypoint3"
                  caption="회수경유지3"
                  alignment="center"
                />
                <Column
                  dataField="unloadPlace"
                  caption="하차지"
                  alignment="center"
                />
              </DataGrid>
            </PanelGridInit>
          </PanelGridWrapper>
        </PanelBody>
      </PanelInit>
    </>
  );
}

export default DispatchStatusPage;
