import { DataGrid } from "devextreme-react";
import {
  Column,
  ColumnChooser,
  FilterRow,
  HeaderFilter,
  KeyboardNavigation,
  RequiredRule,
  Scrolling,
} from "devextreme-react/data-grid";
import CustomStore from "devextreme/data/custom_store";
import React, { useMemo, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { sendRequest } from "../../../../_metronic/_helpers/DataSoureHelpers";
import { DataCount } from "../../../components/Layout/Panel/PanelGrid/panelGrid.style";

export default function ComCodeMasterList({
  onFocusedRowChanged,
  dataGridRef,
}) {
  const [totalCount, setTotalCount] = useState(0);
  const { user } = useSelector(
    (state) => ({ user: state.auth.user.user }),
    shallowEqual
  );
  const dataSource = useMemo(() => {
    const apiUrl = `${process.env.REACT_APP_API_URL}/comcode/master`;
    return new CustomStore({
      key: "groupCode",
      load: () => sendRequest(apiUrl, "GET", { authGroupId: user.authGroupId }),
      insert: (values) => sendRequest(apiUrl, "POST", values),
      update: (key, values) =>
        sendRequest(apiUrl, "PUT", { key: key, values: values }),
      remove: (key) => sendRequest(apiUrl, "DELETE", { key: key }),
    });
  }, [user.authGroupId]);

  return (
    <>
      <DataCount>{`총 ${totalCount}건`}</DataCount>
      <DataGrid
        id="comCodeMaster"
        className="datagrid-wrap"
        dataSource={dataSource}
        ref={dataGridRef}
        showRowLines={true}
        allowColumnResizing={true}
        hoverStateEnabled={true}
        showBorders={true}
        // columnAutoWidth={true}
        focusedRowEnabled={true}
        onFocusedRowChanged={onFocusedRowChanged}
        columnResizingMode="widget"
        onContentReady={(e) => setTotalCount(e.component.totalCount())}
      >
        <FilterRow visible={true} />
        <KeyboardNavigation
          editOnKeyPress={true}
          enterKeyAction="moveFocus"
          enterKeyDirection="row"
        />
        <HeaderFilter visible={true} />
        <ColumnChooser enabled={true} />

        <Scrolling mode="virtual" />
        {/* <Column width={70} caption="No" cellRender={(e) => e.row.loadIndex + 1} /> */}
        <Column dataField="groupCode" caption="그룹코드">
          <RequiredRule />
        </Column>
        <Column dataField="groupName" caption="그룹코드명" />
      </DataGrid>
    </>
  );
}
