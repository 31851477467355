import axios from "axios";

const apiUrl = `${process.env.REACT_APP_API_URL}/Sys_CompanyTax_Info`;

export function Create(data) {
  return axios.post(apiUrl, data);
}
export function Read() {
  return axios.get(apiUrl);
}
export function Update(key, data) {
  return axios.put(apiUrl, { key, data });
}
export function Delete(key) {
  return axios.delete(apiUrl + `/${key}`);
}

export function GetLookup(param) {
  return axios.get(`${process.env.REACT_APP_API_URL}/${param}`);
}
