import { DataGrid } from "devextreme-react";
import { Column } from "devextreme-react/data-grid";
import CustomStore from "devextreme/data/custom_store";
import React, { useMemo } from "react";
import { sendRequest } from "../../../../_metronic/_helpers/DataSoureHelpers";

function OrderSummaryDetail({
  search,
  data,
  setDetailRowData,
  dataGridRef,
  detailGridRef,
  onFocusedRowChanged,
}) {
  const dataSource = useMemo(() => {
    const apiUrl = `${process.env.REACT_APP_API_URL}/order/summaryDetail`;

    return new CustomStore({
      key: "id",
      load: () => sendRequest(apiUrl, "POST", { data: data, search: search }),
    });
    // eslint-disable-next-line
  }, [search]);

  const onDetailCellClick = (e) => {
    if (!e.row) return;
    dataGridRef.current.instance.option("focusedRowIndex", -1);
    // setDetailRowData(e.row.data);
  };

  const divisionRender = (e) => {
    if (e.value === "order") return <div>오더접수</div>;
    if (e.value === "simple") return <div>간편배차</div>;
    if (e.value === "shuttle") return <div>셔틀배차</div>;
    if (e.value === "otherCo") return <div>용차배차</div>;
  };

  const priceCellRender = (e) => {
    if (!e.text) return <div>0</div>;
    return <div>{e.text.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</div>;
  };

  return (
    <DataGrid
      id="orderSummaryDetail"
      keyExpr="id"
      key="id"
      showBorders={true}
      columnAutoWidth={true}
      dataSource={dataSource}
      ref={detailGridRef}
      focusedRowEnabled={true}
      onFocusedRowChanged={onFocusedRowChanged}
      allowColumnResizing={true}
      columnResizingMode="widget"
      onCellClick={onDetailCellClick}
      width="70vw"
    >
      <Column dataField="code" caption="접수번호" />
      <Column dataField="division" caption="구분" cellRender={divisionRender} />
      <Column dataField="subName" caption="상태" />
      <Column dataField="cargoOwnerName" caption="화주" />
      <Column dataField="containerNo" caption="컨테이너No" />
      <Column dataField="sealNo" caption="실No" />
      <Column dataField="containerType" caption="컨/규격" />
      <Column
        dataField="freight"
        caption="청구금액"
        cellRender={priceCellRender}
        alignment="right"
      />
      <Column dataField="extraCharge" caption="할증금액" alignment="right" />
      <Column dataField="habul" caption="실제하불금액" alignment="right" />
    </DataGrid>
  );
}

export default OrderSummaryDetail;
