import { DataGrid } from "devextreme-react";
import {
  Column,
  ColumnChooser,
  Editing,
  FilterRow,
  HeaderFilter,
  Scrolling,
  StateStoring,
} from "devextreme-react/data-grid";
import CustomStore from "devextreme/data/custom_store";
import React, { useCallback, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { sendRequest } from "../../../../_metronic/_helpers/DataSoureHelpers";
import StateStoringSaveAndDeleteButton from "../../../components/StateStoring";

function MainGrid({ onFocusedRowChanged, dataGridRef }) {
  const [totalCount, setTotalCount] = useState(0);
  const { register, handleSubmit } = useForm();
  const [search, setSearch] = useState({});
  const storageKey = "companyTaxInfoMaster";

  const dataSource = useMemo(() => {
    const apiUrl = `${process.env.REACT_APP_API_URL}/Sys_CompanyTax_Info`;

    return new CustomStore({
      key: "Biz_No",
      load: () => sendRequest(apiUrl, "GET", search),
      insert: (values) => sendRequest(apiUrl, "POST", values),
      update: (key, values) => sendRequest(apiUrl, "PUT", { key, values }),
      remove: (key) => sendRequest(apiUrl, "DELETE", key),
    });
  }, [search]);

  const onSubmit = (data) => {
    for (const obj in data) {
      if (data[obj] === "") {
        data[obj] = null;
      }
    }
    setSearch(data);
  };

  return (
    <>
      <div className="grid-search-wrap">
        <form onSubmit={handleSubmit(onSubmit)}>
          <span className="search-group">
            <label>사업자번호</label>
            <input type="text" {...register("Biz_No")} />
          </span>
          <span className="search-group">
            <label>회사명</label>
            <input type="text" {...register("Company_Name")} />
          </span>
          <span className="search-group">
            <label>대표자명</label>
            <input type="text" {...register("Ceo_Name")} />
          </span>
          <button type="submit" className="btn btn-grid-search">
            검색
          </button>
        </form>
      </div>
      <div className="grid-component has-toolbar">
        <StateStoringSaveAndDeleteButton
          dataGridRef={dataGridRef}
          storageKey={storageKey}
        />
        <div className="grid-total">{`총 ${totalCount}건`}</div>
        <DataGrid
          className="datagrid-wrap"
          id="dg_List"
          repaintChangesOnly={true}
          ref={dataGridRef}
          dataSource={dataSource}
          showRowLines={true}
          showBorders={true}
          hoverStateEnabled={true}
          allowColumnResizing={true}
          allowColumnReordering={true}
          columnResizingMode="widget"
          keyExpr="Biz_No"
          focusedRowEnabled={true}
          columnAutoWidth={true}
          onFocusedRowChanged={onFocusedRowChanged}
          onContentReady={(e) => setTotalCount(e.component.totalCount())}
          // onExporting={onExporting}
        >
          {/* <Export
            enabled={true}
            excelFilterEnabled={true}
            fileName="세금계산서 정보"
            customizeExcelCell={customizeExcelCell}
          /> */}
          <FilterRow visible={true} />
          <HeaderFilter visible={true} />
          <ColumnChooser enabled={true} allowSearch={true} />
          <StateStoring
            enabled={true}
            type="custom"
            customLoad={useCallback(() => {
              return JSON.parse(localStorage.getItem(storageKey));
            }, [])}
          />
          <Scrolling columnRenderingMode="virtal" />
          <Editing />
          <Column
            caption="No"
            cellRender={(e) => e.row.loadIndex + 1}
            width="50"
            alignment="center"
          />
          <Column dataField="Biz_No" caption="사업자등록번호" width="100" />
          <Column dataField="Company_Name" caption="회사명" width="150" />
          <Column dataField="Ceo_Name" caption="대표자명" width="100" />
          <Column dataField="Zip_Code" caption="우편번호" width="90" />
          <Column dataField="Address" caption="주소" width="150" />
          <Column dataField="Address_Detail" caption="상세주소" width="150" />
          <Column
            dataField="Biz_Type"
            caption="업태"
            alignment="center"
            width="150"
          />
          <Column
            dataField="Biz_Item"
            caption="종목"
            alignment="center"
            width="150"
          />
          <Column dataField="Tel" caption="전화번호" width="150" />
          <Column dataField="HP" caption="휴대전화" width="150" />
          <Column dataField="Fax" caption="팩스" width="150" />
          <Column dataField="Email" caption="이메일" width="150" />
          <Column
            dataField="Remark"
            caption="비고"
            alignment="center"
            width="150"
          />
        </DataGrid>
      </div>
    </>
  );
}

export default MainGrid;
