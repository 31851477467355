import styled from "styled-components";

export const AsideWrapper = styled.section`
  grid-area: AS;
  overflow: hidden;
  position: relative;
  visibility: visible;
  background: ${(props) => props.theme.colors.white};
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.15);
  z-index: 10;

  &.default {
    .brand-logo-txt {
      display: none;
    }
  }
`;

export const AsideHeader = styled.div`
  display: flex;
  width: 220px;
  height: 40px;
  padding: 0 10px;
  align-items: center;
  justify-content: flex-start;

  .brand-logo-icon {
    width: 20px;
    height: 20px;
  }

  .brand-logo-txt {
    width: 140px;
    height: 26px;
    margin-top: 3px;
    margin-left: 8px;
  }
`;

export const AsideMenuBtn = styled.button`
  display: flex;
  padding: 3px 5px;
  border: none;
  background: none;
  border-radius: 4px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  &:hover {
    background: rgba(0, 0, 0, 0.4);
    cursor: pointer;
    transition: all 0.2s ease-in-out;
  }

  svg {
    rect {
      fill: ${(props) => props.theme.colors.asideTxt};
    }
  }
`;

export const AsideNavigator = styled.nav`
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  max-height: calc(100vh - 40px);
  flex-direction: column;
  font-size: 1.4rem;
  font-weight: 400;
  border-top: 1px solid ${(props) => props.theme.colors.asideBorder};
  border-bottom: 1px solid ${(props) => props.theme.colors.asideBorder};

  .m-dept01 {
    position: relative;
    display: flex;
    height: 44px;
    padding: 0 20px
    color: ${(props) => props.theme.colors.third};
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
  }

  ::-webkit-scrollbar {
    width: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background-clip: padding-box;
    background-color: ${(props) => props.theme.colors.scrollBar};
    border: 2px solid transparent;
    border-radius: 10px;

    &:hover {
      background-color: ${(props) => props.theme.colors.scrollBarHover};
    }
  }

  ::-webkit-scrollbar-track {
    background-color: transparent;
  }
`;

export const AsideMenuList = styled.ul`
  &.list-closed {
    .list-dept02 {
      display: none;
    }
  }

  &.list-dept02 {
    overflow: hidden;

    &.closed {
      max-height: 0;
      transition: max-height 0.2s ease-in-out;
    }

    &.opened {
      border-top: 1px solid ${(props) => props.theme.colors.asideBorder};
      border-bottom: 1px solid ${(props) => props.theme.colors.asideBorder};
      max-height: 500px;
      transition: max-height 0.2s ease-in-out;
    }

    .menu-link {
      padding: 0 20px;
      background: ${(props) => props.theme.colors.asideDept02Bg};

      &:hover {
        color: ${(props) => props.theme.colors.third};
        background: ${(props) => props.theme.colors.asideHoverBg};
      }
    }
  }
`;

export const AsideMenuDept = styled.li`
  &.menu-active {
    & > button {
      color: ${(props) => props.theme.colors.white};
      background: ${(props) => props.theme.colors.secondary};

      svg {
        g {
          path {
            fill: ${(props) => props.theme.colors.white} !important;
          }
        }
      }

      .menu-link {
        color: ${(props) => props.theme.colors.white};
      }
    }
  }

  &.menu-item-active {
    svg {
      g {
        path {
          fill: ${(props) => props.theme.colors.secondary} !important;
        }
      }
    }

    .menu-link {
      color: ${(props) => props.theme.colors.secondary};
      background: ${(props) => props.theme.colors.asideDept01HoverBg};
    }
  }
`;

export const MenuItem = styled.button`
  display: flex;
  width: 220px;
  height: 36px;
  padding: 0 10px;
  letter-spacing: -0.07rem;
  border: none;
  background: none;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;

  .menu-content {
    display: flex;
    align-items: center;
    gap: 10px;

    .menu-icon {
      svg {
        width: 20px;
        height: 20px;

        g {
          path {
            fill: ${(props) => props.theme.colors.asideIconDefault};
          }
        }
      }
    }

    .menu-txt {
      font-size: 1.3rem;
    }
  }

  .arrow-icon {
    width: 20px;
    height: 20px;

    g {
      path {
        fill: ${(props) => props.theme.colors.asideIconDefault} !important;
      }
    }
  }

  &.dept-01,
  &.dept-02 {
    &:hover {
      background: ${(props) => props.theme.colors.asideDept01HoverBg};

      .menu-content {
        .menu-txt {
          color: ${(props) => props.theme.colors.secondary};
        }

        svg {
          g {
            path {
              fill: ${(props) => props.theme.colors.secondary} !important;
            }
          }
        }
      }

      .arrow-icon {
        g {
          path {
            fill: ${(props) => props.theme.colors.secondary} !important;
          }
        }
      }
    }
  }
`;
