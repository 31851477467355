import { useEffect, useState } from "react";
import { getLookup } from "./OrderCrud";

const useOrderPageLookup = () => {
  const [orderSearchLookupData, setOrderSearchLookupData] = useState({
    dispatch: [],
    company: [],
  });

  const [conType, setConType] = useState([]);
  const [orderInputLookupData, setOrderInputLookupData] = useState({
    billing: [],
    cargoOwner: [],
    orderCo: [],
    forwarder: [],
    terminal: [],
    company: [],
    isDispatch: [],
    department: [],
    color: [],
  });

  const [orderInputDetailLookup, setOrderInputDetailLookup] = useState({
    workPlace: [],
    waypoint: [],
    conType: [],
  });

  useEffect(() => {
    // orderSearch 룩업데이터
    initOrderSearchLookupData();

    // orderInput 룩업데이터
    initOrderInputLookupData();

    // orderInputDetail 룩업데이터
    initOrderInputDetailLookupData();
  }, []);

  const initOrderSearchLookupData = async () => {
    try {
      const [dispatchData, companyData] = await Promise.all([
        getLookup("department/isDispatch"),
        getLookup("company"),
      ]);

      setOrderSearchLookupData({
        dispatch: [{ code: "", deptName: "전체" }, ...dispatchData.data],
        company: [{ id: "", companyName: "전체" }, ...companyData.data],
      });
    } catch (error) {
      console.log(error);
    }
  };

  const initOrderInputLookupData = async () => {
    // 업체,
    try {
      const [
        billingplaceData,
        cargoownerData,
        ordercoData,
        forwarderData,
        terminalData,
        companyData,
        isDispatchData,
        departmentData,
        conTypeData,
        colorData,
      ] = await Promise.all([
        getLookup("companyManage/lookup/isBilling"),
        getLookup("companyManage/lookup/isCargoOwner"),
        getLookup("companyManage/lookup/isOrderCo"),
        getLookup("forwarder/lookup"),
        getLookup("terminal/terminal"),
        getLookup("company"),
        getLookup("department/isDispatch"),
        getLookup("department/lookup"),
        getLookup("comcode/detail/10400"),
        getLookup("comcode/detail/10900"),
      ]);

      setConType(conTypeData.data);

      setOrderInputLookupData({
        billing: billingplaceData.data,
        cargoOwner: cargoownerData.data,
        orderCo: ordercoData.data,
        forwarder: forwarderData.data,
        terminal: terminalData.data,
        company: companyData.data,
        isDispatch: isDispatchData.data,
        department: departmentData.data,
        color: [{ subCode: null, subName: "" }, ...colorData.data],
      });
    } catch (err) {
      console.log(err);
    }
  };

  const initOrderInputDetailLookupData = async () => {
    try {
      const [workPlaceData, waypointData, conTypeData] = await Promise.all([
        getLookup("terminal/workplace"),
        getLookup("terminal/waypoint"),
        getLookup("comcode/detail/10400"),
      ]);

      setOrderInputDetailLookup({
        workPlace: [{ code: null, twName: "" }, ...workPlaceData.data],
        waypoint: [{ code: null, twName: "" }, ...waypointData.data],
        conType: conTypeData.data,
      });
    } catch (err) {
      console.log(err);
    }
  };

  return {
    // search
    orderSearchLookupData,

    // input
    conType,
    orderInputLookupData,
    initOrderInputLookupData,

    // input detail
    orderInputDetailLookup,
    initOrderInputDetailLookupData,
  };
};

export default useOrderPageLookup;
