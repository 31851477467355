import axios from "axios";

const apiUrl = `${process.env.REACT_APP_API_URL}/shuttlesimple`;
const apiUrlLookup = `${process.env.REACT_APP_API_URL}`;

export function getLookup(param) {
  return axios.get(apiUrlLookup + `/${param}`);
}

export function createShuttleSimple(data) {
  return axios.post(apiUrl, data);
}

export function updateShuttleSimple(key, data) {
  return axios.put(apiUrl, { key, data });
}

export function deleteRow(key) {
  return axios.delete(apiUrl + `/${key}`);
}
