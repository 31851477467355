import React from "react";
import HeaderTitle from "../../../components/Layout/Header/HeaderTitle";
import { PanelGridWrapper } from "../../../components/Layout/Panel/PanelGrid/panelGrid.style";
import {
  PanelBody,
  PanelHeader,
  PanelInit,
} from "../../../components/Layout/Panel/panel.style";
import DispatchNewGrid from "./components/DispatchNewGrid";
import DispatchNewInput from "./components/DispatchNewInput";
import DispatchNewSearch from "./components/DispatchNewSearch";
import useDispatchNew from "./useDispatchNew";
import FileSelectModal from "./components/FileSelectModal";
import FileListModal from "./components/FileListModal";
import { useEffect } from "react";
import { getLookupDatas } from "./DispatchNewCrud";
import { useState } from "react";
import LogModal from "../../../components/LogModal/LogModal";

function DispatchNew(props) {
  const [companyData, setCompanyData] = useState([]);
  const [terminalData, setTerminalData] = useState([]);
  const [openDispatchLogModal, setOpenDispatchLogModal] = useState(false);
  const {
    state,
    lookupData,
    dataGridRef,
    detailGridRef,

    onSubmit,
    dataSource,

    searchForm,
    onToggleInputBar,

    gridProps,
    inputProps,
    modalProps,
    fileModalProps,
  } = useDispatchNew();

  const { isModalOpen, isFileListModalOpen, rowData, openInputBar } = state;

  useEffect(() => {
    getLookupDatas("companyManage/lookup").then(({ data }) => {
      setCompanyData(data);
    });

    getLookupDatas("terminal").then(({ data }) => {
      setTerminalData(data);
    });
  }, []);

  return (
    <>
      <PanelInit>
        <PanelHeader>
          <HeaderTitle />
        </PanelHeader>

        <PanelBody
          row
          left="800px"
          right="calc(100% - 800px)"
          openInputBar={openInputBar}
        >
          {/* 새 배차등록 Input */}
          <DispatchNewInput
            rowData={rowData}
            openInputBar={openInputBar}
            dataGridRef={dataGridRef}
            inputProps={inputProps}
            setOpenDispatchLogModal={setOpenDispatchLogModal}
          />

          <PanelGridWrapper>
            <DispatchNewSearch
              lookupData={lookupData}
              searchForm={searchForm}
              onToggleInputBar={onToggleInputBar}
              onSubmit={onSubmit}
            />

            <DispatchNewGrid
              lookupData={lookupData}
              dataGridRef={dataGridRef}
              detailGridRef={detailGridRef}
              dataSource={dataSource}
              gridProps={gridProps}
              // lookup data
              companyData={companyData}
              terminalData={terminalData}
            />
          </PanelGridWrapper>
        </PanelBody>
      </PanelInit>
      {isModalOpen && <FileSelectModal state={state} modalProps={modalProps} />}
      {isFileListModalOpen && <FileListModal fileModalProps={fileModalProps} />}

      {openDispatchLogModal && (
        <LogModal
          onClose={() => setOpenDispatchLogModal(false)}
          type="dispatch"
          visible={true}
          maskClosable={false}
          rowData={rowData ?? {}}
        />
      )}
    </>
  );
}

export default DispatchNew;
