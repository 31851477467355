import { yupResolver } from "@hookform/resolvers/yup";
import { alert } from "devextreme/ui/dialog";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Modal, ModalBody } from "react-bootstrap";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import InputTextArea from "../../../app/components/InputForm/InputTextArea";
import { postNofication } from "../../../app/pages/DongApp/PushApp/PushAppCrud";
export function PushAppModal({ onClose, visible, maskClosable, dataGridRef }) {
  /* #region useState, useRef -----------------------------------------------------------------------*/
  const [isLoading, setIsLoading] = useState(false);
  /* #region end */

  /* #region initialState, react-hook-form, yup ---------------------------------------------------- */
  const initialState = {
    startDate: moment().format("YYYY-MM-DD"),
    endDate: moment().format("YYYY-MM-DD"),
    timeSchedule: moment().format("HH:mm:ss"),
    contents: "",
    remark: undefined,
  };

  const schema = yup
    .object({
      contents: yup
        .string()
        .nullable()
        .required("내용을 선택해주세요"),
    })
    .required();

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  /* #endregion */

  /* #region useEffect ----------------------------------------------------------------------------- */
  useEffect(() => {
    //getLookup();
    reset(initialState);
    // eslint-disable-next-line
  }, []);
  /* #endregion */

  const onMaskClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  const onSubmit = async (data) => {
    try {
      setIsLoading(true);
      const response = await postNofication(data);
      const message = response.data.result.message;
      const code = response.data.result.code;
      dataGridRef.current.instance.refresh();
      dataGridRef.current.instance.option("focusedRowIndex", -1);
      if (code === 1) {
        alert(message, "성공");
      } else {
        alert(message, "실패");
      }
      onClose();
      reset(initialState);
    } catch (err) {
      alert(err.response?.data?.message || "ERROR", "오류");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <div className={"modal-overlay " + (visible ? "active" : "")} />
      <div
        className={"modal-wrap user-list-modal " + (visible ? "active" : "")}
        onClick={maskClosable ? onMaskClick : null}
        tabIndex="-1"
        visible={`${visible}`}
      >
        <Modal.Dialog centered size="sm" animation="true">
          <ModalBody>
            <div className="modal-fomr">
              <div className="modal-form-header">
                <div className="modal-form-tit">
                  <span className="modal-tit">단건 푸쉬</span>
                </div>
              </div>
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="grid-component">
                <InputTextArea
                  control={control}
                  errors={errors}
                  height={80}
                  name="contents"
                  label="내용"
                />
              </div>
              <div className="modal-form-footer order-modal">
                <button
                  type="button"
                  className="btn btn-modal btn-close"
                  onClick={onClose}
                >
                  닫기
                </button>

                <button
                  type="submit"
                  className={`btn btn-form-save ${isLoading &&
                    "spinner spinner-white spinner-right"}`}
                >
                  등록
                </button>
              </div>
            </form>
          </ModalBody>
        </Modal.Dialog>
      </div>
    </>
  );
}
