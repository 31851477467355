import { yupResolver } from "@hookform/resolvers/yup";
import { alert, confirm } from "devextreme/ui/dialog";
import React, { useRef, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import * as requestServer from "./CompanyCrud";

import { useCallback } from "react";
import { SelectModal } from "../../../../_metronic/_partials/modals/SelectModal";
import FunctionButton from "../../../components/FunctionButton/FunctionButton";
import HeaderTitle from "../../../components/Layout/Header/HeaderTitle";
import {
  PanelBody,
  PanelBtn,
  PanelHeader,
  PanelInit,
} from "../../../components/Layout/Panel/panel.style";
import { useDatagrid } from "../../../hooks/useDatagrid";
import CompanyMaster from "./CompanyMaster";
import NewCompanyModal from "./components/NewCompanyModal";

export default function CompanyPage() {
  const dataGridRef = useRef();
  useDatagrid(dataGridRef);

  const [selectedRowKey, setSelectedRowKey] = useState();
  const [newCompanyModal, setNewCompanyModal] = useState(false);
  const [updateCompanyModal, setUpdateCompanyModal] = useState(false);
  const [selectModal, setSelectModal] = useState(false);

  const schema = yup.object({
    companyName: yup
      .string()
      .required("법인명을 입력해주세요")
      .max(50, "최대 50자리 까지 입력 가능"),
    Biz_No: yup.string().max(20, "최대 20자리 까지 입력 가능"),
    remark: yup.string().max(100, "최대 100자리 까지 입력 가능"),
  });

  const { setValue, reset } = useForm({
    resolver: yupResolver(schema),
  });

  const onClickDelete = () => {
    if (!dataGridRef.current || !selectedRowKey) return;
    let response = confirm("<i>삭제하시겠습니까?</i>", "삭제 메세지");

    response.then((res) => {
      if (!res) return;
      requestServer
        .deleteRow(selectedRowKey)
        .then(() => {
          dataGridRef.current.instance.refresh();
        })
        .catch((err) => {
          alert(err.response.data.message, "오류");
        });
    });
  };

  const onFocusedRowChanged = (e) => {
    if (!e.row) return;
    const data = e.row.data;
    const joinData = data.Biz_No_Sys_CompanyTax_Info;

    setSelectedRowKey(e.row.key);
    reset({ companyName: data.companyName, Biz_No: data.Biz_No, ...joinData });
  };

  const closeModal = () => {
    setSelectModal(false);
    dataGridRef.current.instance.refresh();
  };

  const onRowDblClick = useCallback((e) => {
    setSelectedRowKey(e.key);
    setUpdateCompanyModal(true);
  }, []);

  const onSelect = (rowData) => {
    setValue("Biz_No", rowData.Biz_No);
    setValue("Ceo_Name", rowData.Ceo_Name);
    setValue("Zip_Code", rowData.Zip_Code);
    setValue("Address", rowData.Address);
    setValue("Address_Detail", rowData.Address_Detail);
    setValue("Biz_Type", rowData.Biz_Type);
    setValue("Biz_Item", rowData.Biz_Item);
    setValue("Tel", rowData.Tel);
    setValue("Fax", rowData.Fax);
    setValue("Email", rowData.Email);

    setSelectModal(false);
  };

  return (
    <>
      <PanelInit>
        <PanelHeader>
          <HeaderTitle />

          <PanelBtn>
            <FunctionButton
              // onClickAdd={onClickAdd}
              onClickAdd={() => setNewCompanyModal(true)}
              onClickDelete={onClickDelete}
            />
          </PanelBtn>
        </PanelHeader>

        <PanelBody row left="100%" right="0">
          <CompanyMaster
            onFocusedRowChanged={onFocusedRowChanged}
            onRowDblClick={onRowDblClick}
            dataGridRef={dataGridRef}
          />
        </PanelBody>
      </PanelInit>
      {newCompanyModal && (
        <NewCompanyModal
          onClose={() => setNewCompanyModal(false)}
          closable={true}
          visible={true}
          maskClosable={false}
          dataGrid={dataGridRef}
        />
      )}

      {updateCompanyModal && (
        <NewCompanyModal
          onClose={() => setUpdateCompanyModal(false)}
          closable={true}
          visible={true}
          maskClosable={false}
          dataGrid={dataGridRef}
          oid={selectedRowKey}
        />
      )}

      {selectModal && (
        <SelectModal
          onClose={closeModal}
          closable={true}
          visible={true}
          maskClosable={false}
          onSelect={onSelect}
          param="/Sys_CompanyTax_Info"
          keyExpr="Biz_No"
        />
      )}
    </>
  );
}
