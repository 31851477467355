import {
  Column,
  ColumnFixing,
  DataGrid,
  FilterRow,
  Format,
  HeaderFilter,
  Paging,
  Scrolling,
  Selection,
} from "devextreme-react/data-grid";
import CustomStore from "devextreme/data/custom_store";
import { alert } from "devextreme/ui/dialog";
import moment from "moment";
import React, { useMemo, useRef, useState } from "react";
import { Modal, ModalBody } from "react-bootstrap";
import { useForm } from "react-hook-form";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";
import { sendRequest } from "../../../../../_metronic/_helpers/DataSoureHelpers";
import InputDate from "../../../../components/Form/InputDate";
import CustomButton from "../../../../components/FunctionButton/CustomButton";
import InputSelect from "../../../../components/InputForm/InputSelect";
import {
  PanelGridSearchBar,
  SearchItems,
} from "../../../../components/Layout/Panel/PanelGrid/panelGrid.style";
import { connectTaxBill } from "../AccountHistoryCrud";

function AccountHistoryModal({ onClose, maskClosable, visible, dataGrid }) {
  const initialSearchState = {
    firstDay: moment()
      .subtract(7, "days")
      .format("YYYY-MM-DD"),
    lastDay: moment().format("YYYY-MM-DD"),
    searchType: "1",
  };
  const [search, setSearch] = useState(initialSearchState);
  const [selectedRow, setSelectedRow] = useState({
    companyName: "",
    priceTotal: "0",
  });
  const [selectedPrice, setSelectedPrice] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const dataGridRef = useRef();

  const dataSource = useMemo(() => {
    const apiUrl = `${process.env.REACT_APP_API_URL}/account-list/connection`;

    return new CustomStore({
      key: "completeNo",
      load: () => sendRequest(apiUrl, "GET", search),
    });
  }, [search]);

  const onMaskClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: initialSearchState,
  });

  const onClickSend = async () => {
    const TransRefKey = dataGrid.current.instance.option("focusedRowKey");
    const selectedTaxBill = dataGridRef.current.instance.getSelectedRowsData();

    if (selectedTaxBill.length === 0) {
      alert("데이터를 선택해주세요", "확인");
      return;
    }

    try {
      await connectTaxBill({
        TransRefKey: TransRefKey,
        selectedTaxBill: selectedTaxBill,
      });

      onClose();
      dataGridRef.current.instance.refresh();
    } catch (error) {
      console.log(error);
    }
  };

  const onFocusedRowChanged = (e) => {
    const data = { ...e.row.data };

    setSelectedRow({
      companyName: data.companyName,
      priceTotal: Number(data.priceTotal).toLocaleString("ko-KR"),
    });
  };

  const onSelectionChanged = (e) => {
    const { selectedRowsData } = e;

    let priceTotal = 0;

    for (const obj of selectedRowsData) {
      priceTotal += Number(obj.priceTotal) || 0;
    }

    setSelectedPrice(priceTotal);
  };

  const onToolbarPreparing = (e) => {
    const items = e.toolbarOptions.items;
    for (const item of items) {
      if (item.name === "addRowButton") {
        // item.options.onClick = () => setSellCompleteModalVisible(true);
      }
    }
  };

  const onSubmit = (data) => {
    setSearch(data);
  };

  return (
    <>
      <div className={"modal-overlay " + (visible ? "active" : "")} />
      <div
        className={"modal-wrap user-list-modal " + (visible ? "active" : "")}
        onClick={maskClosable ? onMaskClick : null}
        tabIndex="-1"
        visible={`${visible}`}
      >
        <Modal.Dialog centered tabIndex="0" size="xl" animation="true">
          <ModalBody>
            <div className="modal-form">
              <div className="modal-form-header">
                <div className="modal-form-tit">
                  <span className="modal-tit">입출금 정보</span>
                </div>
                <button
                  type="button"
                  className="btn-modal btn-modal-close"
                  onClick={onClose}
                >
                  <SVG
                    src={toAbsoluteUrl(
                      "/media/svg/icons/Custom/close_black_24dp.svg"
                    )}
                    className="close-icon"
                  />
                </button>
              </div>

              {/* <div className="grid-search-wrap">
                <form onSubmit={handleSubmit(onSubmit)}> */}
              <PanelGridSearchBar onSubmit={handleSubmit(onSubmit)}>
                <SearchItems>
                  <label>날짜구분</label>
                  <InputSelect
                    className="label-select"
                    control={control}
                    errors={errors}
                    dataSource={[
                      { id: "0", name: "전체" },
                      { id: "1", name: "마감일자" },
                    ]}
                    valueExpr="id"
                    displayExpr="name"
                    name="searchType"
                    flex={true}
                  />
                  <InputDate
                    className="label-select"
                    control={control}
                    errors={errors}
                    name="firstDay"
                    flex={true}
                  />
                  ~
                  <InputDate
                    className="label-select"
                    control={control}
                    errors={errors}
                    name="lastDay"
                    flex={true}
                  />
                </SearchItems>
                <CustomButton
                  type="submit"
                  height={24}
                  layout="solid"
                  color="search"
                  label="검색"
                />
              </PanelGridSearchBar>

              {/* </form>
              </div> */}

              <div className="modal-form-body">
                <div
                  style={{
                    textAlign: "right",
                    fontSize: "14px",
                  }}
                >
                  상대명:{" "}
                  <span
                    style={{
                      width: "50px",
                      minWidth: "50px",
                      marginRight: "10px",
                    }}
                  >
                    {selectedRow.companyName}
                  </span>
                  출입금가능금액:{" "}
                  <span
                    style={{
                      width: "50px",
                      minWidth: "50px",
                    }}
                  >
                    {selectedRow.priceTotal}
                  </span>
                </div>
                <div className="grid-total">{`총 ${totalCount}건`}</div>
                <DataGrid
                  className="datagrid-wrap single-line has-toolbar"
                  id="AccountHistoryMaster"
                  ref={dataGridRef}
                  dataSource={dataSource}
                  showRowLines={true}
                  showBorders={true}
                  hoverStateEnabled={true}
                  allowColumnResizing={true}
                  allowColumnReordering={true}
                  columnResizingMode="widget"
                  keyExpr="completeNo"
                  focusedRowEnabled={true}
                  onFocusedRowChanged={onFocusedRowChanged}
                  onToolbarPreparing={onToolbarPreparing}
                  onSelectionChanged={onSelectionChanged}
                  onContentReady={(e) =>
                    setTotalCount(e.component.totalCount())
                  }
                >
                  <ColumnFixing enabled={true} />
                  <FilterRow visible={true} />
                  <HeaderFilter visible={true} />
                  <Scrolling showScrollbar="always" useNative="false" />
                  <Paging enabled={true} pageSize={25} />
                  <Selection
                    mode="multiple"
                    allowSelectAll={false}
                    showCheckBoxesMode="always"
                  />
                  <Column dataField="division" caption="구분" width="47" />
                  <Column
                    dataField="companyName"
                    caption="청구처/용차사/차량번호"
                    width="134"
                  />
                  {/* 접수법인 */}
                  <Column
                    dataField="receiveCoName"
                    caption="접수법인"
                    width="134"
                  />
                  {/* 마감번호 */}
                  <Column
                    dataField="completeNo"
                    caption="마감번호"
                    width="134"
                  />
                  {/* 마감일자 */}
                  <Column
                    dataField="completeDate"
                    caption="마감일자"
                    width="134"
                  />
                  {/* 상세건수 */}
                  <Column
                    dataField="detailTotal"
                    caption="상세건수"
                    width="100"
                  />
                  {/* 정산상태 */}
                  <Column
                    dataField="closeStatus"
                    caption="정산상태"
                    width="100"
                  />
                  {/* 정산예정일자 */}
                  <Column
                    dataField="taxSchedule"
                    caption="정산일자"
                    width="100"
                    dataType="date"
                    format="yyyy-MM-dd"
                  />
                  {/* 세금계산서번호 */}
                  <Column
                    dataField="finishNo"
                    caption="세금계산서번호"
                    width="150"
                  />
                  {/* 계산서상태 */}
                  <Column
                    dataField="taxStatus"
                    caption="계산서상태"
                    width="100"
                  />
                  {/* 금액 */}
                  <Column
                    dataField="price"
                    caption="금액"
                    dataType="number"
                    width="130"
                  >
                    <Format type="fixedPoint" />
                  </Column>
                  {/* 세액 */}
                  <Column
                    dataField="taxTotal"
                    caption="세액"
                    dataType="number"
                    width="130"
                  >
                    <Format type="fixedPoint" />
                  </Column>
                  {/* 합계금액 */}
                  <Column
                    dataField="priceTotal"
                    caption="합계금액"
                    dataType="number"
                    width="150"
                  >
                    <Format type="fixedPoint" />
                  </Column>
                </DataGrid>
                <div
                  style={{
                    textAlign: "right",
                    fontSize: "14px",
                  }}
                >
                  소계: {selectedPrice.toLocaleString("ko-KR")}
                </div>
              </div>
            </div>
            <div className="modal-form-footer order-modal">
              <button
                onClick={onClickSend}
                type="button"
                className="btn btn-modal btn-save"
              >
                확인
              </button>
            </div>
          </ModalBody>
        </Modal.Dialog>
      </div>
      {/* {sellCompleteModalVisible && (
        <SellCompleteModal
          onClose={closeModal}
          closable={true}
          visible={true}
          maskClosable={false}
          onSelect={onSelectSellComplete}
          sellComplete={sellComplete}
          // TransRefKey={rowData.TransRefKey}
        />
      )} */}
    </>
  );
}

export default AccountHistoryModal;
