import { alert } from "devextreme/ui/dialog";
import React, { useCallback, useEffect, useReducer, useRef } from "react";
import { sendRequest } from "../../../../_metronic/_helpers/DataSoureHelpers";
import { calculateTotalCharge } from "../../../utils/orderFunctions";

const initialState = {
  detailRowData: {},
  detailRowDataKey: null,

  extraChargeData: [],
  etcChargeHabulData: [],

  extraChargeModal: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "SET_EXTRA_CHARGE_MODAL":
      return {
        ...state,
        extraChargeModal: action.payload,
      };

    case "SET_EXTRA_CHARGE_DATA":
      return {
        ...state,
        extraChargeData: action.payload.extraCharge,
        etcChargeHabulData: action.payload.etcChargeHabul,
      };

    case "SET_DETAIL_ROW_DATA":
      return {
        ...state,
        detailRowData: action.payload.detailRowData,
        detailRowDataKey: action.payload.detailRowDataKey,
      };

    default:
      throw new Error("Unhandled action");
  }
};

/**
 * 할증등록 관련 변수, 함수 커스텀 훅
 * @param {*} detailGrid 디테일그리드 Ref
 * @param {*} isConfirm 오더확정 데이터
 * @returns
 */
const useExtraCharge = (detailGrid, isConfirm) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const { detailRowDataKey, extraChargeModal } = state;

  // 할증금액, 기타하불 refs
  const extraChargeRef = useRef();
  const etcChargeHabulRef = useRef();

  useEffect(() => {
    if (extraChargeModal) {
      setTimeout(() => _setExtraCharge(), 200);
    }
    // eslint-disable-next-line
  }, [extraChargeModal]);

  /**
   * 할증청구, 기타하불 데이터 불러오는 함수
   * @param {*} id
   */
  const _getExtraChargeData = async (id) => {
    const apiUrl = `${process.env.REACT_APP_API_URL}/extraCharge`;
    const data = await sendRequest(apiUrl, "GET", {
      orderDetailCode: id || "9999999",
    });

    dispatch({
      type: "SET_EXTRA_CHARGE_DATA",
      payload: data,
    });
  };

  /** ExtraCharge 모달 open시 데이터 binding */
  const _setExtraCharge = () => {
    const detailGridData = detailGrid.current.instance.option(
      "editing.changes"
    );

    // instance
    const extraChargeInstance = extraChargeRef.current.instance;
    const etcChargeHabulInstance = etcChargeHabulRef.current.instance;

    for (const obj of detailGridData) {
      if (obj.key !== detailRowDataKey) continue;

      const extraChargeData = obj.data.extraChargeObj;
      const etcChargeHabulData = obj.data.etcChargeHabulObj;

      _bindingDatas(extraChargeData, extraChargeInstance);
      _bindingDatas(etcChargeHabulData, etcChargeHabulInstance);
    }
  };

  const _bindingDatas = (itemList, instance) => {
    for (const item of itemList) {
      const { type, data, key } = item;
      const rowIndex = instance.getRowIndexByKey(key);

      if (type === "insert") {
        instance.addRow();
        for (const tag in data) {
          instance.cellValue(0, `${tag}`, data[tag]);
        }
      } else if (type === "update") {
        for (const tag in data) {
          instance.cellValue(rowIndex, `${tag}`, data[tag]);
        }
      } else {
        instance.deleteRow(rowIndex);
      }
    }
  };

  // 할증금액 변경시 합계 계산
  const extraChargeCellRender = useCallback((e) => {
    // extraCharges = 할증등록 DB데이터
    const { extraCharges = [] } = e.data;

    /** grid에서 CRUD한 변경된 할증등록 데이터 */
    const extraChargeChanges = detailGrid.current.instance
      ?.option("editing.changes")
      ?.filter((cur) => cur.key === e.key)[0]?.data?.extraChargeObj;

    // 할증등록 total
    const totalExtraCharge = calculateTotalCharge(
      extraCharges,
      extraChargeChanges,
      "chargedAmount"
    );

    const formattedTotal = isConfirm
      ? totalExtraCharge.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      : totalExtraCharge.toLocaleString("ko-KR");

    return <div>{formattedTotal}</div>;
    // eslint-disable-next-line
  }, []);

  // 기타하불 변경시 합계 계산
  const etcChargeHabulCellRender = useCallback((e) => {
    // etcChargeHabuls = 기타하불 DB데이터
    const { etcChargeHabules = [] } = e.data;

    /** grid에서 CRUD한 변경된 기타하불 데이터 */
    const etcChargeHabulChanges = detailGrid.current.instance
      ?.option("editing.changes")
      ?.filter((cur) => cur.key === e.key)[0]?.data?.etcChargeHabulObj;

    // 기타하불 total
    const totalEtcChargeHabul = calculateTotalCharge(
      etcChargeHabules,
      etcChargeHabulChanges,
      "habul"
    );

    const formattedTotal = isConfirm
      ? totalEtcChargeHabul.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      : totalEtcChargeHabul.toLocaleString("ko-KR");

    return <div>{formattedTotal}</div>;
    // eslint-disable-next-line
  }, []);

  const extraChargeCellButtonRender = (e) => {
    return (
      <button
        className="btn btn-grid btn-grid-add charge"
        type="button"
        onClick={() => {
          _getExtraChargeData(e?.data?.id);
          dispatch({
            type: "SET_DETAIL_ROW_DATA",
            payload: {
              detailRowData: e?.data,
              detailRowDataKey: e?.key,
            },
          });
          setTimeout(
            () =>
              dispatch({
                type: "SET_EXTRA_CHARGE_MODAL",
                payload: true,
              }),
            0
          );
        }}
      >
        할증등록
      </button>
    );
  };

  const onExtraChargeSubmit = async () => {
    const DRIVER = 4305; // habulDivision
    const COMPANY = 4304;

    // Get datagrid instances
    const extraChargeInstance = extraChargeRef.current.instance;
    const etcChargeHabulInstance = etcChargeHabulRef.current.instance;

    // Validate datagrids
    const [extraChargeValid, etcChargeHabulValid] = await Promise.all([
      extraChargeInstance.getController("validating").validate(true),
      etcChargeHabulInstance.getController("validating").validate(true),
    ]);

    // validation 실패시 return false;
    if (!extraChargeValid || !etcChargeHabulValid) return false;

    // Get datagrid changes
    const extraChargeData = extraChargeInstance.option("editing.changes");
    const etcChargeHabulData = etcChargeHabulInstance.option("editing.changes");

    // modal datagrid Row
    const etcChargeHabulRow = etcChargeHabulInstance.getVisibleRows();

    // rows를 돌면서 habulDivision이 업체일때 하불처가없으면 오류
    for (const obj of etcChargeHabulRow) {
      if (obj.removed) continue;

      if (
        (obj.data.habulDivision === DRIVER && !obj.data.fromDirverNo) ||
        (obj.data.habulDivision === COMPANY && !obj.data.fromCompany)
      ) {
        alert("하불처 혹은 기사명을 선택해주세요", "오류");
        return;
      }
    }

    // detailGrid Data
    const detailGridData = detailGrid.current.instance.option(
      "editing.changes"
    );
    const index = detailGridData.findIndex(
      (obj) => obj.key === detailRowDataKey
    );

    if (index >= 0 && detailGridData[index].type !== "remove") {
      detailGridData[index].data.extraChargeObj = extraChargeData;
      detailGridData[index].data.etcChargeHabulObj = etcChargeHabulData;
      // remove?
    } else {
      detailGridData.push({
        data: {
          extraChargeObj: extraChargeData,
          etcChargeHabulObj: etcChargeHabulData,
        },
        key: detailRowDataKey,
        type: "update",
      });
    }

    onClose();
  };

  const onClose = () => {
    dispatch({
      type: "SET_EXTRA_CHARGE_MODAL",
      payload: false,
    });
  };

  return {
    state,
    extraChargeModal,
    extraChargeRef,
    etcChargeHabulRef,
    onClose,
    extraChargeCellRender,
    etcChargeHabulCellRender,
    extraChargeCellButtonRender,
    onExtraChargeSubmit,
  };
};

export default useExtraCharge;
