import { yupResolver } from "@hookform/resolvers/yup";
import { DataGrid } from "devextreme-react";
import {
  Column,
  ColumnChooser,
  FilterRow,
  HeaderFilter,
  Scrolling,
  StateStoring,
} from "devextreme-react/data-grid";
import CustomStore from "devextreme/data/custom_store";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { useCallback } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { sendRequest } from "../../../../_metronic/_helpers/DataSoureHelpers";
import StateStoringSaveAndDeleteButton from "../../../components/StateStoring";
import InputDate from "../../../components/InputForm/InputDate";
import InputLookup from "../../../components/InputForm/InputLookup";
import InputSelect from "../../../components/InputForm/InputSelect";
import {
  DataCount,
  PanelGridInit,
  PanelGridSearchBar,
  PanelGridWrapper,
  SearchItems,
} from "../../../components/Layout/Panel/PanelGrid/panelGrid.style";
import CustomButton from "../../../components/FunctionButton/CustomButton";

function BerthStatusMaster({ dataGridRef }) {
  const [search, setSearch] = useState();
  const storageKey = "berthStatusMaster";

  const initialState = {
    startDate: moment(new Date()).format("YYYY-MM-DD"),
    endDate: moment(new Date())
      .add(7, "days")
      .format("YYYY-MM-DD"),
    trminlCode: "BPTS",
    searchType: 1,
  };

  const dataSource = useMemo(() => {
    const apiUrl = `${process.env.REACT_APP_API_URL}/berthStatPy`;

    return new CustomStore({
      key: "oid",
      load: () => sendRequest(apiUrl, "GET", search),
    });
  }, [search]);

  const [totalCount, setTotalCount] = useState(0);

  const schema = yup
    .object({
      startDate: yup.string().required("입력해주세요"),
      endDate: yup.string().required("입력해주세요"),
      trminlCode: yup.string(),
      searchType: yup.string(),
    })
    .required();

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    reset(initialState);
    setSearch(initialState);
    // eslint-disable-next-line
  }, []);

  const onSubmit = (data) => {
    for (const obj in data) {
      if (data[obj] === "") {
        data[obj] = null;
      }
    }
    setSearch(data);
  };

  const onCellPrepared = (e) => {
    if (e.rowType !== "data") return;
    let updateDate = moment(e.row.data.updateDate).format("YYYY-MM-DD");
    if (updateDate === moment().format("YYYY-MM-DD"))
      e.cellElement.bgColor = "#ddf0b2";
  };

  return (
    <>
      <PanelGridWrapper>
        <PanelGridSearchBar onSubmit={handleSubmit(onSubmit)}>
          <SearchItems>
            <label>조회 기준</label>
            <InputSelect
              control={control}
              errors={errors}
              name="searchType"
              dataSource={[
                { id: 1, name: "접안예정일자" },
                { id: 2, name: "출항예정일자" },
              ]}
              valueExpr="id"
              displayExpr="name"
              flex={true}
            />
          </SearchItems>
          <SearchItems>
            <label>조회 시작 날짜</label>
            <InputDate
              control={control}
              errors={errors}
              name="startDate"
              flex={true}
            />
          </SearchItems>
          <SearchItems>
            <label>조회 종료 날짜</label>
            <InputDate
              control={control}
              errors={errors}
              name="endDate"
              flex={true}
            />
          </SearchItems>
          <SearchItems>
            <label>터미널 코드</label>
            <InputLookup
              control={control}
              errors={errors}
              name="trminlCode"
              dataSource={[
                { id: "HKT", name: "HKT" },
                { id: "DPCT", name: "DPCT" },
                { id: "BPTG", name: "BPTG" },
                { id: "BPTS", name: "BPTS" },
                { id: "BNMT", name: "BNMT" },
                { id: "BNCT", name: "BNCT" },
                { id: "HPNT", name: "HPNT" },
                { id: "HJNC", name: "HJNC" },
                { id: "PNC", name: "PNC" },
                { id: "PNIT", name: "PNIT" },
                { id: "SNCT", name: "SNCT" },
                { id: "HJIT", name: "HJIT" },
                { id: "E1CT", name: "E1CT" },
                { id: "ICT", name: "ICT" },
                { id: "UNCT", name: "UNCT" },
                { id: "KPOL", name: "KPOL" },
                { id: "PCCT", name: "PCCT" },
                { id: "GWCT", name: "GWCT" },
              ]}
              valueExpr="id"
              displayExpr="name"
              flex={true}
            />
          </SearchItems>
          <CustomButton
            type="submit"
            height={24}
            layout="solid"
            color="search"
            label="검색"
          />
        </PanelGridSearchBar>

        <PanelGridInit>
          <StateStoringSaveAndDeleteButton
            dataGridRef={dataGridRef}
            storageKey={storageKey}
          />
          <DataCount>{`총 ${totalCount}건`}</DataCount>

          <DataGrid
            className="datagrid-wrap multi-line"
            id="TerminalMaster"
            ref={dataGridRef}
            dataSource={dataSource}
            showRowLines={true}
            showBorders={true}
            hoverStateEnabled={true}
            allowColumnResizing={true}
            allowColumnReordering={true}
            onCellPrepared={onCellPrepared}
            columnResizingMode="widget"
            keyExpr="oid"
            key="oid"
            focusedRowEnabled={true}
            onContentReady={(e) => setTotalCount(e.component.totalCount())}
          >
            <FilterRow visible={true} />
            <HeaderFilter visible={true} />
            <ColumnChooser enabled={true} />
            <StateStoring
              enabled={true}
              type="custom"
              customLoad={useCallback(() => {
                return JSON.parse(localStorage.getItem(storageKey));
              }, [])}
            />
            <Scrolling mode="virtual" />
            <Column
              dataField="trminlCode"
              caption="터미널코드"
              alignment="center"
            />
            <Column
              dataField="berthCode"
              caption="선석코드"
              alignment="center"
            />
            <Column
              dataField="trminlVoyg"
              caption="모선항차"
              alignment="center"
            />
            <Column dataField="wtorcmpCode" caption="선사" alignment="center" />
            <Column
              dataField="trminlShipnm"
              caption="모선명"
              alignment="center"
            />
            <Column dataField="shipRute" caption="항로" alignment="center" />
            <Column
              dataField="csdhpPrarnde"
              caption="입항예정일시"
              alignment="center"
            />
            <Column
              dataField="tkoffPrarnde"
              caption="출항예정일시"
              alignment="center"
            />
            <Column
              dataField="csdhpDrc"
              caption="접안방향"
              alignment="center"
            />

            <Column
              dataField="workStarDay"
              caption="작업시작일시"
              alignment="center"
            />
            <Column
              dataField="workFiniDay"
              caption="작업완료일시"
              alignment="center"
            />
            <Column
              dataField="carryFiniDay"
              caption="반입마감일시"
              alignment="center"
            />
            <Column
              dataField="landngQy"
              caption="양하수량"
              alignment="center"
            />
            <Column
              dataField="shipngQy"
              caption="적하수량"
              alignment="center"
            />
            <Column
              dataField="reshmtQy"
              caption="이적수량"
              alignment="center"
            />
            <Column dataField="predBerth" caption="전배" alignment="center" />
            <Column dataField="shipment" caption="선적" alignment="center" />
            <Column dataField="shifting" caption="S/H" alignment="center" />
          </DataGrid>
        </PanelGridInit>
      </PanelGridWrapper>
    </>
  );
}

export default BerthStatusMaster;
