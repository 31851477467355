import { yupResolver } from "@hookform/resolvers/yup";
import { alert, confirm } from "devextreme/ui/dialog";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import CustomButton from "../../../components/FunctionButton/CustomButton";
import { ButtonGroup } from "../../../components/FunctionButton/CustomButton/customButton.style";
import InputCheckBox from "../../../components/InputForm/InputCheckBox";
import InputNumber from "../../../components/InputForm/InputNumber";
import InputSelect from "../../../components/InputForm/InputSelect";
import InputText from "../../../components/InputForm/InputText";
import InputTextArea from "../../../components/InputForm/InputTextArea";
import HeaderTitle from "../../../components/Layout/Header/HeaderTitle";
import {
  InputFullRow,
  PanelInput,
  PanelInputTitle,
  PanelInputWrapper,
} from "../../../components/Layout/Panel/PanelInput/panelInput.style";
import {
  PanelBody,
  PanelBtn,
  PanelHeader,
  PanelInit,
} from "../../../components/Layout/Panel/panel.style";
import {
  createAppSheet,
  deleteAppSheet,
  getLookup,
  updateAppSheet,
} from "./AppSheetCrud";
import AppSheetMaster from "./AppSheetMaster";

const AppSheetPage = () => {
  const [selectedRowKey, setSelectedRowKey] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [lookupData, setLookupData] = useState({
    Check_Code: [],
    Confirm_Code: [],
    Use_Yn: [],
  });

  const dataGridRef = useRef();

  /* #region initialState, react-hook-form, yup ---------------------------------------------------- */

  const initialState = {
    sortNo: 0,
    danger: false,
    poison: false,
    fridge: false,
    checkCode: "",
    useYn: "",
    gubun: "",
    contents: "",
  };

  const schema = yup.object({}).required();

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: initialState,
  });
  /* #endregion */

  useEffect(() => {
    getLookupData();
  }, []);

  const onFocusedRowChanged = useCallback((e) => {
    if (!e.row) return;

    setSelectedRowKey(e.row.key);
    reset(e.row.data);
    // eslint-disable-next-line
  }, []);

  const onSubmit = async (data) => {
    setIsLoading(true);
    console.log(selectedRowKey);
    try {
      if (!selectedRowKey) {
        // post
        createAppSheet(data);
      } else {
        // put
        data.key = selectedRowKey;
        updateAppSheet(data);
      }

      dataGridRef.current.instance.refresh();
      dataGridRef.current.instance.option("focusedRowIndex", -1);
      reset(initialState);
    } catch (err) {
      alert(err.response?.data?.message || "ERROR", "오류");
    } finally {
      setIsLoading(false);
    }
    console.log(data);
  };
  const onError = (err) => {
    console.log(err);
  };

  const onClickDelete = async (data) => {
    if (!selectedRowKey) return;
    const result = await confirm("정말로 삭제 하시겠습니까?", "경고");
    if (!result) return;

    try {
      setIsLoading(true);

      await deleteAppSheet(selectedRowKey);

      dataGridRef.current.instance.refresh();
      dataGridRef.current.instance.option("focusedRowIndex", -1);
      setSelectedRowKey(null);
      reset(initialState);
    } catch (err) {
      alert(err.response?.data?.message || "ERROR", "오류");
    } finally {
      setIsLoading(false);
    }
  };

  async function getLookupData() {
    try {
      const fileData = await getLookup("comcode/detail/70500");
      const confirmCodeData = await getLookup("comcode/detail/70600");
      const useYnData = await getLookup("comcode/detail/70200");

      setLookupData({
        Check_Code: fileData.data,
        Confirm_Code: confirmCodeData.data,
        Use_Yn: useYnData.data,
      });
    } catch (err) {
      console.log(err);
    }
  }

  return (
    <>
      <PanelInit>
        <PanelHeader>
          <HeaderTitle />
          <PanelBtn>
            <CustomButton
              type="button"
              height={24}
              layout="solid"
              color="new"
              label="새입력"
              onClick={() => {
                reset(initialState);
                setSelectedRowKey(null);
                dataGridRef.current.instance.option("focusedRowIndex", -1);
              }}
            />
            <CustomButton
              type="button"
              height={24}
              layout="solid"
              color="esc"
              label="삭제"
              onClick={onClickDelete}
            />
          </PanelBtn>
        </PanelHeader>

        <PanelBody row left="400px" right="calc(100% - 400px)">
          <PanelInputWrapper
            onSubmit={handleSubmit(onSubmit, onError)}
            openInputBar={true}
          >
            <PanelInputTitle>시트등록</PanelInputTitle>
            <PanelInput grid={2}>
              <InputNumber
                control={control}
                errors={errors}
                name="sortNo"
                label="정렬순서"
              />
              <InputFullRow grid={2}>
                <InputCheckBox
                  control={control}
                  errors={errors}
                  name="danger"
                  text="위험물"
                />
                <InputCheckBox
                  control={control}
                  errors={errors}
                  name="poison"
                  text="유해화학물질"
                />
                <InputCheckBox
                  control={control}
                  errors={errors}
                  name="fridge"
                  text="냉장/냉동"
                />
              </InputFullRow>
              <InputSelect
                control={control}
                errors={errors}
                name="checkCode"
                label="점검구분"
                dataSource={lookupData.Check_Code}
                valueExpr="subCode"
                displayExpr="subName"
              />
              <InputSelect
                control={control}
                errors={errors}
                name="confirmCode"
                label="확인주기"
                dataSource={lookupData.Confirm_Code}
                valueExpr="subCode"
                displayExpr="subName"
              />
              <InputSelect
                control={control}
                errors={errors}
                name="useYn"
                label="사용여부"
                dataSource={lookupData.Use_Yn}
                valueExpr="subCode"
                displayExpr="subName"
              />
              <InputText
                control={control}
                errors={errors}
                name="gubun"
                label="구분값"
              />
              <InputFullRow grid={2}>
                <InputTextArea
                  control={control}
                  errors={errors}
                  name="contents"
                  label="내용"
                />
              </InputFullRow>
            </PanelInput>
            <ButtonGroup marginTop={20}>
              <CustomButton
                type="submit"
                width="100%"
                height={36}
                layout="solid"
                color="common"
                label="저장"
                className={`${isLoading &&
                  "spinner spinner-white spinner-right"}`}
              />
            </ButtonGroup>
          </PanelInputWrapper>
          <AppSheetMaster
            onFocusedRowChanged={onFocusedRowChanged}
            dataGridRef={dataGridRef}
          />
        </PanelBody>
      </PanelInit>
    </>
  );
};

export default AppSheetPage;
