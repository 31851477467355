import axios from "axios";

const apiUrl = `${process.env.REACT_APP_API_URL}/taxmanage`;
const lookup = `${process.env.REACT_APP_API_URL}`;
const apiUrlAccountList = `${process.env.REACT_APP_API_URL}/account-list`;

export function getLookup(param) {
  return axios.get(lookup + `/${param}`);
}

export function post(data) {
  return axios.post(apiUrl, data);
}

export function getBankAccounts(data) {
  return axios.get(lookup + "/account/accounts", { params: data });
}

export function getAccountLists(Biz_No) {
  return axios.get(apiUrlAccountList + "/bank", {
    params: {
      Biz_No,
    },
  });
}

export function getSellComplete(data) {
  return axios.get(apiUrl + "/sellcomplete", { params: data });
}

export function fetchAccountHistory(data) {
  return axios.post(apiUrlAccountList + "/load", data);
}

export function updateRemark(data) {
  return axios.put(apiUrlAccountList, data);
}
export function deleteRemark(rowData) {
  return axios.put(apiUrlAccountList + "/remark", { params: rowData });
}

export function connectTaxBill(data) {
  return axios.post(apiUrlAccountList + "/tax", data);
}
export function connectEtcCost(data) {
  return axios.post(apiUrlAccountList + "/etc-cost", data);
}

export async function initLookupData() {
  const [companyData] = await Promise.all([getLookup("company")]);

  return [...companyData.data];
}

export async function getDetailGridData(rowData) {
  return axios.get(apiUrlAccountList + "/tax", { params: rowData });
}

export async function deleteTaxBill(data) {
  return axios.delete(apiUrlAccountList + "/tax", { params: data });
}

export async function deleteEtcCost(data) {
  return axios.delete(apiUrlAccountList + "/etc-cost", { params: data });
}
