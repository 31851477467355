import { Lookup, DropDownOptions } from "devextreme-react/lookup";
import React from "react";
import { Controller } from "react-hook-form";
import InputLayout from "./InputLayout";

const InputLookup = ({
  control,
  errors,
  name,
  label,
  labelWidth,
  flex,
  dataSource,
  displayExpr,
  valueExpr,
  required,
  disable = false,
  width,
  onValueChange = () => {},
  onInitialized = () => {},
}) => {
  return (
    <InputLayout
      label={label}
      required={required}
      errors={errors}
      name={name}
      labelWidth={labelWidth}
    >
      <Controller
        render={({ field: { onChange, value } }) => (
          <Lookup
            width={width}
            dataSource={dataSource}
            valueExpr={valueExpr}
            displayExpr={displayExpr}
            onValueChanged={(value) => {
              onChange(value.value);
              onValueChange(value);
            }}
            value={value}
            disabled={disable}
            onInitialized={onInitialized}
          >
            <DropDownOptions
              width="auto"
              maxWidth="200px"
              closeOnOutsideClick={true}
              showTitle={false}
            />
          </Lookup>
        )}
        control={control}
        name={name}
        defaultValue=""
      />
    </InputLayout>
  );
};

export default InputLookup;
