import { SelectBox } from "devextreme-react";
import React, { useEffect, useRef } from "react";
import { Controller } from "react-hook-form";
import InputLayout from "./InputLayout";

const InputSelect = ({
  control,
  errors,
  name,
  label,
  labelWidth,
  flex,
  dataSource,
  displayExpr,
  valueExpr,
  required,
  disable,
  searchEnabled = true,
  onValueChanged = () => {},
  onInitialized = () => {},
  width,
}) => {
  const refInput = useRef();

  // useEffect(() => {
  //   let selectBox = refInput.current.instance;

  //   selectBox.registerKeyHandler("downArrow", (e) => {
  //     console.log(e);
  //   });
  // }, []);

  return (
    <InputLayout
      label={label}
      required={required}
      errors={errors}
      name={name}
      labelWidth={labelWidth}
    >
      <Controller
        render={({ field: { onChange, value } }) => (
          <SelectBox
            ref={refInput}
            items={dataSource}
            valueExpr={valueExpr}
            displayExpr={displayExpr}
            searchEnabled={searchEnabled}
            disabled={disable}
            onValueChanged={(value) => {
              onChange(value.value);
              onValueChanged(value);
            }}
            onInitialized={onInitialized}
            value={value}
            width={width}
          />
        )}
        control={control}
        name={name}
        defaultValue={null}
      />
    </InputLayout>
  );
};

export default InputSelect;
