import React from "react";
import CustomButton from "../../../components/FunctionButton/CustomButton";
import HeaderTitle from "../../../components/Layout/Header/HeaderTitle";
import {
  PanelBody,
  PanelBtn,
  PanelHeader,
  PanelInit,
} from "../../../components/Layout/Panel/panel.style";
import CarDeadlineInput from "./components/CarDeadlineInput";
import CarDeadlineMaster from "./components/CarDeadlineMaster";
import useCarDeadline from "./useCarDeadline";

const CarDeadlinePage = () => {
  const {
    state,

    dataGridRef,
    inputGridRef,
    inputEtcGridRef,

    inputForm,
    searchForm,

    pageFunctions,
    inputFunctions,
    masterFunctions,
  } = useCarDeadline();

  const {
    onClickAdd,
    onClickDelete,
    calculateMaintenanceCharge,
  } = pageFunctions;
  return (
    <>
      <PanelInit>
        <PanelHeader>
          <HeaderTitle />
          <PanelBtn>
            <CustomButton
              type="button"
              height={24}
              layout="solid"
              color="new"
              label="신규"
              onClick={onClickAdd}
            />
            <CustomButton
              type="button"
              height={24}
              layout="solid"
              color="esc"
              onClick={onClickDelete}
            >
              삭제
            </CustomButton>
          </PanelBtn>
        </PanelHeader>
        <PanelBody row left="550px" right="calc(100% - 550px)">
          {/* 왼쪽 사이드 섹션 */}
          <CarDeadlineInput
            state={state}
            inputGridRef={inputGridRef}
            inputEtcGridRef={inputEtcGridRef}
            inputForm={inputForm}
            inputFunctions={inputFunctions}
          />
          {/* 오른쪽 메인 섹션 */}
          <CarDeadlineMaster
            state={state}
            dataGridRef={dataGridRef}
            searchForm={searchForm}
            masterFunctions={masterFunctions}
          />
        </PanelBody>
      </PanelInit>
    </>
  );
};

export default CarDeadlinePage;
