import { yupResolver } from "@hookform/resolvers/yup";
import { alert, confirm } from "devextreme/ui/dialog";
import React, { useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { shallowEqual, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import * as yup from "yup";
import * as requestServer from "./CompanyTaxInfoCrud";
import MainGrid from "./CompanyTaxInfoMaster";

import InputAddress from "../../../components/InputForm/InputAddress";
import InputTel from "../../../components/InputForm/InputTel";
import InputText from "../../../components/InputForm/InputText";
import FunctionButton from "../../../components/FunctionButton/FunctionButton";

function MainPage(props) {
  const initialState = {
    Biz_No: "",
    Company_Name: "",
    Ceo_Name: "",
    Biz_Type: "",
    Biz_Item: "",
    Tel: "",
    Fax: "",
    Email: "",
    Remark: "",
  };
  const [selectedRowKey, setSelectedRowKey] = useState(null);
  const [inputDisable, setInputDisable] = useState(false);
  const dataGridRef = useRef();
  const { menuAll, openItem } = useSelector(
    (state) => ({
      menuAll: state.menu.menuAll,
      openItem: state.menu.openItem,
    }),
    shallowEqual /* */
  );
  function LF_RequiredText(text) {
    return text + " 입력하세요";
  }
  function LF_MaxText(text) {
    return "최대 " + text + "자리까지 입력 가능";
  }
  const schema = yup
    .object({
      Biz_No: yup
        .string()
        .required(LF_RequiredText("코드를")) /*text + " 입력하세요"*/
        .max(20, LF_MaxText(20)) /*"최대 " + text + "자리까지 입력 가능";*/,
      Company_Name: yup
        .string()
        .required(LF_RequiredText("회사명을"))
        .max(50, LF_MaxText(50)),
      Ceo_Name: yup
        .string()
        .required(LF_RequiredText("대표자명을"))
        .max(20, LF_MaxText(20)),
      zipCode: yup
        .string()
        .required(LF_RequiredText("우편번호를"))
        .max(7, LF_MaxText(7)),
      address: yup
        .string()
        .required(LF_RequiredText("주소를"))
        .max(100, LF_MaxText(100)),
      address1: yup
        .string()
        // .required(LF_RequiredText("상세주소를"))
        .max(100, LF_MaxText(100)),
      Biz_Type: yup
        .string()
        .required(LF_RequiredText("업태를"))
        .max(50, LF_MaxText(50)),
      Biz_Item: yup
        .string()
        .required(LF_RequiredText("종목을"))
        .max(100, LF_MaxText(100)),
      Tel: yup
        .string()
        .required(LF_RequiredText("전화번호를"))
        .max(13, LF_MaxText(13)),
      HP: yup
        .string()
        // .required(LF_RequiredText("휴대전화를"))
        .max(13, LF_MaxText(13)),
      Fax: yup
        .string()
        .required(LF_RequiredText("팩스번호를"))
        .max(13, LF_MaxText(13)),
      Email: yup
        .string()
        .required(LF_RequiredText("이메일을"))
        .max(50, LF_MaxText(50)),
      ContactID: yup
        .string()
        .nullable(true)
        .max(50, LF_MaxText(50)),
      ContactName: yup
        .string()
        .nullable()
        .max(50, LF_MaxText(50)),
      Remark: yup
        .string()
        .nullable(true)
        .max(100, "최대 100자리 까지 입력 가능"),
    })
    .required();
  /*
  const [Zip_Code,setZip_Code] = useState();
  */
  const {
    control,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const openPost = () => {
    window.daum.postcode.load(() => {
      const postcode = new window.daum.Postcode({
        oncomplete: function(data) {
          let addr = "";
          let extraAddr = "";

          if (data.userSelectedType === "R") {
            addr = data.address;
          } else {
            addr = data.jibunAddress;
          }

          if (data.userSelectedType === "R") {
            if (data.bname !== "" && /[동|로|가]$/g.test(data.bname)) {
              extraAddr += data.bname;
            }
            if (data.buildingName !== "" && data.apartment === "Y") {
              extraAddr +=
                extraAddr !== "" ? ", " + data.buildingName : data.buildingName;
            }
            if (extraAddr !== "") {
              extraAddr = " (" + extraAddr + ")";
            }
            addr += extraAddr;
          }

          setValue("zipCode", data.zonecode);
          setValue("address", addr);
        },
      });
      postcode.open();
    });
  };

  const onClickAdd = () => {
    if (!dataGridRef.current) return;
    reset(initialState);
    setSelectedRowKey(null);
    setInputDisable(false);
    dataGridRef.current.instance.option("focusedRowIndex", -1);
  };

  const onClickDelete = () => {
    if (!dataGridRef.current || !selectedRowKey) return;
    let response = confirm("<i>삭제하시겠습니까?</i>", "삭제 메세지");

    response.then((res) => {
      if (!res) return;
      requestServer
        .Delete(selectedRowKey)
        .then(() => {
          dataGridRef.current.instance.refresh();
        })
        .catch((err) => {
          alert("삭제할 수 없습니다.", "오류");
        });
    });
  };

  const onFocusedRowChanged = (e) => {
    if (!e.row) return;
    setSelectedRowKey(e.row.key);
    setInputDisable(true);
    reset({
      ...e.row.data,
      zipCode: e.row.data.Zip_Code /* 다른 형태를 리셋해서 넣어줌 */,
      address: e.row.data.Address,
      address1: e.row.data.Address_Detail || undefined,
      ContactID: e.row.data.ContactID || undefined,
    });
  };

  const onSubmit = async (data) => {
    delete data.createIP;
    delete data.createUserId;
    delete data.createdAt;
    delete data.updateIP;
    delete data.updateUserId;
    delete data.updatedAt;

    data.Zip_Code = data.zipCode;
    data.Address = data.address;
    data.Address_Detail = data.address1;

    delete data.zipCode;
    delete data.address;
    delete data.address1;

    try {
      if (selectedRowKey) {
        await requestServer.Update(selectedRowKey, data);
      } else {
        await requestServer.Create(data);
      }

      dataGridRef.current.instance.refresh();
      dataGridRef.current.instance.option("focusedRowIndex", -1);
      reset(initialState);
    } catch (err) {
      alert(err.response.data.message, "오류");
    }
  };

  return (
    <>
      <div className="page-wrap">
        {/* 왼쪽 사이드 섹션 */}
        <div className="column-left fluid-left">
          {/* 페이지 헤더 */}
          <div className="panel-header flex-row">
            <div className="panel-header-init">
              <div className="panel-header-tit">{openItem?.menu?.menuName}</div>
              <ul className="breadcrumb-list">
                <li className="breadcrumb-item">
                  <Link to="/">
                    <i className="flaticon2-shelter icon-1x" />
                  </Link>
                </li>
                {openItem?.menu?.parentId && (
                  <li className="breadcrumb-item">
                    {
                      menuAll.find((menu) => {
                        return menu.menuId === openItem?.menu?.parentId;
                      })?.menu?.menuName
                    }
                  </li>
                )}
                {openItem?.menu?.menuName && (
                  <li className="breadcrumb-item current">
                    {openItem?.menu?.menuName}
                  </li>
                )}
              </ul>
            </div>
            <FunctionButton
              onClickAdd={onClickAdd}
              onClickDelete={onClickDelete}
            />
          </div>

          {/* 페이지 메인그리드 */}
          <MainGrid
            onFocusedRowChanged={onFocusedRowChanged}
            dataGridRef={dataGridRef}
          />
        </div>

        {/* 그리드 인풋 너비 고정 */}
        <div className="column-right fixed-right">
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="form-container column-input"
          >
            <div className="form-tit">세금계산서 정보 등록</div>
            <InputText
              control={control}
              errors={errors}
              name="Biz_No"
              label="사업자번호"
              required={true}
              mask="999-99-99999"
              disabled={inputDisable}
            />
            <InputText
              control={control}
              errors={errors}
              name="Company_Name"
              label="법인명"
              required={true}
            />
            <InputText
              control={control}
              errors={errors}
              name="Ceo_Name"
              label="대표자명"
              required={true}
            />
            {/* 주소 */}
            <InputAddress
              control={control}
              errors={errors}
              flexDirection="column"
              openPost={openPost}
              required={true}
            />

            <InputText
              control={control}
              errors={errors}
              name="Biz_Type"
              label="업태"
              required={true}
            />
            <InputText
              control={control}
              errors={errors}
              name="Biz_Item"
              label="종목"
              required={true}
            />
            <InputTel
              control={control}
              errors={errors}
              name="Tel"
              label="전화번호"
              required={true}
            />
            <InputTel
              control={control}
              errors={errors}
              name="HP"
              label="휴대전화"
              // required={true}
            />
            <InputTel
              control={control}
              errors={errors}
              name="Fax"
              label="팩스"
              required={true}
            />
            <InputText
              control={control}
              errors={errors}
              name="Email"
              label="이메일"
              required={true}
            />
            {/* <InputText
              control={control}
              errors={errors}
              name="Bank_Code"
              label="은행"
              required={true}
            /> */}

            <InputText
              control={control}
              errors={errors}
              name="ContactID"
              label="바로빌 아이디"
            />
            <InputText
              control={control}
              errors={errors}
              name="ContactName"
              label="담당자 이름"
            />
            <InputText
              control={control}
              errors={errors}
              name="Remark"
              label="비고"
            />

            <button type="submit" className="btn btn-form-save">
              저장
            </button>
          </form>
        </div>
      </div>
    </>
  );
}

export default MainPage;
