import React, { useMemo, useState } from "react";
import { DataGrid } from "devextreme-react";
import {
  Column,
  ColumnChooser,
  HeaderFilter,
  Scrolling,
  StateStoring,
} from "devextreme-react/data-grid";
import CustomStore from "devextreme/data/custom_store";
import { sendRequest } from "../../../../_metronic/_helpers/DataSoureHelpers";
import { useForm } from "react-hook-form";

function WorkPlaceMaster({ onFocusedRowChanged, dataGridRef }) {
  const [totalCount, setTotalCount] = useState(0);
  const [search, setSearch] = useState({});
  const dataSource = useMemo(() => {
    const apiUrl = `${process.env.REACT_APP_API_URL}/workplace`;

    return new CustomStore({
      key: "code",
      load: () => sendRequest(apiUrl, "GET", search),
      insert: (values) => sendRequest(apiUrl, "POST", values),
      update: (key, values) => sendRequest(apiUrl, "PUT", { key, values }),
      remove: (key) => sendRequest(apiUrl, "DELETE", { key }),
    });
  }, [search]);

  const { register, handleSubmit } = useForm();

  const onSubmit = (data) => {
    for (const obj in data) {
      if (data[obj] === "") {
        data[obj] = null;
      }
    }
    setSearch(data);
  };

  return (
    <>
      <div className="grid-search-wrap">
        <form onSubmit={handleSubmit(onSubmit)}>
          <span className="search-group">
            <label>작업지명</label>
            <input type="text" {...register("workPlaceNaem")} />
          </span>
          <button type="submit" className="btn btn-grid-search single-line">
            검색
          </button>
        </form>
      </div>
      <div className="grid-component has-toolbar">
        <div className="grid-total">{`총 ${totalCount}건`}</div>
        <DataGrid
          className="datagrid-wrap"
          id="WorkPlaceMaster"
          ref={dataGridRef}
          dataSource={dataSource}
          showRowLines={true}
          showBorders={true}
          hoverStateEnabled={true}
          allowColumnResizing={true}
          allowColumnReordering={true}
          columnResizingMode="widget"
          keyExpr="code"
          focusedRowEnabled={true}
          onFocusedRowChanged={onFocusedRowChanged}
          onContentReady={(e) => setTotalCount(e.component.totalCount())}
        >
          <HeaderFilter visible={true} />
          {/* <Column dataField="id" caption="No." width={"35px"} /> */}
          <ColumnChooser enabled={true} />
          <StateStoring
            enabled={true}
            type="localStorage"
            storageKey="workPlaceStorage"
          />
          <Scrolling mode="virtual" />
          <Column dataField="code" caption="작업지코드" alignment="center" />
          <Column dataField="workPlaceNaem" caption="작업지명" />
          <Column dataField="zipCode" caption="우편번호" alignment="center" />
          <Column dataField="address" caption="주소" />
          <Column dataField="address1" caption="상세주소" />
          <Column dataField="isShuttle" caption="셔틀창고" />
          <Column dataField="remark" caption="비고" alignment="center" />
        </DataGrid>
      </div>
    </>
  );
}

export default WorkPlaceMaster;
