import { yupResolver } from "@hookform/resolvers/yup";
import { alert, confirm } from "devextreme/ui/dialog";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import SVG from "react-inlinesvg";
import * as yup from "yup";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";
import CustomButton from "../../../../components/FunctionButton/CustomButton";
import { ButtonGroup } from "../../../../components/FunctionButton/CustomButton/customButton.style";
import {
  InputNumberMinus,
  InputRadioGroup,
} from "../../../../components/InputForm";
import InputDate from "../../../../components/InputForm/InputDate";
import InputNumber from "../../../../components/InputForm/InputNumber";
import InputTel from "../../../../components/InputForm/InputTel";
import InputText from "../../../../components/InputForm/InputText";
import InputTextArea from "../../../../components/InputForm/InputTextArea";
import {
  AdditionalItems,
  AdditionalWrapper,
  CloseItems,
  CloseWrapper,
  DetailItems,
  DetailItemsList,
  DetailWrapper,
  InfoItems,
  InfoWrapper,
  Invoice,
  InvoiceItems,
  InvoiceWrapper,
  ModalTaxIssueBody,
  ModalTaxIssueHeader,
  ModalTaxIssueTitle,
  ModalTaxIssueWrapper,
} from "../../../../components/Modal/modal.style";
import {
  getIssuedData,
  getNotIssuedData,
  getIssuedDriverData,
  editingIssue,
} from "../CarClosingCrud";
import TaxissueSubmitModal from "./TaxissueSubmitModal";
import { ModalContainer } from "../../../../components/Modal";
// import { InputCheckBox } from "../../../../components/InputForm";

const TaxIssueModalDriver = ({
  onClose,
  maskClosable,
  closable,
  visible,
  onSelect,
  selectedRow,
  finishUpperRef,
}) => {
  const [subData, setSubData] = useState([]);
  const [isIssued, setIsIssued] = useState(false);
  const [modalData, setModalData] = useState();
  const [taxNumber, setTaxNumber] = useState();
  const [taxIssueSubmitModalVisible, setTaxIssueSubmitModalVisible] = useState(
    false
  );

  function LF_RequiredText(text) {
    return text + " 입력하세요";
  }

  const schema = yup.object().shape({
    kwon: yup
      .string()
      .nullable()
      .max(4, "4자 이하"),
    ho: yup
      .string()
      .nullable()
      .max(4, "4자 이하"),
    serialNum: yup
      .string()
      .nullable()
      .max(27, "27자 이하"),

    // 공급자
    corpNum: yup
      .string()
      .nullable()
      .required(),
    taxRegID: yup
      .string()
      .nullable()
      .max(4, "4자 이하"),
    corpName: yup
      .string()
      .nullable()
      .required(LF_RequiredText("상호명을"))
      .max(70, "70자 이하"),
    ceoName: yup
      .string()
      .nullable()
      .required(LF_RequiredText("대표자명을"))
      .max(30, "30자 이하"),
    addr: yup
      .string()
      .nullable()
      .required(LF_RequiredText("주소를"))
      .max(150, "150자 이하"),
    bizClass: yup
      .string()
      .nullable()
      .max(40, "40자 이하"),
    bizType: yup
      .string()
      .nullable()
      .max(40, "40자 이하"),
    // Invoicer_ContactID: yup.string().required(),
    // Invoicer_TEL: yup.string().nullable(),
    contactName: yup
      .string()
      .nullable()
      .required(LF_RequiredText("담당자명을"))
      .max(30, "30자 이하"),
    tel: yup
      .string()
      .nullable()
      .max(20, "20자 이하"),
    email: yup
      .string()
      .nullable()
      .required(LF_RequiredText("이메일을"))
      .max(40, "40자 이하"),

    // 공급받는자
    corpNum_R: yup
      .string()
      .nullable()
      .required(),
    taxRegID_R: yup
      .string()
      .nullable()
      .max(4, "4자 이하"),
    corpName_R: yup
      .string()
      .nullable()
      .required(LF_RequiredText("상호명을 "))
      .max(70, "70자 이하"),
    ceoName_R: yup
      .string()
      .nullable()
      .required(LF_RequiredText("대표자명을"))
      .max(30, "30자 이하"),
    addr_R: yup
      .string()
      .nullable()
      .required(LF_RequiredText("주소를"))
      .max(150, "150자 이하"),
    bizClass_R: yup
      .string()
      .nullable()
      .max(40, "40자 이하"),
    bizType_R: yup
      .string()
      .nullable()
      .max(40, "40자 이하"),
    // Invoicee_ContactID: yup.string().required(),
    // Invoicee_TEL: yup.string().nullable(),
    contactName_R: yup
      .string()
      .nullable()
      .required(LF_RequiredText("담당자명을"))
      .max(30, "30자 이하"),
    tel_R: yup
      .string()
      .nullable()
      .max(20, "20자 이하"),
    email_R: yup
      .string()
      .nullable()
      .required(LF_RequiredText("이메일을"))
      .max(40, "40자 이하"),

    // 작성일자, 공급가액, 세액, 합계금액
    writeDate: yup.string().required(),
    amountTotal: yup.number().required(),
    taxTotal: yup.number().required(),
    totalAmount: yup.number().required(),

    // 비고
    remark: yup.array().of(
      yup.object().shape({
        remark: yup
          .string()
          .nullable()
          .max(150, "150자 이하"),
      })
    ),

    // 세부내용

    TaxInvoiceTradeLineItem: yup.array().of(
      yup.object().shape({
        purchaseExpiryMonth: yup.string().nullable(),
        purchaseExpiryDay: yup.string().nullable(),
        name: yup
          .string()
          .nullable()
          .max(100, "100자 이하"),
        information: yup
          .string()
          .nullable()
          .max(60, "60자 이하"),
        chargeableUnit: yup
          .string()
          .nullable()
          .max(12, "12자 이하"),
        unitPrice: yup
          .string()
          .nullable()
          .max(18, "18자 이하"),
        amount: yup
          .string()
          .nullable()
          .max(18, "18자 이하"),
        tax: yup
          .string()
          .nullable()
          .max(18, "18자 이하"),
        description: yup
          .string()
          .nullable()
          .max(40, "40자 이하"),
      })
    ),

    // 영수청구

    cash: yup.string().nullable(),
    chkBill: yup.string().nullable(),
    note: yup.string().nullable(),
    credit: yup.string().nullable(),
    purposeType: yup.string(),
  });

  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
    watch,
    setValue,
    reset,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: { purposeType: "2", taxType: 1 },
  });

  const {
    fields: remarkFields,
    append: remarkAppend,
    remove: remarkRemove,
  } = useFieldArray({
    control,
    name: "remark",
  });

  const { fields, append, remove: removeArr } = useFieldArray({
    control,
    name: "TaxInvoiceTradeLineItem",
  });

  useEffect(() => {
    // 만약 selectedRow의 정산상태가 발급/미발급일때 다르게 select
    if (selectedRow.finishNo && selectedRow.closeStatus === "발행") {
      getIssueDataFunction();
    } else {
      getNotIssueDataFunction();
    }
    // eslint-disable-next-line
  }, []);

  const onSubmit = (data) => {
    try {
      // CREATE 시

      const mainInfo = {};
      const taxInvoiceTradeLineItem = data.TaxInvoiceTradeLineItem;

      mainInfo["finishNo"] = selectedRow["finishNo"];
      mainInfo["division"] = selectedRow["division"];
      mainInfo["extraDivision"] = selectedRow["extraDivision"];
      mainInfo["taxSchedule"] = data["writeDate"];
      mainInfo["closeStatus"] = "Close-100";
      mainInfo["taxType"] = data["taxType"];
      mainInfo["remark1"] = data.remark[0]?.remark;
      mainInfo["remark2"] = data.remark[1]?.remark;
      mainInfo["remark3"] = data.remark[2]?.remark;

      for (const obj in data) {
        if (obj === "TaxInvoiceTradeLineItem") continue;
        mainInfo[obj] = data[obj];
      }

      setModalData({
        completeNo: selectedRow.completeNo,
        main: mainInfo,
        sub: taxInvoiceTradeLineItem,
      });

      setTaxIssueSubmitModalVisible(true);

      // UPDATE 시
    } catch (err) {
      alert(err.response?.data?.message, "오류");
    }
  };

  const issueMinusTax = async () => {
    const data = watch();

    const mainInfo = {};
    const taxInvoiceTradeLineItem = data.TaxInvoiceTradeLineItem;
    mainInfo["finishNo"] = selectedRow["finishNo"];
    mainInfo["completeNo"] = selectedRow["completeNo"];
    mainInfo["division"] = selectedRow["division"];
    mainInfo["extraDivision"] = selectedRow["extraDivision"];
    mainInfo["taxType"] = data["taxType"];
    mainInfo["closeStatus"] = "DisClose-100";
    mainInfo["taxStatus"] = "Publish-170";

    for (const obj in data) {
      if (obj === "TaxInvoiceTradeLineItem") continue;
      mainInfo[obj] = data[obj];
    }

    try {
      const check = await confirm("수정 세금계산서 발행하시겠습니까?", "확인");
      if (!check) return;

      const result = await editingIssue({
        completeNo: selectedRow.completeNo,
        main: mainInfo,
        sub: taxInvoiceTradeLineItem,
      });

      await alert(result.data?.message, "알림");

      onClose();
      finishUpperRef.current.instance.refresh();
    } catch (err) {
      alert(err.response?.data?.message, "오류");
    }
  };

  const onError = (err) => {
    console.log(err);
  };

  /** 발행한 데이터 바인딩 */
  const getIssueDataFunction = async () => {
    try {
      setIsIssued(true);
      const data = await getIssuedData(selectedRow.finishNo);

      const { main, sub } = data.data;
      const mainArr = main[0];

      reset({
        contactID: mainArr.contactID,

        corpNum: mainArr.corp_num,
        taxRegID: mainArr.tax_reg_id,
        corpName: mainArr.corp_name,
        ceoName: mainArr.ceo_name,
        addr: mainArr.addr,
        bizClass: mainArr.biz_class,
        bizType: mainArr.biz_type,
        contactName: mainArr.contact_name,
        tel: mainArr.tel,
        email: mainArr.email,

        corpNum_R: mainArr.corp_num_r,
        taxRegID_R: mainArr.tax_reg_id_r,
        corpName_R: mainArr.corp_name_r,
        ceoName_R: mainArr.ceo_name_r,
        addr_R: mainArr.addr_r,
        bizClass_R: mainArr.biz_class_r,
        bizType_R: mainArr.biz_type_r,
        contactName_R: mainArr.contact_name_r || "담당자",
        tel_R: mainArr.tel_R,
        email_R: mainArr.email_r,

        writeDate: mainArr.write_date,
        amountTotal: Number(mainArr.amount_total || 0),
        taxTotal: Number(mainArr.tax_total || 0),
        totalAmount: Number(mainArr.total_amount || 0),

        purposeType: `${mainArr.purpose_type}`,
      });

      for (const key in mainArr || []) {
        if (!key.includes("remark")) continue;
        mainArr[key] !== null && remarkAppend({ remark: mainArr[key] });
      }

      for (const obj of sub) {
        const item = {
          ...obj,
          chargeableUnit: obj.chargeaable_unit,
          unitPrice: obj.unit_price,
          purchaseExpiryYear: moment(mainArr.writeDate || undefined).format(
            "YYYY"
          ),
          purchaseExpiryMonth: obj.purchase_expiry.split("/")[0],
          purchaseExpiryDay: obj.purchase_expiry.split("/")[1],
        };
        append(item);
      }
    } catch (error) {
      console.log(error);
    }
  };

  /** 발행하기 전 데이터 바인딩 */
  const getNotIssueDataFunction = async () => {
    const {
      id,
      receiveCoName,
      companyId,
      comOid,
      companyName,
      division,
      extraDivision,
      completeNo,
    } = selectedRow;
    const data = await getNotIssuedData(completeNo, {
      id: id,
      receiveCo: receiveCoName,
      completeNo: completeNo,
      companyId: companyId,
      comOid: comOid,
      company: companyName,
      division: division,
      extraDivision: extraDivision,
    });

    const { main, sub } = data.data;
    setSubData(sub);
    const invoicer = main.invoicer[0];
    const invoicee = main.invoicee[0];
    const deliverDate = sub[0]?.deliverDate;

    setTaxNumber(invoicer?.remark1);

    reset({
      orderCode: invoicer.orderCode,
      contactID: invoicer.contactID,
      contactID_R: invoicee.contactID,

      corpNum: invoicer.bizNo || "",
      taxRegID: invoicer.bizPlaceNo,
      corpName: invoicer.bizName,
      ceoName: invoicer.ceoName,
      addr: `${invoicer.bizAddress || ""} ${invoicer.bizDetailAddress || ""}`,
      /** 종목 */
      bizClass: invoicer.item,
      /** 업태 */
      bizType: invoicer.bizCondition,
      contactName: invoicer.registerWorker,
      tel: invoicer.tel,
      email: invoicer.email,

      corpNum_R: invoicee.Biz_No || "",
      corpName_R: invoicee.companyName,
      ceoName_R: invoicee.ceoName,
      addr_R: `${invoicee.address || ""} ${invoicee.addressDetail || ""}`,
      bizClass_R: invoicee.bizItem,
      bizType_R: invoicee.bizType,
      contactName_R: invoicee.registerWorker || "담당자",
      tel_R: invoicee.tel,
      email_R: invoicee.email,

      writeDate: deliverDate
        ? moment(deliverDate).format("YYYY-MM-DD")
        : moment().format("YYYY-MM-DD"),
      // amountTotal: Number(invoicer.habulDriver) || 0,
      // taxTotal: Number(invoicer.taxTotal) || 0,
      // totalAmount: Number(invoicer.price) + Number(invoicer.taxTotal) || 0,
      amountTotal: Number(sub[0]?.price || 0),
      taxTotal: Number(sub[0]?.tax || 0),
      totalAmount: Number(sub[0]?.price) + Number(sub[0]?.tax || 0),

      purposeType: "2",
    });

    remarkAppend({});

    for (const obj of sub) {
      append({
        purchaseExpiryYear: moment().format("YYYY"),
        purchaseExpiryMonth: moment().format("MM"),
        purchaseExpiryDay: moment().format("DD"),
        name: "운송료",
        information: "",
        chargeableUnit: 1,
        unitPrice: Number(obj.price),
        amount: Number(obj.price),
        tax: Number(obj.tax),
        description: "",
      });
    }
  };

  const onTaxTypeChanged = (value) => {
    if (isIssued) return;
    const taxType = value.value;
    const TaxInvoiceTradeLineItem = watch("TaxInvoiceTradeLineItem");

    // 각 아이템의 값 계산
    TaxInvoiceTradeLineItem.forEach((tradeLineItem, index) => {
      let newTax = 0;
      if (taxType === 1) {
        if (index === 0) {
          newTax = Number(subData[0].tax || 0);
        } else {
          newTax = Number(tradeLineItem.amount * 0.1 || 0);
        }
      }

      setValue(`TaxInvoiceTradeLineItem[${index}].tax`, newTax);
    });

    // 총 세액 계산
    let newTaxTotal = 0;
    if (taxType === 1) {
      newTaxTotal = Number(selectedRow?.taxTotal || 0);
    }

    // SetValue
    setValue("taxTotal", newTaxTotal);
  };

  const onWriteDateChanged = (value) => {
    const writeDate = value.value;
    const TaxInvoiceTradeLineItem = watch("TaxInvoiceTradeLineItem");

    if (!TaxInvoiceTradeLineItem || !writeDate) return;
    const year = moment(writeDate).format("YYYY");
    const month = moment(writeDate).format("MM");
    const day = moment(writeDate).format("DD");

    TaxInvoiceTradeLineItem.forEach((cur, index) => {
      setValue(`TaxInvoiceTradeLineItem[${index}]`, {
        ...cur,
        purchaseExpiryYear: year,
        purchaseExpiryMonth: month,
        purchaseExpiryDay: day,
      });
    });
  };

  const onChargeableUnitChanged = useCallback((e) => {
    const data = watch();
    const TaxInvoiceTradeLineItem = data.TaxInvoiceTradeLineItem;

    let amountTotal = 0;
    let taxTotal = 0;

    for (let i = 0; i < TaxInvoiceTradeLineItem.length; i++) {
      const item = TaxInvoiceTradeLineItem[i];
      const chargeableUnit = parseInt(item.chargeableUnit);
      const unitPrice = parseInt(item.unitPrice);

      if (isNaN(chargeableUnit) || isNaN(unitPrice)) continue;

      const amount = chargeableUnit * unitPrice;

      amountTotal += amount;
      taxTotal += Math.round(amount * 0.1);

      setValue(`TaxInvoiceTradeLineItem[${i}].amount`, amount);
      setValue(`TaxInvoiceTradeLineItem[${i}].tax`, Math.round(amount * 0.1));
    }

    setValue("amountTotal", amountTotal);
    setValue("taxTotal", taxTotal);
    setValue("totalAmount", amountTotal + taxTotal);
    // eslint-disable-next-line
  }, []);

  const onUnitPriceChanged = useCallback((e) => {
    const data = watch();
    const TaxInvoiceTradeLineItem = data.TaxInvoiceTradeLineItem;

    let amountTotal = 0;

    for (let i = 0; i < TaxInvoiceTradeLineItem.length; i++) {
      const item = TaxInvoiceTradeLineItem[i];
      const chargeableUnit = parseInt(item.chargeableUnit);
      const unitPrice = parseInt(item.unitPrice);

      if (isNaN(chargeableUnit) || isNaN(unitPrice)) continue;

      const amount = chargeableUnit * unitPrice;

      amountTotal += amount;

      setValue(`TaxInvoiceTradeLineItem[${i}].amount`, amount);
      setValue(`TaxInvoiceTradeLineItem[${i}].tax`, Math.round(amount * 0.1));
    }

    setValue("amountTotal", amountTotal);
    setValue("totalAmount", amountTotal + watch("taxTotal"));
    // eslint-disable-next-line
  }, []);

  const onAmountChanged = useCallback((e) => {
    const data = watch();
    const TaxInvoiceTradeLineItem = data.TaxInvoiceTradeLineItem;

    let amountTotal = 0;

    for (const item of TaxInvoiceTradeLineItem) {
      if (isNaN(parseInt(item.amount))) continue;
      amountTotal += parseInt(item.amount);
    }

    setValue("amountTotal", amountTotal);
    setValue("totalAmount", amountTotal + data.taxTotal);
    // eslint-disable-next-line
  }, []);

  const onTaxChanged = useCallback((e) => {
    const data = watch();
    const TaxInvoiceTradeLineItem = data.TaxInvoiceTradeLineItem;

    let taxTotal = 0;

    for (const item of TaxInvoiceTradeLineItem) {
      if (isNaN(parseInt(item.tax))) continue;
      taxTotal += parseInt(item.tax);
    }

    setValue("taxTotal", taxTotal);
    setValue("totalAmount", data.amountTotal + taxTotal);
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <ModalContainer
        onClose={onClose}
        maskClosable={maskClosable}
        visible={visible}
        size="xl"
      >
        <form onSubmit={handleSubmit(onSubmit, onError)} className="modal-form">
          <div className="modal-form-header">
            <div className="modal-form-tit">
              <span className="modal-tit">세금계산서 발급</span>
            </div>
            <button
              type="button"
              className="btn-modal btn-modal-close"
              onClick={onClose}
            >
              <SVG
                src={toAbsoluteUrl(
                  "/media/svg/icons/Custom/close_black_24dp.svg"
                )}
                className="close-icon"
              />
            </button>
            {/* <button
              type="button"
              className="btn-modal btn-modal-close"
              onClick={async () => {
                const finishNo = selectedRow.finishNo;
                finishNo && (await test({ finishNo }));
              }}
            >
              T
            </button> */}
          </div>
          <div className="modal-form-body">
            <ModalTaxIssueWrapper>
              <label>국세청 승인번호</label>
              <span className="modal-tit">{taxNumber || ""}</span>
              <ModalTaxIssueHeader className="taxissue-header-print">
                <ModalTaxIssueTitle>
                  {watch("taxType") === 1 && (
                    <>
                      세 금 계 산 서<span>(공급자 보관용)</span>
                    </>
                  )}
                  {watch("taxType") === 2 && (
                    <>
                      영 세 율 세 금 계 산 서<span>(공급자 보관용)</span>
                    </>
                  )}
                </ModalTaxIssueTitle>
              </ModalTaxIssueHeader>
              <ModalTaxIssueBody>
                <InvoiceWrapper>
                  <Invoice className="invoicer">
                    <InvoiceItems
                      gridRow
                      gridTop={1}
                      gridBottom={7}
                      className="invoicer-tit"
                    >
                      공<br />급<br />자
                    </InvoiceItems>
                    <InvoiceItems className="item-tit">등록번호</InvoiceItems>
                    <InvoiceItems>
                      <InputText
                        control={control}
                        errors={errors}
                        disabled={true}
                        className="readonly a_c valignm form-s"
                        name="corpNum"
                        maxLength={14}
                        tabIndex={4}
                      />
                    </InvoiceItems>
                    <InvoiceItems className="item-tit">종사업장</InvoiceItems>
                    <InvoiceItems>
                      <InputText
                        control={control}
                        errors={errors}
                        className="form-s"
                        name="taxRegID"
                        maxLength={4}
                        tabIndex={5}
                        disabled={isIssued}
                      />
                    </InvoiceItems>
                    <InvoiceItems className="item-tit">상호</InvoiceItems>
                    <InvoiceItems>
                      <InputTextArea
                        control={control}
                        errors={errors}
                        name="corpName"
                        className="scroll"
                        maxLength={200}
                        tabIndex={6}
                        disabled={isIssued}
                      />
                    </InvoiceItems>
                    <InvoiceItems className="item-tit">성명</InvoiceItems>
                    <InvoiceItems>
                      <InputTextArea
                        control={control}
                        errors={errors}
                        name="ceoName"
                        className="scroll"
                        maxLength={100}
                        tabIndex={7}
                        disabled={isIssued}
                      />
                    </InvoiceItems>
                    <InvoiceItems className="item-tit">
                      사업장
                      <br />
                      주소
                    </InvoiceItems>
                    <InvoiceItems gridLeft={3} gridRight={6}>
                      <InputTextArea
                        control={control}
                        errors={errors}
                        name="addr"
                        className="scroll"
                        maxLength={300}
                        tabIndex={8}
                        disabled={isIssued}
                      />
                    </InvoiceItems>
                    <InvoiceItems className="item-tit">업태</InvoiceItems>
                    <InvoiceItems>
                      <InputTextArea
                        control={control}
                        errors={errors}
                        name="bizType"
                        className="scroll"
                        maxLength={100}
                        tabIndex={9}
                        disabled={isIssued}
                      />
                    </InvoiceItems>
                    <InvoiceItems className="item-tit">종목</InvoiceItems>
                    <InvoiceItems>
                      <InputTextArea
                        control={control}
                        errors={errors}
                        name="bizClass"
                        className="scroll"
                        maxLength={300}
                        tabIndex={10}
                        disabled={isIssued}
                      />
                    </InvoiceItems>
                    <InvoiceItems className="item-tit">담당자</InvoiceItems>
                    <InvoiceItems>
                      <InputText
                        control={control}
                        errors={errors}
                        name="contactName"
                        className="form-s"
                        maxLength={100}
                        tabIndex={11}
                        disabled={isIssued}
                      />
                    </InvoiceItems>
                    <InvoiceItems className="item-tit">연락처</InvoiceItems>
                    <InvoiceItems>
                      <InputTel
                        control={control}
                        errors={errors}
                        name="tel"
                        className="form-s"
                        maxLength={20}
                        tabIndex={12}
                        disabled={isIssued}
                      />
                    </InvoiceItems>
                    <InvoiceItems className="item-tit">이메일</InvoiceItems>
                    <InvoiceItems gridLeft={3} gridRight={6}>
                      <InputText
                        control={control}
                        errors={errors}
                        name="email"
                        className="form-s"
                        maxLength={100}
                        tabIndex={13}
                        disabled={isIssued}
                      />
                    </InvoiceItems>
                  </Invoice>
                  <Invoice className="invoicee">
                    <InvoiceItems
                      gridRow
                      gridTop={1}
                      gridBottom={7}
                      className="invoicee-tit"
                    >
                      공<br />급<br />받<br />는<br />자
                    </InvoiceItems>
                    <InvoiceItems className="item-tit">등록번호</InvoiceItems>
                    <InvoiceItems>
                      <InputText
                        control={control}
                        errors={errors}
                        className="readonly a_c valignm form-s"
                        name="corpNum_R"
                        disabled={true}
                        maxLength={14}
                        tabIndex={14}
                      />
                    </InvoiceItems>
                    <InvoiceItems className="item-tit">종사업장</InvoiceItems>
                    <InvoiceItems>
                      <InputText
                        control={control}
                        errors={errors}
                        className="form-s"
                        name="taxRegID_R"
                        maxLength={4}
                        tabIndex={15}
                        disabled={isIssued}
                      />
                    </InvoiceItems>
                    <InvoiceItems className="item-tit">상호</InvoiceItems>
                    <InvoiceItems>
                      <InputTextArea
                        control={control}
                        errors={errors}
                        name="corpName_R"
                        className="scroll"
                        maxLength={200}
                        tabIndex={16}
                        disabled={isIssued}
                      />
                    </InvoiceItems>
                    <InvoiceItems className="item-tit">성명</InvoiceItems>
                    <InvoiceItems>
                      <InputTextArea
                        control={control}
                        errors={errors}
                        name="ceoName_R"
                        className="scroll"
                        maxLength={100}
                        tabIndex={17}
                        disabled={isIssued}
                      />
                    </InvoiceItems>
                    <InvoiceItems className="item-tit">
                      사업장
                      <br />
                      주소
                    </InvoiceItems>
                    <InvoiceItems gridLeft={3} gridRight={6}>
                      <InputTextArea
                        control={control}
                        errors={errors}
                        name="addr_R"
                        className="scroll"
                        maxLength={300}
                        tabIndex={18}
                        disabled={isIssued}
                      />
                    </InvoiceItems>
                    <InvoiceItems className="item-tit">업태</InvoiceItems>
                    <InvoiceItems>
                      <InputTextArea
                        control={control}
                        errors={errors}
                        name="bizType_R"
                        className="scroll"
                        maxLength={100}
                        tabIndex={19}
                        disabled={isIssued}
                      />
                    </InvoiceItems>
                    <InvoiceItems className="item-tit">종목</InvoiceItems>
                    <InvoiceItems>
                      <InputTextArea
                        control={control}
                        errors={errors}
                        name="bizClass_R"
                        className="scroll"
                        maxLength={300}
                        tabIndex={20}
                        disabled={isIssued}
                      />
                    </InvoiceItems>
                    <InvoiceItems className="item-tit">담당자</InvoiceItems>
                    <InvoiceItems>
                      <InputText
                        control={control}
                        errors={errors}
                        name="contactName_R"
                        className="form-s"
                        maxLength={100}
                        tabIndex={21}
                        disabled={isIssued}
                      />
                    </InvoiceItems>
                    <InvoiceItems className="item-tit">연락처</InvoiceItems>
                    <InvoiceItems>
                      <InputTel
                        control={control}
                        errors={errors}
                        name="tel_R"
                        className="form-s"
                        maxLength={20}
                        tabIndex={22}
                        disabled={isIssued}
                      />
                    </InvoiceItems>
                    <InvoiceItems className="item-tit">이메일</InvoiceItems>
                    <InvoiceItems gridLeft={3} gridRight={6}>
                      <InputText
                        control={control}
                        errors={errors}
                        name="email_R"
                        className="form-s"
                        maxLength={100}
                        tabIndex={23}
                        disabled={isIssued}
                      />
                    </InvoiceItems>
                  </Invoice>
                </InvoiceWrapper>

                <InfoWrapper>
                  <InfoItems className="item-tit">작성일자</InfoItems>
                  <InfoItems className="item-tit">공급가액</InfoItems>
                  <InfoItems className="item-tit">세액</InfoItems>
                  <InfoItems className="item-tit">합계금액</InfoItems>
                  <InfoItems className="no-border">
                    <InputDate
                      control={control}
                      errors={errors}
                      name="writeDate"
                      flex={true}
                      disable={isIssued}
                      onValueChanged={onWriteDateChanged}
                    />
                  </InfoItems>
                  <InfoItems>
                    <InputNumberMinus
                      control={control}
                      errors={errors}
                      className="readonly a_r form-s"
                      name="amountTotal"
                      disabled={true}
                    />
                  </InfoItems>
                  <InfoItems>
                    <InputNumberMinus
                      control={control}
                      errors={errors}
                      className="readonly a_r form-s"
                      name="taxTotal"
                      disabled={true}
                    />
                  </InfoItems>
                  <InfoItems>
                    <InputNumberMinus
                      control={control}
                      errors={errors}
                      className="readonly a_r form-s"
                      name="totalAmount"
                      disabled={true}
                    />
                  </InfoItems>
                </InfoWrapper>
                <ButtonGroup
                  marginTop={20}
                  marginBottom={5}
                  justifyContent="flex-end"
                >
                  <CustomButton
                    type="button"
                    height={26}
                    layout="solid"
                    color="new"
                    label="추가"
                    onClick={() => {
                      const remark = watch("remark");
                      if (Array.isArray(remark) && remark.length === 3) return;
                      remarkAppend({});
                    }}
                  />
                </ButtonGroup>

                <AdditionalWrapper>
                  {remarkFields.map((item, index) => (
                    <>
                      <AdditionalItems className="item-tit">
                        비고{index + 1}
                      </AdditionalItems>
                      <AdditionalItems>
                        <InputText
                          control={control}
                          errors={errors}
                          name={`remark[${index}].remark`}
                          className="form-s"
                          maxLength={150}
                          tabIndex={26}
                        />
                        <button
                          type="button"
                          className="btn btn-grid btn-grid-delete"
                          onClick={() => {
                            remarkRemove(index);
                          }}
                        >
                          삭제
                        </button>
                      </AdditionalItems>
                    </>
                  ))}
                </AdditionalWrapper>
              </ModalTaxIssueBody>

              <DetailWrapper>
                <ButtonGroup
                  marginTop={20}
                  marginBottom={5}
                  justifyContent="flex-end"
                >
                  <CustomButton
                    type="button"
                    height={26}
                    layout="solid"
                    color="new"
                    label="추가"
                    onClick={() =>
                      append({
                        purchaseExpiryYear: moment(
                          watch("writeDate") || undefined
                        ).format("YYYY"),
                        purchaseExpiryMonth: moment(
                          watch("writeDate") || undefined
                        ).format("MM"),
                        purchaseExpiryDay: moment(
                          watch("writeDate") || undefined
                        ).format("DD"),
                        chargeableUnit: 1,
                      })
                    }
                  />
                </ButtonGroup>
                <DetailItemsList>
                  <DetailItems className="item-tit">월</DetailItems>
                  <DetailItems className="item-tit">일</DetailItems>
                  <DetailItems className="item-tit">품목</DetailItems>
                  <DetailItems className="item-tit">규격</DetailItems>
                  <DetailItems className="item-tit">수량</DetailItems>
                  <DetailItems className="item-tit">단가</DetailItems>
                  <DetailItems className="item-tit">공급가액</DetailItems>
                  <DetailItems className="item-tit">세액</DetailItems>
                  <DetailItems className="item-tit">비고</DetailItems>
                  <DetailItems className="item-tit">삭제</DetailItems>
                </DetailItemsList>

                {fields.length === 0 ? (
                  <DetailItemsList>
                    <DetailItems className="grid-full">
                      상품를 등록해 주십시오.
                    </DetailItems>
                  </DetailItemsList>
                ) : (
                  <>
                    {fields.map((item, index) => {
                      return (
                        <DetailItemsList key={item.id}>
                          <DetailItems>
                            <InputText
                              control={control}
                              errors={errors}
                              name={`TaxInvoiceTradeLineItem[${index}].purchaseExpiryMonth`}
                              className="form-s a_c readonly"
                              maxLength={2}
                              tabIndex={50}
                              disabled={true}
                            />
                          </DetailItems>
                          <DetailItems>
                            <InputText
                              control={control}
                              errors={errors}
                              name={`TaxInvoiceTradeLineItem[${index}].purchaseExpiryDay`}
                              className="form-s a_c"
                              maxLength={2}
                              tabIndex={50}
                              disabled={isIssued}
                            />
                          </DetailItems>
                          <DetailItems>
                            <InputText
                              control={control}
                              errors={errors}
                              name={`TaxInvoiceTradeLineItem[${index}].name`}
                              className="form-s a_c"
                              maxLength={100}
                              tabIndex={50}
                              disabled={isIssued}
                            />
                          </DetailItems>
                          <DetailItems>
                            <InputTextArea
                              control={control}
                              errors={errors}
                              name={`TaxInvoiceTradeLineItem[${index}].information`}
                              className="scroll a_l kor valignm"
                              maxLength={60}
                              tabIndex={50}
                              disabled={isIssued}
                            />
                          </DetailItems>
                          <DetailItems>
                            <InputText
                              control={control}
                              errors={errors}
                              name={`TaxInvoiceTradeLineItem[${index}].chargeableUnit`}
                              className="form-s a_r"
                              maxLength={12}
                              tabIndex={50}
                              onValueChange={onChargeableUnitChanged}
                            />
                          </DetailItems>
                          <DetailItems>
                            <InputNumber
                              control={control}
                              errors={errors}
                              name={`TaxInvoiceTradeLineItem[${index}].unitPrice`}
                              className="form-s a_r"
                              maxLength={18}
                              tabIndex={50}
                              onValueChange={onUnitPriceChanged}
                            />
                          </DetailItems>
                          <DetailItems>
                            <InputNumber
                              control={control}
                              errors={errors}
                              name={`TaxInvoiceTradeLineItem[${index}].amount`}
                              className="form-s a_r"
                              maxLength={18}
                              tabIndex={50}
                              disabled={true}
                              onValueChange={onAmountChanged}
                            />
                          </DetailItems>
                          <DetailItems>
                            <InputNumber
                              control={control}
                              errors={errors}
                              name={`TaxInvoiceTradeLineItem[${index}].tax`}
                              className="form-s a_r"
                              maxLength={18}
                              tabIndex={50}
                              onValueChange={onTaxChanged}
                            />
                          </DetailItems>
                          <DetailItems>
                            <InputTextArea
                              control={control}
                              errors={errors}
                              name={`TaxInvoiceTradeLineItem[${index}].description`}
                              className="scroll a_l kor"
                              maxLength={100}
                              tabIndex={50}
                            />
                          </DetailItems>
                          <DetailItems>
                            <button
                              type="button"
                              className="btn btn-grid btn-grid-delete"
                              onClick={() => {
                                removeArr(index);
                                onChargeableUnitChanged();
                              }}
                            >
                              삭제
                            </button>
                          </DetailItems>
                        </DetailItemsList>
                      );
                    })}
                  </>
                )}
              </DetailWrapper>

              <CloseWrapper>
                <CloseItems className="item-tit">현금</CloseItems>
                <CloseItems className="item-tit">수표</CloseItems>
                <CloseItems className="item-tit">어음</CloseItems>
                <CloseItems className="item-tit">외상미수금</CloseItems>
                <CloseItems
                  gridLeft={5}
                  gridRight={6}
                  gridTop={1}
                  gridBottom={3}
                  className="item-finish"
                >
                  <span>이 금액을</span>
                  <div>
                    <label>
                      <input
                        type="radio"
                        name="type"
                        className="rb"
                        value={1}
                        {...register("purposeType")}
                        disabled={isIssued}
                      />{" "}
                      영수
                    </label>
                    <label>
                      <input
                        type="radio"
                        name="type"
                        className="rb"
                        value={2}
                        {...register("purposeType")}
                        disabled={isIssued}
                      />{" "}
                      청구
                    </label>
                    함.
                  </div>
                </CloseItems>
                <CloseItems className="no-border">
                  <InputNumber
                    control={control}
                    errors={errors}
                    name="cash"
                    className=" a_r form-s"
                    tabIndex={51}
                    disabled={isIssued}
                  />
                </CloseItems>
                <CloseItems>
                  <InputNumber
                    control={control}
                    errors={errors}
                    name="chkBill"
                    className=" a_r form-s"
                    tabIndex={52}
                    disabled={isIssued}
                  />
                </CloseItems>
                <CloseItems>
                  <InputNumber
                    control={control}
                    errors={errors}
                    name="note"
                    className=" a_r form-s"
                    tabIndex={53}
                    disabled={isIssued}
                  />
                </CloseItems>
                <CloseItems>
                  <InputNumber
                    control={control}
                    errors={errors}
                    name="credit"
                    className=" a_r form-s"
                    tabIndex={54}
                    disabled={isIssued}
                  />
                </CloseItems>
              </CloseWrapper>
            </ModalTaxIssueWrapper>
          </div>
          <ButtonGroup className="no-print" justifyContent="flex-end">
            {!isIssued && (
              <InputRadioGroup
                control={control}
                errors={errors}
                label="부가세 유형"
                name="taxType"
                onValueChanged={onTaxTypeChanged}
                dataSource={[
                  { key: 1, name: "일반" },
                  { key: 2, name: "영세율" },
                ]}
                className="a_r form-s"
                valueExpr="key"
                displayExpr="name"
              />
            )}
            {!isIssued && (
              <CustomButton
                type="submit"
                height={36}
                layout="solid"
                color="new"
                label="발급하기"
              />
            )}
            {isIssued && (
              <CustomButton
                type="button"
                height={36}
                layout="solid"
                color="common"
                label="확인"
                onClick={() => onClose()}
              />
            )}
            {isIssued && (
              <CustomButton
                type="button"
                height={36}
                layout="solid"
                color="new"
                label="수정 세금계산서 발행"
                onClick={issueMinusTax}
              />
            )}
            <CustomButton
              type="button"
              height={36}
              layout="solid"
              color="common"
              label="출력"
              onClick={() => window.print()}
            />
          </ButtonGroup>
        </form>
      </ModalContainer>
      {taxIssueSubmitModalVisible && (
        <TaxissueSubmitModal
          onClose={() => {
            setTaxIssueSubmitModalVisible(false);
          }}
          onCloseParentModal={() => {
            finishUpperRef.current.instance.refresh();
            setTaxIssueSubmitModalVisible(false);
            onClose();
          }}
          modalData={modalData}
          closable={true}
          visible={true}
          maskClosable={false}
          isIssued={isIssued}
        />
      )}
    </>
  );
};

export default TaxIssueModalDriver;
