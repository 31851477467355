import axios from "axios";
const apiUrl = `${process.env.REACT_APP_API_URL}`;
const apiUrlPasswordChange = `${process.env.REACT_APP_API_URL}/user-list/password`;
const apiUrlUser = `${process.env.REACT_APP_API_URL}/user-list/master`;

export function getUserData(key) {
  return axios.get(apiUrlUser + `/${key}`);
}

export function createUser(data) {
  return axios.post(apiUrlUser, data);
}

export function updateUser(key, data) {
  return axios.put(apiUrlUser, { key, data });
}

export function changePassword(data) {
  return axios.put(apiUrlPasswordChange, data);
}

export function deleteRow(key) {
  return axios.delete(apiUrlUser + `/${key}`);
}

export function getLookup(param) {
  return axios.get(apiUrl + `/${param}`);
}
