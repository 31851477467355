import React, { useCallback } from "react";
import { PanelGridInitPayment } from "../../../components/Layout/Panel/PanelGrid/panelGrid.style";
import { DataGrid } from "devextreme-react";
import {
  Column,
  Editing,
  Button,
  Lookup,
  Format,
  Summary,
  TotalItem,
} from "devextreme-react/data-grid";
import dayjs from "dayjs";

const PaymentLedgerOwnerGrid = ({
  dataSource,
  lookupData,
  ownerGrid,
  user,
  setTotalCount,
}) => {
  const onInitNewRow = (e) => {
    e.data.regDate = dayjs().format("YYYY-MM-DD");
    e.data.regUserName = user.userName;
  };

  const onToolbarPreparing = (e) => {
    e.toolbarOptions.items.forEach((item) => {
      if (item.name === "saveButton") {
        item.visible = false;
      }
    });
  };

  const onEditingStart = (e) => {
    if (e.data.completeNo) {
      e.cancel = true;
    }
  };

  return (
    <>
      <PanelGridInitPayment>
        <DataGrid
          className="datagrid-wrap"
          dataSource={dataSource}
          ref={ownerGrid}
          height={200}
          showRowLines={true}
          showBorders={true}
          wordWrapEnabled={true}
          onInitNewRow={onInitNewRow}
          onToolbarPreparing={onToolbarPreparing}
          onEditingStart={onEditingStart}
          focusedRowEnabled={true}
          keyExpr="oid"
          onContentReady={(e) => setTotalCount(e.component.totalCount())}
        >
          <Editing
            mode="batch"
            allowAdding={true}
            allowUpdating={true}
            allowDeleting={true}
          />
          <Column type="buttons">
            <Button
              name="delete"
              text="삭제"
              onClick={(e) => {
                if (e.row.data.completeNo) return;
                else e.component.deleteRow(e.row.rowIndex);
              }}
            />
          </Column>
          <Column
            caption="No"
            alignment="center"
            cellRender={(e) => {
              return e.row.rowIndex + 1;
            }}
          />
          <Column
            caption="일자"
            dataField="regDate"
            format="yyyy년 MM월 dd일"
            dataType="datetime"
            alignment="center"
            allowEditing={false}
          />
          <Column caption="접수법인" dataField="receiveCo" alignment="center">
            <Lookup
              dataSource={lookupData.company}
              valueExpr="id"
              displayExpr="companyName"
            />
          </Column>
          <Column caption="세부내역" dataField="contents" alignment="center" />
          <Column caption="대납처" dataField="company01" alignment="center" />
          <Column caption="지급처" dataField="company02" alignment="center" />
          <Column
            caption="금액"
            dataField="money01"
            alignment="center"
            dataType="number"
          >
            <Format type="fixedPoint" />
          </Column>
          <Column caption="비고" dataField="remark01" alignment="center" />
          <Column caption="기안자" dataField="regUserName" alignment="center" />
          <Summary>
            <TotalItem
              column="money01"
              summaryType="sum"
              valueFormat="fixedPoint"
              displayFormat="금액 합계: {0}"
              showInGroupFooter={true}
            />
          </Summary>
        </DataGrid>
      </PanelGridInitPayment>
    </>
  );
};

export default PaymentLedgerOwnerGrid;
