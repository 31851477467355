import TreeList, {
  Column,
  ColumnChooser,
  Editing,
} from "devextreme-react/tree-list";
import CustomStore from "devextreme/data/custom_store";
import React, { useMemo } from "react";
import { useForm } from "react-hook-form";
import { sendRequest } from "../../../../_metronic/_helpers/DataSoureHelpers";
import * as requestServer from "./AuthGroupCrud";

import { alert } from "devextreme/ui/dialog";
import CustomButton from "../../../components/FunctionButton/CustomButton";
import { ButtonGroup } from "../../../components/FunctionButton/CustomButton/customButton.style";
import InputLayout from "../../../components/InputForm/InputLayout";
import {
  PanelInput,
  PanelInputGrid,
  PanelInputTitle,
  PanelInputWrapper,
} from "../../../components/Layout/Panel/PanelInput/panelInput.style";

export default function AuthGroupDetail({
  rowData,
  dataGridRef,
  dataGridDetailRef,
}) {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const dataSource = useMemo(() => {
    if (rowData) {
      reset({
        authGroupName: rowData.data.authGroupName,
      });
      const getApiUrl = `${process.env.REACT_APP_API_URL}/authgroup/detail/${rowData.key}`;
      const apiUrl = `${process.env.REACT_APP_API_URL}/authgroup/detail`;
      return new CustomStore({
        key: "menuId",
        load: () => sendRequest(getApiUrl, "POST"),
        update: (key, values) =>
          sendRequest(apiUrl, "PUT", { key, values, id: rowData.data.id }),
      });
    } else {
      reset({
        authGroupName: "",
      });
    }
    //eslint-disable-next-line
  }, [rowData]);

  const onSubmit = async (data) => {
    const { authGroupName } = data;
    const detail = dataGridDetailRef.current.instance.option("editing.changes");

    if (!authGroupName) {
      alert("그룹코드명이 없습니다", "오류");
      return;
    }

    try {
      if (rowData) {
        // Update시
        await requestServer.updateAuthGroup({
          key: rowData.key,
          master: { authGroupName: authGroupName },
          detail: detail,
        });

        dataGridRef.current.instance.refresh();
        dataGridDetailRef.current.instance.refresh();
        dataGridDetailRef.current.instance.option("editing.changes", []);
      } else {
        // Create시
        await requestServer.createAuthGroup({ authGroupName });

        dataGridRef.current.instance.refresh();
      }
    } catch (err) {
      alert(err.response.data.message, "오류");
    }
  };

  const cellRender = (e) => {
    return (
      <input
        type="checkbox"
        style={{ width: "15px", height: "15px" }}
        checked={e.data.isAuth}
        onChange={() => onChange(e)}
      />
    );
  };

  const onChange = (currentRowData) => {
    const instance = dataGridDetailRef.current.instance;
    let index = instance.getRowIndexByKey(currentRowData.data.menuId);
    let node = instance.getNodeByKey(currentRowData.data.menuId);
    let state = !currentRowData.data.isAuth; //true 이면 false에서 true로 변경된 것
    instance.cellValue(index, 1, state);

    // 재귀 parent나 children이 있을 때 실행

    // children이 있을 때 실행
    ChildrenCheckRecur(node, state);

    // state가 false일때 parent는 무조건 false 그 위에도 false
    // ParentCheckFalseRecur(node, state);

    // 함수
    function ChildrenCheckRecur(node, state) {
      if (node.children[0]) {
        node.children.forEach((cur) => {
          index = instance.getRowIndexByKey(cur.key);
          instance.cellValue(index, 1, state);
          ChildrenCheckRecur(cur, state);
        });
      }
    }
  };

  const onToolbarPreparing = (e) => {
    var toolbarItems = e.toolbarOptions.items;

    toolbarItems.forEach((cur) => {
      if (cur.name === "saveButton" || cur.name === "revertButton") {
        cur.visible = false;
      }
    });
  };

  return (
    <PanelInputWrapper onSubmit={handleSubmit(onSubmit)} openInputBar={true}>
      <PanelInputTitle>권한그룹 등록</PanelInputTitle>
      <PanelInput grid={2}>
        <InputLayout label="그룹코드명" errors={errors} name="authGroupName">
          <input type="text" {...register("authGroupName")} />
        </InputLayout>
      </PanelInput>
      <PanelInputGrid>
        <TreeList
          id="authGroupDetail"
          className="datagrid-wrap"
          ref={dataGridDetailRef}
          allowColumnResizing={true}
          rootValue=""
          keyExpr="menuId"
          parentIdExpr="parentId"
          dataSource={dataSource}
          showRowLines={true}
          showBorders={true}
          height={"calc(100vh - 307px)"}
          onToolbarPreparing={onToolbarPreparing}
          onSaving={onSubmit}
        >
          <ColumnChooser enabled={true} />
          <Editing
            mode="batch"
            allowUpdating={true}
            startEditAction="dblClick"
          />
          {/* <StateStoring enabled={true} type="localStorage" storageKey="treeListStorage" /> */}
          <Column dataField="menuName" caption="메뉴명" allowEditing={false} />
          <Column
            dataField="isAuth"
            caption="권한"
            dataType="boolean"
            allowEditing={true}
            cellRender={cellRender}
          />
        </TreeList>
      </PanelInputGrid>
      <ButtonGroup marginTop={20}>
        <CustomButton
          type="submit"
          width="100%"
          height={36}
          layout="solid"
          color="common"
          label="저장"
        />
      </ButtonGroup>
    </PanelInputWrapper>
  );
}
