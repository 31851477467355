import { DataGrid } from "devextreme-react";
import {
  Column,
  ColumnChooser,
  Editing,
  FilterRow,
  HeaderFilter,
  Scrolling,
  StateStoring,
} from "devextreme-react/data-grid";
import CustomStore from "devextreme/data/custom_store";
import React, { useCallback, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { sendRequest } from "../../../../_metronic/_helpers/DataSoureHelpers";
import CustomButton from "../../../components/FunctionButton/CustomButton";
import {
  DataCount,
  PanelGridInit,
  PanelGridSearchBar,
  PanelGridWrapper,
  SearchItems,
} from "../../../components/Layout/Panel/PanelGrid/panelGrid.style";
import StateStoringSaveAndDeleteButton from "../../../components/StateStoring";

function CompanyMaster({ onFocusedRowChanged, onRowDblClick, dataGridRef }) {
  const [totalCount, setTotalCount] = useState(0);
  const { register, handleSubmit } = useForm();
  const [search, setSearch] = useState({});
  const storageKey = "companyMaster";

  const dataSource = useMemo(() => {
    const apiUrl = `${process.env.REACT_APP_API_URL}/company`;

    return new CustomStore({
      key: "id",
      load: () => sendRequest(apiUrl, "GET", search),
    });
  }, [search]);

  const onSubmit = (data) => {
    for (const obj in data) {
      if (data[obj] === "") {
        data[obj] = null;
      }
    }
    setSearch(data);
  };

  return (
    <>
      <PanelGridWrapper>
        <PanelGridSearchBar onSubmit={handleSubmit(onSubmit)}>
          <SearchItems>
            <label>회사명</label>
            <input type="text" {...register("companyName")} />
          </SearchItems>
          <SearchItems>
            <label>대표자명</label>
            <input type="text" {...register("ceoName")} />
          </SearchItems>
          <CustomButton
            type="submit"
            height={24}
            layout="solid"
            color="search"
            label="검색"
          />
        </PanelGridSearchBar>

        <PanelGridInit>
          <StateStoringSaveAndDeleteButton
            dataGridRef={dataGridRef}
            storageKey={storageKey}
          />
          <DataCount>{`총 ${totalCount}건`}</DataCount>
          <DataGrid
            id="company"
            className="datagrid-wrap"
            dataSource={dataSource}
            ref={dataGridRef}
            repaintChangesOnly={true}
            showRowLines={true}
            showBorders={true}
            hoverStateEnabled={true}
            allowColumnResizing={true}
            allowColumnReordering={true}
            columnResizingMode="widget"
            keyExpr="id"
            focusedRowEnabled={true}
            columnAutoWidth={true}
            onFocusedRowChanged={onFocusedRowChanged}
            onRowDblClick={onRowDblClick}
            onContentReady={(e) => setTotalCount(e.component.totalCount())}
          >
            <FilterRow visible={true} />
            <HeaderFilter visible={true} />
            <ColumnChooser enabled={true} allowSearch={true} />
            <StateStoring
              enabled={true}
              type="custom"
              customLoad={useCallback(() => {
                return JSON.parse(localStorage.getItem(storageKey));
              }, [])}
            />
            <Scrolling columnRenderingMode="virtual" />
            <Editing />

            <Column caption="No" cellRender={(e) => e.row.loadIndex + 1} />
            <Column dataField="companyName" caption="회사명" />
            <Column dataField="bizNo" caption="사업자등록번호" />
            <Column dataField="ceoName" caption="대표자명" />
            <Column dataField="address" caption="주소" />
            <Column dataField="addressDetail" caption="상세주소" />
            <Column dataField="bizType" caption="업태" alignment="center" />
            <Column dataField="bizItem" caption="종목" alignment="center" />
            <Column dataField="tel" caption="전화번호" />
            <Column dataField="fax" caption="팩스번호" />
            <Column dataField="eamil" caption="이메일" />
            <Column dataField="remark" caption="비고" alignment="center" />
          </DataGrid>
        </PanelGridInit>
      </PanelGridWrapper>
    </>
  );
}

export default CompanyMaster;
