import { useCallback, useEffect, useMemo, useReducer, useState } from "react";
import * as requestServer from "./DispatchNewCrud";
import { alert } from "devextreme/ui/dialog";
import { useRef } from "react";
import { useForm } from "react-hook-form";
import dayjs from "dayjs";

import { shallowEqual, useSelector } from "react-redux";
import CustomStore from "devextreme/data/custom_store";
import { sendRequest } from "../../../../_metronic/_helpers/DataSoureHelpers";
import { yupResolver } from "@hookform/resolvers/yup";
import { dispatchSchema, fileListSchema } from "./useDispatch.data";

const reducer = (state, action) => {
  switch (action.type) {
    case "SET_ROW_DATA":
      return {
        ...state,
        rowData: action.payload,
      };

    case "SET_OPEN_MODAL":
      return {
        ...state,
        isModalOpen: action.payload.isModalOpen,
        modalType: action.payload.modalType,
      };

    case "SET_FILE_LIST_MODAL":
      return {
        ...state,
        isFileListModalOpen: action.payload,
      };

    case "SET_SEARCH":
      return {
        ...state,
        search: action.payload,
      };

    case "SET_OPEN_INPUT_BAR":
      return {
        ...state,
        openInputBar: action.payload,
      };
    default:
      return state;
  }
};

const useDispatchNew = () => {
  const { user } = useSelector(
    (state) => ({ user: state.auth.user.user }),
    shallowEqual
  );

  const initialSearchState = {
    firstDay: dayjs().format("YYYY-MM-DD"),
    lastDay: dayjs().format("YYYY-MM-DD"),
    searchType: 2,
    authGroupId: user.authGroupId,
    company: user.companyCode,
    dispatchTeam: user?.deptCode || "",
    importExport: "",
    isBobtail: 0,
    isLoss: false,
  };

  const initialState = {
    rowData: null,

    search: initialSearchState,

    openInputBar: false,

    isModalOpen: false,
    modalType: "",

    isFileListModalOpen: false,
  };

  const [state, dispatch] = useReducer(reducer, initialState);
  const { search, openInputBar } = state;

  const [lookupData, setLookupData] = useState({
    company: [],
    dispatch: [],
    department: [],
    color: [],
    chassisType: [],
  });

  // const [search, setSearch] = useState(initialSearchState)

  const dataGridRef = useRef();
  const detailGridRef = useRef();
  const modalGridRef = useRef();
  const fileListModalGridRef = useRef();

  // useForm
  const searchForm = useForm({
    defaultValues: initialSearchState,
  });
  const inputForm = useForm({
    resolver: yupResolver(dispatchSchema),
  });
  const modalForm = useForm({
    resolver: yupResolver(fileListSchema),
  });
  const fileListModalForm = useForm();

  const { watch: inputWatch, setValue: inputSetValue } = inputForm;

  useEffect(() => {
    _initLookupData();
  }, []);

  const dataSource = useMemo(() => {
    const apiUrl = `${process.env.REACT_APP_API_URL}/dispatchNew`;

    return new CustomStore({
      key: "keyId",
      load: () => sendRequest(apiUrl, "GET", search),
    });
  }, [search]);

  const _initLookupData = async () => {
    try {
      const [
        deptNameData,
        companyData,
        disaptchTeamData,
        colorData,
        chassisTypeData,
      ] = await Promise.all([
        requestServer.getLookupDatas("department/lookup"),
        requestServer.getLookupDatas("company"),
        requestServer.getLookupDatas("department/isDispatch"),
        requestServer.getLookupDatas("comcode/detail/10900"),
        requestServer.getLookupDatas("comcode/detail/30300"),
      ]);

      setLookupData({
        company: [{ id: "", companyName: "전체" }, ...companyData.data],
        dispatch: [{ code: "", deptName: "전체" }, ...disaptchTeamData.data],
        department: deptNameData.data,
        color: colorData.data,
        chassisType: chassisTypeData.data.filter((cur) => cur.id === 101),
      });
    } catch (err) {
      alert(err.response.data.message, "오류");
    }
  };

  /* 
  
  
    page

  
  */

  const onToggleInputBar = () => {
    // setOpenInputBar(!openInputBar);
    dispatch({
      type: "SET_OPEN_INPUT_BAR",
      payload: !openInputBar,
    });
  };

  /* 
  

    search


  */

  const onSubmit = (data) => {
    dispatch({
      type: "SET_SEARCH",
      payload: data,
    });
  };

  /*


    grid


  */

  const onCellClick = useCallback((e) => {
    if (!e.row) return;
    const row = e.row;
    if (row.rowType === "data") {
      dispatch({
        type: "SET_ROW_DATA",
        payload: row.data,
      });
    }
  }, []);

  /*


    input


  */

  const onFileSelectClick = (index, name) => {
    dispatch({
      type: "SET_OPEN_MODAL",
      payload: {
        isModalOpen: true,
        modalType: { index, name },
      },
    });
  };

  /*


    file modal


  */

  const closeModal = () => {
    dispatch({
      type: "SET_OPEN_MODAL",
      payload: {
        isModalOpen: false,
        modalType: "",
      },
    });
  };

  // 모달 submit
  const onModalSubmit = (data, type) => {
    const { index, name } = type;
    const modalInstance = modalGridRef.current.instance;
    const modalDatas = modalInstance.getVisibleRows();

    // modalInstance에서 editing.changes를 추출하여 상수로 선언
    const changes = modalInstance.option("editing.changes");

    // `${name}FilesRemove`를 감시하고, 값이 없으면 빈 배열을 할당하여 상수로 선언
    const watchDeleteKeys = inputWatch(`${name}FilesRemove`) || [];

    // 삭제를 누른 데이터의 disOid를 담는 배열
    const removeDisOidArr = [];

    // changes에서 type이 "remove"인 것들을 추출하여 key만으로 이루어진 배열로 만들어 상수로 선언
    const deleteKeys = changes
      .filter((cur) => cur.type === "remove")
      .map((cur) => cur.key);

    // modalDatas에서 data만 추출하여 새로운 배열로 만들고, deleteKeys에 해당하는 Oid를 가진 행은 제외하고 새로운 배열로 만들어 상수로 선언
    const gridTrimmedData = modalDatas
      .map((cur) => cur.data)
      .filter((cur) => {
        const isRemove = deleteKeys.includes(cur.Oid);
        if (isRemove && cur.disOid) removeDisOidArr.push(cur.disOid);
        return !isRemove;
      });

    // `${name}Files`에 gridTrimmedData를 할당
    inputSetValue(`${name}Files`, gridTrimmedData);
    // `${name}FilesRemove`에 watchDeleteKeys와 removeDisOidArr 합친 배열을 할당
    inputSetValue(`${name}FilesRemove`, [
      ...watchDeleteKeys,
      ...removeDisOidArr,
    ]);
    // `dispatchComment_0${index + 1}`에 data.dispatchComment를 할당
    inputSetValue(`dispatchComment_0${index + 1}`, data.dispatchComment);

    closeModal();
  };

  const subModalOpen = () => {
    dispatch({
      type: "SET_FILE_LIST_MODAL",
      payload: true,
    });
  };

  /*


    file list modal


  */

  const onCloseFileList = () => {
    dispatch({
      type: "SET_FILE_LIST_MODAL",
      payload: false,
    });
  };

  const onFileListSubmit = () => {
    const modalInstance = modalGridRef.current.instance;
    const fileListInstance = fileListModalGridRef.current.instance;

    const fileList = fileListInstance.getSelectedRowsData();
    const modalGridData = modalInstance.getVisibleRows();

    for (const obj of fileList) {
      const isDuple = modalGridData.some((row) => row.data.Oid === obj.Oid);
      if (isDuple) continue;
      modalInstance.addRow();
      for (const key in obj) {
        modalInstance.cellValue(0, key, obj[key]);
      }
    }

    onCloseFileList();
  };

  const gridProps = {
    dataGridRef: dataGridRef,
    detailGridRef: detailGridRef,
    dataSource: dataSource,
    lookupData: lookupData,
    onCellClick: onCellClick,
  };

  const inputProps = {
    inputForm,
    onFileSelectClick,
  };

  const modalProps = {
    modalGridRef,
    inputForm,
    modalForm,
    closeModal,
    onModalSubmit,
    subModalOpen,
  };

  const fileModalProps = {
    fileListModalGridRef,
    fileListModalForm,
    onClose: onCloseFileList,
    onSubmit: onFileListSubmit,
  };

  return {
    state,
    lookupData,
    dataGridRef,
    detailGridRef,

    onSubmit,
    dataSource,

    searchForm,
    onToggleInputBar,

    gridProps,
    inputProps,
    modalProps,
    fileModalProps,
  };
};

export default useDispatchNew;
