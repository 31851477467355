import React from "react";
import CustomButton from "../../../../components/FunctionButton/CustomButton/CustomButton";
import { InputMonth, InputSelect } from "../../../../components/InputForm";
import {
  PanelGridSearchBar,
  SearchItems,
} from "../../../../components/Layout/Panel/PanelGrid/panelGrid.style";

const CalculateProfitSearch = ({ state, searchForm, searchFunctions }) => {
  const { lookupData } = state;
  const { onSubmit } = searchFunctions;
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = searchForm;
  return (
    <PanelGridSearchBar onSubmit={handleSubmit(onSubmit)}>
      <SearchItems>
        <label>접수법인</label>
        <InputSelect
          className="label-select"
          control={control}
          errors={errors}
          name="receiveCo"
          displayExpr="companyName"
          valueExpr="id"
          dataSource={lookupData.company}
          flex={true}
        />
      </SearchItems>
      <SearchItems>
        <label>기준 월</label>
        <InputMonth
          className="label-select"
          control={control}
          errors={errors}
          flex={true}
          name="baseMonth"
        />
      </SearchItems>

      <CustomButton
        type="submit"
        height={24}
        layout="solid"
        color="search"
        label="검색"
      />
    </PanelGridSearchBar>
  );
};

export default CalculateProfitSearch;
