import { useContext, useEffect } from "react";
import { DataGridDispatch } from "../BasePage";

export const useDatagrid = (datagrid, datagrid2) => {
  const { dispatch } = useContext(DataGridDispatch);

  useEffect(() => {
    dispatch({
      type: "GET_DATAGRID_REF",
      datagrid: datagrid,
      datagrid2: datagrid2,
    });
  }, [dispatch, datagrid, datagrid2]);
};
