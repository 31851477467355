import { yupResolver } from "@hookform/resolvers/yup";
import { alert, confirm } from "devextreme/ui/dialog";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";

import { useCallback } from "react";
import InputDate from "../../../components/InputForm/InputDate";
import InputText from "../../../components/InputForm/InputText";

import { PushAppModal } from "../../../../_metronic/_partials/modals/PushAppModal";
import CustomButton from "../../../components/FunctionButton/CustomButton";
import { ButtonGroup } from "../../../components/FunctionButton/CustomButton/customButton.style";
import InputTextArea from "../../../components/InputForm/InputTextArea";
import HeaderTitle from "../../../components/Layout/Header/HeaderTitle";
import {
  InputFullRow,
  PanelInput,
  PanelInputTitle,
  PanelInputWrapper,
} from "../../../components/Layout/Panel/PanelInput/panelInput.style";
import {
  PanelBody,
  PanelBtn,
  PanelHeader,
  PanelInit,
} from "../../../components/Layout/Panel/panel.style";
import * as requestServer from "./PushAppCrud";
import PushAppMaster from "./PushAppMaster";

const PushAppPage = () => {
  /* #region useState, useRef -----------------------------------------------------------------------*/
  const [selectedRowKey, setSelectedRowKey] = useState(null);
  const [selectModalVisible, setSelectModalVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const dataGridRef = useRef();
  /* #endregion */

  /* #region initialState, react-hook-form, yup ---------------------------------------------------- */
  const initialState = {
    startDate: moment().format("YYYY-MM-DD"),
    endDate: moment().format("YYYY-MM-DD"),
    timeSchedule: undefined,
    contents: "",
    remark: undefined,
  };

  const schema = yup
    .object({
      startDate: yup
        .string()
        .nullable()
        .required("시작일을 선택해주세요"),
      endDate: yup
        .string()
        .nullable()
        .required("종료일을 선택해주세요"),
      timeSchedule: yup
        .string()
        .nullable()
        .required("시간을 선택해주세요"),
      contents: yup
        .string()
        .nullable()
        .required("내용을 입력해주세요"),
      remark: yup.string().nullable(),
    })
    .required();

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  /* #endregion */

  /* #region useEffect ----------------------------------------------------------------------------- */
  useEffect(() => {
    reset(initialState);
    // eslint-disable-next-line
  }, []);
  /* #endregion */

  /* #region 기타함수 ----------------------------------------------------------------------------- */

  const closeModal = () => {
    setSelectModalVisible(false);
    dataGridRef.current.instance.option("focusedRowIndex", -1);
    reset(initialState);
  };

  const onSubmit = async (data) => {
    try {
      setIsLoading(true);
      if (!selectedRowKey) {
        await requestServer.postAppPush(data);
      } else {
        await requestServer.updateAppPush(selectedRowKey, data);
      }

      dataGridRef.current.instance.refresh();
      dataGridRef.current.instance.option("focusedRowIndex", -1);
      reset(initialState);
    } catch (err) {
      alert(err.response?.data?.message || "ERROR", "오류");
    } finally {
      setIsLoading(false);
    }
  };

  const onError = (errors) => {
    console.log(errors);
  };

  const onFocusedRowChanged = useCallback((e) => {
    if (!e.row) return;
    const rowData = {
      ...e.row.data,
      startDate: e.row.data.startDate ? e.row.data.startDate : "",
      endDate: e.row.data.endDate ? e.row.data.endDate : "",
      timeSchedule: e.row.data.timeSchedule ? e.row.data.timeSchedule : "",
      contents: e.row.data.contents ? e.row.data.contents : "",
      remark: e.row.data.remark ? e.row.data.remark : "",
    };
    setSelectedRowKey(e.row.key);
    reset(rowData);
    // eslint-disable-next-line
  }, []);

  const onClickDelete = async () => {
    if (!selectedRowKey) return;
    const result = await confirm("정말로 삭제 하시겠습니까?", "경고");
    if (!result) return;
    try {
      setIsLoading(true);

      await requestServer.deleteAppPush(selectedRowKey);

      dataGridRef.current.instance.refresh();
      dataGridRef.current.instance.option("focusedRowIndex", -1);
      setSelectedRowKey(null);
      reset(initialState);
    } catch (err) {
      alert(err.response?.data?.message || "ERROR", "오류");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <PanelInit>
        <PanelHeader>
          <HeaderTitle />
          <PanelBtn>
            <CustomButton
              type="button"
              height={24}
              layout="solid"
              color="new"
              label="새입력"
              onClick={() => {
                reset(initialState);
                setSelectedRowKey(null);
                dataGridRef.current.instance.option("focusedRowIndex", -1);
              }}
            />
            <CustomButton
              type="button"
              height={24}
              layout="solid"
              color="common"
              label="단건 푸쉬 보내기"
              onClick={() => setSelectModalVisible(true)}
            />
            <CustomButton
              type="button"
              height={24}
              layout="solid"
              color="esc"
              label="삭제"
              onClick={onClickDelete}
            />
          </PanelBtn>
        </PanelHeader>

        <PanelBody row left="400px" right="calc(100% - 400px)">
          <PanelInputWrapper
            onSubmit={handleSubmit(onSubmit, onError)}
            openInputBar={true}
          >
            <PanelInputTitle>푸쉬등록</PanelInputTitle>
            <PanelInput grid={2}>
              <InputDate
                control={control}
                errors={errors}
                name="startDate"
                label="시작일"
              />
              <InputDate
                control={control}
                errors={errors}
                name="endDate"
                label="종료일"
              />
              <InputText
                control={control}
                errors={errors}
                mask={"00:00:00"}
                name="timeSchedule"
                label="시간"
              />
              <InputFullRow grid={2}>
                <InputTextArea
                  control={control}
                  errors={errors}
                  height={80}
                  name="contents"
                  label="내용"
                />
              </InputFullRow>
              <InputFullRow grid={2}>
                <InputTextArea
                  control={control}
                  errors={errors}
                  height={80}
                  name="remark"
                  label="비고"
                />
              </InputFullRow>
            </PanelInput>
            <ButtonGroup marginTop={20}>
              <CustomButton
                type="submit"
                width="100%"
                height={36}
                layout="solid"
                color="common"
                label="등록"
                className={`${isLoading &&
                  "spinner spinner-white spinner-right"}`}
              />
            </ButtonGroup>
          </PanelInputWrapper>
          <PushAppMaster
            onFocusedRowChanged={onFocusedRowChanged}
            dataGridRef={dataGridRef}
          />
        </PanelBody>
      </PanelInit>
      {selectModalVisible && (
        <PushAppModal
          onClose={closeModal}
          maskClosable={false}
          dataGridRef={dataGridRef}
          visible={true}
        />
      )}
    </>
  );
};

export default PushAppPage;
