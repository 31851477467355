import { DataGrid } from "devextreme-react";
import { Column, Editing, HeaderFilter } from "devextreme-react/data-grid";
import React, { useEffect, useState } from "react";
import { InputTextArea } from "../../../../components/InputForm";
import { ModalHeader } from "../../../../components/Modal";
import ModalContainer from "../../../../components/Modal/ModalContainer";

const FileSelectModal = ({ state, modalProps }) => {
  const [dataSource, setDataSource] = useState([]);
  const { modalType } = state;
  const {
    modalGridRef,
    inputForm,
    modalForm,
    closeModal: onClose,
    onModalSubmit,
    subModalOpen,
  } = modalProps;

  const { watch } = inputForm;

  const {
    setValue,
    control,
    formState: { errors },
    handleSubmit,
  } = modalForm;

  useEffect(() => {
    setDataSource(watch(`${modalType.name}Files`));
    setValue(
      "dispatchComment",
      watch(`dispatchComment_0${modalType.index + 1}`) || ""
    );
    // eslint-disable-next-line
  }, []);

  const onToolbarPreparing = (e) => {
    const items = e.toolbarOptions.items;
    for (const item of items) {
      if (item.name === "addRowButton") {
        item.options.onClick = subModalOpen;
      }
      if (item.name === "saveButton") {
        item.visible = false;
      }
    }
  };

  // db만들고

  return (
    <>
      <ModalContainer
        onClose={onClose}
        maskClosable={false}
        visible={true}
        size="xl"
      >
        <form
          className="modal-form"
          onSubmit={handleSubmit((data) => onModalSubmit(data, modalType))}
        >
          <ModalHeader onClose={onClose} title="파일첨부" />

          <div className="modal-form-body">
            <DataGrid
              className="datagrid-wrap single-line has-toolbar"
              id="DispatchFileSelectModal"
              ref={modalGridRef}
              dataSource={dataSource}
              showRowLines={true}
              showBorders={true}
              hoverStateEnabled={true}
              allowColumnResizing={true}
              allowColumnReordering={true}
              columnResizingMode="widget"
              keyExpr="Oid"
              focusedRowEnabled={true}
              onEditingStart={(e) => {
                e.cancel = true;
              }}
              onToolbarPreparing={onToolbarPreparing}
            >
              <HeaderFilter visible={true} />
              <Editing mode="batch" allowAdding={true} allowDeleting={true} />
              <Column caption="No" cellRender={(e) => e.row.loadIndex + 1} />
              <Column dataField="Oid" visible={false} />
              <Column dataField="fileListCodeName" caption="구분" />
              <Column dataField="File_Name" caption="관리명" />
              <Column dataField="File_Info" caption="파일" />
              <Column
                dataField="Reg_Date"
                caption="등록일"
                dataType="date"
                format="yyyy-MM-dd"
              />
              <Column dataField="useYnName" caption="사용여부" />
              <Column dataField="Remark" caption="비고" />
            </DataGrid>

            <div className="d-flex flex-row mb-1">
              <div className="dx-field">
                <InputTextArea
                  control={control}
                  errors={errors}
                  required={true}
                  name="dispatchComment"
                  label="배차 관련 메세지"
                  flex={true}
                />
              </div>
            </div>
          </div>

          <div className="modal-form-footer">
            <button type="submit" className="btn btn-modal btn-save">
              저장
            </button>
          </div>
        </form>
      </ModalContainer>
    </>
  );
};

export default FileSelectModal;
