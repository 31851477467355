import { DateBox } from "devextreme-react";
import React from "react";
import { Controller } from "react-hook-form";
import moment from "moment";
import InputLayout from "./InputLayout";

const InputDate = ({
  control,
  errors,
  name,
  label,
  labelWidth,
  flex,
  disable = false,
  required,
  defaultValue,
  onValueChanged = () => {},
  onInitialized = () => {},
  displayFormat = "yyyy-MM-dd",
  width,
}) => {
  return (
    <InputLayout
      label={label}
      required={required}
      errors={errors}
      name={name}
      labelWidth={labelWidth}
    >
      <Controller
        render={({ field: { onChange, value } }) => {
          if (value === "Invalid Date") value = null;
          return (
            <DateBox
              type="date"
              displayFormat={displayFormat}
              mask={"0000-00-00"}
              onValueChange={(value) => {
                // 이거 YYYYMMDD로 바꾸면 선석안됨
                if (value) onChange(moment(value).format("YYYY-MM-DD"));
              }}
              value={value}
              disabled={disable}
              onValueChanged={onValueChanged}
              onInitialized={onInitialized}
              width={width}
            />
          );
        }}
        control={control}
        name={name}
        defaultValue={null}
      />
    </InputLayout>
  );
};

export default InputDate;
