import { yupResolver } from "@hookform/resolvers/yup";
import { alert, confirm } from "devextreme/ui/dialog";
import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";

import CustomButton from "../../../components/FunctionButton/CustomButton";
import { ButtonGroup } from "../../../components/FunctionButton/CustomButton/customButton.style";
import FunctionButton from "../../../components/FunctionButton/FunctionButton";
import InputLookup from "../../../components/InputForm/InputLookup";
import InputNumber from "../../../components/InputForm/InputNumber";
import InputSelect from "../../../components/InputForm/InputSelect";
import InputText from "../../../components/InputForm/InputText";
import HeaderTitle from "../../../components/Layout/Header/HeaderTitle";
import {
  PanelInput,
  PanelInputTitle,
  PanelInputWrapper,
} from "../../../components/Layout/Panel/PanelInput/panelInput.style";
import {
  PanelBody,
  PanelBtn,
  PanelHeader,
  PanelInit,
} from "../../../components/Layout/Panel/panel.style";
import { useDatagrid } from "../../../hooks/useDatagrid";
import * as requestServer from "./ShuttleBasicCodeCrud";
import ShuttleBasicCodeMaster from "./ShuttleBasicCodeMaster";

function ShuttleBasicCodePage(props) {
  /* #region useState, useRef -----------------------------------------------------------------------*/

  const [selectedRowKey, setSelectedRowKey] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [lookupData, setLookupData] = useState({
    billing: [],
    conType: [],
    terminal: [],
    workPlace: [],
    cargoOwner: [],
  });
  const dataGridRef = useRef();
  useDatagrid(dataGridRef);

  /* #endregion */

  /* #region initialState, react-hook-form, yup ---------------------------------------------------- */
  const initialState = {
    sortNo: undefined,
    importExport: 1,
    billingPlace: undefined,
    containerType: undefined,
    containerNo: "",
    loadPlace: undefined,
    workPlace: undefined,
    unloadPlace: undefined,
    freight: 0,
    habul01: 0,
    habul02: 0,
  };

  const schema = yup.object({
    sortNo: yup.string().required("정렬순서를 입력하세요"),
    importExport: yup.string().required("수출/수입을 선택하세요"),
    cargoOwner: yup
      .string()
      .nullable()
      .required("화주를 선택하세요"),
    billingPlace: yup
      .string()
      .nullable()
      .required("청구처를 선택하세요"),
    containerType: yup
      .string()
      .nullable()
      .required("컨규격을 선택하세요"),
    containerNo: yup.string().required("컨테이너번호를 입력하세요"),
    loadPlace: yup
      .string()
      .nullable()
      .required("상차지를 선택하세요"),
    workPlace: yup.string().nullable(),
    unloadPlace: yup.string().nullable(),
    freight: yup.number().required("청구금액을 입력하세요"),
    habul01: yup.number(),
    habul02: yup.number(),
  });

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  /* #endregion */

  /* #region useEffect ----------------------------------------------------------------------------- */

  useEffect(() => {
    getLookup();
    reset(initialState);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    initInputData(selectedRowKey);
    // eslint-disable-next-line
  }, [selectedRowKey]);

  const initInputData = async (key) => {
    try {
      const { data } = await requestServer.getShuttleData(key);

      reset(data);
    } catch (error) {
      console.log(error);
    }
  };
  /* #endregion */

  /* #region 기타함수 ----------------------------------------------------------------------------- */

  const onClickAdd = () => {
    reset(initialState);
    setSelectedRowKey(null);
    dataGridRef.current.instance.option("focusedRowIndex", -1);
  };

  const onClickDelete = async () => {
    if (!selectedRowKey) {
      alert("데이터를 선택해주세요", "오류");
      return;
    }

    let response = await confirm("<i>삭제하시겠습니까?</i>", "삭제 메세지");

    if (!response) return;
    try {
      setIsLoading(true);
      await requestServer.deleteRow(selectedRowKey);

      dataGridRef.current.instance.refresh();
      setSelectedRowKey(null);
      dataGridRef.current.instance.option("focusedRowIndex", -1);
      reset(initialState);
    } catch (err) {
      alert(err.response?.data?.message || "ERROR", "오류");
    } finally {
      setIsLoading(false);
    }
  };

  const onSubmit = async (data) => {
    try {
      setIsLoading(true);
      let code;
      if (selectedRowKey) {
        await requestServer.updateShuttleBasicCode(selectedRowKey, data);
      } else {
        const {
          data: { result },
        } = await requestServer.createShuttleBasicCode(data);

        code = result?.Oid;
      }
      dataGridRef.current.instance.refresh();
      selectedRowKey && initInputData(selectedRowKey);
      code && dataGridRef.current.instance.option("focusedRowKey", code);
    } catch (err) {
      alert(err.response?.data?.message || "ERROR", "오류");
    } finally {
      setIsLoading(false);
    }
  };

  const onFocusedRowChanged = (e) => {
    if (!e.row) return;
    setSelectedRowKey(e.row.key);
  };

  async function getLookup() {
    try {
      const billingPlaceData = await requestServer.getLookup(
        "companyManage/lookup/isBilling"
      );
      const terminalData = await requestServer.getLookup("terminal/terminal");
      const conTypeData = await requestServer.getLookup("comcode/detail/10400");
      const workPlaceData = await requestServer.getLookup("terminal/workplace");
      const cargoOwnerData = await requestServer.getLookup(
        "companyManage/lookup/isCargoOwner"
      );

      setLookupData({
        billing: billingPlaceData.data,
        conType: conTypeData.data,
        terminal: terminalData.data,
        workPlace: workPlaceData.data,
        cargoOwner: cargoOwnerData.data,
      });
    } catch (err) {
      console.log(err);
    }
  }

  /* #endregion */

  return (
    <>
      <PanelInit>
        <PanelHeader>
          <HeaderTitle />
          <PanelBtn>
            <FunctionButton
              onClickAdd={onClickAdd}
              onClickDelete={onClickDelete}
            />
          </PanelBtn>
        </PanelHeader>

        <PanelBody row left="400px" right="calc(100% - 400px)">
          <PanelInputWrapper
            onSubmit={handleSubmit(onSubmit)}
            openInputBar={true}
          >
            <PanelInputTitle>셔틀 등록</PanelInputTitle>
            <PanelInput grid={2}>
              <InputText
                control={control}
                errors={errors}
                name="sortNo"
                label="정렬순서"
                required={true}
              />
              <InputSelect
                control={control}
                errors={errors}
                name="importExport"
                label="수출/수입"
                dataSource={[
                  { id: 1, name: "수입" },
                  { id: 2, name: "수출" },
                ]}
                valueExpr="id"
                displayExpr="name"
              />
              <InputLookup
                control={control}
                errors={errors}
                dataSource={lookupData.cargoOwner}
                valueExpr="oid"
                displayExpr="company"
                name="cargoOwner"
                label="화주"
                required={true}
              />
              <InputLookup
                control={control}
                errors={errors}
                name="billingPlace"
                label="청구처"
                dataSource={lookupData.billing}
                displayExpr="company"
                valueExpr="oid"
                required={true}
              />
              <InputLookup
                control={control}
                errors={errors}
                name="containerType"
                label="컨규격"
                dataSource={lookupData.conType}
                valueExpr="subCode"
                displayExpr="subName"
                required={true}
              />
              <InputText
                control={control}
                errors={errors}
                name="containerNo"
                label="컨테이너No"
                required={true}
              />
              <InputLookup
                control={control}
                errors={errors}
                name="loadPlace"
                label="상차지"
                dataSource={lookupData.terminal}
                valueExpr="code"
                displayExpr="code"
                required={true}
              />
              <InputLookup
                control={control}
                errors={errors}
                name="workPlace"
                label="작업지"
                dataSource={lookupData.workPlace}
                valueExpr="code"
                displayExpr="twName"
              />
              <InputLookup
                control={control}
                errors={errors}
                name="unloadPlace"
                label="하차지"
                dataSource={lookupData.terminal}
                valueExpr="code"
                displayExpr="code"
              />
              <InputNumber
                control={control}
                errors={errors}
                name="freight"
                label="청구금액"
                required={true}
              />
              <InputNumber
                control={control}
                errors={errors}
                name="habul01"
                label="상차지 하불금액"
              />
              <InputNumber
                control={control}
                errors={errors}
                name="habul02"
                label="하차지 하불금액"
              />
            </PanelInput>

            <ButtonGroup marginTop={20}>
              <CustomButton
                type="submit"
                width="100%"
                height={36}
                layout="solid"
                color="common"
                label="저장"
                isLoading={isLoading}
              />
            </ButtonGroup>
          </PanelInputWrapper>
          <ShuttleBasicCodeMaster
            onFocusedRowChanged={onFocusedRowChanged}
            dataGridRef={dataGridRef}
          />
        </PanelBody>
      </PanelInit>
    </>
  );
}

export default ShuttleBasicCodePage;
