import { DataGrid } from "devextreme-react";
import {
  Column,
  ColumnChooser,
  FilterRow,
  Format,
  Scrolling,
  StateStoring,
} from "devextreme-react/data-grid";
import { alert, confirm } from "devextreme/ui/dialog";
import React, { useCallback, useEffect, useState } from "react";
import CustomButton from "../../../../components/FunctionButton/CustomButton";
import { ButtonGroup } from "../../../../components/FunctionButton/CustomButton/customButton.style";
import InputTextArea from "../../../../components/InputForm/InputTextArea";
import {
  DataCount,
  GridTapContent,
  GridTapWrapper,
  PanelGridInit,
  PanelGridInput,
  PanelGridInputWrapper,
  PanelSubGridInit,
} from "../../../../components/Layout/Panel/PanelGrid/panelGrid.style";
import {
  InputFullRow,
  PanelInput,
} from "../../../../components/Layout/Panel/PanelInput/panelInput.style";
import StateStoringSaveAndDeleteButton from "../../../../components/StateStoring";
import { deleteMemo, postMemo, putMemo } from "../NonPaymentCrud";

const NonPaymentLower = ({
  state,
  manageInfoForm,
  detailGridRef,
  accountHistoryRef,
  manageInfoRef,
}) => {
  const {
    rowData,
    detailGridDataSource,
    accountHistoryDataSource,
    manageInfoDataSource,
  } = state;
  const storageKey1 = "nonPaymentLower1";
  const storageKey2 = "nonPaymentLower2";
  const [totalCount01, setTotalCount01] = useState(0);
  const [totalCount02, setTotalCount02] = useState(0);
  const [activeIndex, setActiveIndex] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedManagedRow, setSelectedManagedRow] = useState(null);

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = manageInfoForm;

  useEffect(() => {
    setSelectedManagedRow(null);
    // eslint-disable-next-line
  }, [rowData]);

  const onManageFocusedRowChanged = (e) => {
    setSelectedManagedRow(e.row.data);
    reset(e.row.data);
  };

  const onSubmit = async (data) => {
    data.companyCode = rowData.company;

    try {
      setIsLoading(true);
      if (selectedManagedRow?.oid) {
        await putMemo(data);
      } else {
        await postMemo({ ...data, key: selectedManagedRow?.oid });
      }

      manageInfoRef.current.instance.refresh();
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const onClickDeleteMemo = async () => {
    if (!selectedManagedRow?.oid) {
      alert("관리정보를 선택해주세요", "확인");
      return;
    }

    const result = await confirm("삭제하시겠습니까?", "확인");
    if (!result) return;

    try {
      await deleteMemo(selectedManagedRow?.oid);

      setSelectedManagedRow(null);
      manageInfoRef.current.instance.refresh();
    } catch (error) {
      console.log(error);
    }
  };

  const contained = [
    {
      tab: (
        <li
          key={1}
          onClick={() => setActiveIndex(0)}
          className={`grid-tap-item ${activeIndex === 0 ? "active" : ""}`}
        >
          상세내용
        </li>
      ),
      content: (
        <PanelSubGridInit gridSub gridItems column>
          <StateStoringSaveAndDeleteButton
            dataGridRef={detailGridRef}
            storageKey={storageKey1}
            gridItems
          />
          <DataCount gridItems>{`총 ${totalCount01}건`}</DataCount>
          <DataGrid
            className="datagrid-wrap"
            id="NonPaymentLower1"
            dataSource={detailGridDataSource}
            ref={detailGridRef}
            showRowLines={true}
            showBorders={true}
            hoverStateEnabled={true}
            allowColumnResizing={true}
            allowColumnReordering={true}
            columnResizingMode="widget"
            keyExpr="id"
            focusedRowEnabled={true}
            onContentReady={(e) => setTotalCount01(e.component.totalCount())}
          >
            <FilterRow visible={true} />
            <Scrolling mode="virtual" />
            <ColumnChooser enabled={true} />
            <StateStoring
              enabled={true}
              type="custom"
              customLoad={useCallback(() => {
                return JSON.parse(localStorage.getItem(storageKey1));
              }, [])}
            />
            <Column dataField="companyName" caption="접수법인" width="110" />
            <Column dataField="code" caption="접수번호" width="110" />
            <Column dataField="receiveDate" caption="접수일자" width="110" />
            <Column dataField="deliverDate" caption="운송일자" width="110" />
            <Column dataField="completeNo" caption="마감번호" width="110" />
            <Column dataField="completeDate" caption="마감일자" width="110" />
            <Column dataField="closeStatus" caption="정산상태" width="110" />
            <Column
              dataField="price"
              caption="금액"
              dataType="number"
              width="110"
            >
              <Format type="fixedPoint" />
            </Column>
            <Column
              dataField="nonPayment"
              caption="미지급금"
              dataType="number"
              width="110"
            >
              <Format type="fixedPoint" />
            </Column>
            <Column dataField="containerNo" caption="컨테이너No" width="110" />
            <Column dataField="containerType" caption="컨규격" width="110" />
            <Column dataField="remark" caption="비고" width="110" />
            <Column dataField="dispatchTeamName" caption="배차팀" width="110" />
            <Column dataField="billingPlaceName" caption="청구처" width="110" />
            <Column dataField="orderCompany" caption="발주처" width="110" />
            <Column dataField="forwarder" caption="선사" width="110" />
            <Column dataField="shipName" caption="선명" width="110" />
            <Column dataField="motherShip" caption="모선항차" width="110" />
            <Column dataField="cargoOwnerName" caption="화주" width="110" />
            <Column dataField="dem" caption="선사반출기한" width="110" />
            <Column dataField="enterDay" caption="입항일" width="110" />
            <Column dataField="sailDay" caption="출항일" width="110" />
            <Column dataField="putDirect" caption="접안일" width="110" />
            <Column dataField="loadPlace" caption="상차지" width="110" />
            <Column dataField="unloadPlace" caption="하차지" width="110" />
            <Column dataField="blNo" caption="BL NO" width="110" />
            <Column dataField="bkNo" caption="BK NO" width="110" />
            <Column dataField="doNo" caption="DO NO" width="110" />
          </DataGrid>
        </PanelSubGridInit>
      ),
    },
    {
      tab: (
        <li
          key={2}
          onClick={() => setActiveIndex(1)}
          className={`grid-tap-item ${activeIndex === 1 ? "active" : ""}`}
        >
          통장확인내역
        </li>
      ),
      content: (
        <PanelSubGridInit gridSub gridItems column>
          <StateStoringSaveAndDeleteButton
            dataGridRef={accountHistoryRef}
            storageKey={storageKey2}
            gridItems
          />
          <DataCount gridItems>{`총 ${totalCount02}건`}</DataCount>
          <DataGrid
            className="datagrid-wrap"
            id="NonPaymentLower2"
            dataSource={accountHistoryDataSource}
            ref={accountHistoryRef}
            showRowLines={true}
            showBorders={true}
            hoverStateEnabled={true}
            allowColumnResizing={true}
            allowColumnReordering={true}
            columnResizingMode="widget"
            keyExpr="TransRefKey"
            focusedRowEnabled={true}
            onContentReady={(e) => setTotalCount02(e.component.totalCount())}
          >
            <FilterRow visible={true} />
            <Scrolling mode="virtual" />
            <ColumnChooser enabled={true} />
            <StateStoring
              enabled={true}
              type="custom"
              customLoad={useCallback(() => {
                return JSON.parse(localStorage.getItem(storageKey2));
              }, [])}
            />
            <Column
              dataField="TransRefKey"
              caption="일련번호"
              alignment="center"
            />
            <Column dataField="TransDT" caption="거래일시" alignment="center" />
            <Column
              dataField="Withdraw"
              caption="출금"
              alignment="right"
              dataType="number"
            >
              <Format type="fixedPoint" />
            </Column>
            <Column
              dataField="Deposit"
              caption="입금"
              alignment="right"
              dataType="number"
            >
              <Format type="fixedPoint" />
            </Column>
            <Column
              dataField="Balance"
              caption="잔액"
              alignment="right"
              dataType="number"
            >
              <Format type="fixedPoint" />
            </Column>
            <Column dataField="TransOffice" caption="상대은행" />
            <Column dataField="TransRemark" caption="상대명" />
          </DataGrid>
        </PanelSubGridInit>
      ),
    },
    {
      tab: (
        <li
          key={3}
          onClick={() => setActiveIndex(2)}
          className={`grid-tap-item ${activeIndex === 2 ? "active" : ""}`}
        >
          관리정보
        </li>
      ),
      content: (
        <PanelGridInputWrapper row left="70%" right="calc(30% - 10px)">
          <PanelSubGridInit gridSub gridItems column>
            <DataCount gridItems>{`총 ${totalCount02}건`}</DataCount>
            <DataGrid
              className="datagrid-wrap"
              id="NonPaymentLower3"
              dataSource={manageInfoDataSource}
              ref={manageInfoRef}
              showRowLines={true}
              showBorders={true}
              hoverStateEnabled={true}
              allowColumnResizing={true}
              allowColumnReordering={true}
              columnResizingMode="widget"
              keyExpr="oid"
              focusedRowEnabled={true}
              onFocusedRowChanged={onManageFocusedRowChanged}
              onContentReady={(e) => setTotalCount02(e.component.totalCount())}
            >
              <FilterRow visible={true} />
              <ColumnChooser enabled={true} />
              <Scrolling mode="virtual" />
              <Column
                caption="No"
                cellRender={(e) => e.row.loadIndex + 1}
                width="30"
              />
              <Column dataField="content" caption="내용" />
              <Column dataField="registerDate" caption="등록일" width="110" />
            </DataGrid>
          </PanelSubGridInit>
          <PanelGridInput onSubmit={handleSubmit(onSubmit)}>
            <PanelInput grid={2}>
              <InputFullRow grid={2}>
                <InputTextArea
                  control={control}
                  errors={errors}
                  height={80}
                  name="content"
                  label="내용"
                />
              </InputFullRow>
            </PanelInput>

            <ButtonGroup marginTop={20}>
              <CustomButton
                type="submit"
                width="100%"
                height={36}
                layout="solid"
                color="common"
                label="저장"
                className={`${isLoading &&
                  "spinner spinner-white spinner-right"}`}
              />
              <CustomButton
                type="button"
                width="100%"
                height={36}
                layout="solid"
                color="esc"
                label="삭제"
                className={`${isLoading &&
                  "spinner spinner-white spinner-right"}`}
                onClick={onClickDeleteMemo}
              />
            </ButtonGroup>
          </PanelGridInput>
        </PanelGridInputWrapper>
      ),
    },
  ];

  return (
    <PanelGridInit gridSub gridItems tabItems column>
      <GridTapWrapper>
        {contained.map((section, index) => section.tab)}
      </GridTapWrapper>
      <GridTapContent>{contained[activeIndex].content}</GridTapContent>
    </PanelGridInit>
  );
};

export default NonPaymentLower;
