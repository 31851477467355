import { DataGrid } from "devextreme-react";
import { Column, Format } from "devextreme-react/data-grid";
import React, { useEffect, useState } from "react";

const LCLOrderDetailGrid = ({ data }) => {
  const [dataSource, setDataSource] = useState([]);
  // const extraInfo = data?.data?.fridge || data?.data?.danger;
  useEffect(() => {
    setDataSource(data?.data?.detail);
  }, [data]);
  return (
    <DataGrid
      keyExpr="id"
      showBorders={true}
      columnAutoWidth={true}
      dataSource={dataSource}
      allowColumnResizing={true}
      columnResizingMode="widget"
    >
      {/* <Column cellRender={(e) => e.row.loadIndex + 1} /> */}
      <Column dataField="subName" caption="오더상태" alignment="center" />
      <Column dataField="deliverDate" caption="운송일자" alignment="center" />
      <Column
        dataField="containerNo"
        caption="컨테이너번호"
        alignment="center"
      />
      <Column dataField="sealNo" caption="Seal No" alignment="center" />
      <Column dataField="containerType" caption="컨/규격" alignment="center" />
      <Column
        dataField="workWaypoint"
        caption="작업경유지"
        alignment="center"
      />
      <Column dataField="workPlace" caption="작업지" alignment="center" />
      <Column
        dataField="collectWaypoint"
        caption="회수경유지"
        alignment="center"
      />
      <Column
        dataField="returnDate"
        caption="반납마감일자"
        alignment="center"
        dataType="date"
        format="yyyy-MM-dd"
      />
      <Column
        dataField="freight"
        caption="청구금액"
        alignment="right"
        dataType="number"
      >
        <Format type="fixedPoint" />
      </Column>
      <Column
        dataField="extraCharge"
        caption="할증금액"
        alignment="right"
        dataType="number"
        allowEditing={false}
        // cellRender={extraChargeCellRender}
      >
        <Format type="fixedPoint" />
      </Column>
      <Column dataField="cargoWeight" caption="화물중량" alignment="center" />

      <Column dataField="temperature" caption="온도" alignment="center" />

      <Column dataField="vent" caption="환풍구" alignment="center" />

      <Column dataField="unNo" caption="UN넘버" alignment="center" />

      <Column dataField="class" caption="클래스" alignment="center" />

      <Column dataField="extraInfo" caption="기타정보" alignment="center" />
    </DataGrid>
  );
};

export default LCLOrderDetailGrid;
