import { DataGrid } from "devextreme-react";
import { Column } from "devextreme-react/data-grid";
import React from "react";
import { Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";
import AppContainerImageViewer from "../../../../components/AppContainerImageViewer";
import InputText from "../../../../components/InputForm/InputText";

const AppContainerModal = ({
  onClose,
  maskClosable,
  closable,
  visible,
  dataGridRef,
  selectedRow,
}) => {
  const driverInfo = [
    { name: "체온", path: "", date: "2022-08-06 5:20:00 PM" },
    { name: "혈압", path: "", date: "2022-08-06 5:20:00 PM" },
    { name: "알코올 농도", path: "", date: "2022-08-06 5:20:00 PM" },
  ];
  const containerTemperature = [
    { name: "출문", path: "", date: "2022-08-06 5:20:00 PM" },
    { name: "휴게소도착", path: "", date: "2022-08-06 5:20:00 PM" },
    { name: "터미널도착", path: "", date: "2022-08-06 5:20:00 PM" },
    { name: "CY전원연결", path: "", date: "2022-08-06 5:20:00 PM" },
    { name: "씰사진", path: "", date: "2022-08-06 5:20:00 PM" },
  ];
  const containerDamage = [
    { name: "1", path: "", date: "2022-08-06 5:20:00 PM" },
    { name: "2", path: "", date: "2022-08-06 5:20:00 PM" },
    { name: "3", path: "", date: "2022-08-06 5:20:00 PM" },
    { name: "4", path: "", date: "2022-08-06 5:20:00 PM" },
    { name: "5", path: "", date: "2022-08-06 5:20:00 PM" },
    { name: "6", path: "", date: "2022-08-06 5:20:00 PM" },
    { name: "7", path: "", date: "2022-08-06 5:20:00 PM" },
    { name: "8", path: "", date: "2022-08-06 5:20:00 PM" },
    { name: "9", path: "", date: "2022-08-06 5:20:00 PM" },
    { name: "10", path: "", date: "2022-08-06 5:20:00 PM" },
  ];
  // const [driverInfo, setDriverInfo] = useState([
  //   { name: "체온", path: "", date: "2022-08-06 5:20:00 PM" },
  //   { name: "혈압", path: "", date: "2022-08-06 5:20:00 PM" },
  //   { name: "알코올 농도", path: "", date: "2022-08-06 5:20:00 PM" },
  // ]);
  // const [containerTemperature, setContainerTemperature] = useState([
  //   { name: "출문", path: "", date: "2022-08-06 5:20:00 PM" },
  //   { name: "휴게소도착", path: "", date: "2022-08-06 5:20:00 PM" },
  //   { name: "터미널도착", path: "", date: "2022-08-06 5:20:00 PM" },
  //   { name: "CY전원연결", path: "", date: "2022-08-06 5:20:00 PM" },
  //   { name: "씰사진", path: "", date: "2022-08-06 5:20:00 PM" },
  // ]);
  // const [containerDamage, setContainerDamage] = useState([
  //   { name: "1", path: "", date: "2022-08-06 5:20:00 PM" },
  //   { name: "2", path: "", date: "2022-08-06 5:20:00 PM" },
  //   { name: "3", path: "", date: "2022-08-06 5:20:00 PM" },
  //   { name: "4", path: "", date: "2022-08-06 5:20:00 PM" },
  //   { name: "5", path: "", date: "2022-08-06 5:20:00 PM" },
  //   { name: "6", path: "", date: "2022-08-06 5:20:00 PM" },
  //   { name: "7", path: "", date: "2022-08-06 5:20:00 PM" },
  //   { name: "8", path: "", date: "2022-08-06 5:20:00 PM" },
  //   { name: "9", path: "", date: "2022-08-06 5:20:00 PM" },
  //   { name: "10", path: "", date: "2022-08-06 5:20:00 PM" },
  // ]);

  const {
    control,
    handleSubmit,
    // reset,
    formState: { errors },
  } = useForm({
    // resolver: yupResolver(schema),
  });

  const onMaskClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  const onSubmit = async (data) => {};
  return (
    <>
      <div className={"modal-overlay " + (visible ? "active" : "")} />
      <div
        className={"modal-wrap user-list-modal " + (visible ? "active" : "")}
        onClick={maskClosable ? onMaskClick : null}
        tabIndex="-1"
        visible={`${visible}`}
      >
        <Modal.Dialog centered tabIndex="0" size="xl" animation="true">
          <Modal.Body>
            <form className="modal-form" onSubmit={handleSubmit(onSubmit)}>
              <div className="modal-form-header">
                <div className="modal-form-tit">
                  <span className="modal-tit">컨테이너앱관리</span>
                </div>
                <button
                  type="button"
                  className="btn-modal btn-modal-close"
                  onClick={onClose}
                >
                  <SVG
                    src={toAbsoluteUrl(
                      "/media/svg/icons/Custom/close_black_24dp.svg"
                    )}
                    className="close-icon"
                  />
                </button>
              </div>
              <div className="modal-form-body">
                <div className="input-wrap">
                  <div className="d-flex flex-row mb-1">
                    <div className="dx-field">
                      <InputText
                        control={control}
                        errors={errors}
                        name="test"
                        label="접수일자"
                        flex={true}
                      />
                    </div>
                    <div className="dx-field">
                      <InputText
                        control={control}
                        errors={errors}
                        name="test"
                        label="배차팀"
                        flex={true}
                      />
                    </div>
                    <div className="dx-field">
                      <InputText
                        control={control}
                        errors={errors}
                        name="test"
                        label="청구처"
                        flex={true}
                      />
                    </div>
                    <div className="dx-field">
                      <InputText
                        control={control}
                        errors={errors}
                        name="test"
                        label="발주처"
                        flex={true}
                      />
                    </div>
                    <div className="dx-field">
                      <InputText
                        control={control}
                        errors={errors}
                        name="test"
                        label="기사"
                        flex={true}
                      />
                    </div>
                    <div className="dx-field"></div>
                  </div>

                  <div className="d-flex flex-row mb-1">
                    <div className="dx-field">
                      <InputText
                        control={control}
                        errors={errors}
                        name="test"
                        label="선사"
                        flex={true}
                      />
                    </div>
                    <div className="dx-field">
                      <InputText
                        control={control}
                        errors={errors}
                        name="test"
                        label="선명"
                        flex={true}
                      />
                    </div>
                    <div className="dx-field">
                      <InputText
                        control={control}
                        errors={errors}
                        name="test"
                        label="모선항차"
                        flex={true}
                      />
                    </div>
                    <div className="dx-field">
                      <InputText
                        control={control}
                        errors={errors}
                        name="test"
                        label="선사반출기한"
                        flex={true}
                      />
                    </div>
                    <div className="dx-field"></div>
                    <div className="dx-field"></div>
                  </div>

                  <div className="d-flex flex-row mb-1">
                    <div className="dx-field">
                      <InputText
                        control={control}
                        errors={errors}
                        name="test"
                        label="입항일"
                        flex={true}
                      />
                    </div>
                    <div className="dx-field">
                      <InputText
                        control={control}
                        errors={errors}
                        name="test"
                        label="출항일"
                        flex={true}
                      />
                    </div>
                    <div className="dx-field">
                      <InputText
                        control={control}
                        errors={errors}
                        name="test"
                        label="접안일"
                        flex={true}
                      />
                    </div>
                    <div className="dx-field">
                      <InputText
                        control={control}
                        errors={errors}
                        name="test"
                        label="상차지"
                        flex={true}
                      />
                    </div>
                    <div className="dx-field">
                      <InputText
                        control={control}
                        errors={errors}
                        name="test"
                        label="하차지"
                        flex={true}
                      />
                    </div>
                    <div className="dx-field">
                      <InputText
                        control={control}
                        errors={errors}
                        name="test"
                        label="화주"
                        flex={true}
                      />
                    </div>
                  </div>

                  <div className="d-flex flex-row mb-1">
                    <div className="dx-field">
                      <InputText
                        control={control}
                        errors={errors}
                        name="test"
                        label="BL NO"
                        flex={true}
                      />
                    </div>
                    <div className="dx-field">
                      <InputText
                        control={control}
                        errors={errors}
                        name="test"
                        label="BK NO"
                        flex={true}
                      />
                    </div>
                    <div className="dx-field">
                      <InputText
                        control={control}
                        errors={errors}
                        name="test"
                        label="DO NO"
                        flex={true}
                      />
                    </div>
                    <div className="dx-field">
                      <InputText
                        control={control}
                        errors={errors}
                        name="test"
                        label="컨규격"
                        flex={true}
                      />
                    </div>
                    <div className="dx-field">
                      <InputText
                        control={control}
                        errors={errors}
                        name="test"
                        label="컨테이너 NO"
                        flex={true}
                      />
                    </div>
                    <div className="dx-field">
                      <InputText
                        control={control}
                        errors={errors}
                        name="test"
                        label="비고"
                        flex={true}
                      />
                    </div>
                  </div>

                  <div>기사정보등록</div>
                  <div className="input-grid grid-5">
                    {driverInfo &&
                      driverInfo.map((cur) => (
                        <div className="dx-field">
                          <AppContainerImageViewer data={cur} />
                        </div>
                      ))}
                  </div>

                  <div>컨테이너 온도 등록</div>
                  <div className="input-grid grid-5">
                    {containerTemperature &&
                      containerTemperature.map((cur) => (
                        <div className="dx-field">
                          <AppContainerImageViewer data={cur} />
                        </div>
                      ))}
                  </div>

                  <div>컨테이너 데미지</div>
                  <div className="input-grid grid-5">
                    {containerDamage &&
                      containerDamage.map((cur) => (
                        <div className="dx-field">
                          <AppContainerImageViewer data={cur} />
                        </div>
                      ))}
                  </div>
                </div>

                <DataGrid
                  keyExpr="Oid"
                  className="datagrid-wrap"
                  columnResizingMode="widget"
                  focusedRowEnabled={true}
                  showRowLines={true}
                  showBorders={true}
                  hoverStateEnabled={true}
                >
                  <Column caption="No" dataField="" />
                  <Column caption="구분값" dataField="" />
                  <Column caption="내용" dataField="" />
                  <Column caption="체크값" dataField="" />
                </DataGrid>
              </div>
              <div className="modal-form-footer">
                <button type="submit" className="btn btn-modal btn-save">
                  저장
                </button>
              </div>
            </form>
          </Modal.Body>
        </Modal.Dialog>
      </div>
    </>
  );
};

export default AppContainerModal;
