import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  DataCount,
  PanelGridInit,
} from "../../../../components/Layout/Panel/PanelGrid/panelGrid.style";
import StateStoringSaveAndDeleteButton from "../../../../components/StateStoring";
import { DataGrid } from "devextreme-react";
import {
  Column,
  ColumnChooser,
  FilterRow,
  HeaderFilter,
  MasterDetail,
  Scrolling,
  StateStoring,
  Format,
} from "devextreme-react/data-grid";
import OrderGridDetail from "./OrderGridDetail";
import CustomStore from "devextreme/data/custom_store";
import { sendRequest } from "../../../../../_metronic/_helpers/DataSoureHelpers";
import { useOrderHooks } from "../../../../hooks";

const OrderGrid = ({
  search,
  dataGridRef,
  detailGrid,
  detailDataSource,
  setSelectedRowKey,
}) => {
  const { onCellPrepared, onRowPrepared } = useOrderHooks();
  const storageKey = "makeOrderMaster";
  const [totalCount, setTotalCount] = useState(0);

  const dataSource = useMemo(() => {
    const apiUrl = `${process.env.REACT_APP_API_URL}/order/master`;

    return new CustomStore({
      key: "code",
      load: () => sendRequest(apiUrl, "GET", search),
    });
  }, [search]);

  const importExportRender = useCallback((e) => {
    if (e.data.importExport === 1) {
      return <div>수입</div>;
    } else {
      return <div>수출</div>;
    }
  }, []);

  const onFocusedRowChanged = useCallback(
    (e) => {
      if (!e.row) return;
      setSelectedRowKey(e.row.key);
      detailGrid.current.instance.option("editing.changes", []);
    },
    // eslint-disable-next-line
    [detailGrid]
  );

  return (
    <PanelGridInit>
      <StateStoringSaveAndDeleteButton
        dataGridRef={dataGridRef}
        storageKey={storageKey}
      />
      <DataCount>{`총 ${totalCount}건`}</DataCount>
      <DataGrid
        id="MakeOrderMaster"
        className="datagrid-wrap"
        keyExpr="code"
        dataSource={dataSource}
        ref={dataGridRef}
        showRowLines={true}
        showBorders={true}
        hoverStateEnabled={true}
        allowColumnResizing={true}
        allowColumnReordering={true}
        columnResizingMode="widget"
        focusedRowEnabled={true}
        columnAutoWidth={true}
        onRowPrepared={onRowPrepared}
        onCellPrepared={onCellPrepared}
        onFocusedRowChanged={onFocusedRowChanged}
        onContentReady={(e) => {
          setTotalCount(e.component.totalCount());
        }}
      >
        <FilterRow visible={true} />
        <HeaderFilter visible={true} />
        <ColumnChooser enabled={true} />
        <StateStoring
          enabled={true}
          type="custom"
          customLoad={useCallback(() => {
            return JSON.parse(localStorage.getItem(storageKey));
          }, [])}
        />
        <Scrolling mode="virtual" showScrollbar="always" useNative="false" />
        <Column caption="No" cellRender={(e) => e.row.loadIndex + 1} />
        <Column dataField="confirmKo" caption="오더확정" />
        <Column dataField="code" caption="접수번호" width="110" />
        <Column
          dataField="containerCount"
          caption="컨 갯수"
          width="60"
          alignment="center"
        />
        <Column
          dataField="importExport"
          caption="수입/수출"
          cellRender={importExportRender}
          alignment="center"
          width="60"
        />
        {/* 운송일자 MIN값으로 백에서 불러오기 */}
        <Column
          dataField="deliverDate"
          caption="운송일자"
          alignment="center"
          dataType="date"
          format="yyyy-MM-dd"
          width="80"
        />
        <Column
          dataField="receiveDate"
          caption="접수일자"
          alignment="center"
          dataType="date"
          format="yyyy-MM-dd"
          width="80"
        />
        <Column dataField="companyName" caption="접수법인" alignment="center" />
        <Column dataField="orderCompany" caption="발주처" alignment="center" />
        <Column
          dataField="billingPlaceName"
          caption="청구처"
          alignment="center"
        />
        <Column dataField="forwarder" caption="선사" alignment="center" />
        <Column dataField="shipName" caption="선명" alignment="center" />
        <Column dataField="motherShip" caption="모선항차" alignment="center" />
        <Column
          dataField="dem"
          caption="선사반출기한"
          alignment="center"
          dataType="date"
          format="yyyy-MM-dd"
          width="80"
        />
        <Column dataField="deptName" caption="배차팀" alignment="center" />
        <Column
          dataField="manager"
          caption="청구처담당자"
          alignment="center"
          width="80"
        />
        <Column
          dataField="fridge"
          caption="냉동"
          dataType="boolean"
          width="35"
        />
        <Column
          dataField="danger"
          caption="위험물"
          dataType="boolean"
          width="45"
        />
        <Column dataField="tank" caption="탱크" dataType="boolean" width="35" />
        <Column
          dataField="direct"
          caption="직반입"
          dataType="boolean"
          width="45"
        />
        <Column dataField="blNo" caption="B/L No" alignment="center" />
        <Column dataField="doNo" caption="D/O No" alignment="center" />
        <Column dataField="bkNo" caption="B/K No" alignment="center" />
        <Column dataField="loadPlaceName" caption="상차지" alignment="center" />
        <Column
          dataField="unloadPlaceName"
          caption="하차지"
          alignment="center"
        />
        <Column dataField="cargoOwnerName" caption="화주" alignment="center" />
        <Column
          dataField="realCargoOwner"
          caption="실화주"
          alignment="center"
        />
        <Column dataField="createUsr" caption="최초입력자" alignment="center" />
        <Column dataField="updateUsr" caption="최종수정자" alignment="center" />
        <Column dataField="createDate" caption="작성일" alignment="center" />
        <Column dataField="createTime" caption="작성시간" alignment="center" />
        <Column
          dataField="detailBill"
          caption="청구금액"
          dataType="number"
          alignment="center"
        >
          <Format type="fixedPoint" />
        </Column>

        <MasterDetail
          enabled={true}
          component={({ data }) => (
            <OrderGridDetail data={data} detailDataSource={detailDataSource} />
          )}
        />
      </DataGrid>
    </PanelGridInit>
  );
};

export default OrderGrid;
