import axios from "axios";

const apiUrl = `${process.env.REACT_APP_API_URL}`;
// const appUrl = `https://fcm.googleapis.com/fcm/send`;

export function getLookupDatas(param) {
  return axios.get(apiUrl + `/${param}`);
}

export function changeCarNo(key, data, App_FileManage_Oids) {
  return axios.put(apiUrl + `/dispatch/carno`, {
    key,
    data,
    App_FileManage_Oids,
  });
}

export function changeWorkWaypoint(key, data) {
  return axios.put(apiUrl + `/dispatch/workwaypoint`, { key, data });
}

export function changeWorkPlace(key, data) {
  return axios.put(apiUrl + `/dispatch/workplace`, { key, data });
}

export function changeCollectWaypoint(key, data) {
  return axios.put(apiUrl + `/dispatch/collectwaypoint`, { key, data });
}

export function changeUnloadPlace(key, data) {
  return axios.put(apiUrl + `/dispatch/unloadplace`, { key, data });
}

export function changeFreight(key, data) {
  return axios.put(apiUrl + `/dispatch/freight`, { key, data });
}

export function changeChassis(key, data) {
  return axios.put(apiUrl + `/dispatch/chassis`, { key, data });
}

export function changeFridgeDanger(key, data) {
  return axios.put(apiUrl + `/dispatch/fridgedanger`, { key, data });
}

export function toggleBobtail(key, bobtail, rowData) {
  return axios.put(apiUrl + `/dispatch/bobtail`, { key, bobtail, rowData });
}

export function changeArrival(key, workPlaceArrive) {
  return axios.put(apiUrl + `/dispatch/arrival`, { key, workPlaceArrive });
}

export function changeReturnDate(key, data) {
  return axios.put(apiUrl + `/dispatch/returndate`, { ...key, ...data });
}

export function toggleLongterm(key, longStorage) {
  return axios.put(apiUrl + `/dispatch/longterm`, { key, longStorage });
}

export function changeContainerNo(key, data) {
  return axios.put(apiUrl + `/dispatch/containerno`, { key, data });
}

export function setWarehouseArrive(key, data) {
  return axios.put(apiUrl + `/dispatch/warehouse`, { key, data });
}

export function setExtraInfo(key, data) {
  return axios.put(apiUrl + `/dispatch/extrainfo`, { key, ...data });
}

export function setHabul(data) {
  return axios.put(apiUrl + `/dispatch/habul`, { data });
}

export function changeExtraCharge(data) {
  return axios.put(apiUrl + `/dispatch/extraCharge`, data);
}

export function dispatchFinish(data) {
  return axios.put(apiUrl + `/dispatch/finish`, { data });
}

export function deliverFinish(data) {
  return axios.put(apiUrl + `/dispatch/deliver`, { data });
}

export function cancelDispatch(data) {
  return axios.put(apiUrl + `/dispatch/cancel`, { data });
}

export function getDispatchData(id) {
  return axios.get(apiUrl + `/dispatch/file/${id}`);
}
