import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";

import {
  Column,
  ColumnChooser,
  DataGrid,
  FilterRow,
  HeaderFilter,
  Scrolling,
  StateStoring,
} from "devextreme-react/data-grid";
import InputText from "../../../components/InputForm/InputText";
import StateStoringSaveAndDeleteButton from "../../../components/StateStoring";
import CustomStore from "devextreme/data/custom_store";
import { sendRequest } from "../../../../_metronic/_helpers/DataSoureHelpers";
import {
  DataCount,
  PanelGridInit,
  PanelGridSearchBar,
  PanelGridWrapper,
  SearchItems,
} from "../../../components/Layout/Panel/PanelGrid/panelGrid.style";
import CustomButton from "../../../components/FunctionButton/CustomButton";

const PushAppMaster = ({ onFocusedRowChanged, dataGridRef }) => {
  /* #region useState, useRef -----------------------------------------------------------------------*/
  const [totalCount, setTotalCount] = useState(0);
  const [search, setSearch] = useState({});

  const storageKey = "pushAppMaster";
  /* #endregion */

  /* #region initialState, react-hook-form, yup ---------------------------------------------------- */
  const initialState = {
    Contents: "",
    Remark: "",
  };

  const {
    reset,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  /* #region useEffect ----------------------------------------------------------------------------- */
  useEffect(() => {
    reset(initialState);
    setSearch(initialState);
    // eslint-disable-next-line
  }, []);
  /* #endregion */

  /* #region 기타함수 ----------------------------------------------------------------------------- */

  const dataSource = useMemo(() => {
    const apiUrl = `${process.env.REACT_APP_API_URL}/app-push`;
    return new CustomStore({
      key: "oid",
      load: () => sendRequest(apiUrl, "GET", search),
    });
  }, [search]);

  const onSubmit = (data) => {
    for (const obj in data) {
      if (data[obj] === "") {
        data[obj] = null;
      }
    }
    setSearch(data);
  };

  return (
    <>
      <PanelGridWrapper>
        <PanelGridSearchBar onSubmit={handleSubmit(onSubmit)}>
          <SearchItems>
            <label>내용</label>
            <InputText
              control={control}
              errors={errors}
              name="Contents"
              flex={true}
            />
          </SearchItems>
          <SearchItems>
            <label>비고</label>
            <InputText
              control={control}
              errors={errors}
              name="Remark"
              flex={true}
            />
          </SearchItems>
          <CustomButton
            type="submit"
            height={24}
            layout="solid"
            color="search"
            label="검색"
          />
        </PanelGridSearchBar>

        <PanelGridInit>
          <StateStoringSaveAndDeleteButton
            dataGridRef={dataGridRef}
            storageKey={storageKey}
          />
          <DataCount>{`총 ${totalCount}건`}</DataCount>
          <DataGrid
            className="datagrid-wrap"
            id="PushApp"
            ref={dataGridRef}
            dataSource={dataSource}
            keyExpr="oid"
            showRowLines={true}
            showBorders={true}
            hoverStateEnabled={true}
            allowColumnResizing={true}
            allowColumnReordering={true}
            columnResizingMode="widget"
            focusedRowEnabled={true}
            columnAutoWidth={true}
            onFocusedRowChanged={onFocusedRowChanged}
            onContentReady={(e) => setTotalCount(e.component.totalCount())}
          >
            <FilterRow visible={true} />
            <HeaderFilter visible={true} />
            <ColumnChooser enabled={true} />
            <StateStoring
              enabled={true}
              type="custom"
              customLoad={useCallback(() => {
                return JSON.parse(localStorage.getItem(storageKey));
              }, [])}
            />
            <Scrolling
              columnRenderingMode="virtual"
              mode="virtual"
              showScrollbar="always"
              useNative="false"
            />
            <Column
              caption="No"
              cellRender={(e) => e.row.loadIndex + 1}
              alignment="center"
            />
            <Column dataField="startDate" caption="시작일" alignment="center" />
            <Column dataField="endDate" caption="종료일" alignment="center" />
            <Column
              dataField="timeSchedule"
              caption="시간"
              alignment="center"
            />
            <Column dataField="contents" caption="내용" alignment="center" />
            <Column dataField="remark" caption="비고" alignment="center" />
          </DataGrid>
        </PanelGridInit>
      </PanelGridWrapper>
    </>
  );
};

export default PushAppMaster;
