import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../_metronic/layout/components/aside/aside-menu/_redux/menuActions";

import useDynTabs from "react-dyn-tabs";
import "react-dyn-tabs/style/react-dyn-tabs.css";
import "react-dyn-tabs/themes/react-dyn-tabs-card.css";
import { getMenu } from "../../_metronic/layout/components/aside/aside-menu/_redux/menuList";
// import { AppContext } from "../App";

function TestPage() {
  const dispatch = useDispatch();
  const [chagedItemId, setChagedItemId] = useState({});
  const { selectedItem, menuAll, openItem } = useSelector(
    (state) => ({
      selectedItem: state.menu.selectedItem,
      menuItems: state.menu.menuItems,
      menuAll: state.menu.menuAll,
      openItem: state.menu.openItem,
    }),
    shallowEqual
  );

  const options = {
    onChange: function(e) {
      setChagedItemId(e.currentData.selectedTabID);
    },
  };

  const [Tablist, Panellist, ready] = useDynTabs(options);
  let _instance = null;

  ready((instance) => {
    _instance = instance;
  });

  useEffect(() => {
    if (openItem !== {} && openItem.menuId) {
      let menu = getMenu(openItem.menuId);
      if (menu) {
        menu.title = openItem.menu.menuName;
      } else {
        menu = {
          id: openItem.menuId,
          title: "NotFound",
          panelComponent: (porps) => {
            console.log(porps);
            return <>페이지가 없습니다.</>;
          },
        };
      }

      _instance.open(menu).then(() => {});
    }
    // eslint-disable-next-line
  }, [openItem]);

  useEffect(() => {
    if (selectedItem?.menuId) {
      _instance.select(selectedItem.menuId).then(() => {});
    }
    // eslint-disable-next-line
  }, [selectedItem]);

  useEffect(() => {
    if (
      chagedItemId &&
      menuAll.length > 0 &&
      selectedItem?.menuId !== chagedItemId
    ) {
      const item = menuAll.filter((obj) => obj.menuId === chagedItemId) || [];
      dispatch(actions.setSelectedItem(item[0]));
    }
    // eslint-disable-next-line
  }, [chagedItemId]);

  // const onClickRead = () => {
  //   if (gridRef.current) {
  //     console.log("read");
  //     gridRef.current.instance.cancelEditData();
  //     gridRef.current.instance.refresh();
  //   }
  // };

  // const onClickAdd = () => {
  //   if (gridRef.current) {
  //     console.log("add");
  //     gridRef.current.instance.addRow();
  //   }
  // };

  // const onClickSave = () => {
  //   if (gridRef.current) {
  //     console.log("save");
  //     gridRef.current.instance.saveEditData();
  //   }
  // };

  // const onClickDelete = () => {
  //   if (gridRef.current) {
  //     console.log("delete");
  //     // gridRefRef.current.instance.삭제메소드()
  //   }
  // };

  return (
    <>
      <Tablist></Tablist>
      <div>
        {/* <button className="btn btn-light btn-sm font-weight-bold" onClick={onClickRead}>
          Read
        </button>
        <button className="btn btn-light btn-sm font-weight-bold" onClick={onClickAdd}>
          Add
        </button>
        <button className="btn btn-light btn-sm font-weight-bold" onClick={onClickSave}>
          Save
        </button>
        <button className="btn btn-light btn-sm font-weight-bold" onClick={onClickDelete}>
          Delete
        </button> */}
      </div>
      <Panellist></Panellist>
    </>
  );
}

export default TestPage;
