import { DataGrid } from "devextreme-react";
import {
  Column,
  ColumnChooser,
  FilterRow,
  HeaderFilter,
  Lookup,
  Scrolling,
  StateStoring,
} from "devextreme-react/data-grid";
import CustomStore from "devextreme/data/custom_store";
import { alert, confirm } from "devextreme/ui/dialog";
import React, { useCallback, useMemo, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { sendRequest } from "../../../../_metronic/_helpers/DataSoureHelpers";
import CustomButton from "../../../components/FunctionButton/CustomButton";
import InputText from "../../../components/InputForm/InputText";
import HeaderTitle from "../../../components/Layout/Header/HeaderTitle";
import {
  DataCount,
  PanelGridInit,
  PanelGridSearchBar,
  PanelGridWrapper,
  SearchItems,
} from "../../../components/Layout/Panel/PanelGrid/panelGrid.style";
import {
  PanelBody,
  PanelBtn,
  PanelHeader,
  PanelInit,
} from "../../../components/Layout/Panel/panel.style";
import StateStoringSaveAndDeleteButton from "../../../components/StateStoring";
import { useDatagrid } from "../../../hooks/useDatagrid";
import { deleteRowData, getLookup } from "./ComCompanyCrud";
import NewCompanyModal from "./components/NewCompanyModal";
import { useEffect } from "react";

const ComCompanyPage = () => {
  /* #region useState, useRef, const */
  const dataGridRef = useRef(null);
  useDatagrid(dataGridRef);
  const [totalCount, setTotalCount] = useState(0);
  const [search, setSearch] = useState({});
  const [comCode, setComCode] = useState([]);

  const [newCompanyModal, setNewCompanyModal] = useState(false);
  const [updateCompanyModal, setUpdateCompanyModal] = useState(false);
  const [selectedRowKey, setSelectedRowKey] = useState(null);

  const storageKey = "comCompanyMaster";
  /* #endregion */

  /* #region redux, dataSource */

  const dataSource = useMemo(() => {
    const apiUrl = `${process.env.REACT_APP_API_URL}/companyManage`;

    return new CustomStore({
      key: "oid",
      load: () => sendRequest(apiUrl, "GET", search),
    });
  }, [search]);
  /* #endregion */

  useEffect(() => {
    getLookup("comcode/detail/").then((data) => {
      setComCode(data.data);
    });
  }, []);

  /* #region useForm */
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();
  /* #endregion */

  /* #region 함수 */
  const onSubmit = (data) => {
    setSearch(data);
  };

  const onClickDelete = async () => {
    if (!selectedRowKey) {
      alert("데이터를 선택해주세요", "오류");
      return;
    }

    let response = await confirm("<i>삭제하시겠습니까?</i>", "삭제 메세지");
    if (!response) return;
    try {
      const { data } = await deleteRowData(selectedRowKey);
      if (data) await alert(data?.message, "오류");
      dataGridRef.current.instance.refresh();
    } catch (err) {
      alert("삭제할 수 없습니다.", "오류");
    }
  };

  const onRowDblClick = useCallback((e) => {
    setSelectedRowKey(e.key);
    setUpdateCompanyModal(true);
  }, []);
  /* #endregion */

  return (
    <>
      <PanelInit>
        <PanelHeader>
          <HeaderTitle />

          <PanelBtn>
            <CustomButton
              type="button"
              height={24}
              layout="solid"
              color="new"
              label="신규"
              onClick={() => setNewCompanyModal(true)}
            />
            <CustomButton
              type="button"
              height={24}
              layout="solid"
              color="esc"
              label="삭제"
              onClick={onClickDelete}
            />
          </PanelBtn>
        </PanelHeader>
        <PanelBody row left="100%" right="0">
          <PanelGridWrapper>
            <PanelGridSearchBar onSubmit={handleSubmit(onSubmit)}>
              <SearchItems>
                <label>거래처명</label>
                <InputText
                  control={control}
                  errors={errors}
                  name="company"
                  flex={true}
                />
              </SearchItems>

              <CustomButton
                type="submit"
                height={24}
                layout="solid"
                color="search"
                label="검색"
              />
            </PanelGridSearchBar>

            <PanelGridInit>
              <StateStoringSaveAndDeleteButton
                dataGridRef={dataGridRef}
                storageKey={storageKey}
              />
              <DataCount>{`총 ${totalCount}건`}</DataCount>
              <DataGrid
                id="ComCompanyMaster"
                className="datagrid-wrap"
                keyExpr="oid"
                dataSource={dataSource}
                ref={dataGridRef}
                showRowLines={true}
                showBorders={true}
                hoverStateEnabled={true}
                allowColumnResizing={true}
                allowColumnReordering={true}
                columnResizingMode="widget"
                focusedRowEnabled={true}
                columnAutoWidth={true}
                onFocusedRowChanged={(e) => setSelectedRowKey(e.row.key)}
                onRowDblClick={onRowDblClick}
                onContentReady={(e) => setTotalCount(e.component.totalCount())}
              >
                <FilterRow visible={true} />
                <HeaderFilter visible={true} />
                <ColumnChooser enabled={true} />
                <StateStoring
                  enabled={true}
                  type="custom"
                  customLoad={useCallback(() => {
                    return JSON.parse(localStorage.getItem(storageKey));
                  }, [])}
                />
                <Scrolling
                  mode="virtual"
                  showScrollbar="always"
                  useNative="false"
                />
                <Column
                  caption="순번"
                  cellRender={(e) => e.row.loadIndex + 1}
                />
                <Column dataField="company" caption="거래처명" />
                {/* <Column dataField="custoDivisionName" caption="거래처종류" /> */}
                <Column dataField="custoDivision" caption="거래처종류">
                  <Lookup
                    dataSource={comCode}
                    valueExpr="subCode"
                    displayExpr="subName"
                  />
                </Column>
                {/* <Column dataField="" caption="관리ID" /> */}
                <Column dataField="assignName" caption="소속부서" />
                <Column dataField="tel" caption="대표전화" />
                <Column dataField="fax" caption="팩스번호" />
                <Column dataField="route" caption="전문노선" />
                <Column dataField="viewRank" caption="조회순위" />
                {/* <Column dataField="creditClassName" caption="신용등급" /> */}
                <Column dataField="creditClass" caption="신용등급">
                  <Lookup
                    dataSource={comCode}
                    valueExpr="subCode"
                    displayExpr="subName"
                  />
                </Column>
                <Column dataField="reportTel" caption="도착보고전화" />
                <Column dataField="bizNo" caption="사업자번호" />
                <Column dataField="ceoName" caption="대표자명" />
                <Column dataField="bizCondition" caption="업종" />
                <Column dataField="item" caption="업태" />
              </DataGrid>
            </PanelGridInit>
          </PanelGridWrapper>
        </PanelBody>
      </PanelInit>
      {newCompanyModal && (
        <NewCompanyModal
          onClose={() => setNewCompanyModal(false)}
          closable={true}
          visible={true}
          maskClosable={false}
          dataGrid={dataGridRef}
        />
      )}

      {updateCompanyModal && (
        <NewCompanyModal
          onClose={() => setUpdateCompanyModal(false)}
          closable={true}
          visible={true}
          maskClosable={false}
          dataGrid={dataGridRef}
          oid={selectedRowKey}
        />
      )}
    </>
  );
};

export default ComCompanyPage;
