/** 
  할증등록 모달

  OrderDetail에서 할증등록 버튼을 누르면 팝업되는 모달 
*/
import { DataGrid } from "devextreme-react";
import {
  Button,
  Column,
  CustomRule,
  Editing,
  Format,
  Lookup,
  RequiredRule,
} from "devextreme-react/data-grid";
import React, { useState } from "react";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import CustomButton from "../FunctionButton/CustomButton";
import { ButtonGroup } from "../FunctionButton/CustomButton/customButton.style";
import ModalContainer from "../Modal/ModalContainer";
import {
  ExportChargeGridTitle,
  ExportChargeGridWrapper,
} from "./ExtraChargeModal.style";
import useExtraChargeModal from "./useExtraChargeModal";
import { numberValidationCheck } from "../../lib/dataGridFunctions";
import ExtraChangeAllModal from "./ExtraChangeAllModal";

const ExtraChargeModal = ({
  onClose,
  visible,
  maskClosable,
  state,
  extraChargeRef,
  etcChargeHabulRef,
  onExtraChargeSubmit,
}) => {
  const [changeAllModal, setChangeAllModal] = useState(false);
  const {
    lookupData,
    extraChargeForm,
    onExtraChargeToolbarPreparing,
    onEditingStart,
    onOptionChanged,
  } = useExtraChargeModal(etcChargeHabulRef);

  const { extraChargeData, etcChargeHabulData } = state;
  const { handleSubmit } = extraChargeForm;

  const onExtraEditingStart = (e) => {
    if (e.data.completeNo) {
      e.cancel = true;
    }
  };

  return (
    <>
      <ModalContainer
        onClose={onClose}
        maskClosable={maskClosable}
        visible={visible}
        size="xl"
        className="w-1100px closing-modal"
      >
        <form
          className="modal-form"
          onSubmit={handleSubmit(() => onExtraChargeSubmit())}
        >
          <div className="modal-form-header">
            <div className="modal-form-tit">
              <span className="modal-tit">할증등록</span>
            </div>

            <button className="btn-modal btn-modal-close" onClick={onClose}>
              <SVG
                type="button"
                src={toAbsoluteUrl(
                  "/media/svg/icons/Custom/close_black_24dp.svg"
                )}
                className="close-icon"
              />
            </button>
          </div>

          <div className="modal-form-body">
            <ExportChargeGridWrapper>
              <div>
                <ExportChargeGridTitle>
                  할증청구{" "}
                  <CustomButton
                    type="button"
                    height={26}
                    layout="line"
                    color="new"
                    label="할증청구 일괄적용"
                    onClick={() => setChangeAllModal(true)}
                  />
                </ExportChargeGridTitle>
                <DataGrid
                  dataSource={extraChargeData}
                  ref={extraChargeRef}
                  showRowLines={true}
                  showBorders={true}
                  allowColumnResizing={true}
                  onToolbarPreparing={onExtraChargeToolbarPreparing}
                  keyExpr="id"
                  onEditingStart={onExtraEditingStart}
                >
                  <Editing
                    mode="batch"
                    allowAdding={true}
                    allowDeleting={true}
                    allowUpdating={true}
                  />
                  <Column dataField="division" caption="청구구분">
                    <Lookup
                      dataSource={lookupData.division}
                      valueExpr="id"
                      displayExpr="subName"
                    />
                    <RequiredRule message="구분을 선택해주세요" />
                  </Column>
                  <Column
                    dataField="chargedAmount"
                    caption="할증금액"
                    alignment="right"
                    dataType="number"
                  >
                    <Format type="fixedPoint" />
                    <RequiredRule message="할증 금액을 입력해주세요" />
                    <CustomRule
                      message="10억 이하로 입력해주세요"
                      validationCallback={numberValidationCheck}
                    />
                  </Column>
                  <Column dataField="extraRemark" caption="청구비고" />
                  <Column type="buttons" width={55}>
                    <Button
                      name="delete"
                      text="삭제"
                      onClick={(e) => {
                        if (e.row.data.completeNo) return;
                        else e.component.deleteRow(e.row.rowIndex);
                      }}
                    />
                  </Column>
                </DataGrid>
              </div>

              <div>
                <ExportChargeGridTitle>기타하불</ExportChargeGridTitle>
                <DataGrid
                  dataSource={etcChargeHabulData}
                  ref={etcChargeHabulRef}
                  showRowLines={true}
                  showBorders={true}
                  allowColumnResizing={true}
                  onToolbarPreparing={onExtraChargeToolbarPreparing}
                  onEditingStart={onEditingStart}
                  onOptionChanged={onOptionChanged}
                  keyExpr="id"
                >
                  <Editing
                    mode="batch"
                    allowAdding={true}
                    allowDeleting={true}
                    allowUpdating={true}
                  />
                  <Column
                    dataField="habulDivision"
                    caption="하불구분"
                    width="70"
                  >
                    <Lookup
                      dataSource={lookupData.habulDivision}
                      valueExpr="id"
                      displayExpr="subName"
                    />
                    <RequiredRule message="하불구분을 선택해주세요" />
                  </Column>
                  <Column dataField="fromCompany" caption="하불처">
                    <Lookup
                      dataSource={lookupData.billingPlace}
                      valueExpr="oid"
                      displayExpr="company"
                    />
                  </Column>
                  <Column dataField="fromDirverNo" caption="기사명">
                    <Lookup
                      dataSource={lookupData.car}
                      valueExpr="oid"
                      displayExpr="carNo_Name"
                    />
                  </Column>
                  <Column
                    dataField="habul"
                    caption="하불금액"
                    alignment="right"
                    dataType="number"
                  >
                    <Format type="fixedPoint" />
                    <RequiredRule message="하불 금액을 입력해주세요" />
                    <CustomRule
                      message="10억 이하로 입력해주세요"
                      validationCallback={numberValidationCheck}
                    />
                  </Column>
                  <Column dataField="etcHabulRemark" caption="하불비고" />
                  <Column type="buttons" width={55}>
                    <Button
                      name="delete"
                      text="삭제"
                      onClick={(e) => {
                        if (e.row.data.completeNo) return;
                        else e.component.deleteRow(e.row.rowIndex);
                      }}
                    />
                  </Column>
                </DataGrid>
              </div>
            </ExportChargeGridWrapper>
          </div>
          <ButtonGroup marginTop={40}>
            <CustomButton
              type="submit"
              width="100%"
              height={36}
              layout="solid"
              color="common"
              label="저장"
            />
          </ButtonGroup>
        </form>
      </ModalContainer>
      {changeAllModal && (
        <ExtraChangeAllModal
          onClose={() => setChangeAllModal(false)}
          visible={true}
          maskClosable={false}
          extraChargeRef={extraChargeRef}
        />
      )}
    </>
  );
};

export default ExtraChargeModal;
