import { yupResolver } from "@hookform/resolvers/yup";
import { alert, confirm } from "devextreme/ui/dialog";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";

import { useCallback } from "react";
import InputDate from "../../../components/InputForm/InputDate";
import InputSelect from "../../../components/InputForm/InputSelect";
import InputText from "../../../components/InputForm/InputText";

import { AppFileModal } from "../../../../_metronic/_partials/modals/AppFileModal";
import CustomButton from "../../../components/FunctionButton/CustomButton";
import { ButtonGroup } from "../../../components/FunctionButton/CustomButton/customButton.style";
import InputFile from "../../../components/InputForm/InputFile";
import InputTextArea from "../../../components/InputForm/InputTextArea";
import HeaderTitle from "../../../components/Layout/Header/HeaderTitle";
import {
  InputFullRow,
  PanelInput,
  PanelInputTitle,
  PanelInputWrapper,
} from "../../../components/Layout/Panel/PanelInput/panelInput.style";
import {
  PanelBody,
  PanelBtn,
  PanelHeader,
  PanelInit,
} from "../../../components/Layout/Panel/panel.style";
import * as requestServer from "./AppFileManageCrud";
import AppFileManageMaster from "./AppFileManageMaster";

const AppFileManagePage = () => {
  /* #region useState, useRef -----------------------------------------------------------------------*/
  const [selectedRowKey, setSelectedRowKey] = useState(null);
  // const [isSelected, setIsSelected] = useState(false);
  // const [docName, setDocName] = useState("");
  const [selectModalVisible, setSelectModalVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [lookupData, setLookupData] = useState({
    dispatch: [],
    filelistCode: [],
    useYn: [],
  });
  const dataGridRef = useRef();
  const fileInput = useRef();
  /* #endregion */

  /* #region initialState, react-hook-form, yup ---------------------------------------------------- */
  const initialState = {
    filelistCode: "",
    fileName: "",
    fileInfo: "",
    regDate: moment().format("YYYY-MM-DD"),
    useYn: "",
    remark: undefined,
  };

  const schema = yup
    .object({
      filelistCode: yup
        .string()
        .nullable()
        .required("구분을 선택해주세요"),
      fileName: yup
        .string()
        .nullable()
        .required("관리명을 입력해주세요"),
      fileInfo: yup
        .string()
        .nullable()
        .required("파일을 등록해주세요"),
      regDate: yup
        .string()
        .nullable()
        .required("등록일을 선택해주세요"),
      useYn: yup
        .string()
        .nullable()
        .required("사용여부를 선택해주세요"),
      remark: yup.string().nullable(),
    })
    .required();

  const {
    watch,
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  /* #endregion */

  /* #region useEffect ----------------------------------------------------------------------------- */
  useEffect(() => {
    getLookup();
    reset(initialState);
    // eslint-disable-next-line
  }, []);

  /* #endregion */

  /* #region 기타함수 ----------------------------------------------------------------------------- */

  const closeModal = () => {
    setSelectModalVisible(false);
    dataGridRef.current.instance.option("focusedRowIndex", -1);
    reset(initialState);
  };

  const onSubmit = async (data) => {
    try {
      setIsLoading(true);

      const formData = new FormData();

      formData.append("file", data.file[0]);
      formData.append("filelistCode", data.filelistCode);
      formData.append("fileInfo", data.fileInfo);
      formData.append("fileName", data.fileName);
      formData.append("regDate", data.regDate);
      formData.append("remark", data.remark);
      formData.append("useYn", data.useYn);
      formData.append("key", selectedRowKey);

      if (!selectedRowKey) {
        await requestServer.postAppFile(formData);
      } else {
        await requestServer.updateAppFile(formData);
      }

      dataGridRef.current.instance.refresh();
      dataGridRef.current.instance.option("focusedRowIndex", -1);
      reset(initialState);
      fileInput.current.value = null;
    } catch (err) {
      alert(err.response?.data?.message || "ERROR", "오류");
    } finally {
      setIsLoading(false);
    }
  };

  const onError = (errors) => {
    console.log(errors);
  };

  const onFocusedRowChanged = useCallback((e) => {
    // console.log(e);
    if (!e.row) return;
    const rowData = {
      ...e.row.data,
      filelistCode: e.row.data.filelistCode ? e.row.data.filelistCode : "",
      fileName: e.row.data.fileName ? e.row.data.fileName : "",
      regDate: e.row.data.regDate ? e.row.data.regDate : "",
      useYn: e.row.data.useYn ? e.row.data.useYn : "",
      remark: e.row.data.remark ? e.row.data.remark : "",
    };
    setSelectedRowKey(e.row.key);
    reset(rowData);
    // setIsSelected(true);
    // eslint-disable-next-line
  }, []);

  const onClickDelete = async () => {
    if (!selectedRowKey) return;
    const result = await confirm("정말로 삭제 하시겠습니까?", "경고");
    if (!result) return;
    try {
      setIsLoading(true);

      await requestServer.deleteAppFile(selectedRowKey);

      dataGridRef.current.instance.refresh();
      dataGridRef.current.instance.option("focusedRowIndex", -1);
      setSelectedRowKey(null);
      reset(initialState);
    } catch (err) {
      alert(err.response?.data?.message || "ERROR", "오류");
    } finally {
      setIsLoading(false);
    }
  };

  async function getLookup() {
    try {
      const fileData = await requestServer.getLookup("comcode/detail/70300");
      const Use_YnData = await requestServer.getLookup("comcode/detail/70200");

      setLookupData({
        filelistCode: fileData.data,
        useYn: Use_YnData.data,
      });
    } catch (err) {
      console.log(err);
    }
  }

  return (
    <>
      <PanelInit>
        <PanelHeader>
          <HeaderTitle />
          <PanelBtn>
            <CustomButton
              type="button"
              height={24}
              layout="solid"
              color="new"
              label="새입력"
              onClick={() => {
                reset(initialState);
                setSelectedRowKey(null);
                // setIsSelected(false);
                dataGridRef.current.instance.option("focusedRowIndex", -1);
              }}
            />
            <CustomButton
              type="button"
              height={24}
              layout="solid"
              color="esc"
              label="삭제"
              onClick={onClickDelete}
            />
          </PanelBtn>
        </PanelHeader>

        <PanelBody row left="400px" right="calc(100% - 400px)">
          <PanelInputWrapper
            onSubmit={handleSubmit(onSubmit, onError)}
            openInputBar={true}
          >
            <PanelInputTitle>운송파일등록</PanelInputTitle>
            <PanelInput grid={2}>
              <InputSelect
                searchEnabled={true}
                control={control}
                errors={errors}
                name="filelistCode"
                label="구분"
                dataSource={lookupData.filelistCode}
                valueExpr="subCode"
                displayExpr="subName"
              />
              <InputText
                control={control}
                errors={errors}
                name="fileName"
                label="관리명"
              />

              <InputFullRow grid={2}>
                <InputFile
                  control={control}
                  errors={errors}
                  name="fileInfo"
                  fileName="file"
                  label="파일"
                  setValue={setValue}
                  fileTitle={watch("fileInfo")}
                  onFileSelected={(name) => {
                    setValue("fileInfo", name);
                  }}
                  onFileRemove={() => {
                    setValue("file", {});
                    setValue("fileInfo", "");
                  }}
                  fileUrl={watch("path")}
                  fileInput={fileInput}
                />
              </InputFullRow>
              <InputDate
                control={control}
                errors={errors}
                name="regDate"
                label="등록일"
              />
              <InputSelect
                searchEnabled={true}
                control={control}
                errors={errors}
                name="useYn"
                label="사용여부"
                dataSource={lookupData.useYn}
                valueExpr="subCode"
                displayExpr="subName"
              />
              <InputFullRow grid={2}>
                <InputTextArea
                  control={control}
                  errors={errors}
                  height={80}
                  name="remark"
                  label="비고"
                />
              </InputFullRow>
            </PanelInput>
            <ButtonGroup marginTop={20}>
              <CustomButton
                type="submit"
                width="100%"
                height={36}
                layout="solid"
                color="common"
                label="저장"
                className={`${isLoading &&
                  "spinner spinner-white spinner-right"}`}
              />
            </ButtonGroup>
          </PanelInputWrapper>
          <AppFileManageMaster
            lookupData={lookupData}
            getLookup={getLookup}
            onFocusedRowChanged={onFocusedRowChanged}
            dataGridRef={dataGridRef}
          />
        </PanelBody>
      </PanelInit>
      {selectModalVisible && (
        <AppFileModal
          onClose={closeModal}
          maskClosable={false}
          dataGridRef={dataGridRef}
          visible={true}
        />
      )}
    </>
  );
};

export default AppFileManagePage;
