import axios from "axios";

export const LOGIN_URL = `${process.env.REACT_APP_API_URL}/user/login`;
export const REGISTER_URL = "api/auth/register";
export const REQUEST_PASSWORD_URL = "api/auth/forgot-password";
export const USER_INFO = `${process.env.REACT_APP_API_URL}/user/user-info`;
export const USER_IP = `${process.env.REACT_APP_API_URL}/user/ip`;

export function login(userId, password, companySelect) {
  return axios.post(LOGIN_URL, { userId, password, companySelect });
}

export function register(email, fullname, username, password) {
  return axios.post(REGISTER_URL, { email, fullname, username, password });
}

export function requestPassword(email) {
  return axios.post(REQUEST_PASSWORD_URL, { email });
}

export function getUserByToken() {
  return axios.get(USER_INFO);
}

export function getUserIp() {
  return axios.get("/ipify?format=json");
}

export function getIP() {
  const getData = axios.get(USER_IP);
  getData
    .then((ip) => {
      console.log(ip.data);
      localStorage.setItem("userIP", ip.data);
    })
    .catch((error) => {
      console.log(error);
    });
  return console.log(localStorage.getItem("userIP"));
}
