import { DataGrid } from "devextreme-react";
import {
  Column,
  ColumnChooser,
  FilterRow,
  Format,
  HeaderFilter,
  Scrolling,
  StateStoring,
} from "devextreme-react/data-grid";
import React, { useCallback, useState } from "react";
import {
  DataCount,
  PanelGridInit,
} from "../../../../components/Layout/Panel/PanelGrid/panelGrid.style";
import StateStoringSaveAndDeleteButton from "../../../../components/StateStoring";

function AccountHistoryMaster({ state, dataGridRef, masterFunctions }) {
  const [totalCount, setTotalCount] = useState(0);
  const storageKey = "accountHistoryMaster";

  const { dataSource } = state;

  const { onFocusedRowChanged } = masterFunctions;

  return (
    <>
      <PanelGridInit>
        <StateStoringSaveAndDeleteButton
          dataGridRef={dataGridRef}
          storageKey={storageKey}
        />
        <DataCount>{`총 ${totalCount}건`}</DataCount>
        <DataGrid
          className="datagrid-wrap"
          id="AccountHistoryMaster"
          ref={dataGridRef}
          dataSource={dataSource}
          showRowLines={true}
          showBorders={true}
          hoverStateEnabled={true}
          allowColumnResizing={true}
          allowColumnReordering={true}
          columnResizingMode="widget"
          keyExpr="TransRefKey"
          focusedRowEnabled={true}
          onFocusedRowChanged={onFocusedRowChanged}
          onContentReady={(e) => setTotalCount(e.component.totalCount())}
        >
          <FilterRow visible={true} />
          <HeaderFilter visible={true} />
          <ColumnChooser enabled={true} />
          <StateStoring
            enabled={true}
            type="custom"
            customLoad={useCallback(() => {
              return JSON.parse(localStorage.getItem(storageKey));
            }, [])}
          />
          <Scrolling mode="virtual" />
          <Column
            dataField="bankNumber"
            caption="계좌번호"
            alignment="center"
          />
          <Column
            dataField="TransRefKey"
            caption="일련번호"
            alignment="center"
          />
          <Column dataField="TransDT" caption="거래일시" alignment="center" />
          <Column
            dataField="Withdraw"
            caption="출금"
            alignment="right"
            dataType="number"
          >
            <Format type="fixedPoint" />
          </Column>
          <Column
            dataField="Deposit"
            caption="입금"
            alignment="right"
            dataType="number"
          >
            <Format type="fixedPoint" />
          </Column>
          <Column
            dataField="Balance"
            caption="잔액"
            alignment="right"
            dataType="number"
          >
            <Format type="fixedPoint" />
          </Column>
          <Column dataField="TransOffice" caption="상대은행" />
          <Column dataField="TransRemark" caption="상대명" />
          <Column dataField="subTaxCount" caption="연동된세금계산서" />
          <Column dataField="subEtcCount" caption="연동된기타비용" />
          <Column dataField="Remark" caption="통장비고내역" />
        </DataGrid>
      </PanelGridInit>
    </>
  );
}

export default AccountHistoryMaster;
