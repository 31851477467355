import { DataGrid } from "devextreme-react";
import {
  Column,
  ColumnChooser,
  HeaderFilter,
  Scrolling,
  StateStoring,
} from "devextreme-react/data-grid";
import CustomStore from "devextreme/data/custom_store";
import React, { useCallback, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { sendRequest } from "../../../../_metronic/_helpers/DataSoureHelpers";
import StateStoringSaveAndDeleteButton from "../../../components/StateStoring";

function ManagerMaster({ dataGridRef, onFocusedRowChanged }) {
  const [totalCount, setTotalCount] = useState(0);
  const [search, setSearch] = useState({});
  const { register, handleSubmit } = useForm();
  const storageKey = "managerMaster";

  const dataSource = useMemo(() => {
    const apiUrl = `${process.env.REACT_APP_API_URL}/manager`;

    return new CustomStore({
      key: "code",
      load: () => sendRequest(apiUrl, "GET", search),
    });
    // eslint-disable-next-line
  }, []);

  const onSubmit = (data) => {
    for (const obj in data) {
      if (data[obj] === "") {
        data[obj] = null;
      }
    }
    setSearch(data);
  };

  return (
    <>
      <div className="grid-search-wrap">
        <form onSubmit={handleSubmit(onSubmit)}>
          <span className="search-group">
            <label>담당자명</label>
            <input type="text" {...register("managerName")} />
          </span>
          <button type="submit" className="btn btn-grid-search single-line">
            검색
          </button>
        </form>
      </div>
      <div className="grid-component has-toolbar">
        <StateStoringSaveAndDeleteButton
          dataGridRef={dataGridRef}
          storageKey={storageKey}
        />
        <div className="grid-total">{`총 ${totalCount}건`}</div>
        <DataGrid
          className="datagrid-wrap"
          id="manager"
          dataSource={dataSource}
          ref={dataGridRef}
          showRowLines={true}
          showBorders={true}
          hoverStateEnabled={true}
          allowColumnResizing={true}
          allowColumnReordering={true}
          columnResizingMode="widget"
          keyExpr="code"
          focusedRowEnabled={true}
          onFocusedRowChanged={onFocusedRowChanged}
          onContentReady={(e) => setTotalCount(e.component.totalCount())}
        >
          <HeaderFilter visible={true} />
          <ColumnChooser enabled={true} />
          <StateStoring
            enabled={true}
            type="custom"
            customLoad={useCallback(() => {
              return JSON.parse(localStorage.getItem(storageKey));
            }, [])}
          />
          <Scrolling mode="virtual" />
          <Column dataField="code" caption="코드" alignment="center" />
          <Column dataField="division" caption="구분" alignment="center" />
          <Column dataField="client" caption="거래처" alignment="center" />
          <Column
            dataField="dutyDivision"
            caption="업무구분"
            alignment="center"
          />
          <Column dataField="managerName" caption="성명" alignment="center" />
          <Column dataField="phoneNo" caption="휴대폰번호" />
          <Column dataField="tel" caption="직통전화" />
          <Column dataField="email" caption="메일주소" />
          <Column dataField="remark" caption="비고" alignment="center" />
        </DataGrid>
      </div>
    </>
  );
}

export default ManagerMaster;
