import { DataGrid } from "devextreme-react";
import {
  Column,
  ColumnChooser,
  FilterRow,
  Format,
  HeaderFilter,
  Scrolling,
  StateStoring,
} from "devextreme-react/data-grid";
import CustomStore from "devextreme/data/custom_store";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { shallowEqual, useSelector } from "react-redux";
import { sendRequest } from "../../../../_metronic/_helpers/DataSoureHelpers";

import { useCallback } from "react";
import InputDate from "../../../components/InputForm/InputDate";
import InputSelect from "../../../components/InputForm/InputSelect";

import { alert } from "devextreme/ui/dialog";
import moment from "moment";
import CustomButton from "../../../components/FunctionButton/CustomButton";
import HeaderTitle from "../../../components/Layout/Header/HeaderTitle";
import {
  DataCount,
  PanelGridInit,
  PanelGridSearchBar,
  PanelGridWrapper,
  SearchItems,
} from "../../../components/Layout/Panel/PanelGrid/panelGrid.style";
import {
  PanelBody,
  PanelBtn,
  PanelHeader,
  PanelInit,
} from "../../../components/Layout/Panel/panel.style";
import StateStoringSaveAndDeleteButton from "../../../components/StateStoring";
import { useDatagrid } from "../../../hooks/useDatagrid";
import * as requestServer from "./ContainerCostCrud";
import * as Modal from "./ContainerCostModal";

function ContainerCostPage(props) {
  /* #region useState, useRef -------------------------------------------------------- */

  // state
  const [totalCount, setTotalCount] = useState(0);
  const [rowData, setRowData] = useState();
  // const [detailRowData, setDetailRowData] = useState();
  const [search, setSearch] = useState({});
  const [focusedComponent, setFocusedComponent] = useState();

  const [lookupData, setLookupData] = useState({
    dispatch: [],
    company: [],
  });

  // modal state
  const [containerCostModal, setContainerCostModal] = useState(false);

  // ref
  const dataGridRef = useRef();

  useDatagrid(dataGridRef);

  const storageKey = "containerCostMaster";

  /* #endregion */

  /* #region Redux -------------------------------------------------------------------  */

  const { user } = useSelector(
    (state) => ({ user: state.auth.user.user }),
    shallowEqual
  );

  /* #endregion */

  /* #region initialState, react-hook-form, yup -------------------------------------- */
  // initialState

  const initialState = {
    firstDay: moment().format("YYYY-MM-DD"),
    lastDay: moment().format("YYYY-MM-DD"),
    searchType: 2,
    authGroupId: user.authGroupId,
    receiveCo: user.companyCode,
    dispatchTeam: user?.deptCode,
  };

  // hook-form
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  /* #endregion */

  /* #region useEffect --------------------------------------------------------------- */

  // useEffect
  useEffect(() => {
    getLookup();
    reset(initialState);
    setSearch(initialState);
    // eslint-disable-next-line
  }, []);

  /* #endregion */

  /* #region 데이터그리드 함수 -------------------------------------------------------- */
  const onRowDblClick = useCallback(() => {}, []);

  const onCellPrepared = useCallback(() => {}, []);

  const onCellClick = useCallback((e) => {
    if (!e.row) return;
    // detailGridRef.current && detailGridRef.current.instance.option("focusedRowIndex", -1);
    const row = e.row;
    if (row.rowType === "data") {
      setRowData(row.data);
    }
  }, []);

  const onFocusedRowChanged = useCallback(
    (e) => {
      if (e.rowIndex === -1) return;
      // eslint-disable-next-line
      if (focusedComponent && focusedComponent != e.component) {
        focusedComponent.option("focusedRowIndex", -1);
        if (e.element.id === "dispatchMaster") {
          // setDetailRowData(null);
        } else if (e.element.id === "dispatchDetail") {
          setRowData(null);
        }
      }
      setFocusedComponent(e.component);
    },
    [focusedComponent]
  );

  // cellRender

  const divisionRender = useCallback((e) => {
    if (e.value === "order") return <div>오더접수</div>;
    if (e.value === "lclorder") return <div>LCL오더</div>;
    if (e.value === "simple") return <div>간편배차</div>;
    if (e.value === "simpleFinish") return <div>간편정산</div>;
    if (e.value === "shuttle") return <div>셔틀배차</div>;
    if (e.value === "otherCo") return <div>용차배차</div>;
  }, []);

  const orderStatusRender = useCallback((e) => {
    if (e.data.longStorage === 1) {
      return <div>장기보관</div>;
    } else {
      return <div>{e.text}</div>;
    }
  }, []);

  const importExportRender = useCallback((e) => {
    if (e.row.data.importExport === 1) {
      return <div>수입</div>;
    } else {
      return <div>수출</div>;
    }
  }, []);

  const sumCellRender = useCallback((e) => {
    let sum = parseInt(e.data.freight || 0) + parseInt(e.data.extraCharge || 0);

    return <div>{sum.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</div>;
  }, []);
  /* #endregion */

  /* #region 기타함수------------------------------------------------------------ */

  // dataSource

  const dataSource = useMemo(() => {
    const apiUrl = `${process.env.REACT_APP_API_URL}/containercost`;

    return new CustomStore({
      key: "id",
      load: () => sendRequest(apiUrl, "GET", search),
    });
  }, [search]);

  // submit
  const onSubmit = (data) => {
    // for (const obj in data) {
    //   if (data[obj] === "") {
    //     data[obj] = null;
    //   }
    // }
    setSearch(data);
  };

  // modal function

  const setContainerCost = () => {
    if (!rowData) {
      alert("컨테이너를 선택해주세요", "오류");
      return;
    }

    setContainerCostModal(true);
  };

  const onClose = () => {
    setContainerCostModal(false);
  };

  async function getLookup() {
    try {
      const dispatchData = await requestServer.getLookup(
        "department/isDispatch"
      );
      const companyData = await requestServer.getLookup("company");

      setLookupData({
        dispatch: [{ code: "", deptName: "전체" }, ...dispatchData.data],
        company: [{ id: "", companyName: "전체" }, ...companyData.data],
      });
    } catch (err) {
      alert(err.response.data.message, "오류");
    }
  }

  /* #endregion */

  return (
    <>
      <PanelInit>
        <PanelHeader>
          <HeaderTitle />

          <PanelBtn>
            <CustomButton
              type="button"
              height={24}
              layout="solid"
              color="new"
              label="컨테이너비용 등록"
              onClick={setContainerCost}
            />
            {/* <button onClick={setHabul}>하불등록</button> */}

            {/* <button className="btn btn-grid btn-grid-complete lg" onClick={setWarehouseTime}>
              창고입고
            </button> */}
          </PanelBtn>
        </PanelHeader>

        <PanelBody row left="100%" right="0">
          <PanelGridWrapper>
            <PanelGridSearchBar onSubmit={handleSubmit(onSubmit)}>
              <SearchItems>
                <label>접수법인</label>
                <InputSelect
                  control={control}
                  errors={errors}
                  name="receiveCo"
                  dataSource={lookupData.company}
                  valueExpr="id"
                  displayExpr="companyName"
                  flex={true}
                />
              </SearchItems>
              <SearchItems>
                <label>배차팀</label>
                <InputSelect
                  control={control}
                  errors={errors}
                  displayExpr="deptName"
                  valueExpr="code"
                  name="dispatchTeam"
                  dataSource={lookupData.dispatch}
                  flex={true}
                />
              </SearchItems>
              <SearchItems>
                <label>일정검색</label>
                <InputSelect
                  className="label-select"
                  control={control}
                  errors={errors}
                  name="searchType"
                  displayExpr="name"
                  valueExpr="id"
                  dataSource={[
                    { id: 2, name: "운송요청일자" },
                    { id: 1, name: "접수일자" },
                    { id: 3, name: "작업지도착시간" },
                  ]}
                  flex={true}
                />
                <InputDate
                  control={control}
                  errors={errors}
                  name="firstDay"
                  flex={true}
                />
                <div className="to-mark">~</div>
                <InputDate
                  control={control}
                  errors={errors}
                  name="lastDay"
                  flex={true}
                />
                {/* <input type="text" {...register("managerName")} /> */}
              </SearchItems>
              <CustomButton
                type="submit"
                height={24}
                layout="solid"
                color="search"
                label="검색"
              />
            </PanelGridSearchBar>

            <PanelGridInit>
              <StateStoringSaveAndDeleteButton
                dataGridRef={dataGridRef}
                storageKey={storageKey}
              />
              <DataCount>{`총 ${totalCount}건`}</DataCount>
              <DataGrid
                className="datagrid-wrap"
                id="DispatchMaster"
                dataSource={dataSource}
                ref={dataGridRef}
                showRowLines={true}
                showBorders={true}
                hoverStateEnabled={true}
                allowColumnResizing={true}
                allowColumnReordering={true}
                columnAutoWidth={true}
                columnResizingMode="widget"
                onRowDblClick={onRowDblClick}
                focusedRowEnabled={true}
                onCellPrepared={onCellPrepared}
                onCellClick={onCellClick}
                onFocusedRowChanged={onFocusedRowChanged}
                onContentReady={(e) => setTotalCount(e.component.totalCount())}
              >
                <FilterRow visible={true} />
                <HeaderFilter visible={true} />
                <ColumnChooser enabled={true} />
                <StateStoring
                  enabled={true}
                  type="custom"
                  customLoad={useCallback(() => {
                    return JSON.parse(localStorage.getItem(storageKey));
                  }, [])}
                />
                <Scrolling
                  columnRenderingMode="virtual"
                  mode="virtual"
                  showScrollbar="always"
                  useNative="false"
                />
                <Column dataField="orderCode" caption="접수번호" />
                <Column
                  dataField="division"
                  caption="구분"
                  cellRender={divisionRender}
                  alignment="center"
                />
                <Column
                  dataField="orderStatus"
                  caption="상태"
                  cellRender={orderStatusRender}
                  alignment="center"
                />
                <Column
                  dataField="importExport"
                  caption="수입/수출"
                  cellRender={importExportRender}
                  alignment="center"
                />
                <Column
                  dataField="forwarder"
                  caption="선사"
                  alignment="center"
                />
                <Column dataField="cargoOwnerName" caption="화주" />
                <Column dataField="billingPlaceName" caption="청구처" />
                <Column
                  dataField="containerNo"
                  caption="컨테이너 No"
                  alignment="center"
                />
                <Column
                  dataField="sealNo"
                  caption="Seal No"
                  alignment="center"
                />
                <Column
                  dataField="containerType"
                  caption="컨/규격"
                  alignment="center"
                />
                <Column
                  dataField="deliverDate"
                  caption="운송요청일자"
                  alignment="center"
                  dataType="date"
                  format="yyyy-MM-dd"
                />
                <Column
                  dataField="freight"
                  caption="청구금액"
                  alignment="right"
                  dataType="number"
                >
                  <Format type="fixedPoint" />
                </Column>
                <Column
                  dataField="extraCharge"
                  caption="할증금액"
                  alignment="right"
                  dataType="number"
                >
                  <Format type="fixedPoint" />
                </Column>
                <Column
                  dataField="sum"
                  caption="총합계"
                  alignment="right"
                  cellRender={sumCellRender}
                />
                <Column
                  dataField="habul"
                  caption="하불금액"
                  alignment="right"
                  dataType="number"
                >
                  <Format type="fixedPoint" />
                </Column>
                <Column
                  dataField="etcHabul"
                  caption="기타하불"
                  alignment="right"
                  dataType="number"
                >
                  <Format type="fixedPoint" />
                </Column>
                <Column
                  dataField="deptName"
                  caption="배차팀"
                  alignment="center"
                />
                <Column
                  dataField="loadPlace"
                  caption="상차지"
                  alignment="center"
                />
                {/* <Column dataField="workWaypoint" caption="작업경유지" /> */}
                <Column dataField="workPlace" caption="작업지" />
                {/* <Column dataField="collectWaypoint" caption="회수경유지" /> */}
                <Column
                  dataField="unloadPlace"
                  caption="하차지"
                  alignment="center"
                />
                <Column
                  dataField="workTime"
                  caption="작업지 도착시간"
                  alignment="center"
                  dataType="date"
                  format="HH:mm:ss"
                />
                <Column
                  dataField="dem"
                  caption="선사반출기한"
                  alignment="center"
                  dataType="date"
                  format="yyyy-MM-dd"
                />
                <Column
                  dataField="returnDate"
                  caption="반납일자"
                  alignment="center"
                  dataType="date"
                  format="yyyy-MM-dd"
                />
                <Column dataField="chassis" caption="샤시" />
                <Column
                  dataField="bobtail"
                  caption="밥테일"
                  dataType="boolean"
                  width="80"
                />
                <Column
                  dataField="fridge"
                  caption="냉동"
                  dataType="boolean"
                  width="70"
                />
                <Column
                  dataField="danger"
                  caption="위험물"
                  dataType="boolean"
                  width="80"
                />
                <Column
                  dataField="tank"
                  caption="탱크"
                  dataType="boolean"
                  width="70"
                />
                <Column
                  dataField="direct"
                  caption="직반입"
                  dataType="boolean"
                  width="80"
                />
                <Column
                  dataField="temperature"
                  caption="온도"
                  alignment="center"
                />
                <Column dataField="vent" caption="환풍구" alignment="center" />
                <Column dataField="unNo" caption="un번호" alignment="center" />
                <Column dataField="class" caption="클래스" alignment="center" />
                <Column
                  dataField="extraInfo"
                  caption="기타정보"
                  alignment="center"
                />
              </DataGrid>
            </PanelGridInit>
          </PanelGridWrapper>
        </PanelBody>
      </PanelInit>
      {containerCostModal && (
        <Modal.ContainerCostModal
          onClose={onClose}
          closable={true}
          visible={true}
          maskClosable={false}
          rowData={rowData}
          dataGridRef={dataGridRef}
        />
      )}
    </>
  );
}

export default ContainerCostPage;
