import React, { useState, useRef } from "react";
import WorkPlaceMaster from "./WorkPlaceMaster";
import * as requestServer from "./WorkPlaceCrud";
import { confirm, alert } from "devextreme/ui/dialog";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { shallowEqual, useSelector } from "react-redux";
import InputText from "../../../components/InputForm/InputText";
import InputAddress from "../../../components/InputForm/InputAddress";
import InputCheckBox from "../../../components/InputForm/InputCheckBox";
import FunctionButton from "../../../components/FunctionButton/FunctionButton";

function WorkPlacePage(props) {
  const initialState = {
    code: "",
    workPlaceNaem: "",
    zipCode: "",
    address: "",
    address1: "",
    ft20: "",
    ft40: "",
    remark: "",
  };
  const [selectedRowKey, setSelectedRowKey] = useState();
  const [inputDisable, setInputDisable] = useState(false);
  const dataGridRef = useRef();
  const { menuAll, openItem } = useSelector(
    (state) => ({
      menuAll: state.menu.menuAll,
      openItem: state.menu.openItem,
    }),
    shallowEqual
  );

  const schema = yup
    .object({
      code: yup
        .string()
        .required("작업지 코드를 입력하세요")
        .max(20, "최대 20자리까지 입력 가능"),
      workPlaceNaem: yup
        .string()
        .required("작업지명을 입력하세요")
        .max(100, "최대 100자리까지 입력 가능"),
      zipCode: yup.string().required("우편번호를 입력하세요"),
      address: yup.string().required("주소를 입력하세요"),
      address1: yup
        .string()
        .required("상세주소를 입력하세요")
        .max(100, "최대 100자리까지 입력 가능"),
      isShuttle: yup.boolean(),
      remark: yup.string(),
    })
    .required();

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onFocusedRowChanged = (e) => {
    if (!e.row) return;
    setSelectedRowKey(e.row.key);
    setInputDisable(true);
    reset({ ...e.row.data });
  };

  const onClickAdd = () => {
    if (!dataGridRef.current) return;
    reset(initialState);
    setSelectedRowKey(null);
    setInputDisable(false);
    dataGridRef.current.instance.option("focusedRowIndex", -1);
  };

  const onClickDelete = () => {
    if (!dataGridRef.current || !selectedRowKey) return;
    let response = confirm("<i>삭제하시겠습니까?</i>", "삭제 메세지");

    response.then((res) => {
      if (!res) return;
      requestServer
        .deleteRow(selectedRowKey)
        .then(() => {
          dataGridRef.current.instance.refresh();
        })
        .catch((err) => {
          alert(err.response.data.message, "오류");
        });
    });
  };

  const openPost = () => {
    window.daum.postcode.load(() => {
      const postcode = new window.daum.Postcode({
        oncomplete: function(data) {
          let addr = "";
          let extraAddr = "";

          if (data.userSelectedType === "R") {
            addr = data.address;
          } else {
            addr = data.jibunAddress;
          }

          if (data.userSelectedType === "R") {
            if (data.bname !== "" && /[동|로|가]$/g.test(data.bname)) {
              extraAddr += data.bname;
            }
            if (data.buildingName !== "" && data.apartment === "Y") {
              extraAddr +=
                extraAddr !== "" ? ", " + data.buildingName : data.buildingName;
            }
            if (extraAddr !== "") {
              extraAddr = " (" + extraAddr + ")";
            }
            addr += extraAddr;
          }

          setValue("zipCode", data.zonecode);
          setValue("address", addr);
        },
      });
      postcode.open();
    });
  };

  const onSubmit = async (data) => {
    delete data.createIP;
    delete data.createUserId;
    delete data.createdAt;
    delete data.updateIP;
    delete data.updateUserId;
    delete data.updatedAt;

    try {
      if (selectedRowKey) {
        await requestServer.updateWorkPlace(selectedRowKey, data);
      } else {
        await requestServer.createWorkPlace(data);
      }
      dataGridRef.current.instance.refresh();
      reset(initialState);
      setSelectedRowKey(null);
      dataGridRef.current.instance.option("focusedRowIndex", -1);
    } catch (err) {
      alert(err.response.data.message, "오류");
    }
  };

  return (
    <>
      <div className="page-wrap">
        {/* 왼쪽 사이드 섹션 */}
        <div className="column-left fluid-left">
          {/* 페이지 헤더 */}
          <div className="panel-header flex-row">
            <div className="panel-header-init">
              <div className="panel-header-tit">{openItem?.menu?.menuName}</div>
              <ul className="breadcrumb-list">
                <li className="breadcrumb-item">
                  <Link to="/">
                    <i className="flaticon2-shelter icon-1x" />
                  </Link>
                </li>
                {openItem?.menu?.parentId && (
                  <li className="breadcrumb-item">
                    {
                      menuAll.find((menu) => {
                        return menu.menuId === openItem?.menu?.parentId;
                      })?.menu?.menuName
                    }
                  </li>
                )}
                {openItem?.menu?.menuName && (
                  <li className="breadcrumb-item current">
                    {openItem?.menu?.menuName}
                  </li>
                )}
              </ul>
            </div>
            <FunctionButton
              onClickAdd={onClickAdd}
              onClickDelete={onClickDelete}
            />
          </div>
          {/* 페이지 메인그리드 */}
          <WorkPlaceMaster
            onFocusedRowChanged={onFocusedRowChanged}
            dataGridRef={dataGridRef}
          />
        </div>

        {/* 그리드 인풋 너비 고정 */}
        <div className="column-right fixed-right">
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="form-container column-input"
          >
            <div className="form-tit">작업지 등록</div>

            <InputText
              control={control}
              errors={errors}
              name="code"
              label="작업지코드"
              required={true}
              disabled={inputDisable}
            />
            <InputText
              control={control}
              errors={errors}
              name="workPlaceNaem"
              label="작업지명"
              required={true}
            />

            <InputAddress
              control={control}
              errors={errors}
              required={true}
              flexDirection="column"
              openPost={openPost}
            />
            <InputCheckBox
              control={control}
              errors={errors}
              name="isShuttle"
              label="셔틀창고"
            />
            <InputText
              control={control}
              errors={errors}
              name="remark"
              label="비고"
            />

            <button type="submit" className="btn btn-form-save">
              저장
            </button>
          </form>
        </div>
      </div>
    </>
  );
}

export default WorkPlacePage;
