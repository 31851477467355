import { DataGrid } from "devextreme-react";
import { Column, Format } from "devextreme-react/data-grid";
import React, { useCallback } from "react";

function YongchaDispatchDetail({ data, detailDataSource: dataSource }) {
  // 할증금액 render
  const extraChargeCellRender = useCallback((e) => {
    if (e.data.extraCharges[0]) {
      const total = e.data.extraCharges[0].chargedAmount;
      if (total) {
        return (
          <div className="charge-amount">
            <span>{total.toLocaleString("ko-KR")}</span>
          </div>
        );
      } else {
        return <></>;
      }
    } else {
      return <></>;
    }
  }, []);

  return (
    <DataGrid
      id="YongchaDispatchDetail"
      showBorders={true}
      columnAutoWidth={true}
      dataSource={dataSource}
      allowColumnResizing={true}
      columnResizingMode="widget"
    >
      <Column cellRender={(e) => e.rowIndex + 1} alignment="center" />
      <Column dataField="subName" caption="오더상태" alignment="center" />
      <Column dataField="deliverDate" caption="운송일자" alignment="center" />
      <Column
        dataField="containerNo"
        caption="컨테이너번호"
        alignment="center"
      />
      <Column dataField="sealNo" caption="Seal No" alignment="center" />
      <Column dataField="containerType" caption="컨/규격" alignment="center" />
      <Column dataField="workWaypoint" caption="작업경유지" />
      <Column dataField="workPlace" caption="작업지" />
      <Column dataField="collectWaypoint" caption="회수경유지" />
      <Column
        dataField="returnDate"
        caption="반납마감일자"
        dataType="date"
        format="yyyy-MM-dd"
        alignment="center"
      />
      <Column
        dataField="freight"
        caption="청구금액"
        alignment="right"
        dataType="number"
      >
        <Format type="fixedPoint" />
      </Column>
      <Column
        dataField="habul"
        caption="하불운임"
        alignment="right"
        dataType="number"
      >
        <Format type="fixedPoint" />
      </Column>
      <Column
        dataField="extraCharge"
        caption="할증금액"
        alignment="right"
        dataType="number"
        cellRender={extraChargeCellRender}
      >
        <Format type="fixedPoint" />
      </Column>
      <Column dataField="cargoWeight" caption="화물중량" alignment="center" />
      {(data.data.fridge || data.data.danger) && (
        <Column dataField="temperature" caption="온도" alignment="center" />
      )}
      {(data.data.fridge || data.data.danger) && (
        <Column dataField="vent" caption="환풍구" alignment="center" />
      )}
      {(data.data.fridge || data.data.danger) && (
        <Column dataField="unNo" caption="UN넘버" alignment="center" />
      )}
      {(data.data.fridge || data.data.danger) && (
        <Column dataField="class" caption="클래스" alignment="center" />
      )}
      {(data.data.fridge || data.data.danger) && (
        <Column dataField="extraInfo" caption="기타정보" alignment="center" />
      )}
    </DataGrid>
  );
}

export default YongchaDispatchDetail;
