import reset from "styled-reset";
import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
${reset};

html,body {
  margin: 0;
  padding: 0;
  font-size: 10px !important;
  font-family: "Spoqa Han Sans", sans-serif;
  letter-spacing: -0.07rem;
};

a {
  color: inherit;
  text-decoration: none;
};

a:visited {
  color: #000000;
}

p {
  margin: 0;
};

ul {
  margin-top: 0;
  margin-bottom: 0;
};

li {
  list-style: none;
};

fieldset {
  border: none;
};

input {
  border: 1px solid #dddddd;
  border-radius: 4px;
  height: 26px;
  width: 180px;
};

textarea {
  border: 1px solid #dddddd;
  border-radius: 4px;
};

@page {
  size: A4;
  margin: 5mm;
}

@media print {
.modal-wrap {
  position: fixed;
  top:0;
  left:0;
}

.modal-form-body {
  padding: 5mm !important;
}

.print-layout {
  position: absolute !important;
  grid-area: unset !important;
  width: 200mm !important;
  z-index: 10000 !important;
  top:0 !important;
  left: 0 !important;
}

.print-out {
  display: flex !important;
  -webkit-print-color-adjust:exact;
}

  .no-print {
    display: none !important;
  }

  .modal-wrap {
    width: 100% !important;
    height: 100% !important;
    top: 0 !important;
    left: 0 !important;
    
    &> div {
      max-width: 100%;
      align-items: flex-start !important;
      justify-content: center !important;
    }
  }

  .modal-form-header {
    display: none !important;
  }

  .modal-body {
    padding: 0 !important;
  }


  .taxissue-header-print, .item-tit, .invoicer-tit, .invoicee-tit {
    
    -webkit-print-color-adjust: exact;
  }

  .dx-texteditor {
    border: none !important;
  }

  button {
    display: none !important;
  }
}
`;

export default GlobalStyle;
