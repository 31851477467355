import React from "react";
import { LoadingWrapper } from "./loading.style";

const Loading = (props) => {
  return (
    <LoadingWrapper
      className={`${props.type === "page" ? "page-load-wrap" : ""}`}
    >
      <div className="loading-set loader-1"></div>
      {props.text && <div className="loading-txt">{props.text}</div>}
    </LoadingWrapper>
  );
};

export default Loading;
