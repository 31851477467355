import { DataGrid } from "devextreme-react";
import {
  Column,
  ColumnChooser,
  FilterRow,
  Format,
  StateStoring,
} from "devextreme-react/data-grid";
import React, { useCallback, useState } from "react";
import {
  DataCount,
  PanelGridInit,
} from "../../../../components/Layout/Panel/PanelGrid/panelGrid.style";
import StateStoringSaveAndDeleteButton from "../../../../components/StateStoring";

const NonPaymentUpper = ({ state, masterFunction, dataGridRef }) => {
  const storageKey = "nonPaymentUpper";
  const [totalCount, setTotalCount] = useState(0);

  const { dataSource } = state;
  const { onFocusedRowChanged } = masterFunction;

  const cellRender = (e) => {
    const { text } = e;
    if (!text) return "";

    const first = text.substr(0, 4);
    const second = text.substr(4, 2);

    return `${first}-${second}`;
  };

  return (
    <>
      <PanelGridInit gridItems column itemTop>
        <StateStoringSaveAndDeleteButton
          dataGridRef={dataGridRef}
          storageKey={storageKey}
        />
        <DataCount gridItems>{`총 ${totalCount}건`}</DataCount>
        <DataGrid
          className="datagrid-wrap"
          id="NonPaymentUpper"
          dataSource={dataSource}
          ref={dataGridRef}
          showRowLines={true}
          showBorders={true}
          hoverStateEnabled={true}
          allowColumnResizing={true}
          allowColumnReordering={true}
          columnResizingMode="widget"
          keyExpr="id"
          focusedRowEnabled={true}
          onFocusedRowChanged={onFocusedRowChanged}
          onContentReady={(e) => setTotalCount(e.component.totalCount())}
        >
          <FilterRow visible={true} />
          <ColumnChooser enabled={true} />
          <StateStoring
            enabled={true}
            type="custom"
            customLoad={useCallback(() => {
              return JSON.parse(localStorage.getItem(storageKey));
            }, [])}
          />
          <Column
            dataField="baseMonth"
            caption="년월"
            // cellRender={cellRender}
          />
          <Column dataField="receiveCo" caption="접수법인" />
          <Column dataField="division" caption="구분" />
          <Column dataField="company" caption="청구처/용차사/차량번호" />
          <Column dataField="cycle" caption="결제일자" />
          <Column dataField="total" caption="금액" dataType="number">
            <Format type="fixedPoint" />
          </Column>
          <Column dataField="taxTotal" caption="세액" dataType="number">
            <Format type="fixedPoint" />
          </Column>
          <Column dataField="priceTotal" caption="합계" dataType="number">
            <Format type="fixedPoint" />
          </Column>
          <Column dataField="totalBank" caption="통장합계" dataType="number">
            <Format type="fixedPoint" />
          </Column>
          <Column dataField="nonPayment" caption="미수금" dataType="number">
            <Format type="fixedPoint" />
          </Column>
        </DataGrid>
      </PanelGridInit>
    </>
  );
};

export default NonPaymentUpper;
