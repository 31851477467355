import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";

import {
  Column,
  ColumnChooser,
  DataGrid,
  FilterRow,
  HeaderFilter,
  Scrolling,
  StateStoring,
} from "devextreme-react/data-grid";
import CustomStore from "devextreme/data/custom_store";
import { sendRequest } from "../../../../_metronic/_helpers/DataSoureHelpers";
import InputText from "../../../components/InputForm/InputText";
import StateStoringSaveAndDeleteButton from "../../../components/StateStoring";
import InputSelect from "../../../components/InputForm/InputSelect";
import {
  DataCount,
  PanelGridInit,
  PanelGridSearchBar,
  PanelGridWrapper,
  SearchItems,
} from "../../../components/Layout/Panel/PanelGrid/panelGrid.style";
import CustomButton from "../../../components/FunctionButton/CustomButton";

const AppFileManageMaster = ({
  lookupData,
  getLookup,
  onFocusedRowChanged,
  dataGridRef,
}) => {
  /* #region useState, useRef -----------------------------------------------------------------------*/
  const [totalCount, setTotalCount] = useState(0);
  const [search, setSearch] = useState({});

  const storageKey = "appFileManageMaster";
  /* #endregion */

  /* #region initialState, react-hook-form, yup ---------------------------------------------------- */
  const initialState = {
    FileList_Code: "",
    File_Name: "",
    Use_Yn: "",
  };

  const {
    reset,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();
  /* #endregion */

  /* #region useEffect ----------------------------------------------------------------------------- */
  useEffect(() => {
    getLookup();
    reset(initialState);
    setSearch(initialState);
    // eslint-disable-next-line
  }, []);
  /* #endregion */

  const dataSource = useMemo(() => {
    const apiUrl = `${process.env.REACT_APP_API_URL}/app-file-manage`;
    return new CustomStore({
      key: "oid",
      load: () => sendRequest(apiUrl, "GET", search),
    });
  }, [search]);

  const onSubmit = (data) => {
    for (const obj in data) {
      if (data[obj] === "") {
        data[obj] = null;
      }
    }
    setSearch(data);
  };

  return (
    <>
      <PanelGridWrapper>
        <PanelGridSearchBar onSubmit={handleSubmit(onSubmit)}>
          <SearchItems>
            <label>구분</label>
            <InputSelect
              control={control}
              errors={errors}
              name="FileList_Code"
              dataSource={lookupData.filelistCode}
              valueExpr="subCode"
              displayExpr="subName"
              flex={true}
            />
          </SearchItems>
          <SearchItems>
            <label>관리명</label>
            <InputText
              control={control}
              errors={errors}
              name="File_Name"
              flex={true}
            />
          </SearchItems>
          <SearchItems>
            <label>사용여부</label>
            <InputSelect
              control={control}
              errors={errors}
              name="Use_Yn"
              dataSource={lookupData.useYn}
              valueExpr="subCode"
              displayExpr="subName"
              flex={true}
            />
          </SearchItems>
          <CustomButton
            type="submit"
            height={24}
            layout="solid"
            color="search"
            label="검색"
          />
        </PanelGridSearchBar>

        <PanelGridInit>
          <StateStoringSaveAndDeleteButton
            dataGridRef={dataGridRef}
            storageKey={storageKey}
          />
          <DataCount>{`총 ${totalCount}건`}</DataCount>
          <DataGrid
            className="datagrid-wrap"
            id="AppFileManageMaster"
            ref={dataGridRef}
            dataSource={dataSource}
            keyExpr="oid"
            showRowLines={true}
            showBorders={true}
            hoverStateEnabled={true}
            allowColumnResizing={true}
            allowColumnReordering={true}
            columnResizingMode="widget"
            focusedRowEnabled={true}
            onFocusedRowChanged={onFocusedRowChanged}
            onContentReady={(e) => setTotalCount(e.component.totalCount())}
          >
            <FilterRow visible={true} />
            <HeaderFilter visible={true} />
            <ColumnChooser enabled={true} />
            <StateStoring
              enabled={true}
              type="custom"
              customLoad={useCallback(() => {
                return JSON.parse(localStorage.getItem(storageKey));
              }, [])}
            />
            <Scrolling
              columnRenderingMode="virtual"
              mode="virtual"
              showScrollbar="always"
              useNative="false"
            />
            <Column
              caption="No"
              cellRender={(e) => e.row.loadIndex + 1}
              alignment="center"
            />
            <Column dataField="fileListCodeName" caption="구분" />
            <Column dataField="fileName" caption="관리명" />
            <Column dataField="fileInfo" caption="파일" />
            <Column dataField="regDate" caption="등록일" />
            <Column dataField="useYnName" caption="사용여부" />
            <Column dataField="remark" caption="비고" />
          </DataGrid>
        </PanelGridInit>
      </PanelGridWrapper>
    </>
  );
};

export default AppFileManageMaster;
