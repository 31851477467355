import React from "react";
import { InputNumberMinus } from "../../../../components/InputForm";
import {
  InputDivide,
  InputFullRow,
  PanelInputWrapper,
} from "../../../../components/Layout/Panel/PanelInput/panelInput.style";

const CalculateProfitInput = ({ inputForm }) => {
  const {
    control,
    formState: { errors },
  } = inputForm;
  return (
    <PanelInputWrapper openInputBar={true}>
      <InputNumberMinus
        control={control}
        errors={errors}
        name="profit"
        label="매출액"
        className="mb-3"
        disabled={true}
      />
      <InputNumberMinus
        control={control}
        errors={errors}
        name="purchase"
        label="매입액"
        className="mb-3"
        disabled={true}
      />
      <InputNumberMinus
        control={control}
        errors={errors}
        name="income"
        label="이익금"
        className="mb-3"
        disabled={true}
      />

      <InputFullRow grid={4}>
        <InputDivide />
      </InputFullRow>

      <InputNumberMinus
        control={control}
        errors={errors}
        name="etcProfit"
        label="기타매출"
        className="mb-3 mt-3"
        disabled={true}
      />
      <InputNumberMinus
        control={control}
        errors={errors}
        name="etcPurchase"
        label="기타매입"
        className="mb-3"
        disabled={true}
      />
      <InputNumberMinus
        control={control}
        errors={errors}
        name="netProfit"
        label="순수익"
        className="mb-3"
        disabled={true}
      />
    </PanelInputWrapper>
  );
};

export default CalculateProfitInput;
