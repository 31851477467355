import React from "react";
import CustomButton from "../../../components/FunctionButton/CustomButton";
import HeaderTitle from "../../../components/Layout/Header/HeaderTitle";
import {
  GridButtonContent,
  GridButtonRow,
  PanelGridInit,
  PanelGridWrapper,
  PanelMultiGrid,
  PanelMultiGridTit,
} from "../../../components/Layout/Panel/PanelGrid/panelGrid.style";
import {
  PanelBody,
  PanelBtn,
  PanelHeader,
  PanelInit,
} from "../../../components/Layout/Panel/panel.style";
import { SuttleExcelGrid } from "./components/SuttleExcelGrid";
import { SuttleExcelUploadModal } from "./components/SuttleExcelUploadModal";
import { useShuttleExcelUpload } from "./useShuttleExcelUpload";

function ShuttleExcelUploadPage() {
  const {
    state,
    openModal,
    closeModal,
    processExcelData,
    onSaveExcelData,
    exportfailedExcel,
    exportExcelSample,
  } = useShuttleExcelUpload();

  const {
    isLoading,
    isModalOpen,
    shuttleSuccessDataSource,
    shuttleFailDataSource,
  } = state;

  return (
    <>
      {isModalOpen && (
        <SuttleExcelUploadModal
          show={isModalOpen}
          onClose={closeModal}
          closable={true}
          visible={true}
          maskClosable={true}
          processExcelData={processExcelData}
          onSaveExcelData={onSaveExcelData}
          isLoading={isLoading || false}
        />
      )}

      <PanelInit>
        <PanelHeader>
          <HeaderTitle />
          <PanelBtn>
            <CustomButton
              type="button"
              height={24}
              layout="solid"
              color="new"
              label="엑셀업로드"
              onClick={openModal}
            />
            <CustomButton
              type="button"
              height={24}
              layout="solid"
              color="search"
              label="엑셀샘플다운로드"
              onClick={exportExcelSample}
            />
          </PanelBtn>
        </PanelHeader>
        <PanelBody row left="100%" right="auto">
          <PanelGridWrapper>
            <PanelMultiGrid column top="50%" bottom="50%">
              <PanelGridInit gridItems column itemTop>
                <PanelMultiGridTit>엑셀업로드 성공 내역</PanelMultiGridTit>
                {/* <DataCount gridItems>{`총 ${state.totalCount}건`}</DataCount> */}
                <SuttleExcelGrid
                  id="shuttleSuccess"
                  dataSource={shuttleSuccessDataSource}
                />
              </PanelGridInit>

              <PanelGridInit gridSub gridItems column borderTop>
                <PanelMultiGridTit>
                  엑셀업로드 실패 내역
                  <GridButtonRow>
                    <span>
                      업로드에 실패한 에러내용 컬럼은 삭제후 업로드 해주세요.
                    </span>
                    <CustomButton
                      type="button"
                      height={24}
                      layout="solid"
                      color="common"
                      label="실패 내역 다운로드"
                      onClick={exportfailedExcel}
                    />
                  </GridButtonRow>
                </PanelMultiGridTit>
                <GridButtonContent>
                  <SuttleExcelGrid
                    gridItems
                    id="shuttleFail"
                    dataSource={shuttleFailDataSource}
                  />
                </GridButtonContent>
              </PanelGridInit>
            </PanelMultiGrid>
          </PanelGridWrapper>
        </PanelBody>
      </PanelInit>
    </>
  );
}

export default ShuttleExcelUploadPage;
