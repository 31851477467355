import { DataGrid } from "devextreme-react";
import { Column, Format } from "devextreme-react/data-grid";
import CustomStore from "devextreme/data/custom_store";
import React, { useCallback, useMemo } from "react";
import { sendRequest } from "../../../../_metronic/_helpers/DataSoureHelpers";

function ShuttleDispatchDetail({
  data,
  setDetailRowData,
  dataGridRef,
  detailGridRef,
  onFocusedRowChanged,
}) {
  const dataSource = useMemo(() => {
    const apiUrl = `${process.env.REACT_APP_API_URL}/shuttlereturn/detail`;

    return new CustomStore({
      key: "id",
      load: () => sendRequest(apiUrl + `/${data}`, "GET"),
    });
    // eslint-disable-next-line
  }, []);

  // functions
  const onDetailCellClick = useCallback((e) => {
    if (!e.row) return;
    dataGridRef.current.instance.option("focusedRowIndex", -1);
    setDetailRowData(e.row.data);
    // eslint-disable-next-line
  }, []);

  const onRowPrepared = useCallback((e) => {
    if (e.rowType !== "data") return;

    // 색깔 조정하기
    if (e.data.dispatchStatus === 62) {
      // 접수
      e.rowElement.style.background = "#B3E8E5";
    } else if (e.data.dispatchStatus === 78) {
      // 배차등록
      e.rowElement.style.background = "#82DBD8";
    } else if (e.data.dispatchStatus === 79) {
      // 배차확정
      e.rowElement.style.background = "#3BACB6";
    } else if (e.data.dispatchStatus === 80) {
      // 운송완료
      e.rowElement.style.background = "#2F8F9D";
    } else if (e.data.dispatchStatus === 4048) {
      // 창고작업완료
      e.rowElement.style.background = "#2F8F9D";
    }
  }, []);

  return (
    <DataGrid
      id="DispatchDetail"
      showBorders={true}
      columnAutoWidth={true}
      dataSource={dataSource}
      ref={detailGridRef}
      allowColumnResizing={true}
      columnResizingMode="widget"
      focusedRowEnabled={true}
      onFocusedRowChanged={onFocusedRowChanged}
      onCellClick={onDetailCellClick}
      onRowPrepared={onRowPrepared}
      width="70vw"
    >
      {/* <Column dataField="dispatchStatus_comCodeDetail.subName" caption="상태" alignment="center" />
      <Column dataField="deliverDate" caption="운송일자" alignment="center" dataType="date" format="yyyy-MM-dd" />
      <Column dataField="carNo_car.carNo" caption="배차차량" />
      <Column dataField="yongchasa_billingPlace.companyName" caption="용차사" />
      <Column dataField="loadPlace" caption="상차지" alignment="center" />
      <Column dataField="workWaypoint_terminalWarehouse.twName" caption="작업경유지" />
      <Column dataField="workPlace_workPlace.workPlaceNaem" caption="작업지" />
      <Column dataField="collectWaypoint_terminalWarehouse.twName" caption="회수경유지" />
      <Column dataField="unloadPlace" caption="하차지" alignment="center" />
      <Column dataField="habul" caption="하불금액" alignment="right" dataType="number">
        <Format type="fixedPoint" />
      </Column> */}
      <Column dataField="dispatchStatus" caption="상태" alignment="center" />
      <Column dataField="deliverDate" caption="운송일자" alignment="center" />
      <Column dataField="car" caption="배차차량" />
      <Column dataField="yongchasa" caption="용차사" />
      <Column dataField="startCode" caption="출발지코드" />
      <Column dataField="startPoint" caption="출발지" />
      <Column dataField="endPoint" caption="도착지" />
      <Column
        dataField="habul"
        caption="하불금액"
        alignment="right"
        dataType="number"
      >
        <Format type="fixedPoint" />
      </Column>
    </DataGrid>
  );
}

export default ShuttleDispatchDetail;
