import { all } from "redux-saga/effects";
import { combineReducers } from "redux";

import * as auth from "../app/modules/Auth/_redux/authRedux";
import { menuSlice } from "../_metronic/layout/components/aside/aside-menu/_redux/menuSlice";

export const rootReducer = combineReducers({
  auth: auth.reducer,
  menu: menuSlice.reducer,
});

export function* rootSaga() {
  yield all([auth.saga()]);
}
