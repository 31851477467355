import styled, { css } from "styled-components";

export const ModalWrapper = styled.div`
  box-sizing: border-box;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  overflow: auto;
  outline: 0;

  &.w-1100px {
    & > div {
      width: 1100px !important;
      max-width: 1100px !important;
    }

    &.closing-modal {
      .modal-form-body {
        .tradeset {
          tr {
            border-left: none;
            border-right: none;
          }
        }
      }
    }
  }
`;

export const ModalTaxIssueWrapper = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  box-sizing: border-box;
  font-size: 13px;
  word-break: break-all;
  border-collapse: separate;
  border-color: ${(props) => props.theme.colors.tableBorder};
  border-top: 2px solid ${(props) => props.theme.colors.tableTopBottomBorder};
  border-bottom: 2px solid ${(props) => props.theme.colors.tableTopBottomBorder};
  border-spacing: 0;
  flex-direction: column;

  .readonly {
    background-color: #eaeaea !important;
    textarea {
      background-color: #eaeaea !important;
    }
  }
`;

export const ModalTaxIssueHeader = styled.div`
  display: flex;
  width: 100%;
  padding: ${(props) => (props.tradeset ? "10px" : "5px 10px")};
  color: ${(props) => props.theme.colors.tableHeaderTxt};
  background: ${(props) => props.theme.colors.tableHeaderBg};
  justify-content: space-between;

  ${(props) =>
    props.modalHeader &&
    css`
      overflow: hidden;
      height: 0;
      padding: 0;
      font-size: 24pt;
      font-weight: 600;
      color: ${(props) => props.theme.colors.tableHeaderTxt};
      background: ${(props) => props.theme.colors.white};
      justify-content: center;

      @media print {
        height: auto;
        padding: 10px;
        border-bottom: 2px solid
          ${(props) => props.theme.colors.tableTopBottomBorder};
      }
    `}
`;

export const ModalTaxIssueFooter = styled.div`
  display: grid;
  width: 100%;
  padding: ${(props) => (props.tradeset ? "10px" : "5px 10px")};
  color: ${(props) => props.theme.colors.tableHeaderTxt};
  background: ${(props) => props.theme.colors.white};
  grid-template-columns: ${(props) => (props.tradeset ? "5fr 1fr" : "100%")};
  grid-template-rows: 1fr;
`;

export const ModalTaxIssueTitle = styled.span`
  display: flex;
  width: ${(props) => (props.tradeset ? "auto" : "100%")};
  height: 100%;
  font-size: ${(props) => (props.tradeset ? "1.3rem" : "2.8rem")};
  font-weight: ${(props) => (props.tradeset ? "400" : "600")};
  align-items: center;
  justify-content: ${(props) => (props.tradeset ? "flex-start" : "center")};

  span {
    font-size: 2rem;
    font-weight: 500;
  }

  @media print {
    font-size: 11.5pt !important;
  }
`;

export const ModalTaxIssueSubtit = styled.span`
  display: flex;

  @media print {
    font-size: 11.5pt !important;
  }
`;

export const SubtitItems = styled.div`
  display: grid;
  width: 100%;

  &.grid-5 {
    grid-template-columns: 60px 1fr 20px 1fr 20px;
  }

  &.grid-2 {
    grid-template-columns: 60px 1fr;
  }

  div {
    display: flex;
    align-items: center;
    justify-content: center;

    input {
      width: 100%;
    }
  }
`;

export const ModalTaxIssueBody = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

export const InvoiceWrapper = styled.div`
  display: flex;
  width: 100%;
`;

export const Invoice = styled.div`
  display: grid;
  width: 50%;
  grid-template-columns: 30px 90px 1fr 90px 1fr;
  grid-template-rows: repeat(6, 1fr);

  &.invoicer {
    border-top: 2px solid ${(props) => props.theme.colors.tableInvoicerBorder};

    .invoicer-tit {
      background-color: ${(props) => props.theme.colors.tableInvoicerTitBg};
      color: ${(props) => props.theme.colors.tableInvoicerTitTxt};
      font-weight: bold;
      line-height: 2rem;
    }

    .item-tit {
      border-bottom-color: ${(props) => props.theme.colors.tableInvoicerBorder};
      border-right-color: ${(props) => props.theme.colors.tableInvoicerBorder};
      background-color: ${(props) => props.theme.colors.tableInvoicerBg};
      color: ${(props) => props.theme.colors.tableInvoicerTxt};
    }
  }

  &.invoicee {
    border-top: 2px solid
      ${(props) => props.theme.colors.tableInvoiceeHeaderBorder};

    .invoicee-tit {
      background-color: ${(props) => props.theme.colors.tableInvoiceeTitBg};
      color: ${(props) => props.theme.colors.tableInvoiceeTitTxt};
      font-weight: bold;
      line-height: 2rem;
    }

    .item-tit {
      border-bottom-color: ${(props) => props.theme.colors.tableInvoiceeBorder};
      border-right-color: ${(props) => props.theme.colors.tableInvoiceeBorder};
      background-color: ${(props) => props.theme.colors.tableInvoiceeBg};
      color: ${(props) => props.theme.colors.tableInvoiceeTxt};
    }
  }
`;

export const InvoiceItems = styled.div`
  -webkit-print-color-adjust: exact;
  display: flex;
  height: ${(props) => (props.gridRow ? "100%" : "31px")};
  padding: 3px;
  border-top-width: 0;
  border-left-width: 0;
  border: 0.5px solid ${(props) => props.theme.colors.tableTopBottomBorder};
  text-align: center;
  word-break: break-all;
  align-items: center;
  justify-content: center;
  grid-row: ${(props) =>
    props.gridTop ? props.gridTop + " / " + props.gridBottom : "initial"};
  grid-column: ${(props) =>
    props.gridLeft ? props.gridLeft + " / " + props.gridRight : "initial"};

  & > div {
    width: 100%;
    grid-template-columns: 100% !important;

    div {
      input,
      textarea {
        height: 24px;
        line-height: 20px;
        border: none;
        padding: 2px 5px;
      }
    }
  }
`;

export const TradesetWrapper = styled.div`
  display: flex;
  width: 100%;
`;

export const Tradeset = styled.table`
  width: 50%;
  border-top: 2px solid ${(props) => props.theme.colors.tableTradesetBorder};
  border-bottom: 2px solid ${(props) => props.theme.colors.tableTradesetBorder};

  // & + table {
  //   border-left: 1px solid ${(props) =>
    props.theme.colors.tableTradesetBorder};
  // }
`;

export const TradesetBody = styled.tbody``;

export const TradesetRows = styled.tr``;

export const TradesetItems = styled.td`
  padding: 3px;
  text-align: center;
  vertical-align: middle;

  &.tradeset-tit {
    color: ${(props) => props.theme.colors.tableTradesetTxt};
    font-weight: bold;
    line-height: 2rem;
    border-right: 1px solid;
    border-color: ${(props) => props.theme.colors.tableTradesetBorder};
    background-color: ${(props) => props.theme.colors.tableTradesetBg};
  }

  &.item-tit {
    color: ${(props) => props.theme.colors.tableTradesetTxt};
    border-left: 1px solid;
    border-right: 1px solid;
    border-color: ${(props) => props.theme.colors.tableTradesetBorder};
    background-color: ${(props) => props.theme.colors.tableTradesetBg};
  }

  & > div {
    grid-template-columns: 100%;

    .dx-texteditor-input-container {
      height: 25px;
    }
  }

  .item-input {
    display: flex;
    min-height: 25px;
    align-items: center;
    justify-content: center;
  }

  @media print {
    min-height: 50px;
    border-bottom: 1px solid
      ${(props) => props.theme.colors.tableTradesetBorder};
    font-size: 12pt;

    &.item-tit {
      width: 70px;
      font-size: 12pt;
    }

    .item-input {
      overflow: hidden;
      width: 100%;
      min-height: 36px !important;
      font-size: 11.5pt !important;

      input {
        min-height: 36px !important;
        font-family: "Spoqa Han Sans", sans-serif;
        font-size: 11.5pt !important;
        text-align: center;
        letter-spacing: -0.07rem;
      }
    }
  }
`;

export const InfoWrapper = styled.div`
  display: grid;
  width: 100%;
  border-top: 2px solid ${(props) => props.theme.colors.tableBorder};
  grid-template-columns: 120px 1fr 1fr 1fr;
  grid-template-rows: repeat(2, 1fr);
`;

export const InfoItems = styled.div`
  display: flex;
  padding: 3px;
  align-items: center;
  justify-content: center;

  &.item-tit {
    color: ${(props) => props.theme.colors.tableHeaderTxt};
    background: ${(props) => props.theme.colors.tableHeaderBg};
    border-bottom: 1px solid
      ${(props) => props.theme.colors.tableTopBottomBorder};
  }

  & + div {
    border-left: 1px solid ${(props) => props.theme.colors.tableTopBottomBorder};

    &.no-border {
      border-left: none;
    }
  }

  & > div {
    width: 100%;
    grid-template-columns: 100% !important;

    div {
      input,
      textarea {
        height: 24px;
        line-height: 20px;
        border: none;
        padding: 2px 5px;
      }
    }
  }
`;

export const AdditionalWrapper = styled.div`
  display: grid;
  width: 100%;
  margin: ${(props) => (props.tradeset ? "0 0 10px" : "0")};
  border-top: 2px solid ${(props) => props.theme.colors.tableBorder};
  border-bottom: 2px solid ${(props) => props.theme.colors.tableBorder};
  grid-template-columns: ${(props) => (props.tradeset ? "100%" : "120px 1fr")};
  grid-template-rows: 1fr;
`;

export const AdditionalGrid = styled.div`
  display: none;
  width: 100%;
  border: 1px solid ${(props) => props.theme.colors.tableTradesetBorder};
  flex-direction: column;
`;

export const GridHeader = styled.div`
  display: flex;
  width: 100%;
  background: ${(props) => props.theme.colors.gridDetailHeader};
`;

export const GridHeaderItem = styled.div`
  display: flex;
  width: ${(props) => props.width}%;
  padding: 5px;
  color: ${(props) => props.theme.colors.white};
  background: ${(props) => props.theme.colors.gridDetailHeader};
  justify-content: ${(props) =>
    props.alignTxt === "left"
      ? "flex-start"
      : props.alignTxt === "right"
      ? "flex-end"
      : "center"};
  text-align: ${(props) => props.alignTxt};
  align-items: center;
  word-break: break-all;

  & + div {
    border-left: 1px solid ${(props) => props.theme.colors.white};
  }

  &.last-cell {
    border-left: 1px solid ${(props) => props.theme.colors.tableTopBottomBorder};
  }

  @media print {
    font-size: 11.5pt !important;
  }
`;

export const GridTr = styled.div`
  display: flex;
  width: 100%;
  font-size: 1.2rem;
  page-break-inside: avoid !important;

  & + div {
    border-top: 1px solid ${(props) => props.theme.colors.tableTradesetBorder};
  }
`;

export const GridItem = styled.div`
  display: flex;
  width: ${(props) => props.width}%;
  padding: 5px;
  align-items: center;
  justify-content: ${(props) =>
    props.alignTxt === "left"
      ? "flex-start"
      : props.alignTxt === "right"
      ? "flex-end"
      : "center"};
  text-align: ${(props) => props.alignTxt};
  word-break: break-all;
  page-break-inside: avoid !important;

  & + div {
    border-left: 1px solid ${(props) => props.theme.colors.tableTradesetBorder};
  }

  &.last-cell {
    border-left: 1px solid ${(props) => props.theme.colors.tableTopBottomBorder};
  }

  @media print {
    font-size: 11.5pt !important;
  }
`;

export const AdditionalItems = styled.div`
  display: flex;
  padding: 3px;
  border-bottom: 1px solid ${(props) => props.theme.colors.tableTopBottomBorder};
  align-items: center;
  justify-content: center;

  &.item-tit {
    color: ${(props) => props.theme.colors.tableHeaderTxt};
    background: ${(props) => props.theme.colors.tableHeaderBg};
  }

  & > div {
    width: 100%;
    grid-template-columns: 100% !important;

    div {
      input,
      textarea {
        height: 24px;
        line-height: 20px;
        border: none;
        padding: 2px 5px;
      }
    }
  }
`;

export const DetailWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

export const DetailItemsList = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: 40px 40px 1fr 60px 60px 90px 90px 90px 1fr 100px;
`;

export const DetailItems = styled.div`
  display: flex;
  height: 32px;
  padding: 3px;
  border-bottom: 1px solid ${(props) => props.theme.colors.tableTopBottomBorder};
  align-items: center;
  justify-content: center;

  &.grid-full {
    padding: 30px 0;
    grid-column: 1 / 11;
  }

  &.item-tit {
    color: ${(props) => props.theme.colors.tableHeaderTxt};
    background: ${(props) => props.theme.colors.tableHeaderBg};
    border-top: 2px solid ${(props) => props.theme.colors.tableBorder};
  }

  & + div {
    border-left: 1px solid ${(props) => props.theme.colors.tableTopBottomBorder};
  }

  & > div {
    width: 100%;
    grid-template-columns: 100% !important;

    div {
      input,
      textarea {
        height: 24px;
        line-height: 20px;
        border: none;
        padding: 2px 5px;
      }
    }
  }
`;

export const TotalWrapper = styled.div`
  -webkit-print-color-adjust: exact;
  display: grid;
  width: 100%;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(3, 1fr);
  border-top: 2px solid ${(props) => props.theme.colors.tableTopBottomBorder};
  border-bottom: 2px solid ${(props) => props.theme.colors.tableTopBottomBorder};

  .total-tit {
    display: flex;
    padding: 0 10px;
    font-weight: bold;
    align-items: center;
    background: ${(props) => props.theme.colors.tableTradesetBg};
    grid-row: 1/4;
  }

  .total-items {
    display: grid;
    width: 100%;
    grid-template-columns: repeat(2, 1fr);

    & + .total-items {
      border-top: 1px solid ${(props) => props.theme.colors.tableTradesetBorder};
    }

    & > div {
      padding: 3px;

      & > div {
        width: 100%;
        grid-template-columns: 100% !important;
      }
    }

    .item-tit {
      display: flex;
      padding: 0px 10px;
      align-items: center;
      border-left: 1px solid
        ${(props) => props.theme.colors.tableTradesetBorder};
      border-right: 1px solid
        ${(props) => props.theme.colors.tableTradesetBorder};
      background: ${(props) => props.theme.colors.tableTradesetBg};
    }
  }

  @media print {
    .total-tit,
    .item-tit {
      font-size: 11.5pt !important;
    }

    .dx-state-disabled input {
      font-size: 11.5pt !important;
    }
  }
`;

export const CloseWrapper = styled.div`
  display: grid;
  width: 100%;
  border-top: 2px solid ${(props) => props.theme.colors.tableBorder};
  border-bottom: 2px solid ${(props) => props.theme.colors.tableBorder};
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: repeat(2, 1fr);
`;

export const CloseItems = styled.div`
  display: flex;
  height: 32px;
  padding: 3px;
  align-items: center;
  justify-content: center;
  grid-row: ${(props) =>
    props.gridTop ? props.gridTop + " / " + props.gridBottom : "initial"};
  grid-column: ${(props) =>
    props.gridLeft ? props.gridLeft + " / " + props.gridRight : "initial"};

  &.item-tit {
    color: ${(props) => props.theme.colors.tableHeaderTxt};
    background: ${(props) => props.theme.colors.tableHeaderBg};
    border-bottom: 1px solid
      ${(props) => props.theme.colors.tableTopBottomBorder};
  }

  &.item-finish {
    height: 64px;
    flex-direction: column;
    gap: 5px;

    div {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;

      label {
        display: flex;
        font-size: 1.3rem;
        align-items: center;
        gap: 5px;

        input[type="radio"] {
          display: inline-block;
          width: 13px;
          height: 13px;
          line-height: 1;
          vertical-align: baseline;
          cursor: pointer;
        }
      }
    }
  }

  & + div {
    border-left: 1px solid ${(props) => props.theme.colors.tableTopBottomBorder};

    &.no-border {
      border-left: none;
    }
  }

  & > div {
    width: 100%;
    grid-template-columns: 100% !important;

    div {
      input,
      textarea {
        height: 24px;
        line-height: 20px;
        border: none;
        padding: 2px 5px;
      }
    }
  }
`;

export const ModalMailingWrapper = styled.div`
  display: flex;
  padding: 20px 0;
  gap: 5px;
  flex-direction: column;
`;

export const ModalMailingAttach = styled.div`
  display: grid;
  margin-bottom: 10px;
  grid-template-columns: 65px calc(100% - 70px);
  grid-gap: 5px;

  label {
    display: flex;
    align-items: center;
  }

  div {
    display: flex;
    padding: 6px 5px;
    font-size: 1.1rem;
    font-weight: bold;
    border: 1px solid #ddd;
    border-radius: 4px;
    background: ${(props) => props.theme.colors.inputDisabledBg};
    align-items: center;
  }
`;

export const UploadFileWrapper = styled.div`
  display: flex;
  margin-top: 10px;

  .container {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 10px;

    .dropzone {
      display: flex;
      width: 100%;
      height: 100px;
      color: ${(props) => props.theme.colors.tableBorder};
      font-size: 1.2rem;
      border: 1px dashed ${(props) => props.theme.colors.tableBorder};
      background: ${(props) => props.theme.colors.tableHeaderBg};
      align-items: center;
      justify-content: center;
      cursor: pointer;

      p {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 5px;

        svg {
          width: 36px;
          height: 36px;
          path:last-child {
            fill: ${(props) => props.theme.colors.tableBorder};
          }
        }
      }
    }

    aside {
      display: grid;
      width: 100%;
      font-size: 1.2rem;
      grid-template-columns: 40px auto;
      gap: 10px;

      div {
        display: flex;
        align-items: center;
      }

      p {
        min-height: 20px;
        padding: 5px;
        border: 1px solid ${(props) => props.theme.colors.gridBorder};
        border-radius: 2px;
      }
    }
  }
`;
