import { yupResolver } from "@hookform/resolvers/yup";
import { alert, confirm } from "devextreme/ui/dialog";
import moment from "moment";
import {
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useRef,
  useState,
} from "react";
import { useForm } from "react-hook-form";
import { shallowEqual, useSelector } from "react-redux";

import { sendRequest } from "../../../../_metronic/_helpers/DataSoureHelpers";
import {
  handleOtherDeliver,
  makeOtherCoList,
} from "../../../utils/orderFunctions";
import { getUnipassInfo } from "../OrderPage/OrderCrud";
import {
  getInputInitialState,
  getSearchInitialState,
  inputSchema,
  SimpleSellFinishReducer,
} from "./components/SimpleSellFinishData";
import {
  createSimpleFinish,
  deleteSimpleFinish,
  getOrderDataByCode,
  initLookupFetch,
  updateSimpleFinish,
} from "./SimpleSellFinishCrud";

const useSimpleSellFinish = () => {
  const { user } = useSelector(
    (state) => ({
      user: state.auth.user.user,
    }),
    shallowEqual
  );
  const searchInitialState = getSearchInitialState(user);
  const inputInitialState = getInputInitialState(user);

  const initialState = {
    rowData: null,

    search: searchInitialState,
    lookupData: {
      company: [],
      billing: [],
      orderCo: [],
      forwarder: [],
      terminal: [],
      cargoOwner: [],
      conType: [],
      car: [],
      yongchasa: [],
      workPlace: [],
      color: [],

      dispatchName: [],

      amountUnit: [],
    },
    otherCo: [],

    openTerminalCreateModal: false,
    openCompanyCreateModal: false,
    openForwarderCreateModal: false,
    openInputBar: false,

    type: "",

    masterDataSource: [],

    isLoading: false,
    isConfirm: false,
    importExport: 1,
  };
  const [state, dispatch] = useReducer(SimpleSellFinishReducer, initialState);

  // 마감된 데이터 수정
  const [isOrderEditable, setIsOrderEditable] = useState(false);
  const [isDisEditable, setIsDisEditable] = useState(false);

  const dataGridRef = useRef();

  const { openInputBar, rowData, search, lookupData, otherCo } = state;

  // search용 useForm
  const searchForm = useForm({
    defaultValues: searchInitialState,
  });

  // input용 useForm
  const inputForm = useForm({
    defaultValues: inputInitialState,
    resolver: yupResolver(inputSchema),
  });
  const {
    watch: inputWatch,
    setValue: inputSetValue,
    reset: inputReset,
  } = inputForm;

  useEffect(() => {
    pageFunctions.initLookupData();
    dispatch({
      type: "SET_SEARCH",
      payload: searchInitialState,
    });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    _getOrderMasterData(search);
    // eslint-disable-next-line
  }, [search]);

  useEffect(() => {
    _getInputDataByCode(rowData?.data?.code);
    // eslint-disable-next-line
  }, [rowData]);

  useEffect(() => {
    dispatch({
      type: "SET_OTHERCO_DATA",
      payload: _makeOtherCo,
    });
    handleOtherDeliver(
      rowData,
      inputWatch("receiveCo"),
      inputWatch("otherDeliver"),
      inputSetValue
    );
    // eslint-disable-next-line
  }, [
    lookupData.company,
    otherCo,
    handleOtherDeliver,
    // eslint-disable-next-line
    inputWatch("receiveCo"),
  ]);

  const _makeOtherCo = useMemo(
    () => makeOtherCoList(lookupData, inputWatch("receiveCo")),
    // eslint-disable-next-line
    [lookupData.company, inputWatch("receiveCo")]
  );

  /** isLoading 토글 */
  const _setIsLoading = (bool) => {
    dispatch({
      type: "SET_LOADING",
      payload: bool,
    });
  };

  const _getOrderMasterData = async (search) => {
    const apiUrl = `${process.env.REACT_APP_API_URL}/simple-sell-finish`;
    try {
      _setIsLoading(true);
      const data = await sendRequest(apiUrl, "GET", search);

      dispatch({
        type: "SET_ORDER_MASTER_GRID_DATA",
        payload: data,
      });
    } catch (error) {
      console.log(error);
    } finally {
      _setIsLoading(false);
    }
  };

  const _getInputDataByCode = async (code) => {
    if (!code) {
      inputReset(inputInitialState);
      return;
    }

    setIsOrderEditable(false);
    setIsDisEditable(false);

    try {
      _setIsLoading(true);

      const { data } = await getOrderDataByCode(code);
      const { master, detail, dispatch, finishInfoDetail } = data;

      if (finishInfoDetail.completeNo) setIsOrderEditable(true);
      if (dispatch[0]?.completeNo) setIsDisEditable(true);

      inputReset({ ...master[0], ...detail[0], ...dispatch[0] });

      // 여기서 input에 데이터바인딩
    } catch (error) {
      console.log(error);
    } finally {
      _setIsLoading(false);
    }
  };

  const _getMasterData = (data) => {
    return {
      code: data.code,
      importExport: data.importExport,
      receiveCo: data.receiveCo,
      receiveDate: data.receiveDate,
      dispatchTeam: data.dispatchTeam,
      billingPlace: data.billingPlace,
      orderCo: data.orderCo,
      forwarder: data.forwarder,
      shipName: data.shipName,
      motherShip: data.motherShip,
      dem: data.dem,
      manager: data.manager,
      enterDay: data.enterDay,
      sailDay: data.sailDay,
      bkNo: data.bkNo,
      blNo: data.blNo,
      blType: data.blType,
      loadPlaceName: data.loadPlaceName,
      loadPlace: data.loadPlace,
      unloadPlaceName: data.unloadPlaceName,
      unloadPlace: data.unloadPlace,
      cargoOwner: data.cargoOwner,
      realCargoOwner: data.realCargoOwner,
      otherDeliver: data.otherDeliver,
      color: data.color,
      fridge: data.fridge,
      danger: data.danger,
      tank: data.tank,
      direct: data.direct,
      toxic: data.toxic,
      keep: data.keep,
      remark: data.remark,
      innerMemo: data.innerMemo,
    };
  };

  const _getDetailData = (data) => {
    return {
      containerNo: data.containerNo,
      containerType: data.containerType,
      amount: data.amount,
      amountUnit: data.amountUnit,
      containerStatus01: data.containerStatus01,
      containerStatus02: data.containerStatus02,
      cargoWeight: data.cargoWeight,
      spec: data.spec,
      freight: data.freight,
      pointManager: data.pointManager,
      pointManageContact: data.pointManageContact,
    };
  };

  const _getDispatchData = (data) => {
    return {
      habul: data.habul,
      carTypeCode: data.carTypeCode,
      carNo: data.carNo,
      yongchasa: data.yongchasa,
      yongchaCarNo: data.yongchaCarNo,
    };
  };

  /** SimpleSellFinishPage의 함수들 */
  const pageFunctions = {
    /** 간편정산에서 쓰이는 모든 lookupData를 fetch */
    initLookupData: async () => {
      try {
        _setIsLoading(true);
        const result = await initLookupFetch();

        dispatch({
          type: "SET_LOOKUP_DATA",
          payload: result,
        });
      } catch (error) {
        console.log(error);
      } finally {
        _setIsLoading(false);
      }
    },

    /** 신규버튼을 눌렀을 때 */
    onClickAdd: () => {
      setIsOrderEditable(false);
      setIsDisEditable(false);

      dataGridRef.current.instance.option("focusedRowIndex", -1);
      dispatch({
        type: "SET_ROW_DATA",
        payload: {},
      });
    },

    onClickCopy: () => {
      inputSetValue("code", "");
      inputSetValue("carTypeCode", null);
      inputSetValue("carNo", null);
      inputSetValue("yongchasa", null);
      inputSetValue("yongchaCarNo", "");
    },

    /** 삭제버튼을 눌렀을 때 */
    onClickDelete: async () => {
      const res = await confirm("삭제하시겠습니까?", "확인");
      if (!res) return;

      const code = dataGridRef.current.instance.option("focusedRowKey");
      if (!code) {
        await alert("데이터를 선택해주세요", "오류");
        return;
      }

      try {
        _setIsLoading(true);

        const {
          data: { message, result },
        } = await deleteSimpleFinish(code);

        if (result === -1) {
          await alert(`${message || "ERROR"}`, "오류");
        } else {
          await alert(`삭제완료`, "확인");
          dataGridRef.current.instance.refresh();
          _getOrderMasterData(search);
        }
      } catch (error) {
        console.log(error);
      } finally {
        _setIsLoading(false);
      }
    },
  };

  /** SimpleSellFinishInput의 함수들 */
  const inputFunctions = {
    /** 저장버튼을 눌렀을 때 */
    onInputSubmit: async (data) => {
      const res = await confirm("저장하시겠습니까?", "확인");
      if (!res) return;

      const instance = dataGridRef.current.instance;

      /** 접수번호 */
      const code = data?.code;
      /** post, put 요청 후의 결과 */
      let result;

      try {
        _setIsLoading(true);
        /** 마스터 데이터 */
        const master = _getMasterData(data);
        /** 디테일 데이터 */
        const detail = _getDetailData(data);
        /** 배차 데이터 */
        const dispatch = _getDispatchData(data);

        if (code) {
          result = await updateSimpleFinish({ master, detail, dispatch });
        } else {
          result = await createSimpleFinish({ master, detail, dispatch });
        }

        const {
          data: { code: resultCode },
        } = result;

        await _getOrderMasterData(search);
        await instance.option("focusedRowKey", -1);
        instance.refresh();
      } catch (error) {
        alert("ERROR", "에러");
        console.log(error);
      } finally {
        _setIsLoading(false);
      }
    },

    /** UNI-PASS 연동버튼을 눌렀을 때 */
    getUnipassInfos: async () => {
      try {
        _setIsLoading(true);

        const { blNo, blType, blYear, loadPlace } = inputWatch();

        if (!blNo) {
          alert("B/L No를 입력해주세요", "오류");
          return false;
        }

        const {
          data: { cntrs, vydf, shipNm, etprDt },
        } = await getUnipassInfo({
          blNo: blNo,
          blType: blType,
          blYear: blYear,
          loadPlace: loadPlace,
        });

        inputSetValue("enterDay", moment(etprDt).format("YYYY-MM-DD"));
        inputSetValue("motherShip", vydf);
        inputSetValue("shipName", shipNm);
        inputSetValue("containerNo", cntrs[0]?.cntrNo);
      } catch (error) {
        alert("getUnipassInfos 에러", "오류");
        console.log(error);
      } finally {
        _setIsLoading(false);
      }
    },

    onImportExportValueChanged: (e) => {
      dispatch({ type: "SET_IMPORT_EXPORT_VALUE", payload: e.value });
    },

    /** 청구처, 발주처 등 +버튼 눌렀을 때 */
    openModal: (modalName, bool, modalType) => {
      /** reducer type */
      const type = `SET_OPEN_${modalName.toUpperCase()}_MODAL`;
      /** reducer payload */
      let payload = {};

      switch (modalName) {
        case "company":
          payload = {
            openCompanyCreateModal: bool,
            type: modalType,
          };
          break;

        case "forwarder":
          payload = {
            openForwarderCreateModal: bool,
          };
          break;

        case "terminal":
          payload = {
            openTerminalCreateModal: bool,
          };
          break;

        default:
          return {};
      }

      dispatch({ type, payload });
    },
  };

  /** SimpleSellFinishMaster의 함수들 */
  const masterFunctions = {
    onToggleInputBar: () => {
      dispatch({
        type: "SET_TOGGLE_INPUT_BAR",
        payload: !openInputBar,
      });
    },

    onSearchSubmit: async (data) => {
      dispatch({
        type: "SET_SEARCH",
        payload: data,
      });
    },

    onFocusedRowChanged: useCallback((e) => {
      if (!e.row) return;
      dispatch({
        type: "SET_ROW_DATA",
        payload: e.row,
      });
    }, []),

    onRowPrepared: useCallback(
      (e) => {
        if (e.rowType !== "data") return;

        const { data } = e;
        const colors = data.color;
        if (!colors) return;

        const filteredColorArr = lookupData.color.filter(
          (cur) => cur.subCode === colors
        );
        e.rowElement.style.background = filteredColorArr[0]?.extra1;
      },
      [lookupData.color]
    ),
  };

  return {
    state,
    dataGridRef,
    searchForm,
    inputForm,

    // page
    pageFunctions,

    // input
    inputFunctions,

    // master
    masterFunctions,

    // 디테일, 하불 마감 state
    isOrderEditable,
    isDisEditable,
  };
};

export default useSimpleSellFinish;
