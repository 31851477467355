import axios from "axios";

const apiUrl = `${process.env.REACT_APP_API_URL}/mothership`;
const apiUrlForwarder = `${process.env.REACT_APP_API_URL}/forwarder`;

export function getMotherShip(key) {
  return axios.get(apiUrl + `/${key}`);
}

export function createMotherShip(data) {
  return axios.post(apiUrl, data);
}

export function updateMotherShip(key, data) {
  return axios.put(apiUrl, { key, data });
}

export function deleteRow(key) {
  return axios.delete(apiUrl + `/${key}`);
}

export function getForwarder() {
  return axios.get(apiUrlForwarder);
}
