import React from "react";
import ModalContainer from "../../../../components/Modal/ModalContainer";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";
import { useForm } from "react-hook-form";
import { InputDate } from "../../../../components/InputForm";
import { ButtonGroup } from "../../../../components/FunctionButton/CustomButton/customButton.style";
import CustomButton from "../../../../components/FunctionButton/CustomButton";
import { updateDeliverDate } from "../FinishManageCrud";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

const DeliverDateModal = ({
  onClose,
  maskClosable,
  closable,
  visible,
  onSelect,
  finishUpperRef,
  finishLowerRef,
}) => {
  const schema = yup.object({
    deliverDate: yup
      .string()
      .nullable()
      .required("운송일자를 입력해주세요"),
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data) => {
    const list = finishUpperRef.current.instance.getSelectedRowsData();
    try {
      const sendData = {
        data: list,
        deliverDate: data.deliverDate,
      };

      await updateDeliverDate(sendData);
      finishUpperRef.current.instance.refresh();
      finishLowerRef.current.instance.refresh();
      onClose();
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <>
      <ModalContainer
        onClose={onClose}
        maskClosable={maskClosable}
        visible={visible}
        size="md"
      >
        <form
          onSubmit={handleSubmit(onSubmit, (err) => console.log(err))}
          className="modal-form"
        >
          <div className="modal-form-header">
            <div className="modal-form-tit">
              <span className="modal-tit">운송일자 변경</span>
            </div>
            <button
              type="button"
              className="btn-modal btn-modal-close"
              onClick={onClose}
            >
              <SVG
                src={toAbsoluteUrl(
                  "/media/svg/icons/Custom/close_black_24dp.svg"
                )}
                className="close-icon"
              />
            </button>
          </div>

          <div className="modal-form-body">
            <InputDate
              control={control}
              errors={errors}
              name="deliverDate"
              label="운송일자"
              flex={true}
              onInitialized={(e) => {
                setTimeout(() => {
                  e.component.focus();
                }, 0);
              }}
            />
          </div>
          <ButtonGroup justifyContent="flex-end">
            <CustomButton
              type="submit"
              height={36}
              layout="solid"
              color="common"
              label="저장"
            />
          </ButtonGroup>
        </form>
      </ModalContainer>
    </>
  );
};

export default DeliverDateModal;
