import React, { useMemo, useState } from "react";
import { DataGrid } from "devextreme-react";
import { Column, ColumnChooser, Editing } from "devextreme-react/data-grid";
import CustomStore from "devextreme/data/custom_store";
import { sendRequest } from "../../../../_metronic/_helpers/DataSoureHelpers";
import { DataCount } from "../../../components/Layout/Panel/PanelGrid/panelGrid.style";

export default function AuthGroup({
  onFocusedRowChanged,
  dataGridRef,
  search,
}) {
  const [totalCount, setTotalCount] = useState(0);
  const dataSource = useMemo(() => {
    const apiUrl = `${process.env.REACT_APP_API_URL}/authgroup/master`;
    console.log(search);
    return new CustomStore({
      key: "id",
      load: () => sendRequest(apiUrl, "GET", search),
      insert: (values) => sendRequest(apiUrl, "POST", values),
      update: (key, values) => sendRequest(apiUrl, "PUT", { key, values }),
      remove: (key) => sendRequest(apiUrl, "DELETE", { key }),
    });
  }, [search]);

  return (
    <>
      <DataCount>{`총 ${totalCount}건`}</DataCount>
      <DataGrid
        id="authGroupMaster"
        className="datagrid-wrap"
        dataSource={dataSource}
        ref={dataGridRef}
        showRowLines={true}
        allowColumnResizing={true}
        hoverStateEnabled={true}
        showBorders={true}
        focusedRowEnabled={true}
        onFocusedRowChanged={onFocusedRowChanged}
        columnResizingMode="widget"
        onContentReady={(e) => setTotalCount(e.component.totalCount())}
      >
        <Editing mode="row" startEditAction="dblClick" />
        <ColumnChooser enabled={true} />
        <Column dataField="authGroupName" caption="그룹명" />
      </DataGrid>
    </>
  );
}
