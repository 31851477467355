import { alert, confirm } from "devextreme/ui/dialog";
import moment from "moment";
import { useEffect, useReducer, useRef } from "react";
import { useForm } from "react-hook-form";
import { sendRequest } from "../../../../_metronic/_helpers/DataSoureHelpers";
import {
  deleteEtcCost,
  deleteRemark,
  deleteTaxBill,
  fetchAccountHistory,
  getAccountLists,
  getDetailGridData,
  initLookupData,
  updateRemark,
} from "./AccountHistoryCrud";

const initialSearch = {
  baseMonth: moment().format("YYYY-MM"),
  bankNumber: "전체",
  Biz_No: "536-86-00366",
};

const initialState = {
  search: initialSearch,

  modalVisible: false,

  lookupData: {
    company: [],
    bankAccount: [],
  },

  dataSource: [],
  rowData: {},

  taxModal: false,
  etcCostModal: false,

  taxBillDataSource: [],
  etcCostDataSource: [],

  isLoading: false,
};

const accountHistoryReducer = (state, action) => {
  switch (action.type) {
    case "SET_DETAIL_DATAS":
      const { taxBill, etcCost } = action.payload;
      return {
        ...state,
        taxBillDataSource: taxBill,
        etcCostDataSource: etcCost,
      };
    case "SET_SEARCH":
      return {
        ...state,
        search: action.payload,
      };

    case "SET_MODAL_VISIBLE":
      return {
        ...state,
        modalVisible: action.payload,
      };

    case "SET_LOOKUP_DATA":
      const { company, bankAccount } = action.payload;
      return {
        ...state,
        lookupData: {
          company: company,
          bankAccount: [{ bankNumber: "전체" }, ...bankAccount],
        },
      };

    case "SET_DATA_SOURCE":
      return {
        ...state,
        dataSource: action.payload,
      };

    case "SET_ROW_DATA":
      return {
        ...state,
        rowData: action.payload,
      };

    case "SET_TAX_MODAL":
      return {
        ...state,
        taxModal: action.payload,
      };

    case "SET_ETC_COST_MODAL":
      return {
        ...state,
        etcCostModal: action.payload,
      };

    case "SET_IS_LOADING":
      return {
        ...state,
        isLoading: action.payload,
      };

    default:
      throw new Error("Unhandled action");
  }
};

const useAccountHistory = () => {
  const [state, dispatch] = useReducer(accountHistoryReducer, initialState);
  const { search, rowData, lookupData } = state;

  const dataGridRef = useRef();
  const taxBillGridRef = useRef();
  const etcCostGridRef = useRef();

  const searchForm = useForm({
    defaultValues: initialSearch,
  });

  const { watch: searchWatch, setValue: searchSetValue } = searchForm;

  const accountRemark = useForm({});

  const { reset: remarkReset } = accountRemark;

  // 컴포넌트가 마운트될 때 한 번만 실행
  useEffect(() => {
    _initialPage();
    // eslint-disable-next-line
  }, []);

  // search 값이 변경될 때마다 실행
  useEffect(() => {
    _getDataGrid(search);
  }, [search]);

  useEffect(() => {
    _getDetailGrid(rowData);
    // eslint-disable-next-line
  }, [rowData]);

  /** 초기 lookupData설정 하는 함수 */
  const _initialPage = async () => {
    const company = await initLookupData();
    const bankAccount = await _initBankAccount(initialSearch.Biz_No);

    dispatch({
      type: "SET_LOOKUP_DATA",
      payload: { company, bankAccount: bankAccount || [] },
    });
  };

  /** Biz_No를 요청보내면 계좌리스트를 return 받는 함수 */
  const _initBankAccount = async (Biz_No) => {
    try {
      const { data } = await getAccountLists(Biz_No);
      return data;
    } catch (error) {
      console.log(error);
    }
  };

  const _setCreateModalVisible = (boolean) => {
    dispatch({
      type: "SET_MODAL_VISIBLE",
      payload: boolean,
    });
  };

  const _isRowDataSelected = async () => {
    if (
      Object.keys(rowData).length === 0 ||
      Object.keys(rowData).length === 1
    ) {
      await alert("통장내역을 선택해주세요", "오류");
      return true;
    }
    return false;
  };

  const _getDataGrid = async (search) => {
    // apiUrl 변수에 API 주소를 할당
    const apiUrl = `${process.env.REACT_APP_API_URL}/account-list`;
    try {
      dispatch({
        type: "SET_IS_LOADING",
        payload: true,
      });
      // sendRequest 함수를 호출하여 GET 요청을 보내고 반환된 데이터를 변수 data에 할당
      const data = await sendRequest(apiUrl, "GET", search);

      // dispatch 함수를 호출하여 SET_DATA_SOURCE 액션을 실행하고 데이터를 전달
      dispatch({
        type: "SET_DATA_SOURCE",
        payload: data,
      });
    } catch (error) {
      console.log(error);
    } finally {
      dispatch({
        type: "SET_IS_LOADING",
        payload: false,
      });
    }
  };

  const _getDetailGrid = async (rowData) => {
    const keys = Object.keys(rowData);
    if (keys.length === 0) return;
    // 여기서 세금계산서, 기타비용, 통장비고내역 불러오기;
    const { data } = await getDetailGridData(rowData);
    const { taxBill, etcCost, accountRemark } = data;

    dispatch({
      type: "SET_DETAIL_DATAS",
      payload: { taxBill, etcCost },
    });

    remarkReset(accountRemark[0]);
  };

  // _refreshDataGrid 함수 선언
  const _refreshDataGrid = async () => {
    // 0.5초 후에 dataGridRef.current.instance.refresh() 함수를 호출하여 데이터 그리드를 새로고침
    setTimeout(() => {
      dataGridRef.current.instance.refresh();
    }, 500);
  };

  // _resetFocusedRowIndex 함수 선언
  const _resetFocusedRowIndex = () => {
    // dataGridRef.current.instance.option 함수를 호출하여 focusedRowIndex 값을 -1로 초기화
    dataGridRef.current.instance.option("focusedRowIndex", -1);
    dispatch({
      type: "SET_ROW_DATA",
      payload: { TransRefKey: "" },
    });
  };

  const pageFunctions = {
    onSubmit: (data) => {
      _resetFocusedRowIndex();
      dispatch({
        type: "SET_SEARCH",
        payload: data,
      });
    },

    // AccountModalButton 클릭 시 실행되는 함수
    onClickAccountModalButton: () => {
      // dataGridRef에서 선택된 행 데이터 가져오기
      const selectedRows = dataGridRef.current.instance.getSelectedRowsData();
      // 선택된 행이 없을 경우 알림창 띄우기
      if (selectedRows.length === 0) {
        alert("데이터를 선택해주세요", "확인");
        return;
      }
      // CreateModalVisible 상태를 true로 변경하여 모달창 띄우기
      _setCreateModalVisible(true);
    },

    setTaxModalVisible: async () => {
      _refreshDataGrid();
      await _getDetailGrid(rowData);
      // _resetFocusedRowIndex();
      dispatch({
        type: "SET_TAX_MODAL",
        payload: false,
      });
    },

    setEtcCostModalVisible: async () => {
      _refreshDataGrid();
      await _getDetailGrid(rowData);
      // _resetFocusedRowIndex();
      dispatch({
        type: "SET_ETC_COST_MODAL",
        payload: false,
      });
    },

    loadAccountHistory: async () => {
      // 통장내역 불러오기
      const { bankNumber } = searchWatch();

      if (bankNumber === "전체") {
        alert("계좌번호를 선택해주세요", "오류");
        return;
      }

      try {
        dispatch({
          type: "SET_IS_LOADING",
          payload: true,
        });
        const { data } = await fetchAccountHistory(searchWatch());

        const { ok } = data;

        if (ok) {
          alert("불러오기 완료", "확인");
        } else {
          alert("불러오기 실패", "오류");
        }

        _resetFocusedRowIndex();
      } catch (error) {
        console.log(error);
      } finally {
        dispatch({
          type: "SET_IS_LOADING",
          payload: false,
        });
      }
    },

    onValueChanged: async (e) => {
      try {
        const bankAccount = await _initBankAccount(e.value);

        searchSetValue("bankNumber", "전체");
        dispatch({
          type: "SET_LOOKUP_DATA",
          payload: { ...lookupData, bankAccount: bankAccount || [] },
        });
      } catch (error) {
        console.log(error);
      }
    },
  };

  const masterFunctions = {
    // 현재 포커스된 행이 변경되었을 때 실행되는 함수
    onFocusedRowChanged: (e) => {
      const { row } = e;
      if (!row) return;
      // 행 데이터를 액션으로 전달하여 상태를 업데이트함
      dispatch({ type: "SET_ROW_DATA", payload: row.data });
    },
  };

  const detailFunctions = {
    openTaxBill: async () => {
      if (await _isRowDataSelected()) return;

      dispatch({
        type: "SET_TAX_MODAL",
        payload: true,
      });
    },

    onClickDeleteTaxBillBtn: async () => {
      const instance = taxBillGridRef.current.instance;
      const rows = instance.getSelectedRowsData();
      if (rows.length === 0) {
        await alert("데이터를 선택해주세요", "오류");
        return;
      }
      if (!(await confirm("선택한 세금계산서를 삭제하시겠습니까?", "확인")))
        return;

      const keys = rows.map((cur) => cur.Oid);

      await deleteTaxBill(keys);

      await _getDetailGrid(rowData);
    },

    openEtcCost: async () => {
      if (await _isRowDataSelected()) return;

      dispatch({
        type: "SET_ETC_COST_MODAL",
        payload: true,
      });
    },

    onClickDeleteEtcCostBtn: async () => {
      const instance = etcCostGridRef.current.instance;
      const rows = instance.getSelectedRowsData();
      if (rows.length === 0) {
        await alert("데이터를 선택해주세요", "오류");
        return;
      }

      if (!(await confirm("선택한 기타비용을 삭제하시겠습니까?", "확인")))
        return;

      const keys = rows.map((cur) => cur.Oid);

      await deleteEtcCost(keys);

      await _getDetailGrid(rowData);
    },

    onSubmit: async (data) => {
      if (await _isRowDataSelected()) return;

      try {
        dispatch({
          type: "SET_IS_LOADING",
          payload: true,
        });
        await updateRemark({ rowData, data });

        _getDataGrid(search);
        await _getDetailGrid(rowData);
      } catch (error) {
        console.log(error);
      } finally {
        dispatch({
          type: "SET_IS_LOADING",
          payload: false,
        });
      }
    },

    onClickDeleteMemo: async () => {
      if (await _isRowDataSelected()) return;

      if (!(await confirm("통장비고내역을 삭제하시겠습니까?", "확인"))) return;

      try {
        dispatch({
          type: "SET_IS_LOADING",
          payload: true,
        });

        await deleteRemark(rowData);

        _getDataGrid(search);
        await _getDetailGrid(rowData);
      } catch (error) {
        console.log(error);
      } finally {
        dispatch({
          type: "SET_IS_LOADING",
          payload: false,
        });
      }
    },
  };

  const modalFunctions = {};

  return {
    state,

    dataGridRef,
    taxBillGridRef,
    etcCostGridRef,

    searchForm,
    accountRemark,

    pageFunctions,
    masterFunctions,
    detailFunctions,
    modalFunctions,
  };
};

export default useAccountHistory;
