import { yupResolver } from "@hookform/resolvers/yup";
import CustomStore from "devextreme/data/custom_store";
import React, { useMemo, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { shallowEqual, useSelector } from "react-redux";
import { sendRequest } from "../../../../_metronic/_helpers/DataSoureHelpers";
import ChangeAllModal from "../../../components/ChangeAllModal/ChangeAllModal";
import ExtraChargeModal from "../../../components/ExtraChargeModal/ExtraChargeModal";
import HeaderTitle from "../../../components/Layout/Header/HeaderTitle";
import { PanelGridWrapper } from "../../../components/Layout/Panel/PanelGrid/panelGrid.style";
import { PanelSubInputWrapper } from "../../../components/Layout/Panel/PanelInput/panelInput.style";
import {
  PanelBody,
  PanelHeader,
  PanelInit,
} from "../../../components/Layout/Panel/panel.style";
import NewCompanyModal from "../../Management/ComCompany/components/NewCompanyModal";
import NewForwarderModal from "../../Management/Forwarder/components/NewForwarderModal";
import NewTerminalModal from "../../Management/Terminal/components/NewTerminalModal";
import { getOrderDataByCode } from "./OrderCrud";
import { getInitialInputState, schema } from "./OrderInitialState";
import OrderGrid from "./components/OrderGrid";
import OrderHeader from "./components/OrderHeader";
import OrderInput from "./components/OrderInput";
import OrderInputDetail from "./components/OrderInputDetail";
import OrderSearch from "./components/OrderSearch";
import useExtraCharge from "./useExtraCharge";
import useOrderPageLookup from "./useOrderPageLookup";

const OrderPage = () => {
  // 로딩여부
  const [isLoading, setLoading] = useState(false);
  // 확정여부
  const [isConfirm, setIsConfirm] = useState(false);
  // 클릭한 row 키값
  const [selectedRowKey, setSelectedRowKey] = useState(null);
  // input펼치기 여부
  const [openInputBar, setOpenInputBar] = useState(false);
  // mainGrid 검색
  const [search, setSearch] = useState({});

  // 마감된 데이터 수정
  const [isOrderEditable, setIsOrderEditable] = useState(false);

  // modal
  // 업체등록 모달
  const [companyCreateModal, setCompanyCreateModal] = useState(false);
  // 선사등록 모달
  const [forwarderCreateModal, setForwarderCreateModal] = useState(false);
  // 터미널/창고/작업지 등록 모달
  const [terminalCreateModal, setTerminalCreateModal] = useState(false);
  // 일괄적용 모달
  const [changeAllModal, setChangeAllModal] = useState(false);

  // mainGrid
  const dataGridRef = useRef();
  // inputDetailGrid
  const detailGrid = useRef();

  const { user } = useSelector(
    (state) => ({ user: state.auth.user.user }),
    shallowEqual
  );

  const initialState = getInitialInputState(user);

  const inputForm = useForm({
    resolver: yupResolver(schema),
    defaultValues: initialState,
  });

  const { reset } = inputForm;

  // lookupData 커스텀 훅
  const {
    // search
    orderSearchLookupData,

    // input
    conType,
    orderInputLookupData,
    initOrderInputLookupData,

    // input detail
    orderInputDetailLookup,
    initOrderInputDetailLookupData,
  } = useOrderPageLookup();

  // 할증등록 관련 변수, 함수 커스텀 훅
  const extraChargeFunctions = useExtraCharge(detailGrid, isConfirm);
  const {
    state,
    extraChargeModal,
    extraChargeRef,
    etcChargeHabulRef,
    onExtraChargeSubmit,
    onClose,
  } = extraChargeFunctions;

  const initInputData = async (key) => {
    setIsOrderEditable(false);
    try {
      // db에서 데이터 불러오기
      setLoading(true);
      if (key) {
        const {
          data: { result },
        } = await getOrderDataByCode(key);

        const { data, finishInfoDetail } = result;

        // 여기서 클릭한 오더가 확정인지 아닌지 확인
        if (data?.confirm === true) setIsConfirm(true);
        else setIsConfirm(false);

        if (finishInfoDetail.completeNo) setIsOrderEditable(true);

        reset(data);
      } else {
        setIsConfirm(false);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const orderDetailDataSource = useMemo(() => {
    const apiUrl = `${process.env.REACT_APP_API_URL}/order/modal/detail`;
    return new CustomStore({
      key: "id",
      load: () =>
        sendRequest(apiUrl, "GET", {
          orderCode: selectedRowKey ? selectedRowKey : "999999999",
        }),
    });
  }, [selectedRowKey]);

  return (
    <>
      <PanelInit>
        <PanelHeader>
          <HeaderTitle />

          {openInputBar && (
            <OrderHeader
              dataGridRef={dataGridRef}
              detailGrid={detailGrid}
              inputForm={inputForm}
              isConfirm={isConfirm}
              selectedRowKey={selectedRowKey}
              setSelectedRowKey={setSelectedRowKey}
              initInputData={initInputData}
            />
          )}
        </PanelHeader>

        <PanelBody
          row
          left="30%"
          right="70%"
          column
          top="65%"
          bottom="35%"
          openInputBar={openInputBar}
        >
          <OrderInput
            selectedRowKey={selectedRowKey}
            openInputBar={openInputBar}
            isLoading={isLoading}
            dataGridRef={dataGridRef}
            detailGrid={detailGrid}
            inputForm={inputForm}
            conType={conType}
            lookupData={orderInputLookupData}
            initInputData={initInputData}
            setLoading={setLoading}
            isConfirm={isConfirm}
            setCompanyCreateModal={setCompanyCreateModal}
            setForwarderCreateModal={setForwarderCreateModal}
            setTerminalCreateModal={setTerminalCreateModal}
            isOrderEditable={isOrderEditable}
          />

          <PanelGridWrapper>
            {/* 페이지 메인그리드 검색 */}
            <OrderSearch
              setSearch={setSearch}
              lookupData={orderSearchLookupData}
              onClickToggle={() => setOpenInputBar(!openInputBar)}
            />

            {/* 페이지 메인그리드 */}
            <OrderGrid
              search={search}
              dataGridRef={dataGridRef}
              detailGrid={detailGrid}
              detailDataSource={orderDetailDataSource}
              setSelectedRowKey={setSelectedRowKey}
            />
          </PanelGridWrapper>

          <PanelSubInputWrapper fullsize openInputBar={openInputBar}>
            <OrderInputDetail
              dataSource={orderDetailDataSource}
              inputForm={inputForm}
              isConfirm={isConfirm}
              detailGrid={detailGrid}
              extraChargeFunctions={extraChargeFunctions}
              setTerminalCreateModal={setTerminalCreateModal}
              setChangeAllModal={setChangeAllModal}
              lookupData={orderInputDetailLookup}
            />
          </PanelSubInputWrapper>
        </PanelBody>
      </PanelInit>

      {/* modals */}
      {extraChargeModal && (
        <ExtraChargeModal
          onClose={onClose}
          visible={true}
          maskClosable={false}
          state={state}
          extraChargeRef={extraChargeRef}
          etcChargeHabulRef={etcChargeHabulRef}
          onExtraChargeSubmit={onExtraChargeSubmit}
        />
      )}
      {companyCreateModal && (
        <NewCompanyModal
          onClose={() => {
            setCompanyCreateModal(false);
            initOrderInputLookupData();
          }}
          closable={true}
          visible={true}
          maskClosable={false}
          // type={type}
        />
      )}
      {forwarderCreateModal && (
        <NewForwarderModal
          onClose={() => {
            setForwarderCreateModal(false);
            initOrderInputLookupData();
          }}
          closable={true}
          visible={true}
          maskClosable={false}
        />
      )}
      {terminalCreateModal && (
        <NewTerminalModal
          onClose={() => {
            setTerminalCreateModal(false);
            // 디테일 룩업도 refetch
            initOrderInputLookupData();
            initOrderInputDetailLookupData();
          }}
          closable={true}
          visible={true}
          maskClosable={false}
        />
      )}
      {changeAllModal && (
        <ChangeAllModal
          onClose={() => setChangeAllModal(false)}
          visible={true}
          maskClosable={false}
          detailGrid={detailGrid}
        />
      )}
    </>
  );
};

export default OrderPage;
