import axios from "axios";

const apiUrl = `${process.env.REACT_APP_API_URL}/billingplace`;

export function createBillingPlace(data) {
  return axios.post(apiUrl, data);
}

export function updateBillingPlace(key, data) {
  return axios.put(apiUrl, { key, data });
}

export function deleteRow(key) {
  return axios.delete(apiUrl + `/${key}`);
}
