import dayjs from "dayjs";
import * as yup from "yup";

export const getInitialInputState = (user) => {
  return {
    code: undefined,
    importExport: 1,
    receiveCo: user.companyCode,
    receiveDate: dayjs().format("YYYY-MM-DD"),
    dispatchTeam: user?.deptCode,
    billingPlace: null,
    orderCo: null,
    forwarder: null,
    shipName: undefined,
    motherShip: undefined,
    dem: null,
    manager: undefined,
    enterDay: null,
    sailDay: null,
    bkNo: undefined,
    doNo: undefined,
    blNo: undefined,
    blType: 1,
    loadPlace: null,
    unloadPlace: null,
    cargoOwner: null,
    realCargoOwner: undefined,
    otherDeliver: null,
    fridge: false,
    danger: false,
    tank: false,
    direct: false,
    toxic: false,
    keep: false,
    remark: undefined,
    completeNo: undefined,
  };
};

export const schema = yup
  .object({
    importExport: yup.string().nullable(),
    receiveCo: yup
      .string()
      .nullable()
      .required("접수법인을 입력하세요"),
    receiveDate: yup
      .string()
      .nullable()
      .required("접수일자를 입력하세요"),
    dispatchTeam: yup.string().nullable(),
    billingPlace: yup
      .string()
      .required("청구처를 입력하세요")
      .nullable(),
    orderCo: yup.string().nullable(),
    forwarder: yup.string().nullable(),
    shipName: yup.string().max(50, "최대 50자 까지 입력 가능"),
    motherShip: yup.string().max(50, "최대 50자 까지 입력 가능"),
    dem: yup
      .string()
      .nullable()
      .when("importExport", { is: 1, then: yup.string() }),
    manager: yup.string().nullable(),
    enterDay: yup.string().nullable(),
    sailDay: yup.string().nullable(),
    blNo: yup
      .string()
      .nullable()
      .when("importExport", {
        is: 1,
        then: yup.string().required("B/L 번호를 입력하세요"),
      })
      .max(50, "최대 50자 까지 입력 가능"),
    blYear: yup
      .string()
      .nullable()
      .when("importExport", { is: 1, then: yup.string() }),
    blType: yup.string().nullable(),
    doNo: yup
      .string()
      .nullable()
      .when("importExport", { is: 1, then: yup.string() })
      .max(50, "최대 50자 까지 입력 가능"),
    bkNo: yup
      .string()
      .nullable()
      .when("importExport", { is: 2, then: yup.string() }),
    loadPlace: yup
      .string()
      .nullable()
      .required("상차지를 선택해주세요"),
    unloadPlace: yup.string().nullable(),
    cargoOwner: yup
      .string()
      // .required("화주를 입력하세요")
      .nullable(),
    realCargoOwner: yup.string().nullable(),
    otherDeliver: yup.string().nullable(),
    fridge: yup.bool(),
    danger: yup.bool(),
    tank: yup.bool(),
    direct: yup.bool(),
    toxic: yup.bool(),
    keep: yup.bool(),
    remark: yup
      .string()
      .nullable()
      .max(200, "200자 이내로 입력"),
    confirm: yup.boolean(),
  })
  .required();
