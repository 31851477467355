import axios from "axios";
const apiUrl = `${process.env.REACT_APP_API_URL}/comcode/lookup`;
const apiUrlDelete = `${process.env.REACT_APP_API_URL}/employee/master`;

export function loadLookup(params) {
  return axios.get(apiUrl + `/${params}`);
}

export function deleteRow(key) {
  return axios.delete(apiUrlDelete + `/${key}`);
}
