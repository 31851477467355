import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import InputDate from "../../../components/InputForm/InputDate";

import {
  Column,
  ColumnChooser,
  DataGrid,
  FilterRow,
  Format,
  HeaderFilter,
  Scrolling,
  StateStoring,
} from "devextreme-react/data-grid";
import CustomStore from "devextreme/data/custom_store";
import { sendRequest } from "../../../../_metronic/_helpers/DataSoureHelpers";
import InputText from "../../../components/InputForm/InputText";
import StateStoringSaveAndDeleteButton from "../../../components/StateStoring";
import InputSelect from "../../../components/InputForm/InputSelect";
import { getLookup } from "../ShuttleDispatch/ShuttleDispatchCrud";
import { shallowEqual, useSelector } from "react-redux";
import {
  DataCount,
  PanelGridBtnBar,
  PanelGridInit,
  PanelGridSearchBar,
  PanelGridTopBar,
  PanelGridWrapper,
  SearchItems,
} from "../../../components/Layout/Panel/PanelGrid/panelGrid.style";
import CustomButton from "../../../components/FunctionButton/CustomButton";
import { ReactComponent as IconEdit } from "../../../../style/icons/edit-note.svg";

const ImportReturnMaster = ({
  onFocusedRowChanged,
  dataGridRef,
  openInputBar,
  getOpenInputBar,
  lookupDataMaster,
}) => {
  /* #region useState, useRef -----------------------------------------------------------------------*/
  const [totalCount, setTotalCount] = useState(0);
  const [search, setSearch] = useState({});
  const [lookupData, setLookupData] = useState({
    company: [],
    disaptchName: [],
  });

  const storageKey = "importReturnMaster";
  /* #endregion */

  const { user } = useSelector(
    (state) => ({ user: state.auth.user.user }),
    shallowEqual
  );

  /* #region initialState, react-hook-form, yup ---------------------------------------------------- */
  const initialState = {
    receiveCo: user.companyCode,
    firstDay: moment().format("YYYY-MM-DD"),
    lastDay: moment().format("YYYY-MM-DD"),
    containerNo: "",
    blNo: "",
    doNo: "",
    company: user.companyCode,
    dispatchTeam: user?.deptCode || "",
    searchType: 2,
  };

  const {
    reset,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();
  /* #endregion */

  /* #region useEffect ----------------------------------------------------------------------------- */
  useEffect(() => {
    reset(initialState);
    setSearch(initialState);
    getLookupData();
    // eslint-disable-next-line
  }, []);
  /* #endregion */

  /* #region 기타함수 ----------------------------------------------------------------------------- */

  const dataSource = useMemo(() => {
    const apiUrl = `${process.env.REACT_APP_API_URL}/shuttlesimple/imreturn`;

    return new CustomStore({
      key: "code",
      load: () => sendRequest(apiUrl, "GET", search),
    });
  }, [search]);

  async function getLookupData() {
    try {
      const dispatchTeamData = await getLookup("department/isDispatch");
      const companyData = await getLookup("company");

      setLookupData({
        company: companyData.data,
        disaptchName: [
          {
            code: 0,
            deptName: "전체",
          },
          ...dispatchTeamData.data,
        ],
      });
    } catch (err) {
      console.log(err);
    }
  }

  const onSubmit = (data) => {
    for (const obj in data) {
      if (data[obj] === "") {
        data[obj] = null;
      }
    }
    setSearch(data);
  };

  const onCellPrepared = useCallback((e) => {
    if (e.rowType !== "data") return;
    if (e.column.caption !== "접수번호") return;
    if (e.data.orderStatus !== 80) return;

    e.cellElement.style.background = "#ADE792";
  }, []);

  const onRowPrepared = useCallback(
    (e) => {
      if (e.rowType !== "data") return;

      const { data } = e;
      const color = data.color;
      if (!color) return;

      const filteredColorArr = lookupDataMaster.color.filter(
        (cur) => cur.subCode === color
      );
      e.rowElement.style.background = filteredColorArr[0]?.extra1;
    },
    [lookupDataMaster.color]
  );

  const onToggleInputBar = () => {
    getOpenInputBar(!openInputBar);
  };

  /* #endregion */

  return (
    <>
      <PanelGridWrapper>
        <PanelGridTopBar>
          <PanelGridBtnBar>
            <CustomButton
              type="button"
              height={36}
              width="full"
              layout="icon"
              color="transp"
              onClick={onToggleInputBar}
            >
              <IconEdit />
            </CustomButton>
          </PanelGridBtnBar>
          <PanelGridSearchBar onSubmit={handleSubmit(onSubmit)}>
            <SearchItems>
              <label>접수법인</label>
              <InputSelect
                className="label-select"
                control={control}
                errors={errors}
                name="receiveCo"
                displayExpr="companyName"
                valueExpr="id"
                dataSource={lookupData.company}
                flex={true}
              />
            </SearchItems>
            <SearchItems>
              <label>배차팀</label>
              <InputSelect
                control={control}
                errors={errors}
                name="dispatchTeam"
                dataSource={lookupData.disaptchName}
                valueExpr="code"
                displayExpr="deptName"
                flex={true}
              />
            </SearchItems>
            <SearchItems>
              <label>일정검색</label>
              <InputSelect
                control={control}
                errors={errors}
                name="searchType"
                displayExpr="name"
                valueExpr="id"
                dataSource={[
                  { id: 1, name: "접수일자" },
                  { id: 2, name: "운송일자" },
                ]}
                flex={true}
              />
              <InputDate
                control={control}
                errors={errors}
                name="firstDay"
                flex={true}
              />
              <div className="to-mark">~</div>
              <InputDate
                control={control}
                errors={errors}
                name="lastDay"
                flex={true}
              />
            </SearchItems>
            <SearchItems>
              <label>컨테이너No</label>
              <InputText
                control={control}
                errors={errors}
                name="containerNo"
                flex={true}
              />
            </SearchItems>
            <SearchItems>
              <label>BL</label>
              <InputText
                control={control}
                errors={errors}
                name="blNo"
                flex={true}
              />
            </SearchItems>
            <SearchItems>
              <label>Do</label>
              <InputText
                control={control}
                errors={errors}
                name="doNo"
                flex={true}
              />
            </SearchItems>
            <CustomButton
              type="submit"
              height={24}
              layout="solid"
              color="search"
              label="검색"
            />
          </PanelGridSearchBar>
        </PanelGridTopBar>
        <PanelGridInit>
          <StateStoringSaveAndDeleteButton
            dataGridRef={dataGridRef}
            storageKey={storageKey}
          />
          <DataCount>{`총 ${totalCount}건`}</DataCount>
          <DataGrid
            className="datagrid-wrap"
            id="shuttleSimpleMaster"
            ref={dataGridRef}
            dataSource={dataSource}
            keyExpr="code"
            showRowLines={true}
            showBorders={true}
            hoverStateEnabled={true}
            allowColumnResizing={true}
            allowColumnReordering={true}
            columnResizingMode="widget"
            focusedRowEnabled={true}
            onRowPrepared={onRowPrepared}
            onFocusedRowChanged={onFocusedRowChanged}
            onCellPrepared={onCellPrepared}
            onContentReady={(e) => setTotalCount(e.component.totalCount())}
          >
            <FilterRow visible={true} />
            <HeaderFilter visible={true} />
            <ColumnChooser enabled={true} />
            <StateStoring
              enabled={true}
              type="custom"
              customLoad={useCallback(() => {
                return JSON.parse(localStorage.getItem(storageKey));
              }, [])}
            />
            <Scrolling
              columnRenderingMode="virtual"
              mode="virtual"
              showScrollbar="always"
              useNative="false"
            />

            <Column dataField="code" caption="접수번호" width="115" />
            <Column dataField="deliverDate" caption="운송일자" width="85" />
            <Column dataField="containerNo" caption="컨테이너NO" width="115" />
            <Column dataField="forwarder" caption="선사" width="75" />
            <Column
              dataField="containerType"
              caption="컨규격"
              alignment="center"
              width="95"
            />
            <Column dataField="receiveCoName" caption="접수법인" width="85" />
            <Column dataField="loadPlaceName" caption="상차지" width="75" />
            <Column dataField="carName_01" caption="배차차량" width="75" />
            <Column dataField="yongchasaName_01" caption="용차사" width="75" />
            <Column
              dataField="yongchaCarNo_01"
              caption="용차사차량"
              width="115"
            />
            <Column dataField="workPlaceName" caption="작업지" width="75" />
            <Column dataField="carName_02" caption="배차차량" width="75" />
            <Column dataField="yongchasaName_02" caption="용차사" width="75" />
            <Column
              dataField="yongchaCarNo_02"
              caption="용차사차량"
              width="115"
            />
            <Column dataField="unloadPlaceName" caption="하차지" width="75" />
            <Column dataField="manager" caption="담당자" width="75" />
            <Column dataField="cargoOwnerName" caption="화주" width="75" />
            <Column dataField="realCargoOwner" caption="실화주" width="70" />
            <Column dataField="returnDate" caption="반납일자" width="75" />
            <Column
              dataField="freight"
              caption="청구운임"
              dataType="number"
              width="75"
            >
              <Format type="fixedPoint" />
            </Column>
            <Column
              dataField="habul_01"
              caption="상차지 하불금액"
              dataType="number"
              width="115"
            >
              <Format type="fixedPoint" />
            </Column>
            {/* <Column
            dataField="habul_02"
            caption="하차지 하불금액"
            dataType="number"
          >
            <Format type="fixedPoint" />
          </Column> */}
            {/* <Column dataField="blNo" caption="BLNO" /> */}
            {/* <Column dataField="bkNo" caption="BKNO" /> */}
            {/* <Column dataField="doNo" caption="DONO" /> */}
            <Column dataField="extraInfo" caption="특이사항" width="75" />
            <Column dataField="remark" caption="비고" width="75" />
            {/* <Column dataField="" caption="총하불금액" /> */}
          </DataGrid>
        </PanelGridInit>
      </PanelGridWrapper>
    </>
  );
};

export default ImportReturnMaster;
