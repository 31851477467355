import React, { useMemo, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { DataGrid } from "devextreme-react";
import {
  Column,
  Editing,
  Scrolling,
  Lookup,
  KeyboardNavigation,
} from "devextreme-react/data-grid";
import { confirm } from "devextreme/ui/dialog";
import { Lookup as Lup } from "devextreme-react/lookup";
import CustomStore from "devextreme/data/custom_store";
import { sendRequest } from "../../../../_metronic/_helpers/DataSoureHelpers";
import * as requestServer from "./EmployeeCrud";
import FunctionButton from "../../../components/FunctionButton/FunctionButton";

export default function EmployeePage() {
  const [rowData, setRowData] = useState();
  const dataGridRef = useRef();
  const [dataSourceDept, setDataSourceDept] = useState();
  const [dataSourceDuty, setDataSourceDuty] = useState();

  const { register, handleSubmit, setValue } = useForm();

  const dataSource = useMemo(() => {
    const apiUrl = `${process.env.REACT_APP_API_URL}/employee/master`;

    return new CustomStore({
      key: "id",
      load: () => sendRequest(apiUrl, "GET"),
      insert: (values) => sendRequest(apiUrl, "POST", values),
      update: (key, values) => sendRequest(apiUrl, "PUT", { key, values }),
    });
  }, []);

  useEffect(() => {
    requestServer
      .loadLookup(10100)
      .then(({ data }) => {
        setDataSourceDept(data);
      })
      .catch((err) => {
        console.log(err);
      });

    requestServer
      .loadLookup(11600)
      .then(({ data }) => {
        setDataSourceDuty(data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const openPost = (rowData) => {
    const column = rowData.columnIndex;
    const row = rowData.rowIndex;

    window.daum.postcode.load(() => {
      const postcode = new window.daum.Postcode({
        oncomplete: function(data) {
          let addr = "";
          let extraAddr = "";

          if (data.userSelectedType === "R") {
            addr = data.address;
          } else {
            addr = data.jibunAddress;
          }

          if (data.userSelectedType === "R") {
            if (data.bname !== "" && /[동|로|가]$/g.test(data.bname)) {
              extraAddr += data.bname;
            }
            if (data.buildingName !== "" && data.apartment === "Y") {
              extraAddr +=
                extraAddr !== "" ? ", " + data.buildingName : data.buildingName;
            }
            if (extraAddr !== "") {
              extraAddr = " (" + extraAddr + ")";
            }
            addr += extraAddr;
          }

          dataGridRef.current.instance.cellValue(row, column, data.zonecode);
          dataGridRef.current.instance.cellValue(row, column + 1, addr);
        },
      });
      postcode.open();
    });
  };

  const onSubmit = (data) => {
    const search = [];
    let { employeeName, deptCode } = data;

    if (employeeName) search.push(["employeeName", "contains", employeeName]);
    if (deptCode) {
      if (search.length !== 0 && search[search.length - 1] !== "and")
        search.push("and");
      search.push(["deptCode", "contains", deptCode]);
    }

    if (search[0]) {
      dataGridRef.current.instance.filter(search);
    } else {
      dataGridRef.current.instance.clearFilter();
    }
  };

  const zipcodeCellRender = (e) => {
    return (
      <div className="datagrid-zip">
        <span>{e.data.zipCode}</span>
        <button className="btn datagrid-btn" onClick={() => openPost(e)}>
          zip
        </button>
      </div>
    );
  };

  //

  const onClickRead = () => {
    if (!dataGridRef.current) return;

    dataGridRef.current.instance.cancelEditData();
    dataGridRef.current.instance.clearFilter();
    dataGridRef.current.instance.refresh();
  };

  const onClickAdd = () => {
    if (!dataGridRef.current) return;

    setRowData(null);
    dataGridRef.current.instance.option("focusedRowIndex", -1);
    dataGridRef.current.instance.addRow();
  };

  const onClickSave = () => {
    if (!dataGridRef.current) return;

    dataGridRef.current.instance.saveEditData();
  };

  const onClickDelete = () => {
    if (!dataGridRef.current || !rowData) return;
    let response = confirm("<i>삭제하시겠습니까?</i>", "삭제 메세지");

    response.then((res) => {
      if (!res) return;
      requestServer
        .deleteRow(rowData.key)
        .then(({ data }) => {
          dataGridRef.current.instance.refresh();
        })
        .catch((err) => {
          console.log(err);
        });
    });
  };

  const onFocusedRowChanged = (e) => {
    const data = e.row;
    if (!data) return;
    if (!rowData || rowData.rowIndex !== data.rowIndex) setRowData(data);
  };

  const onToolbarPreparing = (e) => {
    var toolbarItems = e.toolbarOptions.items;

    toolbarItems.forEach((cur) => {
      if (cur.name === "saveButton" || cur.name === "revertButton") {
        cur.visible = false;
      }
    });
  };

  const headerCellRender = (data) => {
    return <p style={{ color: "red", margin: "0" }}>{data.column.caption}</p>;
  };

  return (
    <>
      <FunctionButton
        onClickRead={onClickRead}
        onClickAdd={onClickAdd}
        onClickSave={onClickSave}
        onClickDelete={onClickDelete}
      />

      <div className="d-flex flex-column system-page employee-page">
        <div className="form-container">
          <form onSubmit={handleSubmit(onSubmit)}>
            <span className="search-group">
              <label>부서</label>
              <Lup
                id="lookup"
                dataSource={dataSourceDept}
                valueExpr="subCode"
                displayExpr="subName"
                width="200px"
                style={{ display: "inline-block" }}
                onValueChange={(e) => setValue("deptCode", e)}
                closeOnOutsideClick={true}
              />
            </span>
            <span className="search-group">
              <label>사원명</label>
              <input type="text" {...register("employeeName")} />
            </span>
            <button type="submit" className="btn btn-search">
              검색
            </button>
          </form>
        </div>
        <div className="d-flex flex-column dataGrid">
          <DataGrid
            ref={dataGridRef}
            id="employee"
            dataSource={dataSource}
            allowColumnResizing={true}
            showRowLines={true}
            hoverStateEnabled={true}
            showBorders={true}
            columnAutoWidth={true}
            focusedRowEnabled={true}
            onFocusedRowChanged={onFocusedRowChanged}
            onToolbarPreparing={onToolbarPreparing}
            height="60vh"
          >
            <Editing
              mode="batch"
              startEditAction="dblClick"
              allowUpdating={true}
            />
            <Scrolling mode="virtual" />
            <KeyboardNavigation
              editOnKeyPress={true}
              enterKeyAction="moveFocus"
              enterKeyDirection="row"
            />
            <Column
              width={70}
              caption="No"
              cellRender={(e) => e.row.loadIndex + 1}
            />
            <Column
              dataField="id"
              caption="사원번호"
              dataType="number"
              allowEditing={false}
              headerCellRender={headerCellRender}
            />
            <Column
              dataField="employeeName"
              caption="사원명"
              headerCellRender={headerCellRender}
            />
            <Column
              dataField="deptCode"
              caption="부서"
              headerCellRender={headerCellRender}
            >
              <Lookup
                dataSource={dataSourceDept}
                valueExpr="subCode"
                displayExpr="subName"
              />
            </Column>
            <Column
              dataField="dutyCode"
              caption="직책"
              headerCellRender={headerCellRender}
            >
              <Lookup
                dataSource={dataSourceDuty}
                valueExpr="subCode"
                displayExpr="subName"
              />
            </Column>
            <Column
              dataField="zipCode"
              caption="우편번호"
              cellRender={zipcodeCellRender}
            />
            <Column dataField="address" caption="주소" allowEditing={true} />
            <Column dataField="address1" caption="주소상세" />
            <Column dataField="tel" caption="전화번호" />
            <Column dataField="moblieNo" caption="휴대전화번호" />
            <Column dataField="enterDate" caption="입사일자" dataType="date" />
            <Column dataField="retireDate" caption="퇴사일자" dataType="date" />
            <Column dataField="remark" caption="비고" />
          </DataGrid>
        </div>
      </div>
    </>
  );
}
