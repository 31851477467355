import React from "react";
import {
  InputFullRow,
  PanelInput,
  PanelInputTitle,
  PanelInputWrapper,
} from "../../../../components/Layout/Panel/PanelInput/panelInput.style";
import {
  InputDate,
  InputNumber,
  InputSelect,
  InputText,
  InputTextArea,
} from "../../../../components/InputForm";
import { ButtonGroup } from "../../../../components/FunctionButton/CustomButton/customButton.style";
import CustomButton from "../../../../components/FunctionButton/CustomButton/CustomButton";

const EtcCostInput = ({ inputProps }) => {
  const {
    lookupData,
    isLoading,
    inputDisable,
    inputForm,
    onSubmit,
  } = inputProps;

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = inputForm;
  return (
    <PanelInputWrapper onSubmit={handleSubmit(onSubmit)} openInputBar={true}>
      <PanelInputTitle>기타비용 등록</PanelInputTitle>
      <PanelInput grid={2}>
        <InputSelect
          className="label-select"
          control={control}
          errors={errors}
          label="업체"
          name="companyOid"
          displayExpr="companyName"
          valueExpr="id"
          dataSource={lookupData.inputCompany}
        />
        <div />
        <InputDate
          control={control}
          errors={errors}
          name="regDate"
          label="등록일"
          disable={inputDisable}
        />
        <InputText
          control={control}
          errors={errors}
          name="companyName"
          label="업체명"
        />
        <InputNumber
          control={control}
          errors={errors}
          name="sales"
          label="매출"
          maxLength={18}
        />
        <InputNumber
          control={control}
          errors={errors}
          name="purchase"
          label="매입"
          maxLength={18}
        />
        <InputFullRow grid={2}>
          <InputTextArea
            control={control}
            errors={errors}
            name="remark01"
            label="비고1"
          />
        </InputFullRow>
        <InputFullRow grid={2}>
          <InputTextArea
            control={control}
            errors={errors}
            name="remark02"
            label="비고2"
          />
        </InputFullRow>
      </PanelInput>

      <ButtonGroup marginTop={20}>
        <CustomButton
          type="submit"
          width="100%"
          height={36}
          layout="solid"
          color="common"
          label="저장"
          className={`${isLoading && "spinner spinner-white spinner-right"}`}
          disabled={isLoading}
        />
      </ButtonGroup>
    </PanelInputWrapper>
  );
};

export default EtcCostInput;
