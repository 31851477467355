import axios from "axios";

const apiUrl = `${process.env.REACT_APP_API_URL}/order`;
const apiDispatch = `${process.env.REACT_APP_API_URL}/yongchadispatch`;
const apiUrlLookup = `${process.env.REACT_APP_API_URL}`;

export function getUnipassInfo(data) {
  return axios.post(`${apiUrl}/unipass`, data);
}

export function getLookup(param) {
  return axios.get(apiUrlLookup + `/${param}`);
}

export async function initLookupData() {
  // const [] = await Promise.all([]);
  return {};
}

export function getOrderDataByCode(code) {
  return axios.get(apiDispatch + `/${code}`);
}

export function createOrder(data) {
  return axios.post(apiDispatch, data);
}

export function updateOrder(data) {
  return axios.put(apiDispatch, data);
}

export function cancelOrder(data) {
  return axios.put(apiDispatch + `/cancel`, { key: data });
}

export function deleteOrder(data) {
  return axios.delete(apiDispatch, { params: { key: data } });
}

export function onClickConfirmCreate(data) {
  return axios.post(apiDispatch + `/confirmcreate`, data);
}

export function onClickConfirmUpdate(data) {
  return axios.put(apiDispatch + `/confirmupdate`, data);
}

export function getDistancePrice(data) {
  return axios.post(apiUrl + `/distanceprice`, data);
}

export function updateExtraCharge(data) {
  return axios.post(apiDispatch + `/extraCharge`, data);
}
