import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import {
  InputDate,
  InputNumber,
  InputSelect,
  InputTextArea,
} from "../../../../components/InputForm";
import ModalContainer from "../../../../components/Modal/ModalContainer";
import ModalHeader from "../../../../components/Modal/ModalHeader";
import {
  getLookup,
  getOrderDetailData,
  updateClosingDetailData,
} from "../ClosingCrud";
import { alert } from "devextreme/ui/dialog";

const OrderDetailModal = ({
  onClose,
  maskClosable,
  closable,
  visible,
  rowData,
  selectedKey,
  closingRightRef,
}) => {
  const [lookupData, setLookupData] = useState({
    billing: [],
    conType: [],
  });
  // 마감된 데이터 수정
  const [isOrderEditable, setIsOrderEditable] = useState(false);

  const schema = yup.object({
    remark: yup.string().nullable(),
    containerType: yup
      .string()
      .nullable()
      .required("컨테이너 규격을 선택하세요"),
    freight: yup
      .string()
      .nullable()
      .required("금액을 입력하세요"),
    deliverDate: yup.string().nullable(),
  });

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    getOrderData(rowData);
    // eslint-disable-next-line
  }, [rowData]);

  useEffect(() => {
    getLookupData();
  }, []);

  const onSubmit = async (data) => {
    try {
      if (rowData.closeStatus === "발행") {
        await alert("세금계산서가 발행된 데이터입니다.", "오류");
        return;
      }

      await updateClosingDetailData(data);

      closingRightRef.current.instance.refresh();
      onClose();
    } catch (error) {
      console.log(error);
      alert("ERROR-01", "오류");
    }
  };

  const getOrderData = async (rowData) => {
    const { id: key, division, extraDivision } = rowData;
    if (division !== "청구처" || extraDivision !== "detail") {
      await alert("청구처데이터 중 오더만 수정 가능합니다.", "오류");
      onClose();
      return;
    }
    const { data } = await getOrderDetailData(key, division, extraDivision);
    const orderData = data[0];

    if (rowData.completeStatus === "마감") {
      setIsOrderEditable(true);
    }

    reset({
      orderCode: orderData.orderCode,
      id: key,
      // billingPlace: master.billingPlace,
      remark: orderData.remark,
      containerType: orderData.containerType,
      freight: orderData.freight,
      deliverDate: orderData.deliverDate,
    });
  };

  async function getLookupData() {
    // 업체,
    try {
      const [billingplaceData, containerTypeData] = await Promise.all([
        getLookup("companyManage/lookup/isBilling"),
        getLookup("comcode/detail/10400"),
      ]);

      setLookupData({
        billing: billingplaceData.data,
        conType: containerTypeData.data,
      });
    } catch (err) {
      console.log(err);
    }
  }

  return (
    <>
      <ModalContainer
        onClose={onClose}
        maskClosable={maskClosable}
        visible={visible}
        size="sm"
      >
        <form className="modal-form" onSubmit={handleSubmit(onSubmit)}>
          <ModalHeader title="오더수정" onClose={onClose} />
          <div className="modal-form-body">
            <div className="dx-field">
              <InputTextArea
                control={control}
                errors={errors}
                name="remark"
                label="비고"
                flex={true}
              />
            </div>
            <div className="dx-field">
              <InputSelect
                searchEnabled={true}
                control={control}
                errors={errors}
                name="containerType"
                label="컨/규격"
                flex={true}
                dataSource={lookupData.conType}
                displayExpr="subName"
                valueExpr="subCode"
              />
            </div>
            <div className="dx-field">
              <InputNumber
                control={control}
                errors={errors}
                name="freight"
                label="청구금액"
                disabled={isOrderEditable}
                flex={true}
              />
            </div>
            <div className="dx-field">
              <InputDate
                control={control}
                errors={errors}
                name="deliverDate"
                label="운송일자"
                flex={true}
              />
            </div>

            {/* 운송일자 */}
          </div>
          <div className="modal-form-footer">
            <button type="submit" className="btn btn-modal btn-save">
              저장
            </button>
          </div>
        </form>
      </ModalContainer>
    </>
  );
};

export default OrderDetailModal;
