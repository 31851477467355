import styled from "styled-components";

export const InputWrapper = styled.div`
  display: grid;
  width: ${(props) =>
    props.textArea
      ? "100%"
      : props.addressInput
      ? "100%"
      : props.comboSelectBox
      ? "200px"
      : "auto"};
  grid-template-columns: ${(props) =>
    props.labelWidth
      ? props.labelWidth + " calc(100% - 5px - " + props.labelWidth + ")"
      : props.label
      ? "65px calc(100% - 70px)"
      : props.checkbox
      ? "auto"
      : props.radio
      ? "auto"
      : props.address
      ? "100%"
      : "100px"};
  grid-gap: 5px;

  label {
    display: flex;
    font-size: 1.1rem;
    align-items: center;
    word-break: keep-all;

    &.required {
      &::after {
        content: " *";
        font-size: 1.1rem;
        line-height: 0px;
        vertical-align: middle;
        color: ${(props) => props.theme.colors.warn};
      }
    }
  }

  .dx-texteditor-input {
    font-size: 1.1rem !important;
    min-height: 20px !important;
  }

  .dx-placeholder {
    font-size: 1.1rem !important;
  }

  // CHECKBOX 텍스트 표기위치 조정
  .dx-checkbox-text {
    margin-left: unset;
    letter-spacing: -1px;
  }

  input,
  textarea {
    padding: 5px;
  }
`;

export const InputInit = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  flex-direction: column;
  gap: 2px;

  div {
    border-radius: 3px !important;
  }

  & > input {
    width: unset;
  }

  .dx-state-disabled {
    opacity: 1 !important;

    input {
      font-weight: 600;
      background: ${(props) => props.theme.colors.inputDisabledBg};
    }
  }
`;

export const InputAddressWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: ${(props) => props.flexDirection};
  gap: 2px;

  & > div {
    width: 100%;
  }
`;

export const InputAddressRow = styled.div`
  display: flex;
  max-width: ${(props) => (props.flexDirection === "row" ? "193px" : "100%")};
  gap: 2px;
`;

export const InputAddBtnWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 26px;
  grid-gap: 2px;
`;

export const InputErrMsg = styled.div`
  font-size: 0.9rem;
  color: ${(props) => props.theme.colors.warn};
`;
