import { useEffect } from "react";
import { useState } from "react";
import { useCallback } from "react";
import { getLookup } from "../pages/Order/OrderPage/OrderCrud";

export const useOrderHooks = () => {
  const [color, setColor] = useState([]);

  useEffect(() => {
    initLookup();
  }, []);

  const initLookup = async () => {
    try {
      const colorData = await getLookup("comcode/detail/10900");

      setColor(colorData.data);
    } catch (error) {
      console.log(error);
    }
  };

  const onCellPrepared = useCallback((e) => {
    if (e.rowType !== "data") return;
    if (e.column.caption !== "상차지" && e.column.caption !== "하차지") return;

    if (e.column.caption === "상차지") {
      e.cellElement.style.background = e.data.loadPlaceColor || "transparant";
    } else if (e.column.caption === "하차지") {
      e.cellElement.style.background = e.data.unloadPlaceColor || "transparant";
    }
  }, []);

  const onRowPrepared = useCallback(
    (e) => {
      if (e.rowType !== "data") return;

      const { data } = e;
      const colors = data.color;
      if (!colors) return;

      const filteredColorArr = color.filter((cur) => cur.subCode === colors);
      e.rowElement.style.background = filteredColorArr[0]?.extra1;
    },
    [color]
  );

  return { onCellPrepared, onRowPrepared };
};
