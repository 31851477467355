import axios from "axios";

const apiUrl = `${process.env.REACT_APP_API_URL}/lclorder`;
const apiUrlLookup = `${process.env.REACT_APP_API_URL}`;

export function createOrder(data) {
  return axios.post(apiUrl, data);
}

export function updateOrder(data) {
  return axios.put(apiUrl, data);
}

export function onClickConfirmCreate(data) {
  return axios.post(apiUrl + `/confirmcreate`, data);
}

export function onClickConfirmUpdate(data) {
  return axios.put(apiUrl + `/confirmupdate`, data);
}

export function cancelOrder(data) {
  return axios.put(apiUrl + `/cancel`, { key: data });
}

export function deleteOrder(data) {
  return axios.delete(apiUrl, { params: { key: data } });
}

export function getUnipassInfoApi(data) {
  return axios.post(`${apiUrlLookup}/order/unipass`, data);
}

export function getLookup(param) {
  return axios.get(apiUrlLookup + `/${param}`);
}

export function getOrderDataByCode(code) {
  return axios.get(apiUrl + `/master/${code}`);
}

export async function initLookupFetch() {
  const [
    billingplaceData,
    cargoownerData,
    ordercoData,
    forwarderData,
    terminalData,
    companyData,
    dispatchData,
    departmentData,
    conTypeData,
    colorData,
    workPlaceData,
    waypointData,
    divisionData,
    habulDivisionData,
    billingPlaceData,
  ] = await Promise.all([
    getLookup("companyManage/lookup/isBilling"),
    getLookup("companyManage/lookup/isCargoOwner"),
    getLookup("companyManage/lookup/isOrderCo"),
    getLookup("forwarder/lookup"),
    getLookup("terminal/terminal"),
    getLookup("company"),
    getLookup("department/isDispatch"),
    getLookup("department/lookup"),
    getLookup("comcode/detail/10400"),
    getLookup("comcode/detail/10900"),
    getLookup("terminal/workplace"),
    getLookup("terminal/waypoint"),
    getLookup("comcode/detail/10300"),
    getLookup("comcode/detail/10700"),
    getLookup("companyManage/lookup/isBilling"),
  ]);

  return {
    billing: billingplaceData.data,
    cargoOwner: cargoownerData.data,
    orderCo: ordercoData.data,
    forwarder: forwarderData.data,
    terminal: terminalData.data,
    company: companyData.data,
    dispatch: [...dispatchData.data],
    department: departmentData.data,
    conType: conTypeData.data,
    color: [{ subCode: null, subName: "" }, ...colorData.data],
    workPlace: workPlaceData.data,
    waypoint: waypointData.data,
    division: divisionData.data,
    habulDivision: habulDivisionData.data,
    billingPlace: billingPlaceData.data,
  };
}
