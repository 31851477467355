import styled from "styled-components";

export const HeaderWrapper = styled.header`
  grid-area: MH;
  display: flex;
  padding: 0 10px;
  background: ${(props) => props.theme.colors.headerBg};
  align-items: center;
  justify-content: space-between;
`;

export const HeaderMenuBtn = styled.button`
  display: flex;
  padding: 3px 3px;
  border: none;
  background: none;
  border-radius: 4px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  &:hover {
    background: rgba(0, 0, 0, 0.4);
    cursor: pointer;
    transition: all 0.2s ease-in-out;
  }

  svg {
    path:nth-of-type(2) {
      fill: ${(props) => props.theme.colors.white};
    }
  }
`;

export const HeaderUserBtn = styled.div`
  padding: 3px 4px 3px 8px;
  color: ${(props) => props.theme.colors.white};
  border-radius: 4px;
  transition: all 0.2s ease-in-out;

  &:hover {
    background: rgba(0, 0, 0, 0.4);
    cursor: pointer;
    transition: all 0.2s ease-in-out;
  }
`;

export const UserName = styled.div`
  display: flex;
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 24px;
  align-items: center;
  justify-content: center;

  .user-type {
    margin-right: 5px;
    color: ${(props) => props.theme.colors.headerUserTypetxt};
    font-size: 1.1rem;
  }

  .user-icon {
    display: flex;
    width: 20px;
    height: 20px;
    margin-left: 10px;
    background: #fff;
    border-radius: 4px;
    justify-content: center;
    align-items: center;

    svg {
      fill: unset;
    }
  }
`;

export const HeaderUserPopup = styled.ul`
  position: absolute;
  overflow: hidden;
  display: flex;
  top: 38px;
  right: 10px;
  padding: 5px 0;
  border-radius: 4px;
  background-color: ${(props) => props.theme.colors.white};
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.15);
  justify-content: center;
  flex-direction: column;
  transition: all 0.2 ease-in-out;
  z-index: 10;
`;

export const PopupContent = styled.li`
  display: flex;
  height: 40px;
  padding: 0 20px 0;
  font-size: 1.5rem;
  border: none;
  background-color: ${(props) => props.theme.colors.white};
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:hover {
    color: ${(props) => props.theme.colors.primary};
    background-color: ${(props) => props.theme.colors.whiteShade};
    transition: all 0.2s ease-in-out;

    svg {
      path {
        fill: ${(props) => props.theme.colors.primary};
        transition: all 0.2 ease-in-out;
      }
    }
  }

  span {
    margin: 2px 3px 0 5px;
    color: ${(props) => props.theme.colors.secondary};
  }

  svg {
    margin-right: 5px;

    path {
      fill: ${(props) => props.theme.colors.secondary};
      transition: all 0.2s ease;
    }
  }

  a {
    display: flex;
    width: 100%;
    height: 40px;
    align-items: center;
    justify-content: center;

    &:hover {
      color: ${(props) => props.theme.colors.primary};
      transition: all 0.2s ease-in-out;
    }

    svg {
      margin-bottom: 2px;
    }
  }
`;
