import React, { useCallback, useMemo, useState } from "react";
import { DataGrid } from "devextreme-react";
import {
  Column,
  ColumnChooser,
  HeaderFilter,
  Scrolling,
  StateStoring,
} from "devextreme-react/data-grid";
import CustomStore from "devextreme/data/custom_store";
import { sendRequest } from "../../../../_metronic/_helpers/DataSoureHelpers";
import { useForm } from "react-hook-form";
import StateStoringSaveAndDeleteButton from "../../../components/StateStoring";

function DriverMaster({ onFocusedRowChanged, dataGridRef }) {
  const [totalCount, setTotalCount] = useState(0);
  const [search, setSearch] = useState({});
  const storageKey = "driverMaster";

  const dataSource = useMemo(() => {
    const apiUrl = `${process.env.REACT_APP_API_URL}/driver`;

    return new CustomStore({
      key: "id",
      load: () => sendRequest(apiUrl, "GET", search),
    });
  }, [search]);

  const { register, handleSubmit } = useForm();

  const onSubmit = (data) => {
    for (const obj in data) {
      if (data[obj] === "") {
        data[obj] = null;
      }
    }
    setSearch(data);
  };

  return (
    <>
      <div className="grid-search-wrap">
        <form onSubmit={handleSubmit(onSubmit)}>
          <span className="search-group">
            <label>이름</label>
            <input type="text" {...register("driverName")} />
          </span>
          <button type="submit" className="btn btn-grid-search">
            검색
          </button>
        </form>
      </div>
      <div className="grid-component has-toolbar">
        <StateStoringSaveAndDeleteButton
          dataGridRef={dataGridRef}
          storageKey={storageKey}
        />
        <div className="grid-total">{`총 ${totalCount}건`}</div>
        <DataGrid
          className="datagrid-wrap"
          id="DriverMaster"
          ref={dataGridRef}
          dataSource={dataSource}
          showRowLines={true}
          showBorders={true}
          hoverStateEnabled={true}
          allowColumnResizing={true}
          allowColumnReordering={true}
          columnResizingMode="widget"
          focusedRowEnabled={true}
          onFocusedRowChanged={onFocusedRowChanged}
          onContentReady={(e) => setTotalCount(e.component.totalCount())}
        >
          <HeaderFilter visible={true} />
          <ColumnChooser enabled={true} />
          <StateStoring
            enabled={true}
            type="custom"
            customLoad={useCallback(() => {
              return JSON.parse(localStorage.getItem(storageKey));
            }, [])}
          />
          <Scrolling mode="virtual" />
          {/* <Column caption="No" cellRender={(e) => e.row.loadIndex + 1} /> */}
          {/* <Column dataField="driverId" caption="아이디" /> */}
          <Column dataField="driverName" caption="이름" alignment="center" />
          <Column
            dataField="birth"
            caption="생년월일"
            alignment="center"
            dataType="date"
            format="yyyy-MM-dd"
          />
          <Column dataField="tel" caption="전화번호" />
          <Column dataField="fax" caption="팩스" />
          <Column dataField="isLeave" caption="퇴사여부" />
          <Column
            dataField="Biz_No_Sys_CompanyTax_Info.Company_Name"
            caption="사업자명"
          />
          <Column dataField="Biz_No" caption="사업자번호" />
          <Column dataField="remark" caption="비고" alignment="center" />
        </DataGrid>
      </div>
    </>
  );
}

export default DriverMaster;
