import { alert, confirm } from "devextreme/ui/dialog";
import moment from "moment";
import React, { useEffect, useState } from "react";
import CustomButton from "../../../../components/FunctionButton/CustomButton";
import { ButtonGroup } from "../../../../components/FunctionButton/CustomButton/customButton.style";
import InputCheckBox from "../../../../components/InputForm/InputCheckBox";
import InputColorSelectBox from "../../../../components/InputForm/InputColorSelectBox";
import InputDate from "../../../../components/InputForm/InputDate";
import InputLookup from "../../../../components/InputForm/InputLookup";
import InputText from "../../../../components/InputForm/InputText";
import InputTextArea from "../../../../components/InputForm/InputTextArea";
import {
  InputDivide,
  InputFullRow,
  PanelInput,
  PanelInputTitle,
  PanelInputWrapper,
  PanelPreviewGrid,
} from "../../../../components/Layout/Panel/PanelInput/panelInput.style";
import {
  getLookupDatas,
  getOrderDataById,
  upsertDispatchData,
} from "../DispatchNewCrud";
import DispatchNewInputList from "./DispatchNewInputList";
import { InputSelect } from "../../../../components/InputForm";

const DISPATCH_ARR = [
  { name: "loadPlace", label: "상차지" },
  { name: "workWaypoint_01", label: "작업" },
  { name: "workWaypoint_02", label: "작업경유지-1" },
  { name: "workWaypoint_03", label: "작업경유지-2" },
  { name: "workPlace", label: "작업지" },
  { name: "collectWaypoint_01", label: "회수" },
  { name: "collectWaypoint_02", label: "반입" },
  { name: "collectWaypoint_03", label: "회수경유지" },
];
/** 리스타트 */
const DispatchNewInput = ({
  rowData,
  openInputBar,
  dataGridRef,
  inputProps,
  setOpenDispatchLogModal,
}) => {
  const { onFileSelectClick, inputForm } = inputProps;
  const [orderData, setOrderData] = useState({});
  const [orderDdata, setOdrderData] = useState({});
  const [lookupData, setLookupData] = useState({
    conType: [],
    workplace: [],
    terminal: [],
    waypoint: [],
    status: [],
    car: [],
    yongchasa: [],
    color: [],
    chassis: [],
  });

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = inputForm;

  useEffect(() => {
    getLookupData();
  }, []);

  useEffect(() => {
    // 여기서 master, detail, dispatch데이터 전부 불러오기
    getOrderData(rowData?.id);

    // eslint-disable-next-line
  }, [rowData]);

  const onSubmit = async (data) => {
    const {
      originalLoadPlace,
      originalUnloadPlace,
      loadPlace,
      unloadPlace,
    } = data;

    for (const key in data) {
      if (!key.includes("completeNo")) continue;

      if (data[key]) {
        // await alert("마감/정산된 데이터가 있습니다.");
        // return;
      }
    }

    if (
      originalLoadPlace !== loadPlace ||
      originalUnloadPlace !== unloadPlace
    ) {
      const result = await confirm(
        "상차지나 하차지가 바뀌면 다른 데이터에 영향이 갈 수 있습니다 진행하시겠습니까?"
      );
      if (!result) return;
    }

    try {
      const {
        data: { result },
      } = await upsertDispatchData({
        orderCode: rowData.orderCode,
        orderDetailCode: rowData.id,
        ...data,
      });

      if (result?.message) {
        alert(result?.message, "오류");
      }

      dataGridRef.current.instance.refresh();
      getOrderData(rowData?.id);
    } catch (error) {
      console.log(error);
    }
  };

  async function getOrderData(id) {
    if (!id) return;

    const orderData = await getOrderDataById(id);

    const { masterDetail, dispatches } = orderData.data;

    setOrderData(masterDetail);
    reset({
      containerNo: masterDetail.containerNo,
      containerType: masterDetail.containerType,
      sealNo: masterDetail.sealNo,
      returnDate: masterDetail.returnDate,
      chassis: masterDetail.chassis,
      originalLoadPlace: masterDetail.originalLoadPlace,
      originalUnloadPlace: masterDetail.originalUnloadPlace,
      fridge: masterDetail.fridge,
      danger: masterDetail.danger,
      tank: masterDetail.tank,
      direct: masterDetail.direct,
      toxic: masterDetail.toxic,
      keep: masterDetail.keep,
      manager: masterDetail.manager || undefined,
      extraInfo: masterDetail.extraInfo || undefined,
      color: masterDetail.color || null,
      remark: masterDetail.remark || undefined,
      innerMemo: masterDetail.innerMemo || undefined,
      loadPlaceFiles: [],
      workWaypoint_01Files: [],
      workWaypoint_02Files: [],
      workWaypoint_03Files: [],
      workPlaceFiles: [],
      collectWaypoint_01Files: [],
      collectWaypoint_02Files: [],
      collectWaypoint_03Files: [],
      ...dispatches,
    });
  }

  async function getLookupData() {
    const [
      workPlaceData,
      terminalData,
      waypointData,
      dispatchStatusData,
      carData,
      yongchasaData,
      colorData,
      chassisData,
      conTypeData,
    ] = await Promise.all([
      getLookupDatas("terminal/workplace"),
      getLookupDatas("terminal/terminal"),
      getLookupDatas("terminal/waypoint"),
      getLookupDatas("comcode/detail/40200"),
      getLookupDatas("carManage/lookup"),
      getLookupDatas("companyManage/lookup/isYongchasa"),
      getLookupDatas("comcode/detail/10900"),
      getLookupDatas("chassis/lookup"),
      getLookupDatas("comcode/detail/10400"),
    ]);

    setLookupData({
      workplace: [{ code: null, displayExpr: "" }, ...workPlaceData.data],
      terminal: [{ code: null, displayExpr: "" }, ...terminalData.data],
      waypoint: [{ code: null, displayExpr: "" }, ...waypointData.data],
      status: [{ id: null, subName: "" }, ...dispatchStatusData.data],
      car: [{ oid: null, carNo_Name: "" }, ...carData.data],
      yongchasa: [{ oid: null, company: "" }, ...yongchasaData.data],
      color: [{ subCode: null, subName: "" }, ...colorData.data],
      chassis: chassisData.data,
      conType: conTypeData.data,
    });
  }

  return (
    <PanelInputWrapper
      onSubmit={handleSubmit(onSubmit, (err) => console.log(err))}
      openInputBar={openInputBar}
    >
      <PanelInputTitle>
        새배차등록{" "}
        {/* <CustomButton
          type="button"
          height="20"
          layout="solid"
          color="common"
          label="내역확인"
          onClick={() => setOpenDispatchLogModal(true)}
        /> */}
      </PanelInputTitle>
      <PanelPreviewGrid>
        <colgroup>
          <col width={60} />
          <col width={67} />
          <col width={60} />
          <col width={67} />
          <col width={60} />
          <col width={67} />
          <col width={60} />
          <col width={67} />
        </colgroup>
        <tbody>
          <tr>
            <td>접수번호</td>
            <td>{orderData?.orderCode}</td>
            <td>접수일자</td>
            <td>
              {orderData?.receiveDate
                ? moment(orderData.receiveDate).format("YYYY-MM-DD")
                : ""}
            </td>
            <td>접수법인</td>
            <td>{orderData?.receiveCoName}</td>
            <td>배차팀</td>
            <td>{orderData?.dispatchTeamName}</td>
          </tr>
          <tr>
            <td>밥테일</td>
            <td>{orderData?.bobtail}</td>
            <td>수입/수출</td>
            <td>{orderData?.importExport}</td>
            <td>청구처</td>
            <td>{orderData?.billingPlaceName}</td>
            <td>청구금액</td>
            <td>{orderData?.freight}</td>
          </tr>
          <tr>
            <td>화주</td>
            <td>{orderData?.cargoOwnerName}</td>
            <td>실화주</td>
            <td>{orderData?.realCargoOwner}</td>
            <td>컨규격</td>
            <td>{orderData?.containerType}</td>
            <td>컨테이너NO</td>
            <td>{orderData?.containerNo}</td>
          </tr>
          <tr>
            <td>선사</td>
            <td>{orderData?.forwarder}</td>
            <td>Seal No</td>
            <td>{orderData?.sealNo}</td>
            <td>운송일자</td>
            <td>
              {orderData?.deliverDate
                ? moment(orderData.deliverDate).format("YYYY-MM-DD")
                : ""}
            </td>

            <td>BL NO</td>
            <td>{orderData?.blNo}</td>
          </tr>
          <tr>
            <td>BK NO</td>
            <td>{orderData?.bkNo}</td>
            <td>DO NO</td>
            <td>{orderData?.doNO}</td>
            <td>입항일</td>
            <td>
              {orderData?.enterDay
                ? moment(orderData.enterDay).format("YYYY-MM-DD")
                : ""}
            </td>
            <td>출항일</td>
            <td>
              {orderData?.sailDay
                ? moment(orderData.sailDay).format("YYYY-MM-DD")
                : ""}
            </td>
          </tr>
          <tr>
            <td>온도</td>
            <td>{orderData?.temperature}</td>
            <td>습도</td>
            <td>{orderData?.humidity}</td>
            <td>환풍구</td>
            <td>{orderData?.vent}</td>
            <td>UN번호</td>
            <td>{orderData?.unNo}</td>
          </tr>
          <tr>
            <td>중량</td>
            <td>{orderData?.cargoWeight}</td>
            <td>작업지주소</td>
            <td colSpan={3}>
              {
                lookupData.workplace.find(
                  (obj) => obj.code === orderData?.workPlace
                )?.address
              }
            </td>
            <td>클래스</td>
            <td>{orderData && orderData["class"]}</td>
          </tr>
          <tr>
            <td>발주처</td>
            <td>{orderData?.orderCoName}</td>
            <td>도착지연락처</td>
            <td>{orderData?.pointManageContact}</td>
          </tr>
          <tr>
            <td colSpan={7} className="td-fullrow">
              <div>
                <InputCheckBox
                  control={control}
                  errors={errors}
                  name="fridge"
                  text="냉동"
                  flex={true}
                  disabled={true}
                />
                <InputCheckBox
                  control={control}
                  errors={errors}
                  name="danger"
                  text="위험물"
                  flex={true}
                  disabled={true}
                />
                <InputCheckBox
                  control={control}
                  errors={errors}
                  name="tank"
                  text="탱크"
                  flex={true}
                  disabled={true}
                />
                <InputCheckBox
                  control={control}
                  errors={errors}
                  name="direct"
                  text="직반입"
                  flex={true}
                  disabled={true}
                />
                <InputCheckBox
                  control={control}
                  errors={errors}
                  name="toxic"
                  text="유해화학물질"
                  flex={true}
                  disabled={true}
                />
                <InputCheckBox
                  control={control}
                  errors={errors}
                  name="keep"
                  text="보관"
                  flex={true}
                  disabled={true}
                />
              </div>
            </td>
          </tr>
        </tbody>
      </PanelPreviewGrid>
      <PanelInput grid={3}>
        <InputText
          control={control}
          errors={errors}
          name="containerNo"
          label="컨테이너 No"
          labelWidth="45px"
        />
        <InputSelect
          control={control}
          errors={errors}
          dataSource={lookupData.conType}
          name="containerType"
          label="컨/규격"
          valueExpr="subCode"
          displayExpr="subName"
          flex={true}
        />
        <InputText
          control={control}
          errors={errors}
          name="sealNo"
          label="Seal No"
          labelWidth="45px"
        />
      </PanelInput>
      <InputFullRow grid={4}>
        <InputDivide />
      </InputFullRow>

      <PanelInput grid={2}>
        <InputDate
          control={control}
          errors={errors}
          name="returnDate"
          label="반납마감일자"
        />
        <InputLookup
          control={control}
          errors={errors}
          name="chassis"
          label="샤시"
          dataSource={lookupData.chassis}
          valueExpr="chassisNo"
          displayExpr="chassisNo"
        />
      </PanelInput>
      <InputFullRow grid={4}>
        <InputDivide />
      </InputFullRow>

      {DISPATCH_ARR.map((cur, index) => {
        const { name, label } = cur;
        return (
          <DispatchNewInputList
            key={index}
            index={index}
            name={name}
            label={label}
            useForm={inputForm}
            lookupData={lookupData}
            disabled={!rowData?.id}
            onClick={onFileSelectClick}
          />
        );
      })}
      <PanelInput grid={4}>
        <InputLookup
          control={control}
          errors={errors}
          name="unloadPlace"
          label="하차지"
          dataSource={lookupData.terminal}
          valueExpr="code"
          displayExpr="twName"
        />
        <InputText
          control={control}
          errors={errors}
          name="manager"
          label="담당자"
          labelWidth="45px"
          // disabled={true}
        />
        {/* <InputText
          control={control}
          errors={errors}
          name="extraInfo"
          label="특이사항"
          labelWidth="45px"
          // disabled={true}
        /> */}
        <InputColorSelectBox
          control={control}
          errors={errors}
          dataSource={lookupData.color}
          label="색상"
          name="color"
          valueExpr="subCode"
          displayExpr="subName"
          flex={true}
        />
        <InputFullRow grid={4}>
          <InputTextArea
            control={control}
            errors={errors}
            name="extraInfo"
            label="특이사항"
            // labelWidth="45px"
            // disabled={true}
          />
        </InputFullRow>
        <InputFullRow grid={4}>
          <InputTextArea
            control={control}
            errors={errors}
            name="remark"
            label="비고"
            // disabled={true}
          />
        </InputFullRow>
        <InputFullRow grid={4}>
          <InputTextArea
            control={control}
            errors={errors}
            name="innerMemo"
            label="내부메모"
            flex={true}
          />
        </InputFullRow>
      </PanelInput>

      <ButtonGroup marginTop={20}>
        <CustomButton
          type="submit"
          width="100%"
          height={36}
          layout="solid"
          color="common"
          label="저장"
          disabled={!rowData?.id}
        />
      </ButtonGroup>
    </PanelInputWrapper>
  );
};

export default DispatchNewInput;
