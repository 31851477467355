import { DataGrid } from "devextreme-react";
import {
  Column,
  ColumnChooser,
  FilterRow,
  HeaderFilter,
  Lookup,
  Scrolling,
  StateStoring,
} from "devextreme-react/data-grid";
import CustomStore from "devextreme/data/custom_store";
import { alert, confirm } from "devextreme/ui/dialog";
import React, { useCallback, useMemo, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { sendRequest } from "../../../../_metronic/_helpers/DataSoureHelpers";
import CustomButton from "../../../components/FunctionButton/CustomButton";
import InputText from "../../../components/InputForm/InputText";
import HeaderTitle from "../../../components/Layout/Header/HeaderTitle";
import {
  DataCount,
  PanelGridInit,
  PanelGridSearchBar,
  PanelGridWrapper,
  SearchItems,
} from "../../../components/Layout/Panel/PanelGrid/panelGrid.style";
import {
  PanelBody,
  PanelBtn,
  PanelHeader,
  PanelInit,
} from "../../../components/Layout/Panel/panel.style";
import StateStoringSaveAndDeleteButton from "../../../components/StateStoring";
import { useDatagrid } from "../../../hooks/useDatagrid";
import { deleteRowData, getLookup } from "./ComCarCrud";
import NewCarModal from "./components/NewCarModal";
import { useEffect } from "react";

const ComCarPage = () => {
  /* #region useState, useRef, const */
  const dataGridRef = useRef();
  useDatagrid(dataGridRef);
  const [totalCount, setTotalCount] = useState(0);
  const [search, setSearch] = useState({});
  const [comCode, setComCode] = useState([]);

  const [newCarModal, setNewCarModal] = useState(false);
  const [updateCarModal, setUpdateCarModal] = useState(false);
  const [selectedRowKey, setSelectedRowKey] = useState(null);

  const storageKey = "comCarMaster";
  /* #endregion */

  /* #region redux, dataSource */

  const dataSource = useMemo(() => {
    const apiUrl = `${process.env.REACT_APP_API_URL}/carManage`;

    return new CustomStore({
      key: "oid",
      load: () => sendRequest(apiUrl, "GET", search),
    });
  }, [search]);
  /* #endregion */

  useEffect(() => {
    getLookup("comcode/detail/").then((data) => {
      setComCode(data.data);
    });
  }, []);

  /* #region useForm */
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();
  /* #endregion */

  /* #region 함수 */
  const onSubmit = (data) => {
    // console.log(data);
    setSearch(data);
  };

  const onClickDelete = async () => {
    if (!selectedRowKey) {
      alert("데이터를 선택해주세요", "오류");
      return;
    }

    let response = await confirm("<i>삭제하시겠습니까?</i>", "삭제 메세지");
    if (!response) return;
    try {
      await deleteRowData(selectedRowKey);
      dataGridRef.current.instance.refresh();
    } catch (err) {
      alert("삭제할 수 없습니다.", "오류");
    }
  };

  const onRowDblClick = useCallback((e) => {
    setSelectedRowKey(e.key);
    setUpdateCarModal(true);
  }, []);
  /* #endregion */

  return (
    <>
      <PanelInit>
        <PanelHeader>
          <HeaderTitle />

          <PanelBtn>
            <CustomButton
              type="button"
              height={24}
              layout="solid"
              color="new"
              label="신규"
              onClick={() => setNewCarModal(true)}
            />
            <CustomButton
              type="button"
              height={24}
              layout="solid"
              color="esc"
              label="삭제"
              onClick={onClickDelete}
            />
          </PanelBtn>
        </PanelHeader>

        <PanelBody row left="100%" right="0">
          <PanelGridWrapper>
            <PanelGridSearchBar onSubmit={handleSubmit(onSubmit)}>
              <SearchItems>
                <label>차량번호</label>
                <InputText
                  control={control}
                  errors={errors}
                  name="carNo"
                  flex={true}
                />
              </SearchItems>

              <CustomButton
                type="submit"
                height={24}
                layout="solid"
                color="search"
                label="검색"
              />
            </PanelGridSearchBar>

            <PanelGridInit>
              <StateStoringSaveAndDeleteButton
                dataGridRef={dataGridRef}
                storageKey={storageKey}
              />
              <DataCount>{`총 ${totalCount}건`}</DataCount>
              <DataGrid
                id="ComCarMaster"
                keyExpr="oid"
                className="datagrid-wrap"
                dataSource={dataSource}
                ref={dataGridRef}
                showRowLines={true}
                showBorders={true}
                hoverStateEnabled={true}
                allowColumnResizing={true}
                allowColumnReordering={true}
                columnResizingMode="widget"
                focusedRowEnabled={true}
                columnAutoWidth={true}
                onFocusedRowChanged={(e) => setSelectedRowKey(e.row.key)}
                onRowDblClick={onRowDblClick}
                onContentReady={(e) => setTotalCount(e.component.totalCount())}
              >
                <FilterRow visible={true} />
                <HeaderFilter visible={true} />
                <ColumnChooser enabled={true} />
                <StateStoring
                  enabled={true}
                  type="custom"
                  customLoad={useCallback(() => {
                    return JSON.parse(localStorage.getItem(storageKey));
                  }, [])}
                />
                <Scrolling
                  mode="virtual"
                  showScrollbar="always"
                  useNative="false"
                />
                <Column
                  caption="순번"
                  cellRender={(e) => e.row.loadIndex + 1}
                />
                <Column dataField="carNo" caption="차량번호" />
                <Column dataField="departName" caption="관리소속" />
                {/* <Column dataField="carDivisionName" caption="차량구분" /> */}
                <Column dataField="carDivision" caption="차량구분">
                  <Lookup
                    dataSource={comCode}
                    valueExpr="subCode"
                    displayExpr="subName"
                  />
                </Column>
                {/* <Column dataField="" caption="관리ID" /> */}
                {/* <Column dataField="kindName" caption="차량종류" /> */}
                <Column dataField="kindCar" caption="차량종류">
                  <Lookup
                    dataSource={comCode}
                    valueExpr="subCode"
                    displayExpr="subName"
                  />
                </Column>
                {/* <Column dataField="tonName" caption="차량톤수" /> */}
                <Column dataField="carTon" caption="차량톤수">
                  <Lookup
                    dataSource={comCode}
                    valueExpr="subCode"
                    displayExpr="subName"
                  />
                </Column>
                <Column dataField="assignName" caption="소속운송사" />
                <Column dataField="carIdCard" caption="차량ID카드" />
                <Column dataField="driverName" caption="이름" />
                <Column dataField="tel" caption="휴대전화" />
                <Column dataField="bizNo" caption="사업자번호" />
                <Column dataField="bizName" caption="상호" />
                <Column dataField="viewRank" caption="조회순위" />
                {/* <Column dataField="creditClassName" caption="신용등급" /> */}
                <Column dataField="creditClass" caption="신용등급">
                  <Lookup
                    dataSource={comCode}
                    valueExpr="subCode"
                    displayExpr="subName"
                  />
                </Column>
                <Column dataField="route" caption="전문노선" />
              </DataGrid>
            </PanelGridInit>
          </PanelGridWrapper>
        </PanelBody>
      </PanelInit>
      {newCarModal && (
        <NewCarModal
          onClose={() => setNewCarModal(false)}
          closable={true}
          visible={true}
          maskClosable={false}
          dataGrid={dataGridRef}
        />
      )}

      {updateCarModal && (
        <NewCarModal
          onClose={() => setUpdateCarModal(false)}
          closable={true}
          visible={true}
          maskClosable={false}
          dataGrid={dataGridRef}
          oid={selectedRowKey}
        />
      )}
    </>
  );
};

export default ComCarPage;
