import React, { useState } from "react";
import Aside from "./Aside";
import Header from "./Header";
import { ContentWrapper, ContentInit } from "./layout.style";

export function Layout({ children }) {
  const [isOpen, setOpen] = useState(true);
  const [asideFixed, setAsideFixed] = useState(true);

  const openToggleHandler = () => {
    setOpen(!isOpen);
    setAsideFixed(!asideFixed);
  };

  const openHoverHandler = () => {
    if ((isOpen === false) | (asideFixed === false)) {
      setOpen(!isOpen);
    }
  };

  return (
    <ContentWrapper className={`${isOpen ? "aside-open" : "default"}`}>
      <Header toggleMenu={openToggleHandler} isOpen={isOpen} />
      <Aside
        toggleMenu={openToggleHandler}
        hoverMenu={openHoverHandler}
        isOpen={isOpen}
      />
      <ContentInit className="print-layout" isOpen={isOpen}>
        {children}
      </ContentInit>
    </ContentWrapper>
  );
}
