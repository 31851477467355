import TreeList, {
  Column,
  Editing,
  RequiredRule,
} from "devextreme-react/tree-list";
import CustomStore from "devextreme/data/custom_store";
import React, { useMemo, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { sendRequest } from "../../../../_metronic/_helpers/DataSoureHelpers";
import CustomButton from "../../../components/FunctionButton/CustomButton";
import FunctionButton from "../../../components/FunctionButton/FunctionButton";
import HeaderTitle from "../../../components/Layout/Header/HeaderTitle";
import {
  PanelGridInit,
  PanelGridSearchBar,
  PanelGridWrapper,
  SearchItems,
} from "../../../components/Layout/Panel/PanelGrid/panelGrid.style";
import {
  PanelBody,
  PanelBtn,
  PanelHeader,
  PanelInit,
} from "../../../components/Layout/Panel/panel.style";

export default function MenuPage() {
  const [search, setSearch] = useState({});
  const dataGridRef = useRef();
  // const [remove, setRemove] = useState(false);
  const { register, handleSubmit } = useForm();

  const dataSource = useMemo(() => {
    const apiUrl = `${process.env.REACT_APP_API_URL}/menu`;

    return new CustomStore({
      key: "id",
      load: () => sendRequest(apiUrl, "GET", search),
      insert: (values) => sendRequest(apiUrl, "POST", { values }),
      update: (key, values) => sendRequest(apiUrl, "PUT", { key, values }),
      remove: (key) => sendRequest(apiUrl, "DELETE", { key }),
    });
  }, [search]);

  //

  const onClickRead = (data) => {
    for (const obj in data) {
      if (data[obj] === "") {
        data[obj] = null;
      }
    }
    setSearch(data);
  };

  const onClickSave = () => {
    console.log("save data", dataGridRef.current);
    if (!dataGridRef.current) return;
    console.log("save");

    dataGridRef.current.instance.saveEditData();
  };

  const onSaved = () => {
    dataGridRef.current.instance.refresh();
  };

  const onToolbarPreparing = (e) => {
    var toolbarItems = e.toolbarOptions.items;

    toolbarItems.forEach((cur) => {
      if (cur.name === "saveButton" || cur.name === "revertButton") {
        cur.visible = false;
      }
    });
  };

  const headerCellRender = (data) => {
    return <p style={{ color: "red", margin: "0" }}>{data.column.caption}</p>;
  };

  // const buttonChange = (e) => {
  //   console.log(e);
  //   console.log(dataGridRef.current.instance);
  //   if (e.column.buttons[0].text === "삭제") {
  //     e.column.buttons[0].text = "삭제 취소";
  //     dataGridRef.current.instance.deleteRow(e.row.rowIndex);
  //     // dataGridRef.current.instance.cancelEditData();
  //     // e.column.buttons[0].text = "삭제";
  //   } else if (e.column.buttons[0].text === "삭제 취소") {
  //     e.column.buttons[0].text = "삭제";
  //     dataGridRef.current.instance.cancelEditData();
  //   }
  // };

  return (
    <>
      <PanelInit>
        <PanelHeader>
          <HeaderTitle />
          <PanelBtn>
            <FunctionButton
              onClickRead={onClickRead}
              onClickSave={onClickSave}
            />
          </PanelBtn>
        </PanelHeader>

        <PanelBody row left="100%" right="0">
          {/* 페이지 메인그리드 검색 */}
          <PanelGridWrapper>
            <PanelGridSearchBar onSubmit={handleSubmit(onClickRead)}>
              <SearchItems>
                <label>메뉴명</label>
                <input type="text" {...register("menuName")} />
              </SearchItems>
              <CustomButton
                type="submit"
                height={24}
                layout="solid"
                color="search"
                label="검색"
              />
            </PanelGridSearchBar>
            <PanelGridInit>
              <TreeList
                id="menuPage"
                className="datagrid-wrap"
                ref={dataGridRef}
                dataSource={dataSource}
                rootValue=""
                keyExpr="id"
                parentIdExpr="parentId"
                allowColumnResizing={true}
                showRowLines={true}
                showBorders={true}
                onToolbarPreparing={onToolbarPreparing}
                onSaved={onSaved}
              >
                <Editing
                  mode="batch"
                  allowAdding={true}
                  allowUpdating={true}
                  allowDeleting={true}
                  startEditAction="dblClick"
                />
                <Column
                  dataField="menuName"
                  caption="메뉴명"
                  headerCellRender={headerCellRender}
                >
                  <RequiredRule message="메뉴명을 입력해주세요."></RequiredRule>
                </Column>
                <Column
                  dataField="menuType"
                  caption="구분"
                  allowEditing={false}
                  alignment="center"
                />
                <Column dataField="sortSeq" caption="순서" alignment="center">
                  <RequiredRule message="순서를 입력해주세요."></RequiredRule>
                </Column>

                <Column dataField="isUse" caption="사용">
                  {/* <RequiredRule message="사용 여부를 체크해주세요."></RequiredRule> */}
                </Column>
                <Column dataField="isRel" caption="배포">
                  {/* <RequiredRule message="배포여부를 체크해주세요."></RequiredRule> */}
                </Column>
                <Column dataField="path" caption="경로">
                  <RequiredRule message="경로를 입력해주세요."></RequiredRule>
                </Column>
                <Column caption="종류" alignment="center" />
                <Column
                  dataField="class"
                  capition="클래스"
                  alignment="center"
                />

                {/* <Column type="buttons" alignment="center">
                <Button
                  // onClick={buttonChange}
                  type="delete"
                  // cssClass="btn btn-grid btn-grid-change pw-change"
                  text="삭제"
                ></Button>
              </Column> */}
              </TreeList>
            </PanelGridInit>
          </PanelGridWrapper>
        </PanelBody>
      </PanelInit>
    </>
  );
}
