import axios from "axios";

const API_URL = `${process.env.REACT_APP_API_URL}`;

export function initialData({ search }) {
  return axios
    .all([
      axios.get(`${API_URL}/shuttlesimple`, { params: search }),
      axios.get(`${API_URL}/company`),
      axios.get(`${API_URL}/department/isDispatch`),
    ])
    .then(
      axios.spread((shuttles, company, dispatch) => {
        return {
          dataSource: shuttles.data,
          lookupCompany: [
            {
              id: "0",
              companyName: "전체",
            },
            ...company.data,
          ],
          lookupDisaptchName: [
            {
              code: 0,
              deptName: "전체",
            },
            ...dispatch.data,
          ],
        };
      })
    );
}

export function searchShuttle({ search }) {
  return axios.get(`${API_URL}/shuttlesimple/excel`, { params: search });
}

export function deleteShuttle(keys) {
  return axios.delete(`${API_URL}/shuttlesimple/excel`, { params: { keys } });
}
