import Guid from "devextreme/core/guid";
import { alert } from "devextreme/ui/dialog";
import moment from "moment";
import { getUnipassInfo } from "../pages/Order/OrderPage/OrderCrud";

export const submitConfirm = async (
  detail,
  rows,
  detailGrid,
  setValue = () => {}
) => {
  if (detail.length === 0 && (!Array.isArray(rows) || rows.length === 0)) {
    alert("세부정보가 없습니다", "오류");
    setValue("confirm", false);
    return false;
  }

  const detailIsValid = await detailGrid.instance
    .getController("validating")
    .validate(true);

  const containerValid = await detailGrid.instance.getController("validating")
    ._validationState;

  for (const obj of containerValid) {
    if (!obj.validationResults) continue;
    const index = Object.keys(obj.validationResults).find(
      (i) => obj.validationResults[i].name === "컨테이너번호"
    );
    const con = obj.validationResults[index]?.brokenRule;
    if (!con) continue;
    if (con.message === "컨테이너번호가 올바르지 않습니다.") {
      alert(con.message, "오류");
      setValue("confirm", false);
      return false;
    }
  }

  if (!detailIsValid) {
    alert("세부정보 오류", "오류");
    setValue("confirm", false);
    return false;
  }

  return true;
};

export const trimOrderMain = (data, detail, division) => {
  // 작업시간 수정
  for (const obj of detail) {
    let workTime = obj.data?.workTime;
    if (!workTime || typeof workTime !== "object") continue;
    workTime = workTime.toTimeString();

    workTime = workTime.split(" ")[0];

    obj.data.workTime = workTime;
  }

  if (data.importExport === "1" || data.importExport === 1) {
    data.bkNo = "";
  } else {
    data.dem = null;
    data.blNo = "";
    data.doNo = "";
  }
  data.division = division;
};

export const getUnipassInfos = async (
  watch,
  setIsLoading,
  setValue,
  detailGrid,
  conType
) => {
  const data = watch();
  if (!data.blNo) {
    alert("B/L No를 입력해 주세요.");
    return false;
  }

  if (!data.blType) {
    alert("B/L 구분을 입력해 주세요.");
    return false;
  }

  try {
    setIsLoading(true);
    const res = await getUnipassInfo({
      blNo: data.blNo,
      blType: data.blType,
      blYear: data.blYear,
      loadPlace: data.loadPlace,
    });
    const { cntrs, vydf, shipNm, etprDt } = res.data;

    setValue("enterDay", moment(etprDt).format("YYYY-MM-DD"));
    setValue("motherShip", vydf);
    setValue("shipName", shipNm);

    if (cntrs.length === 0) {
      alert("조회된 정보가 없습니다.");
    }
    const grid = detailGrid.instance;
    const rows = grid.getVisibleRows().map((cur) => cur.data.containerNo);

    for (const obj of cntrs) {
      if (rows.includes(obj.cntrNo)) continue;
      let ctnr = "";

      const filteredType = conType.filter(
        (cur) => cur.subCode === obj.cntrStszCd
      );

      ctnr = filteredType[0] ? filteredType[0].extra1 : "";

      grid.addRow();
      grid.cellValue(0, "containerNo", obj.cntrNo);
      grid.cellValue(0, "containerType", ctnr);
      grid.cellValue(0, "cargoWeight", obj.weight);
    }
  } catch (err) {
    alert(err);
  }
  setIsLoading(false);
};

// 냉동, 위험물, 탱크 체크시 할증등록 계산
export const onExtraChargeCheckBoxChecked = (
  e,
  rows,
  changes,
  extraChargeType
) => {
  for (const obj of rows) {
    if (!obj.data.freight) continue; // 청구금액이 없을때는 무시

    const check = changes.filter((cur) => cur.key === obj.key)[0];

    if (!check) {
      changes.push({
        data: { extraChargeObj: [] },
        key: obj.key,
        type: "update",
      });
    } else {
      check.data.extraChargeObj = check.data.extraChargeObj || [];
    }

    const extraCharge = obj.data.extraCharges;
    const extraChargeCheck = extraCharge?.filter(
      (cur) => cur.division === extraChargeType
    )[0];

    // 할증등록 계산
    for (const obj2 of changes) {
      if (obj2.key !== obj.key) continue;
      const newKey = new Guid();
      if (!obj2.data.extraChargeObj) obj.data.extraChargeObj = [];

      const extraChargeObj = obj2.data.extraChargeObj;

      for (let i = 0; i < extraChargeObj.length; i++) {
        if (extraChargeObj[i].type !== "remove") continue;
        const key = extraChargeObj[i].key;

        const compare = extraCharge?.filter((cur) => cur.id === key)[0];
        if (compare.division === extraChargeType) {
          extraChargeObj.splice(i, 1);
          const data = {
            isCharged: true,
            isHabul: true,
            division: extraChargeType,
          };
          data.chargedRate = 30;
          data.chargedAmount = Math.round(obj.data.freight * 0.3);
          extraChargeObj.push({ data: data, type: "update", key: key });
          break;
        }
      }

      if (extraChargeCheck) continue;

      const data = {
        isCharged: true,
        isHabul: true,
        division: extraChargeType,
      };
      data.chargedRate = 30;
      data.chargedAmount = Math.round(obj.data.freight * 0.3);

      extraChargeObj.push({
        data: data,
        type: "insert",
        key: newKey._value,
        index: 0,
        pageIndex: 0,
      });
    }
  }
};

// 냉동, 위험물, 탱크 체크해제시 할증등록 계산
export const onExtraChargeCheckBoxUnchecked = (
  e,
  rows,
  changes,
  extraChargeType
) => {
  for (const obj of rows) {
    const check = changes.filter((cur) => cur.key === obj.key)[0];

    if (!check) {
      changes.push({
        data: { extraChargeObj: [] },
        key: obj.key,
        type: "update",
      });
    }

    const extraCharge = obj.data.extraCharges;
    const extraChargeCheck = extraCharge?.filter(
      (cur) => cur.division === extraChargeType
    )[0];

    for (const obj2 of changes) {
      if (obj2.key !== obj.key) continue;
      if (!obj2.data.extraChargeObj) obj.data.extraChargeObj = [];

      const extraChargeObj = obj2.data.extraChargeObj;

      for (let i = 0; i < extraChargeObj.length; i++) {
        if (extraChargeObj[i].type === "remove") continue;
        if (extraChargeObj[i].data.division === extraChargeType) {
          extraChargeObj.splice(i, 1);
          break;
        }
      }

      if (!extraChargeCheck) continue;
      for (let i = 0; i < extraChargeObj.length; i++) {
        if (extraChargeObj[i].type === "remove") continue;
        if (extraChargeObj[i].key === extraChargeCheck.id) {
          extraChargeObj.splice(i, 1);
          break;
        }
      }
      extraChargeObj.push({ key: extraChargeCheck.id, type: "remove" });
    }
  }
};

// 컨테이너 CHECK-DIGIT 계산
// http://www.gvct.co.uk/2011/09/how-is-the-check-digit-of-a-container-calculated/ 참고
export const containerValidationCallback = (e) => {
  if (!e.value) return true;
  if (e.value.length !== 11) return false; // 11자리가 아니면 return
  const conRegex = /^[A-Z]{4}\d{7}$/;
  const result = conRegex.test(e.value); // 형식에 안맞으면 return;
  if (!result) return false;

  let sum = calculateCheckDigit(e.value);

  let mod = sum % 11;
  if (mod === 10) mod = 0;

  if (parseInt(e.value[e.value.length - 1]) !== mod) return false; // CheckDigit이 다르면 return

  return true;
};

function calculateCheckDigit(value) {
  let sum = 0;

  for (let i = 0; i < 10; i++) {
    if (value[i].charCodeAt(0) === 65) {
      // A일때
      let num = value[i].charCodeAt(0) - 55;

      sum += parseInt(num) * Math.pow(2, i);
    } else if (value[i].charCodeAt(0) > 65 && value[i].charCodeAt(0) <= 75) {
      // B-K일때
      let num = value[i].charCodeAt(0) - 54;

      sum += parseInt(num) * Math.pow(2, i);
    } else if (value[i].charCodeAt(0) > 75 && value[i].charCodeAt(0) <= 85) {
      // L-U일떄
      let num = value[i].charCodeAt(0) - 53;

      sum += parseInt(num) * Math.pow(2, i);
    } else if (value[i].charCodeAt(0) > 85 && value[i].charCodeAt(0) <= 90) {
      // V-Z일때
      let num = value[i].charCodeAt(0) - 52;

      sum += parseInt(num) * Math.pow(2, i);
    } else {
      // 숫자일때
      sum += parseInt(value[i]) * Math.pow(2, i);
    }
  }

  return sum;
}

export const makeOtherCoList = (lookupData, receiveCo) => {
  const other = lookupData.company.filter((cur) => cur.id !== receiveCo);
  return [{ id: null, companyName: undefined }, ...other];
};

export const handleOtherDeliver = (
  rowData,
  receiveCo,
  otherDeliver,
  setValue
) => {
  if (!rowData || receiveCo === otherDeliver) setValue("otherDeliver", null);
  else setValue("otherDeliver", rowData?.data?.otherDeliver);
};

/**
 * 할증등록 total계산 함수
 * @param {any[]} charges DB데이터
 * @param {any[]} chargeChanges grid에서 변경된 데이터
 * @param {string} amountName 할증등록은 "chargedAmount" 기타하불은 "habul"
 */
export const calculateTotalCharge = (charges, chargeChanges, amountName) => {
  let total = 0;

  // grid에서 변경된 데이터 insert, update, remove 분리
  const insertChanges =
    chargeChanges?.filter((cur) => cur.type === "insert") ?? [];
  const updateChanges =
    chargeChanges?.filter((cur) => cur.type === "update") ?? [];
  const removeChanges =
    chargeChanges?.filter((cur) => cur.type === "remove") ?? [];

  // DB데이터가 있는경우
  if (Array.isArray(charges) && charges.length > 0) {
    total = charges.reduce((acc, cur) => {
      const chargedAmount =
        updateChanges?.filter((ex) => ex.key === cur.id)[0]?.data?.[
          amountName
        ] ?? cur[amountName];

      return acc + chargedAmount;
    }, total);

    total = insertChanges.reduce((acc, cur) => {
      return acc + cur.data[amountName];
    }, total);

    total = removeChanges.reduce((acc, cur) => {
      const removedCharge = charges.filter((extra) => extra.id === cur.key)[0];
      return acc - removedCharge[amountName];
    }, total);
  }
  // grid에서 변경된 데이터만 있는 경우
  else if (Array.isArray(chargeChanges) && chargeChanges.length > 0) {
    total = chargeChanges.reduce((acc, cur) => {
      return acc + parseInt(cur.data[amountName]);
    }, total);
  }
  return total;
};
