import React, { useMemo, useState } from "react";
import { ModalContainer, ModalHeader } from "../../../../components/Modal";
import CustomStore from "devextreme/data/custom_store";
import { sendRequest } from "../../../../../_metronic/_helpers/DataSoureHelpers";
import { DataGrid } from "devextreme-react";
import {
  Column,
  FilterRow,
  HeaderFilter,
  Scrolling,
  Selection,
} from "devextreme-react/data-grid";

const FileListModal = ({ fileModalProps }) => {
  const [search] = useState({});
  const {
    fileListModalGridRef,
    fileListModalForm,
    onClose,
    onSubmit,
  } = fileModalProps;

  const { handleSubmit } = fileListModalForm;

  const dataSource = useMemo(() => {
    const apiUrl = `${process.env.REACT_APP_API_URL}/app-file-manage`;
    return new CustomStore({
      key: "Oid",
      load: () => sendRequest(apiUrl, "GET", search),
    });
  }, [search]);

  return (
    <>
      <ModalContainer
        onClose={onClose}
        maskClosable={false}
        visible={true}
        size="xl"
      >
        <form className="modal-form" onSubmit={handleSubmit(onSubmit)}>
          <ModalHeader onClose={onClose} title="파일선택" />

          <div className="modal-form-body">
            <DataGrid
              className="datagrid-wrap"
              id="AppFileManageMaster"
              ref={fileListModalGridRef}
              dataSource={dataSource}
              keyExpr="Oid"
              showRowLines={true}
              showBorders={true}
              hoverStateEnabled={true}
              allowColumnResizing={true}
              allowColumnReordering={true}
              columnResizingMode="widget"
              focusedRowEnabled={true}
            >
              <FilterRow visible={true} />
              <HeaderFilter visible={true} />
              <Scrolling
                columnRenderingMode="virtual"
                mode="virtual"
                showScrollbar="always"
                useNative="false"
              />
              <Selection
                mode="multiple"
                allowSelectAll={true}
                showCheckBoxesMode="always"
              />
              <Column
                caption="No"
                cellRender={(e) => e.row.loadIndex + 1}
                alignment="center"
              />
              <Column dataField="fileListCodeName" caption="구분" />
              <Column dataField="File_Name" caption="관리명" />
              <Column dataField="File_Info" caption="파일" />
              <Column dataField="Reg_Date" caption="등록일" />
              <Column dataField="useYnName" caption="사용여부" />
              <Column dataField="Remark" caption="비고" />
            </DataGrid>
          </div>

          <div className="modal-form-footer">
            <button type="submit" className="btn btn-modal btn-save">
              저장
            </button>
          </div>
        </form>
      </ModalContainer>
    </>
  );
};

export default FileListModal;
