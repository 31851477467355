import styled from "styled-components";

export const TabListWrapper = styled.div`
  background: ${(props) => props.theme.colors.tablistBg};

  .rc-dyn-tabs-tablist {
    white-space: nowrap;
    overflow-x: overlay;
    display: flex;
    width: 100%;
    position: relative;
    height: 30px;
    border-bottom: none;
    z-index: 9;
    background-clip: text;

    > li + li {
      margin-left: 1px;
    }

    &:hover {
      transition: background-color 0.2s;

      &::-webkit-scrollbar {
        visibility: visible;
        width: 4px;
        height: 6px;
      }

      &::-webkit-scrollbar-thumb {
        background-clip: padding-box;
        border-color: 1px solid transparent;
        border-radius: 3.5px;
        background-color: inherit;

        &:hover {
          background-color: ${(props) => props.theme.colors.scrollThumbHover};
        }
      }

      &::-webkit-scrollbar-track {
        background-color: rgba(255, 255, 255, 0);
      }
    }

    &::-webkit-scrollbar {
      visibility: hidden;
      width: 0px;
      height: 0px;
      background: transparent;
    }

    .rc-dyn-tabs-tab {
      float: none;
      display: inline-block;
      width: 160px;
      height: 30px;
      border-radius: 2px 2px 0 0;

      &:first-child {
        width: 36px !important;
        margin-left: 1px;

        .bookmark {
          display: none;
        }

        .rc-dyn-tabs-title {
          width: 36px !important;
          padding: 2px;
          font-size: 1.3rem;
          background: ${(props) => props.theme.colors.secondary};
          color: ${(props) => props.theme.colors.white};

          .rc-dyn-tabs-icon {
            padding-right: 1px;
          }
        }
      }

      .rc-dyn-tabs-title {
        width: 160px;
        height: 30px;
        min-width: 0;
        min-height: 0;
        color: ${(props) => props.theme.colors.tablistTxt};
        font-size: 1.3rem;
        letter-spacing: -1px;
        border-radius: 2px 2px 0 0;
        background: ${(props) => props.theme.colors.tablistDefaultBg};
      }

      .rc-dyn-tabs-close {
        display: none;
      }

      &:hover {
        .rc-dyn-tabs-title {
          height: 30px;
          color: rgb(61, 61, 61);
          color: ${(props) => props.theme.colors.tablistTxt};
          background: ${(props) => props.theme.colors.tablistHoverBg};
        }

        .rc-dyn-tabs-close {
          display: inline;
          right: 8px;
          color: ${(props) => props.theme.colors.tablistTxt};
          font-size: 12px;
        }

        .bookmark {
          position: absolute;
          width: 20px;
          height: 30px;
          left: 5px;
          margin: 0;
          z-index: 10;
          visibility: visible;
          opacity: 1;
          transition: ease all 0.2s;

          .MuiIconButton-root {
            width: 20px;
            height: 20px;
            fill: ${(props) => props.theme.colors.tablistTxt};
          }
        }
      }

      &.rc-dyn-tabs-selected {
        width: 160px;
        height: 30px;
        border-radius: 2px 2px 0 0;
        box-shadow: 0px 2px 10px 0px rgba(17, 42, 80, 0.1);

        .rc-dyn-tabs-title {
          width: 160px;
          height: 30px;
          min-height: 0;
          min-width: 0;
          color: ${(props) => props.theme.colors.black};
          background: ${(props) => props.theme.colors.white};
          border-bottom: 3px solid ${(props) => props.theme.colors.primary};
          border-radius: 2px 2px 0 0;
          box-shadow: 0px 2px 10px 0px rgba(17, 42, 80, 0.1);
        }

        .rc-dyn-tabs-close {
          display: inline;
          right: 8px;
          font-size: 12px;
        }

        &:hover {
          .rc-dyn-tabs-close {
            color: ${(props) => props.theme.colors.black};
          }
        }
      }
    }
  }
`;
