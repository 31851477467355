import React, { useEffect, useState } from "react";
import Axios from "axios";
import SVG from "react-inlinesvg";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../_redux/menuActions";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";
import { AsideMenuList, AsideMenuDept, MenuItem } from "../aside.style";
import { ReactComponent as IconArrow } from "../../../../../style/icons/Angle-right.svg";

export default function AsideList(isOpen) {
  const dispatch = useDispatch();
  const [menuItems, setMenuItems] = useState([]);
  const [openMenu, setOpenMenu] = useState({});

  const { selectedItem } = useSelector(
    (state) => ({
      selectedItem: state.menu.selectedItem,
    }),
    shallowEqual
  );

  const getMenuActive = (id, hasSubmenu = false) => {
    let css = "";

    if (selectedItem && selectedItem.parentId === id) {
      css += "menu-active";
    }

    return css;
  };

  const getMenuItemActive = (id, hasSubmenu = false) => {
    let css = "";

    if (selectedItem) {
      if (selectedItem.menuId === id) {
        css += "menu-item-active";
      }
    }

    return css;
  };

  useEffect(() => {
    Axios.get(`${process.env.REACT_APP_API_URL}/menu/list`).then(({ data }) => {
      // console.log("[data]", data);
      setMenuItems(data);
    });
  }, []);

  useEffect(() => {
    dispatch(actions.fetchMenu());
  }, [dispatch]);

  const onSelected = (menu, isDirectory) => {
    if (!isDirectory) {
      dispatch(actions.openItem(menu));
    }
  };

  const toggleMenu = (idx) => {
    setOpenMenu((prevOpenMenu) => ({
      ...prevOpenMenu,
      [idx]: !prevOpenMenu[idx],
    }));
  };

  return (
    <AsideMenuList className={`${isOpen.isOpen ? "list-open" : "list-closed"}`}>
      {menuItems
        .filter((obj) => obj.menuType === "1")
        .map((menu1, idx) => {
          const menu2List = menuItems.filter(
            (obj) => obj.parentId === menu1.id
          );
          const isMenu2 = menu2List.length > 0;
          return (
            // 1단 메뉴
            <AsideMenuDept
              key={menu1.id}
              className={`menu-item ${isMenu2 &&
                "menu-item-submenu"} ${getMenuActive(menu1.id, true)}`}
              aria-haspopup="true"
              data-menu-toggle={isMenu2 && "hover"}
            >
              <MenuItem
                type="button"
                className={`menu-link dept-01 ${isMenu2 && "menu-toggle"}`}
                onClick={() => toggleMenu(idx)}
                // onClick={() => onSelected(menu1, isMenu2 ? true : false)}
              >
                <div className="menu-content">
                  {menu1.iconUrl && (
                    <span className="svg-icon menu-icon">
                      <SVG src={toAbsoluteUrl(menu1.iconUrl)} />
                    </span>
                  )}
                  <span className="menu-txt">{menu1.menuName}</span>
                </div>
                {isMenu2 && <IconArrow className="arrow-icon" />}
              </MenuItem>

              {/* 2단 메뉴 */}
              {isMenu2 && (
                <AsideMenuList
                  className={`list-dept02 ${
                    openMenu[idx] ? "opened" : "closed"
                  }`}
                >
                  {/* <i className="menu-arrow" />
                  <MenuItem
                    className="menu-item  menu-item-parent"
                    aria-haspopup="true"
                  >
                    <span className="menu-link">
                      <span className="menu-text">{menu1.menu.menuName}</span>
                    </span>
                  </MenuItem> */}
                  {menu2List.map((menu2) => {
                    const menu3List = menuItems.filter(
                      (obj) => obj.parentId === menu2.id
                    );
                    const isMenu3 = menu3List.length > 0;
                    return (
                      <AsideMenuDept
                        key={menu2.id}
                        className={`menu-item ${isMenu3 &&
                          "menu-item-submenu"} ${getMenuItemActive(
                          // menu2.path
                          menu2.id
                        )}`}
                        aria-haspopup="true"
                        data-menu-toggle={isMenu3 && "hover"}
                      >
                        <MenuItem
                          className={`menu-link dept-02 ${isMenu3 &&
                            "menu-toggle"}`}
                          onClick={() =>
                            onSelected(menu2, isMenu3 ? true : false)
                          }
                        >
                          <div className="menu-content">
                            {menu2.iconUrl && (
                              <span className="menu-icon">
                                <SVG src={toAbsoluteUrl(menu2.iconUrl)} />
                              </span>
                            )}
                            <span className="menu-txt">{menu2.menuName}</span>
                          </div>
                          {isMenu3 && <IconArrow className="arrow-icon" />}
                        </MenuItem>

                        {/* 3단 메뉴 */}
                        {isMenu3 && (
                          <AsideMenuList>
                            <i className="menu-arrow" />
                            {menu3List.map((menu3) => {
                              return (
                                <AsideMenuDept
                                  key={menu3.id}
                                  className={`menu-item ${getMenuItemActive(
                                    menu3.id
                                  )}`}
                                  aria-haspopup="true"
                                >
                                  <MenuItem
                                    className="menu-link"
                                    to={menu3.path}
                                    onClick={() => onSelected(menu3, false)}
                                  >
                                    {menu3.iconUrl && (
                                      <span className="svg-icon menu-icon">
                                        <SVG
                                          src={toAbsoluteUrl(menu3.iconUrl)}
                                        />
                                      </span>
                                    )}
                                    <span className="menu-txt">
                                      {menu3.menuName}
                                    </span>
                                  </MenuItem>
                                </AsideMenuDept>
                              );
                            })}
                          </AsideMenuList>
                        )}
                      </AsideMenuDept>
                    );
                  })}
                </AsideMenuList>
              )}
            </AsideMenuDept>
          );
        })}
    </AsideMenuList>
  );
}
