import {
  Column,
  DataGrid,
  FilterRow,
  HeaderFilter,
  Scrolling,
  StateStoring,
} from "devextreme-react/data-grid";
import CustomStore from "devextreme/data/custom_store";
import React, { useCallback, useMemo } from "react";
import { sendRequest } from "../../_helpers/DataSoureHelpers";

export function AppFileModal({ dataGridRef, onSelect }) {
  /* #region useState, useRef -----------------------------------------------------------------------*/
  // const [search, setSearch] = useState({});
  // const [selectedRowKey, setSelectedRowKey] = useState();

  const storageKey = "appFileManageMaster";
  /* #region end */

  const dataSource = useMemo(() => {
    const apiUrl = `${process.env.REACT_APP_API_URL}/app-file-manage`;

    return new CustomStore({
      key: "Oid",
      load: () => sendRequest(apiUrl, "GET"),
    });
  }, []);

  const onFocusedRowChanged = useCallback((e) => {
    if (!e.row) return;
    // const rowData = {
    //   ...e.row.data,
    //   FileList_Code: e.row.data.FileList_Code ? e.row.data.FileList_Code : "",
    //   File_Name: e.row.data.File_Name ? e.row.data.File_Name : "",
    //   Reg_date: e.row.data.Reg_date ? e.row.data.Reg_date : "",
    //   Use_Yn: e.row.data.Use_Yn ? e.row.data.Use_Yn : "",
    //   Remark: e.row.data.Remark ? e.row.data.Remark : "",
    // };
    // setSelectedRowKey(e.row.key);
  }, []);

  const onRowDbClick = (e) => {
    onSelect(e.data);
  };
  const customLoad = useCallback(() => {
    return JSON.parse(localStorage.getItem(storageKey));
  }, []);

  return (
    <>
      <div className="grid-component has-toolbar">
        <DataGrid
          className="datagrid-wrap"
          id="AppFileManageMaster"
          ref={dataGridRef}
          dataSource={dataSource}
          keyExpr="Oid"
          showRowLines={true}
          showBorders={true}
          hoverStateEnabled={true}
          allowColumnResizing={true}
          allowColumnReordering={true}
          columnResizingMode="widget"
          onRowDblClick={onRowDbClick}
          focusedRowEnabled={true}
          onFocusedRowChanged={onFocusedRowChanged}
        >
          <FilterRow visible={true} />
          <HeaderFilter visible={true} />
          <StateStoring enabled={true} type="custom" customLoad={customLoad} />
          <Scrolling
            columnRenderingMode="virtual"
            mode="virtual"
            showScrollbar="always"
            useNative="false"
          />
          <Column
            caption="No"
            cellRender={(e) => e.row.loadIndex + 1}
            alignment="center"
          />
          <Column dataField="FileList_Code" caption="구분" />
          <Column dataField="File_Name" caption="관리명" />
          <Column dataField="File_Info" caption="파일" />
          <Column dataField="Reg_Date" caption="등록일" />
          <Column dataField="Use_Yn" caption="사용여부" />
          <Column dataField="Remark" caption="비고" />
        </DataGrid>
      </div>
    </>
  );
}
