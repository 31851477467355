import { alert, confirm } from "devextreme/ui/dialog";
import moment from "moment/moment";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import CustomButton from "../../../components/FunctionButton/CustomButton";
import InputDate from "../../../components/InputForm/InputDate";
import InputSelect from "../../../components/InputForm/InputSelect";
import InputText from "../../../components/InputForm/InputText";
import PageHeader from "../../../components/Layout/PageHeader/PageHeader";
import {
  PanelGridSearchBar,
  PanelGridWrapper,
  PanelMultiGrid,
  SearchItems,
} from "../../../components/Layout/Panel/PanelGrid/panelGrid.style";
import {
  PanelBody,
  PanelInit,
} from "../../../components/Layout/Panel/panel.style";
import {
  finishCancel,
  getLookup,
  updateZeroTaxSellManage,
} from "./ClosingCrud";
import ClosingDateModal from "./Components/ClosingDateModal";
import ClosingDetail from "./Components/ClosingDetail";
import ClosingMaster from "./Components/ClosingMaster";
import ClosingModal from "./Components/ClosingModal";
import MailingModal from "./Components/MailingModal";
import NextMonthModal from "./Components/NextMonthModal";
import SpecificationTempleteModal from "./Components/SpecificationTempleteModal";
import useClosing from "./useClosing";
import { InputCheckBox } from "../../../components/InputForm";
import InputComboSelectBox from "../../../components/InputForm/InputComboSelectBox";

const ClosingPage = () => {
  const {
    openNextMonthModal,
    closingLeftRef,
    closingRightRef,
    closingDetailRef,
    onClickNextMonth,
    onClickCancelNextMonth,
    nextMonthModalProps,
  } = useClosing();
  /* #region useState */
  const [openMailingModal, setOpenMailingModal] = useState(false);
  const [openSpecification, setOpenSpecification] = useState(false);
  const [openSpecificationTemplete, setOpenSpecificationTemplete] = useState(
    false
  );
  const [openFinish, setOpenFinish] = useState(false);
  const [search, setSearch] = useState({});
  const [selectedRow, setSelectedRow] = useState({});
  const [templeteInfo, setTempleteInfo] = useState({});
  const [mailPdf, setMailPdf] = useState();

  const [lookupData, setLookupData] = useState({
    company: [],
    disaptchName: [],
  });
  /* #endregion */

  /* #region useForm */
  const initialState = {
    receiveCo: "0",
    searchType: "0",
    firstDay: moment()
      .subtract(14, "days")
      .format("YYYY-MM-DD"),
    lastDay: moment().format("YYYY-MM-DD"),
    isDivision: "0",
    isFinish: "0",
    // dispatchTeam: `${user?.deptCode}`,
    dispatchTeam: "0",
    companyName: "",
    receiveCoName: "",
    carryoverInquiry: false,
  };
  const {
    watch,
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: initialState,
  });
  /* #endregion */

  /* #region useEffect */
  useEffect(() => {
    getLookupData();
    setSearch(initialState);
    // eslint-disable-next-line
  }, []);
  /* #endregion */

  /* #region 기타함수 */

  async function getLookupData() {
    try {
      const dispatchTeamData = await getLookup("department/isDispatch");
      const companyData = await getLookup("company");

      setLookupData({
        company: [
          {
            id: "0",
            companyName: "전체",
          },
          ...companyData.data,
        ],
        disaptchName: [
          {
            code: "0",
            deptName: "전체",
          },
          ...dispatchTeamData.data,
        ],
      });
    } catch (err) {
      console.log(err);
    }
  }

  const checkSelection = (instance) => {
    const selected = instance.getSelectedRowsData();

    if (selected.length === 0) {
      alert("데이터를 선택해주세요", "오류");
      return false;
    }
    return selected;
  };

  const onClickSpecification = () => {
    // 접수법인 체크 후에 true로 전환
    const instance = closingRightRef.current.instance;
    const selected = checkSelection(instance);
    if (!selected) return;

    let receiveCo = selected[0].receiveCo;

    for (const obj of selected) {
      if (receiveCo !== obj.receiveCo) {
        alert("접수법인이 다른 데이터가 존재합니다", "오류");
        return;
      }
    }

    setOpenSpecificationTemplete(true);
  };

  // 영세율 버튼 클릭 시 실행되는 함수
  const onClickZeroTax = async () => {
    const instance = closingRightRef.current.instance;
    // 선택된 행의 division 값을 가져옴
    const division = selectedRow?.division;
    // checkSelection 함수를 이용해 선택된 행을 가져옴
    const selected = checkSelection(instance);
    // 선택된 행이 없으면 함수 종료
    if (!selected) return;

    // 선택된 행의 id 값을 배열로 만듦
    const selectedKeys = selected.map((cur) => cur.id);

    // 서버로 보낼 데이터 객체 생성
    const sendData = {
      selected: selected,
      division: division,
      key: selectedKeys,
    };

    // confirm 함수를 이용해 영세율로 수정할 것인지 확인
    const shouldUpdateZeroTax = await confirm(
      "영세율로 수정하시겠습니까?",
      "확인"
    );
    // 확인하지 않으면 함수 종료
    if (!shouldUpdateZeroTax) return;

    try {
      // updateZeroTaxSellManage 함수를 이용해 서버에 데이터 전송
      await updateZeroTaxSellManage(sendData);
      // 인스턴스를 새로고침
      await instance.refresh();
      // 새로 선택된 행을 가져와서 onSelectionChanged 함수를 실행
      const selected = instance.getSelectedRowsData();
      closingDetailRef.current.onSelectionChanged(selected);
      // onSelectionChanged(selected);
    } catch (error) {
      // 에러 발생 시 알림창 띄움
      alert("에러", "오류");
    }
  };

  const onClickFinish = async () => {
    // instance에서 선택한거 마감된 건이 있는지 확인후 마감처리
    const instance = closingRightRef.current.instance;
    const selected = checkSelection(instance);
    if (!selected) return;

    for (const obj of selected) {
      if (obj.completeStatus === "마감") {
        alert("마감된 데이터가 있습니다.", "오류");
        return;
      }
      // 정산된 데이터 확인
      if (obj.closeStatus === "정산") {
        alert("정산된 데이터가 있습니다.", "오류");
        return;
      }
    }

    setOpenFinish(true);
  };

  const onClickCancel = async () => {
    // instance에서 선택한거 정산된 건이 있는지 확인후 마감취소
    const instance = closingRightRef.current.instance;
    const instanceLeft = closingLeftRef.current.instance;
    const selected = checkSelection(instance);
    if (!selected) return;

    for (const obj of selected) {
      // 정산된 데이터 확인
      if (obj.closeStatus === "정산") {
        alert("정산된 데이터가 있습니다.", "오류");
        return;
      }
    }

    const result = await confirm("마감취소 하시겠습니까?", "확인");
    if (!result) return;

    try {
      const {
        data: { result },
      } = await finishCancel(selected);

      if (result.message) {
        await alert(result.message, "오류");
      }

      instance.deselectAll();
      instance.refresh();
      instanceLeft.refresh();
    } catch (error) {
      await alert("에러가 발생했습니다.", "오류");
      console.log(error);
    }
  };

  function handleMonthChange({ value }) {
    const currentYear = moment(watch("firstDay")).year();
    const startOfMonth = moment(`${currentYear}-${value}-01`)
      .startOf("month")
      .format("YYYY-MM-DD");
    const endOfMonth = moment(`${currentYear}-${value}-01`)
      .endOf("month")
      .format("YYYY-MM-DD");

    setValue("firstDay", startOfMonth);
    setValue("lastDay", endOfMonth);
  }
  /* #endregion */

  return (
    <>
      <PanelInit>
        <PageHeader>
          <CustomButton
            type="button"
            height={24}
            layout="solid"
            color="common"
            label="이월"
            onClick={onClickNextMonth}
          />
          <CustomButton
            type="button"
            height={24}
            layout="solid"
            color="esc"
            label="이월취소"
            onClick={onClickCancelNextMonth}
          />
          <CustomButton
            type="button"
            height={24}
            layout="solid"
            color="common"
            label="영세율"
            onClick={onClickZeroTax}
          />
          <CustomButton
            type="button"
            height={24}
            layout="solid"
            color="common"
            label="거래명세서 출력"
            onClick={onClickSpecification}
          />
          <CustomButton
            type="button"
            height={24}
            layout="solid"
            color="common"
            label="마감"
            onClick={onClickFinish}
          />
          <CustomButton
            type="button"
            height={24}
            layout="solid"
            color="esc"
            label="마감취소"
            className="btn btn-grid btn-grid-delete"
            onClick={onClickCancel}
          />
        </PageHeader>

        <PanelBody row left="100%" right="auto">
          <PanelGridWrapper>
            <PanelGridSearchBar
              onSubmit={handleSubmit((data) => {
                for (const key in data) {
                  data[key] = `${data[key]}`;
                }
                const { searchType } = data;
                if (searchType === "null" || !searchType) {
                  alert("일정검색조건을 선택해주세요", "오류");
                  return;
                }
                setSearch(data);
                closingLeftRef.current.instance.option("focusedRowIndex", -1);
                setSelectedRow({});
              })}
            >
              <SearchItems>
                <label>접수법인</label>
                <InputSelect
                  className="label-select"
                  control={control}
                  errors={errors}
                  name="receiveCo"
                  displayExpr="companyName"
                  valueExpr="id"
                  dataSource={lookupData.company}
                  flex={true}
                />
              </SearchItems>
              <SearchItems>
                <label>배차팀</label>
                <InputSelect
                  control={control}
                  errors={errors}
                  name="dispatchTeam"
                  dataSource={lookupData.disaptchName}
                  valueExpr="code"
                  displayExpr="deptName"
                  flex={true}
                />
              </SearchItems>
              <SearchItems>
                <label>일정검색</label>
                <InputComboSelectBox
                  className="label-select"
                  control={control}
                  errors={errors}
                  name="searchType"
                  dataSource={[
                    { id: "1", name: "접수일자" },
                    { id: "0", name: "운송일자" },
                    { id: "2", name: "운송마감일자" },
                  ]}
                  keyExpr="id"
                  valueExpr="id"
                  displayExpr="name"
                />
              </SearchItems>

              <SearchItems>
                <InputDate
                  className="label-select"
                  control={control}
                  errors={errors}
                  name="firstDay"
                  flex={true}
                />
                ~
                <InputDate
                  className="label-select"
                  control={control}
                  errors={errors}
                  name="lastDay"
                  flex={true}
                />
              </SearchItems>
              <SearchItems>
                <label>월선택</label>
                <InputSelect
                  className="label-select"
                  control={control}
                  errors={errors}
                  dataSource={[
                    { id: "01", name: "1월" },
                    { id: "02", name: "2월" },
                    { id: "03", name: "3월" },
                    { id: "04", name: "4월" },
                    { id: "05", name: "5월" },
                    { id: "06", name: "6월" },
                    { id: "07", name: "7월" },
                    { id: "08", name: "8월" },
                    { id: "09", name: "9월" },
                    { id: "10", name: "10월" },
                    { id: "11", name: "11월" },
                    { id: "12", name: "12월" },
                  ]}
                  valueExpr="id"
                  displayExpr="name"
                  name="month"
                  flex={true}
                  onValueChanged={handleMonthChange}
                />
              </SearchItems>
              <SearchItems>
                <label>구분</label>
                <InputSelect
                  className="label-select"
                  control={control}
                  errors={errors}
                  dataSource={[
                    { id: "0", name: "전체" },
                    { id: "청구처", name: "청구처" },
                    { id: "용차사", name: "용차사" },
                    { id: "기사", name: "기사" },
                  ]}
                  valueExpr="id"
                  displayExpr="name"
                  name="isDivision"
                  flex={true}
                />
              </SearchItems>
              <SearchItems>
                <label>마감구분</label>
                <InputSelect
                  className="label-select"
                  control={control}
                  errors={errors}
                  dataSource={[
                    { id: "0", name: "전체" },
                    { id: "1", name: "마감" },
                    { id: "2", name: "미마감" },
                  ]}
                  valueExpr="id"
                  displayExpr="name"
                  name="isFinish"
                  flex={true}
                />
              </SearchItems>

              <SearchItems>
                <label>청구처/용차사/차량번호</label>
                <InputText
                  className="label-select"
                  control={control}
                  errors={errors}
                  name="companyName"
                  flex={true}
                />
              </SearchItems>

              <SearchItems>
                <label>청구이월조회</label>
                <InputCheckBox
                  className="label-select"
                  control={control}
                  errors={errors}
                  name="carryoverInquiry"
                  flex={true}
                />
              </SearchItems>
              <CustomButton
                type="submit"
                height={24}
                layout="solid"
                color="search"
                label="검색"
              />
            </PanelGridSearchBar>
            <PanelMultiGrid row left="360px" right="calc(100% - 370px)">
              <ClosingMaster
                search={search}
                closingLeftRef={closingLeftRef}
                selectedRow={selectedRow}
                setSelectedRow={setSelectedRow}
              />
              <ClosingDetail
                ref={closingDetailRef}
                search={search}
                closingRightRef={closingRightRef}
                selectedRow={selectedRow}
              />
            </PanelMultiGrid>
          </PanelGridWrapper>
        </PanelBody>
      </PanelInit>
      {openSpecificationTemplete && (
        <SpecificationTempleteModal
          onClose={() => setOpenSpecificationTemplete(false)}
          closable={true}
          visible={true}
          maskClosable={false}
          search={search}
          selectedRow={selectedRow}
          selectedRightRow={closingRightRef.current.instance.getSelectedRowsData()}
          onFinish={(data) => {
            setTempleteInfo(data);
            setOpenSpecification(true);
            setOpenSpecificationTemplete(false);
          }}
        />
      )}
      {openFinish && (
        <ClosingDateModal
          onClose={() => setOpenFinish(false)}
          closable={true}
          visible={true}
          maskClosable={false}
          closingRightRef={closingRightRef}
          closingLeftRef={closingLeftRef}
        />
      )}
      {openSpecification && (
        <ClosingModal
          onClose={() => setOpenSpecification(false)}
          closable={true}
          visible={true}
          maskClosable={false}
          searchObj={watch()}
          closingRightRef={closingRightRef}
          selectedLeftRow={selectedRow}
          selectedRightRow={closingRightRef.current.instance.getSelectedRowsData()}
          templeteInfo={templeteInfo}
          setOpenMailingModal={setOpenMailingModal}
          setMailPdf={setMailPdf}
        />
      )}
      {openMailingModal && (
        <MailingModal
          onClose={() => setOpenMailingModal(false)}
          selectedRowData={selectedRow}
          searchObj={watch()}
          closable={true}
          visible={true}
          maskClosable={false}
          mailPdf={mailPdf}
        />
      )}
      {openNextMonthModal && (
        <NextMonthModal nextMonthModalProps={nextMonthModalProps} />
      )}
    </>
  );
};

export default ClosingPage;
