import React from "react";
/* System**/
import AuthGroupPage from "../../../../../../app/pages/System/AuthGroup/AuthGroupPage";
import ComCodePage from "../../../../../../app/pages/System/ComCode.js/ComCodePage";
import CompanyPage from "../../../../../../app/pages/System/Company/CompanyPage";
import DepartmentPage from "../../../../../../app/pages/System/Department/DepartmentPage";
import MenuPage from "../../../../../../app/pages/System/Menu/MenuPage";
import UserPage from "../../../../../../app/pages/System/User/UserPage";
import CarPage from "../../../../../../app/pages/System/Car/CarPage";
import ComCarPage from "../../../../../../app/pages/System/ComCar/ComCarPage";
import ChassisPage from "../../../../../../app/pages/System/Chassis/ChassisPage";
import DriverPage from "../../../../../../app/pages/System/Driver/DriverPage";
import ManagerPage from "../../../../../../app/pages/System/Manager/ManagerPage";
import CompanyTaxInfoPage from "../../../../../../app/pages/System/CompanyTaxInfo/CompanyTaxInfoPage";
/* Management */
import BillingPage from "../../../../../../app/pages/Management/Billing/BillingPage";
import ComCompanyPage from "../../../../../../app/pages/Management/ComCompany/ComCompanyPage";
import CargoOwnerPage from "../../../../../../app/pages/Management/CargoOwner/CargoOwnerPage";
import ForwarderPage from "../../../../../../app/pages/Management/Forwarder/ForwarderPage";
import MotherShipPage from "../../../../../../app/pages/Management/MotherShip/MotherShipPage";
import ShuttlePage from "../../../../../../app/pages/Management/Shuttle/ShuttlePage";
import TerminalPage from "../../../../../../app/pages/Management/Terminal/TerminalPage";
import WorkPlacePage from "../../../../../../app/pages/Management/WorkPlace/WorkPlacePage";
import YongchasaPage from "../../../../../../app/pages/Management/Yongchasa/YongchasaPage";
import PaymentLedgerPage from "../../../../../../app/pages/Management/PaymentLedger/PaymentLedgerPage";

/* Order */
import OrderPage from "../../../../../../app/pages/Order/OrderPage/OrderPage";
import LCLOrderPage from "../../../../../../app/pages/Order/LCLOrder/LCLOrderPage";
import SimpleDispatchPage from "../../../../../../app/pages/Order/SimpleDispatch/SimpleDispatchPage";
// import ShuttleDispatchPage from "../../../../../../app/pages/Order/ShuttleDispatch/ShuttleDispatchPage";
import YongchaDispatchPage from "../../../../../../app/pages/Order/YongchaDispatch/YongchaDispatchPage";
import SuttleDispatchReturnPage from "../../../../../../app/pages/Order/ShuttleDispatchReturn/ShuttleDispatchReturnPage";
import ContainerCostPage from "../../../../../../app/pages/Order/ContainerCost/ContainerCostPage";
import Dispatch from "../../../../../../app/pages/Order/Dispatch/Dispatch";
import DispatchStatus from "../../../../../../app/pages/Order/DispatchStatus/DispatchStatusPage";
import DangerManagePage from "../../../../../../app/pages/Order/DangerManage/DangerManagePage";
import OrderSummary from "../../../../../../app/pages/Order/OrderSummary/OrderSummary";
import OrderRefPage from "../../../../../../app/pages/Order/OrderRef/OrderRefPage";
import BerthStatus from "../../../../../../app/pages/Order/BerthStatus/BerthStatus";
import ReturnListManagePage from "../../../../../../app/pages/Order/ReturnListManage/ReturnListManagePage";
import ChassisRefPage from "../../../../../../app/pages/Order/ChassisRef/ChassisRefPage";
import DispatchNew from "../../../../../../app/pages/Order/DispatchNew/DispatchNew";
import DispatchSearch from "../../../../../../app/pages/Order/DispatchSearch/DispatchSearch";
import SimpleSellFinishPage from "../../../../../../app/pages/Order/SimpleSellFinish/SimpleSellFinishPage";

/* Shuttle Manage */
import ShuttleDispatchPage from "../../../../../../app/pages/Shuttle/ShuttleDispatch/ShuttleDispatchPage";
import ImportPickupPage from "../../../../../../app/pages/Shuttle/ImportPickup/ImportPickupPage";
import ImportReturnPage from "../../../../../../app/pages/Shuttle/ImportReturn/ImportReturnPage";
import ExportPickupPage from "../../../../../../app/pages/Shuttle/ExportPickup/ExportPickupPage";
import ExportReturnPage from "../../../../../../app/pages/Shuttle/ExportReturn/ExportReturnPage";

/* Sell Manage */
import ClosingPage from "../../../../../../app/pages/SellManage/Closing/ClosingPage";
import FinishManagePage from "../../../../../../app/pages/SellManage/FinishManage/FinishManagePage";
import AccountHistoryPage from "../../../../../../app/pages/SellManage/AccountHistory/AccountHistoryPage";
import NonPaymentPage from "../../../../../../app/pages/SellManage/NonPayment/NonPaymentPage";
// import LossCostPage from "../../../../../../app/pages/SellManage/LossCost/LossCostPage";
// import CostMy from "../../../../../../app/pages/SellManage/CostMy/CostMyPage";
// import YongchaReferencePage from "../../../../../../app/pages/SellManage/CostMy/YongchaReferencePage";
// import CostBillPage from "../../../../../../app/pages/SellManage/CostBill/CostBillPage";
// import CostBillReferencePage from "../../../../../../app/pages/SellManage/CostBill/CostBillReferencePage";
// import CostDriverPage from "../../../../../../app/pages/SellManage/CostDriver/CostDriverPage";
// import CostDriverReferencePage from "../../../../../../app/pages/SellManage/CostDriver/CostDriverReferencePage";
// import SellSummary from "../../../../../../app/pages/SellManage/SellSummary/SellSummaryPage";
// import TradeSetPage from "../../../../../../app/pages/SellManage/TradeSetPage/TradeSetPage";
// import TaxIssuePage from "../../../../../../app/pages/SellManage/TaxIssue/TaxIssuePage";
// import TaxManagePage from "../../../../../../app/pages/SellManage/TaxManage/TaxManagePage";
// import AccountManagePage from "../../../../../../app/pages/Management/AccountManage/AccountManagePage";
import ShuttleBasicCodePage from "../../../../../../app/pages/Management/ShuttleBasicCode/ShuttleBasicCodePage";
import ShuttleSimpleDispatchPage from "../../../../../../app/pages/Order/ShuttleSimpleDispatch/ShuttleSimpleDispatchPage";
import CarClosingPage from "../../../../../../app/pages/SellManage/CarClosing/CarClosingPage";

/* Dong App */
import PushAppPage from "../../../../../../app/pages/DongApp/PushApp/PushAppPage";
import AppFileManagePage from "../../../../../../app/pages/DongApp/AppFileManage/AppFileManagePage";
import TelephonePage from "../../../../../../app/pages/DongApp/Telephone/TelephonePage";
import AppSheetPage from "../../../../../../app/pages/DongApp/AppSheet/AppSheetPage";
import AppContainerPage from "../../../../../../app/pages/DongApp/AppContainer/AppContainerPage";
import ShuttleExcelPage from "../../../../../../app/pages/Shuttle/SuttleExcel/ShuttleExcelPage";
import ShuttleExcelUploadPage from "../../../../../../app/pages/Shuttle/SuttleExcelUpload/ShuttleExcelUploadPage";
import InvoiceEmailHistoryPage from "../../../../../../app/pages/SellManage/InvoiceEmailHistory/InvoiceEmailHistoryPage";
import EtcCostPage from "../../../../../../app/pages/SellManage/EtcCost/EtcCostPage";
import CarDeadlinePage from "../../../../../../app/pages/SellManage/CarDeadline/CarDeadlinePage";
import CalculateProfitPage from "../../../../../../app/pages/SellManage/CalculateProfit/CalculateProfitPage";

const menus = [
  {
    id: "B06",
    panelComponent: (props) => <CompanyPage />,
  },
  {
    id: "B04",
    panelComponent: (props) => <AuthGroupPage />,
  },
  {
    id: "hVeMtAcZUPNv2FszkQuP2b",
    panelComponent: (props) => <DepartmentPage />,
  },
  {
    id: "B03",
    panelComponent: (props) => <UserPage />,
  },
  {
    id: "f3ie7s1PB3Ax9KTWjMPuJ8",
    panelComponent: (props) => <CarPage />,
  },
  {
    id: "tcF25iX3g2r3B8UB6muH51",
    panelComponent: (props) => <ChassisPage />,
  },
  {
    id: "6RbnaTzZqyGJfyLHMXWJfp",
    panelComponent: (props) => <DriverPage />,
  },
  {
    id: "B01",
    panelComponent: (props) => <ComCodePage />,
  },
  {
    id: "c7RM72qaLRHpSSFceuH7ND",
    panelComponent: (props) => <ManagerPage />,
  },
  {
    id: "B05",
    panelComponent: (props) => <MenuPage />,
  },
  {
    id: "1r1DuEUHonVDmtakqw9N3c",
    panelComponent: (props) => <MotherShipPage />,
  },
  {
    id: "2hYYe1mrUg57jppJ9bntTW",
    panelComponent: (props) => <YongchasaPage />,
  },
  {
    id: "3ngg5pWGris22VBBj6B7Tn",
    panelComponent: (props) => <TerminalPage />,
  },
  {
    id: "ks8YkYqALKM2Vt9U8j72DQ",
    panelComponent: (props) => <ShuttlePage />,
  },
  {
    id: "mVwkWGAsLNKJjKLmqsWFPU",
    panelComponent: (props) => <CargoOwnerPage />,
  },
  {
    id: "uCPX3FbEdxsE4uvfTyvuuc",
    panelComponent: (props) => <BillingPage />,
  },
  {
    id: "w6KNkDmaAAhRuabVRLmg3h",
    panelComponent: (props) => <ForwarderPage />,
  },
  {
    id: "ceCM7DHAKaKCnPLehjaqwo",
    panelComponent: (props) => <YongchaDispatchPage />,
  },
  {
    id: "2NJ4gThi91b9j21eCGhCMn",
    panelComponent: (props) => <SimpleDispatchPage />,
  },
  // {
  //   id: "88LuR1H5x3xo5yDss1C4XU",
  //   panelComponent: (props) => <ShuttleDispatchPage />,
  // },
  {
    id: "fR39bTp3MbgePwuCk6Qah2",
    panelComponent: (props) => <SuttleDispatchReturnPage />,
  },
  {
    id: "7JtVmFjdota2P4zCHzPgpU",
    panelComponent: (props) => <OrderPage />,
  },
  // {
  //   id: "q7TZMomSni6CxDUJHX5nhB",
  //   panelComponent: (props) => <OrderPage />,
  // },
  {
    id: "3c4XSGYuhLzSSjKDH6uVgZ",
    panelComponent: (props) => <Dispatch />,
  },
  {
    id: "xtYPqs7gnvDU7xkLYLdrND",
    panelComponent: (props) => <BerthStatus />,
  },
  // {
  //   id: "tsxrJBG8hRpdBCmDCGnZwH",
  //   panelComponent: (props) => <LossCostPage />,
  // },
  {
    id: "wWYwqfNvKFbQp8oVtFDhVK",
    panelComponent: (props) => <CompanyTaxInfoPage />,
  },
  // {
  //   id: "pPRcak9Vatf6pXBpQPr5qg",
  //   panelComponent: (props) => <CostMy />,
  // },
  // {
  //   id: "kjZL8rbX5v92q26TVDpwqB",
  //   panelComponent: (props) => <CostBillPage />,
  // },
  // {
  //   id: "xzYjRiVkXdyTENhkFvBFyD",
  //   panelComponent: (props) => <CostDriverPage />,
  // },
  // {
  //   id: "3rHDRfzt5jdCkGK6hyjsLE",
  //   panelComponent: (props) => <TaxIssuePage />,
  // },
  // {
  //   id: "1FguR86JFdc4P3NotvfLqp",
  //   panelComponent: (props) => <TaxManagePage />,
  // },
  {
    id: "6ohCqLDX8AHA4K2m2uiGE3",
    panelComponent: (props) => <AccountHistoryPage />,
  },
  // {
  //   id: "5Y4den6EYdRjvbgXioe4et",
  //   panelComponent: (props) => <AccountManagePage />,
  // },
  {
    id: "cG6R6e3ZxJECaXaFBCdnmv",
    panelComponent: (props) => <OrderSummary />,
  },
  // {
  //   id: "n4yHnzGEVGf2fPUNzKbkji",
  //   panelComponent: (props) => <SellSummary />,
  // },
  {
    id: "q4HuL11hh8PzZexetpvhd8",
    panelComponent: (props) => <OrderRefPage />,
  },
  // {
  //   id: "xf6tFp6ss33WTb5FeTqKk8",
  //   panelComponent: (props) => <YongchaReferencePage />,
  // },
  // {
  //   id: "dnx7wax2D6S6kYQjdvSvdD",
  //   panelComponent: (props) => <CostBillReferencePage />,
  // },
  // {
  //   id: "qXuSYvuMD2xSsKHPJ9YAhR",
  //   panelComponent: (props) => <CostDriverReferencePage />,
  // },
  // {
  //   id: "aQ9kpM9bXFYAZLKcL1sLcq",
  //   panelComponent: (props) => <TradeSetPage />,
  // },
  {
    id: "81jYGSgnB3Cre8vPHz7XH4",
    panelComponent: (props) => <WorkPlacePage />,
  },
  {
    id: "phe2v8TgYJc1bVdJ1sxb6d",
    panelComponent: (props) => <DangerManagePage />,
  },
  {
    id: "6oVyd6jfAscLFN87sLqR9g",
    panelComponent: (props) => <ReturnListManagePage />,
  },
  {
    id: "mMqp1H5aXqL9GxTwKckRMn",
    panelComponent: (props) => <ChassisRefPage />,
  },
  {
    id: "fA1Jv3xzkfwSHHGR8ZQ4PV",
    panelComponent: (props) => <DispatchStatus />,
  },
  {
    id: "7ij6p5TaEd61587oEWhZ8y",
    panelComponent: (props) => <ContainerCostPage />,
  },
  {
    id: "marz716K4gmQW8pcrKMcit",
    panelComponent: (props) => <LCLOrderPage />,
  },
  {
    id: "rqHwNVFqv6mQ7aHJjmukJp",
    panelComponent: (props) => <ShuttleBasicCodePage />,
  },
  {
    id: "oAo6nvE6n4EikmPvVXkFyD",
    panelComponent: (props) => <ShuttleSimpleDispatchPage />,
  },

  {
    id: "ekntbWJBWhxHaT61AFJjri",
    panelComponent: (props) => <ShuttleDispatchPage />,
  },
  {
    id: "3Qa2qerrPkyobxUKxeFCV8",
    panelComponent: (props) => <ShuttleExcelPage />,
  },
  {
    id: "nGJs1UEbjYiap5bYXYH3e8",
    panelComponent: (props) => <ShuttleExcelUploadPage />,
  },
  {
    id: "jWk392Phw3TDJaAyyNwWV3",
    panelComponent: (props) => <ImportPickupPage />,
  },

  {
    id: "4Dqx2zBFo9KVqc8f86cczV",
    panelComponent: (props) => <ImportReturnPage />,
  },
  {
    id: "kGvLDNNnLbSgdbCHnafvZx",
    panelComponent: (props) => <ExportPickupPage />,
  },
  {
    id: "qna6o4rYZyfvvVfkgtwFHK",
    panelComponent: (props) => <ExportReturnPage />,
  },
  {
    id: "bbmzGRg2w8oXpJcUfGDfoK",
    panelComponent: (props) => <ComCompanyPage />,
  },
  {
    id: "uFs4wPcTAK8jXJbP8vkZaS",
    panelComponent: (props) => <ComCarPage />,
  },
  {
    id: "8Ug3y21CRfY7ZjsoooMa2w",
    panelComponent: (props) => <ClosingPage />,
  },
  {
    id: "gQGVxuMBFE5nYsnXBKucKK",
    panelComponent: (props) => <FinishManagePage />,
  },

  {
    id: "mLE9pBtHjCqLJKgrTcZwq7",
    panelComponent: (props) => <PushAppPage />,
  },
  {
    id: "uDFC1L2oWHJm6iwuQQgrh1",
    panelComponent: (props) => <AppFileManagePage />,
  },
  {
    id: "kWvMhxPPadHVqEuuQbZoHH",
    panelComponent: (props) => <TelephonePage />,
  },
  {
    id: "7RofBh3CdDpEtRHhXwidLK",
    panelComponent: (props) => <AppSheetPage />,
  },
  {
    id: "dEt67qojYE27L1B3bxjJXo",
    panelComponent: (props) => <AppContainerPage />,
  },
  {
    id: "3xGjqo7G7jfxnkyUqiuMBA",
    panelComponent: (props) => <NonPaymentPage />,
  },
  {
    id: "mkU1Gvb7GqgxGJ35rd2pGC",
    panelComponent: (props) => <DispatchNew />,
  },
  {
    id: "fAFriGVTrW4SwJdNgVMwNZ",
    panelComponent: (props) => <DispatchSearch />,
  },
  {
    id: "iaihDwNbJiNdPfkkVrDUqr",
    panelComponent: (props) => <SimpleSellFinishPage />,
  },
  {
    id: "fKqXhuUWSxWxf1pLekPyMt",
    panelComponent: (props) => <InvoiceEmailHistoryPage />,
  },
  {
    id: "oyrZnLVMbUwee6FbghYU8q",
    panelComponent: (props) => <EtcCostPage />,
  },
  {
    id: "3ENFhJ8X9b6wTHVVQtyaq7",
    panelComponent: (props) => <CarDeadlinePage />,
  },
  {
    id: "fAm4MzaKDn4yHfC8evHrtt",
    panelComponent: (props) => <CalculateProfitPage />,
  },
  {
    id: "qu6zX44iToK1tjRF1uoTeL",
    panelComponent: (props) => <CarClosingPage />,
  },
  {
    id: "p6gezARwmFqRMP9e4gpb3W",
    panelComponent: (props) => <PaymentLedgerPage />,
  },
];
export function getMenu(id) {
  return menus.filter((obj) => obj.id === id)[0];
}
