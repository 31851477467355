import { DataGrid } from "devextreme-react";
import {
  Column,
  ColumnChooser,
  FilterRow,
  HeaderFilter,
  Scrolling,
  StateStoring,
} from "devextreme-react/data-grid";
import React, { useCallback, useState } from "react";
import { useForm } from "react-hook-form";
import CustomButton from "../../../components/FunctionButton/CustomButton";
import InputDate from "../../../components/InputForm/InputDate";
import InputSelect from "../../../components/InputForm/InputSelect";
import InputText from "../../../components/InputForm/InputText";
import {
  DataCount,
  PanelGridInit,
  PanelGridSearchBar,
  PanelGridWrapper,
  SearchItems,
} from "../../../components/Layout/Panel/PanelGrid/panelGrid.style";
import StateStoringSaveAndDeleteButton from "../../../components/StateStoring";

const AppContainerMaster = ({
  onFocusedRowChanged,
  dataGridRef,
  setContainerModalVisible,
}) => {
  /* #region useState, useRef -----------------------------------------------------------------------*/

  const [totalCount, setTotalCount] = useState(0);
  // const [search, setSearch] = useState({});

  const storageKey = "appContainerMaster";
  /* #endregion */

  /* #region initialState, react-hook-form, yup ---------------------------------------------------- */
  // const initialState = {};

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();
  /* #endregion */

  // const dataSource = useMemo(() => {
  //   const apiUrl = `${process.env.REACT_APP_API_URL}/app-file-manage`;
  //   return new CustomStore({
  //     key: "Oid",
  //     load: () => sendRequest(apiUrl, "GET", search),
  //   });
  // }, [search]);

  const onSubmit = (data) => {
    for (const obj in data) {
      if (data[obj] === "") {
        data[obj] = null;
      }
    }
    // setSearch(data);
  };

  const onRowDblClick = useCallback(
    (e) => {
      setContainerModalVisible(true);
    },
    [setContainerModalVisible]
  );

  return (
    <>
      <PanelGridWrapper>
        <PanelGridSearchBar onSubmit={handleSubmit(onSubmit)}>
          <SearchItems>
            <label>일정검색</label>
            <InputSelect
              className="label-select"
              control={control}
              errors={errors}
              name="searchType"
              displayExpr="name"
              valueExpr="id"
              dataSource={[
                { id: 2, name: "운송요청일자" },
                { id: 1, name: "접수일자" },
                { id: 3, name: "작업지도착시간" },
              ]}
              flex={true}
            />
            <InputDate
              control={control}
              errors={errors}
              name="firstDay"
              flex={true}
            />
            <div className="to-mark">~</div>
            <InputDate
              control={control}
              errors={errors}
              name="lastDay"
              flex={true}
            />
          </SearchItems>
          <SearchItems>
            <label>컨테이너NO</label>
            <InputText
              control={control}
              errors={errors}
              name="containerNo"
              flex={true}
            />
          </SearchItems>
          <CustomButton
            type="submit"
            height={24}
            layout="solid"
            color="search"
            label="검색"
          />
        </PanelGridSearchBar>
        <PanelGridInit>
          <StateStoringSaveAndDeleteButton
            dataGridRef={dataGridRef}
            storageKey={storageKey}
          />
          <DataCount>{`총 ${totalCount}건`}</DataCount>
          <DataGrid
            className="datagrid-wrap"
            id="AppContainerMaster"
            ref={dataGridRef}
            dataSource={[
              {
                Oid: "oid1",
                receiveDate: "접수일자1",
                containerNo: "컨테이너No1",
                containerType: "컨규격1",
                carNo: "기사1",
                dispatchTeam: "배차팀1",
                billingPlaceName: "청구처1",
                orderCompany: "발주처1",
              },
              {
                Oid: "oid2",
                receiveDate: "접수일자2",
                containerNo: "컨테이너No2",
                containerType: "컨규격2",
                carNo: "기사2",
                dispatchTeam: "배차팀2",
                billingPlaceName: "청구처2",
                orderCompany: "발주처2",
              },
            ]}
            keyExpr="Oid"
            showRowLines={true}
            showBorders={true}
            hoverStateEnabled={true}
            allowColumnResizing={true}
            allowColumnReordering={true}
            columnResizingMode="widget"
            focusedRowEnabled={true}
            onFocusedRowChanged={onFocusedRowChanged}
            onRowDblClick={onRowDblClick}
            onContentReady={(e) => setTotalCount(e.component.totalCount())}
          >
            <FilterRow visible={true} />
            <HeaderFilter visible={true} />
            <ColumnChooser enabled={true} />
            <StateStoring
              enabled={true}
              type="custom"
              customLoad={useCallback(() => {
                return JSON.parse(localStorage.getItem(storageKey));
              }, [])}
            />
            <Scrolling
              columnRenderingMode="virtual"
              mode="virtual"
              showScrollbar="always"
              useNative="false"
            />
            <Column
              caption="No"
              cellRender={(e) => e.row.loadIndex + 1}
              alignment="center"
            />
            <Column dataField="receiveDate" caption="접수일자" />
            <Column dataField="containerNo" caption="컨테이너No" />
            <Column dataField="containerType" caption="컨규격" />
            <Column dataField="carNo" caption="기사" />
            <Column dataField="dispatchTeam" caption="배차팀" />
            <Column dataField="billingPlaceName" caption="청구처" />
            <Column dataField="orderCompany" caption="발주처" />
            <Column dataField="" caption="선사" />
            <Column dataField="" caption="선명" />
            <Column dataField="" caption="모선항차" />
            <Column dataField="" caption="화주" />
            <Column dataField="dem" caption="선사반출기한" />
            <Column dataField="" caption="입항일" />
            <Column dataField="" caption="출항일" />
            <Column dataField="" caption="접안일" />
            <Column dataField="" caption="상차지" />
            <Column dataField="" caption="하차지" />
            <Column dataField="" caption="BL NO" />
            <Column dataField="" caption="BK NO" />
            <Column dataField="" caption="DO NO" />
            <Column dataField="" caption="비고" />
          </DataGrid>
        </PanelGridInit>
      </PanelGridWrapper>
    </>
  );
};

export default React.memo(AppContainerMaster);
