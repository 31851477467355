import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useForm } from "react-hook-form";
import { shallowEqual, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import * as requestServer from "./ShuttleDispatchReturnCrud";

import InputDate from "../../../components/InputForm/InputDate";
import InputSelect from "../../../components/InputForm/InputSelect";

import moment from "moment";

import { alert, confirm } from "devextreme/ui/dialog";
import * as Modals from "./ShuttleDispatchReturnModals";

import {
  Column,
  ColumnChooser,
  DataGrid,
  Format,
  MasterDetail,
  Scrolling,
} from "devextreme-react/data-grid";

import CustomStore from "devextreme/data/custom_store";
import { sendRequest } from "../../../../_metronic/_helpers/DataSoureHelpers";
import InputText from "../../../components/InputForm/InputText";
import ShuttleDispatchDetail from "./ShuttleDispatchDetail";

function SuttleDispatchReturnPage(props) {
  // user
  const { user } = useSelector(
    (state) => ({ user: state.auth.user.user }),
    shallowEqual
  );

  // menu
  const { menuAll, openItem } = useSelector(
    (state) => ({
      menuAll: state.menu.menuAll,
      openItem: state.menu.openItem,
    }),
    shallowEqual
  );

  // initialState
  const initialState = {
    searchType: 1,
    numbersSelect: "blNo",
    firstDay: moment(new Date())
      .subtract(30, "days")
      .format("YYYY-MM-DD"),
    lastDay: moment().format("YYYY-MM-DD"),
    authGroupId: user.authGroupId,
    dispatchTeam: user?.deptCode,
    forwarder: "",
  };

  // dataState
  const [totalCount, setTotalCount] = useState(0);
  const [search, setSearch] = useState();

  // Ref
  const detailGridRef = useRef();
  const dataGridRef = useRef();

  // onFocusedRowChanged State
  const [focusedComponent, setFocusedComponent] = useState();
  // const [rowData, setRowData] = useState();
  const [detailRowData, setDetailRowData] = useState();

  // Modals State
  const [makeDispatchModalVisible, setMakeDispatchModalVisible] = useState();
  const [habulModalVisible, setHabulModalVisible] = useState();

  // lookup state
  const [forwarder, setForwarder] = useState();

  // useForm
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  // data
  const dataSource = useMemo(() => {
    const apiUrl = `${process.env.REACT_APP_API_URL}/shuttlereturn`;

    return new CustomStore({
      key: "id",
      load: () => sendRequest(apiUrl, "GET", search),
    });
  }, [search]);

  // *functions*
  const getLookup = async (data) => {
    const forwarderData = await requestServer.getLookup("forwarder/lookup");
    setForwarder(forwarderData.data);
  };

  // dispatches
  // 배차등록
  const makeDispatch = () => {
    if (!detailRowData) {
      alert("디테일데이터를 선택해주세요", "오류");
      return;
    }

    if (
      detailRowData.dispatchStatus !== "접수" &&
      detailRowData.dispatchStatus !== "배차등록"
    ) {
      alert("접수 상태에서만 배차등록이 가능합니다", "오류");
      return;
    }

    if (detailRowData.completeNo) {
      alert("정산된 데이터입니다", "오류");
      return;
    }

    setMakeDispatchModalVisible(true);
  };

  // 배차확정
  const finishDispatch = async () => {
    if (!detailRowData) {
      alert("배차디테일정보를 선택해주세요", "오류");
      return;
    }
    if (detailRowData.dispatchStatus !== "배차등록") {
      alert("배차등록 상태에서만 배차확정이 가능합니다", "오류");
      return;
    }

    const result = await confirm("배차확정 하시겠습니까?", "확인");
    if (!result) return;

    try {
      await requestServer.dispatchFinish(detailRowData);

      dataGridRef.current.instance.refresh();
    } catch (err) {
      alert(err.response.data.message, "오류");
    }
  };

  // 배차취소
  const cancelDispatch = async () => {
    if (!detailRowData) {
      alert("디테일데이터를 선택해주세요", "오류");
      return;
    }

    if (detailRowData.completeNo) {
      alert("정산된 데이터입니다", "오류");
      return;
    }

    if (detailRowData.dispatchStatus === "접수") {
      alert("배차등록 되지않았습니다", "오류");
      return;
    }

    const result = await confirm("배차를 취소하시겠습니까?", "확인");
    if (!result) return;

    try {
      await requestServer.cancelDispatch(detailRowData);

      dataGridRef.current.instance.refresh();
    } catch (error) {
      alert(error.response.data.message, "오류");
    }
  };

  // 창고작업완료
  const confirmDispatch = async () => {
    if (!detailRowData) {
      alert("디테일데이터를 선택해주세요", "오류");
      return;
    }

    if (detailRowData.completeNo) {
      alert("정산된 데이터입니다", "오류");
      return;
    }

    if (detailRowData.dispatchStatus === "창고작업완료") {
      // 완료했을 때
      alert("이미 작업을 완료한 배차입니다", "오류");
      return;
    }

    if (detailRowData.dispatchStatus === "운송완료") {
      // 운송완료했을 때
      alert("운송완료를 한 배차입니다", "오류");
      return;
    }

    if (detailRowData.dispatchStatus !== "배차확정") {
      // 배차확정이 아닐 때
      alert("배차확정이 되지 않았습니다", "오류");
      return;
    } else if (
      detailRowData.dispatchStatus !== "배차확정" &&
      detailRowData.loadPlace === null
    ) {
      alert("상차지가 없어 완료할 수 없습니다", "오류");
      return;
    }

    const result = await confirm("창고작업완료 하시겠습니까?", "확인");
    if (!result) return;

    try {
      await requestServer.finishWarehouse(detailRowData);

      dataGridRef.current.instance.refresh();
    } catch (error) {
      alert(error.response.data.message, "오류");
    }
  };

  const finishDeliver = async () => {
    if (!detailRowData) {
      alert("디테일데이터를 선택해주세요", "오류");
      return;
    }

    if (detailRowData.completeNo) {
      alert("정산된 데이터입니다", "오류");
      return;
    }

    if (detailRowData.dispatchStatus === "창고작업완료") {
      // 완료했을 때
      alert("이미 작업을 완료한 배차입니다", "오류");
      return;
    }

    if (detailRowData.dispatchStatus === "운송완료") {
      // 운송완료했을 때
      alert("운송완료를 한 배차입니다", "오류");
      return;
    }

    const result = await confirm("운송완료 하시겠습니까?", "확인");
    if (!result) return;

    try {
      await requestServer.confirmDispatch(detailRowData);

      dataGridRef.current.instance.refresh();
    } catch (error) {
      alert(error.response.data.message, "오류");
    }
  };

  // 하불등록
  const setHabulModal = () => {
    if (!detailRowData) {
      alert("디테일데이터를 선택해주세요", "오류");
      return;
    }

    setHabulModalVisible(true);
  };

  const closeModal = () => {
    setMakeDispatchModalVisible(false);
    setHabulModalVisible(false);

    dataGridRef.current.instance.option("focusedRowIndex", -1);
    // setRowData(null);
    setDetailRowData(null);
  };

  // useEffects
  useEffect(() => {
    try {
      reset(initialState);
      setSearch(initialState);

      getLookup();
    } catch (error) {
      console.log(error);
    }
    // eslint-disable-next-line
  }, []);

  // submit
  const onSubmit = (data) => {
    for (const obj in data) {
      if (data[obj] === "") {
        data[obj] = null;
      }
    }

    // console.log("submit", data);

    const submit = {
      authGroupId: user.authGroupId,
      dispatchTeam: user.dispatchTeam,
      ...data,
    };

    setSearch(submit);
  };

  // renders
  const importExportRender = useCallback((e) => {
    if (
      e.data.orderCode_orderMaster.importExport === 1 ||
      e.data.importExport === "1"
    ) {
      return <div>수입</div>;
    } else {
      return <div>수출</div>;
    }
  }, []);

  const habulCellRender = useCallback((e) => {
    const dispatches = e.data.dispatches;

    let sum = 0;
    for (const obj of dispatches) {
      sum += obj.habul;
    }

    return <div>{sum.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</div>;
  }, []);

  // DataGrids
  const onFocusedRowChanged = useCallback(
    (e) => {
      if (e.rowIndex === -1) return;
      // eslint-disable-next-line
      if (focusedComponent && focusedComponent != e.component) {
        focusedComponent.option("focusedRowIndex", -1);
        if (e.element.id === "SimpleDispatchMaster") {
          setDetailRowData(null);
        } else if (e.element.id === "SimpleDispatchDetail") {
          // setRowData(null);
        }
      }
      setFocusedComponent(e.component);
    },
    [focusedComponent]
  );

  const onCellClick = (e) => {
    if (!e.row) return;
  };

  const onCellPrepared = useCallback((e) => {
    if (e.rowType !== "data") return;
    if (e.column.caption !== "상차지" && e.column.caption !== "하차지") return;

    if (e.column.caption === "상차지") {
      e.cellElement.style.background =
        e.data.orderCode_orderMaster.loadPlace_terminalWarehouse?.color ||
        "transparant";
    } else if (e.column.caption === "하차지") {
      e.cellElement.style.background =
        e.data.orderCode_orderMaster.unloadPlace_terminalWarehouse?.color ||
        "transparant";
    }
  }, []);

  return (
    <>
      <div className="page-wrap">
        <div className="column-left full-size">
          {/* 페이지 헤더 */}
          <div className="panel-header flex-row">
            <div className="panel-header-init">
              <div className="panel-header-tit">{openItem?.menu?.menuName}</div>
              <ul className="breadcrumb-list">
                <li className="breadcrumb-item">
                  <Link to="/">
                    <i className="flaticon2-shelter icon-1x" />
                  </Link>
                </li>
                {openItem?.menu?.parentId && (
                  <li className="breadcrumb-item">
                    {
                      menuAll.find((menu) => {
                        return menu.menuId === openItem?.menu?.parentId;
                      })?.menu?.menuName
                    }
                  </li>
                )}
                {openItem?.menu?.menuName && (
                  <li className="breadcrumb-item current">
                    {openItem?.menu?.menuName}
                  </li>
                )}
              </ul>
            </div>

            {/* 기본 btn set */}
            <div className="d-flex align-items-center button-group">
              <button
                className="btn btn-grid btn-grid-new lg"
                onClick={confirmDispatch}
              >
                창고작업완료
              </button>
              <button
                className="btn btn-grid btn-grid-new lg"
                onClick={finishDeliver}
              >
                운송완료
              </button>
              <button
                className="btn btn-grid btn-grid-add"
                onClick={makeDispatch}
              >
                배차등록
              </button>
              <button
                className="btn btn-grid btn-grid-add"
                onClick={finishDispatch}
              >
                배차확정
              </button>
              <button
                className="btn btn-grid btn-grid-add"
                onClick={setHabulModal}
              >
                하불등록
              </button>
              <button
                className="btn btn-grid btn-grid-delete"
                onClick={cancelDispatch}
              >
                배차취소
              </button>
            </div>
          </div>

          {/* 페이지 메인그리드 검색 */}
          <div className="grid-search-wrap">
            <form onSubmit={handleSubmit(onSubmit)}>
              <span className="search-group">
                <div className="dx-field">
                  <InputSelect
                    className="label-select"
                    control={control}
                    errors={errors}
                    name="searchType"
                    displayExpr="name"
                    valueExpr="id"
                    dataSource={[
                      { id: 1, name: "접수일자" },
                      { id: 2, name: "운송(요청)일자" },
                      { id: 3, name: "반납일자" },
                    ]}
                    flex={true}
                  />
                </div>
                <InputDate
                  control={control}
                  errors={errors}
                  name="firstDay"
                  flex={true}
                />
                <div className="to-mark">~</div>
                <InputDate
                  control={control}
                  errors={errors}
                  name="lastDay"
                  flex={true}
                />
              </span>
              <span className="search-group">
                <label>선사</label>
                <div className="dx-field">
                  <InputSelect
                    className="label-select"
                    control={control}
                    errors={errors}
                    name="forwarder"
                    displayExpr="forwarder_Name"
                    valueExpr="code"
                    dataSource={forwarder}
                    flex={true}
                  />
                </div>
              </span>
              <span className="search-group">
                <div className="dx-field">
                  <InputSelect
                    className="label-select"
                    control={control}
                    errors={errors}
                    name="numbersSelect"
                    displayExpr="name"
                    valueExpr="id"
                    dataSource={[
                      { id: "blNo", name: "B/L No" },
                      { id: "doNo", name: "D/O No" },
                      { id: "bkNo", name: "B/K No" },
                    ]}
                    flex={true}
                  />
                </div>
                <InputText
                  className="label-select"
                  control={control}
                  errors={errors}
                  name="numbers"
                  flex={true}
                />
              </span>
              <span className="search-group">
                <label>컨테이너 번호</label>
                <div className="dx-field">
                  <InputText
                    className="label-select"
                    control={control}
                    errors={errors}
                    name="containerNo"
                    flex={true}
                  />
                </div>
              </span>
              <button type="submit" className="btn btn-grid-search single-line">
                검색
              </button>
            </form>
          </div>

          {/* 페이지 메인그리드 */}
          <div className="grid-component order-page has-toolbar">
            <div className="grid-total">{`총 ${totalCount}건`}</div>
            <DataGrid
              className="datagrid-wrap multi-line has-toolbar"
              id="ShuttleDispatchReturnMaster"
              keyExpr="code"
              dataSource={dataSource}
              ref={dataGridRef}
              showRowLines={true}
              showBorders={true}
              hoverStateEnabled={true}
              allowColumnResizing={true}
              allowColumnReordering={true}
              columnResizingMode="widget"
              focusedRowEnabled={true}
              columnAutoWidth={true}
              onCellPrepared={onCellPrepared}
              onCellClick={onCellClick}
              onFocusedRowChanged={onFocusedRowChanged}
              onContentReady={(e) => setTotalCount(e.component.totalCount())}
            >
              <ColumnChooser enabled={true} />
              <Scrolling
                columnRenderingMode="virtual"
                mode="virtual"
                showScrollbar="always"
                useNative="false"
              />
              <Column
                dataField="orderCode_orderMaster.code"
                caption="접수번호"
              />
              <Column
                dataField="orderStatus_comCodeDetail.subName"
                caption="상태"
                alignment="center"
              />
              <Column
                dataField="orderCode_orderMaster.importExport"
                caption="수입/수출"
                cellRender={importExportRender}
                alignment="center"
              />
              <Column
                dataField="orderCode_orderMaster.receiveDate"
                caption="접수일자"
                alignment="center"
              />
              <Column
                dataField="orderCode_orderMaster.billingPlace_billingPlace.companyName"
                caption="청구처"
              />
              <Column
                dataField="orderCode_orderMaster.forwarder"
                caption="선사"
                alignment="center"
              />
              <Column
                dataField="orderCode_orderMaster.motherShip"
                caption="모선항차"
                alignment="center"
              />
              <Column
                dataField="orderCode_orderMaster.blNo"
                caption="B/L No"
                alignment="center"
              />
              <Column
                dataField="orderCode_orderMaster.doNo"
                caption="D/O No"
                alignment="center"
              />
              <Column
                dataField="orderCode_orderMaster.bkNo"
                caption="B/K No"
                alignment="center"
              />
              <Column
                dataField="orderCode_orderMaster.loadPlace"
                caption="상차지"
                alignment="center"
              />
              <Column
                dataField="workPlace_workPlace.workPlaceNaem"
                caption="작업지(창고)"
                alignment="center"
              />
              <Column
                dataField="orderCode_orderMaster.unloadPlace"
                caption="하차지"
                alignment="center"
              />
              <Column
                dataField="returnDate"
                caption="반납일자"
                alignment="center"
              />
              <Column
                dataField="orderCode_orderMaster.cargoOwner_billingPlace.companyName"
                caption="화주"
              />
              <Column
                dataField="deliverDate"
                caption="운송일자"
                dataType="date"
                format="yyyy-MM-dd"
                alignment="center"
              />
              <Column
                dataField="containerNo"
                caption="컨테이너No"
                alignment="center"
              />
              <Column
                dataField="containerType"
                caption="컨/규격"
                alignment="center"
              ></Column>
              <Column
                dataField="cargoWeight"
                caption="중량"
                alignment="center"
              />
              <Column dataField="sealNo" caption="실번호" alignment="center" />
              <Column
                dataField="freight"
                dataType="number"
                caption="청구금액"
                alignment="right"
              >
                <Format type="fixedPoint" />
              </Column>
              <Column
                caption="하불금액"
                alignment="right"
                cellRender={habulCellRender}
              >
                <Format type="fixedPoint" />
              </Column>
              <Column dataField="remark" caption="비고" />
              <MasterDetail
                enabled={true}
                render={(e) => (
                  <ShuttleDispatchDetail
                    data={e.data.id}
                    dataGridRef={dataGridRef}
                    setDetailRowData={setDetailRowData}
                    detailGridRef={detailGridRef}
                    onFocusedRowChanged={onFocusedRowChanged}
                  />
                )}
              />
            </DataGrid>
          </div>
        </div>
      </div>
      {makeDispatchModalVisible && (
        <Modals.MakeDispatch
          onClose={closeModal}
          closable={true}
          visible={true}
          maskClosable={false}
          detailRowData={detailRowData}
          dataGridRef={dataGridRef}
        />
      )}
      {habulModalVisible && (
        <Modals.HabulModal
          onClose={closeModal}
          closable={true}
          visible={true}
          maskClosable={false}
          detailRowData={detailRowData}
          dataGridRef={dataGridRef}
        />
      )}
    </>
  );
}

export default SuttleDispatchReturnPage;
