import axios from "axios";

const apiUrl = `${process.env.REACT_APP_API_URL}/shuttlesimple/exreturn`;
const apiUrlLookup = `${process.env.REACT_APP_API_URL}`;

export function getLookup(param) {
  return axios.get(apiUrlLookup + `/${param}`);
}

// export function createShuttleSimple(data) {
//   return axios.post(apiUrl, data);
// }

export function updateExportReturn(key, data) {
  return axios.put(apiUrl, { key, data });
}

export function updateExportReturnComplete(key, data) {
  return axios.put(apiUrl + `/complete`, { key, data });
}

export function deleteRow(key) {
  return axios.delete(apiUrl + `/${key}`);
}
