import axios from "axios";

const apiUrlLookup = `${process.env.REACT_APP_API_URL}`;
const apiUrl = `${process.env.REACT_APP_API_URL}/company`;

export function getLookup(param) {
  return axios.get(apiUrlLookup + `/${param}`);
}

export function getRowDataById(oid) {
  return axios.get(apiUrl + `/${oid}`);
}

export function loadCompany() {
  return axios.get(apiUrl);
}
export function createCompany(data) {
  return axios.post(apiUrl, data);
}

export function updateCompany(key, data) {
  return axios.put(apiUrl, { key, data });
}

export function deleteRow(key) {
  return axios.delete(apiUrl + `/${key}`);
}
