import { yupResolver } from "@hookform/resolvers/yup";
import { alert } from "devextreme/ui/dialog";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import SVG from "react-inlinesvg";
import * as yup from "yup";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";
import InputSelect from "../../../../components/InputForm/InputSelect";
import InputTel from "../../../../components/InputForm/InputTel";
import InputText from "../../../../components/InputForm/InputText";
import InputTextArea from "../../../../components/InputForm/InputTextArea";
import {
  createCompany,
  getLookup,
  getRowDataById,
  updateCompany,
} from "../CompanyCrud";
import InputPassword from "../../../../components/InputForm//InputPassword";
import { ModalContainer } from "../../../../components/Modal";

const NewCompanyModal = ({
  onClose,
  maskClosable,
  visible,
  rowData,
  dataGrid,
  oid,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [lookupData, setLookupData] = useState({
    custoDivision: [],
    creditClass: [],
    actStatus: [],
    company: [],
    agency: [],
    department: [],
    assignWorker: [],
    kindBiz: [],
    bank: [],
  });

  useEffect(() => {
    if (oid) {
      // 여기서 oid를 이용해서 dbFetch
      getData(oid);
    }
    getLookupData();
    // eslint-disable-next-line
  }, []);

  const initialState = {
    companyName: "",
    companyNameEng: "",
    bizNo: "",
    ceoName: "",
    bizType: "",
    bizItem: "",
    tel: "",
    hp: "",
    fax: "",
    email: "",
    bankCode: null,
    bankNumber: "",
    contactId: "",
    contactName: "",
    zipCode: "",
    address: "",
    addressDetail: "",
    remark: "",
  };

  const schema = yup.object().shape({
    companyName: yup
      .string()
      .nullable()
      .required("회사명을 입력해주세요"),
    companyNameEng: yup.string().nullable(),
    bizNo: yup
      .string()
      .nullable()
      .required("사업자등록번호를 입력해주세요"),
    ceoName: yup
      .string()
      .nullable()
      .required("대표자명을 입력해주세요"),
    bizType: yup.string().nullable(),
    bizItem: yup.string().nullable(),
    tel: yup.string().nullable(),
    hp: yup.string().nullable(),
    fax: yup.string().nullable(),
    email: yup.string().nullable(),
    bankCode: yup.string().nullable(),
    bankNumber: yup.string().nullable(),
    contactId: yup.string().nullable(),
    contactName: yup.string().nullable(),
    zipCode: yup.string().nullable(),
    address: yup.string().nullable(),
    addressDetail: yup.string().nullable(),
    remark: yup.string().nullable(),
  });

  const {
    control,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: initialState,
  });

  const openPost = () => {
    window.daum.postcode.load(() => {
      const postcode = new window.daum.Postcode({
        oncomplete: function(data) {
          let addr = "";
          let extraAddr = "";

          if (data.userSelectedType === "R") {
            addr = data.address;
          } else {
            addr = data.jibunAddress;
          }

          if (data.userSelectedType === "R") {
            if (data.bname !== "" && /[동|로|가]$/g.test(data.bname)) {
              extraAddr += data.bname;
            }
            if (data.buildingName !== "" && data.apartment === "Y") {
              extraAddr +=
                extraAddr !== "" ? ", " + data.buildingName : data.buildingName;
            }
            if (extraAddr !== "") {
              extraAddr = " (" + extraAddr + ")";
            }
            addr += extraAddr;
          }

          setValue("zipCode", data.zonecode);
          setValue("address", addr);
        },
      });
      postcode.open();
    });
  };

  const onSubmit = async (data) => {
    setIsLoading(true);
    try {
      if (oid) {
        // update
        await updateCompany(oid, data);
      } else {
        // create
        await createCompany(data);
      }

      dataGrid.current.instance.refresh();
      onClose();
    } catch (err) {
      console.log(err);
      alert("오류가 생겼습니다", "오류");
    } finally {
      setIsLoading(false);
    }
  };
  const onError = (err) => {
    console.log(err);
  };

  const getData = async (oid) => {
    try {
      const data = await getRowDataById(oid);
      let resetData = { ...data.data };
      for (const key in resetData) {
        if (resetData[key] === null) resetData[key] = "";
      }
      reset(resetData);
    } catch (err) {
      console.log(err);
    }
  };

  const getLookupData = async () => {
    try {
      const creditClassData = await getLookup("comcode/detail/20100");
      const custoDivisionData = await getLookup("comcode/detail/20200");
      const actStatusData = await getLookup("comcode/detail/20600");
      const companyData = await getLookup("company");
      const agencyData = await getLookup("comcode/detail/20500");
      const departmentData = await getLookup("department/lookup");
      const assignWorkerData = await getLookup("user-list/master");
      const kindBizData = await getLookup("comcode/detail/20700");
      const bankData = await getLookup("comcode/detail/60100");

      setLookupData({
        custoDivision: [
          { subCode: "", subName: "(선택)" },
          ...custoDivisionData.data,
        ],
        creditClass: [
          { subCode: null, subName: "없음" },
          ...creditClassData.data,
        ],
        actStatus: [
          { subCode: null, subName: "(선택)" },
          ...actStatusData.data,
        ],
        company: companyData.data,
        agency: [{ subCode: null, subName: "없음" }, ...agencyData.data],
        department: [{ code: "", deptName: "(전체)" }, ...departmentData.data],
        assignWorker: [
          { id: null, userName: "(미정)" },
          ...assignWorkerData.data,
        ],
        kindBiz: [{ subCode: null, subName: "(선택)" }, ...kindBizData.data],
        bank: [{ subCode: null, subName: "(선택)" }, ...bankData.data],
      });
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <ModalContainer
      onClose={onClose}
      maskClosable={maskClosable}
      visible={visible}
      size="xl"
    >
      <form onSubmit={handleSubmit(onSubmit, onError)} className="modal-form">
        <div className="modal-form-header">
          <div className="modal-form-tit">
            <span className="modal-tit">회사정보 관리</span>
          </div>
          <div className="d-flex flex-row">
            <button
              type="button"
              className="btn-modal btn-modal-close"
              onClick={onClose}
            >
              <SVG
                src={toAbsoluteUrl(
                  "/media/svg/icons/Custom/close_black_24dp.svg"
                )}
                className="close-icon"
              />
            </button>
          </div>
        </div>

        <div className="input-wrap">
          <div className="input-grid">
            <div className="dx-field">
              <InputText
                control={control}
                errors={errors}
                name="companyName"
                label="회사명"
                required={true}
                flex={true}
                onInitialized={(e) => {
                  setTimeout(() => {
                    e.component.focus();
                  }, 0);
                }}
              />
            </div>

            <div className="dx-field">
              <InputText
                control={control}
                errors={errors}
                name="companyNameEng"
                label="회사명 영문"
                flex={true}
              />
            </div>

            <div className="dx-field">
              <InputText
                control={control}
                errors={errors}
                name="bizNo"
                label="사업자등록번호"
                mask="999-99-99999"
                required={true}
                flex={true}
              />
            </div>

            <div className="dx-field">
              <InputText
                control={control}
                errors={errors}
                name="ceoName"
                label="대표자명"
                required={true}
                flex={true}
              />
            </div>
          </div>

          <div className="input-grid">
            <div className="dx-field">
              <InputText
                control={control}
                errors={errors}
                name="bizType"
                label="업태"
                flex={true}
              />
            </div>

            <div className="dx-field">
              <InputText
                control={control}
                errors={errors}
                name="bizItem"
                label="종목"
                flex={true}
              />
            </div>

            <div className="dx-field">
              <InputTel
                control={control}
                errors={errors}
                name="tel"
                label="전화번호"
                flex={true}
              />
            </div>

            <div className="dx-field">
              <InputTel
                control={control}
                errors={errors}
                name="hp"
                label="휴대전화"
                flex={true}
              />
            </div>
          </div>

          <div className="input-grid">
            <div className="dx-field">
              <InputText
                control={control}
                errors={errors}
                name="fax"
                label="팩스번호"
                flex={true}
              />
            </div>

            <div className="dx-field">
              <InputText
                control={control}
                errors={errors}
                name="email"
                label="이메일"
                flex={true}
              />
            </div>
            <div className="dx-field">
              <InputSelect
                control={control}
                errors={errors}
                name="bankCode"
                label="계좌종류"
                dataSource={lookupData.bank}
                valueExpr="subCode"
                displayExpr="subName"
                flex={true}
              />
            </div>
            <div className="dx-field">
              <InputText
                control={control}
                errors={errors}
                name="bankNumber"
                label="계좌번호"
                flex={true}
              />
            </div>
          </div>

          <div className="input-grid">
            <div className="dx-field">
              <InputText
                control={control}
                errors={errors}
                name="contactId"
                label="바로빌 아이디"
                flex={true}
              />
            </div>
            <div className="dx-field">
              <InputPassword
                control={control}
                errors={errors}
                name="contactPassword"
                label="바로빌 패스워드"
              />
            </div>
            <div className="dx-field">
              <InputText
                control={control}
                errors={errors}
                name="contactName"
                label="담당자 이름"
              />
            </div>
          </div>

          <div className="input-grid">
            <div className="dx-field postal-code">
              <InputText
                control={control}
                errors={errors}
                name="zipCode"
                label="우편번호"
                disabled={true}
              />
              <button
                type="button"
                className="btn btn-postal"
                onClick={openPost}
              >
                주소 검색
              </button>
            </div>

            <div className="dx-field">
              <InputText
                control={control}
                errors={errors}
                name="address"
                label="주소"
                disabled={true}
              />
            </div>
            <div className="dx-field">
              <InputText
                control={control}
                errors={errors}
                name="addressDetail"
                label="상세주소"
              />
            </div>
          </div>

          <div className="input-grid grid-1">
            <div className="dx-field">
              <InputTextArea
                control={control}
                errors={errors}
                name="remark"
                label="비고"
                flex={true}
              />
            </div>
          </div>
        </div>
        <div className="modal-form-footer order-modal">
          <button
            type="submit"
            className={`btn btn-modal btn-save  ${isLoading &&
              "spinner spinner-white spinner-right"}`}
            disabled={isLoading}
          >
            저장
          </button>
        </div>
      </form>
    </ModalContainer>
  );
};

export default NewCompanyModal;
