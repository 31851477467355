import React, { useCallback, useMemo, useState } from "react";
import { DataGrid } from "devextreme-react";
import {
  Column,
  ColumnChooser,
  FilterRow,
  HeaderFilter,
  StateStoring,
} from "devextreme-react/data-grid";
import CustomStore from "devextreme/data/custom_store";
import { sendRequest } from "../../../../_metronic/_helpers/DataSoureHelpers";
import { useForm } from "react-hook-form";
import StateStoringSaveAndDeleteButton from "../../../components/StateStoring";

function BillingMaster({ onFocusedRowChanged, dataGridRef }) {
  const [totalCount, setTotalCount] = useState(0);
  const [search, setSearch] = useState({});
  const storageKey = "billingMaster";

  const dataSource = useMemo(() => {
    const apiUrl = `${process.env.REACT_APP_API_URL}/billingplace`;

    return new CustomStore({
      key: "code",
      load: () => sendRequest(apiUrl, "GET", search),
      insert: (values) => sendRequest(apiUrl, "POST", values),
      update: (key, values) => sendRequest(apiUrl, "PUT", { key, values }),
      remove: (key) => sendRequest(apiUrl, "DELETE", { key }),
    });
  }, [search]);

  const { register, handleSubmit } = useForm();

  const onSubmit = (data) => {
    for (const obj in data) {
      if (data[obj] === "") {
        data[obj] = null;
      }
    }
    setSearch(data);
  };

  return (
    <>
      <div className="grid-search-wrap">
        <form onSubmit={handleSubmit(onSubmit)}>
          <span className="search-group">
            <label>회사명</label>
            <input type="text" {...register("companyName")} />
          </span>

          <button type="submit" className="btn btn-grid-search single-line">
            검색
          </button>
        </form>
      </div>
      <div className="grid-component has-toolbar">
        <StateStoringSaveAndDeleteButton
          dataGridRef={dataGridRef}
          storageKey={storageKey}
        />
        <div className="grid-total">{`총 ${totalCount}건`}</div>
        <DataGrid
          className="datagrid-wrap"
          id="BillingPlaceMaster"
          ref={dataGridRef}
          dataSource={dataSource}
          showRowLines={true}
          showBorders={true}
          hoverStateEnabled={true}
          allowColumnResizing={true}
          columnResizingMode="widget"
          keyExpr="code"
          focusedRowEnabled={true}
          columnAutoWidth={true}
          onFocusedRowChanged={onFocusedRowChanged}
          onContentReady={(e) => setTotalCount(e.component.totalCount())}
        >
          <FilterRow visible={true} />
          <HeaderFilter visible={true} />
          <ColumnChooser enabled={true} />
          <StateStoring
            enabled={true}
            type="custom"
            customLoad={useCallback(() => {
              return JSON.parse(localStorage.getItem(storageKey));
            }, [])}
          />
          <Column dataField="code" caption="업체코드" alignment="center" />
          <Column dataField="companyName" caption="회사명" />
          <Column dataField="Biz_No" caption="사업자번호" />
          <Column
            dataField="Biz_No_Sys_CompanyTax_Info.Ceo_Name"
            caption="대표자명"
          />
          <Column
            dataField="Biz_No_Sys_CompanyTax_Info.Zip_Code"
            caption="우편번호"
            alignment="center"
          />
          <Column
            dataField="Biz_No_Sys_CompanyTax_Info.Address"
            caption="주소"
          />
          <Column
            dataField="Biz_No_Sys_CompanyTax_Info.Address_Detail"
            caption="상세주소"
          />
          <Column
            dataField="Biz_No_Sys_CompanyTax_Info.Biz_Type"
            caption="업태"
            alignment="center"
          />
          <Column
            dataField="Biz_No_Sys_CompanyTax_Info.Biz_Item"
            caption="종목"
            alignment="center"
          />
          <Column
            dataField="Biz_No_Sys_CompanyTax_Info.Email"
            caption="메일주소"
          />
          <Column dataField="isShuttleDepot" caption="셔틀배차창고여부" />
          <Column dataField="isBilling" caption="청구처" />
          <Column dataField="isOrderCo" caption="발주처" />
          <Column dataField="isCargoOwner" caption="화주" />
          <Column dataField="remark" caption="비고" alignment="center" />
        </DataGrid>
      </div>
    </>
  );
}

export default BillingMaster;
