import {
  Column,
  ColumnChooser,
  DataGrid,
  FilterRow,
  Format,
  HeaderFilter,
  Lookup,
  MasterDetail,
  Scrolling,
  StateStoring,
} from "devextreme-react/data-grid";
import CustomStore from "devextreme/data/custom_store";
import moment from "moment";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useForm } from "react-hook-form";
import { shallowEqual, useSelector } from "react-redux";
import { sendRequest } from "../../../../_metronic/_helpers/DataSoureHelpers";

import CreateSimpleModal from "./components/CreateSimpleModal";
import * as Modals from "./components/SimpleDispatchModals";

import { alert, confirm } from "devextreme/ui/dialog";
import * as requestServer from "./SimpleDispatchCrud";
import SimpleDispatchDetail from "./SimpleDispatchDetail";

import CustomButton from "../../../components/FunctionButton/CustomButton";
import InputDate from "../../../components/InputForm/InputDate";
import InputSelect from "../../../components/InputForm/InputSelect";
import HeaderTitle from "../../../components/Layout/Header/HeaderTitle";
import {
  DataCount,
  PanelGridInit,
  PanelGridSearchBar,
  PanelGridWrapper,
  SearchItems,
} from "../../../components/Layout/Panel/PanelGrid/panelGrid.style";
import {
  PanelBody,
  PanelBtn,
  PanelHeader,
  PanelInit,
} from "../../../components/Layout/Panel/panel.style";
import StateStoringSaveAndDeleteButton from "../../../components/StateStoring";
import { useDatagrid } from "../../../hooks/useDatagrid";

function SimpleDispatchPage(props) {
  const [totalCount, setTotalCount] = useState(0);
  const [createOrderModalVisible, setCreateOrderModalVisible] = useState();
  const [updateOrderModalVisible, setUpdateOrderModalVisible] = useState();
  const [extraChargeModalVisible, setExtraChargeModalVisible] = useState();
  const [makeDispatchModalVisible, setMakeDispatchModalVisible] = useState();
  const [habulModalVisible, setHabulModalVisible] = useState();
  const [search, setSearch] = useState({});
  const [deptName, setDeptName] = useState();

  const storageKey = "simpleDispatchMaster";

  const dataGridRef = useRef();
  const detailGridRef = useRef();

  useDatagrid(dataGridRef);

  const [rowData, setRowData] = useState();
  const [detailRowData, setDetailRowData] = useState();
  const [focusedComponent, setFocusedComponent] = useState();
  const [lookupData, setLookupData] = useState({
    dispatch: [],
    company: [],
  });

  const { user } = useSelector(
    (state) => ({ user: state.auth.user.user }),
    shallowEqual
  );

  const dataSource = useMemo(() => {
    const apiUrl = `${process.env.REACT_APP_API_URL}/simpledispatch`;

    return new CustomStore({
      key: "code",
      load: () => sendRequest(apiUrl, "GET", search),
    });
  }, [search]);

  const initialState = {
    firstDay: moment(new Date())
      .subtract(30, "days")
      .format("YYYY-MM-DD"),
    lastDay: moment().format("YYYY-MM-DD"),
    isBobtail: 0,
    searchType: 1,
    receiveCo: user.companyCode,
    dispatchTeam: user?.deptCode || "",
  };

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    reset(initialState);
    setSearch(initialState);
    getLookup();
    // eslint-disable-next-line
  }, []);

  // 신규오더접수
  const createNewOrder = () => {
    setCreateOrderModalVisible(true);
  };

  // 수정
  const updateOrder = () => {
    if (!rowData) {
      alert("데이터를 선택해주세요", "오류");
      return;
    }
    setUpdateOrderModalVisible(true);
  };

  // 배차등록
  const makeDispatch = () => {
    if (!detailRowData) {
      alert("디테일데이터를 선택해주세요", "오류");
      return;
    }

    if (
      detailRowData.dispatchStatus !== "접수" &&
      detailRowData.dispatchStatus !== "배차등록"
    ) {
      alert("접수 상태에서만 배차등록이 가능합니다", "오류");
      return;
    }

    if (detailRowData.completeNo) {
      alert("정산된 데이터입니다", "오류");
      return;
    }

    setMakeDispatchModalVisible(true);
  };

  // 배차확정
  const confirmDispatch = async () => {
    if (!detailRowData) {
      alert("디테일데이터를 선택해주세요", "오류");
      return;
    }

    if (detailRowData.dispatchStatus !== "배차등록") {
      alert("배차등록 상태에서만 배차확정이 가능합니다", "오류");
      return;
    }

    const result = await confirm("배차를 확정하시겠습니까?", "확인");
    if (!result) return;

    try {
      await requestServer.confirmDispatch(detailRowData);

      dataGridRef.current.instance.refresh();
    } catch (err) {
      alert(err.response.data.message, "오류");
    }
  };

  // 운송완료
  const finishDelivery = async () => {
    if (!detailRowData) {
      alert("디테일데이터를 선택해주세요", "오류");
      return;
    }

    if (detailRowData.dispatchStatus !== "배차확정") {
      alert("배차확정 상태에서만 운송완료 가능합니다", "오류");
      return;
    }

    const result = await confirm("운송완료 하시겠습니까?", "확인");
    if (!result) return;

    try {
      await requestServer.deliverFinish(detailRowData);

      dataGridRef.current.instance.refresh();
    } catch (err) {
      alert(err.response.data.message, "오류");
    }
  };

  // 배차취소
  const cancelDispatch = async () => {
    if (!detailRowData) {
      alert("디테일데이터를 선택해주세요", "오류");
      return;
    }

    if (detailRowData.completeNo) {
      alert("정산된 데이터입니다", "오류");
      return;
    }

    if (detailRowData.dispatchStatus === 62) {
      alert("배차등록 되지않았습니다", "오류");
      return;
    }

    const result = await confirm("배차를 취소하시겠습니까?", "확인");
    if (!result) return;

    try {
      await requestServer.cancelDispatch(detailRowData);

      dataGridRef.current.instance.refresh();
    } catch (err) {
      alert(err.response.data.message, "오류");
    }
  };

  // 할증등록
  const extraCharge = () => {
    if (!rowData) {
      alert("데이터를 선택해주세요", "오류");
      return;
    }

    const detail = rowData.data;
    const dispatch = detail?.dispatches || [];

    if (detail.completeNo) {
      alert("정산된 데이터입니다", "오류");
      return;
    }

    for (const obj of dispatch) {
      if (obj.completeNo) {
        alert("정산된 데이터입니다", "오류");
        return;
      }
    }

    setExtraChargeModalVisible(true);
  };

  // 하불등록
  const setHabul = () => {
    if (!detailRowData) {
      alert("세부사항을 선택해주세요", "오류");
      return;
    }

    if (detailRowData.dispatchStatus === "접수") {
      alert("배차등록을 해주세요", "오류");
      return;
    }

    if (detailRowData.completeNo) {
      alert("정산된 데이터입니다", "오류");
      return;
    }

    setHabulModalVisible(true);
  };

  // 모달 닫기
  const closeModal = () => {
    setCreateOrderModalVisible(false);
    setUpdateOrderModalVisible(false);
    setExtraChargeModalVisible(false);
    setMakeDispatchModalVisible(false);
    setHabulModalVisible(false);

    dataGridRef.current.instance.option("focusedRowIndex", -1);
    setRowData(null);
    setDetailRowData(null);
  };

  const onSubmit = (data) => {
    for (const obj in data) {
      if (data[obj] === "") {
        data[obj] = null;
      }
    }
    setSearch(data);
  };

  // dataGrid 함수
  const onFocusedRowChanged = useCallback(
    (e) => {
      if (e.rowIndex === -1) return;
      console.log(e);
      // eslint-disable-next-line
      if (focusedComponent && focusedComponent != e.component) {
        focusedComponent.option("focusedRowIndex", -1);
        if (e.element.id === "SimpleDispatchMaster") {
          setDetailRowData(null);
        } else if (e.element.id === "SimpleDispatchDetail") {
          setRowData(null);
        }
      }
      setFocusedComponent(e.component);
    },
    [focusedComponent]
  );

  const importExportRender = useCallback((e) => {
    if (e.data.importExport === 1) {
      return <div>수입</div>;
    } else {
      return <div>수출</div>;
    }
  }, []);

  const onCellPrepared = useCallback((e) => {
    if (e.rowType !== "data") return;
    if (e.column.caption !== "상차지" && e.column.caption !== "하차지") return;

    if (e.column.caption === "상차지") {
      e.cellElement.style.background =
        e.data.loadPlace_terminalWarehouse?.color || "transparant";
    } else if (e.column.caption === "하차지") {
      e.cellElement.style.background =
        e.data.unloadPlace_terminalWarehouse?.color || "transparant";
    }
  }, []);

  const onCellClick = useCallback((e) => {
    if (!e.row) return;
    // detailGridRef.current && detailGridRef.current.instance.option("focusedRowIndex", -1);
    const row = e.row;
    if (row.rowType === "data") {
      // console.log(row)
      setRowData(row);
    }
  }, []);

  // Lookup데이터 불러오기
  async function getLookup() {
    try {
      const deptNameData = await requestServer.getLookup("department/lookup");
      const dispatchData = await requestServer.getLookup(
        "department/isDispatch"
      );
      const companyData = await requestServer.getLookup("company");

      setDeptName(deptNameData.data);
      setLookupData({
        dispatch: [{ code: "", deptName: "전체" }, ...dispatchData.data],
        company: [{ id: "", companyName: "전체" }, ...companyData.data],
      });
    } catch (err) {
      alert(err.response.data.message, "오류");
    }
  }

  return (
    <>
      <PanelInit>
        <PanelHeader>
          <HeaderTitle />
          <PanelBtn>
            <CustomButton
              type="button"
              height={24}
              layout="solid"
              color="new"
              label="신규오더 접수"
              onClick={createNewOrder}
            />
            <CustomButton
              type="button"
              height={24}
              layout="solid"
              color="new"
              label="할증등록"
              onClick={extraCharge}
            />
            <CustomButton
              type="button"
              height={24}
              layout="solid"
              color="new"
              label="하불등록"
              onClick={setHabul}
            />
            <CustomButton
              type="button"
              height={24}
              layout="solid"
              color="new"
              label="배차등록"
              onClick={makeDispatch}
            />
            <CustomButton
              type="button"
              height={24}
              layout="solid"
              color="common"
              label="수정"
              onClick={updateOrder}
            />

            <CustomButton
              type="button"
              height={24}
              layout="solid"
              color="common"
              label="배차확정"
              onClick={confirmDispatch}
            />
            <CustomButton
              type="button"
              height={24}
              layout="solid"
              color="common"
              label="운송완료"
              onClick={finishDelivery}
            />
            <CustomButton
              type="button"
              height={24}
              layout="solid"
              color="esc"
              label="배차취소"
              onClick={cancelDispatch}
            />
          </PanelBtn>
        </PanelHeader>
        <PanelBody row left="100%" right="0">
          <PanelGridWrapper>
            <PanelGridSearchBar onSubmit={handleSubmit(onSubmit)}>
              <SearchItems>
                <label>접수법인</label>
                <InputSelect
                  control={control}
                  errors={errors}
                  name="receiveCo"
                  dataSource={lookupData.company}
                  valueExpr="id"
                  displayExpr="companyName"
                  flex={true}
                />
              </SearchItems>
              <SearchItems>
                <label>배차팀</label>
                <InputSelect
                  control={control}
                  errors={errors}
                  displayExpr="deptName"
                  valueExpr="code"
                  name="dispatchTeam"
                  dataSource={lookupData.dispatch}
                  flex={true}
                />
              </SearchItems>
              <SearchItems>
                <label>일정검색</label>
                <InputSelect
                  className="label-select"
                  control={control}
                  errors={errors}
                  name="searchType"
                  displayExpr="name"
                  valueExpr="id"
                  dataSource={[
                    { id: 1, name: "접수일자" },
                    { id: 2, name: "배차일자" },
                  ]}
                  flex={true}
                />
                <InputDate
                  control={control}
                  errors={errors}
                  name="firstDay"
                  flex={true}
                />
                <div className="to-mark">~</div>
                <InputDate
                  control={control}
                  errors={errors}
                  name="lastDay"
                  flex={true}
                />
              </SearchItems>
              <SearchItems>
                <label>밥테일 여부</label>
                <InputSelect
                  className="label-select"
                  control={control}
                  errors={errors}
                  name="isBobtail"
                  valueExpr="id"
                  displayExpr="name"
                  dataSource={[
                    { id: 0, name: "전체" },
                    { id: 1, name: "밥테일" },
                    { id: 1, name: "밥테일제외" },
                  ]}
                  flex={true}
                />
              </SearchItems>
              <CustomButton
                type="submit"
                height={24}
                layout="solid"
                color="search"
                label="검색"
              />
            </PanelGridSearchBar>

            <PanelGridInit>
              <StateStoringSaveAndDeleteButton
                dataGridRef={dataGridRef}
                storageKey={storageKey}
              />
              <DataCount>{`총 ${totalCount}건`}</DataCount>
              <DataGrid
                id="SimpleDispatchMaster"
                className="datagrid-wrap"
                dataSource={dataSource}
                ref={dataGridRef}
                showRowLines={true}
                showBorders={true}
                hoverStateEnabled={true}
                allowColumnReordering={true}
                allowColumnResizing={true}
                columnResizingMode="widget"
                focusedRowEnabled={true}
                columnAutoWidth={true}
                onCellClick={onCellClick}
                onCellPrepared={onCellPrepared}
                onFocusedRowChanged={onFocusedRowChanged}
                onContentReady={(e) => setTotalCount(e.component.totalCount())}
              >
                <FilterRow visible={true} />
                <HeaderFilter visible={true} />
                <ColumnChooser enabled={true} />
                <StateStoring
                  enabled={true}
                  type="custom"
                  customLoad={useCallback(() => {
                    return JSON.parse(localStorage.getItem(storageKey));
                  }, [])}
                />
                <Scrolling
                  columnRenderingMode="virtual"
                  mode="virtual"
                  showScrollbar="always"
                  useNative="false"
                />
                <Column dataField="code" caption="접수번호" width="110" />
                <Column dataField="subName" caption="상태" alignment="center" />
                <Column
                  dataField="importExport"
                  caption="수입/수출"
                  cellRender={importExportRender}
                  alignment="center"
                  width="60"
                />
                <Column
                  dataField="receiveDate"
                  caption="접수일자"
                  alignment="center"
                  width="80"
                />
                <Column dataField="billingPlaceName" caption="청구처" />
                <Column
                  dataField="forwarder"
                  caption="선사"
                  alignment="center"
                />
                <Column
                  dataField="shipName"
                  caption="선명"
                  alignment="center"
                />
                <Column
                  dataField="motherShip"
                  caption="모선항차"
                  alignment="center"
                />
                <Column
                  dataField="dem"
                  caption="선사반출기한"
                  alignment="center"
                  width="80"
                />
                <Column
                  dataField="dispatchTeam"
                  caption="배차팀"
                  alignment="center"
                >
                  <Lookup
                    dataSource={deptName}
                    valueExpr="code"
                    displayExpr="deptName"
                  />
                </Column>
                <Column
                  dataField="bobtail"
                  caption="밥테일"
                  dataType="boolean"
                />
                <Column
                  dataField="fridge"
                  caption="냉동"
                  dataType="boolean"
                  width="35"
                />
                <Column
                  dataField="danger"
                  caption="위험물"
                  dataType="boolean"
                  width="45"
                />
                <Column
                  dataField="tank"
                  caption="탱크"
                  dataType="boolean"
                  width="35"
                />
                <Column
                  dataField="direct"
                  caption="직반입"
                  dataType="boolean"
                  width="45"
                />
                <Column dataField="blNo" caption="B/L No" alignment="center" />
                <Column dataField="doNo" caption="D/O No" alignment="center" />
                <Column dataField="bkNo" caption="B/K No" alignment="center" />
                <Column
                  dataField="loadPlace"
                  caption="상차지"
                  alignment="center"
                />
                {/* <Column
                dataField="orderDetails[0].workWaypoint_terminalWarehouse.twName"
                caption="작업경유지"
              /> */}
                <Column dataField="workPlace" caption="작업지" />
                {/* <Column
                dataField="orderDetails[0].collectWaypoint_terminalWarehouse.twName"
                caption="회수경유지"
              /> */}
                <Column
                  dataField="unloadPlace"
                  caption="하차지"
                  alignment="center"
                />
                <Column
                  dataField="workWaypoint"
                  caption="반납일자"
                  alignment="center"
                  dataType="date"
                  format="yyyy-MM-dd"
                />
                <Column dataField="cargoOwnerName" caption="화주" />
                <Column
                  dataField="containerNo"
                  caption="컨번호"
                  alignment="center"
                />
                <Column
                  dataField="sealNo"
                  caption="씰번호"
                  alignment="center"
                />
                <Column
                  dataField="freight"
                  caption="청구금액"
                  alignment="right"
                  dataType="number"
                >
                  <Format type="fixedPoint" />
                </Column>
                <Column
                  dataField="realHabul"
                  caption="실제하불금액"
                  alignment="right"
                  dataType="number"
                >
                  <Format type="fixedPoint" />
                </Column>
                <Column
                  dataField="extraCharge"
                  caption="할증금액"
                  alignment="right"
                  dataType="number"
                >
                  <Format type="fixedPoint" />
                </Column>
                <Column
                  dataField="confirm"
                  caption="오더확정"
                  width="55"
                  dataType="boolean"
                />
                <MasterDetail
                  enabled={true}
                  render={(e) => (
                    <SimpleDispatchDetail
                      data={e.data.id}
                      dataGridRef={dataGridRef}
                      setDetailRowData={setDetailRowData}
                      detailGridRef={detailGridRef}
                      onFocusedRowChanged={onFocusedRowChanged}
                    />
                  )}
                />
              </DataGrid>
            </PanelGridInit>
          </PanelGridWrapper>
        </PanelBody>
      </PanelInit>
      {createOrderModalVisible && (
        <CreateSimpleModal
          onClose={closeModal}
          closable={true}
          visible={true}
          maskClosable={false}
          dataGrid={dataGridRef}
        />
      )}
      {updateOrderModalVisible && (
        <CreateSimpleModal
          onClose={closeModal}
          closable={true}
          visible={true}
          maskClosable={false}
          dataGrid={dataGridRef}
          rowData={rowData}
        />
      )}
      {extraChargeModalVisible && (
        <Modals.ExtraChargeModal
          onClose={closeModal}
          closable={true}
          visible={true}
          maskClosable={false}
          rowData={rowData}
          dataGridRef={dataGridRef}
        />
      )}
      {makeDispatchModalVisible && (
        <Modals.MakeDispatch
          onClose={closeModal}
          closable={true}
          visible={true}
          maskClosable={false}
          detailRowData={detailRowData}
          dataGridRef={dataGridRef}
        />
      )}
      {habulModalVisible && (
        <Modals.HabulModal
          onClose={closeModal}
          closable={true}
          visible={true}
          maskClosable={false}
          detailRowData={detailRowData}
          dataGridRef={dataGridRef}
        />
      )}
    </>
  );
}

export default SimpleDispatchPage;
