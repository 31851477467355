import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import SVG from "react-inlinesvg";
import * as yup from "yup";
import * as requestServer from "./ShuttleDispatchReturnCrud";

import moment from "moment";

import { toAbsoluteUrl } from "../../../../_metronic/_helpers";

import InputDate from "../../../components/InputForm/InputDate";
import InputLookup from "../../../components/InputForm/InputLookup";
import InputNumber from "../../../components/InputForm/InputNumber";
import InputRadioGroup from "../../../components/InputForm/InputRadioGroup";

import { alert } from "devextreme/ui/dialog";
import InputText from "../../../components/InputForm/InputText";

export function MakeDispatch({
  onClose,
  maskClosable,
  closable,
  visible,
  detailRowData,
  dataGridRef,
}) {
  const [car, setCar] = useState();
  const [yongchasa, setYongchasa] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const schema = yup
    .object({
      deliverDate: yup.string(),
      carNo: yup.string(),
      yongchasa: yup.string(),
    })
    .required();

  const {
    control,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    function resetInput(detailRowData) {
      const toggle = detailRowData.yongchasa ? "2" : "1";
      reset({
        carNo: detailRowData.carNo || undefined,
        yongchasa: detailRowData.yongchasaCode || undefined,
        yongchaCarNo: detailRowData.yongchaCarNo || undefined,
        toggle,
        deliverDate: moment().format("YYYY-MM-DD") || undefined,
      });
    }
    async function getLookup() {
      try {
        const carData = await requestServer.getLookup("car/lookup");
        const yongchasaData = await requestServer.getLookup(
          "billingplace/isYongchasa"
        );

        setCar(carData.data);
        setYongchasa(yongchasaData.data);
      } catch (err) {
        console.log(err);
      }
    }

    if (detailRowData) resetInput(detailRowData);
    getLookup();
    // eslint-disable-next-line
  }, []);

  const onSubmit = async (data) => {
    if (!data.deliverDate) {
      alert("운송일자를 입력해주세요", "오류");
      return;
    }
    if (data.toggle === "1") {
      if (!data.carNo) {
        alert("차량번호를 입력해주세요", "오류");
        return;
      }
      data.yongchasa = null;
      data.yongchaCarNo = null;
    } else {
      if (!data.yongchasa) {
        alert("용차사를 선택해주세요", "오류");
        return;
      }
      data.carNo = null;
    }
    delete data.toggle;

    setIsLoading(true);
    try {
      await requestServer.changeCarNo(detailRowData.id, data);

      dataGridRef.current.instance.refresh();
      onClose();
    } catch (err) {
      alert(err.response.data.message, "오류");
    }
    setIsLoading(false);
  };

  const onMaskClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  return (
    <>
      <div className={"modal-overlay " + (visible ? "active" : "")} />
      <div
        className={"modal-wrap user-list-modal " + (visible ? "active" : "")}
        onClick={maskClosable ? onMaskClick : null}
        tabIndex="-1"
        visible={`${visible}`}
      >
        <Modal.Dialog centered tabIndex="0" size="sm" animation="true">
          <Modal.Body>
            <form onSubmit={handleSubmit(onSubmit)} className="modal-form">
              <div className="modal-form-header">
                <div className="modal-form-tit">
                  <span className="modal-tit">배차등록</span>
                </div>
                <button
                  type="button"
                  className="btn-modal btn-modal-close"
                  onClick={onClose}
                >
                  <SVG
                    src={toAbsoluteUrl(
                      "/media/svg/icons/Custom/close_black_24dp.svg"
                    )}
                    className="close-icon"
                  />
                </button>
              </div>
              <div className="modal-form-body">
                {/* <div className="d-flex flex-row mb-1">
                    <div className="dx-field">
                      <InputDate control={control} errors={errors} name="original" label="기존일자" flex={true} disable={true} />
                    </div>
                  </div> */}

                <div className="d-flex flex-row mb-1">
                  <div className="dx-field">
                    <InputDate
                      control={control}
                      errors={errors}
                      name="deliverDate"
                      label="운송일자"
                      flex={true}
                    />
                  </div>
                </div>

                <div className="d-flex flex-row mb-1">
                  <div className="dx-field">
                    <InputRadioGroup
                      control={control}
                      errors={errors}
                      flex={true}
                      name="toggle"
                      dataSource={[
                        { id: "1", name: "차량번호" },
                        { id: "2", name: "용차사" },
                      ]}
                    />
                  </div>
                </div>

                {watch("toggle") === "1" && (
                  <div className="d-flex flex-row mb-1">
                    <div className="dx-field">
                      <InputLookup
                        control={control}
                        errors={errors}
                        name="carNo"
                        label="차량번호"
                        flex={true}
                        dataSource={car}
                        valueExpr="code"
                        displayExpr="carNo_Name"
                        disable={watch("toggle") === "2"}
                        onInitialized={(e) => {
                          e.component.open();
                          setTimeout(() => {
                            e.component.focus();
                          }, 0);
                        }}
                      />
                    </div>
                  </div>
                )}

                {watch("toggle") === "2" && (
                  <>
                    <div className="d-flex flex-row mb-1">
                      <div className="dx-field">
                        <InputLookup
                          control={control}
                          errors={errors}
                          name="yongchasa"
                          label="용차사"
                          flex={true}
                          dataSource={yongchasa}
                          valueExpr="code"
                          displayExpr="companyName"
                          disable={watch("toggle") === "1"}
                          onInitialized={(e) => {
                            e.component.open();
                            setTimeout(() => {
                              e.component.focus();
                            }, 0);
                          }}
                        />
                      </div>
                    </div>
                    <div className="d-flex flex-row mb-1">
                      <div className="dx-field">
                        <InputText
                          control={control}
                          errors={errors}
                          name="yongchaCarNo"
                          label="차량번호"
                          flex={true}
                          disable={watch("toggle") === "1"}
                        />
                      </div>
                    </div>
                  </>
                )}
              </div>

              <div className="modal-form-footer">
                <button
                  type="submit"
                  disabled={isLoading}
                  className={`btn btn-modal btn-save ${isLoading &&
                    "spinner spinner-white spinner-right"}`}
                >
                  저장
                </button>
              </div>
            </form>
          </Modal.Body>
        </Modal.Dialog>
      </div>
    </>
  );
}

export function HabulModal({
  onClose,
  maskClosable,
  closable,
  visible,
  detailRowData,
  dataGridRef,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const schema = yup
    .object({
      ex: yup.string(),
    })
    .required();

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onMaskClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  useEffect(() => {
    setValue("habul", detailRowData.habul);
    // eslint-disable-next-line
  }, []);

  const onSubmit = async (data) => {
    setIsLoading(true);
    try {
      data.id = detailRowData.id;

      await requestServer.setHabul(data);

      dataGridRef.current.instance.refresh();
      onClose();
    } catch (err) {
      alert(err.response.data.message, "오류");
      return;
    }
    setIsLoading(false);
  };

  return (
    <>
      <div className={"modal-overlay " + (visible ? "active" : "")} />
      <div
        className={"modal-wrap user-list-modal " + (visible ? "active" : "")}
        onClick={maskClosable ? onMaskClick : null}
        tabIndex="-1"
        visible={`${visible}`}
      >
        <Modal.Dialog centered tabIndex="0" size="sm" animation="true">
          <Modal.Body>
            <form className="modal-form" onSubmit={handleSubmit(onSubmit)}>
              <div className="modal-form-header">
                <div className="modal-form-tit">
                  <span className="modal-tit">하불등록</span>
                </div>
                <button
                  type="button"
                  className="btn-modal btn-modal-close"
                  onClick={onClose}
                >
                  <SVG
                    src={toAbsoluteUrl(
                      "/media/svg/icons/Custom/close_black_24dp.svg"
                    )}
                    className="close-icon"
                  />
                </button>
              </div>
              <div className="modal-form-body">
                <div className="d-flex flex-row mb-1">
                  <div className="dx-field">
                    <InputNumber
                      control={control}
                      errors={errors}
                      name="habul"
                      label="하불금액"
                      flex={true}
                    />
                  </div>
                </div>
              </div>
              <div className="modal-form-footer">
                <button
                  type="submit"
                  disabled={isLoading}
                  className={`btn btn-modal btn-save  ${isLoading &&
                    "spinner spinner-white spinner-right"}`}
                >
                  저장
                </button>
              </div>
            </form>
          </Modal.Body>
        </Modal.Dialog>
      </div>
    </>
  );
}
