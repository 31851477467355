import React, { useMemo, useState } from "react";
import { DataGrid } from "devextreme-react";
import {
  Column,
  ColumnChooser,
  FilterRow,
  HeaderFilter,
  Scrolling,
} from "devextreme-react/data-grid";
import CustomStore from "devextreme/data/custom_store";
import { sendRequest } from "../../../../_metronic/_helpers/DataSoureHelpers";
import { useForm } from "react-hook-form";
import {
  DataCount,
  PanelGridInit,
  PanelGridSearchBar,
  PanelGridWrapper,
  SearchItems,
} from "../../../components/Layout/Panel/PanelGrid/panelGrid.style";
import CustomButton from "../../../components/FunctionButton/CustomButton";

function ChassisMaster({ onFocusedRowChanged, dataGridRef }) {
  const [totalCount, setTotalCount] = useState(0);
  const { register, handleSubmit } = useForm();
  const [search, setSearch] = useState({});

  const dataSource = useMemo(() => {
    const apiUrl = `${process.env.REACT_APP_API_URL}/chassis`;

    return new CustomStore({
      key: "chassisNo",
      load: () => sendRequest(apiUrl, "GET", search),
    });
  }, [search]);

  const onSubmit = (data) => {
    for (const obj in data) {
      if (data[obj] === "") {
        data[obj] = null;
      }
    }
    setSearch(data);
  };
  return (
    <>
      <PanelGridWrapper>
        <PanelGridSearchBar onSubmit={handleSubmit(onSubmit)}>
          <SearchItems>
            <label>샤시번호</label>
            <input type="text" {...register("chassisNo")} />
          </SearchItems>
          <CustomButton
            type="submit"
            height={24}
            layout="solid"
            color="search"
            label="검색"
          />
        </PanelGridSearchBar>

        <PanelGridInit>
          <DataCount>{`총 ${totalCount}건`}</DataCount>
          <DataGrid
            id="ChassisMaster"
            className="datagrid-wrap"
            dataSource={dataSource}
            ref={dataGridRef}
            showRowLines={true}
            showBorders={true}
            hoverStateEnabled={true}
            allowColumnResizing={true}
            // allowColumnReordering={true}
            columnResizingMode="widget"
            keyExpr="chassisNo"
            focusedRowEnabled={true}
            onFocusedRowChanged={onFocusedRowChanged}
            onContentReady={(e) => setTotalCount(e.component.totalCount())}
          >
            <FilterRow visible={true} />
            <HeaderFilter visible={true} />
            <ColumnChooser enabled={true} />
            <Scrolling mode="virtual" />
            <Column
              dataField="assignDivisonName"
              caption="소속구분"
              alignment="center"
            />
            <Column
              dataField="manageAssign"
              caption="소속"
              alignment="center"
            />
            <Column
              dataField="companyName"
              caption="관리소속"
              alignment="center"
            />
            <Column
              dataField="chassisNo"
              caption="샤시번호"
              alignment="center"
            />
            <Column
              dataField="chassisTypeName"
              caption="샤시종류"
              alignment="center"
            />
            <Column
              dataField="manufacturerName"
              caption="제조사"
              alignment="center"
            />
            <Column
              dataField="registerDate"
              caption="차량등록일"
              alignment="center"
            />
            <Column
              dataField="firRegisterDate"
              caption="최초등록일"
              alignment="center"
            />
            <Column dataField="length" caption="길이" alignment="center" />
            <Column dataField="width" caption="너비" alignment="center" />
            <Column dataField="height" caption="높이" alignment="center" />
            <Column dataField="ton" caption="중량" alignment="center" />
            <Column
              dataField="underframe"
              caption="차대번호"
              alignment="center"
            />
            <Column
              dataField="confrimDate"
              caption="검사일자"
              alignment="center"
            />
            <Column
              dataField="checkDate"
              caption="점검일자"
              alignment="center"
            />
            <Column
              dataField="actStatusName"
              caption="활동상태"
              alignment="center"
            />
            <Column
              dataField="editDate"
              caption="변경일자"
              alignment="center"
            />
            <Column
              dataField="assignWorker"
              caption="등록직원"
              alignment="center"
            />
            {/* conCodeDetail id */}
            {/* <Column
            dataField="chassisDivision_comCodeDetail.subName"
            caption="구분"
            alignment="center"
          /> */}
            {/* conCodeDetail id */}
            <Column dataField="remark" caption="비고" alignment="center" />
          </DataGrid>
        </PanelGridInit>
      </PanelGridWrapper>
    </>
  );
}

export default ChassisMaster;
