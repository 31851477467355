import { yupResolver } from "@hookform/resolvers/yup";
import { Button } from "devextreme-react/button";
import { alert, confirm } from "devextreme/ui/dialog";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { shallowEqual, useSelector } from "react-redux";
import * as yup from "yup";

import { useCallback } from "react";
import { SelectModal } from "../../../../_metronic/_partials/modals/SelectModal";
import CustomButton from "../../../components/FunctionButton/CustomButton";
import { ButtonGroup } from "../../../components/FunctionButton/CustomButton/customButton.style";
import InputDate from "../../../components/InputForm/InputDate";
import InputLookup from "../../../components/InputForm/InputLookup";
import InputNumber from "../../../components/InputForm/InputNumber";
import InputSelect from "../../../components/InputForm/InputSelect";
import InputText from "../../../components/InputForm/InputText";
import InputTextArea from "../../../components/InputForm/InputTextArea";
import HeaderTitle from "../../../components/Layout/Header/HeaderTitle";
import {
  PanelInput,
  PanelInputTitle,
  PanelInputWrapper,
} from "../../../components/Layout/Panel/PanelInput/panelInput.style";
import {
  PanelBody,
  PanelBtn,
  PanelHeader,
  PanelInit,
} from "../../../components/Layout/Panel/panel.style";
import * as requestServer from "./ShuttleSimpleDispatchCrud";
import ShuttleSimpleDispatchMaster from "./ShuttleSimpleDispatchMaster";

function ShuttleSimpleDispatchPage(props) {
  /* #region useState, useRef -----------------------------------------------------------------------*/
  const [selectedRowKey, setSelectedRowKey] = useState(null);
  const [selectModalVisible, setSelectModalVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [lookupData, setLookupData] = useState({
    company: [],
    billing: [],
    conType: [],
    terminal: [],
    workPlace: [],
    cargoOwner: [],
    car: [],
    user: [],
  });
  const dataGridRef = useRef();
  const conNoRef = useRef();

  /* #endregion */

  /* #region Redux --------------------------------------------------------------------------------- */

  const { user } = useSelector(
    (state) => ({ user: state.auth.user.user }),
    shallowEqual
  );
  /* #endregion */

  /* #region initialState, react-hook-form, yup ---------------------------------------------------- */
  const initialState = {
    code: "",
    receiveDate: moment().format("YYYY-MM-DD"),
    receiveCo: user.companyCode,
    cargoOwner: undefined,
    importExport: 1,
    billingPlace: undefined,
    containerType: undefined,
    containerNo: "",
    loadPlace: undefined,
    carNo_01: undefined,
    workPlace: undefined,
    carNo_02: undefined,
    unloadPlace: undefined,
    freight: 0,
    habul_01: 0,
    habul_02: 0,
    blNo: "",
    bkNo: "",
    doNo: "",
    manager: user?.id || undefined,
    motherShip: undefined,
    putDirect: undefined,
    putTime: undefined,
    sailDay: undefined,
    sailTime: undefined,
    remark: undefined,
  };

  const schema = yup
    .object({
      receiveDate: yup
        .string()
        .nullable()
        .required("접수일자를 선택해주세요"),
      receiveCo: yup
        .string()
        .nullable()
        .required("접수법인을 선택해주세요"),
      cargoOwner: yup
        .string()
        .nullable()
        .required("화주를 선택해주세요"),
      importExport: yup
        .string()
        .nullable()
        .required("수출입 구분을 선택해주세요"),
      billingPlace: yup
        .string()
        .nullable()
        .required("청구처를 선택해주세요"),
      containerType: yup
        .string()
        .nullable()
        .required("컨규격을 선택해주세요"),
      containerNo: yup
        .string()
        .nullable()
        .test(
          "containerNo",
          "컨테이너 번호가 올바르지 않습니다",
          validateContainerNo
        ),
      loadPlace: yup
        .string()
        .nullable()
        .required("상차지를 선택해주세요"),
      carNo_01: yup.string().nullable(),
      workPlace: yup.string().nullable(),
      carNo_02: yup.string().nullable(),
      unloadPlace: yup
        .string()
        .nullable()
        .required("하차지를 선택해주세요"),
      freight: yup
        .string()
        .nullable()
        .required("청구운임을 선택해주세요"),
      habul_01: yup.string().nullable(),
      habul_02: yup.string().nullable(),
      blNo: yup.string().nullable(),
      bkNo: yup.string().nullable(),
      doNo: yup.string().nullable(),
      manager: yup.string().nullable(),
      motherShip: yup.string().nullable(),
      putDirect: yup.string().nullable(),
      putTime: yup.string().nullable(),
      sailDay: yup.string().nullable(),
      sailTime: yup.string().nullable(),
      remark: yup.string().nullable(),
    })
    .required();

  const {
    watch,
    control,
    setValue,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  /* #endregion */

  /* #region useEffect ----------------------------------------------------------------------------- */
  useEffect(() => {
    getLookup();
    reset(initialState);
    // eslint-disable-next-line
  }, []);
  /* #endregion */

  /* #region 기타함수 ----------------------------------------------------------------------------- */

  const closeModal = () => {
    setSelectModalVisible(false);
    dataGridRef.current.instance.option("focusedRowIndex", -1);
  };

  const onSelect = (rowData) => {
    setValue("importExport", rowData.importExport);
    setValue("cargoOwner", rowData.cargoOwner);
    setValue("billingPlace", rowData.billingPlace);
    setValue("containerType", rowData.containerType);
    setValue("loadPlace", rowData.loadPlace);
    setValue("workPlace", rowData.workPlace);
    setValue("unloadPlace", rowData.unloadPlace);
    setValue("freight", rowData.freight);
    setValue("habul_01", rowData.habul_01);
    setValue("habul_02", rowData.habul_02);

    closeModal();

    setTimeout(() => {
      conNoRef.current.instance.focus();
    }, 0);
  };

  const onClickAdd = () => {
    reset(initialState);
    setSelectedRowKey(null);
    dataGridRef.current.instance.option("focusedRowIndex", -1);
  };

  const onClickDelete = async () => {
    if (!selectedRowKey) {
      alert("데이터를 선택해주세요", "오류");
      return;
    }

    let response = await confirm("<i>삭제하시겠습니까?</i>", "삭제 메세지");

    if (!response) return;
    try {
      setIsLoading(true);
      await requestServer.deleteRow(selectedRowKey);

      dataGridRef.current.instance.refresh();
      dataGridRef.current.instance.option("focusedRowIndex", -1);
      setSelectedRowKey(null);
      reset(initialState);
    } catch (err) {
      alert(err.response?.data?.message || "ERROR", "오류");
    } finally {
      setIsLoading(false);
    }
  };

  const onClickRework = () => {
    if (!selectedRowKey) {
      alert("데이터를 선택해주세요", "오류");
      return;
    }

    setValue("code", "");
    setValue("receiveDate", moment().format("YYYY-MM-DD"));

    setSelectedRowKey(null);
    dataGridRef.current.instance.option("focusedRowIndex", -1);
  };

  const onSubmit = async (data) => {
    try {
      setIsLoading(true);
      if (selectedRowKey) {
        await requestServer.updateShuttleSimple(selectedRowKey, data);
      } else {
        console.log(data);
        await requestServer.createShuttleSimple(data);
      }
      dataGridRef.current.instance.refresh();
      setSelectedRowKey(null);
      dataGridRef.current.instance.option("focusedRowIndex", -1);
      reset(initialState);
    } catch (err) {
      alert(err.response?.data?.message || "ERROR", "오류");
    } finally {
      setIsLoading(false);
    }
  };

  const onFocusedRowChanged = useCallback((e) => {
    if (!e.row) return;
    const rowData = {
      ...e.row.data,
      containerNo: e.row.data.containerNo ? e.row.data.containerNo : "",
      blNo: e.row.data.blNo ? e.row.data.blNo : "",
      bkNo: e.row.data.bkNo ? e.row.data.bkNo : "",
      doNo: e.row.data.doNo ? e.row.data.doNo : "",
      putTime: e.row.data.putTime ? e.row.data.putTime : "",
      sailTime: e.row.data.sailTime ? e.row.data.sailTime : "",
      motherShip: e.row.data.motherShip ? e.row.data.motherShip : "",
      remark: e.row.data.remark ? e.row.data.remark : "",
    };
    setSelectedRowKey(e.row.key);
    reset(rowData);
    setTimeout(() => {
      conNoRef.current.instance.focus();
    }, 0);
    // eslint-disable-next-line
  }, []);

  function validateContainerNo(conNo) {
    if (!conNo) return false;
    if (conNo.length !== 11) return false;
    const conRegex = /^[A-Z]{4}\d{7}$/;
    const result = conRegex.test(conNo);
    if (!result) return false;

    let sum = calculateCheckDigit(conNo);

    let mod = sum % 11;
    if (mod === 10) mod = 0;

    if (parseInt(conNo[conNo.length - 1]) !== mod) return false; // CheckDigit이 다르면 return

    return true;
  }

  function calculateCheckDigit(value) {
    let sum = 0;

    for (let i = 0; i < 10; i++) {
      if (value[i].charCodeAt(0) === 65) {
        // A일때
        let num = value[i].charCodeAt(0) - 55;

        sum += parseInt(num) * Math.pow(2, i);
      } else if (value[i].charCodeAt(0) > 65 && value[i].charCodeAt(0) <= 75) {
        // B-K일때
        let num = value[i].charCodeAt(0) - 54;

        sum += parseInt(num) * Math.pow(2, i);
      } else if (value[i].charCodeAt(0) > 75 && value[i].charCodeAt(0) <= 85) {
        // L-U일떄
        let num = value[i].charCodeAt(0) - 53;

        sum += parseInt(num) * Math.pow(2, i);
      } else if (value[i].charCodeAt(0) > 85 && value[i].charCodeAt(0) <= 90) {
        // V-Z일때
        let num = value[i].charCodeAt(0) - 52;

        sum += parseInt(num) * Math.pow(2, i);
      } else {
        // 숫자일때
        sum += parseInt(value[i]) * Math.pow(2, i);
      }
    }

    return sum;
  }

  async function getLookup() {
    try {
      const billingPlaceData = await requestServer.getLookup(
        "billingplace/isBilling"
      );
      const terminalData = await requestServer.getLookup("terminal/terminal");
      const conTypeData = await requestServer.getLookup("comcode/detail/10400");
      const workPlaceData = await requestServer.getLookup("terminal/workplace");
      const companyData = await requestServer.getLookup("company");
      const cargoOwnerData = await requestServer.getLookup(
        "billingplace/isCargoOwner"
      );
      const carData = await requestServer.getLookup("car/lookup");
      const userData = await requestServer.getLookup("user-list/master");

      setLookupData({
        company: companyData.data,
        billing: billingPlaceData.data,
        conType: conTypeData.data,
        terminal: terminalData.data,
        workPlace: workPlaceData.data,
        cargoOwner: cargoOwnerData.data,
        car: carData.data,
        user: userData.data,
      });
    } catch (err) {
      console.log(err);
    }
  }
  /* #endregion */

  return (
    <>
      <PanelInit>
        <PanelHeader>
          <HeaderTitle />
          <PanelBtn>
            <CustomButton
              type="button"
              height={24}
              layout="solid"
              color="new"
              label="신규"
              onClick={onClickAdd}
            />
            <CustomButton
              type="button"
              height={24}
              layout="solid"
              color="common"
              label="재작업"
              onClick={onClickRework}
            />
            <CustomButton
              type="button"
              height={24}
              layout="solid"
              color="esc"
              label="삭제"
              onClick={onClickDelete}
            />
          </PanelBtn>
        </PanelHeader>

        <PanelBody row left="400px" right="calc(100% - 400px)">
          <PanelInputWrapper
            onSubmit={handleSubmit(onSubmit)}
            openInputBar={true}
          >
            <PanelInputTitle>셔틀 등록</PanelInputTitle>
            <PanelInput grid={2}>
              <InputText
                control={control}
                errors={errors}
                name="code"
                label="접수번호"
                disabled={true}
              />
              <InputDate
                control={control}
                errors={errors}
                name="receiveDate"
                label="접수일자"
              />
              <InputLookup
                control={control}
                errors={errors}
                dataSource={lookupData.company}
                valueExpr="id"
                displayExpr="companyName"
                name="receiveCo"
                label="접수법인"
                required={true}
              />
              <InputLookup
                control={control}
                errors={errors}
                dataSource={lookupData.cargoOwner}
                valueExpr="code"
                displayExpr="companyName"
                name="cargoOwner"
                label="화주"
                required={true}
              />
              <Button
                onClick={() => setSelectModalVisible(true)}
                className="button-dis"
                icon="find"
              ></Button>
              <InputSelect
                control={control}
                errors={errors}
                name="importExport"
                label="수출/수입"
                dataSource={[
                  { id: 1, name: "수입" },
                  { id: 2, name: "수출" },
                ]}
                valueExpr="id"
                displayExpr="name"
              />
              <InputLookup
                control={control}
                errors={errors}
                name="billingPlace"
                label="청구처"
                dataSource={lookupData.billing}
                displayExpr="companyName"
                valueExpr="code"
                required={true}
              />
              <InputLookup
                control={control}
                errors={errors}
                name="containerType"
                label="컨규격"
                dataSource={lookupData.conType}
                valueExpr="subCode"
                displayExpr="subName"
                required={true}
              />
              <InputText
                control={control}
                errors={errors}
                name="containerNo"
                label="컨테이너No"
                refs={conNoRef}
                required={true}
              ></InputText>
              <InputLookup
                control={control}
                errors={errors}
                name="loadPlace"
                label="상차지"
                dataSource={lookupData.terminal}
                valueExpr="code"
                displayExpr="code"
                required={true}
              />
              <InputLookup
                control={control}
                errors={errors}
                name="carNo_01"
                label="배차차량"
                dataSource={lookupData.car}
                valueExpr="code"
                displayExpr="carNo_Name"
              />
              <InputLookup
                control={control}
                errors={errors}
                name="workPlace"
                label="작업지"
                dataSource={lookupData.workPlace}
                valueExpr="code"
                displayExpr="twName"
              />
              <InputLookup
                control={control}
                errors={errors}
                name="carNo_02"
                label="배차차량"
                dataSource={lookupData.car}
                valueExpr="code"
                displayExpr="carNo_Name"
                disable={
                  watch("workPlace") === "" || watch("workPlace") === undefined
                }
              />
              <InputLookup
                control={control}
                errors={errors}
                name="unloadPlace"
                label="하차지"
                dataSource={lookupData.terminal}
                valueExpr="code"
                displayExpr="code"
                required={true}
              />
              <InputNumber
                control={control}
                errors={errors}
                name="freight"
                label="청구금액"
                required={true}
              />
              <InputNumber
                control={control}
                errors={errors}
                name="habul_01"
                label="상차지 하불금액"
              />
              <InputNumber
                control={control}
                errors={errors}
                name="habul_02"
                label="하차지 하불금액"
                disabled={
                  watch("workPlace") === "" || watch("workPlace") === undefined
                }
              />
              {watch("importExport") === 1 && (
                <InputText
                  control={control}
                  errors={errors}
                  name="blNo"
                  label="BL NO"
                />
              )}
              {watch("importExport") === 2 && (
                <InputText
                  control={control}
                  errors={errors}
                  name="bkNo"
                  label="BK NO"
                />
              )}
              <InputLookup
                control={control}
                errors={errors}
                name="manager"
                label="담당자"
                dataSource={lookupData.user}
                valueExpr="id"
                displayExpr="userName"
              />

              {watch("importExport") === 1 && (
                <InputText
                  control={control}
                  errors={errors}
                  name="doNo"
                  label="DO NO"
                />
              )}

              {watch("importExport") === 2 && (
                <InputText
                  control={control}
                  errors={errors}
                  name="motherShip"
                  label="모선항차"
                />
              )}
              {watch("importExport") === 2 && (
                <>
                  <InputDate
                    control={control}
                    errors={errors}
                    name="sailDay"
                    label="출항일"
                  />
                  <InputText
                    control={control}
                    errors={errors}
                    mask={"00:00:00"}
                    name="sailTime"
                    label="출항시간"
                  />
                </>
              )}
              {watch("importExport") === 2 && (
                <>
                  <InputDate
                    control={control}
                    errors={errors}
                    name="putDirect"
                    label="접안일"
                  />
                  <InputText
                    control={control}
                    errors={errors}
                    mask={"00:00:00"}
                    name="putTime"
                    label="접안시간"
                  />
                </>
              )}

              <InputTextArea
                control={control}
                errors={errors}
                name="remark"
                label="비고"
              />
            </PanelInput>
            <ButtonGroup marginTop={20}>
              <CustomButton
                type="submit"
                width="100%"
                height={36}
                layout="solid"
                color="common"
                label="저장"
                disabled={isLoading}
              />
            </ButtonGroup>
          </PanelInputWrapper>
          <ShuttleSimpleDispatchMaster
            onFocusedRowChanged={onFocusedRowChanged}
            dataGridRef={dataGridRef}
          />
        </PanelBody>
      </PanelInit>
      {selectModalVisible && (
        <SelectModal
          onClose={closeModal}
          closable={true}
          visible={true}
          maskClosable={false}
          dataGrid={dataGridRef}
          onSelect={onSelect}
          param="/shuttlebasic"
          keyExpr="Oid"
        />
      )}
    </>
  );
}

export default ShuttleSimpleDispatchPage;
