import React, { useCallback } from "react";
import CustomButton from "../../../../components/FunctionButton/CustomButton";
import { ButtonGroup } from "../../../../components/FunctionButton/CustomButton/customButton.style";
import {
  InputCheckBox,
  InputColorSelectBox,
  InputDate,
  InputDateTimePart,
  InputRadioGroup,
  InputSelect,
  InputText,
  InputTextArea,
} from "../../../../components/InputForm";
import InputLookup from "../../../../components/InputForm/InputLookup";
import InputNumber from "../../../../components/InputForm/InputNumber";
import {
  InputFullRow,
  PanelInput,
  PanelInputTitle,
  PanelInputWrapper,
} from "../../../../components/Layout/Panel/PanelInput/panelInput.style";
import {
  CompanySelect,
  ForwarderSelect,
  TerminalSelect,
} from "../../../../components/OrderModalSelects";

const SimpleSellFinishInput = ({
  state,
  openInputBar,
  inputForm,
  inputFunctions,
  isOrderEditable,
  isDisEditable,
}) => {
  const {
    handleSubmit,
    control,
    formState: { errors },
    watch,
    setValue,
  } = inputForm;

  const { isLoading, isConfirm, lookupData, otherCo, importExport } = state;

  const {
    onInputSubmit,
    getUnipassInfos,
    onImportExportValueChanged,
    openModal,
  } = inputFunctions;

  const onRemarkChange = useCallback((e) => {
    const data = watch();
    const remark = data.remark;

    const formattedValue = remark.replace(/\\/g, "");
    setValue("remark", formattedValue);
  }, []);
  return (
    <>
      <PanelInputWrapper
        onSubmit={handleSubmit(onInputSubmit)}
        openInputBar={openInputBar}
      >
        <PanelInputTitle>간편정산</PanelInputTitle>
        <PanelInput grid={2}>
          <InputText
            control={control}
            errors={errors}
            name="code"
            label="접수번호"
            disabled={true}
          />
          <InputSelect
            control={control}
            errors={errors}
            name="importExport"
            label="수출/수입"
            dataSource={[
              { id: 1, name: "수입" },
              { id: 2, name: "수출" },
            ]}
            valueExpr="id"
            displayExpr="name"
            onValueChanged={onImportExportValueChanged}
            disable={isOrderEditable}
          />
          <InputLookup
            control={control}
            errors={errors}
            dataSource={lookupData.company}
            valueExpr="id"
            displayExpr="companyName"
            name="receiveCo"
            label="접수법인"
            required={true}
            disable={isOrderEditable}
          />
          <InputDate
            control={control}
            errors={errors}
            name="receiveDate"
            label="접수일자"
            disable={isOrderEditable}
          />
          <InputSelect
            searchEnabled={true}
            control={control}
            errors={errors}
            dataSource={lookupData.dispatchName}
            displayExpr="deptName"
            valueExpr="code"
            name="dispatchTeam"
            label="배차팀"
            flex={true}
            disable={isOrderEditable}
          />
          <CompanySelect
            control={control}
            errors={errors}
            name="billingPlace"
            label="청구처"
            dataSource={lookupData.billing}
            onClick={() => {
              openModal("company", true, "isBilling");
            }}
            disable={isOrderEditable}
          />
          <CompanySelect
            control={control}
            errors={errors}
            name="orderCo"
            label="발주처"
            dataSource={lookupData.orderCo}
            onClick={() => {
              openModal("company", true, "isOrderCo");
            }}
            disable={isOrderEditable}
          />
          <ForwarderSelect
            control={control}
            errors={errors}
            name="forwarder"
            label="선사"
            dataSource={lookupData.forwarder}
            onClick={() => {
              openModal("forwarder", true);
            }}
            disable={isOrderEditable}
          />
          <InputText
            control={control}
            errors={errors}
            name="shipName"
            label="선명"
            flex={true}
            disabled={isOrderEditable}
          />
          <InputText
            control={control}
            errors={errors}
            name="motherShip"
            label="모선/항차"
            flex={true}
            disabled={isOrderEditable}
          />
          {(importExport === 1 || importExport === undefined) && (
            <>
              <InputDate
                control={control}
                errors={errors}
                name="dem"
                label="선사반출기한"
                flex={true}
                disable={isOrderEditable}
              />
            </>
          )}
          {importExport === 2 && (
            <>
              <div />
            </>
          )}
          <InputText
            control={control}
            errors={errors}
            name="manager"
            label="청구처담당자"
            flex={true}
            disabled={isOrderEditable}
          />
          <InputDateTimePart
            name="enterDay"
            control={control}
            errors={errors}
            label="입항일자"
            flex={true}
            disable={isOrderEditable}
          />
          <InputDateTimePart
            name="sailDay"
            control={control}
            errors={errors}
            label="출항일자"
            flex={true}
            disable={isOrderEditable}
          />
          <InputText
            name="containerNo"
            control={control}
            errors={errors}
            label="컨테이너번호"
            flex={true}
            disabled={isOrderEditable}
          />
          <InputSelect
            control={control}
            errors={errors}
            dataSource={lookupData.conType}
            name="containerType"
            label="컨/규격"
            valueExpr="subCode"
            displayExpr="subName"
            flex={true}
            disable={isOrderEditable}
          />

          <InputRadioGroup
            control={control}
            errors={errors}
            name="containerStatus01"
            label="컨테이너 상태"
            dataSource={[
              { id: 1, name: "Empty" },
              { id: 2, name: "Full" },
            ]}
            flex={true}
            disable={isOrderEditable}
          />
          <InputRadioGroup
            control={control}
            errors={errors}
            name="containerStatus02"
            dataSource={[
              { id: 1, name: "FCL" },
              { id: 2, name: "LCL" },
            ]}
            flex={true}
            disable={isOrderEditable}
          />
          {(importExport === 1 || importExport === undefined) && (
            <>
              <InputText
                control={control}
                errors={errors}
                name="blNo"
                label="B/L No"
                flex={true}
                disabled={isOrderEditable}
              />
              <div />
              <InputRadioGroup
                control={control}
                errors={errors}
                name="blType"
                dataSource={[
                  { id: 1, name: "M" },
                  { id: 2, name: "H" },
                ]}
                label="B/L 구분"
                flex={true}
                disable={isOrderEditable}
              />
              <CustomButton
                type="button"
                width="full"
                height={30}
                layout="line"
                color="common"
                label="UNI-PASS 연동"
                isLoading={isLoading}
                className={`
                ${isLoading && "spinner spinner-white spinner-right"}
                `}
                onClick={() => getUnipassInfos()}
                disabled={isLoading || isConfirm || isOrderEditable}
              />
              {/* </InputFullRow> */}
            </>
          )}

          {importExport === 2 && (
            <>
              <InputText
                control={control}
                errors={errors}
                name="bkNo"
                label="B/K No"
                flex={true}
                disable={isOrderEditable}
              />
            </>
          )}
          <InputText
            control={control}
            errors={errors}
            name="loadPlaceName"
            label="상차지텍스트"
            flex={true}
            disabled={isOrderEditable}
          />
          <InputText
            control={control}
            errors={errors}
            name="unloadPlaceName"
            label="하차지텍스트"
            flex={true}
            disabled={isOrderEditable}
          />
          <TerminalSelect
            control={control}
            errors={errors}
            name="loadPlace"
            label="상차지"
            dataSource={lookupData.terminal}
            onClick={() => {
              openModal("terminal", true);
            }}
            disable={isConfirm || isOrderEditable}
          />
          <TerminalSelect
            control={control}
            errors={errors}
            name="unloadPlace"
            label="하차지"
            dataSource={lookupData.terminal}
            onClick={() => {
              openModal("terminal", true);
            }}
            disable={isConfirm || isOrderEditable}
          />

          <CompanySelect
            control={control}
            errors={errors}
            name="cargoOwner"
            label="화주"
            dataSource={lookupData.cargoOwner}
            onClick={() => {
              openModal("company", true, "isCargoOwner");
            }}
            disable={isOrderEditable}
          />
          <InputText
            control={control}
            errors={errors}
            name="realCargoOwner"
            label="실화주"
            flex={true}
            disabled={isOrderEditable}
          />
          <InputSelect
            control={control}
            errors={errors}
            dataSource={otherCo}
            name="otherDeliver"
            label="타법인 배차"
            valueExpr="id"
            displayExpr="companyName"
            flex={true}
          />
          <InputColorSelectBox
            control={control}
            errors={errors}
            dataSource={lookupData.color}
            label="색상"
            name="color"
            valueExpr="subCode"
            displayExpr="subName"
            flex={true}
            disable={isOrderEditable}
          />
          <InputFullRow grid={2}>
            <InputCheckBox
              control={control}
              errors={errors}
              name="fridge"
              text="냉동"
              flex={true}
              disabled={isOrderEditable}
            />
            <InputCheckBox
              control={control}
              errors={errors}
              name="danger"
              text="위험물"
              flex={true}
              disabled={isOrderEditable}
            />
            <InputCheckBox
              control={control}
              errors={errors}
              name="tank"
              text="탱크"
              flex={true}
              disabled={isOrderEditable}
            />
            <InputCheckBox
              control={control}
              errors={errors}
              name="direct"
              text="직반입"
              flex={true}
              disabled={isOrderEditable}
            />
            <InputCheckBox
              control={control}
              errors={errors}
              name="toxic"
              text="유해화학물질"
              flex={true}
              disabled={isOrderEditable}
            />
            <InputCheckBox
              control={control}
              errors={errors}
              name="keep"
              text="보관"
              flex={true}
              disabled={isOrderEditable}
            />
          </InputFullRow>
          <InputFullRow grid={2}>
            <InputTextArea
              control={control}
              errors={errors}
              name="remark"
              label="비고"
              flex={true}
              onValueChange={onRemarkChange}
              disabled={isOrderEditable}
            />
          </InputFullRow>
          <InputFullRow grid={2}>
            <InputTextArea
              control={control}
              errors={errors}
              name="innerMemo"
              label="내부메모"
              flex={true}
              disabled={isOrderEditable}
            />
          </InputFullRow>

          <InputNumber
            control={control}
            errors={errors}
            name="amount"
            label="수량"
            disabled={isOrderEditable}
          />
          <InputSelect
            control={control}
            errors={errors}
            dataSource={lookupData.amountUnit}
            name="amountUnit"
            valueExpr="subCode"
            displayExpr="subName"
            flex={true}
            disable={isOrderEditable}
          />
          <InputText
            control={control}
            errors={errors}
            name="cargoWeight"
            label="중량"
            disabled={isOrderEditable}
          />
          <InputText
            control={control}
            errors={errors}
            name="spec"
            label="규격"
            disabled={isOrderEditable}
          />
          <InputNumber
            control={control}
            errors={errors}
            name="freight"
            label="청구금액"
            disabled={isOrderEditable}
          />
          <InputRadioGroup
            control={control}
            errors={errors}
            name="carTypeCode"
            dataSource={[
              { key: "DISCARTYPE-01", value: "차량번호" },
              { key: "DISCARTYPE-02", value: "용차사" },
            ]}
            valueExpr="key"
            displayExpr="value"
            disable={isDisEditable}
          />
          <InputNumber
            control={control}
            errors={errors}
            name="habul"
            label="하불금액"
            disabled={isDisEditable}
          />

          {watch("carTypeCode") === "DISCARTYPE-01" && (
            <>
              <InputLookup
                control={control}
                errors={errors}
                name="carNo"
                label="배차차량"
                dataSource={lookupData.car}
                valueExpr="oid"
                displayExpr="carNo_Name"
                disable={isDisEditable}
              />
              <div />
            </>
          )}
          {watch("carTypeCode") === "DISCARTYPE-02" && (
            <>
              <InputLookup
                control={control}
                errors={errors}
                name="yongchasa"
                label="용차사"
                dataSource={lookupData.yongchasa}
                valueExpr="oid"
                displayExpr="company"
                disable={isDisEditable}
              />
              <InputText
                control={control}
                errors={errors}
                name="yongchaCarNo"
                label="차량번호"
                disabled={isDisEditable}
              />
            </>
          )}
          <InputText
            control={control}
            errors={errors}
            name="pointManager"
            label="도착지담당자"
            disabled={isOrderEditable}
          />
          <InputText
            control={control}
            errors={errors}
            name="pointManageContact"
            label="도착지연락처"
            disabled={isOrderEditable}
          />
        </PanelInput>
        <ButtonGroup marginTop={20}>
          <CustomButton
            type="submit"
            width="100%"
            height={36}
            layout="solid"
            color="common"
            label="저장"
            className={`${isLoading && "spinner spinner-white spinner-right"}`}
            disabled={isLoading}
          />
        </ButtonGroup>
      </PanelInputWrapper>
    </>
  );
};

export default SimpleSellFinishInput;
