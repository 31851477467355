import React from "react";
import CustomButton from "../../../../components/FunctionButton/CustomButton";
import { ButtonGroup } from "../../../../components/FunctionButton/CustomButton/customButton.style";
import {
  InputMonth,
  InputNumber,
  InputSelect,
  InputText,
} from "../../../../components/InputForm";
import {
  InputDivide,
  InputFullRow,
  PanelInput,
  PanelInputTitle,
  PanelInputWrapper,
} from "../../../../components/Layout/Panel/PanelInput/panelInput.style";
import CarDeadlineInputGrid from "./CarDeadlineInputGrid";

const CarDeadlineInput = ({
  state,
  inputGridRef,
  inputEtcGridRef,
  inputForm,
  inputFunctions,
}) => {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = inputForm;

  const { lookupData, isUpdate, isLoading } = state;

  const { onSubmit, onComCarOidValueChanged } = inputFunctions;

  return (
    <>
      <PanelInputWrapper onSubmit={handleSubmit(onSubmit)} openInputBar={true}>
        <PanelInputTitle>차량하불마감</PanelInputTitle>
        <PanelInput grid={2}>
          <InputSelect
            searchEnabled={true}
            control={control}
            errors={errors}
            name="receiveCo"
            label="접수법인"
            flex={true}
            dataSource={lookupData.company}
            valueExpr="id"
            displayExpr="companyName"
          />
          <div />
          <InputMonth
            control={control}
            errors={errors}
            name="carDeadlineDate"
            label="하불마감일자"
            // onValueChanged={onComCarOidValueChanged}
            disable={isUpdate}
          />
          <InputSelect
            control={control}
            errors={errors}
            name="comCarOid"
            label="차량번호"
            dataSource={lookupData.car}
            valueExpr="oid"
            displayExpr="carNo_Name"
            onValueChanged={onComCarOidValueChanged}
            disable={isUpdate}
          />
          <InputText
            control={control}
            errors={errors}
            name="bizName"
            label="사업자상호"
            disabled={true}
          />
          <InputText
            control={control}
            errors={errors}
            name="ceoName"
            label="대표자"
            disabled={true}
          />
          <InputSelect
            control={control}
            errors={errors}
            name="carDivision"
            label="차량구분"
            dataSource={lookupData.carDivision}
            valueExpr="subCode"
            displayExpr="subName"
            flex={true}
            disable={true}
          />
          <InputNumber
            control={control}
            errors={errors}
            name="carCommission"
            label="수수료"
            disabled={true}
          />
        </PanelInput>
        <InputFullRow grid={2}>
          <InputDivide />
        </InputFullRow>

        <PanelInputTitle>운송료</PanelInputTitle>

        <PanelInput grid={2}>
          <InputNumber
            control={control}
            errors={errors}
            name="money01"
            label="공급가액"
            disabled={true}
          />
          <InputNumber
            control={control}
            errors={errors}
            name="money02"
            label="세액"
            disabled={true}
          />
          <InputNumber
            control={control}
            errors={errors}
            name="money03"
            label="소계"
            disabled={true}
          />
        </PanelInput>
        <InputFullRow grid={2}>
          <InputDivide />
        </InputFullRow>
        <PanelInputTitle>수수료 기타공제</PanelInputTitle>

        <PanelInput grid={2}>
          <InputNumber
            control={control}
            errors={errors}
            name="money04"
            label="공급가액"
            disabled={true}
          />
          <InputNumber
            control={control}
            errors={errors}
            name="money05"
            label="세액"
            disabled={true}
          />
          <InputNumber
            control={control}
            errors={errors}
            name="money06"
            label="소계"
            disabled={true}
          />
          <InputNumber
            control={control}
            errors={errors}
            name="money07"
            label="기타공제합계"
            disabled={true}
          />
          <InputNumber
            control={control}
            errors={errors}
            name="money08"
            label="기타합계"
            disabled={true}
          />
        </PanelInput>
        <InputFullRow grid={2}>
          <InputDivide />
        </InputFullRow>
        <PanelInputTitle>결산</PanelInputTitle>

        <PanelInput grid={2}>
          <InputNumber
            control={control}
            errors={errors}
            name="money09"
            label="지출계"
            disabled={true}
          />
          <InputNumber
            control={control}
            errors={errors}
            name="money10"
            label="미지급금"
            disabled={true}
          />
        </PanelInput>

        <ButtonGroup marginTop={20}>
          <CustomButton
            type="submit"
            width="100%"
            height={36}
            layout="solid"
            color="common"
            label="저장"
            className={`${isLoading && "spinner spinner-white spinner-right"}`}
            disabled={isLoading}
          />
        </ButtonGroup>

        <InputFullRow grid={2}>
          <InputDivide />
        </InputFullRow>
        <PanelInputTitle>상세정보</PanelInputTitle>

        <CarDeadlineInputGrid
          state={state}
          inputGridRef={inputGridRef}
          inputEtcGridRef={inputEtcGridRef}
          inputFunctions={inputFunctions}
        />
      </PanelInputWrapper>
    </>
  );
};

export default CarDeadlineInput;
