import axios from "axios";

const apiUrl = `${process.env.REACT_APP_API_URL}`;
const apiUrlCarClosing = `${process.env.REACT_APP_API_URL}/car-closing`;
const carDeadlineApiUrl = `${process.env.REACT_APP_API_URL}/car-deadline`;
const excelExportUrl = `${process.env.REACT_APP_API_URL}/excel-export`;

export function getLookup(param) {
  return axios.get(apiUrl + `/${param}`);
}

export function getIssuedData(param) {
  return axios.get(apiUrlCarClosing + `/issue/${param}`);
}

export function getNotIssuedData(param) {
  return axios.get(apiUrlCarClosing + `/not-issue/${param}`);
}

export function getIssuedDriverData(param) {
  return axios.get(apiUrlCarClosing + `/opposite/${param}`);
}

export function getDriverFee(data) {
  return axios.get(carDeadlineApiUrl + `/driver`, { params: data });
}

export function createTaxIssue(data) {
  return axios.post(apiUrlCarClosing + "/issue", data);
}

export function editingIssue(data) {
  return axios.post(apiUrlCarClosing + "/edit", data);
}

export function closingExport(date, cominfor, column) {
  return axios.post(excelExportUrl + "/closing", {
    date,
    cominfor,
    column,
  });
}

export function exportExcelRowsData(data, userName) {
  return axios.post(excelExportUrl + "/selectedRowsData", {
    data,
    userName,
  });
}
