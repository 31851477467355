import React from "react";
import CustomButton from "./CustomButton";

function FunctionButton({
  onClickRead,
  onClickAdd,
  onClickSave,
  onClickDelete,
}) {
  return (
    <>
      {onClickAdd && (
        <CustomButton
          type="button"
          height={24}
          layout="solid"
          color="new"
          label="신규"
          onClick={onClickAdd}
        />
      )}
      {onClickSave && (
        <CustomButton
          type="button"
          height={24}
          layout="solid"
          color="common"
          label="저장"
          onClick={onClickSave}
        />
      )}
      {onClickRead && (
        <CustomButton
          type="button"
          height={24}
          layout="solid"
          color="common"
          label="조회"
          onClick={onClickRead}
        />
      )}
      {onClickDelete && (
        <CustomButton
          type="button"
          height={24}
          layout="solid"
          color="esc"
          label="삭제"
          onClick={onClickDelete}
        />
      )}
    </>
  );
}

export default FunctionButton;
