import React from "react";
import CustomButton from "../../../components/FunctionButton/CustomButton";
import HeaderTitle from "../../../components/Layout/Header/HeaderTitle";
import {
  PanelBody,
  PanelBtn,
  PanelHeader,
  PanelInit,
} from "../../../components/Layout/Panel/panel.style";
import NewCompanyModal from "../../Management/ComCompany/components/NewCompanyModal";
import NewForwarderModal from "../../Management/Forwarder/components/NewForwarderModal";
import NewTerminalModal from "../../Management/Terminal/components/NewTerminalModal";
import SimpleSellFinishInput from "./components/SimpleSellFinishInput";
import SimpleSellFinishMaster from "./components/SimpleSellFinishMaster";
import useSimpleSellFinish from "./useSimpleSellFinish";

const SimpleSellFinishPage = () => {
  const {
    state,
    dataGridRef,
    searchForm,
    inputForm,

    // page
    pageFunctions,

    // input
    inputFunctions,

    // master
    masterFunctions,

    // 디테일, 하불 마감 state
    isOrderEditable,
    isDisEditable,
  } = useSimpleSellFinish();

  const {
    openInputBar,
    openTerminalCreateModal,
    openCompanyCreateModal,
    openForwarderCreateModal,
    type,
  } = state;

  const {
    initLookupData,
    onClickAdd,
    onClickCopy,
    onClickDelete,
  } = pageFunctions;

  const { openModal } = inputFunctions;
  return (
    <>
      <PanelInit>
        <PanelHeader>
          <HeaderTitle />
          <PanelBtn>
            <CustomButton
              type="button"
              height={24}
              layout="solid"
              color="new"
              label="신규"
              onClick={onClickAdd}
            />
            <CustomButton
              type="button"
              height={24}
              layout="solid"
              color="new"
              label="복사"
              onClick={onClickCopy}
            />

            <CustomButton
              type="button"
              height={24}
              layout="solid"
              color="esc"
              onClick={onClickDelete}
            >
              삭제
            </CustomButton>
          </PanelBtn>
        </PanelHeader>

        <PanelBody row left="30%" right="70%" openInputBar={openInputBar}>
          <SimpleSellFinishInput
            state={state}
            openInputBar={openInputBar}
            inputForm={inputForm}
            inputFunctions={inputFunctions}
            isOrderEditable={isOrderEditable}
            isDisEditable={isDisEditable}
          />

          <SimpleSellFinishMaster
            state={state}
            dataGridRef={dataGridRef}
            searchForm={searchForm}
            masterFunctions={masterFunctions}
          />
        </PanelBody>
      </PanelInit>
      {openCompanyCreateModal && (
        <NewCompanyModal
          onClose={() => {
            openModal("company", false);
            initLookupData();
          }}
          closable={true}
          visible={true}
          maskClosable={false}
          type={type}
        />
      )}
      {openTerminalCreateModal && (
        <NewTerminalModal
          onClose={() => {
            openModal("terminal", false);
            initLookupData();
          }}
          closable={true}
          visible={true}
          maskClosable={false}
        />
      )}
      {openForwarderCreateModal && (
        <NewForwarderModal
          onClose={() => {
            openModal("forwarder", false);
            initLookupData();
          }}
          closable={true}
          visible={true}
          maskClosable={false}
        />
      )}
    </>
  );
};

export default SimpleSellFinishPage;
