import { DataGrid } from "devextreme-react";
import {
  Column,
  ColumnChooser,
  FilterRow,
  Format,
  HeaderFilter,
  Scrolling,
  StateStoring,
} from "devextreme-react/data-grid";
import React, { useCallback, useState } from "react";
import {
  DataCount,
  PanelGridInit,
} from "../../../../components/Layout/Panel/PanelGrid/panelGrid.style";
import StateStoringSaveAndDeleteButton from "../../../../components/StateStoring";

const CalculateProfitLowerGrid = ({ state, lowerGridRef }) => {
  const [totalCount, setTotalCount] = useState(0);
  const storageKey = "calculateProfitLower";

  const { lowerDataSource } = state;
  return (
    <>
      <PanelGridInit gridItems column>
        <StateStoringSaveAndDeleteButton
          dataGridRef={lowerGridRef}
          storageKey={storageKey}
        />
        <DataCount>{`총 ${totalCount}건`}</DataCount>
        <DataGrid
          className="datagrid-wrap"
          id={storageKey}
          dataSource={lowerDataSource}
          ref={lowerGridRef}
          showRowLines={true}
          showBorders={true}
          hoverStateEnabled={true}
          allowColumnResizing={true}
          allowColumnReordering={true}
          columnResizingMode="widget"
          keyExpr="Oid"
          focusedRowEnabled={true}
          onContentReady={(e) => setTotalCount(e.component.totalCount())}
        >
          <FilterRow visible={true} />
          <HeaderFilter visible={true} />
          <Scrolling
            columnRenderingMode="virtual"
            mode="virtual"
            showScrollbar="always"
            useNative="false"
          />
          <StateStoring
            enabled={true}
            type="custom"
            customLoad={useCallback(() => {
              return JSON.parse(localStorage.getItem(storageKey));
            }, [])}
          />
          <ColumnChooser enabled={true} />
          <Column dataField="companyName" caption="업체구분" />
          <Column
            dataField="regDate"
            caption="등록일"
            alignment="center"
            dataType="date"
            format="yyyy-MM-dd"
          />
          <Column dataField="companyName" caption="업체명" alignment="center" />
          <Column dataField="sales" caption="매출">
            <Format type="fixedPoint" />
          </Column>
          <Column dataField="purchase" caption="매입">
            <Format type="fixedPoint" />
          </Column>
          <Column dataField="remark01" caption="비고1" alignment="center" />
          <Column dataField="remark02" caption="비고2" alignment="center" />
          <Column
            dataField="AccountConection"
            caption="계좌연결여부"
            alignment="center"
          />
          <Column
            dataField="ActualPaymentDate"
            caption="실지급일"
            alignment="center"
          />
        </DataGrid>
      </PanelGridInit>
    </>
  );
};

export default CalculateProfitLowerGrid;
