import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import moment from "moment";

export const getDefaultMessage = (user, selectedRowData, searchObj) => {
  return `
  <p>안녕하세요</p>
  <p>HU로지스틱스입니다</p>
  <br />
  <p>${moment(searchObj?.firstDay).format("YYYY년 MM월 DD일")}~${moment(
    searchObj?.lastDay
  ).format("YYYY년 MM월 DD일")} 명세서 전달드립니다.</p>
  <br />
  <br />
  <p>감사합니다.</p>
  <br />
  <p>Best regards,</p>
  <strong>${user?.englishName || ""}❘${user?.userName || ""} ${user
    ?.dutyCode_comCodeDetail?.subName || ""}</strong>
  <br />
  <br />
  <br />

  <table style="width: initial;">
    <tbody>
      <tr data-table-row="row-t5rm">
        <td style="width: 380px;" class="ql-table-data-cell" data-table-row="row-t5rm">
          <p class="ql-table-cell-line" data-table-row="row-t5rm" data-table-cell="row-j0yn" data-cellwidth="380px" data-tablewidth="initial">
            <strong style="color: rgb(0, 85, 255); font-size: 16px;">
              HU LOGISTICS CO., LTD.
            </strong>
          </p>
          <p class="ql-table-cell-line" data-table-row="row-t5rm" data-table-cell="row-j0yn">
            Rm.301, 3F, 136, Jungang-daero,
          </p>
          <p class="ql-table-cell-line" data-table-row="row-t5rm" data-table-cell="row-j0yn">
            Jung-gu, Busan, Republic of Korea
          </p>
          <p class="ql-table-cell-line" data-table-row="row-t5rm" data-table-cell="row-j0yn">
            TEL : 051-466-0821&nbsp;/&nbsp;FAX : 051-468-0346
          </p>
          <p class="ql-table-cell-line" data-table-row="row-t5rm" data-table-cell="row-j0yn" data-cellwidth="1033px">
            Homepage : www.hulogistics.co.kr&nbsp;/&nbsp;email : mail@hulogistics.co.kr
          </p>
        </td>
      </tr>
      <tr data-table-row="row-q26w">
        <td style="width: 380px;" class="ql-table-data-cell" data-table-row="row-q26w">
          <p class="ql-table-cell-line" data-table-row="row-q26w" data-table-cell="row-e591" data-cellwidth="380px" data-tablewidth="initial">
            <strong style="font-size: 14px;">${user?.userName || ""}</strong>
          </p>
          <p class="ql-table-cell-line" data-table-row="row-q26w" data-table-cell="row-e591">
            (${user?.englishName || ""})
          </p>
          <p class="ql-table-cell-line" data-table-row="row-q26w" data-table-cell="row-e591">
            HP.${user?.tel || ""}
          </p>
          <p class="ql-table-cell-line" data-table-row="row-q26w" data-table-cell="row-e591" data-cellwidth="1033px">
            ${user?.deptCode_department?.deptName || ""}팀&nbsp;/&nbsp;${user
    ?.dutyCode_comCodeDetail?.subName || ""}
          </p>
        </td>
      </tr>
    </tbody>
  </table>
  
  `;
};

export const convertPdf = async () => {
  const canvas = await html2canvas(document.querySelector("#pdf-form"));
  const imageFile = canvas.toDataURL("image/png");
  const doc = new jsPDF("p", "mm", "a4");
  const pageWidth = doc.internal.pageSize.getWidth();
  const pageHeight = doc.internal.pageSize.getHeight();

  const widthRatio = pageWidth / canvas.width;
  const customHeight = canvas.height * widthRatio;
  doc.addImage(imageFile, "png", 0, 0, pageWidth, customHeight);
  let heightLeft = customHeight;
  let heightAdd = -pageHeight;

  while (heightLeft >= pageHeight) {
    doc.addPage();
    doc.addImage(imageFile, "png", 0, heightAdd, pageWidth, customHeight);
    heightLeft -= pageHeight;
    heightAdd -= pageHeight;
  }
  const binary = doc.output("blob");

  return binary;
  //문서저장
  // doc.save("filename" + new Date().getTime() + ".pdf");
};
