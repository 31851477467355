import React, { useEffect, useState } from "react";
import * as requestServer from "../SimpleDispatchCrud";

// import Guid from "devextreme/core/guid";

import InputCheckBox from "../../../../components/InputForm/InputCheckBox";
import InputDate from "../../../../components/InputForm/InputDate";
import InputSelect from "../../../../components/InputForm/InputSelect";
import InputNumber from "../../../../components/InputForm/InputNumber";
import InputText from "../../../../components/InputForm/InputText";

// 냉동, 위험물
// const FRIDGE = 4053;
// const DANGER = 4054;
// const TANK = 4055;

function CreateSimpleModalDetail({
  control,
  errors,
  setValue,
  setExtraChargeModal,
  rowData,
  watch,
  onValueChanged,
  isConfirm,
}) {
  const [lookupData, setLookupData] = useState({
    workPlace: [],
    workWaypoint: [],
    collectWaypoint: [],
    conType: [],
  });

  const datas = watch();
  const freight = watch("freight");
  const fridge = watch("fridge");
  const danger = watch("danger");
  // const tank = watch("tank");

  useEffect(() => {
    getLookup();
  }, []);

  // const onFreightValueChange = (e) => {
  //   const key = rowData?.data.id;
  //   const changes = datas.extraChargeObj || [];
  //   const freight = parseInt(e.value);
  //   if (!key) {
  //     const newKey = new Guid();

  //     const data = { isCharged: true, isHabul: true, division: FRIDGE };
  //     const data2 = { isCharged: true, isHabul: true, division: DANGER };
  //     const data3 = { isCharged: true, isHabul: true, division: TANK };
  //     data.chargedAmount = Math.round(freight * 0.3);
  //     data.chargedRate = 30;
  //     data2.chargedAmount = Math.round(freight * 0.3);
  //     data2.chargedRate = 30;
  //     data3.chargedAmount = Math.round(freight * 0.3);
  //     data3.chargedRate = 30;
  //     if (fridge)
  //       changes.push({
  //         data: data,
  //         type: "insert",
  //         key: newKey._value,
  //         index: 0,
  //         pageIndex: 0,
  //       });
  //     if (danger)
  //       changes.push({
  //         data: data2,
  //         type: "insert",
  //         key: newKey._value,
  //         index: 0,
  //         pageIndex: 0,
  //       });
  //     if (tank)
  //       changes.push({
  //         data: data3,
  //         type: "insert",
  //         key: newKey._value,
  //         index: 0,
  //         pageIndex: 0,
  //       });

  //     setValue("extraChargeObj", changes);
  //   }
  //   const extraChargesFridge = rowData?.data.extraCharges.filter(
  //     (cur) => cur.division === FRIDGE
  //   )[0];
  //   const extraChargesDanger = rowData?.data.extraCharges.filter(
  //     (cur) => cur.division === DANGER
  //   )[0];
  //   const extraChargesTank = rowData?.data.extraCharges.filter(
  //     (cur) => cur.division === TANK
  //   )[0];

  //   if (fridge) {
  //     const extraChargeObj = changes.filter(
  //       (cur) => cur.key === extraChargesFridge?.id
  //     );
  //     let extraChanges;

  //     for (const obj of changes) {
  //       if (obj.type === "remove") continue;
  //       if (obj.data.division === FRIDGE) {
  //         extraChanges = obj;
  //         break;
  //       }
  //     }

  //     if (extraChargeObj[0]) {
  //       if (extraChargeObj[0].type !== "remove") {
  //         extraChargeObj[0].data.chargedRate = 30;
  //         extraChargeObj[0].data.chargedAmount = Math.round(freight * 0.3);
  //       }
  //     } else if (extraChargesFridge) {
  //       changes.push({
  //         data: {
  //           chargedRate: 30,
  //           chargedAmount: Math.round(freight * 0.3),
  //         },
  //         type: "update",
  //         key: extraChargesFridge.id,
  //       });
  //     } else if (extraChanges) {
  //       extraChanges.data.chargedRate = 30;
  //       extraChanges.data.chargedAmount = Math.round(freight * 0.3);
  //     } else {
  //       const newKey = new Guid();
  //       const data = { isCharged: true, isHabul: true, division: FRIDGE };
  //       data.chargedRate = 30;
  //       data.chargedAmount = Math.round(freight * 0.3);
  //       changes.push({
  //         data: data,
  //         type: "insert",
  //         key: newKey._value,
  //         index: 0,
  //         pageIndex: 0,
  //       });
  //     }
  //   }

  //   if (danger) {
  //     const extraChargeObj = changes.filter(
  //       (cur) => cur.key === extraChargesDanger?.id
  //     );
  //     let extraChanges;

  //     for (const obj of changes) {
  //       if (obj.type === "remove") continue;
  //       if (obj.data.division === DANGER) {
  //         extraChanges = obj;
  //         break;
  //       }
  //     }

  //     if (extraChargeObj[0]) {
  //       if (extraChargeObj[0].type !== "remove") {
  //         extraChargeObj[0].data.chargedRate = 30;
  //         extraChargeObj[0].data.chargedAmount = Math.round(freight * 0.3);
  //       }
  //     } else if (extraChargesDanger) {
  //       changes.push({
  //         data: {
  //           chargedRate: 30,
  //           chargedAmount: Math.round(freight * 0.3),
  //         },
  //         type: "update",
  //         key: extraChargesDanger.id,
  //       });
  //     } else if (extraChanges) {
  //       extraChanges.data.chargedRate = 30;
  //       extraChanges.data.chargedAmount = Math.round(freight * 0.3);
  //     } else {
  //       const newKey = new Guid();
  //       const data = { isCharged: true, isHabul: true, division: DANGER };
  //       data.chargedRate = 30;
  //       data.chargedAmount = Math.round(freight * 0.3);
  //       changes.push({
  //         data: data,
  //         type: "insert",
  //         key: newKey._value,
  //         index: 0,
  //         pageIndex: 0,
  //       });
  //     }
  //   }

  //   if (tank) {
  //     const extraChargeObj = changes.filter(
  //       (cur) => cur.key === extraChargesTank?.id
  //     );
  //     let extraChanges;

  //     for (const obj of changes) {
  //       if (obj.type === "remove") continue;
  //       if (obj.data.division === TANK) {
  //         extraChanges = obj;
  //         break;
  //       }
  //     }

  //     if (extraChargeObj[0]) {
  //       if (extraChargeObj[0].type !== "remove") {
  //         extraChargeObj[0].data.chargedRate = 30;
  //         extraChargeObj[0].data.chargedAmount = Math.round(freight * 0.3);
  //       }
  //     } else if (extraChargesTank) {
  //       changes.push({
  //         data: {
  //           chargedRate: 30,
  //           chargedAmount: Math.round(freight * 0.3),
  //         },
  //         type: "update",
  //         key: extraChargesTank.id,
  //       });
  //     } else if (extraChanges) {
  //       extraChanges.data.chargedRate = 30;
  //       extraChanges.data.chargedAmount = Math.round(freight * 0.3);
  //     } else {
  //       const newKey = new Guid();
  //       const data = { isCharged: true, isHabul: true, division: TANK };
  //       data.chargedRate = 30;
  //       data.chargedAmount = Math.round(freight * 0.3);
  //       changes.push({
  //         data: data,
  //         type: "insert",
  //         key: newKey._value,
  //         index: 0,
  //         pageIndex: 0,
  //       });
  //     }
  //   }

  //   setValue("extraChargeObj", changes);
  // };

  const workPlaceChange = (e) => {
    setValue(
      "address",
      lookupData.workPlace.find((obj) => obj.code === e.value)?.address
    );
  };

  useEffect(() => {
    // onFreightValueChange({ value: freight });
  }, [freight]);

  useEffect(() => {
    const extraCharge = rowData?.data.extraCharges;
    const extraChargeChanges = datas.extraChargeObj;
    setValue("extraCharge", 0);

    let total = 0;
    if (extraCharge && extraCharge.length > 0) {
      total = extraCharge.reduce((acc, cur) => {
        let changedChargedAmount;
        if (extraChargeChanges) {
          changedChargedAmount = extraChargeChanges.filter(
            (ex) => ex.key === cur.id
          )[0]?.data?.chargedAmount;
        }

        if (changedChargedAmount) {
          return acc + changedChargedAmount;
        } else {
          return acc + cur.chargedAmount;
        }
      }, 0);

      if (extraChargeChanges) {
        const insertChanges = extraChargeChanges.filter(
          (cur) => cur.type === "insert"
        );
        const removeChanges = extraChargeChanges.filter(
          (cur) => cur.type === "remove"
        );

        total =
          total +
          insertChanges.reduce((acc, cur) => {
            return acc + cur.data.chargedAmount;
          }, 0);

        total =
          total -
          removeChanges.reduce((acc, cur) => {
            const removedCharge = extraCharge.filter(
              (extra) => extra.id === cur.key
            )[0];
            return acc + removedCharge.chargedAmount;
          }, 0);
      }
    } else if (extraChargeChanges && extraChargeChanges.length > 0) {
      total = extraChargeChanges.reduce((acc, cur) => {
        return acc + parseInt(cur.data.chargedAmount);
      }, 0);
    } else {
      total = 0;
    }

    setValue("extraCharge", total);

    // eslint-disable-next-line
  }, [datas.extraChargeObj]);

  // lookup불러오기
  async function getLookup() {
    try {
      const [
        workPlaceData,
        workWaypointData,
        collectWaypointData,
        conTypeData,
      ] = await Promise.all([
        requestServer.getLookup("workplace"),
        requestServer.getLookup("terminal/waypoint"),
        requestServer.getLookup("terminal/waypoint"),
        requestServer.getLookup("comcode/detail/10400"),
      ]);

      setLookupData({
        workPlace: workPlaceData.data,
        workWaypoint: workWaypointData.data,
        collectWaypoint: collectWaypointData.data,
        conType: conTypeData.data,
      });
    } catch (err) {
      console.log(err);
    }
  }
  return (
    <>
      <div className="input-wrap">
        <div className="input-grid">
          <div className="dx-field">
            <InputDate
              control={control}
              errors={errors}
              name="deliverDate"
              label="운송(요청)일자"
              disable={isConfirm}
            />
          </div>
          <div className="dx-field">
            <InputDate
              control={control}
              errors={errors}
              name="returnExpectDate"
              label="반납예정일자"
              disable={isConfirm}
            />
          </div>
          <div className="dx-field">
            <InputDate
              control={control}
              errors={errors}
              name="returnDate"
              label="반납일자"
              disable={isConfirm}
            />
          </div>
        </div>
        <div className="input-grid">
          <div className="dx-field">
            <InputText
              control={control}
              errors={errors}
              name="containerNo"
              label="컨테이너 번호"
              disabled={isConfirm}
            />
          </div>
          <div className="dx-field">
            <InputSelect
              searchEnabled={true}
              control={control}
              errors={errors}
              name="containerType"
              label="컨/규격"
              dataSource={lookupData.conType}
              valueExpr="subCode"
              displayExpr="subName"
              onValueChange={onValueChanged}
              disable={isConfirm}
            />
          </div>
          <div className="dx-field">
            <InputDate
              control={control}
              errors={errors}
              name="freightDate"
              label="청구일자"
              disable={isConfirm}
            />
          </div>
        </div>
        <div className="input-grid">
          {/* <div className="dx-field">
            <InputSelect
              searchEnabled={true}
              control={control}
              errors={errors}
              name="workWaypoint"
              label="작업경유지"
              dataSource={lookupData.workWaypoint}
              valueExpr="code"
              displayExpr="twName"
              disable={isConfirm}
            />
          </div>
          <div className="dx-field">
            <InputSelect
              searchEnabled={true}
              control={control}
              errors={errors}
              name="collectWaypoint"
              label="회수경유지"
              dataSource={lookupData.collectWaypoint}
              valueExpr="code"
              displayExpr="twName"
              disable={isConfirm}
            />
          </div> */}
          <div className="dx-field">
            <InputSelect
              searchEnabled={true}
              control={control}
              errors={errors}
              name="workPlace"
              label="작업지"
              dataSource={lookupData.workPlace}
              valueExpr="code"
              displayExpr="workPlaceNaem"
              onValueChanged={(v) => {
                onValueChanged(v);
                workPlaceChange(v);
              }}
              disable={isConfirm}
            />
          </div>
          <div className="dx-field row-double">
            <InputText
              control={control}
              errors={errors}
              name="address"
              label="주소"
              disabled={true}
            />
          </div>
        </div>
        <div className="input-grid">
          <div className="dx-field">
            <InputNumber
              control={control}
              errors={errors}
              name="freight"
              label="청구운임"
              // onValueChange={onFreightValueChange}
              disabled={isConfirm}
            />
          </div>
          {/* <div className="dx-field">
            <InputNumber control={control} errors={errors} name="habul" label="하불운임" disabled={isConfirm} />
          </div> */}
          <div className="dx-field">
            <InputNumber
              control={control}
              errors={errors}
              name="extraCharge"
              label="할증금액"
              disabled={true}
            />
          </div>
          <div className={`dx-field button-line`}>
            <button
              type="button"
              className={`btn btn-connect`}
              onClick={() => setExtraChargeModal(true)}
              disabled={isConfirm}
            >
              할증등록
            </button>
          </div>
          <div className="dx-field">
            <InputText
              control={control}
              errors={errors}
              name="cargoWeight"
              label="화물중량"
              disabled={isConfirm}
            />
          </div>
        </div>

        <div className="input-grid">
          {/* <div className="dx-field">
            <InputText control={control} errors={errors} name="carNo" label="차량번호" disabled={isConfirm} />
          </div> */}
          <div className="dx-field checkbox-group">
            <InputCheckBox
              control={control}
              errors={errors}
              name="bobtail"
              label="밥테일"
              flex={true}
              disabled={isConfirm}
            />
          </div>
        </div>
        {(fridge || danger) && (
          <div className="input-grid">
            <div className="dx-field">
              <InputText
                control={control}
                errors={errors}
                name="temperature"
                label="온도"
                disabled={isConfirm}
              />
            </div>
            <div className="dx-field">
              <InputText
                control={control}
                errors={errors}
                name="humidity"
                label="습도"
                disabled={isConfirm}
              />
            </div>
            <div className="dx-field">
              <InputText
                control={control}
                errors={errors}
                name="vent"
                label="환풍구"
                disabled={isConfirm}
              />
            </div>
            <div className="dx-field">
              <InputText
                control={control}
                errors={errors}
                name="unNo"
                label="UN넘버"
                disabled={isConfirm}
              />
            </div>
            <div className="dx-field">
              <InputText
                control={control}
                errors={errors}
                name="class"
                label="클래스"
                disabled={isConfirm}
              />
            </div>
            <div className="dx-field">
              <InputText
                control={control}
                errors={errors}
                name="extraInfo"
                label="기타정보"
                disabled={isConfirm}
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default CreateSimpleModalDetail;
