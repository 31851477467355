import React, { useCallback, useState } from "react";
import {
  DataCount,
  PanelGridInit,
} from "../../../../components/Layout/Panel/PanelGrid/panelGrid.style";
import StateStoringSaveAndDeleteButton from "../../../../components/StateStoring";
import { DataGrid } from "devextreme-react";
import {
  Column,
  ColumnChooser,
  FilterRow,
  Format,
  HeaderFilter,
  Lookup,
  MasterDetail,
  Scrolling,
  StateStoring,
} from "devextreme-react/data-grid";
import DispatchNewDetail from "../DispatchNewDetail";

const DispatchNewGrid = ({ gridProps, companyData, terminalData }) => {
  const {
    lookupData,
    dataGridRef,
    detailGridRef,
    dataSource,
    onCellClick,
  } = gridProps;
  const [totalCount, setTotalCount] = useState(0);
  const storageKey = "dispatchNewMaster";

  /* #region dataGrid 함수 -------------------------------------------------------- */

  const onRowDblClick = useCallback((e) => {
    if (e.isExpanded) {
      e.component.collapseRow(e.key);
    } else {
      e.component.expandRow(e.key);
    }
  }, []);

  const importExportRender = useCallback((e) => {
    if (e.row.data.importExport === 1) {
      return <div>수입</div>;
    } else {
      return <div>수출</div>;
    }
  }, []);

  const orderStatusRender = useCallback((e) => {
    if (e.data.longStorage === 1) {
      return <div>장기보관</div>;
    } else {
      return <div>{e.text}</div>;
    }
  }, []);

  const divisionRender = useCallback((e) => {
    if (e.value === "order") return <div>오더접수</div>;
    if (e.value === "lclorder") return <div>LCL오더</div>;
    if (e.value === "simple") return <div>간편배차</div>;
    if (e.value === "shuttle") return <div>셔틀배차</div>;
    if (e.value === "otherCo") return <div>용차배차</div>;
  }, []);

  const onCellPrepared = useCallback(
    (e) => {
      if (e.rowType !== "data") return;
      if (
        e.column.caption !== "상차지" &&
        e.column.caption !== "하차지" &&
        e.column.caption !== "하불금액" &&
        e.column.caption !== "샤시종류"
      )
        return;

      if (e.column.caption === "상차지") {
        e.cellElement.style.background = e.data.loadPlaceColor || "transparant";
      } else if (e.column.caption === "하차지") {
        e.cellElement.style.background =
          e.data.unloadPlaceColor || "transparant";
      } else if (e.column.caption === "하불금액") {
        const extraCharge = Number(e.row.data.extraCharge) || 0;
        const freight = Number(e.row.data.freight) || 0;
        const habul = Number(e.row.data.habul) || 0;
        if (extraCharge + freight < habul) {
          e.cellElement.style.background = "red";
        }
      } else if (
        e.column.caption === "샤시종류" &&
        e.data.chassisType === lookupData.chassisType[0]?.subName
      ) {
        const green = lookupData.color.filter(
          (cur) => cur.subCode === "CLOR-GREEN"
        )[0];

        e.cellElement.style.background = green?.extra1 || "transparant";
      }
    },
    [lookupData]
  );

  const onRowPrepared = useCallback(
    (e) => {
      if (e.rowType !== "data") return;

      const { data } = e;
      const color = data.color;
      if (!color) return;

      const filteredColorArr = lookupData.color.filter(
        (cur) => cur.subCode === color
      );
      e.rowElement.style.background = filteredColorArr[0]?.extra1;
    },
    [lookupData.color]
  );

  /* #endregion */

  return (
    <PanelGridInit>
      <StateStoringSaveAndDeleteButton
        dataGridRef={dataGridRef}
        storageKey={storageKey}
      />
      <DataCount>{`총 ${totalCount}건`}</DataCount>
      <DataGrid
        className="datagrid-wrap"
        id="DispatchMaster"
        dataSource={dataSource}
        ref={dataGridRef}
        showRowLines={true}
        showBorders={true}
        hoverStateEnabled={true}
        allowColumnResizing={true}
        allowColumnReordering={true}
        columnAutoWidth={true}
        columnResizingMode="widget"
        onRowDblClick={onRowDblClick}
        focusedRowEnabled={true}
        onRowPrepared={onRowPrepared}
        onCellPrepared={onCellPrepared}
        onCellClick={onCellClick}
        // onFocusedRowChanged={onFocusedRowChanged}
        onContentReady={(e) => setTotalCount(e.component.totalCount())}
      >
        <FilterRow visible={true} />
        <HeaderFilter visible={true} />
        <ColumnChooser enabled={true} />
        <StateStoring
          enabled={true}
          type="custom"
          customLoad={useCallback(() => {
            return JSON.parse(localStorage.getItem(storageKey));
          }, [])}
        />
        <Scrolling
          columnRenderingMode="virtual"
          mode="virtual"
          showScrollbar="always"
          useNative="false"
        />
        <Column caption="No" cellRender={(e) => e.row.loadIndex + 1} />
        <Column dataField="orderCode" caption="접수번호" />
        <Column
          dataField="division"
          caption="구분"
          cellRender={divisionRender}
          alignment="center"
        />
        <Column
          dataField="orderStatus"
          caption="상태"
          cellRender={orderStatusRender}
          alignment="center"
        />
        <Column
          dataField="importExport"
          caption="수입/수출"
          cellRender={importExportRender}
          alignment="center"
        />
        <Column dataField="forwarder" caption="선사" alignment="center" />
        <Column dataField="cargoOwnerName" caption="화주">
          <Lookup
            dataSource={companyData}
            displayExpr="company"
            valueExpr="oid"
          />
        </Column>
        <Column
          dataField="realCargoOwner"
          caption="실화주"
          alignment="center"
        />
        <Column dataField="billingPlaceName" caption="청구처">
          <Lookup
            dataSource={companyData}
            displayExpr="company"
            valueExpr="oid"
          />
        </Column>
        <Column dataField="orderCompanyName" caption="발주처">
          <Lookup
            dataSource={companyData}
            displayExpr="company"
            valueExpr="oid"
          />
        </Column>
        <Column dataField="blNo" caption="BL No" alignment="center" />
        <Column dataField="bkNo" caption="BK No" alignment="center" />
        <Column dataField="doNo" caption="DO No" alignment="center" />
        <Column
          dataField="containerNo"
          caption="컨테이너 No"
          alignment="center"
        />
        <Column dataField="sealNo" caption="Seal No" alignment="center" />
        <Column
          dataField="containerType"
          caption="컨/규격"
          alignment="center"
        />

        <Column dataField="chassis" caption="샤시번호" alignment="center" />
        <Column dataField="chassisType" caption="샤시종류" alignment="center" />

        <Column
          dataField="deliverDate"
          caption="운송요청일자"
          alignment="center"
          dataType="date"
          format="yyyy-MM-dd"
        />
        <Column
          dataField="returnDate"
          caption="반납마감일자"
          alignment="center"
          dataType="date"
          format="yyyy-MM-dd"
        />
        <Column
          dataField="freight"
          caption="청구금액"
          alignment="right"
          dataType="number"
        >
          <Format type="fixedPoint" />
        </Column>
        <Column
          dataField="extraCharge"
          caption="할증금액"
          alignment="right"
          dataType="number"
        >
          <Format type="fixedPoint" />
        </Column>
        <Column dataField="totalPrice" caption="총합계" alignment="right">
          <Format type="fixedPoint" />
        </Column>
        <Column
          dataField="habul"
          caption="하불금액"
          alignment="right"
          dataType="number"
        >
          <Format type="fixedPoint" />
        </Column>
        <Column dataField="manager" caption="청구처담당자" alignment="center" />
        <Column dataField="dispatchTeam" caption="배차팀" alignment="center" />
        <Column dataField="loadPlaceCode" caption="상차지" alignment="center">
          <Lookup
            dataSource={terminalData}
            displayExpr="twName"
            valueExpr="code"
          />
        </Column>
        <Column dataField="workPlaceCode" caption="작업지">
          <Lookup
            dataSource={terminalData}
            displayExpr="twName"
            valueExpr="code"
          />
        </Column>
        <Column dataField="unloadPlaceCode" caption="하차지" alignment="center">
          <Lookup
            dataSource={terminalData}
            displayExpr="twName"
            valueExpr="code"
          />
        </Column>
        <Column
          dataField="workTime"
          caption="작업시간"
          alignment="center"
          dataType="date"
          format="HH:mm:ss"
        />
        <Column
          dataField="dem"
          caption="선사반출기한"
          alignment="center"
          dataType="date"
          format="yyyy-MM-dd"
        />
        <Column
          dataField="bobtail"
          caption="밥테일"
          dataType="boolean"
          width="80"
        />
        <Column
          dataField="fridge"
          caption="냉동"
          dataType="boolean"
          width="70"
        />
        <Column
          dataField="danger"
          caption="위험물"
          dataType="boolean"
          width="80"
        />
        <Column dataField="tank" caption="탱크" dataType="boolean" width="70" />
        <Column
          dataField="direct"
          caption="직반입"
          dataType="boolean"
          width="80"
        />
        <Column dataField="temperature" caption="온도" alignment="center" />
        <Column dataField="humidity" caption="습도" />
        <Column dataField="vent" caption="환풍구" alignment="center" />
        <Column dataField="unNo" caption="un번호" alignment="center" />
        <Column dataField="class" caption="클래스" alignment="center" />
        <Column dataField="extraInfo" caption="특이사항" alignment="center" />
        <Column dataField="createUsr" caption="최초입력자" alignment="center" />
        <Column dataField="updateUsr" caption="최종수정자" alignment="center" />
        <Column dataField="createDate" caption="작성일" alignment="center" />
        <Column dataField="createTime" caption="작성시간" alignment="center" />
        <MasterDetail
          enabled={true}
          render={(e) => (
            <DispatchNewDetail
              dataGridRef={dataGridRef}
              detailGridRef={detailGridRef}
              detail={e.data.detail}
              companyData={companyData}
              terminalData={terminalData}
            />
          )}
        />
      </DataGrid>
    </PanelGridInit>
  );
};

export default DispatchNewGrid;
