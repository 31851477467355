import { DataGrid } from "devextreme-react";
import {
  Column,
  ColumnChooser,
  FilterRow,
  Scrolling,
} from "devextreme-react/data-grid";
import React, { useState } from "react";
import { DataCount } from "../../../../components/Layout/Panel/PanelGrid/panelGrid.style";

export const SuttleExcelGrid = ({ id, dataSource, gridItems }) => {
  const [totalCount, setTotalCount] = useState(0);

  return (
    <>
      <DataCount gridItems={gridItems}>{`총 ${totalCount}건`}</DataCount>
      <DataGrid
        id={id}
        className="datagrid-wrap"
        dataSource={dataSource}
        keyExpr="code"
        showRowLines={true}
        showBorders={true}
        hoverStateEnabled={true}
        allowColumnResizing={true}
        allowColumnReordering={true}
        columnResizingMode="widget"
        focusedRowEnabled={true}
        onContentReady={(e) => setTotalCount(e.component.totalCount())}
      >
        <FilterRow visible={true} />
        <ColumnChooser enabled={true} />
        <Scrolling
          columnRenderingMode="virtual"
          mode="virtual"
          showScrollbar="always"
          useNative="false"
        />
        <Column dataField="receiveDate" caption="접수일자" width="85" />
        <Column dataField="receiveCo" caption="접수법인" width="85" />
        <Column dataField="deliverDate" caption="운송일자" width="85" />
        <Column dataField="returnDate" caption="반납일자" width="85" />
        <Column dataField="cargoOwner" caption="화주" width="85" />
        <Column
          dataField="importExport"
          caption="수입/수출"
          alignment="center"
          // cellRender={importExportCellRender}
          width="95"
        />
        <Column dataField="billingPlace" caption="청구처" width="70" />
        <Column
          dataField="containerType"
          caption="컨규격"
          alignment="center"
          width="95"
        />
        <Column dataField="containerNo" caption="컨테이너NO" width="115" />
        <Column dataField="sealNo" caption="Seal No" width="85" />
        <Column dataField="cargoWeight" caption="화물중량(ton)" width="85" />
        <Column dataField="blNo" caption="BL NO" width="85" />
        <Column dataField="doNo" caption="DO NO" width="85" />
        <Column dataField="bkNo" caption="BK NO" width="85" />
        <Column dataField="motherShip" caption="모선항차" width="85" />
        <Column dataField="forwarder" caption="선사" width="85" />
        <Column dataField="realCargoOwner" caption="실화주" width="70" />
        <Column dataField="loadPlace" caption="상차지" width="75" />
        <Column dataField="carTypeCode1" caption="차량용차구분1" width="85" />
        <Column dataField="carNo_01" caption="배차차량1" width="90" />
        <Column dataField="yongchasa_01" caption="용차사1" width="90" />
        <Column dataField="yongchaCarNo_01" caption="차량번호1" width="85" />
        <Column dataField="workPlace" caption="작업지" width="75" />
        <Column dataField="carTypeCode2" caption="차량용차구분2" width="85" />
        <Column dataField="carNo_02" caption="배차차량2" width="90" />
        <Column dataField="yongchasa_02" caption="용차사2" width="90" />
        <Column dataField="yongchaCarNo_02" caption="차량번호2" width="85" />
        <Column
          dataField="workPlaceArrive"
          caption="작업지도착시간"
          width="85"
        />
        <Column dataField="unloadPlace" caption="하차지" width="75" />
        <Column dataField="freight" caption="청구운임" width="75" />
        <Column dataField="habul_01" caption="상차지 하불금액" width="115" />
        <Column dataField="habul_02" caption="하차지 하불금액" width="115" />
        <Column dataField="putDirect" caption="접안일" width="85" />
        <Column dataField="putTime" caption="접안시간" width="85" />
        <Column dataField="sailDay" caption="출항일" width="85" />
        <Column dataField="sailTime" caption="출항시간" width="85" />
        <Column dataField="manager" caption="담당자" width="75" />
        <Column dataField="extraInfo" caption="특이사항" width="75" />
        <Column dataField="remark" caption="비고" width="75" />
        {id === "shuttleFail" && (
          <Column dataField="message" caption="에러내용" width="400" />
        )}
      </DataGrid>
    </>
  );
};
