import DataGrid, {
  Column,
  ColumnChooser,
  FilterRow,
  HeaderFilter,
  Scrolling,
  StateStoring,
} from "devextreme-react/data-grid";
import CustomStore from "devextreme/data/custom_store";
import React, { useCallback, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { sendRequest } from "../../../../_metronic/_helpers/DataSoureHelpers";
import { ChangePasswordModal } from "../../../../_metronic/_partials/modals/ChangePasswordModal";
import CustomButton from "../../../components/FunctionButton/CustomButton";
import {
  DataCount,
  PanelGridInit,
  PanelGridSearchBar,
  PanelGridWrapper,
  SearchItems,
} from "../../../components/Layout/Panel/PanelGrid/panelGrid.style";
import StateStoringSaveAndDeleteButton from "../../../components/StateStoring";

function UserMaster({ dataGridRef, onFocusedRowChanged, selectedRowKey }) {
  const [totalCount, setTotalCount] = useState(0);
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [search, setSearch] = useState();
  const storageKey = "userMaster";

  const { register, handleSubmit } = useForm();

  const dataSource = useMemo(() => {
    const apiUrl = `${process.env.REACT_APP_API_URL}/user-list/master`;

    return new CustomStore({
      key: "id",
      load: () => sendRequest(apiUrl, "GET", search),
    });
  }, [search]);

  const onSubmit = (data) => {
    for (const obj in data) {
      if (data[obj] === "") {
        data[obj] = null;
      }
    }

    setSearch(data);
  };

  const passwordCellRender = (e) => {
    return (
      <button
        type="button"
        className="btn btn-grid btn-grid-change pw-change"
        onClick={() => setEditModalVisible(true)}
      >
        변경
      </button>
    );
  };

  const closeModal = () => {
    setEditModalVisible(false);
  };

  return (
    <>
      <PanelGridWrapper>
        <PanelGridSearchBar onSubmit={handleSubmit(onSubmit)}>
          <SearchItems>
            <label>아이디</label>
            <input type="text" {...register("userId")} />
          </SearchItems>
          <SearchItems>
            <label>사용자명</label>
            <input type="text" {...register("userName")} />
          </SearchItems>
          <CustomButton
            type="submit"
            height={24}
            layout="solid"
            color="search"
            label="검색"
          />
        </PanelGridSearchBar>

        <PanelGridInit>
          <StateStoringSaveAndDeleteButton
            dataGridRef={dataGridRef}
            storageKey={storageKey}
          />
          <DataCount>{`총 ${totalCount}건`}</DataCount>
          <DataGrid
            id="userMaster"
            className="datagrid-wrap"
            ref={dataGridRef}
            dataSource={dataSource}
            showRowLines={true}
            showBorders={true}
            hoverStateEnabled={true}
            allowColumnResizing={true}
            allowColumnReordering={true}
            columnResizingMode="widget"
            keyExpr="id"
            focusedRowEnabled={true}
            onFocusedRowChanged={onFocusedRowChanged}
            onContentReady={(e) => setTotalCount(e.component.totalCount())}
          >
            <FilterRow visible={true} />
            <HeaderFilter visible={true} />
            <ColumnChooser enabled={true} allowSearch={true} />
            <StateStoring
              enabled={true}
              type="custom"
              customLoad={useCallback(() => {
                return JSON.parse(localStorage.getItem(storageKey));
              }, [])}
            />
            <Scrolling mode="virtual" showScrollbar="always" />
            <Column
              width={35}
              caption="No"
              cellRender={(e) => e.row.loadIndex + 1}
            />
            <Column dataField="userId" caption="아이디" alignment="center" />
            <Column
              dataField="userName"
              caption="사용자명"
              alignment="center"
            />
            <Column
              dataField="englishName"
              caption="영문이름"
              alignment="center"
            />
            <Column dataField="mail" caption="이메일" alignment="center" />
            <Column dataField="hp" caption="휴대전화" alignment="center" />
            <Column dataField="tel" caption="직통번호" alignment="center" />
            <Column dataField="fax" caption="팩스번호" alignment="center" />
            <Column
              dataField="userStatusName"
              caption="사용상태"
              alignment="center"
            />
            <Column dataField="companyName" caption="소속법인" />
            <Column dataField="deptName" caption="소속부서" />
            <Column
              dataField="authGroupName"
              caption="권한 그룹"
              alignment="center"
            />
            <Column
              dataField="dutyCodeName"
              caption="직책"
              alignment="center"
            />
            <Column dataField="remark" caption="비고" alignment="center" />
            <Column
              caption="비밀번호 변경"
              cellRender={passwordCellRender}
              alignment="center"
            />
          </DataGrid>
        </PanelGridInit>
      </PanelGridWrapper>
      {editModalVisible && (
        <ChangePasswordModal
          onClose={closeModal}
          closable={true}
          visible={true}
          maskClosable={true}
          selectedRowKey={selectedRowKey}
        />
      )}
    </>
  );
}

export default UserMaster;
