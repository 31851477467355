import { DataGrid } from "devextreme-react";
import {
  Column,
  ColumnChooser,
  FilterRow,
  Format,
  HeaderFilter,
  Paging,
  Scrolling,
  StateStoring,
} from "devextreme-react/data-grid";
import CustomStore from "devextreme/data/custom_store";
import { alert } from "devextreme/ui/dialog";
import moment from "moment";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { shallowEqual, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { sendRequest } from "../../../../_metronic/_helpers/DataSoureHelpers";

import * as requestServer from "./DispatchSearchCrud";
// import DispatchDetail from "./DispatchDetail";
// import * as Modals from "./DispatchModals";

import { useCallback } from "react";
import CustomButton from "../../../components/FunctionButton/CustomButton";
import InputCheckBox from "../../../components/InputForm/InputCheckBox";
import InputDate from "../../../components/InputForm/InputDate";
import InputSelect from "../../../components/InputForm/InputSelect";
import {
  PanelBody,
  PanelHeader,
  PanelInit,
  PanelTitle,
} from "../../../components/Layout/Panel/panel.style";
import {
  DataCount,
  PanelGridInit,
  PanelGridSearchBar,
  PanelGridWrapper,
  SearchItems,
} from "../../../components/Layout/Panel/PanelGrid/panelGrid.style";
import StateStoringSaveAndDeleteButton from "../../../components/StateStoring";
import { useDatagrid } from "../../../hooks/useDatagrid";

function DispatchSearch(props) {
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const [companyName, setCompany] = useState();
  const [disaptchName, setDisaptchTeam] = useState();

  // other state
  const [totalCount, setTotalCount] = useState(0);
  const [search, setSearch] = useState({});
  const dataGridRef = useRef();
  useDatagrid(dataGridRef);

  const storageKey = "dispatchSearchMaster";

  const { menuAll, openItem } = useSelector(
    (state) => ({
      menuAll: state.menu.menuAll,
      openItem: state.menu.openItem,
    }),
    shallowEqual
  );

  const { user } = useSelector(
    (state) => ({ user: state.auth.user.user }),
    shallowEqual
  );

  const dataSource = useMemo(() => {
    const apiUrl = `${process.env.REACT_APP_API_URL}/dispatch`;

    return new CustomStore({
      key: "id",
      load: () => sendRequest(apiUrl, "GET", search),
    });
  }, [search]);

  const initialState = {
    firstDay: moment().format("YYYY-MM-DD"),
    lastDay: moment().format("YYYY-MM-DD"),
    searchType: 2,
    authGroupId: user.authGroupId,
    company: user.companyCode,
    dispatchTeam: user?.deptCode || "",
    importExport: "",
    isBobtail: 0,
    isLoss: false,
  };

  useEffect(() => {
    reset(initialState);
    setSearch(initialState);

    getLookup();
    // eslint-disable-next-line
  }, []);

  async function getLookup() {
    try {
      // const deptNameData = await requestServer.getLookupDatas(
      //   "department/lookup"
      // );
      const companyData = await requestServer.getLookupDatas("company");
      const disaptchTeamData = await requestServer.getLookupDatas(
        "department/isDispatch"
      );

      setDisaptchTeam([
        { code: "", deptName: "전체" },
        ...disaptchTeamData.data,
      ]);
      setCompany([{ id: "", companyName: "전체" }, ...companyData.data]);
      // setDeptName(deptNameData.data);
    } catch (err) {
      alert(err.response.data.message, "오류");
    }
  }

  /* #region modal function -------------------------------------------------------- */

  /* #endregion */

  /* #region dataGrid 함수 -------------------------------------------------------- */

  // const sumCellRender = useCallback((e) => {
  //   let sum = parseInt(e.data.freight || 0) + parseInt(e.data.extraCharge || 0);

  //   return <div>{sum.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</div>;
  // }, []);

  const importExportRender = useCallback((e) => {
    if (e.row.data.importExport === 1) {
      return <div>수입</div>;
    } else {
      return <div>수출</div>;
    }
  }, []);

  const orderStatusRender = useCallback((e) => {
    if (e.data.longStorage === 1) {
      return <div>장기보관</div>;
    } else {
      return <div>{e.text}</div>;
    }
  }, []);

  const divisionRender = useCallback((e) => {
    if (e.value === "order") return <div>오더접수</div>;
    if (e.value === "lclorder") return <div>LCL오더</div>;
    if (e.value === "simple") return <div>간편배차</div>;
    if (e.value === "shuttle") return <div>셔틀배차</div>;
    if (e.value === "otherCo") return <div>용차배차</div>;
  }, []);

  const onCellPrepared = useCallback((e) => {
    if (e.rowType !== "data") return;
    if (
      e.column.caption !== "상차지" &&
      e.column.caption !== "하차지" &&
      e.column.caption !== "하불금액"
    )
      return;

    if (e.column.caption === "상차지") {
      e.cellElement.style.background = e.data.loadPlaceColor || "transparant";
    } else if (e.column.caption === "하차지") {
      e.cellElement.style.background = e.data.unloadPlaceColor || "transparant";
    } else if (e.column.caption === "하불금액") {
      const extraCharge = Number(e.row.data.extraCharge) || 0;
      const freight = Number(e.row.data.freight) || 0;
      const habul = Number(e.row.data.habul) || 0;
      if (extraCharge + freight < habul) {
        e.cellElement.style.background = "red";
      }
    }
  }, []);

  /* #endregion */

  const onSubmit = (data) => {
    // for (const obj in data) {
    //   if (data[obj] === "") {
    //     data[obj] = null;
    //   }
    // }
    setSearch(data);
  };
  return (
    <>
      <PanelInit>
        <PanelHeader>
          <PanelTitle>
            <div>{openItem?.menu?.menuName}</div>
            <ul>
              <li>
                <Link to="/">
                  <i className="flaticon2-shelter icon-1x" />
                </Link>
              </li>
              {openItem?.menu?.parentId && (
                <li>
                  {
                    menuAll.find((menu) => {
                      return menu.menuId === openItem?.menu?.parentId;
                    })?.menu?.menuName
                  }
                </li>
              )}
              {openItem?.menu?.menuName && <li>{openItem?.menu?.menuName}</li>}
            </ul>
          </PanelTitle>
        </PanelHeader>

        <PanelBody row left="100%" right="0">
          <PanelGridWrapper>
            <PanelGridSearchBar onSubmit={handleSubmit(onSubmit)}>
              <SearchItems>
                <label>접수법인</label>
                <InputSelect
                  control={control}
                  errors={errors}
                  name="company"
                  dataSource={companyName}
                  valueExpr="id"
                  displayExpr="companyName"
                  flex={true}
                />
              </SearchItems>

              <SearchItems>
                <label>배차팀</label>
                <InputSelect
                  control={control}
                  errors={errors}
                  name="dispatchTeam"
                  dataSource={disaptchName}
                  valueExpr="code"
                  displayExpr="deptName"
                  flex={true}
                />
              </SearchItems>

              <SearchItems>
                <label>수출/수입</label>
                <InputSelect
                  control={control}
                  errors={errors}
                  name="importExport"
                  dataSource={[
                    { id: "", name: "전체" },
                    { id: 1, name: "수입" },
                    { id: 2, name: "수출" },
                  ]}
                  valueExpr="id"
                  displayExpr="name"
                  flex={true}
                />
              </SearchItems>
              <SearchItems>
                <InputSelect
                  className="label-select"
                  control={control}
                  errors={errors}
                  name="searchType"
                  displayExpr="name"
                  valueExpr="id"
                  dataSource={[
                    { id: 2, name: "운송요청일자" },
                    { id: 1, name: "접수일자" },
                    { id: 3, name: "작업지도착시간" },
                  ]}
                  flex={true}
                />
                <InputDate
                  control={control}
                  errors={errors}
                  name="firstDay"
                  flex={true}
                />
                <div className="to-mark">~</div>
                <InputDate
                  control={control}
                  errors={errors}
                  name="lastDay"
                  flex={true}
                />
              </SearchItems>

              <SearchItems>
                <label>밥테일 여부</label>
                <InputSelect
                  className="label-select"
                  control={control}
                  errors={errors}
                  name="isBobtail"
                  valueExpr="id"
                  displayExpr="name"
                  dataSource={[
                    { id: 0, name: "전체" },
                    { id: 1, name: "밥테일" },
                    { id: 2, name: "밥테일제외" },
                  ]}
                  flex={true}
                />
              </SearchItems>

              <SearchItems>
                <InputCheckBox
                  className="label-select"
                  control={control}
                  errors={errors}
                  label="하불손실조회"
                  name="isLoss"
                />
              </SearchItems>

              <CustomButton
                type="submit"
                height={24}
                layout="solid"
                color="search"
                label="검색"
              />
            </PanelGridSearchBar>

            <PanelGridInit>
              <StateStoringSaveAndDeleteButton
                dataGridRef={dataGridRef}
                storageKey={storageKey}
              />
              <DataCount>{`총 ${totalCount}건`}</DataCount>
              <DataGrid
                className="datagrid-wrap"
                id="DispatchMaster"
                dataSource={dataSource}
                ref={dataGridRef}
                showRowLines={true}
                showBorders={true}
                hoverStateEnabled={true}
                allowColumnResizing={true}
                allowColumnReordering={true}
                columnAutoWidth={true}
                columnResizingMode="widget"
                focusedRowEnabled={true}
                onCellPrepared={onCellPrepared}
                onContentReady={(e) => setTotalCount(e.component.totalCount())}
              >
                <FilterRow visible={true} />
                <HeaderFilter visible={true} />
                <ColumnChooser enabled={true} />
                <StateStoring
                  enabled={true}
                  type="custom"
                  customLoad={useCallback(() => {
                    return JSON.parse(localStorage.getItem(storageKey));
                  }, [])}
                />
                <Scrolling showScrollbar="always" />
                <Paging defaultPageSize={3000} />
                <Column dataField="orderCode" caption="접수번호" />
                <Column
                  dataField="division"
                  caption="구분"
                  cellRender={divisionRender}
                  alignment="center"
                />
                <Column
                  dataField="orderStatus"
                  caption="상태"
                  cellRender={orderStatusRender}
                  alignment="center"
                />
                <Column
                  dataField="importExport"
                  caption="수입/수출"
                  cellRender={importExportRender}
                  alignment="center"
                />
                <Column
                  dataField="forwarder"
                  caption="선사"
                  alignment="center"
                />
                <Column dataField="cargoOwnerName" caption="화주" />
                <Column
                  dataField="realCargoOwner"
                  caption="실화주"
                  alignment="center"
                />
                <Column dataField="billingPlaceName" caption="청구처" />
                <Column dataField="blNo" caption="BL No" alignment="center" />
                <Column dataField="bkNo" caption="BK No" alignment="center" />
                <Column dataField="doNo" caption="DO No" alignment="center" />
                <Column
                  dataField="containerNo"
                  caption="컨테이너 No"
                  alignment="center"
                />
                <Column
                  dataField="sealNo"
                  caption="Seal No"
                  alignment="center"
                />
                <Column
                  dataField="containerType"
                  caption="컨/규격"
                  alignment="center"
                />
                <Column
                  dataField="deliverDate"
                  caption="운송요청일자"
                  alignment="center"
                  dataType="date"
                  format="yyyy-MM-dd"
                />
                <Column
                  dataField="freight"
                  caption="청구금액"
                  alignment="right"
                  dataType="number"
                >
                  <Format type="fixedPoint" />
                </Column>
                <Column
                  dataField="extraCharge"
                  caption="할증금액"
                  alignment="right"
                  dataType="number"
                >
                  <Format type="fixedPoint" />
                </Column>
                <Column
                  dataField="totalPrice"
                  caption="총합계"
                  alignment="right"
                >
                  <Format type="fixedPoint" />
                </Column>
                <Column
                  dataField="habul"
                  caption="하불금액"
                  alignment="right"
                  dataType="number"
                >
                  <Format type="fixedPoint" />
                </Column>
                <Column
                  dataField="dispatchTeam"
                  caption="배차팀"
                  alignment="center"
                />
                <Column
                  dataField="loadPlace"
                  caption="상차지"
                  alignment="center"
                />
                {/* <Column dataField="workWaypoint" caption="작업경유지" /> */}
                <Column dataField="workPlace" caption="작업지" />
                {/* <Column dataField="collectWaypoint" caption="회수경유지" /> */}
                <Column
                  dataField="unloadPlace"
                  caption="하차지"
                  alignment="center"
                />
                <Column
                  dataField="workPlaceArrive"
                  caption="작업지 도착시간"
                  alignment="center"
                  dataType="date"
                  format="yyyy-MM-dd HH:mm:ss"
                />
                <Column
                  dataField="dem"
                  caption="선사반출기한"
                  alignment="center"
                  dataType="date"
                  format="yyyy-MM-dd"
                />
                <Column
                  dataField="returnDate"
                  caption="반납일자"
                  alignment="center"
                  dataType="date"
                  format="yyyy-MM-dd"
                />
                <Column dataField="chassis" caption="샤시" />
                <Column
                  dataField="bobtail"
                  caption="밥테일"
                  dataType="boolean"
                  width="80"
                />
                <Column
                  dataField="fridge"
                  caption="냉동"
                  dataType="boolean"
                  width="70"
                />
                <Column
                  dataField="danger"
                  caption="위험물"
                  dataType="boolean"
                  width="80"
                />
                <Column
                  dataField="tank"
                  caption="탱크"
                  dataType="boolean"
                  width="70"
                />
                <Column
                  dataField="direct"
                  caption="직반입"
                  dataType="boolean"
                  width="80"
                />
                <Column
                  dataField="temperature"
                  caption="온도"
                  alignment="center"
                />
                <Column dataField="humidity" caption="습도" />
                <Column dataField="vent" caption="환풍구" alignment="center" />
                <Column dataField="unNo" caption="un번호" alignment="center" />
                <Column dataField="class" caption="클래스" alignment="center" />
                <Column
                  dataField="extraInfo"
                  caption="특이사항"
                  alignment="center"
                />
              </DataGrid>
            </PanelGridInit>
          </PanelGridWrapper>
        </PanelBody>
      </PanelInit>
    </>
  );
}

export default DispatchSearch;
