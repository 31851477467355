import axios from "axios";

const apiUrl = `${process.env.REACT_APP_API_URL}`;

// lookup get
export function getLookup(param) {
  return axios.get(apiUrl + `/${param}`);
}

// 차량넘버 update
export function changeCarNo(key, data) {
  return axios.put(apiUrl + `/dispatch/carno`, { key, data });
}

// 배차취소
export function cancelDispatch(data) {
  return axios.put(apiUrl + `/dispatch/cancel`, { data });
}

// 운송완료
export function confirmDispatch(data) {
  return axios.put(apiUrl + `/shuttlereturn/finsh`, { data });
}

// 하불등록
export function setHabul(data) {
  return axios.put(apiUrl + `/dispatch/habul`, { data });
}

// 창고작업완료
export function finishWarehouse(data) {
  return axios.put(apiUrl + `/shuttlereturn/finishwarehouse`, { data });
}

// 배차확정
export function dispatchFinish(data) {
  return axios.put(apiUrl + `/dispatch/shuttlefinish`, { data });
}
