import { DataGrid } from "devextreme-react";
import {
  Column,
  ColumnChooser,
  FilterRow,
  Format,
  HeaderFilter,
  Scrolling,
  StateStoring,
} from "devextreme-react/data-grid";
import React, { useCallback, useState } from "react";
import { DataCount } from "../../../../components/Layout/Panel/PanelGrid/panelGrid.style";
import StateStoringSaveAndDeleteButton from "../../../../components/StateStoring";

const SimpleSellFinishGrid = ({
  dataGridRef,
  masterDataSource,
  onFocusedRowChanged,
  onRowPrepared,
}) => {
  const [totalCount, setTotalCount] = useState(0);
  const storageKey = "SimpleSellFinishMaster";
  return (
    <>
      <StateStoringSaveAndDeleteButton
        dataGridRef={dataGridRef}
        storageKey={storageKey}
      />
      <DataCount>{`총 ${totalCount}건`}</DataCount>
      <DataGrid
        id="simpleFinishMaster"
        className="datagrid-wrap"
        ref={dataGridRef}
        dataSource={masterDataSource}
        keyExpr="code"
        showRowLines={true}
        showBorders={true}
        hoverStateEnabled={true}
        allowColumnResizing={true}
        allowColumnReordering={true}
        columnResizingMode="widget"
        focusedRowEnabled={true}
        onRowPrepared={onRowPrepared}
        onFocusedRowChanged={onFocusedRowChanged}
        onContentReady={(e) => setTotalCount(e.component.totalCount())}
      >
        <FilterRow visible={true} />
        <HeaderFilter visible={true} />
        <ColumnChooser enabled={true} />
        <StateStoring
          enabled={true}
          type="custom"
          customLoad={useCallback(() => {
            return JSON.parse(localStorage.getItem(storageKey));
          }, [])}
        />
        <Scrolling
          columnRenderingMode="virtual"
          mode="virtual"
          showScrollbar="always"
          useNative="false"
        />
        <Column
          caption="No"
          cellRender={(e) => e.row.loadIndex + 1}
          width="40"
        />
        <Column dataField="code" caption="접수번호" width="115" />
        <Column dataField="receiveDate" caption="접수일자" width="85" />
        <Column dataField="receiveCoName" caption="접수법인" width="85" />
        <Column
          dataField="importExport"
          caption="수입/수출"
          alignment="center"
          width="95"
        />
        <Column dataField="billingPlaceName" caption="청구처" width="100" />
        <Column dataField="cargoOwnerName" caption="화주" width="100" />
        <Column dataField="realCargoOwner" caption="실화주" width="70" />
        <Column dataField="dispatchTeamName" caption="배차팀" width="70" />
        <Column dataField="orderCoName" caption="발주처" width="100" />
        <Column dataField="forwarder" caption="선사" width="70" />
        <Column dataField="motherShip" caption="모선/항차" width="70" />
        <Column
          dataField="dem"
          caption="모선/항차"
          width="70"
          dataType="date"
          format="yyyy-MM-dd"
        />
        <Column
          dataField="enterDay"
          caption="입항일자"
          width="90"
          dataType="date"
          format="yyyy-MM-dd"
        />
        <Column
          dataField="sailDay"
          caption="출항일자"
          width="90"
          dataType="date"
          format="yyyy-MM-dd"
        />

        <Column
          dataField="containerNo"
          caption="컨테이너 번호"
          alignment="center"
          width="95"
        />
        <Column
          dataField="containerType"
          caption="컨규격"
          alignment="center"
          width="95"
        />
        <Column dataField="loadPlaceCodeName" caption="상차지" width="75" />
        <Column dataField="loadPlaceName" caption="상차지텍스트" width="75" />
        <Column dataField="unloadPlaceCodeName" caption="하차지" width="75" />
        <Column dataField="unloadPlaceName" caption="하차지텍스트" width="75" />
        <Column
          dataField="freight"
          caption="청구운임"
          dataType="number"
          width="75"
        >
          <Format type="fixedPoint" />
        </Column>
        <Column
          dataField="habul"
          caption="하불금액"
          dataType="number"
          width="75"
        >
          <Format type="fixedPoint" />
        </Column>
        <Column dataField="yongchasaName" caption="용차사" width="100" />
        <Column dataField="amount" caption="수량" dataType="number" width="75">
          <Format type="fixedPoint" />
        </Column>
        <Column dataField="amountUnitName" caption="수량단위" width="75" />
        <Column
          dataField="cargoWeight"
          caption="중량"
          dataType="number"
          width="75"
        >
          <Format type="fixedPoint" />
        </Column>
        <Column dataField="blNo" caption="BLNO" width="75" />
        <Column dataField="bkNo" caption="BKNO" width="75" />
        <Column dataField="doNo" caption="DONO" width="75" />
        <Column dataField="bkNo" caption="BKNO" width="75" />
        <Column dataField="masterManager" caption="담당자" width="75" />
        <Column dataField="detailManager" caption="도착지담당자" width="75" />
        <Column
          dataField="pointManageContact"
          caption="도착지담당자연락처"
          width="75"
        />
        <Column dataField="remark" caption="비고" width="75" />
        <Column dataField="innerMemo" caption="내부메모" width="75" />
      </DataGrid>
    </>
  );
};

export default SimpleSellFinishGrid;
