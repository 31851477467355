import React from "react";
import AccountHistoryMaster from "./components/AccountHistoryMaster";

import CustomButton from "../../../components/FunctionButton/CustomButton";
import InputMonth from "../../../components/InputForm/InputMonth";
import InputSelect from "../../../components/InputForm/InputSelect";
import HeaderTitle from "../../../components/Layout/Header/HeaderTitle";
import {
  PanelGridSearchBar,
  PanelGridWrapper,
  PanelMultiGrid,
  SearchItems,
} from "../../../components/Layout/Panel/PanelGrid/panelGrid.style";
import {
  PanelBody,
  PanelBtn,
  PanelHeader,
  PanelInit,
} from "../../../components/Layout/Panel/panel.style";
import Loading from "../../../components/Loading/Loading";
import AccountHistoryLower from "./components/AccountHistoryLower";
import AccountHistoryModal from "./components/AccountHistoryModal";
import EtcCostModal from "./components/EtcCostModal";
import useAccountHistory from "./useAccountHistory";

function AccountHistoryPage(props) {
  const {
    state,

    dataGridRef,
    taxBillGridRef,
    etcCostGridRef,

    searchForm,
    accountRemark,

    pageFunctions,
    masterFunctions,
    detailFunctions,
  } = useAccountHistory();

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = searchForm;

  const { lookupData, taxModal, etcCostModal, isLoading } = state;

  const {
    onSubmit,
    setTaxModalVisible,
    setEtcCostModalVisible,
    loadAccountHistory,
    onValueChanged,
  } = pageFunctions;

  return (
    <>
      {isLoading && <Loading type="page" text="조회중입니다..." />}
      <PanelInit>
        <PanelHeader>
          <HeaderTitle />

          <PanelBtn>
            <CustomButton
              type="button"
              height={24}
              layout="solid"
              color="common"
              label="통장내역불러오기"
              onClick={loadAccountHistory}
            />
          </PanelBtn>
        </PanelHeader>

        <PanelBody row left="100%" right="auto">
          <PanelGridWrapper>
            <PanelGridSearchBar onSubmit={handleSubmit(onSubmit)}>
              <SearchItems>
                <label>접수법인</label>
                <InputSelect
                  className="label-select"
                  control={control}
                  errors={errors}
                  flex={true}
                  name="Biz_No"
                  valueExpr="bizNo"
                  displayExpr="companyName"
                  dataSource={lookupData.company}
                  onValueChanged={onValueChanged}
                />
              </SearchItems>

              <SearchItems>
                <label>계좌번호</label>
                <InputSelect
                  className="label-select"
                  control={control}
                  errors={errors}
                  flex={true}
                  name="bankNumber"
                  valueExpr="bankNumber"
                  displayExpr="bankNumber"
                  dataSource={lookupData.bankAccount}
                />
              </SearchItems>

              <SearchItems>
                <label>기준 월</label>
                <InputMonth
                  className="label-select"
                  control={control}
                  errors={errors}
                  flex={true}
                  name="baseMonth"
                />
              </SearchItems>
              <CustomButton
                type="submit"
                height={24}
                layout="solid"
                color="search"
                label="검색"
              />
            </PanelGridSearchBar>
            <PanelMultiGrid column top="50%" bottom="50%">
              <AccountHistoryMaster
                state={state}
                dataGridRef={dataGridRef}
                masterFunctions={masterFunctions}
              />
              <AccountHistoryLower
                state={state}
                accountRemark={accountRemark}
                taxBillGridRef={taxBillGridRef}
                etcCostGridRef={etcCostGridRef}
                detailFunctions={detailFunctions}
              />
            </PanelMultiGrid>
          </PanelGridWrapper>
        </PanelBody>
      </PanelInit>
      {taxModal && (
        <AccountHistoryModal
          onClose={setTaxModalVisible}
          visible={true}
          maskClosable={false}
          dataGrid={dataGridRef}
        />
      )}
      {etcCostModal && (
        <EtcCostModal
          onClose={setEtcCostModalVisible}
          maskClosable={false}
          visible={true}
          dataGrid={dataGridRef}
        />
      )}
    </>
  );
}

export default AccountHistoryPage;
