import { yupResolver } from "@hookform/resolvers/yup";
import React, { useCallback, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import InputSelect from "../../../components/InputForm/InputSelect";
import InputText from "../../../components/InputForm/InputText";
import InputTextArea from "../../../components/InputForm/InputTextArea";
import AppFileManageMaster from "./TelephoneMaster";

import { confirm } from "devextreme/ui/dialog";
import CustomButton from "../../../components/FunctionButton/CustomButton";
import { ButtonGroup } from "../../../components/FunctionButton/CustomButton/customButton.style";
import HeaderTitle from "../../../components/Layout/Header/HeaderTitle";
import {
  InputFullRow,
  PanelInput,
  PanelInputTitle,
  PanelInputWrapper,
} from "../../../components/Layout/Panel/PanelInput/panelInput.style";
import {
  PanelBody,
  PanelBtn,
  PanelHeader,
  PanelInit,
} from "../../../components/Layout/Panel/panel.style";
import * as requestServer from "./TelephoneCrud";

const TelephonePage = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [selectedRowKey, setSelectedRowKey] = useState(null);
  const [lookupData, setLookupData] = useState({
    tel: [],
  });

  const dataGridRef = useRef();

  /* #region initialState, react-hook-form, yup ---------------------------------------------------- */
  const initialState = {
    telephoneCode: "",
    organ: "",
    manage: "",
    telephoneNumber: "",
    mainPoint: "",
    remark: "",
  };

  const schema = yup
    .object({
      telephoneCode: yup
        .string()
        .nullable()
        .required("구분을 선택해주세요"),
      organ: yup
        .string()
        .nullable()
        .required("기관명을 입력해주세요"),
      manage: yup
        .string()
        .nullable()
        .required("담당자/주무부서를 입력해주세요"),
      telephoneNumber: yup
        .string()
        .nullable()
        .required("전화번호를 입력해주세요"),
      mainPoint: yup.string().nullable(),
      // .required("주요임무(조치사항)을 입력해주세요"),
      remark: yup.string().nullable(),
    })
    .required();

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  /* #endregion */

  async function getLookup() {
    try {
      const telData = await requestServer.getLookup("comcode/detail/70400");
      setLookupData({
        tel: telData.data,
      });
    } catch (err) {
      console.log(err);
    }
  }

  const onSubmit = async (data) => {
    try {
      setIsLoading(true);
      if (!selectedRowKey) {
        await requestServer.postAppFile(data);
      } else {
        await requestServer.updateAppFile(selectedRowKey, data);
      }

      dataGridRef.current.instance.refresh();
      dataGridRef.current.instance.option("focusedRowIndex", -1);
      reset(initialState);
    } catch (err) {
      alert(err.response?.data?.message || "ERROR", "오류");
    } finally {
      setIsLoading(false);
    }
  };

  const onError = (errors) => {
    console.log(errors);
  };

  const onFocusedRowChanged = useCallback((e) => {
    if (!e.row) return;
    const rowData = {
      ...e.row.data,
      telephoneCode: e.row.data.telephoneCode ? e.row.data.telephoneCode : "",
      organ: e.row.data.organ ? e.row.data.organ : "",
      manage: e.row.data.manage ? e.row.data.manage : "",
      telephoneNumber: e.row.data.telephoneNumber
        ? e.row.data.telephoneNumber
        : "",
      mainPoint: e.row.data.mainPoint ? e.row.data.mainPoint : "",
      remark: e.row.data.remark ? e.row.data.remark : "",
    };
    setSelectedRowKey(e.row.key);
    reset(rowData);
    // eslint-disable-next-line
  }, []);

  const onClickDelete = async () => {
    if (!selectedRowKey) return;
    const result = await confirm("정말로 삭제 하시겠습니까?", "경고");
    if (!result) return;
    try {
      setIsLoading(true);

      await requestServer.deleteAppFile(selectedRowKey);

      dataGridRef.current.instance.refresh();
      dataGridRef.current.instance.option("focusedRowIndex", -1);
      setSelectedRowKey(null);
      reset(initialState);
    } catch (err) {
      alert(err.response?.data?.message || "ERROR", "오류");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <PanelInit>
        <PanelHeader>
          <HeaderTitle />
          <PanelBtn>
            <CustomButton
              type="button"
              height={24}
              layout="solid"
              color="new"
              label="새입력"
              onClick={() => {
                reset(initialState);
                setSelectedRowKey(null);
                dataGridRef.current.instance.option("focusedRowIndex", -1);
              }}
            />
            <CustomButton
              type="button"
              height={24}
              layout="solid"
              color="esc"
              label="삭제"
              onClick={onClickDelete}
            />
          </PanelBtn>
        </PanelHeader>

        <PanelBody row left="400px" right="calc(100% - 400px)">
          <PanelInputWrapper
            onSubmit={handleSubmit(onSubmit, onError)}
            openInputBar={true}
          >
            <PanelInputTitle>비상연락망등록</PanelInputTitle>
            <PanelInput grid={2}>
              <InputSelect
                searchEnabled={true}
                control={control}
                errors={errors}
                name="telephoneCode"
                label="구분"
                dataSource={lookupData.tel}
                valueExpr="subCode"
                displayExpr="subName"
                required={true}
              />

              <InputText
                control={control}
                errors={errors}
                name="organ"
                label="기관명"
                required={true}
              />
              <InputText
                control={control}
                errors={errors}
                name="manage"
                label="담당자/주무부서"
                required={true}
              />
              <InputText
                control={control}
                errors={errors}
                name="telephoneNumber"
                label="전화번호"
                required={true}
              />
              <InputFullRow grid={2}>
                <InputTextArea
                  control={control}
                  errors={errors}
                  name="mainPoint"
                  label="주요임무"
                />
              </InputFullRow>
              <InputFullRow grid={2}>
                <InputTextArea
                  control={control}
                  errors={errors}
                  name="remark"
                  label="비고"
                />
              </InputFullRow>
            </PanelInput>

            <ButtonGroup marginTop={20}>
              <CustomButton
                type="submit"
                width="100%"
                height={36}
                layout="solid"
                color="common"
                label="저장"
                className={`${isLoading &&
                  "spinner spinner-white spinner-right"}`}
              />
            </ButtonGroup>
          </PanelInputWrapper>
          <AppFileManageMaster
            lookupData={lookupData}
            getLookup={getLookup}
            onFocusedRowChanged={onFocusedRowChanged}
            dataGridRef={dataGridRef}
          />
        </PanelBody>
      </PanelInit>
    </>
  );
};

export default TelephonePage;
