import {
  Column,
  ColumnChooser,
  DataGrid,
  FilterRow,
  Format,
  HeaderFilter,
  Scrolling,
} from "devextreme-react/data-grid";
import CustomStore from "devextreme/data/custom_store";
import React, { useMemo, useRef, useState } from "react";
import { Modal, ModalBody } from "react-bootstrap";
import { useForm } from "react-hook-form";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../_helpers";
import { sendRequest } from "../../_helpers/DataSoureHelpers";

export function SelectModal({
  onClose,
  maskClosable,
  closable,
  visible,
  onSelect,
  param,
  keyExpr,
}) {
  const [selectRowData, setRowData] = useState();
  const [search, setSearch] = useState({});
  const dataGridRef = useRef();

  const form = useForm();

  const dataSource = useMemo(() => {
    const apiUrl = `${process.env.REACT_APP_API_URL}${param}`;

    return new CustomStore({
      key: `${keyExpr}`,
      load: () => sendRequest(apiUrl, "GET", search),
    });
    // eslint-disable-next-line
  }, [search]);

  // row 클릭하면 focus주기
  const onFocusedRowChanged = (e) => {
    setRowData(e.row.data);
    if (!e.row) return;
  };

  const onMaskClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  const onSubmit = (data) => {
    for (const obj in data) {
      if (data[obj] === "") {
        data[obj] = null;
      }
    }
    setSearch(data);
  };

  return (
    <>
      <div className={"modal-overlay " + (visible ? "active" : "")} />
      <div
        className={"modal-wrap user-list-modal " + (visible ? "active" : "")}
        onClick={maskClosable ? onMaskClick : null}
        tabIndex="-1"
        visible={`${visible}`}
      >
        <Modal.Dialog centered tabIndex="0" size="xl" animation="true">
          <ModalBody>
            <div className="modal-form">
              <div className="modal-form-header">
                <div className="modal-form-tit">
                  {param === "/Sys_CompanyTax_Info" && (
                    <span className="modal-tit">회사 선택</span>
                  )}
                  {param === "/order/lookup" && (
                    <span className="modal-tit">오더 선택</span>
                  )}
                  {param === "/bank" && (
                    <span className="modal-tit">은행 선택</span>
                  )}
                  {param === "/shuttlebasic" && (
                    <span className="modal-tit">셔틀 기본정보 선택</span>
                  )}
                  {param === "/app-file-manage" && (
                    <span className="modal-tit">파일 리스트 선택</span>
                  )}
                </div>
                <button className="btn-modal btn-modal-close" onClick={onClose}>
                  <SVG
                    type="button"
                    src={toAbsoluteUrl(
                      "/media/svg/icons/Custom/close_black_24dp.svg"
                    )}
                    className="close-icon"
                  />
                </button>
              </div>
              <div className="modal-form-body">
                {param === "/Sys_CompanyTax_Info" &&
                  companyTax({
                    dataGridRef,
                    keyExpr,
                    dataSource,
                    onFocusedRowChanged,
                    form,
                    onSubmit,
                  })}
                {param === "/order/lookup" &&
                  lossCost({
                    dataGridRef,
                    keyExpr,
                    dataSource,
                    onFocusedRowChanged,
                    form,
                    onSubmit,
                  })}
                {param === "/bank" &&
                  bankColumn({
                    dataGridRef,
                    keyExpr,
                    dataSource,
                    onFocusedRowChanged,
                    form,
                    onSubmit,
                  })}
                {param === "/shuttlebasic" &&
                  shuttlebasic({
                    dataGridRef,
                    keyExpr,
                    dataSource,
                    onFocusedRowChanged,
                    onSelect,
                    form,
                    onSubmit,
                  })}
                {param === "/app-file-manage" &&
                  appFile({
                    dataGridRef,
                    keyExpr,
                    dataSource,
                    onFocusedRowChanged,
                    onSelect,
                    form,
                    onSubmit,
                  })}
              </div>
              <div className="modal-form-footer order-modal">
                <button
                  type="button"
                  onClick={() => {
                    onSelect(selectRowData);
                  }}
                  className="btn btn-modal btn-save"
                >
                  확인
                </button>
              </div>
            </div>
          </ModalBody>
        </Modal.Dialog>
      </div>
    </>
  );
}

// 각 페이지 DataGrid
// 회사 정보 관리
function companyTax({
  dataGridRef,
  keyExpr,
  dataSource,
  onFocusedRowChanged,
  form,
  onSubmit,
}) {
  return (
    <>
      <div className="grid-search-wrap">
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <span className="search-group">
            <label>회사명</label>
            <input type="text" {...form.register("Company_Name")} />
          </span>

          <button type="submit" className="btn btn-grid-search single-line">
            검색
          </button>
        </form>
      </div>
      <DataGrid
        className="datagrid-wrap multi-line"
        id="SelectModal"
        ref={dataGridRef}
        dataSource={dataSource}
        keyExpr={keyExpr}
        showRowLines={true}
        showBorders={true}
        hoverStateEnabled={true}
        allowColumnResizing={true}
        allowColumnReordering={true}
        columnResizingMode="widget"
        focusedRowEnabled={true}
        onFocusedRowChanged={onFocusedRowChanged}
      >
        <Column dataField="Biz_No" caption="사업자등록번호" />
        <Column dataField="Company_Name" caption="회사명" />
        <Column dataField="Ceo_Name" caption="대표자명" />
        <Column dataField="Zip_Code" caption="우편번호" alignment="center" />
        <Column dataField="Address" caption="주소" />
        <Column dataField="Address_Detail" caption="상세주소" />
        <Column dataField="Biz_Type" caption="업태" alignment="center" />
        <Column dataField="Biz_Item" caption="종목" alignment="center" />
        <Column dataField="Tel" caption="전화번호" alignment="center" />
        <Column dataField="Fax" caption="팩스번호" alignment="center" />
        <Column dataField="Email" caption="이메일" />
        <Column dataField="Remark" caption="비고" alignment="center" />
      </DataGrid>
    </>
  );
}

// 손실 비용 관리
const lossCost = ({
  dataGridRef,
  keyExpr,
  dataSource,
  onFocusedRowChanged,
  form,
  onSubmit,
}) => {
  const importExportRender = (e) => {
    if (e.data.importExport === 1) {
      return <div>수입</div>;
    } else {
      return <div>수출</div>;
    }
  };

  const blRender = (e) => {
    if (e.data.blType === 1) {
      return <div>M</div>;
    } else {
      return <div>H</div>;
    }
  };
  return (
    <>
      <div className="grid-search-wrap">
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <span className="search-group">
            <label>Bl No</label>
            <input type="text" {...form.register("blNo")} />
          </span>
          {/* BL No */}

          <button type="submit" className="btn btn-grid-search single-line">
            검색
          </button>
        </form>
      </div>
      <DataGrid
        className="datagrid-wrap multi-line"
        id="OrderMaster"
        ref={dataGridRef}
        dataSource={dataSource}
        keyExpr={keyExpr}
        showRowLines={true}
        showBorders={true}
        hoverStateEnabled={true}
        allowColumnResizing={true}
        allowColumnReordering={true}
        columnResizingMode="widget"
        focusedRowEnabled={true}
        onFocusedRowChanged={onFocusedRowChanged}
      >
        {/* <HeaderFilter visible={true} />
      <ColumnChooser enabled={true} />
      <Scrolling mode="virtual" /> */}
        <Column
          dataField="code"
          caption="접수번호"
          width="150"
          alignment="center"
        />
        <Column
          dataField="receiveCo_company.companyName"
          caption="접수법인"
          width="100"
        />
        <Column
          dataField="receiveDate"
          caption="접수일자"
          width="150"
          alignment="center"
        />
        <Column
          dataField="importExport"
          caption="수입수출"
          width="100"
          cellRender={importExportRender}
          alignment="center"
        />
        <Column
          dataField="dispatchTeam_department.deptName"
          caption="배차팀"
          width="100"
          alignment="center"
        />
        <Column
          dataField="billingPlace_billingPlace.companyName"
          caption="청구처"
          width="70"
        />
        <Column
          dataField="orderCo_billingPlace.companyName"
          caption="발주처"
          width="70"
        />
        <Column
          dataField="forwarder"
          caption="선사"
          width="70"
          alignment="center"
        />
        <Column
          dataField="shipName"
          caption="선명"
          width="70"
          alignment="center"
        />
        <Column
          dataField="motherShip"
          caption="모선항차"
          width="100"
          alignment="center"
        />
        <Column dataField="bobtail" caption="밥테일여부" width="130" />
        <Column dataField="fridge" caption="냉동여부" width="100" />
        <Column dataField="danger" caption="위험물여부" width="130" />
        <Column
          dataField="dem"
          caption="선사반출기한"
          width="150"
          alignment="center"
          dataType="date"
          format="yyyy-MM-dd"
        />
        <Column
          dataField="blNo"
          caption="B넘버"
          width="180"
          alignment="center"
        />
        <Column
          dataField="blType"
          caption="BL구분"
          width="70"
          cellRender={blRender}
          alignment="center"
        />
        <Column
          dataField="blYear"
          caption="BL년도"
          width="70"
          alignment="center"
        />
        <Column
          dataField="doNo"
          caption="DO넘버"
          width="70"
          alignment="center"
        />
        <Column
          dataField="loadPlace"
          caption="상차치"
          width="70"
          alignment="center"
        />
        <Column
          dataField="unloadPlace"
          caption="하차지"
          width="70"
          alignment="center"
        />
        <Column
          dataField="cargoOwner_billingPlace.companyName"
          caption="화주"
          width="70"
        />
        <Column dataField="confirm" caption="오더확정" width="100" />
      </DataGrid>
    </>
  );
};

// 은행 조회
const bankColumn = ({
  dataGridRef,
  keyExpr,
  dataSource,
  onFocusedRowChanged,
  form,
  onSubmit,
}) => {
  return (
    <>
      <div className="grid-search-wrap">
        <form onSubmit={form.handleSubmit(onSubmit)}>
          {/* <span className="search-group">
            <label>회사명</label>
            <input type="text" {...register("companyName")} />
          </span> */}

          <button type="submit" className="btn btn-grid-search single-line">
            검색
          </button>
        </form>
      </div>
      <DataGrid
        className="datagrid-wrap single-line has-toolbar"
        id="SelectModal"
        ref={dataGridRef}
        dataSource={dataSource}
        showRowLines={true}
        showBorders={true}
        hoverStateEnabled={true}
        allowColumnResizing={true}
        allowColumnReordering={true}
        columnResizingMode="widget"
        keyExpr={keyExpr}
        focusedRowEnabled={true}
        onFocusedRowChanged={onFocusedRowChanged}
      >
        <FilterRow visible={true} />
        <HeaderFilter visible={true} />
        <ColumnChooser enabled={true} />
        <Scrolling mode="virtual" />
        {/* <Column caption="접수번호"/> */}
        <Column dataField="Biz_No" caption="사업자 번호" />
        <Column dataField="Manager" caption="담당자" alignment="center" />
        <Column
          dataField="Manager_Tel"
          caption="담당자 전화번호"
          alignment="center"
        />
        <Column dataField="Bank_Code_comCodeDetail.subName" caption="은행" />
        <Column dataField="Account_No" caption="계좌번호" />
        <Column
          dataField="Biz_No_Sys_CompanyTax_Info.Company_Name"
          caption="회사명"
        />
        <Column
          dataField="Biz_No_Sys_CompanyTax_Info.Zip_Code"
          caption="우편번호"
        />
        <Column dataField="Biz_No_Sys_CompanyTax_Info.Address" caption="주소" />
        <Column
          dataField="Biz_No_Sys_CompanyTax_Info.Address_Detail"
          caption="상세주소"
        />
        <Column dataField="Biz_No_Sys_CompanyTax_Info.Tel" caption="전화번호" />
        <Column dataField="Biz_No_Sys_CompanyTax_Info.Fax" caption="팩스번호" />
        <Column dataField="Biz_No_Sys_CompanyTax_Info.Email" caption="이메일" />
        <Column dataField="Remark" caption="비고" />
      </DataGrid>
    </>
  );
};

const shuttlebasic = ({
  dataGridRef,
  keyExpr,
  dataSource,
  onFocusedRowChanged,
  onSelect,
  form,
  onSubmit,
}) => {
  const importExportCellRender = (e) => {
    if (e.value === 1) return "수입";
    if (e.value === 2) return "수출";
    return;
  };

  const onRowDblClick = (e) => {
    onSelect(e.data);
  };
  return (
    <>
      <div className="grid-search-wrap">
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <span className="search-group">
            <label>컨테이너 No</label>
            <input type="text" {...form.register("containerNo")} />
          </span>

          <button type="submit" className="btn btn-grid-search single-line">
            검색
          </button>
        </form>
      </div>
      <DataGrid
        className="datagrid-wrap single-line has-toolbar"
        id="SelectModal"
        ref={dataGridRef}
        dataSource={dataSource}
        showRowLines={true}
        showBorders={true}
        hoverStateEnabled={true}
        allowColumnResizing={true}
        allowColumnReordering={true}
        columnResizingMode="widget"
        keyExpr={keyExpr}
        focusedRowEnabled={true}
        onRowDblClick={onRowDblClick}
        onFocusedRowChanged={onFocusedRowChanged}
      >
        <FilterRow visible={true} />
        <HeaderFilter visible={true} />
        <ColumnChooser enabled={true} />
        <Column caption="No" cellRender={(e) => e.row.loadIndex + 1} />
        <Column
          dataField="importExport"
          caption="수입/수출"
          cellRender={importExportCellRender}
        />
        <Column dataField="cargoOwnerName" caption="화주" />
        <Column dataField="billingPlaceName" caption="청구처" />
        <Column dataField="containerType" caption="컨규격" />
        <Column dataField="containerNo" caption="컨테이너No" />
        <Column dataField="loadPlaceName" caption="상차지" />
        <Column dataField="workPlaceName" caption="작업지" />
        <Column dataField="unloadPlaceName" caption="하차지" />
        <Column dataField="freight" caption="청구운임" dataType="number">
          <Format type="fixedPoint" />
        </Column>
        <Column
          dataField="habul_01"
          caption="상차지 하불금액"
          dataType="number"
        >
          <Format type="fixedPoint" />
        </Column>
        <Column
          dataField="habul_02"
          caption="하차지 하불금액"
          dataType="number"
        >
          <Format type="fixedPoint" />
        </Column>
      </DataGrid>
    </>
  );
};

const appFile = ({
  dataGridRef,
  keyExpr,
  dataSource,
  onFocusedRowChanged,
  onSelect,
  form,
  onSubmit,
}) => {
  const onRowDblClick = (e) => {
    onSelect(e.data);
  };
  return (
    <>
      <div className="grid-search-wrap">
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <span className="search-group">
            <label>관리명</label>
            <input type="text" {...form.register("File_Name")} />
          </span>

          <button type="submit" className="btn btn-grid-search single-line">
            검색
          </button>
        </form>
      </div>
      <DataGrid
        className="datagrid-wrap single-line has-toolbar"
        id="SelectModal"
        ref={dataGridRef}
        dataSource={dataSource}
        showRowLines={true}
        showBorders={true}
        hoverStateEnabled={true}
        allowColumnResizing={true}
        allowColumnReordering={true}
        columnResizingMode="widget"
        keyExpr={keyExpr}
        focusedRowEnabled={true}
        onRowDblClick={onRowDblClick}
        onFocusedRowChanged={onFocusedRowChanged}
      >
        <FilterRow visible={true} />
        <HeaderFilter visible={true} />
        <ColumnChooser enabled={true} />
        <Column caption="No" cellRender={(e) => e.row.loadIndex + 1} />

        <Column dataField="fileListCodeName" caption="구분" />
        <Column dataField="File_Name" caption="관리명" />
        <Column dataField="File_Info" caption="파일" />
        <Column dataField="Reg_Date" caption="등록일" />
        <Column dataField="useYnName" caption="사용여부" />
        <Column dataField="Remark" caption="비고" />
      </DataGrid>
    </>
  );
};
