import { alert, confirm } from "devextreme/ui/dialog";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import CustomButton from "../../../components/FunctionButton/CustomButton";
import InputDate from "../../../components/InputForm/InputDate";
import InputSelect from "../../../components/InputForm/InputSelect";
import InputText from "../../../components/InputForm/InputText";
import HeaderTitle from "../../../components/Layout/Header/HeaderTitle";
import {
  PanelGridSearchBar,
  PanelGridWrapper,
  PanelMultiGrid,
  SearchItems,
} from "../../../components/Layout/Panel/PanelGrid/panelGrid.style";
import {
  PanelBody,
  PanelBtn,
  PanelHeader,
  PanelInit,
} from "../../../components/Layout/Panel/panel.style";
import { useDatagrid } from "../../../hooks/useDatagrid";
import { cancelTax, getLookup } from "./FinishManageCrud";
import DeliverDateModal from "./components/DeliverDateModal";
import FinishManageLower from "./components/FinishManageLower";
import FinishManageUpper from "./components/FinishManageUpper";
import TaxIssueDateModal from "./components/TaxIssueDateModal";
import TaxIssueModal from "./components/TaxIssueModal";

const FinishManagePage = () => {
  const [search, setSearch] = useState({});
  const [selectedRow, setSelectedRow] = useState({});
  const [taxIssueModalVisible, setTaxIssueModalVisible] = useState(false);
  const [taxIssueDateModalVisible, setTaxIssueDateModalVisible] = useState(
    false
  );
  const [deliverDateModal, setDeliverDateModal] = useState(false);
  const finishUpperRef = useRef();
  const finishLowerRef = useRef();

  useDatagrid(finishUpperRef, finishLowerRef);

  const [lookupData, setLookupData] = useState({
    company: [],
    disaptchName: [],
  });

  /* #region useForm */
  const initialState = {
    receiveCo: "0",
    searchType: "0",
    firstDay: moment()
      .subtract(14, "days")
      .format("YYYY-MM-DD"),
    lastDay: moment().format("YYYY-MM-DD"),
    isDivision: "0",
    isFinish: "0",
    isTaxIssue: "0",
    // dispatchTeam: "0",
    companyName: "",
  };

  const {
    watch,
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: initialState,
  });
  /* #endregion */

  /* #region useEffect */
  useEffect(() => {
    getLookupData();
    setSearch(initialState);
    // eslint-disable-next-line
  }, []);
  /* #endregion */

  const changeDeliverDate = async () => {
    const list = finishUpperRef.current.instance.getSelectedRowsData();

    if (list.length === 0) {
      await alert("데이터를 선택해주세요", "오류");
      return;
    }
    setDeliverDateModal(true);
  };

  const openModal = () => {
    if (!selectedRow.completeNo) {
      alert("데이터를 선택해주세요", "오류");
      return;
    }

    if (
      selectedRow.closeStatus === "정산" ||
      selectedRow.closeStatus === "예약발행"
    ) {
      // 정산된 데이터
      setTaxIssueModalVisible(true);
    } else {
      // 정산되지않은 데이터
      setTaxIssueModalVisible(true);
    }
  };

  const cancelFinish = async () => {
    if (selectedRow.taxStatus === "발행 취소") {
      alert("이미 정산 취소된 데이터입니다.", "오류");
      return;
    }

    const result = await confirm("정산취소 하시겠습니까?", "확인");
    if (!result) return;

    try {
      const result = await cancelTax(selectedRow);

      if (result.data.message) {
        alert(result.data.message, "확인");
      }

      finishUpperRef.current.instance.refresh();
      resetSelectedRow();
    } catch (error) {
      console.log(error);
    }
  };

  async function getLookupData() {
    try {
      const dispatchTeamData = await getLookup("department/isDispatch");
      const companyData = await getLookup("company");

      setLookupData({
        company: [
          {
            id: "0",
            companyName: "전체",
          },
          ...companyData.data,
        ],
        disaptchName: [
          {
            code: "0",
            deptName: "전체",
          },
          ...dispatchTeamData.data,
        ],
      });
    } catch (err) {
      console.log(err);
    }
  }

  function handleMonthChange({ value }) {
    const currentYear = moment(watch("firstDay")).year();
    const startOfMonth = moment(`${currentYear}-${value}-01`)
      .startOf("month")
      .format("YYYY-MM-DD");
    const endOfMonth = moment(`${currentYear}-${value}-01`)
      .endOf("month")
      .format("YYYY-MM-DD");

    setValue("firstDay", startOfMonth);
    setValue("lastDay", endOfMonth);
  }

  const resetSelectedRow = () => {
    setSelectedRow({});
    finishUpperRef.current.instance.option("focusedRowIndex", -1);
  };

  return (
    <>
      <PanelInit>
        <PanelHeader>
          <HeaderTitle />

          <PanelBtn>
            <CustomButton
              type="button"
              height={24}
              layout="solid"
              color="common"
              label="운송날짜변경"
              onClick={changeDeliverDate}
            />
            <CustomButton
              type="button"
              height={24}
              layout="solid"
              color="common"
              label="세금계산서(발행)"
              onClick={openModal}
            />
            <CustomButton
              type="button"
              height={24}
              layout="solid"
              color="common"
              label="정산취소"
              onClick={cancelFinish}
              disabled={selectedRow.closeStatus ? false : true}
            />
          </PanelBtn>
        </PanelHeader>

        <PanelBody row left="100%" right="auto">
          <PanelGridWrapper>
            <PanelGridSearchBar
              onSubmit={handleSubmit((data) => setSearch(data))}
            >
              <SearchItems>
                <label>접수법인</label>
                <InputSelect
                  className="label-select"
                  control={control}
                  errors={errors}
                  name="receiveCo"
                  displayExpr="companyName"
                  valueExpr="id"
                  dataSource={lookupData.company}
                  flex={true}
                />
              </SearchItems>
              <SearchItems>
                <label>날짜구분</label>
                <InputSelect
                  className="label-select"
                  control={control}
                  errors={errors}
                  dataSource={[
                    { id: "0", name: "마감일자" },
                    { id: "1", name: "접수일자" },
                    { id: "2", name: "운송일자" },
                  ]}
                  valueExpr="id"
                  displayExpr="name"
                  name="searchType"
                  flex={true}
                />
                <InputDate
                  className="label-select"
                  control={control}
                  errors={errors}
                  name="firstDay"
                  flex={true}
                />
                ~
                <InputDate
                  className="label-select"
                  control={control}
                  errors={errors}
                  name="lastDay"
                  flex={true}
                />
              </SearchItems>
              <SearchItems>
                <label>월선택</label>
                <InputSelect
                  className="label-select"
                  control={control}
                  errors={errors}
                  dataSource={[
                    { id: "01", name: "1월" },
                    { id: "02", name: "2월" },
                    { id: "03", name: "3월" },
                    { id: "04", name: "4월" },
                    { id: "05", name: "5월" },
                    { id: "06", name: "6월" },
                    { id: "07", name: "7월" },
                    { id: "08", name: "8월" },
                    { id: "09", name: "9월" },
                    { id: "10", name: "10월" },
                    { id: "11", name: "11월" },
                    { id: "12", name: "12월" },
                  ]}
                  valueExpr="id"
                  displayExpr="name"
                  name="month"
                  flex={true}
                  onValueChanged={handleMonthChange}
                />
              </SearchItems>
              <SearchItems>
                <label>구분</label>
                <InputSelect
                  className="label-select"
                  control={control}
                  errors={errors}
                  dataSource={[
                    { id: "0", name: "전체" },
                    { id: "청구처", name: "청구처" },
                    { id: "용차사", name: "용차사" },
                    { id: "기사", name: "기사" },
                  ]}
                  valueExpr="id"
                  displayExpr="name"
                  name="isDivision"
                  flex={true}
                />
              </SearchItems>
              <SearchItems>
                <label>정산구분</label>
                <InputSelect
                  className="label-select"
                  control={control}
                  errors={errors}
                  dataSource={[
                    { id: "0", name: "전체" },
                    { id: "1", name: "정산" },
                    { id: "2", name: "미정산" },
                  ]}
                  valueExpr="id"
                  displayExpr="name"
                  name="isFinish"
                  flex={true}
                />
              </SearchItems>

              <SearchItems>
                <label>청구처/용차사/차량번호</label>
                <InputText
                  className="label-select"
                  control={control}
                  errors={errors}
                  name="companyName"
                  flex={true}
                />
              </SearchItems>

              <SearchItems>
                <label>계산서 상태</label>
                <InputSelect
                  className="label-select"
                  control={control}
                  errors={errors}
                  dataSource={[
                    { id: "0", name: "전체" },
                    { id: "1", name: "미발행" },
                    { id: "2", name: "발행" },
                    { id: "3", name: "예약발행" },
                  ]}
                  valueExpr="id"
                  displayExpr="name"
                  name="isTaxIssue"
                  flex={true}
                />
              </SearchItems>
              <CustomButton
                type="submit"
                height={24}
                layout="solid"
                color="search"
                label="검색"
              />
            </PanelGridSearchBar>
            <PanelMultiGrid column top="60%" bottom="40%">
              <FinishManageUpper
                search={search}
                finishUpperRef={finishUpperRef}
                setSelectedRow={setSelectedRow}
                onRowDblClick={openModal}
              />
              <FinishManageLower
                search={search}
                finishLowerRef={finishLowerRef}
                selectedRow={selectedRow}
              />
            </PanelMultiGrid>
          </PanelGridWrapper>
        </PanelBody>
      </PanelInit>
      {taxIssueDateModalVisible && (
        <TaxIssueDateModal
          onClose={() => {
            resetSelectedRow();
            setTaxIssueDateModalVisible(false);
            setTaxIssueModalVisible(true);
          }}
          closable={true}
          visible={true}
          maskClosable={false}
          selectedRow={selectedRow}
          setSelectedRow={setSelectedRow}
          setTaxIssueDateModalVisible={setTaxIssueDateModalVisible}
        />
      )}
      {taxIssueModalVisible && (
        <TaxIssueModal
          onClose={() => {
            resetSelectedRow();
            setTaxIssueModalVisible(false);
          }}
          closable={true}
          visible={true}
          maskClosable={false}
          selectedRow={selectedRow}
          finishUpperRef={finishUpperRef}
        />
      )}
      {deliverDateModal && (
        <DeliverDateModal
          onClose={() => {
            resetSelectedRow();
            setDeliverDateModal(false);
          }}
          closable={true}
          visible={true}
          maskClosable={false}
          finishUpperRef={finishUpperRef}
          finishLowerRef={finishLowerRef}
        />
      )}
    </>
  );
};

export default FinishManagePage;
