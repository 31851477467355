import { Button, DataGrid } from "devextreme-react";
import {
  Column,
  Editing,
  FilterRow,
  HeaderFilter,
  KeyboardNavigation,
  RequiredRule,
  Scrolling,
} from "devextreme-react/data-grid";
import CustomStore from "devextreme/data/custom_store";
import { alert } from "devextreme/ui/dialog";
import React, { useMemo } from "react";
import { useForm } from "react-hook-form";
import { sendRequest } from "../../../../_metronic/_helpers/DataSoureHelpers";
import CustomButton from "../../../components/FunctionButton/CustomButton";
import { ButtonGroup } from "../../../components/FunctionButton/CustomButton/customButton.style";
import InputLayout from "../../../components/InputForm/InputLayout";
import {
  InputFullRow,
  PanelInput,
  PanelInputGrid,
  PanelInputTitle,
  PanelInputWrapper,
} from "../../../components/Layout/Panel/PanelInput/panelInput.style";
import * as requestServer from "./ComCodeCrud";

export default function ComCodeDetailList({
  rowData,
  dataGridRef,
  dataGridDetailRef,
}) {
  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm();
  // const [remove, setRemove] = useState(false);
  const dataSource = useMemo(() => {
    if (rowData) {
      dataGridDetailRef.current &&
        dataGridDetailRef.current.instance.cancelEditData();
      reset({
        groupCode: rowData.data.groupCode,
        groupName: rowData.data.groupName,
        remark: rowData.data.remark,
      });
      const getApiUrl = `${process.env.REACT_APP_API_URL}/comcode/detail/${rowData.key}`;
      const apiUrl = `${process.env.REACT_APP_API_URL}/comcode/detail`;
      console.log(getApiUrl);

      return new CustomStore({
        key: "id",
        load: () => sendRequest(getApiUrl, "GET"),
        insert: (values) =>
          sendRequest(apiUrl, "POST", {
            ...values,
            groupCode: watch("groupCode"),
          }),
        update: (id, values) =>
          sendRequest(apiUrl, "PUT", {
            id,
            values,
            groupCode: watch("groupCode"),
          }),
        remove: (id) =>
          sendRequest(apiUrl, "DELETE", { id, groupCode: watch("groupCode") }),
      });
    } else {
      reset({
        groupCode: "",
        groupName: "",
        remark: "",
      });

      const apiUrl = `${process.env.REACT_APP_API_URL}/comcode/detail`;

      return new CustomStore({
        key: "id",
        load: () => {},
        insert: (values) =>
          sendRequest(apiUrl, "POST", {
            ...values,
            groupCode: watch("groupCode"),
          }),
      });
    }
    // eslint-disable-next-line
  }, [rowData, reset]);

  const onSubmit = async (data) => {
    if (!data.groupCode || !data.groupName) {
      alert("그룹코드정보를 입력해주세요", "오류");
      return;
    }
    const master = data;
    const detail = dataGridDetailRef.current.instance.option("editing.changes");
    const detailIsValid = await dataGridDetailRef.current.instance
      .getController("validating")
      .validate(true);
    if (!detailIsValid) return false;

    try {
      if (rowData) {
        await requestServer.updateComCodeMaster({ master, detail });

        dataGridDetailRef.current.instance.option("editing.changes", []);
        dataGridDetailRef.current.instance.refresh();
      } else {
        await requestServer.createComCodeMaster({ master, detail });

        dataGridRef.current.instance.refresh();
        reset({
          groupCode: "",
          groupName: "",
          remark: "",
        });
        dataGridDetailRef.current.instance.option("editing.changes", []);
      }
    } catch (err) {
      alert(err.response.data.message, "오류");
    }
  };

  const onSaved = () => {
    const data = watch();
    setTimeout(() => {
      const index = dataGridRef.current.instance.getRowIndexByKey(
        data.groupCode
      );
      dataGridRef.current.instance.option("focusedRowIndex", index);
    }, 300);
  };

  const onToolbarPreparing = (e) => {
    var toolbarItems = e.toolbarOptions.items;

    toolbarItems.forEach((cur) => {
      if (cur.name === "saveButton" || cur.name === "revertButton") {
        cur.visible = false;
      }
    });
  };

  const headerCellRender = (data) => {
    return (
      <p style={{ color: "#FF8D23", margin: "0" }}>{data.column.caption}</p>
    );
  };

  const onInitNewRow = (e) => {
    e.data.useYn = true;
  };

  // const removeButtonRender = (e) => {
  //   return (
  //     <div>
  //       <button
  //         onClick={() => {
  //           try {
  //             dataGridRef.current.instance.deleteRow(e.rowIndex);
  //             setRemove(true);
  //           } catch (error) {
  //             alert(error.response.data.message, "오류");
  //             return;
  //           }
  //         }}
  //         className="btn btn-grid btn-grid-change pw-change"
  //       >
  //         삭제
  //       </button>
  //     </div>
  //   );
  // };

  // const editClick = (e) => {
  //   dataGridRef.current.instance.editRow(e.rowIndex);
  //   dataGridRef.current.instance.cancelEditData();
  // };

  return (
    <PanelInputWrapper onSubmit={handleSubmit(onSubmit)} openInputBar={true}>
      <PanelInputTitle>그룹코드 등록</PanelInputTitle>
      <PanelInput grid={2}>
        <InputLayout label="그룹코드" errors={errors} name="groupCode">
          <input
            type="text"
            {...register("groupCode")}
            disabled={rowData ? true : false}
          />
        </InputLayout>
        <InputLayout label="그룹코드명" errors={errors} name="groupName">
          <input type="text" {...register("groupName")} />
        </InputLayout>
        <InputFullRow grid={2}>
          <InputLayout label="비고" errors={errors} name="remark" textArea>
            <textarea type="text" {...register("remark")} />
          </InputLayout>
        </InputFullRow>
      </PanelInput>
      <PanelInputGrid>
        <DataGrid
          id="comCodeDetail"
          className="datagrid-wrap"
          dataSource={dataSource}
          ref={dataGridDetailRef}
          allowColumnResizing={true}
          columnAutoWidth={true}
          showRowLines={true}
          hoverStateEnabled={true}
          showBorders={true}
          columnResizingMode="widget"
          height={"calc(100vh - 307px)"}
          onInitNewRow={onInitNewRow}
          onSaving={onSubmit}
          onSaved={onSaved}
          onToolbarPreparing={onToolbarPreparing}
        >
          <FilterRow visible={true} />
          <HeaderFilter visible={true} />
          <Scrolling mode="virtual" />
          <Editing //
            mode="batch"
            allowUpdating={true}
            allowAdding={true}
            allowDeleting={true}
            startEditAction="dblClick"
          ></Editing>
          <KeyboardNavigation
            editOnKeyPress={true}
            enterKeyAction="moveFocus"
            enterKeyDirection="row"
          />
          <Column
            width={70}
            caption="No"
            cellRender={(e) => e.row.loadIndex + 1}
          />
          <Column
            dataField="subCode"
            caption="하위코드"
            allowEditing={true}
            headerCellRender={headerCellRender}
          >
            <RequiredRule message="하위코드를 입력해주세요" />
          </Column>
          <Column
            dataField="subName"
            caption="하위코드명"
            allowEditing={true}
            headerCellRender={headerCellRender}
          >
            <RequiredRule message="하위코드명을 입력해주세요" />
          </Column>
          <Column dataField="extra1" caption="여유필드1" allowEditing={true} />
          <Column dataField="extra2" caption="여유필드2" allowEditing={true} />
          <Column dataField="extra3" caption="여유필드3" allowEditing={true} />
          <Column
            dataField="sortSeq"
            caption="정렬순서"
            dataType="number"
            allowEditing={true}
            headerCellRender={headerCellRender}
          >
            <RequiredRule message="정렬순서를 입력해주세요" />
          </Column>
          <Column
            dataField="useYn"
            caption="사용"
            dataType="boolean"
            allowEditing={true}
          />
          <Column dataField="remark" caption="비고" />
          <Column type="buttons">
            <Button name="delete" text="삭제" />
            <Button name="edit" />
          </Column>

          {/* <Column alignment="center" cellRender={removeButtonRender}></Column> */}
        </DataGrid>
      </PanelInputGrid>
      <ButtonGroup marginTop={20}>
        <CustomButton
          type="submit"
          width="100%"
          height={36}
          layout="solid"
          color="common"
          label="저장"
        />
      </ButtonGroup>
    </PanelInputWrapper>
  );
}
