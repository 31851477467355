import { DataGrid } from "devextreme-react";
import {
  Column,
  ColumnChooser,
  Format,
  HeaderFilter,
} from "devextreme-react/data-grid";
import React, { useEffect, useMemo, useState } from "react";
import * as requestServer from "./ShuttleCrud";

import CustomStore from "devextreme/data/custom_store";
import { useForm } from "react-hook-form";
import { sendRequest } from "../../../../_metronic/_helpers/DataSoureHelpers";
import InputSelect from "../../../components/InputForm/InputSelect";

function ShuttleMaster({ onFocusedRowChanged, dataGridRef }) {
  const [totalCount, setTotalCount] = useState(0);
  const [search, setSearch] = useState({});
  const [conType, setConType] = useState();
  const dataSource = useMemo(() => {
    const apiUrl = `${process.env.REACT_APP_API_URL}/shuttlecharge`;

    return new CustomStore({
      key: "id",
      load: () => sendRequest(apiUrl, "GET", search),
      insert: (values) => sendRequest(apiUrl, "POST", values),
      update: (key, values) => sendRequest(apiUrl, "PUT", { key, values }),
      remove: (key) => sendRequest(apiUrl, "DELETE", { key }),
    });
  }, [search]);

  useEffect(() => {
    async function getLookup() {
      try {
        const conTypeData = await requestServer.getLookup("code/conType");

        setConType([{ containerType: "", name: "전체" }, ...conTypeData.data]);
      } catch (err) {
        console.log(err);
      }
    }

    getLookup();
  }, []);

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    for (const obj in data) {
      if (data[obj] === "") {
        data[obj] = null;
      }
    }
    setSearch(data);
  };

  return (
    <>
      <div className="grid-search-wrap">
        <form onSubmit={handleSubmit(onSubmit)}>
          <span className="search-group">
            <label>컨/규격</label>
            <InputSelect
              className="label-select"
              control={control}
              errors={errors}
              name="containerType"
              displayExpr="codeName"
              valueExpr="codeName"
              dataSource={conType}
              flex={true}
            />
          </span>
          <button type="submit" className="btn btn-grid-search">
            검색
          </button>
        </form>
      </div>
      <div className="grid-component has-toolbar">
        <div className="grid-total">{`총 ${totalCount}건`}</div>
        <DataGrid
          className="datagrid-wrap"
          id="shuttleMaster"
          ref={dataGridRef}
          dataSource={dataSource}
          showRowLines={true}
          showBorders={true}
          hoverStateEnabled={true}
          allowColumnResizing={true}
          columnResizingMode="widget"
          keyExpr="id"
          focusedRowEnabled={true}
          onFocusedRowChanged={onFocusedRowChanged}
          onContentReady={(e) => setTotalCount(e.component.totalCount())}
        >
          <HeaderFilter visible={true} />
          <ColumnChooser enabled={true} />
          {/* <StateStoring
            enabled={true}
            type="localStorage"
            storageKey="shuttleStorage"
          /> */}
          <Column dataField="serialNo" caption="일련번호" alignment="center" />
          <Column dataField="loadPlace" caption="상차지" alignment="center" />
          <Column dataField="unloadPlace" caption="하차지" alignment="center" />
          <Column
            dataField="containerType"
            caption="컨/규격"
            alignment="center"
          />
          <Column dataField="isOneWay" caption="편도여부" />
          <Column
            dataField="chargedCost"
            caption="청구금액"
            alignment="right"
            dataType="number"
          >
            <Format type="fixedPoint" />
          </Column>
          <Column
            dataField="habulCost"
            caption="하불금액"
            alignment="right"
            dataType="number"
          >
            <Format type="fixedPoint" />
          </Column>
          <Column dataField="remark" caption="비고" alignment="center" />
        </DataGrid>
      </div>
    </>
  );
}

export default ShuttleMaster;
