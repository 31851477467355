import styled from "styled-components";

export const PanelInputWrapper = styled.form`
  overflow: auto;
  display: flex;
  width: 100%;
  height: 100%;
  padding: 15px;
  background: ${(props) => props.theme.colors.panelInputBg};
  border-right: 1px solid ${(props) => props.theme.colors.layoutBorder};
  flex-direction: column;
  flex-grow: 1;
  opacity: ${(props) => (props.openInputBar === true ? "1" : "0")};
  transition: opacity 0.3s ease-in-out;
`;

export const PanelSubInputWrapper = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  height: calc(100%);
  padding: 15px;
  grid-column: ${(props) => (props.fullsize ? "1/3" : "1/2")};
  background: ${(props) => props.theme.colors.panelInputBg};
  border-top: 1px solid ${(props) => props.theme.colors.layoutBorder};
  flex-direction: column;
  opacity: ${(props) => (props.openInputBar === true ? "1" : "0")};
  transition: opacity 0.3s ease-in-out;
`;

export const PanelInputTitle = styled.div`
  position: ${(props) =>
    props.position === "absolute" ? "absolute" : "initial"};
  display: flex;
  width: 100%;
  height: 26px;
  margin-bottom: 15px;
  font-size: 1.4rem;
  font-weight: 600;
  align-items: center;
  justify-content: space-between;
  top: ${(props) => (props.top ? props.top + "px" : "")};
`;

export const PanelPreviewGrid = styled.table`
  margin-bottom: 10px;
  background: ${(props) => props.theme.colors.white};
  border: 1px solid ${(props) => props.theme.colors.gridBorder};

  tr {
    & + tr {
      border-top: 1px solid ${(props) => props.theme.colors.gridBorder};
    }

    td {
      height: 20px;
      padding: 3px;
      font-size: 1.2rem;
      vertical-align: middle;

      &:nth-of-type(2n-1) {
        color: ${(props) => props.theme.colors.white};
        background: ${(props) => props.theme.colors.gridDetailHeader};

        &.td-fullrow {
          background: ${(props) => props.theme.colors.white};
        }
      }

      & + td {
        border-left: 1px solid ${(props) => props.theme.colors.gridBorder};
      }

      &.td-fullrow {
        div {
          display: flex;
          align-items: center;
          justify-content: space-around;
        }
      }

      .dx-checkbox-container {
        .dx-checkbox-icon {
          width: 12px;
          height: 12px;
        }

        .dx-checkbox-text {
          font-size: 1rem;
        }
      }
    }
  }
`;

export const PanelInput = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: ${(props) =>
    props.grid === 2
      ? "repeat(2, calc(50% - 5px))"
      : props.grid === 3
      ? "5fr 4fr 4fr"
      : props.grid === 4
      ? "repeat(4, calc(25% - 7.5px))"
      : "repeat(5, calc(20% - 7px))"};
  grid-gap: 3px 10px;

  .total-count {
    font-size: 1.2rem;
    font-weight: 600;
  }
`;

export const InputSearchBtnSet = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: calc(100% - 29px) 26px;
  grid-gap: 3px;
`;

export const InputRadioSet = styled.div`
  display: flex;
  width: ${(props) => (props.width === "full" ? "100%" : "unset")};
  min-height: 26px;
  padding: 0px 5px;
  align-items: center;
  justify-content: center;
  background: ${(props) => props.theme.colors.white};
  border: 1px solid ${(props) => props.theme.colors.inputDividerBorder};
  border-radius: 4px;
`;

export const InputDivide = styled.div`
  height: 1px;
  width: 100%;
  background: ${(props) => props.theme.colors.inputDividerBorder};
`;

export const PanelInputGrid = styled.div`
  margin-top: 10px;

  .datagrid-wrap {
    overflow: auto;
    height: 100%;

    .dx-treelist-container,
    .dx-datagrid {
      background-color: transparent;

      .dx-datagrid-header-panel,
      .dx-treelist-header-panel {
        .dx-toolbar {
          background-color: transparent;

          .dx-toolbar-after {
            right: unset;
            padding-left: 0;

            .dx-item {
              padding-left: 0;
            }
          }
        }
      }

      .dx-datagrid-headers,
      .dx-treelist-headers {
        color: ${(props) => props.theme.colors.white};

        .dx-datagrid-content,
        .dx-header-row {
          background: ${(props) => props.theme.colors.gridDetailHeader};
        }

        .dx-datagrid-filter-row {
          background: ${(props) => props.theme.colors.white};
        }

        td {
          text-align: center !important;
        }
      }

      .dx-scrollable-content {
        background-color: ${(props) => props.theme.colors.gridBorder};

        .dx-data-row {
          background-color: ${(props) => props.theme.colors.white};
        }
      }

      .dx-datagrid-rowsview,
      .dx-treelist-rowsview {
        tr {
          td {
            border-top: none;
            border-color: ${(props) => props.theme.colors.gridBorder};
          }
        }
        .dx-freespace-row {
          background-color: ${(props) => props.theme.colors.gridBorder};
        }
      }
    }
  }
`;

export const InputFullRow = styled.div`
  display: flex;
  width: 100%;
  min-height: 12px;
  grid-column: ${(props) =>
    props.grid === 2
      ? "1/3"
      : props.grid === 3
      ? "1/4"
      : props.grid === 4
      ? "1/5"
      : props.grid === 5
      ? "1/6"
      : "2/4"};
  align-items: center;
  justify-content: space-between;
`;
