import { DataGrid } from "devextreme-react";
import {
  Column,
  ColumnChooser,
  FilterRow,
  Format,
  HeaderFilter,
  Scrolling,
  Selection,
  StateStoring,
} from "devextreme-react/data-grid";
import React, { useCallback, useState } from "react";
import CustomButton from "../../../../components/FunctionButton/CustomButton";
import { ButtonGroup } from "../../../../components/FunctionButton/CustomButton/customButton.style";
import InputTextArea from "../../../../components/InputForm/InputTextArea";
import { PanelBtn } from "../../../../components/Layout/Panel/panel.style";
import {
  DataCount,
  GridTapContent,
  GridTapWrapper,
  PanelGridInit,
  PanelGridInput,
  PanelGridInputWrapper,
  PanelSubGridInit,
} from "../../../../components/Layout/Panel/PanelGrid/panelGrid.style";
import {
  InputFullRow,
  PanelInput,
} from "../../../../components/Layout/Panel/PanelInput/panelInput.style";
import StateStoringSaveAndDeleteButton from "../../../../components/StateStoring";

const AccountHistoryLower = ({
  state,
  accountRemark,
  taxBillGridRef,
  etcCostGridRef,
  detailFunctions,
}) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [totalCount01, setTotalCount01] = useState(0);
  const [totalCount02, setTotalCount02] = useState(0);
  const storageKey01 = "accountHistoryLower01";
  const storageKey02 = "accountHistoryLower02";

  const { taxBillDataSource, etcCostDataSource, isLoading } = state;

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = accountRemark;

  const {
    openTaxBill,
    onClickDeleteTaxBillBtn,
    openEtcCost,
    onClickDeleteEtcCostBtn,
    onSubmit,
    onClickDeleteMemo,
  } = detailFunctions;
  const tablist = [
    {
      tab: (
        <li
          key={1}
          onClick={() => setActiveIndex(0)}
          className={`grid-tap-item ${activeIndex === 0 ? "active" : ""}`}
        >
          세금계산서
        </li>
      ),
      contents: (
        <>
          <PanelSubGridInit gridSub gridItems column>
            <StateStoringSaveAndDeleteButton
              dataGridRef={taxBillGridRef}
              storageKey={storageKey01}
              gridItems
            />
            <DataCount gridItems panelBtn>
              {`총 ${totalCount01}건`}{" "}
              <PanelBtn>
                <CustomButton
                  type="button"
                  height={24}
                  layout="solid"
                  color="common"
                  label="연결하기"
                  onClick={openTaxBill}
                />
                <CustomButton
                  type="button"
                  height={24}
                  layout="solid"
                  color="esc"
                  label="삭제하기"
                  onClick={onClickDeleteTaxBillBtn}
                />
              </PanelBtn>
            </DataCount>
            <DataGrid
              className="datagrid-wrap"
              id="TaxBillMaster"
              dataSource={taxBillDataSource}
              ref={taxBillGridRef}
              showRowLines={true}
              showBorders={true}
              hoverStateEnabled={true}
              allowColumnResizing={true}
              allowColumnReordering={true}
              columnResizingMode="widget"
              keyExpr="Oid"
              focusedRowEnabled={true}
              onContentReady={(e) => setTotalCount01(e.component.totalCount())}
            >
              <FilterRow visible={true} />
              <HeaderFilter visible={true} />
              <ColumnChooser enabled={true} />
              <StateStoring
                enabled={true}
                type="custom"
                customLoad={useCallback(() => {
                  return JSON.parse(localStorage.getItem(storageKey01));
                }, [])}
              />
              <Scrolling mode="virtual" />
              <Selection
                mode="multiple"
                allowSelectAll={false}
                showCheckBoxesMode="always"
              />
              <Column caption="접수법인" dataField="receiveCo" width="90px" />
              <Column caption="접수번호" dataField="code" width="90px" />
              <Column
                caption="접수일자"
                dataField="receiveDate"
                dataType="date"
                format="yyyy-MM-dd"
                width="90px"
              />
              <Column
                caption="운송일자"
                dataField="deliverDate"
                dataType="date"
                format="yyyy-MM-dd"
                width="90px"
              />
              <Column caption="마감번호" dataField="completeNo" width="90px" />
              <Column
                caption="마감일자"
                dataField="taxSchedule"
                dataType="date"
                format="yyyy-MM-dd"
                width="90px"
              />
              <Column caption="정산상태" dataField="closeStatus" width="90px" />
              <Column caption="금액" dataField="totalAmount" width="90px" />
              {/* <Column caption="미지급금" dataField="" width="90px" /> */}
              <Column
                caption="컨테이너NO"
                dataField="containerNo"
                width="90px"
              />
              <Column caption="컨규격" dataField="containerType" width="90px" />
              <Column caption="비고" dataField="remark" width="90px" />
              <Column caption="배차팀" dataField="dispatchTeam" width="90px" />
              <Column caption="청구처" dataField="billingPlace" width="90px" />
              <Column caption="발주처" dataField="orderCo" width="90px" />
              <Column caption="선사" dataField="forwarder" width="90px" />
              <Column caption="선명" dataField="" width="90px" />
              <Column caption="모선항차" dataField="motherShip" width="90px" />
              <Column caption="화주" dataField="cargoOwner" width="90px" />
              <Column caption="선사반출기한" dataField="dem" width="90px" />
              <Column caption="입항일" dataField="enterDay" width="90px" />
              <Column caption="출항일" dataField="sailDay" width="90px" />
              <Column caption="접안일" dataField="putTime" width="90px" />
              <Column caption="상차지" dataField="loadPlaceName" width="90px" />
              <Column
                caption="하차지"
                dataField="unloadPlaceName"
                width="90px"
              />
              <Column caption="BL NO" dataField="blNo" width="90px" />
              <Column caption="BK NO" dataField="bkNo" width="90px" />
              <Column caption="DO NO" dataField="doNo" width="90px" />
            </DataGrid>
          </PanelSubGridInit>
        </>
      ),
    },
    {
      tab: (
        <li
          key={2}
          onClick={() => setActiveIndex(1)}
          className={`grid-tap-item ${activeIndex === 1 ? "active" : ""}`}
        >
          기타비용
        </li>
      ),
      contents: (
        <>
          <PanelSubGridInit gridSub gridItems column>
            <StateStoringSaveAndDeleteButton
              dataGridRef={etcCostGridRef}
              storageKey={storageKey02}
              gridItems
            />
            <DataCount gridItems panelBtn>
              {`총 ${totalCount02}건`}
              <PanelBtn>
                <CustomButton
                  type="button"
                  height={24}
                  layout="solid"
                  color="common"
                  label="연결하기"
                  onClick={openEtcCost}
                />
                <CustomButton
                  type="button"
                  height={24}
                  layout="solid"
                  color="esc"
                  label="삭제하기"
                  onClick={onClickDeleteEtcCostBtn}
                />
              </PanelBtn>
            </DataCount>
            <DataGrid
              className="datagrid-wrap"
              id="EtcCostMaster"
              dataSource={etcCostDataSource}
              ref={etcCostGridRef}
              showRowLines={true}
              showBorders={true}
              hoverStateEnabled={true}
              allowColumnResizing={true}
              allowColumnReordering={true}
              columnResizingMode="widget"
              keyExpr="Oid"
              focusedRowEnabled={true}
              onContentReady={(e) => setTotalCount02(e.component.totalCount())}
            >
              <FilterRow visible={true} />
              <HeaderFilter visible={true} />
              <ColumnChooser enabled={true} />
              <StateStoring
                enabled={true}
                type="custom"
                customLoad={useCallback(() => {
                  return JSON.parse(localStorage.getItem(storageKey02));
                }, [])}
              />
              <Scrolling mode="virtual" />
              <Selection
                mode="multiple"
                allowSelectAll={false}
                showCheckBoxesMode="always"
              />
              <Column caption="등록일" dataField="regDate" />
              <Column caption="업체명" dataField="companyName" />
              <Column caption="매출" dataField="sales" dataType="number">
                <Format type="fixedPoint" />
              </Column>
              <Column caption="매입" dataField="purchase" dataType="number">
                <Format type="fixedPoint" />
              </Column>
              <Column caption="비고1" dataField="remark01" />
              <Column caption="비고2" dataField="remark02" />
              <Column caption="계좌연결여부" dataField="" />
              <Column caption="실지급일" dataField="" />
            </DataGrid>
          </PanelSubGridInit>
        </>
      ),
    },
    {
      tab: (
        <li
          key={3}
          onClick={() => setActiveIndex(2)}
          className={`grid-tap-item ${activeIndex === 2 ? "active" : ""}`}
        >
          통장비고내역
        </li>
      ),
      contents: (
        <PanelGridInputWrapper row left="100%">
          <PanelGridInput onSubmit={handleSubmit(onSubmit)}>
            <PanelInput grid={2}>
              <InputFullRow grid={2}>
                <InputTextArea
                  control={control}
                  errors={errors}
                  height={80}
                  name="Remark"
                  label="통장비고내역"
                />
              </InputFullRow>
            </PanelInput>

            <ButtonGroup marginTop={20}>
              <CustomButton
                type="submit"
                width="100%"
                height={36}
                layout="solid"
                color="common"
                label="저장"
                className={`${isLoading &&
                  "spinner spinner-white spinner-right"}`}
              />
              <CustomButton
                type="button"
                width="100%"
                height={36}
                layout="solid"
                color="esc"
                label="삭제"
                className={`${isLoading &&
                  "spinner spinner-white spinner-right"}`}
                onClick={onClickDeleteMemo}
              />
            </ButtonGroup>
          </PanelGridInput>
        </PanelGridInputWrapper>
      ),
    },
  ];
  return (
    <>
      <PanelGridInit gridSub gridItems tabItems column>
        <GridTapWrapper>{tablist.map((cur) => cur.tab)}</GridTapWrapper>
        <GridTapContent>{tablist[activeIndex].contents}</GridTapContent>
      </PanelGridInit>
    </>
  );
};

export default AccountHistoryLower;
