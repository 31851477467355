import React, { useEffect, useState, useMemo, useRef } from "react";
import {
  PanelBody,
  PanelInit,
} from "../../../components/Layout/Panel/panel.style";
import PageHeader from "../../../components/Layout/PageHeader/PageHeader";
import CustomButton from "../../../components/FunctionButton/CustomButton";
import {
  PanelGridWrapper,
  PanelGridSearchBar,
  SearchItems,
  GridBottomText,
  GridMulitpleBodyWrapper,
  GridPaymentLedger,
  GridTopText,
} from "../../../components/Layout/Panel/PanelGrid/panelGrid.style";
import { PanelInputTitle } from "../../../components/Layout/Panel/PanelInput/panelInput.style";
import { InputSelect } from "../../../components/InputForm";
import moment from "moment/moment";
import {
  getLookup,
  paymentLawData,
  paymentOwnerData,
  paymentDriverData,
} from "./PaymentLedgerCrud";
import { useForm } from "react-hook-form";
import InputDate from "../../../components/InputForm/InputDate";
import PaymentLedgerLawGrid from "./PaymentLedgerLawGrid";
import CustomStore from "devextreme/data/custom_store";
import { sendRequest } from "../../../../_metronic/_helpers/DataSoureHelpers";
import { shallowEqual, useSelector } from "react-redux";
import PaymentLedgerOwnerGrid from "./PaymentLedgerOwnerGrid";
import PaymentLedgerDriverGrid from "./PaymentLedgerDriverGrid";
import dayjs from "dayjs";

export default function PaymentLedgerPage() {
  // Grid 검색
  const [search, setSearch] = useState({});

  // Grid 총 건 계산
  const [totalCount, setTotalCount] = useState(0);
  const [ownerCount, setOwnerTotalCount] = useState(0);
  const [driverCount, setDriverTotalCount] = useState(0);

  // 등록 user
  const { user } = useSelector(
    (state) => ({ user: state.auth.user.user }),
    shallowEqual
  );

  // 일정 검색 초기값
  const initialState = {
    receiveCo: "0",
    searchType: "0",
    firstDay: dayjs()
      .startOf("month")
      .format("YYYY-MM-DD"),
    lastDay: dayjs()
      .endOf("month")
      .format("YYYY-MM-DD"),
    isDivision: "0",
    isFinish: "0",
    // dispatchTeam: `${user?.deptCode}`,
    dispatchTeam: "0",
    companyName: "",
    receiveCoName: "",
    carryoverInquiry: false,
  };

  const [lookupData, setLookupData] = useState({
    company: [],
    disaptchName: [],
  });

  async function getLookupData() {
    try {
      const dispatchTeamData = await getLookup("department/isDispatch");
      const companyData = await getLookup("company");

      setLookupData({
        company: [
          {
            id: "0",
            companyName: "전체",
          },
          ...companyData.data,
        ],
        disaptchName: [
          {
            code: "0",
            deptName: "전체",
          },
          ...dispatchTeamData.data,
        ],
      });
    } catch (err) {
      console.log(err);
    }
  }
  const {
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: initialState,
  });

  function handleMonthChange({ value }) {
    const currentYear = moment().year();
    const startOfMonth = moment(`${currentYear}-${value}-01`)
      .startOf("month")
      .format("YYYY-MM-DD");
    const endOfMonth = moment(`${currentYear}-${value}-01`)
      .endOf("month")
      .format("YYYY-MM-DD");

    setValue("firstDay", startOfMonth);
    setValue("lastDay", endOfMonth);
  }

  //** 법원 대납금 데이터 GET */
  const paymentLedgerLawDataSource = useMemo(() => {
    const apiUrl = `${process.env.REACT_APP_API_URL}/paymentLedger/lawData`;
    return new CustomStore({
      key: "oid",
      load: () => sendRequest(apiUrl, "GET", search),
    });
  }, [search]);

  //** 화주 대납금 데이터 GET */
  const paymentLedgerOwnerDataSource = useMemo(() => {
    const apiUrl = `${process.env.REACT_APP_API_URL}/paymentLedger/ownerData`;
    return new CustomStore({
      key: "oid",
      load: () => sendRequest(apiUrl, "GET", search),
    });
  }, [search]);

  //** 기사 가불금 데이터 GET */
  const paymentLedgerDriverDataSource = useMemo(() => {
    const apiUrl = `${process.env.REACT_APP_API_URL}/paymentLedger/driverData`;
    return new CustomStore({
      key: "oid",
      load: () => sendRequest(apiUrl, "GET", search),
    });
  }, [search]);

  //** 테이블 row 추가 */
  const lawGrid = useRef();
  const ownerGrid = useRef();
  const driverGrid = useRef();

  // 초기 화면 데이터 및 접수 법인 설정
  useEffect(() => {
    getLookupData();
    setSearch(initialState);
    // eslint-disable-next-line
  }, []);

  // 검색 조건
  const onSubmit = (data) => {
    for (const obj in data) {
      if (data[obj] === "") {
        data[obj] = null;
      }
    }

    const submit = {
      ...data,
      authGroupId: user.authGroupId,
    };

    setSearch(submit);
  };

  //** 데이터 POST, UPDATE, DELETE */
  const postPaymentLedgerData = async () => {
    // DataGrid 데이터 접근
    const lawGridInstance = lawGrid.current.instance;
    const ownerGridInstance = ownerGrid.current.instance;
    const driverGridInstance = driverGrid.current.instance;

    // DataGrid ROW 데이터 수정
    const paymentLedgerLawData = lawGridInstance.option("editing.changes");
    const paymentLedgerOwnerData = ownerGridInstance.option("editing.changes");
    const paymentLedgerDriverData = driverGridInstance.option(
      "editing.changes"
    );

    try {
      // LawData - 법원대납금
      if (paymentLedgerLawData.length !== 0) {
        await paymentLawData(paymentLedgerLawData, user.userName);
      }

      // OwnerData - 화주대납금
      if (paymentLedgerOwnerData.length !== 0) {
        await paymentOwnerData(paymentLedgerOwnerData, user.userName);
      }

      // DriverData - 기사가불금
      if (paymentLedgerDriverData.length !== 0) {
        await paymentDriverData(paymentLedgerDriverData, user.userName);
      }

      // DataGrid 새로고침
      lawGridInstance.refresh();
      ownerGridInstance.refresh();
      driverGridInstance.refresh();

      // DataGrid 추가된 내용 삭제
      lawGridInstance.option("editing.changes", []);
      ownerGridInstance.option("editing.changes", []);
      driverGridInstance.option("editing.changes", []);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <PanelInit>
      <PageHeader>
        <CustomButton
          type="button"
          height={24}
          layout="solid"
          color="common"
          label="저장"
          onClick={postPaymentLedgerData}
        />
      </PageHeader>
      <PanelBody row left="100%" right="auto">
        <PanelGridWrapper>
          <PanelGridSearchBar onSubmit={handleSubmit(onSubmit)}>
            <SearchItems>
              <label> 접수법인 </label>
              <InputSelect
                className="label-select"
                control={control}
                errors={errors}
                name="receiveCo"
                displayExpr="companyName"
                valueExpr="id"
                dataSource={lookupData.company}
                flex={true}
              />
            </SearchItems>
            <SearchItems>
              <label> 닐짜 기간 조회 </label>
              <InputDate
                className="label-select"
                control={control}
                errors={errors}
                name="firstDay"
                flex={true}
              />
              ~
              <InputDate
                className="label-select"
                control={control}
                errors={errors}
                name="lastDay"
                flex={true}
              />
            </SearchItems>
            <SearchItems>
              <label>월선택</label>
              <InputSelect
                className="label-select"
                control={control}
                errors={errors}
                dataSource={[
                  { id: "01", name: "1월" },
                  { id: "02", name: "2월" },
                  { id: "03", name: "3월" },
                  { id: "04", name: "4월" },
                  { id: "05", name: "5월" },
                  { id: "06", name: "6월" },
                  { id: "07", name: "7월" },
                  { id: "08", name: "8월" },
                  { id: "09", name: "9월" },
                  { id: "10", name: "10월" },
                  { id: "11", name: "11월" },
                  { id: "12", name: "12월" },
                ]}
                valueExpr="id"
                displayExpr="name"
                name="month"
                flex={true}
                onValueChanged={handleMonthChange}
              />
            </SearchItems>
            <CustomButton
              type="submit"
              height={24}
              layout="solid"
              color="search"
              label="검색"
            />
          </PanelGridSearchBar>
          <GridMulitpleBodyWrapper>
            <GridPaymentLedger>
              <PanelInputTitle position="absolute" top={2}>
                <span> 1. 법원 대납금 </span>
              </PanelInputTitle>
              <GridTopText top="8px">총 {`${totalCount}`} 건</GridTopText>
              <PaymentLedgerLawGrid
                dataSource={paymentLedgerLawDataSource}
                lawGrid={lawGrid}
                lookupData={lookupData}
                user={user}
                setTotalCount={setTotalCount}
              />
            </GridPaymentLedger>
            <GridPaymentLedger>
              <PanelInputTitle position="absolute" top={2}>
                <span> 2. 화주 대납금 </span>
              </PanelInputTitle>
              <GridTopText top="8px">총 {`${ownerCount}`} 건</GridTopText>
              <PaymentLedgerOwnerGrid
                dataSource={paymentLedgerOwnerDataSource}
                ownerGrid={ownerGrid}
                lookupData={lookupData}
                user={user}
                setTotalCount={setOwnerTotalCount}
              />
            </GridPaymentLedger>
            <GridPaymentLedger>
              <PanelInputTitle position="absolute" top={2}>
                <span> 3. 기사 가불금 </span>
              </PanelInputTitle>
              <GridTopText top="8px">총 {`${driverCount}`} 건</GridTopText>
              <PaymentLedgerDriverGrid
                dataSource={paymentLedgerDriverDataSource}
                driverGrid={driverGrid}
                lookupData={lookupData}
                setTotalCount={setDriverTotalCount}
                user={user}
              />
            </GridPaymentLedger>
          </GridMulitpleBodyWrapper>
        </PanelGridWrapper>
      </PanelBody>
    </PanelInit>
  );
}

// const PaymentLedgerPage = () => {
//   return <>asdfasdfasdf</>;
// };

// export default PaymentLedgerPage;
