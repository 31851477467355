import { DateBox } from "devextreme-react";
import React from "react";
import { Controller } from "react-hook-form";
import moment from "moment";

const InputDate = ({
  control,
  errors,
  name,
  label,
  flex,
  disable = false,
  required,
  defaultValue,
  onValueChanged = () => {},
  onInitialized = () => {},
  displayFormat = "yyyy-MM-dd",
  width,
}) => {
  return (
    <>
      <div className={"search-group " + (flex ? "d-flex flex-row" : "")}>
        <label className={required ? "required" : ""}>{label}</label>
        <div>
          <Controller
            render={({ field: { onChange, value } }) => (
              <DateBox
                type="date"
                displayFormat={displayFormat}
                onValueChange={(value) => {
                  // 이거 YYYYMMDD로 바꾸면 선석안됨
                  if (value) onChange(moment(value).format("YYYY-MM-DD"));
                }}
                value={value}
                disabled={disable}
                onValueChanged={onValueChanged}
                onInitialized={onInitialized}
                width={width}
              />
            )}
            control={control}
            name={name}
            defaultValue={null}
          />
        </div>
      </div>
      {errors[name]?.message && (
        <div className="fv-plugins-message-container">
          <div className="fv-help-block">{errors[name]?.message}</div>
        </div>
      )}
    </>
  );
};

export default InputDate;
