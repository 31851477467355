import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  HeaderMenuBtn,
  HeaderUserBtn,
  HeaderUserPopup,
  HeaderWrapper,
  PopupContent,
  UserName,
} from "./header.style";
import { ReactComponent as MenuIcon } from "../../../../style/icons/Menu.svg";
import { ExitToApp, Person } from "@material-ui/icons";
import * as actions from "../Aside/_redux/menuActions";
import { ChangePasswordModal } from "../../../../_metronic/_partials/modals/ChangePasswordModal";

function Header({ toggleMenu, isOpen }) {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth.user);
  const [changePasswordModalVisible, setChangePasswordModalVisible] = useState(
    false
  );
  const [popupShow, setPopupShow] = useState(false);

  const userPopupClick = () => {
    setPopupShow(!popupShow);
  };

  const logout = () => {
    dispatch(actions.logout());
  };

  const changePassword = () => {
    setChangePasswordModalVisible(true);
  };

  const closeModal = () => {
    setChangePasswordModalVisible(false);
  };

  return (
    <HeaderWrapper
      className={`${isOpen ? "mobile-inactive" : "default"} no-print`}
    >
      <HeaderMenuBtn
        className={`${isOpen ? "active" : "default"}`}
        onClick={toggleMenu}
      >
        <MenuIcon />
      </HeaderMenuBtn>
      <HeaderUserBtn onClick={userPopupClick}>
        <UserName>
          <span className="user-type">{user.companyName}</span>
          <span>{user.userName} 님</span>
          <span className="user-icon">
            <Person />
          </span>
        </UserName>
      </HeaderUserBtn>
      {popupShow && (
        <HeaderUserPopup>
          <PopupContent onClick={changePassword}>비밀번호 변경</PopupContent>
          <PopupContent onClick={logout}>
            <Link to="/logout">
              <ExitToApp />
              로그아웃
            </Link>
          </PopupContent>
        </HeaderUserPopup>
      )}

      {changePasswordModalVisible && (
        <ChangePasswordModal
          onClose={closeModal}
          closable={true}
          visible={true}
          maskClosable={false}
          selectedRowKey={user.id}
        />
      )}
    </HeaderWrapper>
  );
}

export default Header;
