import axios from "axios";

const apiUrl = `${process.env.REACT_APP_API_URL}`;
const apiUrlCar = `${process.env.REACT_APP_API_URL}/carManage`;

export function getLookup(param) {
  return axios.get(apiUrl + `/${param}`);
}

export function getRowDataById(oid) {
  return axios.get(apiUrlCar + `/${oid}`);
}

export function createRowData(data) {
  return axios.post(apiUrlCar, data);
}

export function updateRowData(key, data) {
  return axios.put(apiUrlCar, { key, data });
}

export function deleteRowData(key) {
  return axios.delete(apiUrlCar + `/${key}`);
}
