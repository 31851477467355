import React from "react";
import CustomButton from "../../../../components/FunctionButton/CustomButton";
import {
  InputDate,
  InputSelect,
  InputText,
} from "../../../../components/InputForm";
import {
  PanelGridBtnBar,
  PanelGridInit,
  PanelGridSearchBar,
  PanelGridTopBar,
  PanelGridWrapper,
  SearchItems,
} from "../../../../components/Layout/Panel/PanelGrid/panelGrid.style";
import { ReactComponent as IconEdit } from "../../../../../style/icons/edit-note.svg";
import SimpleSellFinishGrid from "./SimpleSellFinishGrid";

const SimpleSellFinishMaster = ({
  state,
  dataGridRef,
  searchForm,
  masterFunctions,
}) => {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = searchForm;
  const { lookupData, masterDataSource } = state;

  const {
    onSearchSubmit,
    onToggleInputBar,
    onFocusedRowChanged,
    onRowPrepared,
  } = masterFunctions;
  return (
    <>
      <PanelGridWrapper>
        <PanelGridTopBar>
          <PanelGridBtnBar>
            <CustomButton
              type="button"
              height={36}
              width="full"
              layout="icon"
              color="transp"
              onClick={onToggleInputBar}
            >
              <IconEdit />
            </CustomButton>
          </PanelGridBtnBar>
          <PanelGridSearchBar onSubmit={handleSubmit(onSearchSubmit)}>
            <SearchItems>
              <label>접수법인</label>
              <InputSelect
                className="label-select"
                control={control}
                errors={errors}
                name="receiveCo"
                displayExpr="companyName"
                valueExpr="id"
                dataSource={[
                  { companyName: "전체", id: "0" },
                  ...lookupData.company,
                ]}
                flex={true}
              />
            </SearchItems>
            <SearchItems>
              <label>배차팀</label>
              <InputSelect
                control={control}
                errors={errors}
                name="dispatchTeam"
                dataSource={[
                  {
                    code: 0,
                    deptName: "전체",
                  },
                  ...lookupData.dispatchName,
                ]}
                valueExpr="code"
                displayExpr="deptName"
                flex={true}
              />
            </SearchItems>
            <SearchItems>
              <label>수입/수출</label>
              <InputSelect
                control={control}
                errors={errors}
                name="importExport"
                dataSource={[
                  { id: "", name: "전체" },
                  { id: 1, name: "수입" },
                  { id: 2, name: "수출" },
                ]}
                valueExpr="id"
                displayExpr="name"
                flex={true}
              />
            </SearchItems>
            <SearchItems>
              <label>일정검색</label>
              <InputSelect
                control={control}
                errors={errors}
                name="searchType"
                displayExpr="name"
                valueExpr="id"
                dataSource={[
                  { id: 1, name: "접수일자" },
                  { id: 2, name: "운송일자" },
                ]}
                flex={true}
              />
              <InputDate
                control={control}
                errors={errors}
                name="firstDay"
                flex={true}
              />
              <div className="to-mark">~</div>
              <InputDate
                control={control}
                errors={errors}
                name="lastDay"
                flex={true}
              />
            </SearchItems>
            <SearchItems>
              <label>컨테이너No</label>
              <InputText
                control={control}
                errors={errors}
                name="containerNo"
                flex={true}
              />
            </SearchItems>
            <SearchItems>
              <label>BL</label>
              <InputText
                control={control}
                errors={errors}
                name="blNo"
                flex={true}
              />
            </SearchItems>
            <SearchItems>
              <label>Do</label>
              <InputText
                control={control}
                errors={errors}
                name="doNo"
                flex={true}
              />
            </SearchItems>
            <SearchItems>
              <label>BK</label>
              <InputText
                control={control}
                errors={errors}
                name="bkNo"
                flex={true}
              />
            </SearchItems>
            <CustomButton
              type="submit"
              height={24}
              layout="solid"
              color="search"
              label="검색"
            />
          </PanelGridSearchBar>
        </PanelGridTopBar>
        <PanelGridInit>
          <SimpleSellFinishGrid
            dataGridRef={dataGridRef}
            masterDataSource={masterDataSource}
            onFocusedRowChanged={onFocusedRowChanged}
            onRowPrepared={onRowPrepared}
          />
        </PanelGridInit>
      </PanelGridWrapper>
    </>
  );
};

export default SimpleSellFinishMaster;
