import React, { useEffect, useState } from "react";
import { ModalContainer, ModalHeader } from "../Modal";
import { useForm } from "react-hook-form";
import { getLookup } from "./ChangeAllModalCrud";
import { InputDate, InputNumber, InputSelect, InputText } from "../InputForm";

const ChangeAllModal = ({ onClose, visible, maskClosable, detailGrid }) => {
  const [lookupData, setLookupData] = useState({
    workPlace: [],
    conType: [],
  });

  useEffect(() => {
    // orderInputDetail 룩업데이터
    initOrderInputDetailLookupData();
  }, []);

  const initOrderInputDetailLookupData = async () => {
    try {
      const [workPlaceData, conTypeData] = await Promise.all([
        getLookup("terminal/workplace"),
        getLookup("comcode/detail/10400"),
      ]);

      setLookupData({
        workPlace: [{ code: null, twName: "" }, ...workPlaceData.data],
        conType: conTypeData.data,
      });
    } catch (err) {
      console.log(err);
    }
  };
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    const instance = detailGrid.current.instance;
    const rows = instance.getVisibleRows();

    for (const obj of rows) {
      const { rowIndex } = obj;

      for (const key in data) {
        if (!data[key]) continue;
        instance.cellValue(rowIndex, key, data[key]);
      }
    }

    onClose();
  };

  return (
    <ModalContainer
      onClose={onClose}
      maskClosable={maskClosable}
      visible={visible}
      size="sm"
    >
      <form className="modal-form" onSubmit={handleSubmit(onSubmit)}>
        <ModalHeader title="일괄적용" onClose={onClose} />
        <div className="modal-form-body">
          <div className="dx-field">
            <InputDate
              control={control}
              errors={errors}
              name="deliverDate"
              label="운송(요청)일자"
            />
            <InputSelect
              control={control}
              errors={errors}
              dataSource={lookupData.conType}
              displayExpr="subName"
              valueExpr="subCode"
              name="containerType"
              label="컨/규격"
            />

            <InputSelect
              control={control}
              errors={errors}
              dataSource={lookupData.workPlace}
              displayExpr="twName"
              valueExpr="code"
              name="workPlace"
              label="작업지"
            />

            <InputText
              control={control}
              errors={errors}
              name="pointManageContact"
              label="담당자연락처"
            />

            <InputNumber
              control={control}
              errors={errors}
              name="freight"
              label="청구금액"
            />
          </div>
        </div>
        <div className="modal-form-footer">
          <button type="submit" className="btn btn-modal btn-save">
            적용
          </button>
        </div>
      </form>
    </ModalContainer>
  );
};

export default ChangeAllModal;
