import { DataGrid } from "devextreme-react";
import {
  Column,
  ColumnChooser,
  FilterRow,
  Format,
  HeaderFilter,
  Scrolling,
  Selection,
  StateStoring,
} from "devextreme-react/data-grid";
import React, { useCallback, useRef } from "react";
import CustomButton from "../../../components/FunctionButton/CustomButton";
import {
  InputDate,
  InputSelect,
  InputText,
} from "../../../components/InputForm";
import HeaderTitle from "../../../components/Layout/Header/HeaderTitle";
import {
  DataCount,
  PanelGridInit,
  PanelGridSearchBar,
  PanelGridTopBar,
  PanelGridWrapper,
  SearchItems,
} from "../../../components/Layout/Panel/PanelGrid/panelGrid.style";
import { PanelInputWrapper } from "../../../components/Layout/Panel/PanelInput/panelInput.style";
import {
  PanelBody,
  PanelBtn,
  PanelHeader,
  PanelInit,
} from "../../../components/Layout/Panel/panel.style";
import Loading from "../../../components/Loading/Loading";
import StateStoringSaveAndDeleteButton from "../../../components/StateStoring";
import { useShuttleExcel } from "./useSuttleExcel";

const STORAGE_KEY = "shuttleExcel";

function ShuttleExcelPage(props) {
  const dataGridRef = useRef();
  const {
    isLoading,
    state,
    form,
    setGridTotalCount,
    onRowDelete,
    onSearch,
    importExportCellRender,
  } = useShuttleExcel({
    dataGridRef,
  });
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = form;

  const { lookupCompany, lookupDisaptchName, dataSource } = state;

  return (
    <>
      {isLoading && <Loading type="page" text="조회중입니다..." />}
      <PanelInit>
        <PanelHeader>
          <HeaderTitle />
          <PanelBtn>
            <CustomButton
              type="button"
              height={24}
              layout="solid"
              color="esc"
              onClick={onRowDelete}
            >
              삭제
            </CustomButton>
          </PanelBtn>
        </PanelHeader>
        <PanelBody
          row
          left="400px"
          right="calc(100% - 400px)"
          openInputBar={false}
        >
          <PanelInputWrapper></PanelInputWrapper>
          <PanelGridWrapper>
            <PanelGridTopBar noBtn>
              <PanelGridSearchBar onSubmit={handleSubmit(onSearch)}>
                <SearchItems>
                  <label>접수법인</label>
                  <InputSelect
                    className="label-select"
                    control={control}
                    errors={errors}
                    name="receiveCo"
                    displayExpr="companyName"
                    valueExpr="id"
                    dataSource={lookupCompany}
                    flex={true}
                  />
                </SearchItems>
                <SearchItems>
                  <label>배차팀</label>
                  <InputSelect
                    control={control}
                    errors={errors}
                    name="dispatchTeam"
                    dataSource={lookupDisaptchName}
                    valueExpr="code"
                    displayExpr="deptName"
                    flex={true}
                  />
                </SearchItems>
                <SearchItems>
                  <label>수입/수출</label>
                  <InputSelect
                    control={control}
                    errors={errors}
                    name="importExport"
                    dataSource={[
                      { id: "", name: "전체" },
                      { id: 1, name: "수입" },
                      { id: 2, name: "수출" },
                    ]}
                    valueExpr="id"
                    displayExpr="name"
                    flex={true}
                  />
                </SearchItems>
                <SearchItems>
                  <label>일정검색</label>
                  <InputSelect
                    control={control}
                    errors={errors}
                    name="searchType"
                    displayExpr="name"
                    valueExpr="id"
                    dataSource={[
                      { id: 1, name: "접수일자" },
                      { id: 2, name: "운송일자" },
                    ]}
                    flex={true}
                  />
                  <InputDate
                    control={control}
                    errors={errors}
                    name="firstDay"
                    flex={true}
                  />
                  <div className="to-mark">~</div>
                  <InputDate
                    control={control}
                    errors={errors}
                    name="lastDay"
                    flex={true}
                  />
                </SearchItems>
                <SearchItems>
                  <label>컨테이너No</label>
                  <InputText
                    control={control}
                    errors={errors}
                    name="containerNo"
                    flex={true}
                  />
                </SearchItems>
                <SearchItems>
                  <label>BL</label>
                  <InputText
                    control={control}
                    errors={errors}
                    name="blNo"
                    flex={true}
                  />
                </SearchItems>
                <SearchItems>
                  <label>Do</label>
                  <InputText
                    control={control}
                    errors={errors}
                    name="doNo"
                    flex={true}
                  />
                </SearchItems>
                <SearchItems>
                  <label>BK</label>
                  <InputText
                    control={control}
                    errors={errors}
                    name="bkNo"
                    flex={true}
                  />
                </SearchItems>
                <CustomButton
                  type="submit"
                  height={24}
                  layout="solid"
                  color="search"
                  label="검색"
                />
              </PanelGridSearchBar>
            </PanelGridTopBar>
            <PanelGridInit>
              <StateStoringSaveAndDeleteButton
                dataGridRef={dataGridRef}
                storageKey={STORAGE_KEY}
              />
              <DataCount>{`총 ${state.totalCount}건`}</DataCount>
              <DataGrid
                id="shuttleSimpleMaster"
                className="datagrid-wrap"
                ref={dataGridRef}
                dataSource={dataSource}
                keyExpr="code"
                showRowLines={true}
                showBorders={true}
                hoverStateEnabled={true}
                allowColumnResizing={true}
                allowColumnReordering={true}
                columnResizingMode="widget"
                focusedRowEnabled={true}
                // onRowPrepared={onRowPrepared}
                // onFocusedRowChanged={onFocusedRowChanged}
                onContentReady={(e) =>
                  setGridTotalCount(e.component.totalCount())
                }
              >
                <FilterRow visible={true} />
                <HeaderFilter visible={true} />
                <ColumnChooser enabled={true} />
                <Selection
                  mode="multiple"
                  allowSelectAll={true}
                  showCheckBoxesMode="always"
                />
                <StateStoring
                  enabled={true}
                  type="custom"
                  customLoad={useCallback(() => {
                    return JSON.parse(localStorage.getItem(STORAGE_KEY));
                  }, [])}
                />
                <Scrolling
                  columnRenderingMode="virtual"
                  mode="virtual"
                  showScrollbar="always"
                  useNative="false"
                />
                <Column dataField="code" caption="접수번호" width="115" />
                <Column dataField="receiveDate" caption="접수일자" width="85" />
                <Column
                  dataField="receiveCoName"
                  caption="접수법인"
                  width="85"
                />
                <Column
                  dataField="importExport"
                  caption="수입/수출"
                  alignment="center"
                  cellRender={importExportCellRender}
                  width="95"
                />
                <Column
                  dataField="billingPlaceName"
                  caption="청구처"
                  width="70"
                />
                <Column
                  dataField="containerNo"
                  caption="컨테이너NO"
                  width="115"
                />
                <Column dataField="cargoOwnerName" caption="화주" width="70" />
                <Column
                  dataField="realCargoOwner"
                  caption="실화주"
                  width="70"
                />
                <Column
                  dataField="containerType"
                  caption="컨규격"
                  alignment="center"
                  width="95"
                />
                <Column dataField="loadPlaceName" caption="상차지" width="75" />
                <Column dataField="carName_01" caption="배차차량" width="90" />
                <Column
                  dataField="yongchasaName_01"
                  caption="용차사"
                  width="90"
                />
                <Column
                  dataField="yongchaCarNo_01"
                  caption="용차사차량"
                  width="110"
                />
                <Column dataField="workPlaceName" caption="작업지" width="75" />
                <Column dataField="carName_02" caption="배차차량" width="90" />
                <Column
                  dataField="yongchasaName_02"
                  caption="용차사"
                  width="90"
                />
                <Column
                  dataField="yongchaCarNo_02"
                  caption="용차사차량"
                  width="110"
                />
                <Column
                  dataField="unloadPlaceName"
                  caption="하차지"
                  width="75"
                />
                <Column
                  dataField="freight"
                  caption="청구운임"
                  dataType="number"
                  width="75"
                >
                  <Format type="fixedPoint" />
                </Column>
                <Column
                  dataField="habul_01"
                  caption="상차지 하불금액"
                  dataType="number"
                  width="115"
                >
                  <Format type="fixedPoint" />
                </Column>
                <Column
                  dataField="habul_02"
                  caption="하차지 하불금액"
                  dataType="number"
                  width="115"
                >
                  <Format type="fixedPoint" />
                </Column>
                <Column dataField="blNo" caption="BLNO" width="75" />
                <Column dataField="bkNo" caption="BKNO" width="75" />
                <Column dataField="doNo" caption="DONO" width="75" />
                <Column dataField="manager" caption="담당자" width="75" />
                <Column dataField="extraInfo" caption="특이사항" width="75" />
                <Column dataField="remark" caption="비고" width="75" />
              </DataGrid>
            </PanelGridInit>
          </PanelGridWrapper>
        </PanelBody>
      </PanelInit>
    </>
  );
}

export default ShuttleExcelPage;
