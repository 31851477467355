import axios from "axios";

const apiUrl = `${process.env.REACT_APP_API_URL}/car`;
const apiUrlLookup = `${process.env.REACT_APP_API_URL}`;

export function createCar(data) {
  return axios.post(apiUrl, data);
}

export function updateCar(key, data) {
  return axios.put(apiUrl, { key, data });
}

export function deleteRow(key) {
  return axios.delete(apiUrl + `/${key}`);
}

export function getLookup(param) {
  return axios.get(apiUrlLookup + `/${param}`);
}
