import { useEffect, useReducer } from "react";
import { shallowEqual, useSelector } from "react-redux";
import * as XLSX from "xlsx";
import * as XLSX_ST from "xlsx-js-style";
import { createShuttleByExcel, getLookups } from "./SuttleExcelCrud";
import { v4 as uuidv4 } from "uuid";

const inputType = Object.freeze({
  STRING: "string",
  NUMBER: "number",
  DATE: "date",
  TIME: "time",
  SELECT: "select",
  RADIO: "radio",
});

const shuttleOptions = [
  {
    id: "receiveDate",
    name: "접수일자",
    type: inputType.DATE,
    required: true,
    table: "master",
    order: 1,
  },
  {
    id: "receiveCo",
    name: "접수법인",
    type: inputType.SELECT,
    required: true,
    lookup: "company",
    table: "master",
    order: 2,
  },
  {
    id: "deliverDate",
    name: "운송일자",
    type: inputType.DATE,
    required: true,
    table: "detail",
    order: 3,
  },
  {
    id: "returnDate",
    name: "반납일자",
    type: inputType.DATE,
    table: "detail",
    order: 4,
  },
  {
    id: "cargoOwner",
    name: "화주",
    type: inputType.SELECT,
    required: true,
    lookup: "cargoOwner",
    table: "master",
    order: 5,
  },
  {
    id: "importExport",
    name: "수출_수입",
    type: inputType.SELECT,
    lookup: "importExport",
    table: "master",
    order: 6,
  },
  {
    id: "billingPlace",
    name: "청구처",
    type: inputType.SELECT,
    required: true,
    lookup: "billingPlace",
    table: "master",
    order: 7,
  },
  {
    id: "containerType",
    name: "컨규격",
    type: inputType.SELECT,
    lookup: "conType",
    table: "detail",
    order: 8,
  },
  {
    id: "containerNo",
    name: "컨테이너No",
    type: inputType.STRING,
    table: "detail",
    order: 9,
  },
  {
    id: "sealNo",
    name: "SealNo",
    type: inputType.STRING,
    table: "detail",
    order: 10,
  },
  {
    id: "cargoWeight",
    name: "화물중량_ton",
    type: inputType.NUMBER,
    table: "detail",
    order: 11,
  },
  {
    id: "blNo",
    name: "BL_NO",
    type: inputType.STRING,
    table: "master",
    order: 12,
  },
  {
    id: "doNo",
    name: "DO_NO",
    type: inputType.STRING,
    table: "master",
    order: 13,
  },
  {
    id: "bkNo",
    name: "BK_NO",
    type: inputType.STRING,
    table: "master",
    order: 14,
  },
  {
    id: "motherShip",
    name: "모선항차",
    type: inputType.STRING,
    table: "master",
    order: 15,
  },
  {
    id: "forwarder",
    name: "선사",
    type: inputType.SELECT,
    lookup: "forwarder",
    table: "master",
    order: 16,
  },
  {
    id: "realCargoOwner",
    name: "실화주",
    type: inputType.STRING,
    table: "master",
    order: 17,
  },
  {
    id: "loadPlace",
    name: "상차지",
    type: inputType.SELECT,
    required: true,
    lookup: "terminal",
    table: "master",
    order: 18,
  },
  {
    id: "carTypeCode1",
    name: "차량용차구분1",
    type: inputType.RADIO,
    required: true,
    lookup: "carType",
    table: "dispatch",
    order: 19,
  },
  {
    id: "carNo_01",
    name: "배차차량1",
    type: inputType.SELECT,
    lookup: "car",
    table: "dispatch",
    order: 20,
  },
  {
    id: "yongchasa_01",
    name: "용차사1",
    type: inputType.SELECT,
    lookup: "yongchasa",
    table: "dispatch",
    order: 21,
  },
  {
    id: "yongchaCarNo_01",
    name: "차량번호1",
    type: inputType.STRING,
    table: "dispatch",
    order: 22,
  },
  {
    id: "workPlace",
    name: "작업지",
    type: inputType.SELECT,
    lookup: "workPlace",
    table: "dispatch",
    order: 23,
  },
  {
    id: "carTypeCode2",
    name: "차량용차구분2",
    type: inputType.RADIO,
    required: true,
    lookup: "carType",
    talbe: "dispatch",
    order: 24,
  },
  {
    id: "carNo_02",
    name: "배차차량2",
    type: inputType.SELECT,
    lookup: "car",
    table: "dispatch",
    order: 25,
  },
  {
    id: "yongchasa_02",
    name: "용차사2",
    type: inputType.SELECT,
    lookup: "yongchasa",
    table: "dispatch",
    order: 26,
  },
  {
    id: "yongchaCarNo_02",
    name: "차량번호2",
    type: inputType.STRING,
    table: "dispatch",
    order: 27,
  },
  {
    id: "workPlaceArrive",
    name: "작업지도착시간",
    type: inputType.TIME,
    table: "detail",
    order: 28,
  },
  {
    id: "unloadPlace",
    name: "하차지",
    type: inputType.SELECT,
    required: true,
    lookup: "terminal",
    table: "dispatch",
    order: 29,
  },
  {
    id: "freight",
    name: "청구운임",
    required: true,
    type: inputType.NUMBER,
    table: "detail",
    order: 30,
  },
  {
    id: "habul_01",
    name: "상차지_하불금액",
    type: inputType.NUMBER,
    table: "dispatch",
    order: 31,
  },
  {
    id: "habul_02",
    name: "하차지_하불금액",
    type: inputType.NUMBER,
    table: "dispatch",
    order: 32,
  },
  {
    id: "putDirect",
    name: "접안일",
    type: inputType.DATE,
    table: "master",
    order: 33,
  },
  {
    id: "putTime",
    name: "접안시간",
    type: inputType.TIME,
    table: "master",
    order: 34,
  },
  {
    id: "sailDay",
    name: "출항일",
    type: inputType.DATE,
    table: "master",
    order: 35,
  },
  {
    id: "sailTime",
    name: "출항시간",
    type: inputType.TIME,
    table: "master",
    order: 36,
  },
  {
    id: "manager",
    name: "담당자",
    type: inputType.STRING,
    table: "master",
    order: 37,
  },
  {
    id: "extraInfo",
    name: "특이사항",
    type: inputType.STRING,
    table: "detail",
    order: 38,
  },
  {
    id: "remark",
    name: "비고",
    type: inputType.STRING,
    table: "master",
    order: 39,
  },
];

const initialState = {
  user: null,
  isModalOpen: false,
  isLoading: false,
  preparedExcelData: null,
  shuttleSuccessDataSource: [],
  shuttleFailDataSource: [],
};

const shuttleExcelUploadReducer = (state, action) => {
  switch (action.type) {
    case "INITIAL_STATE":
      return {
        ...state,
        ...action.payload,
      };
    case "OPEN_MODAL":
      return {
        ...state,
        isModalOpen: true,
      };
    case "CLOSE_MODAL":
      return {
        ...state,
        isModalOpen: false,
        preparedExcelData: null,
      };
    case "SET_PREPARED_EXCEL_DATA":
      return {
        ...state,
        preparedExcelData: action.payload,
      };
    case "SAVE_EXCEL_DATA":
      return {
        ...state,
        isLoading: true,
      };
    case "SAVE_EXCEL_DATA_SUCCESS":
      return {
        ...state,
        shuttleSuccessDataSource: action.payload.success,
        shuttleFailDataSource: action.payload.fail,
        isModalOpen: false,
        isLoading: false,
      };
    case "SAVE_EXCEL_DATA_FAIL":
      return {
        ...state,
        isLoading: false,
      };
    default:
      throw new Error("Unhandled action");
  }
};

export const useShuttleExcelUpload = () => {
  const [state, dispatch] = useReducer(shuttleExcelUploadReducer, initialState);
  const { user } = useSelector(
    (state) => ({
      user: state.auth.user.user,
    }),
    shallowEqual
  );

  useEffect(() => {
    dispatch({
      type: "INITIAL_STATE",
      payload: { user },
    });
    // eslint-disable-next-line
  }, []);

  /**
   *
   * @param {`shuttleOption`} option shuttleOptions Object중 해당하는 값
   * @param {*} columnValue excel data의 coulmn 값
   * @param {*} lookup lookup,check type 일때 검증하기 위한 데이터
   * @returns {} isError(실패여부), message(실패메세지), value(select, radio 타입일 경우 lookup data의 value값 반환)
   *
   * 필수 값 체크: 데이터가 빈값일 경우 필수 값 체크
   *
   * type 체크:
   *
   * inputType.STRING: 무조건 성공처리
   *
   * inputType.NUMBER: number 정규식 필터
   *
   * inputType.SELECT: lookup데이터에서 포함되지 않으면 에러 반환
   *
   * inputType.RADIO: lookup데이터에서 포함되지 않으면 에러 반환
   *
   * inputType.DATE: date 정규식 필터(YYYY-MM-DD)
   *
   * inputType.TIME: time 정규식 필터(hh:mm:ss)
   */
  const _validator = (option, value, lookup) => {
    if (value === "") {
      if (option.required) {
        return {
          isError: true,
          message: `(${option.name}) [required error]: 해당 컬럼은 필수로 입력되어야 합니다.\n`,
          value: value,
          displayValue: value,
        };
      }

      return { isError: false, value: null };
    }
    switch (option.type) {
      case inputType.STRING:
        return { isError: false, value: value, displayValue: value };

      case inputType.NUMBER:
        const num = value.replace(/,/gi, "");
        if (!/^[0-9]+$/.test(num)) {
          return {
            isError: true,
            message: `(${option.name}) [type error]: 숫자만 입력해 주세요.\n`,
            value: num,
            displayValue: value,
          };
        }
        return { isError: false, value: num, displayValue: value };

      case inputType.SELECT:
        const selectedData = lookup.find((element) => element.name === value);
        if (!selectedData) {
          return {
            isError: true,
            message: `(${option.name}) [code error]: "${value}"값으로 저장된 코드가 없습니다.\n`,
            value: value,
            displayValue: value,
          };
        }

        return {
          isError: false,
          value: selectedData.value,
          displayValue: value,
        };

      case inputType.RADIO:
        const checkedData = lookup.find((element) => element.name === value);
        if (!checkedData) {
          return {
            isError: true,
            message: `(${option.name}) [code error]: "${value}"값으로 저장된 코드가 없습니다.\n`,
            value: value,
            displayValue: value,
          };
        }

        return {
          isError: false,
          value: checkedData.value,
          displayValue: value,
        };

      case inputType.DATE:
        if (!/^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/.test(value)) {
          return {
            isError: true,
            message: `(${option.name}) [type error]: 날짜 형식에 맞게 입력해 주세요(YYYY-MM-DD).\n`,
            value: value,
            displayValue: value,
          };
        }
        return { isError: false, value: value, displayValue: value };
      case inputType.TIME:
        if (
          // eslint-disable-next-line
          !/([0-5][0-9]):([0-5][0-9]):([0-5][0-9])(([\-\+]([0-1][0-9])\:00))?/.test(
            value
          )
        ) {
          return {
            isError: true,
            message: `(${option.name}) [type error]: 시간 형식에 맞게 입력해 주세요(hh:mm:ss).\n`,
            value: value,
            displayValue: value,
          };
        }
        return { isError: false, value: value, displayValue: value };
      default:
        return { isError: true, value: "", displayValue: "" };
    }
  };

  const _checkCarCodeType = (data) => {
    const result = { error: false, message: "" };
    if (
      data.carTypeCode1 === "DISCARTYPE-01" &&
      data.yongchasa_01 &&
      data.yongchaCarNo_01
    ) {
      result.error = true;
      result.message = result.message +=
        "차량용차구분1 값이 차량번호인 경우 용차사1, 차량번호1 값은 입력이 불가능합니다.\n";
    }

    if (data.carTypeCode1 === "DISCARTYPE-02" && data.carNo_01) {
      result.error = true;
      result.message = result.message +=
        "차량용차구분1 값이 용차사인 경우 배차차량1 값은 입력이 불가능합니다.\n";
    }

    if (
      data.carTypeCode2 === "DISCARTYPE-01" &&
      data.yongchasa_02 &&
      data.yongchaCarNo_02
    ) {
      result.error = true;
      result.message = result.message +=
        "차량용차구분2 값이 차량번호인 경우 용차사2, 차량번호2 값은 입력이 불가능합니다.\n";
    }

    if (data.carTypeCode2 === "DISCARTYPE-02" && data.carNo_02) {
      result.error = true;
      result.message = result.message +=
        "차량용차구분2 값이 용차사인 경우 배차차량2 값은 입력이 불가능합니다.\n";
    }

    return result;
  };

  const _generateExcelTitleAoa = ({ sortedShuttleOptions, isFailInclude }) => {
    const title = [];
    sortedShuttleOptions.forEach((option) => {
      if (option.type === inputType.SELECT || option.type === inputType.RADIO) {
        title.push({
          v: option.name,
          t: "s",
          s: { fill: { fgColor: { rgb: "F9D71C" } } },
        });
      } else {
        title.push(option.name);
      }
    });

    if (isFailInclude) {
      title.push("에러내용");
    }

    return title;
  };

  const _generateExcelAoa = (jsonArray) => {
    const sortedShuttleOptions = [...shuttleOptions].sort(
      (a, b) => a.order - b.order
    );
    const title = _generateExcelTitleAoa({
      sortedShuttleOptions: sortedShuttleOptions,
      isFailInclude: true,
    });

    const body = [];

    jsonArray.forEach((json) => {
      const row = [];
      sortedShuttleOptions.forEach((option) => {
        row.push(json[option.id]);
      });
      row.push(json["message"]);
      body.push(row);
    });

    return [title, ...body];
  };

  // const _generateExcelCodeBookAoa = (lookup) => {
  //   const title = ["순번", "이름", "값"];
  //   const body = [];
  //   lookup.forEach((data, i) => {
  //     body.push([i + 1, data.name, data.value]);
  //   });
  //   return [title, ...body];
  // };

  const openModal = () =>
    dispatch({
      type: "OPEN_MODAL",
    });

  const closeModal = () =>
    dispatch({
      type: "CLOSE_MODAL",
    });

  const processExcelData = (data) => {
    const reader = new FileReader();

    reader.onload = (evt) => {
      const fileData = evt.target.result;
      const wb = XLSX.read(fileData, { type: "binary" });

      const sheetName = wb.SheetNames[0];

      const objArr = XLSX.utils.sheet_to_json(wb.Sheets[sheetName], {
        raw: false,
        blankrows: false,
        defval: "",
      });

      dispatch({
        type: "SET_PREPARED_EXCEL_DATA",
        payload: objArr,
      });
    };
    reader.readAsBinaryString(data[0]);
  };

  const onSaveExcelData = async () => {
    dispatch({
      type: "SAVE_EXCEL_DATA",
    });

    /** DB INSERT DATA LIST */
    let forDataListResult = [];
    let forGridListResult = [];

    try {
      const lookups = await getLookups();
      const excelData = state.preparedExcelData;
      const shuttleDataSource = {
        success: [],
        fail: [],
      };
      for (const row of excelData) {
        const result = {
          isError: false,
          message: "",
          data: {},
          gridData: {},
        };

        // success, error 분기
        shuttleOptions.forEach((option) => {
          const columnValue = row[option.name];
          const lookup = Array.isArray(lookups[option.lookup])
            ? lookups[option.lookup]
            : [];

          const { isError, message, value, displayValue } = _validator(
            option,
            columnValue,
            lookup
          );

          if (isError) {
            result.isError = true;
          }

          result.message += message ?? "";

          //그리드 표현 데이터
          result.gridData = {
            ...result.gridData,
            [option.id]: displayValue,
            message: message,
          };

          //실제 db에 insert될 데이터
          result.data = {
            ...result.data,
            [option.id]: value,
            message: message,
          };
        });

        if (result.isError === false) {
          forDataListResult.push(result.data);
          forGridListResult.push(result.gridData);
        }

        const { error, message } = _checkCarCodeType(result.data);
        if (error) {
          result.isError = true;
          result.message += message;
        }

        if (result.isError) {
          shuttleDataSource.fail.push({
            code: uuidv4(),
            ...result.gridData,
            message: result.message,
          });
          continue;
        }
      }

      const isSuccess = await createShuttleByExcel(forDataListResult);

      for (const obj of forGridListResult) {
        if (isSuccess) {
          shuttleDataSource.success.push({
            code: uuidv4(),
            ...obj,
          });
        } else {
          shuttleDataSource.fail.push({
            code: uuidv4(),
            ...obj,
            message: "server error",
          });
        }
      }

      dispatch({
        type: "SAVE_EXCEL_DATA_SUCCESS",
        payload: shuttleDataSource,
      });
    } catch (e) {
      dispatch({
        type: "SAVE_EXCEL_DATA_FAIL",
      });
      alert("엑셀 업로드 오류 !!");
    }
  };

  const exportfailedExcel = async () => {
    const failedData = [...state.shuttleFailDataSource];

    if (failedData.length === 0) {
      alert("데이터가 없습니다.");
    }
    const aoaData = _generateExcelAoa(failedData);
    const wb = XLSX_ST.utils.book_new();
    const newWorksheet = XLSX_ST.utils.aoa_to_sheet(aoaData);

    XLSX_ST.utils.book_append_sheet(wb, newWorksheet, "실패목록");
    XLSX_ST.writeFile(wb, "업로드_실패목록.xlsx");
  };

  const exportExcelSample = async () => {
    const wb = XLSX_ST.utils.book_new();
    // const lookups = await getLookups();

    const sortedShuttleOptions = [...shuttleOptions].sort(
      (a, b) => a.order - b.order
    );

    const title = _generateExcelTitleAoa({
      sortedShuttleOptions: sortedShuttleOptions,
      isFailInclude: false,
    });

    // 입력폼 sheet 생성
    const inputSheet = XLSX_ST.utils.aoa_to_sheet([title]);
    XLSX_ST.utils.book_append_sheet(wb, inputSheet, "입력 폼");

    // 입력 (select, radio) 코드북 sheet 생성
    // sortedShuttleOptions
    //   .filter(
    //     (option) =>
    //       option.type === inputType.SELECT || option.type === inputType.RADIO
    //   )
    //   .forEach((option) => {
    //     const lookup = Array.isArray(lookups[option.lookup])
    //       ? lookups[option.lookup]
    //       : [];
    //     const aoa = _generateExcelCodeBookAoa(lookup);

    //     const newSheet = XLSX_ST.utils.aoa_to_sheet(aoa);
    //     XLSX_ST.utils.book_append_sheet(wb, newSheet, option.name);
    //   });

    // 엑셀 export
    XLSX_ST.writeFile(wb, "업로드_샘플.xlsx");
  };

  return {
    state,
    openModal,
    closeModal,
    processExcelData,
    onSaveExcelData,
    exportfailedExcel,
    exportExcelSample,
  };
};
