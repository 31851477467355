import { DataGrid } from "devextreme-react";
import { Column, Scrolling } from "devextreme-react/data-grid";
import CustomStore from "devextreme/data/custom_store";
import moment from "moment";
import React, { useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { sendRequest } from "../../../../_metronic/_helpers/DataSoureHelpers";
import CustomButton from "../../../components/FunctionButton/CustomButton";
import InputMonth from "../../../components/InputForm/InputMonth";
import HeaderTitle from "../../../components/Layout/Header/HeaderTitle";
import {
  DataCount,
  PanelGridInit,
  PanelGridSearchBar,
  PanelGridWrapper,
  SearchItems,
} from "../../../components/Layout/Panel/PanelGrid/panelGrid.style";
import {
  PanelBody,
  PanelHeader,
  PanelInit,
} from "../../../components/Layout/Panel/panel.style";
import EmailPopup from "./components/EmailPopup";

function InvoiceEmailHistoryPage() {
  const initialState = { baseMonth: moment().format("YYYY-MM") };
  const [totalCount, setTotalCount] = useState(0);
  const [search, setSearch] = useState(initialState);
  const [selectedData, setSelectedData] = useState({});
  const [mailPopup, setMailPopup] = useState(false);

  const dataSource = useMemo(() => {
    const apiUrl = `${process.env.REACT_APP_API_URL}/invoice-email-history`;
    return new CustomStore({
      key: "oid",
      load: () => sendRequest(apiUrl, "GET", search),
    });
  }, [search]);

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: initialState,
  });

  const onSubmit = (data) => {
    setSearch({ ...data });
  };

  const onRowDblClick = (e) => {
    setSelectedData(e.data);
    setMailPopup(true);
  };

  return (
    <>
      <PanelInit>
        <PanelHeader>
          <HeaderTitle />
        </PanelHeader>

        <PanelBody row left="100%" right="auto">
          <PanelGridWrapper>
            <PanelGridSearchBar onSubmit={handleSubmit(onSubmit)}>
              <SearchItems>
                <label>기준 월</label>
                <InputMonth
                  className="label-select"
                  control={control}
                  errors={errors}
                  flex={true}
                  name="baseMonth"
                />
              </SearchItems>
              <CustomButton
                type="submit"
                height={24}
                layout="solid"
                color="search"
                label="검색"
              />
            </PanelGridSearchBar>
            <PanelGridInit>
              <br />
              <DataCount>{`총 ${totalCount}건`}</DataCount>
              <DataGrid
                className="datagrid-wrap"
                id="FinishManageUpper"
                dataSource={dataSource}
                showRowLines={true}
                showBorders={true}
                hoverStateEnabled={true}
                allowColumnResizing={true}
                allowColumnReordering={true}
                columnResizingMode="widget"
                keyExpr="id"
                focusedRowEnabled={true}
                onContentReady={(e) => setTotalCount(e.component.totalCount())}
                onRowDblClick={onRowDblClick}
              >
                <Scrolling
                  columnRenderingMode="virtual"
                  mode="virtual"
                  showScrollbar="always"
                  useNative="false"
                />
                <Column dataField="subject" caption="제목" />
                <Column dataField="userName" caption="발송자명" width={150} />
                <Column
                  dataField="mailTo"
                  caption="수신자 이메일"
                  width={180}
                />
                <Column dataField="contents" caption="내용" />
                <Column dataField="fileName" caption="첨부파일명" />
                <Column
                  dataField="createDate"
                  caption="발송일시"
                  dataType="date"
                  format="yyyy-MM-dd HH:mm:ss"
                  width={130}
                />
              </DataGrid>
            </PanelGridInit>
          </PanelGridWrapper>
        </PanelBody>
      </PanelInit>
      {mailPopup && (
        <EmailPopup
          onClose={() => setMailPopup(false)}
          visible={true}
          maskClosable={false}
          selectedData={selectedData}
        />
      )}
    </>
  );
}

export default InvoiceEmailHistoryPage;
