import axios from "axios";

const apiUrl = `${process.env.REACT_APP_API_URL}`;
const apiUrlContainer = `${process.env.REACT_APP_API_URL}/containercost`;

export function getLookup(param) {
  return axios.get(apiUrl + `/${param}`);
}

export function ContainerCostCRUD(data) {
  return axios.post(apiUrlContainer, data);
}
