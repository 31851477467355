import { DataGrid } from "devextreme-react";
import {
  Column,
  ColumnChooser,
  FilterRow,
  Format,
  HeaderFilter,
  MasterDetail,
  Scrolling,
  StateStoring,
} from "devextreme-react/data-grid";
import CustomStore from "devextreme/data/custom_store";
import { alert, confirm } from "devextreme/ui/dialog";
import moment from "moment";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { shallowEqual, useSelector } from "react-redux";
import { sendRequest } from "../../../../_metronic/_helpers/DataSoureHelpers";

import * as requestServer from "./DispatchCrud";
import DispatchDetail from "./DispatchDetail";
import * as Modals from "./DispatchModals";

import { useCallback } from "react";
import CustomButton from "../../../components/FunctionButton/CustomButton";
import InputCheckBox from "../../../components/InputForm/InputCheckBox";
import InputDate from "../../../components/InputForm/InputDate";
import InputSelect from "../../../components/InputForm/InputSelect";
import HeaderTitle from "../../../components/Layout/Header/HeaderTitle";
import {
  DataCount,
  PanelGridInit,
  PanelGridSearchBar,
  PanelGridWrapper,
  SearchItems,
} from "../../../components/Layout/Panel/PanelGrid/panelGrid.style";
import {
  PanelBody,
  PanelHeader,
  PanelInit,
} from "../../../components/Layout/Panel/panel.style";
import StateStoringSaveAndDeleteButton from "../../../components/StateStoring";
import { useDatagrid } from "../../../hooks/useDatagrid";

function Dispatch(props) {
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  // modals
  const [departureModal, setDepartureModal] = useState(false);
  const [dispatchModal, setDispatchModal] = useState(false);
  const [workWaypointModal, setWorkWaypointModal] = useState(false);
  const [workPlaceModal, setWorkPlaceModal] = useState(false);
  const [collectWaypointModal, setCollectWaypointModal] = useState(false);
  const [unloadPlaceModal, setUnloadPlaceModal] = useState(false);
  const [freightModal, setFreightModal] = useState(false);
  const [chassisModal, setChassisModal] = useState(false);
  const [fridgeDangerModal, setFridgeDangerModal] = useState(false);
  const [bobtailModal, setBobtailModal] = useState(false);
  const [habulModal, setHabulModal] = useState(false);
  const [extraChargeModal, setExtraChargeModal] = useState(false);
  const [wareHouseModal, setWareHouseModal] = useState(false);
  const [arrivalModal, setArrivalModal] = useState(false);
  const [returnModal, setReturnModal] = useState(false);
  const [longtermModal, setLongtermModal] = useState(false);
  const [containerNoModal, setContainerNoModal] = useState(false);
  const [warehouseTimeModal, setWarehouseTimeModal] = useState(false);
  const [extraInfoModal, setExtraInfoModal] = useState(false);

  const [companyName, setCompany] = useState();
  const [disaptchName, setDisaptchTeam] = useState();
  // const [deptName, setDeptName] = useState();

  // other state
  const [totalCount, setTotalCount] = useState(0);
  const [rowData, setRowData] = useState();
  const [detailRowData, setDetailRowData] = useState();
  const [search, setSearch] = useState({});
  const [focusedComponent, setFocusedComponent] = useState();

  const dataGridRef = useRef(null);
  const detailGridRef = useRef(null);

  useDatagrid(dataGridRef);

  const storageKey = "dispatchMaster";

  const { user } = useSelector(
    (state) => ({ user: state.auth.user.user }),
    shallowEqual
  );

  const dataSource = useMemo(() => {
    const apiUrl = `${process.env.REACT_APP_API_URL}/dispatch`;

    return new CustomStore({
      key: "id",
      load: () => sendRequest(apiUrl, "GET", search),
    });
  }, [search]);

  const initialState = {
    firstDay: moment().format("YYYY-MM-DD"),
    lastDay: moment().format("YYYY-MM-DD"),
    searchType: 2,
    authGroupId: user.authGroupId,
    company: user.companyCode,
    dispatchTeam: user?.deptCode || "",
    importExport: "",
    isBobtail: 0,
    isLoss: false,
  };
  useEffect(() => {
    reset(initialState);
    setSearch(initialState);

    getLookup();
    // eslint-disable-next-line
  }, []);

  async function getLookup() {
    try {
      // const deptNameData = await requestServer.getLookupDatas(
      //   "department/lookup"
      // );
      const companyData = await requestServer.getLookupDatas("company");
      const disaptchTeamData = await requestServer.getLookupDatas(
        "department/isDispatch"
      );

      setDisaptchTeam([
        { code: "", deptName: "전체" },
        ...disaptchTeamData.data,
      ]);
      setCompany([{ id: "", companyName: "전체" }, ...companyData.data]);
      // setDeptName(deptNameData.data);
    } catch (err) {
      alert(err.response.data.message, "오류");
    }
  }

  /* #region modal function -------------------------------------------------------- */

  // const setDeparture = () => setDepartureModal(true);
  // 배차등록
  const setDispatch = () => {
    if (!detailRowData) {
      alert("배차디테일정보를 선택해주세요", "오류");
      return;
    }

    if (
      detailRowData.dispatchStatus !== "접수" &&
      detailRowData.dispatchStatus !== "배차등록"
    ) {
      alert("접수 상태에서만 배차등록이 가능합니다", "오류");
      return;
    }

    if (detailRowData.completeNo) {
      alert("정산된 데이터입니다", "오류");
      return;
    }

    // if (detailRowData.dispatchStatus !== 62) {
    //   alert("접수 상태에서만 배차등록이 가능합니다", "오류");
    //   return;
    // }

    setDispatchModal(true);
  };

  // 작업경유지등록
  const setWorkWaypoint = () => {
    if (!rowData) {
      alert("배차정보를 선택해주세요", "오류");
      return;
    }

    for (const obj of rowData.detail) {
      if (obj.completeNo) {
        alert("정산된 데이터입니다", "오류");
        return;
      }
    }

    if (rowData.completeNo) {
      alert("정산된 데이터입니다", "오류");
      return;
    }

    if (!rowData.confirm) {
      alert("오더 확정을 해주세요", "오류");
      return;
    }

    setWorkWaypointModal(true);
  };

  // 작업지등록
  const setWorkplace = () => {
    if (!rowData) {
      alert("배차정보를 선택해주세요", "오류");
      return;
    }

    for (const obj of rowData.detail) {
      if (obj.completeNo) {
        alert("정산된 데이터입니다", "오류");
        return;
      }
    }

    if (rowData.completeNo) {
      alert("정산된 데이터입니다", "오류");
      return;
    }

    if (!rowData.confirm) {
      alert("오더 확정을 해주세요", "오류");
      return;
    }

    setWorkPlaceModal(true);
  };

  const setCollectWaypoint = () => {
    if (!rowData) {
      alert("배차정보를 선택해주세요", "오류");
      return;
    }

    for (const obj of rowData.detail) {
      if (obj.completeNo) {
        alert("정산된 데이터입니다", "오류");
        return;
      }
    }

    if (rowData.completeNo) {
      alert("정산된 데이터입니다", "오류");
      return;
    }

    if (!rowData.confirm) {
      alert("오더 확정을 해주세요", "오류");
      return;
    }

    if (!rowData.workPlace) {
      alert("작업지를 등록해주세요", "오류");
      return;
    }

    setCollectWaypointModal(true);
  };

  // 하차지 등록
  const setUnloadPlace = () => {
    if (!rowData) {
      alert("배차정보를 선택해주세요", "오류");
      return;
    }

    for (const obj of rowData.detail) {
      if (obj.completeNo) {
        alert("정산된 데이터입니다", "오류");
        return;
      }
    }

    if (rowData.completeNo) {
      alert("정산된 데이터입니다", "오류");
      return;
    }

    if (!rowData.confirm) {
      alert("오더 확정을 해주세요", "오류");
      return;
    }

    setUnloadPlaceModal(true);
  };

  const setFreight = () => {
    if (!rowData) {
      alert("배차정보를 선택해주세요", "오류");
      return;
    }

    for (const obj of rowData.detail) {
      if (obj.completeNo) {
        alert("정산된 데이터입니다", "오류");
        return;
      }
    }

    if (rowData.completeNo) {
      alert("정산된 데이터입니다", "오류");
      return;
    }

    if (!rowData.confirm) {
      alert("오더 확정을 해주세요", "오류");
      return;
    }

    setFreightModal(true);
  };

  const setChassis = () => {
    if (!rowData) {
      alert("배차정보를 선택해주세요", "오류");
      return;
    }

    for (const obj of rowData.detail) {
      if (obj.completeNo) {
        alert("정산된 데이터입니다", "오류");
        return;
      }
    }

    if (rowData.completeNo) {
      alert("정산된 데이터입니다", "오류");
      return;
    }

    if (!rowData.confirm) {
      alert("오더 확정을 해주세요", "오류");
      return;
    }

    setChassisModal(true);
  };

  const setFridgeDanger = () => {
    if (!rowData) {
      alert("배차정보를 선택해주세요", "오류");
      return;
    }

    if (!(rowData.fridge || rowData.danger)) {
      alert("위험물/냉동컨테이너가 아닙니다", "오류");
      return;
    }

    setFridgeDangerModal(true);
  };

  // 밥테일 등록
  const setBobtail = () => {
    if (!rowData) {
      alert("배차정보를 선택해주세요", "오류");
      return;
    }

    if (!rowData.workPlace) {
      alert("작업지가 없습니다", "오류");
      return;
    }

    for (const obj of rowData.detail) {
      if (obj.completeNo) {
        alert("정산된 데이터입니다", "오류");
        return;
      }
    }

    if (rowData.completeNo) {
      alert("정산된 데이터입니다", "오류");
      return;
    }

    if (!rowData.confirm) {
      alert("오더 확정을 해주세요", "오류");
      return;
    }

    setBobtailModal(true);
  };

  // 하불등록
  const setHabul = () => {
    if (!detailRowData) {
      alert("배차디테일정보를 선택해주세요", "오류");
      return;
    }

    if (detailRowData.completeNo) {
      alert("정산된 데이터입니다", "오류");
      return;
    }
    setHabulModal(true);
  };

  // 할증등록
  const setExtraCharge = () => {
    if (!rowData) {
      alert("배차정보를 선택해주세요", "오류");
      return;
    }

    for (const obj of rowData.detail) {
      if (obj.completeNo) {
        alert("정산된 데이터입니다", "오류");
        return;
      }
    }

    if (rowData.completeNo) {
      alert("정산된 데이터입니다", "오류");
      return;
    }

    setExtraChargeModal(true);
  };

  const setArrival = () => {
    if (!rowData) {
      alert("배차정보를 선택해주세요", "오류");
      return;
    }
    setArrivalModal(true);
  };

  const setReturn = () => {
    if (!rowData) {
      alert("배차정보를 선택해주세요", "오류");
      return;
    }

    setReturnModal(true);
  };

  const setLongterm = () => {
    if (!rowData) {
      alert("배차정보를 선택해주세요", "오류");
      return;
    }

    for (const obj of rowData.detail) {
      if (obj.completeNo) {
        alert("정산된 데이터입니다", "오류");
        return;
      }
    }

    if (rowData.completeNo) {
      alert("정산된 데이터입니다", "오류");
      return;
    }

    setLongtermModal(true);
  };

  const setContainerNo = () => {
    if (!rowData) {
      alert("배차정보를 선택해주세요", "오류");
      return;
    }

    if (rowData.importExport === 1) {
      alert("수입컨테이너는 번호를 변경할 수 없습니다", "오류");
      return;
    }

    setContainerNoModal(true);
  };

  const finishDispatch = async () => {
    if (!detailRowData) {
      alert("배차디테일정보를 선택해주세요", "오류");
      return;
    }

    if (detailRowData.dispatchStatus !== "배차등록") {
      alert("배차등록 상태에서만 배차확정이 가능합니다", "오류");
      return;
    }

    const result = await confirm("배차확정 하시겠습니까?", "확인");
    if (!result) return;

    try {
      await requestServer.dispatchFinish(detailRowData);
      dataGridRef.current.instance.refresh();
    } catch (err) {
      alert(err.response.data.message, "오류");
    }
  };

  const finishDelivery = async () => {
    if (!detailRowData) {
      alert("배차디테일정보를 선택해주세요", "오류");
      return;
    }

    if (detailRowData.dispatchStatus !== "배차확정") {
      alert("배차확정 상태에서만 운송완료 가능합니다", "오류");
      return;
    }

    if (!detailRowData.endPoint) {
      alert("도착지가 없습니다", "오류");
      return;
    }

    const result = await confirm("운송완료 하시겠습니까?", "확인");
    if (!result) return;

    try {
      await requestServer.deliverFinish(detailRowData);
      dataGridRef.current.instance.refresh();
    } catch (err) {
      alert(err);
      // alert(err.response.data.message, "오류");
    }
  };

  const cancelDispatch = async () => {
    if (!detailRowData) {
      alert("배차디테일정보를 선택해주세요", "오류");
      return;
    }

    if (detailRowData.completeNo) {
      alert("정산된 데이터입니다", "오류");
      return;
    }

    if (detailRowData.dispatchStatus === 62) {
      alert("배차등록 되지않았습니다", "오류");
      return;
    }

    const result = await confirm("배차취소 하시겠습니까?", "확인");
    if (!result) return;

    try {
      await requestServer.cancelDispatch(detailRowData);

      dataGridRef.current.instance.refresh();
    } catch (err) {
      alert(err.response.data.message, "오류");
    }
  };

  const setExtraInfo = async () => {
    if (!rowData) {
      alert("배차정보를 선택해주세요", "오류");
      return;
    }

    setExtraInfoModal(true);
  };

  // const setWarehouseTime = () => {
  //   if (!rowData) {
  //     alert("배차정보를 선택해주세요", "오류");
  //     return;
  //   }

  //   if (!rowData.orderCode_orderMaster.danger) {
  //     alert("위험물 컨테이너가 아닙니다", "오류");
  //     return;
  //   }

  //   if (
  //     rowData.orderCode_orderMaster.unloadPlace_terminalWarehouse.division ===
  //     52
  //   ) {
  //     alert("하차지가 창고가 아닙니다", "오류");
  //     return;
  //   }

  //   if (rowData.warehouseArrive) {
  //     alert("이미 입고된 데이터입니다.", "오류");
  //     return;
  //   }

  //   if (rowData.orderStatus !== 80) {
  //     alert("운송완료된 데이터가 아닙니다.", "오류");
  //     return;
  //   }

  //   if (rowData.orderCode.substr(rowData.orderCode.length - 1, 1) === "R") {
  //     alert("위험물데이터 입니다", "오류");
  //     return;
  //   }

  //   setWarehouseTimeModal(true);
  // };

  const closeModal = () => {
    setDepartureModal(false);
    setDispatchModal(false);
    setWorkWaypointModal(false);
    setWorkPlaceModal(false);
    setCollectWaypointModal(false);
    setUnloadPlaceModal(false);
    setChassisModal(false);
    setFridgeDangerModal(false);
    setBobtailModal(false);
    setHabulModal(false);
    setExtraChargeModal(false);
    setWareHouseModal(false);
    setArrivalModal(false);
    setReturnModal(false);
    setLongtermModal(false);
    setContainerNoModal(false);
    setWarehouseTimeModal(false);
    setFreightModal(false);
    setExtraInfoModal(false);

    dataGridRef.current &&
      dataGridRef.current.instance.option("focusedRowIndex", -1);
    detailGridRef.current &&
      detailGridRef.current.instance.option("focusedRowIndex", -1);
    setRowData(null);
    setDetailRowData(null);
  };

  /* #endregion */

  /* #region dataGrid 함수 -------------------------------------------------------- */
  const onCellClick = useCallback((e) => {
    if (!e.row) return;
    // detailGridRef.current && detailGridRef.current.instance.option("focusedRowIndex", -1);
    const row = e.row;
    if (row.rowType === "data") {
      setRowData(row.data);
    }
  }, []);

  const onFocusedRowChanged = useCallback(
    (e) => {
      if (e.rowIndex === -1) return;
      // eslint-disable-next-line
      if (focusedComponent && focusedComponent != e.component) {
        focusedComponent.option("focusedRowIndex", -1);
        if (e.element.id === "dispatchMaster") {
          setDetailRowData(null);
        } else if (e.element.id === "dispatchDetail") {
          setRowData(null);
        }
      }
      setFocusedComponent(e.component);
    },
    [focusedComponent]
  );

  const onRowDblClick = useCallback((e) => {
    if (e.isExpanded) {
      e.component.collapseRow(e.key);
    } else {
      e.component.expandRow(e.key);
    }
  }, []);

  const importExportRender = useCallback((e) => {
    if (e.row.data.importExport === 1) {
      return <div>수입</div>;
    } else {
      return <div>수출</div>;
    }
  }, []);

  const orderStatusRender = useCallback((e) => {
    if (e.data.longStorage === 1) {
      return <div>장기보관</div>;
    } else {
      return <div>{e.text}</div>;
    }
  }, []);

  const divisionRender = useCallback((e) => {
    if (e.value === "order") return <div>오더접수</div>;
    if (e.value === "lclorder") return <div>LCL오더</div>;
    if (e.value === "simple") return <div>간편배차</div>;
    if (e.value === "shuttle") return <div>셔틀배차</div>;
    if (e.value === "otherCo") return <div>용차배차</div>;
  }, []);

  const onCellPrepared = useCallback((e) => {
    if (e.rowType !== "data") return;
    if (
      e.column.caption !== "상차지" &&
      e.column.caption !== "하차지" &&
      e.column.caption !== "하불금액"
    )
      return;

    if (e.column.caption === "상차지") {
      e.cellElement.style.background = e.data.loadPlaceColor || "transparant";
    } else if (e.column.caption === "하차지") {
      e.cellElement.style.background = e.data.unloadPlaceColor || "transparant";
    } else if (e.column.caption === "하불금액") {
      const extraCharge = Number(e.row.data.extraCharge) || 0;
      const freight = Number(e.row.data.freight) || 0;
      const habul = Number(e.row.data.habul) || 0;
      if (extraCharge + freight < habul) {
        e.cellElement.style.background = "red";
      }
    }
  }, []);

  /* #endregion */

  const onSubmit = (data) => {
    // for (const obj in data) {
    //   if (data[obj] === "") {
    //     data[obj] = null;
    //   }
    // }
    setSearch(data);
  };
  return (
    <>
      <PanelInit>
        <PanelHeader>
          <HeaderTitle />
        </PanelHeader>

        <PanelBody row left="100%" right="0">
          <PanelGridWrapper>
            <PanelGridSearchBar onSubmit={handleSubmit(onSubmit)}>
              <SearchItems>
                <label>접수법인</label>
                <InputSelect
                  control={control}
                  errors={errors}
                  name="company"
                  dataSource={companyName}
                  valueExpr="id"
                  displayExpr="companyName"
                  flex={true}
                />
              </SearchItems>

              <SearchItems>
                <label>배차팀</label>
                <InputSelect
                  control={control}
                  errors={errors}
                  name="dispatchTeam"
                  dataSource={disaptchName}
                  valueExpr="code"
                  displayExpr="deptName"
                  flex={true}
                />
              </SearchItems>

              <SearchItems>
                <label>수출/수입</label>
                <InputSelect
                  control={control}
                  errors={errors}
                  name="importExport"
                  dataSource={[
                    { id: "", name: "전체" },
                    { id: 1, name: "수입" },
                    { id: 2, name: "수출" },
                  ]}
                  valueExpr="id"
                  displayExpr="name"
                  flex={true}
                />
              </SearchItems>
              <SearchItems>
                <InputSelect
                  className="label-select"
                  control={control}
                  errors={errors}
                  name="searchType"
                  displayExpr="name"
                  valueExpr="id"
                  dataSource={[
                    { id: 2, name: "운송요청일자" },
                    { id: 1, name: "접수일자" },
                    { id: 3, name: "작업지도착시간" },
                  ]}
                  flex={true}
                />
                <InputDate
                  control={control}
                  errors={errors}
                  name="firstDay"
                  flex={true}
                />
                <div className="to-mark">~</div>
                <InputDate
                  control={control}
                  errors={errors}
                  name="lastDay"
                  flex={true}
                />
              </SearchItems>

              <SearchItems>
                <label>밥테일 여부</label>
                <InputSelect
                  className="label-select"
                  control={control}
                  errors={errors}
                  name="isBobtail"
                  valueExpr="id"
                  displayExpr="name"
                  dataSource={[
                    { id: 0, name: "전체" },
                    { id: 1, name: "밥테일" },
                    { id: 2, name: "밥테일제외" },
                  ]}
                  flex={true}
                />
              </SearchItems>

              <SearchItems>
                <InputCheckBox
                  className="label-select"
                  control={control}
                  errors={errors}
                  label="하불손실조회"
                  name="isLoss"
                />
              </SearchItems>

              <CustomButton
                type="submit"
                height={24}
                layout="solid"
                color="search"
                label="검색"
              />
            </PanelGridSearchBar>

            <PanelGridSearchBar>
              <button
                type="button"
                className="btn btn-grid btn-grid-set lg"
                onClick={setWorkWaypoint}
              >
                작업경유지 등록
              </button>
              <button
                type="button"
                className="btn btn-grid btn-grid-set lg"
                onClick={setWorkplace}
              >
                작업지 등록
              </button>
              <button
                type="button"
                className="btn btn-grid btn-grid-set lg"
                onClick={setCollectWaypoint}
              >
                회수경유지 등록
              </button>
              <button
                type="button"
                className="btn btn-grid btn-grid-set lg"
                onClick={setUnloadPlace}
              >
                하차지 등록
              </button>

              <div className="seperate" />

              <button
                type="button"
                className="btn btn-grid btn-grid-modi lg"
                onClick={setFreight}
              >
                청구금액등록
              </button>
              <button
                type="button"
                className="btn btn-grid btn-grid-modi lg"
                onClick={setChassis}
              >
                샤시등록
              </button>
              <button
                type="button"
                className="btn btn-grid btn-grid-modi lg"
                onClick={setArrival}
              >
                도착시간등록
              </button>
              <button
                type="button"
                className="btn btn-grid btn-grid-modi lg"
                onClick={setContainerNo}
              >
                컨테이너정보등록
              </button>
              <button
                type="button"
                className="btn btn-grid btn-grid-modi lg"
                onClick={setExtraCharge}
              >
                할증등록
              </button>
              <button
                type="button"
                className="btn btn-grid btn-grid-modi lg"
                onClick={setExtraInfo}
              >
                특이사항등록
              </button>

              <div className="seperate" />

              <button
                type="button"
                className="btn btn-grid btn-grid-change lg"
                onClick={setFridgeDanger}
              >
                위험물/냉동
              </button>
              <button
                type="button"
                className="btn btn-grid btn-grid-change lg"
                onClick={setBobtail}
              >
                밥테일로 변경
              </button>
              <button
                type="button"
                className="btn btn-grid btn-grid-change lg"
                onClick={setReturn}
              >
                반납일자/선사반출기한 변경
              </button>
              <button
                type="button"
                className="btn btn-grid btn-grid-change lg"
                onClick={setLongterm}
              >
                장기보관/취소
              </button>
            </PanelGridSearchBar>
            <div className="function-btn-wrap">
              <button
                type="button"
                className="btn btn-grid btn-grid-delete"
                onClick={setHabul}
              >
                하불등록
              </button>
              <button
                type="button"
                className="btn btn-grid btn-grid-new lg"
                onClick={setDispatch}
              >
                배차등록
              </button>
              <button
                type="button"
                className="btn btn-grid btn-grid-add"
                onClick={finishDispatch}
              >
                배차확정
              </button>
              <button
                type="button"
                className="btn btn-grid btn-grid-add"
                onClick={finishDelivery}
              >
                운송완료
              </button>
              <button
                type="button"
                className="btn btn-grid btn-grid-delete"
                onClick={cancelDispatch}
              >
                배차취소
              </button>
            </div>

            <PanelGridInit>
              <StateStoringSaveAndDeleteButton
                dataGridRef={dataGridRef}
                storageKey={storageKey}
              />
              <DataCount>{`총 ${totalCount}건`}</DataCount>
              <DataGrid
                className="datagrid-wrap"
                id="DispatchMaster"
                dataSource={dataSource}
                ref={dataGridRef}
                showRowLines={true}
                showBorders={true}
                hoverStateEnabled={true}
                allowColumnResizing={true}
                allowColumnReordering={true}
                columnAutoWidth={true}
                columnResizingMode="widget"
                onRowDblClick={onRowDblClick}
                focusedRowEnabled={true}
                onCellPrepared={onCellPrepared}
                onCellClick={onCellClick}
                onFocusedRowChanged={onFocusedRowChanged}
                onContentReady={(e) => setTotalCount(e.component.totalCount())}
              >
                <FilterRow visible={true} />
                <HeaderFilter visible={true} />
                <ColumnChooser enabled={true} />
                <StateStoring
                  enabled={true}
                  type="custom"
                  customLoad={useCallback(() => {
                    return JSON.parse(localStorage.getItem(storageKey));
                  }, [])}
                />
                <Scrolling
                  columnRenderingMode="virtual"
                  mode="virtual"
                  showScrollbar="always"
                  useNative="false"
                />
                <Column dataField="orderCode" caption="접수번호" />
                <Column
                  dataField="division"
                  caption="구분"
                  cellRender={divisionRender}
                  alignment="center"
                />
                <Column
                  dataField="orderStatus"
                  caption="상태"
                  cellRender={orderStatusRender}
                  alignment="center"
                />
                <Column
                  dataField="importExport"
                  caption="수입/수출"
                  cellRender={importExportRender}
                  alignment="center"
                />
                <Column
                  dataField="forwarder"
                  caption="선사"
                  alignment="center"
                />
                <Column dataField="cargoOwnerName" caption="화주" />
                <Column dataField="billingPlaceName" caption="청구처" />
                <Column dataField="blNo" caption="BL No" alignment="center" />
                <Column dataField="bkNo" caption="BK No" alignment="center" />
                <Column
                  dataField="containerNo"
                  caption="컨테이너 No"
                  alignment="center"
                />
                <Column
                  dataField="sealNo"
                  caption="Seal No"
                  alignment="center"
                />
                <Column
                  dataField="containerType"
                  caption="컨/규격"
                  alignment="center"
                />
                <Column
                  dataField="deliverDate"
                  caption="운송요청일자"
                  alignment="center"
                  dataType="date"
                  format="yyyy-MM-dd"
                />
                <Column
                  dataField="freight"
                  caption="청구금액"
                  alignment="right"
                  dataType="number"
                >
                  <Format type="fixedPoint" />
                </Column>
                <Column
                  dataField="extraCharge"
                  caption="할증금액"
                  alignment="right"
                  dataType="number"
                >
                  <Format type="fixedPoint" />
                </Column>
                <Column
                  dataField="totalPrice"
                  caption="총합계"
                  alignment="right"
                >
                  <Format type="fixedPoint" />
                </Column>
                <Column
                  dataField="habul"
                  caption="하불금액"
                  alignment="right"
                  dataType="number"
                >
                  <Format type="fixedPoint" />
                </Column>
                <Column
                  dataField="dispatchTeam"
                  caption="배차팀"
                  alignment="center"
                />
                <Column
                  dataField="loadPlace"
                  caption="상차지"
                  alignment="center"
                />
                {/* <Column dataField="workWaypoint" caption="작업경유지" /> */}
                <Column dataField="workPlace" caption="작업지" />
                {/* <Column dataField="collectWaypoint" caption="회수경유지" /> */}
                <Column
                  dataField="unloadPlace"
                  caption="하차지"
                  alignment="center"
                />
                <Column
                  dataField="workTime"
                  caption="작업지 도착시간"
                  alignment="center"
                  dataType="date"
                  format="yyyy-MM-dd HH:mm:ss"
                />
                <Column
                  dataField="dem"
                  caption="선사반출기한"
                  alignment="center"
                  dataType="date"
                  format="yyyy-MM-dd"
                />
                <Column
                  dataField="returnDate"
                  caption="반납일자"
                  alignment="center"
                  dataType="date"
                  format="yyyy-MM-dd"
                />
                <Column dataField="chassis" caption="샤시" />
                <Column
                  dataField="bobtail"
                  caption="밥테일"
                  dataType="boolean"
                  width="80"
                />
                <Column
                  dataField="fridge"
                  caption="냉동"
                  dataType="boolean"
                  width="70"
                />
                <Column
                  dataField="danger"
                  caption="위험물"
                  dataType="boolean"
                  width="80"
                />
                <Column
                  dataField="tank"
                  caption="탱크"
                  dataType="boolean"
                  width="70"
                />
                <Column
                  dataField="direct"
                  caption="직반입"
                  dataType="boolean"
                  width="80"
                />
                <Column
                  dataField="temperature"
                  caption="온도"
                  alignment="center"
                />
                <Column dataField="humidity" caption="습도" />
                <Column dataField="vent" caption="환풍구" alignment="center" />
                <Column dataField="unNo" caption="un번호" alignment="center" />
                <Column dataField="class" caption="클래스" alignment="center" />
                <Column
                  dataField="extraInfo"
                  caption="특이사항"
                  alignment="center"
                />
                <MasterDetail
                  enabled={true}
                  render={(e) => (
                    <DispatchDetail
                      data={e.data.id}
                      dataGridRef={dataGridRef}
                      setDetailRowData={setDetailRowData}
                      detailGridRef={detailGridRef}
                      onFocusedRowChanged={onFocusedRowChanged}
                      detail={e.data.detail}
                    />
                  )}
                />
              </DataGrid>
            </PanelGridInit>
          </PanelGridWrapper>
        </PanelBody>
      </PanelInit>
      {departureModal && (
        <Modals.DepartureModal
          onClose={closeModal}
          closable={true}
          visible={true}
          maskClosable={false}
          rowData={rowData}
          dataGridRef={dataGridRef}
        />
      )}
      {dispatchModal && (
        <Modals.DispatchModal
          onClose={closeModal}
          closable={true}
          visible={true}
          fileVisible={true}
          maskClosable={false}
          detailRowData={detailRowData}
          dataGridRef={dataGridRef}
          focusedComponent={focusedComponent}
        />
      )}
      {workWaypointModal && (
        <Modals.WorkWaypointModal
          onClose={closeModal}
          closable={true}
          visible={true}
          maskClosable={false}
          rowData={rowData}
          dataGridRef={dataGridRef}
        />
      )}
      {workPlaceModal && (
        <Modals.WorkPlaceModal
          onClose={closeModal}
          closable={true}
          visible={true}
          maskClosable={false}
          rowData={rowData}
          dataGridRef={dataGridRef}
        />
      )}
      {collectWaypointModal && (
        <Modals.CollectWaypointModal
          onClose={closeModal}
          closable={true}
          visible={true}
          maskClosable={false}
          rowData={rowData}
          dataGridRef={dataGridRef}
        />
      )}
      {unloadPlaceModal && (
        <Modals.UnloadPlaceModal
          onClose={closeModal}
          closable={true}
          visible={true}
          maskClosable={false}
          rowData={rowData}
          dataGridRef={dataGridRef}
        />
      )}

      {freightModal && (
        <Modals.FreightModal
          onClose={closeModal}
          closable={true}
          visible={true}
          maskClosable={false}
          rowData={rowData}
          dataGridRef={dataGridRef}
        />
      )}
      {chassisModal && (
        <Modals.ChassisModal
          onClose={closeModal}
          closable={true}
          visible={true}
          maskClosable={false}
          rowData={rowData}
          dataGridRef={dataGridRef}
        />
      )}
      {fridgeDangerModal && (
        <Modals.FridgeDangerModal
          onClose={closeModal}
          closable={true}
          visible={true}
          maskClosable={false}
          rowData={rowData}
          dataGridRef={dataGridRef}
        />
      )}
      {bobtailModal && (
        <Modals.BobtailModal
          onClose={closeModal}
          closable={true}
          visible={true}
          maskClosable={false}
          rowData={rowData}
          dataGridRef={dataGridRef}
        />
      )}
      {habulModal && (
        <Modals.HabulModal
          onClose={closeModal}
          closable={true}
          visible={true}
          maskClosable={false}
          detailRowData={detailRowData}
          dataGridRef={dataGridRef}
        />
      )}
      {extraChargeModal && (
        <Modals.ExtraChargeModal
          onClose={closeModal}
          closable={true}
          visible={true}
          maskClosable={false}
          rowData={rowData}
          dataGridRef={dataGridRef}
        />
      )}
      {wareHouseModal && (
        <Modals.WareHouseModal
          onClose={closeModal}
          closable={true}
          visible={true}
          maskClosable={false}
          rowData={rowData}
          dataGridRef={dataGridRef}
        />
      )}
      {arrivalModal && (
        <Modals.ArrivalModal
          onClose={closeModal}
          closable={true}
          visible={true}
          maskClosable={false}
          rowData={rowData}
          dataGridRef={dataGridRef}
        />
      )}
      {returnModal && (
        <Modals.ReturnModal
          onClose={closeModal}
          closable={true}
          visible={true}
          maskClosable={false}
          rowData={rowData}
          dataGridRef={dataGridRef}
        />
      )}
      {longtermModal && (
        <Modals.LongtermModal
          onClose={closeModal}
          closable={true}
          visible={true}
          maskClosable={false}
          rowData={rowData}
          dataGridRef={dataGridRef}
        />
      )}
      {containerNoModal && (
        <Modals.ContainerNoModal
          onClose={closeModal}
          closable={true}
          visible={true}
          maskClosable={false}
          rowData={rowData}
          dataGridRef={dataGridRef}
        />
      )}
      {warehouseTimeModal && (
        <Modals.WarehouseTimeModal
          onClose={closeModal}
          closable={true}
          visible={true}
          maskClosable={false}
          rowData={rowData}
          dataGridRef={dataGridRef}
        />
      )}
      {extraInfoModal && (
        <Modals.ExtraInfoModal
          onClose={closeModal}
          closable={true}
          visible={true}
          maskClosable={false}
          rowData={rowData}
          dataGridRef={dataGridRef}
        />
      )}
    </>
  );
}

export default Dispatch;
