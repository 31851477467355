import { DataGrid } from "devextreme-react";
import {
  Column,
  ColumnChooser,
  FilterRow,
  Format,
  HeaderFilter,
  Scrolling,
  StateStoring,
} from "devextreme-react/data-grid";
import CustomStore from "devextreme/data/custom_store";
import React, { useCallback, useMemo, useState, useEffect } from "react";
import { sendRequest } from "../../../../../_metronic/_helpers/DataSoureHelpers";
import {
  DataCount,
  PanelGridInit,
} from "../../../../components/Layout/Panel/PanelGrid/panelGrid.style";
import StateStoringSaveAndDeleteButton from "../../../../components/StateStoring";

const FinishManageLower = ({ search, finishLowerRef, selectedRow }) => {
  const storageKey = "finishManageLower";
  const [totalCount, setTotalCount] = useState(0);

  useEffect(() => {
    const instance = finishLowerRef.current.instance;
    instance.clearFilter();
    instance.clearSorting();
    // eslint-disable-next-line
  }, [selectedRow]);

  const dataSource = useMemo(() => {
    let apiUrl = `${process.env.REACT_APP_API_URL}`;
    if (selectedRow.division === "청구처")
      apiUrl += "/manage-all-finish/billing";
    else if (selectedRow.division === "용차사")
      apiUrl += "/manage-all-finish/yongcha";
    else if (selectedRow.division === "기사")
      apiUrl += "/manage-all-finish/driver";

    return new CustomStore({
      key: "id",
      load: () => {
        if (apiUrl === `${process.env.REACT_APP_API_URL}`) return [];
        return sendRequest(apiUrl, "GET", {
          ...search,
          id: selectedRow.id,
          receiveCo: selectedRow.receiveCoName,
          completeNo: selectedRow.completeNo,
          companyId: selectedRow.companyId,
          comOid: selectedRow.comOid,
          company: selectedRow.companyName,
          extraDivision: selectedRow.extraDivision,
          division: selectedRow.division,
        });
      },
    });
  }, [search, selectedRow]);

  return (
    <>
      <PanelGridInit
        // style={{ maxWidth: "70%" }}
        gridSub
        gridItems
        borderTop
        column
      >
        <StateStoringSaveAndDeleteButton
          dataGridRef={finishLowerRef}
          storageKey={storageKey}
        />
        <DataCount>{`총 ${totalCount}건`}</DataCount>
        <DataGrid
          className="datagrid-wrap multi-line"
          id="ClosingRight"
          dataSource={dataSource}
          ref={finishLowerRef}
          showRowLines={true}
          showBorders={true}
          hoverStateEnabled={true}
          allowColumnResizing={true}
          allowColumnReordering={true}
          columnResizingMode="widget"
          keyExpr="id"
          focusedRowEnabled={true}
          onContentReady={(e) => setTotalCount(e.component.totalCount())}
        >
          <FilterRow visible={true} />
          <ColumnChooser enabled={true} />
          <HeaderFilter visible={true} />
          <Scrolling
            columnRenderingMode="virtual"
            mode="virtual"
            showScrollbar="always"
            useNative="false"
          />
          <StateStoring
            enabled={true}
            type="custom"
            customLoad={useCallback(() => {
              return JSON.parse(localStorage.getItem(storageKey));
            }, [])}
          />
          {/* <Column dataField="" caption="No" /> */}
          <Column
            dataField="extraDivision"
            caption="구분"
            width="110"
            cellRender={(e) => {
              const { value } = e;
              if (value === "detail") return "오더";
              if (value === "dis") return "배차";
              if (value === "extra") return "할증";
              if (value === "etc") return "기타하불";
              return "";
            }}
          />
          <Column dataField="companyName" caption="접수법인" width="110" />
          {/* 사업자 번호 */}
          <Column dataField="bizNo" caption="사업자번호" width="134" />
          {/* 사업자 상호 */}
          <Column dataField="bizName" caption="사업자상호" width="134" />
          <Column dataField="code" caption="접수번호" width="110" />
          <Column dataField="receiveDate" caption="접수일자" width="110" />
          <Column dataField="deliverDate" caption="운송일자" width="110" />
          <Column dataField="completeNo" caption="마감번호" width="110" />
          <Column dataField="completeDate" caption="마감일자" width="110" />
          <Column dataField="closeStatus" caption="정산상태" width="110" />
          <Column
            dataField="price"
            caption="금액"
            dataType="number"
            width="110"
          >
            <Format type="fixedPoint" />
          </Column>
          <Column dataField="containerNo" caption="컨테이너No" width="110" />
          <Column dataField="containerType" caption="컨규격" width="110" />
          <Column dataField="remark" caption="비고" width="110" />
          <Column dataField="dispatchTeamName" caption="배차팀" width="110" />
          <Column dataField="billingPlaceName" caption="청구처" width="110" />
          <Column dataField="orderCompany" caption="발주처" width="110" />
          <Column dataField="forwarder" caption="선사" width="110" />
          <Column dataField="shipName" caption="선명" width="110" />
          <Column dataField="motherShip" caption="모선항차" width="110" />
          <Column dataField="cargoOwnerName" caption="화주" width="110" />
          <Column dataField="dem" caption="선사반출기한" width="110" />
          <Column dataField="enterDay" caption="입항일" width="110" />
          <Column dataField="sailDay" caption="출항일" width="110" />
          <Column dataField="putDirect" caption="접안일" width="110" />
          <Column dataField="loadPlace" caption="상차지" width="110" />
          <Column dataField="unloadPlace" caption="하차지" width="110" />
          <Column dataField="blNo" caption="BL NO" width="110" />
          <Column dataField="bkNo" caption="BK NO" width="110" />
          <Column dataField="doNo" caption="DO NO" width="110" />
        </DataGrid>
      </PanelGridInit>
    </>
  );
};

export default FinishManageLower;
