import React from "react";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";

const ModalHeader = ({ onClose, title }) => {
  return (
    <div className="modal-form-header">
      <div className="modal-form-tit">
        <span className="modal-tit">{title}</span>
      </div>
      <button
        type="button"
        className="btn-modal btn-modal-close"
        onClick={onClose}
      >
        <SVG
          src={toAbsoluteUrl("/media/svg/icons/Custom/close_black_24dp.svg")}
          className="close-icon"
        />
      </button>
    </div>
  );
};

export default ModalHeader;
