import React, { useEffect, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import * as requestServer from "../SimpleDispatchCrud";

import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";

import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";

import Guid from "devextreme/core/guid";
import { alert, confirm } from "devextreme/ui/dialog";
import { Modal } from "react-bootstrap";

import InputCheckBox from "../../../../components/InputForm/InputCheckBox";
import InputDate from "../../../../components/InputForm/InputDate";
import InputRadioGroup from "../../../../components/InputForm/InputRadioGroup";
import InputSelect from "../../../../components/InputForm/InputSelect";
import InputText from "../../../../components/InputForm/InputText";

import moment from "moment";
import InputTextArea from "../../../../components/InputForm/InputTextArea";
import CreateSimpleModalDetail from "./CreateSimpleModalDetail";
import * as Modals from "./SimpleDispatchModals";

// 냉동, 위험물
const FRIDGE = 4053;
const DANGER = 4054;
const TANK = 4055;

function CreateSimpleModal({
  onClose,
  maskClosable,
  closable,
  visible,
  rowData,
  dataGrid,
}) {
  const [isConfirm, setIsConfirm] = useState(false);
  const [otherDeliver, setOtherDeliver] = useState([]);

  const [lookupData, setLookupData] = useState({
    billing: [],
    cargoOwner: [],
    forwarder: [],
    terminal: [],
    company: [],
    isDispatch: [],
  });

  const [isExport, setIsExport] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [extraChargeModal, setExtraChargeModal] = useState(false);

  const { user } = useSelector(
    (state) => ({ user: state.auth.user.user }),
    shallowEqual
  );

  const initialState = {
    // master
    receiveDate: moment().format("YYYY-MM-DD"),
    deliverDate: moment().format("YYYY-MM-DD"),
    returnExpectDate: moment().format("YYYY-MM-DD"),
    returnDate: moment().format("YYYY-MM-DD"),
    freightDate: undefined,
    receiveCo: user.companyCode,
    dispatchTeam: user?.deptCode,
    blType: 1,
    fridge: false,
    danger: false,
    tank: false,
    direct: false,
    billingPlace: null,
    companyName: null,
    workPlace: null,
    forwarder: null,
    loadPlace: null,
    unloadPlace: null,

    // detail
    containerType: null,
    workWaypoint: null,
    collectWaypoint: null,
  };

  const schema = yup
    .object({
      receiveCo: yup.string().required("접수법인을 입력하세요"),
      receiveDate: yup.string().required("접수일자를 입력하세요"),
      importExport: yup.string(),
      dispatchTeam: yup.string().nullable(),
      billingPlace: yup
        .string()
        .nullable()
        .required("청구처를 입력하세요"),
      companyName: yup.string().nullable(),
      forwarder: yup.string().nullable(),
      shipName: yup.string().max(50, "최대 50자 까지 입력 가능"),
      motherShip: yup.string().max(50, "최대 50자 까지 입력 가능"),
      // bobtail: yup.bool(),
      fridge: yup.bool(),
      danger: yup.bool(),
      dem: yup.string().when("importExport", { is: "1", then: yup.string() }),
      blNo: yup
        .string()
        .when("importExport", {
          is: "1",
          then: yup.string().required("B/L 번호를 입력하세요"),
        })
        .max(50, "최대 50자 까지 입력 가능"),
      blYear: yup
        .string()
        .when("importExport", { is: "1", then: yup.string() }),
      blType: yup.string().nullable(),
      doNo: yup
        .string()
        .when("importExport", { is: "1", then: yup.string() })
        .max(50, "최대 50자 까지 입력 가능"),
      bkNo: yup.string().when("importExport", { is: "2", then: yup.string() }),
      loadPlace: yup
        .string()
        .nullable()
        .when("confirm", {
          is: true,
          then: yup
            .string()
            .nullable()
            .required("상차지를 선택해주세요"),
        }),
      unloadPlace: yup
        .string()
        .nullable()
        .when("confirm", {
          is: true,
          then: yup
            .string()
            .nullable()
            .required("하차지를 선택해주세요"),
        }),
      cargoOwner: yup.string().required("화주를 입력하세요"),
      otherDeliver: yup.string().nullable(), //다른회사로배차
      remark: yup.string().max(200, "200자 이내로 입력"),
      confirm: yup.bool(),

      // simple 디테일 데이터
      deliverDate: yup.string().required("운송일자를 입력하세요"),
      containerNo: yup.string().when("importExport", {
        is: "1",
        then: yup
          .string()
          .required("컨테이너번호를 입력하세요")
          .test(
            "containerNo",
            "컨테이너 번호가 올바르지 않습니다",
            validateContainerNo
          ),
      }),
      containerType: yup
        .string()
        .nullable()
        .when("importExport", {
          is: "1",
          then: yup.string().required("컨/규격을 입력하세요"),
        }),
      cargoWeight: yup.string(),
      workWaypoint: yup.string().nullable(),
      collectWaypoint: yup.string().nullable(),
      workPlace: yup
        .string()
        .nullable()
        .required("작업지를 입력하세요"),
      freight: yup.string().required("청구운임을 입력하세요"),
      extraCharge: yup.string(),
      extraChargeObj: yup.array(),
      // habul: yup.string().required("하불운임을 입력하세요"),
      returnExpectDate: yup.string(),
      returnDate: yup.string(),
      freightDate: yup.string().nullable(),
      bobtail: yup.bool(),
      temperature: yup.string(),
      vent: yup.string(),
      unNo: yup.string(),
      class: yup.string(),
      extraInfo: yup.string(),
    })
    .required();

  const {
    handleSubmit,
    watch,
    reset,
    trigger,
    setValue,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const datas = watch();
  const imEx = watch("importExport");

  const onMaskClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  useEffect(() => {
    if (rowData) {
      // 수정버튼 클릭시
      console.log(rowData);
      const master = rowData.data;

      for (const obj in master) {
        if (
          obj === "otherDeliver" ||
          obj === "dispatchTeam" ||
          obj === "forwarder" ||
          obj === "loadPlace" ||
          obj === "unloadPlace" ||
          obj === "workWaypoint" ||
          obj === "collectWaypoint" ||
          obj === "workPlace"
        ) {
          master[obj] = master[obj] ? master[obj] : null;
          continue;
        } else if (
          obj === "fridge" ||
          obj === "danger" ||
          obj === "tank" ||
          obj === "put" ||
          obj === "toxic" ||
          obj === "keep"
        ) {
          master[obj] = master[obj] || false;
          continue;
        }
        master[obj] = master[obj] || undefined;
      }

      reset({ ...initialState, ...master });
      setIsConfirm(rowData.data.confirm === 1);
    } else {
      // 새로운 오더

      reset(initialState);
    }

    getLookup();

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (imEx === 1 || !imEx) {
      // 수출 -> 수입
      setIsExport(false);
    } else if (imEx === 2) {
      // 수입 -> 수출
      setIsExport(true);
    }
  }, [imEx]);

  useEffect(() => {
    const other = lookupData.company.filter(
      (cur) => cur.id !== watch("receiveCo")
    );
    setOtherDeliver([{ id: undefined, companyName: undefined }, ...other]);

    if (!rowData || watch("receiveCo") === watch("otherDeliver"))
      setValue("otherDeliver", null);
    else setValue("otherDeliver", rowData.data.otherDeliver);
    // eslint-disable-next-line
  }, [watch("receiveCo"), lookupData.company]);

  const getUnipassInfo = async () => {
    const data = watch();
    if (!data.blNo) {
      alert("B/L No를 입력해 주세요.");
      return false;
    }

    if (!data.blType) {
      alert("B/L 구분을 입력해 주세요.");
      return false;
    }

    try {
      setIsLoading(true);
      const res = await requestServer.getUnipassInfo({
        blNo: data.blNo,
        blType: data.blType,
        blYear: data.blYear,
        loadPlace: data.loadPlace,
      });

      const { cntrs, vydf, shipNm } = res.data;
      setValue("motherShip", vydf);
      setValue("shipName", shipNm);

      if (cntrs.length === 0) {
        alert("조회된 정보가 없습니다.", "오류");
      }

      if (cntrs.length > 1) {
        alert("컨테이너가 1개 이상입니다.", "오류");
        setIsLoading(false);
        return;
      }

      const obj = cntrs[0];

      let ctnr = "";

      const filteredType = lookupData.containerType.filter(
        (cur) => cur.subCode === obj.cntrStszCd
      );

      ctnr = filteredType[0] ? filteredType[0].extra1 : "";

      // if (obj.cntrStszCd === "45GP") {
      //   ctnr = "40HC";
      // } else {
      //   ctnr = "20DRY";
      // }

      setValue("containerNo", obj.cntrNo);
      setValue("containerType", ctnr);
      setValue("cargoWeight", obj.weight);
    } catch (err) {
      alert(err);
    }
    setIsLoading(false);
  };

  const onSubmit = async (data) => {
    const master = {
      code: data.code || undefined,
      importExport: data.importExport || undefined,
      receiveCo: data.receiveCo || undefined,
      receiveDate: data.receiveDate || undefined,
      dispatchTeam: data.dispatchTeam || undefined,
      billingPlace: data.billingPlace || undefined,
      workPlace: data.workPlace || undefined,
      forwarder: data.forwarder || undefined,
      shipName: data.shipName || undefined,
      putDirect: data.putDirect || null,
      motherShip: data.motherShip || undefined,
      dem: data.dem || undefined,
      bkNo: data.bkNo || undefined,
      blNo: data.blNo || undefined,
      blType: data.blType || undefined,
      doNo: data.doNo || undefined,
      fridge: data.fridge || false,
      danger: data.danger || false,
      tank: data.tank || false,
      direct: data.direct || false,
      toxic: data.toxic || false,
      keep: data.keep || false,
      manager: data.manager || undefined,
      loadPlace: data.loadPlace || undefined,
      unloadPlace: data.unloadPlace || undefined,
      cargoOwner: data.cargoOwner || undefined,
      remark: data.remark || undefined,
      confirm: data.confirm || undefined,
      otherDeliver: data.otherDeliver || undefined,
    };

    const detail = {
      id: data.id || undefined,
      deliverDate: data.deliverDate || undefined,
      containerNo: data.containerNo || undefined,
      freightDate: data.freightDate || undefined,
      workPlace: data.workPlace || undefined,
      containerType: data.containerType || undefined,
      cargoWeight: data.cargoWeight || undefined,
      freight: data.freight || undefined,
      extraCharge: data.extraCharge || undefined,
      habul: data.habul || undefined,
      returnExpectDate: data.returnExpectDate || undefined,
      returnDate: data.returnDate || undefined,
      bobtail: data.bobtail || false,
      carNo: data.carNo || undefined,
      extraChargeObj: data.extraChargeObj || [],

      workWaypoint: data.workWaypoint || null,
      collectWaypoint: data.collectWaypoint || null,
      temperature: data.temperature || undefined,
      humidity: data.humidity || undefined,
      vent: data.vent || undefined,
      unNo: data.unNo || undefined,
      class: data.class || undefined,
      extraInfo: data.extraInfo || undefined,
    };

    if (master.importExport === "1" || master.importExport === 1) {
      master.bkNo = null;
    } else {
      master.dem = null;
      master.blNo = null;
      master.doNo = null;
    }
    master.division = "simple";

    setIsLoading(true);
    try {
      if (rowData) {
        if (data.confirm) {
          await requestServer.onClickConfirmUpdate({
            master: master,
            detail: detail,
          });
        } else {
          await requestServer.updateOrder({ master: master, detail: detail });
        }
      } else {
        await requestServer.createOrder({ master: master, detail: detail });
      }

      dataGrid.current.instance.refresh();
      onClose();
    } catch (err) {
      alert(err.response.data.message, "오류");
    } finally {
      setIsLoading(false);
    }
  };

  const onClickConfirm = async () => {
    setValue("confirm", true);
    const trig = await trigger();
    if (!trig) {
      setValue("confirm", false);
      return;
    }

    const result = await confirm("오더확정하시겠습니까?");
    if (!result) {
      setValue("confirm", false);
      return;
    }
    const data = watch();

    const master = {
      code: data.code || undefined,
      importExport: data.importExport || undefined,
      receiveCo: data.receiveCo || null,
      receiveDate: data.receiveDate || undefined,
      dispatchTeam: data.dispatchTeam || null,
      billingPlace: data.billingPlace || null,
      forwarder: data.forwarder || null,
      shipName: data.shipName || undefined,
      putDirect: data.putDirect || null,
      motherShip: data.motherShip || undefined,
      dem: data.dem || undefined,
      bkNo: data.bkNo || undefined,
      blNo: data.blNo || undefined,
      blType: data.blType || undefined,
      doNo: data.doNo || undefined,
      fridge: data.fridge || false,
      danger: data.danger || false,
      tank: data.tank || false,
      direct: data.direct || false,
      toxic: data.toxic || false,
      keep: data.keep || false,
      manager: data.manager || undefined,
      loadPlace: data.loadPlace || null,
      unloadPlace: data.unloadPlace || null,
      cargoOwner: data.cargoOwner || null,
      remark: data.remark || undefined,
      otherDeliver: data.otherDeliver || null,
      confirm: true,
    };

    const detail = {
      id: data.id || undefined,
      deliverDate: data.deliverDate || undefined,
      containerNo: data.containerNo || undefined,
      containerType: data.containerType || undefined,
      cargoWeight: data.cargoWeight || undefined,
      workWaypoint: data.workWaypoint || null,
      collectWaypoint: data.collectWaypoint || null,
      workPlace: data.workPlace || null,
      freight: data.freight || undefined,
      extraCharge: data.extraCharge || undefined,
      habul: data.habul || undefined,
      returnExpectDate: data.returnExpectDate || undefined,
      returnDate: data.returnDate || undefined,
      freightDate: data.freightDate || undefined,
      bobtail: data.bobtail || undefined,
      carNo: data.carNo || undefined,
      temperature: data.temperature || undefined,
      humidity: data.humidity || undefined,
      vent: data.vent || undefined,
      unNo: data.unNo || undefined,
      class: data.class || undefined,
      extraInfo: data.extraInfo || undefined,
      extraChargeObj: data.extraChargeObj || [],
    };

    if (master.importExport === "1" || master.importExport === 1) {
      master.bkNo = null;
    } else {
      master.dem = null;
      master.blNo = null;
      master.doNo = null;
    }
    master.division = "simple";

    setIsLoading(true);
    try {
      if (rowData) {
        await requestServer.onClickConfirmUpdate({
          master: master,
          detail: detail,
        });
      } else {
        await requestServer.onClickConfirmCreate({
          master: master,
          detail: detail,
        });
      }

      dataGrid.current.instance.refresh();
      onClose();
    } catch (err) {
      setValue("confirm", false);
      alert(err.response.data.message, "오류");
    } finally {
      setIsLoading(false);
    }
  };

  const fridgeCheckBoxValueChanged = (e) => {
    // 냉동 체크
    if (e.value) {
      if (!datas.freight) return;

      const newKey = new Guid();

      const extraCharge = datas.extraCharges;
      const extraChargeCheck = extraCharge?.filter(
        (cur) => cur.division === FRIDGE
      )[0];
      const extraChargeObj = datas.extraChargeObj || [];

      for (let i = 0; i < extraChargeObj.length; i++) {
        if (extraChargeObj[i].type !== "remove") continue;
        const key = extraChargeObj[i].key;

        const compare = extraCharge?.filter((cur) => cur.id === key)[0];
        if (compare.division === FRIDGE) {
          extraChargeObj.splice(i, 1);
          const data = { isCharged: true, isHabul: true, division: FRIDGE };
          data.chargedRate = 30;
          data.chargedAmount = Math.round(parseInt(datas.freight) * 0.3);
          extraChargeObj.push({ data: data, type: "update", key: key });
          break;
        }
      }

      if (extraChargeCheck) {
        setValue("extraChargeObj", extraChargeObj);
        return;
      }

      const data = { isCharged: true, isHabul: true, division: FRIDGE };
      data.chargedRate = 30;
      data.chargedAmount = Math.round(parseInt(datas.freight) * 0.3);
      extraChargeObj.push({
        data: data,
        type: "insert",
        key: newKey._value,
        index: 0,
        pageIndex: 0,
      });

      setValue("extraChargeObj", extraChargeObj);
    }
    // 냉동 체크해제
    else if (!e.value) {
      const extraCharge = datas.extraCharges;
      const extraChargeCheck = extraCharge?.filter(
        (cur) => cur.division === FRIDGE
      )[0];
      const extraChargeObj = datas.extraChargeObj || [];

      for (let i = 0; i < extraChargeObj.length; i++) {
        if (extraChargeObj[i].type === "remove") continue;
        if (extraChargeObj[i].data.division === FRIDGE) {
          extraChargeObj.splice(i, 1);
          break;
        }
      }

      if (!extraChargeCheck) {
        setValue("extraChargeObj", extraChargeObj);
        return;
      }
      for (let i = 0; i < extraChargeObj.length; i++) {
        if (extraChargeObj[i].type === "remove") continue;
        if (extraChargeObj[i].key === extraChargeCheck.id) {
          extraChargeObj.splice(i, 1);
          break;
        }
      }
      extraChargeObj.push({ key: extraChargeCheck.id, type: "remove" });
      setValue("extraChargeObj", extraChargeObj);
    }
  };

  const dangerCheckBoxValueChanged = (e) => {
    // 위험물 체크
    if (e.value) {
      if (!datas.freight) return;

      const newKey = new Guid();

      const extraCharge = datas.extraCharges;
      const extraChargeCheck = extraCharge?.filter(
        (cur) => cur.division === DANGER
      )[0];
      const extraChargeObj = datas.extraChargeObj || [];

      for (let i = 0; i < extraChargeObj.length; i++) {
        if (extraChargeObj[i].type !== "remove") continue;
        const key = extraChargeObj[i].key;

        const compare = extraCharge?.filter((cur) => cur.id === key)[0];
        if (compare.division === DANGER) {
          extraChargeObj.splice(i, 1);
          const data = { isCharged: true, isHabul: true, division: DANGER };
          data.chargedRate = 30;
          data.chargedAmount = Math.round(parseInt(datas.freight) * 0.3);
          extraChargeObj.push({ data: data, type: "update", key: key });
          break;
        }
      }

      if (extraChargeCheck) {
        setValue("extraChargeObj", extraChargeObj);
        return;
      }

      const data = { isCharged: true, isHabul: true, division: DANGER };
      data.chargedRate = 30;
      data.chargedAmount = Math.round(parseInt(datas.freight) * 0.3);
      extraChargeObj.push({
        data: data,
        type: "insert",
        key: newKey._value,
        index: 0,
        pageIndex: 0,
      });

      setValue("extraChargeObj", extraChargeObj);
    }
    // 위험물 체크해제
    else if (!e.value) {
      const extraCharge = datas.extraCharges;
      const extraChargeCheck = extraCharge?.filter(
        (cur) => cur.division === DANGER
      )[0];
      const extraChargeObj = datas.extraChargeObj || [];

      for (let i = 0; i < extraChargeObj.length; i++) {
        if (extraChargeObj[i].type === "remove") continue;
        if (extraChargeObj[i].data.division === DANGER) {
          extraChargeObj.splice(i, 1);
          break;
        }
      }

      if (!extraChargeCheck) {
        setValue("extraChargeObj", extraChargeObj);
        return;
      }
      for (let i = 0; i < extraChargeObj.length; i++) {
        if (extraChargeObj[i].type === "remove") continue;
        if (extraChargeObj[i].key === extraChargeCheck.id) {
          extraChargeObj.splice(i, 1);
          break;
        }
      }
      extraChargeObj.push({ key: extraChargeCheck.id, type: "remove" });
      setValue("extraChargeObj", extraChargeObj);
    }
  };

  const tankCheckBoxValueChanged = (e) => {
    // 탱크 체크
    if (e.value) {
      if (!datas.freight) return;

      const newKey = new Guid();

      const extraCharge = datas.extraCharges;
      const extraChargeCheck = extraCharge?.filter(
        (cur) => cur.division === TANK
      )[0];
      const extraChargeObj = datas.extraChargeObj || [];

      for (let i = 0; i < extraChargeObj.length; i++) {
        if (extraChargeObj[i].type !== "remove") continue;
        const key = extraChargeObj[i].key;

        const compare = extraCharge?.filter((cur) => cur.id === key)[0];
        if (compare.division === TANK) {
          extraChargeObj.splice(i, 1);
          const data = { isCharged: true, isHabul: true, division: TANK };
          data.chargedRate = 30;
          data.chargedAmount = Math.round(parseInt(datas.freight) * 0.3);
          extraChargeObj.push({ data: data, type: "update", key: key });
          break;
        }
      }

      if (extraChargeCheck) {
        setValue("extraChargeObj", extraChargeObj);
        return;
      }

      const data = { isCharged: true, isHabul: true, division: TANK };
      data.chargedRate = 30;
      data.chargedAmount = Math.round(parseInt(datas.freight) * 0.3);
      extraChargeObj.push({
        data: data,
        type: "insert",
        key: newKey._value,
        index: 0,
        pageIndex: 0,
      });

      setValue("extraChargeObj", extraChargeObj);
    }
    // 탱크 체크해제
    else if (!e.value) {
      const extraCharge = datas.extraCharges;
      const extraChargeCheck = extraCharge?.filter(
        (cur) => cur.division === TANK
      )[0];
      const extraChargeObj = datas.extraChargeObj || [];

      for (let i = 0; i < extraChargeObj.length; i++) {
        if (extraChargeObj[i].type === "remove") continue;
        if (extraChargeObj[i].data.division === TANK) {
          extraChargeObj.splice(i, 1);
          break;
        }
      }

      if (!extraChargeCheck) {
        setValue("extraChargeObj", extraChargeObj);
        return;
      }
      for (let i = 0; i < extraChargeObj.length; i++) {
        if (extraChargeObj[i].type === "remove") continue;
        if (extraChargeObj[i].key === extraChargeCheck.id) {
          extraChargeObj.splice(i, 1);
          break;
        }
      }
      extraChargeObj.push({ key: extraChargeCheck.id, type: "remove" });
      setValue("extraChargeObj", extraChargeObj);
    }
  };

  const onValueChanged = async () => {
    const workPlace = watch("workPlace");
    const receiveDate = watch("receiveDate");
    const loadPlace = watch("loadPlace");
    const unloadPlace = watch("unloadPlace");
    const containerType = watch("containerType");

    if (!(workPlace && receiveDate && loadPlace && containerType)) return;

    try {
      const result = await requestServer.getDistancePrice({
        workPlace,
        loadPlace,
        unloadPlace,
        receiveDate,
        containerType,
      });

      const { freight, habul } = result.data;

      setValue("freight", freight);
      setValue("habul", habul);
    } catch (err) {
      console.log(err);
    }
  };

  const onError = (err) => {
    console.log(err);
  };

  const closeModal = () => {
    setExtraChargeModal(false);
  };

  // const onLoadPlaceValueChange = (e) => {
  //   setValue("unloadPlace", e.value);
  // };

  //  Lookup 불러오기
  async function getLookup() {
    try {
      const [
        billingplaceData,
        cargoownerData,
        forwarderData,
        terminalData,
        companyData,
        isDispatchData,
        containerType,
      ] = await Promise.all([
        requestServer.getLookup("companyManage/lookup/isBilling"),
        requestServer.getLookup("companyManage/lookup/isCargoOwner"),
        requestServer.getLookup("forwarder/lookup"),
        requestServer.getLookup("terminal/terminal"),
        requestServer.getLookup("company"),
        requestServer.getLookup("department/isDispatch"),
        requestServer.getLookup("comcode/lookup/10400"),
      ]);

      setLookupData({
        billing: billingplaceData.data,
        cargoOwner: cargoownerData.data,
        forwarder: forwarderData.data,
        terminal: terminalData.data,
        company: companyData.data,
        isDispatch: isDispatchData.data,
        containerType: containerType.data,
      });
    } catch (err) {
      console.log(err);
    }
  }

  // containerNo Validation

  function validateContainerNo(conNo) {
    if (conNo.length !== 11) return false;
    const conRegex = /^[A-Z]{4}\d{7}$/;
    const result = conRegex.test(conNo);
    if (!result) return false;

    let sum = calculateCheckDigit(conNo);

    let mod = sum % 11;
    if (mod === 10) mod = 0;

    if (parseInt(conNo[conNo.length - 1]) !== mod) return false; // CheckDigit이 다르면 return

    return true;
  }

  function calculateCheckDigit(value) {
    let sum = 0;

    for (let i = 0; i < 10; i++) {
      if (value[i].charCodeAt(0) === 65) {
        // A일때
        let num = value[i].charCodeAt(0) - 55;

        sum += parseInt(num) * Math.pow(2, i);
      } else if (value[i].charCodeAt(0) > 65 && value[i].charCodeAt(0) <= 75) {
        // B-K일때
        let num = value[i].charCodeAt(0) - 54;

        sum += parseInt(num) * Math.pow(2, i);
      } else if (value[i].charCodeAt(0) > 75 && value[i].charCodeAt(0) <= 85) {
        // L-U일떄
        let num = value[i].charCodeAt(0) - 53;

        sum += parseInt(num) * Math.pow(2, i);
      } else if (value[i].charCodeAt(0) > 85 && value[i].charCodeAt(0) <= 90) {
        // V-Z일때
        let num = value[i].charCodeAt(0) - 52;

        sum += parseInt(num) * Math.pow(2, i);
      } else {
        // 숫자일때
        sum += parseInt(value[i]) * Math.pow(2, i);
      }
    }

    return sum;
  }

  return (
    <>
      <div className={"modal-overlay " + (visible ? "active" : "")} />
      <div
        className={"modal-wrap user-list-modal " + (visible ? "active" : "")}
        onClick={maskClosable ? onMaskClick : null}
        tabIndex="-1"
        visible={`${visible}`}
      >
        <Modal.Dialog centered tabIndex="0" size="xl" animation="true">
          <Modal.Body>
            <form
              onSubmit={handleSubmit(onSubmit, onError)}
              className="modal-form"
            >
              <div className="modal-form-header">
                <div className="modal-form-tit">
                  <span className="modal-tit">간편 배차</span>
                  <div className="dx-field">
                    <InputSelect
                      control={control}
                      errors={errors}
                      name="importExport"
                      displayExpr="name"
                      valueExpr="id"
                      disable={isConfirm}
                      dataSource={[
                        { id: 1, name: "수입" },
                        { id: 2, name: "수출" },
                      ]}
                      flex={true}
                    />
                  </div>
                </div>
                <div className="d-flex flex-row">
                  <div className="dx-field receive-co">
                    <InputSelect
                      searchEnabled={true}
                      control={control}
                      errors={errors}
                      name="receiveCo"
                      label="접수법인"
                      flex={true}
                      dataSource={lookupData.company}
                      valueExpr="id"
                      displayExpr="companyName"
                    />
                  </div>
                  <button
                    type="button"
                    className="btn-modal btn-modal-close"
                    onClick={onClose}
                  >
                    <SVG
                      src={toAbsoluteUrl(
                        "/media/svg/icons/Custom/close_black_24dp.svg"
                      )}
                      className="close-icon"
                    />
                  </button>
                </div>
              </div>
              <div className="input-wrap">
                <div className="input-grid">
                  <div className="dx-field">
                    <InputDate
                      control={control}
                      errors={errors}
                      name="receiveDate"
                      label="접수일자"
                      flex={true}
                      onInitialized={(e) => {
                        setTimeout(() => {
                          e.component.focus();
                        }, 0);
                      }}
                      onValueChanged={onValueChanged}
                    />
                  </div>
                  <div className="dx-field">
                    {/* lookup으로 바꾸기 */}
                    <InputSelect
                      searchEnabled={true}
                      control={control}
                      errors={errors}
                      dataSource={lookupData.isDispatch}
                      displayExpr="deptName"
                      valueExpr="code"
                      name="dispatchTeam"
                      label="배차팀"
                      flex={true}
                    />
                  </div>
                  <div className="dx-field">
                    <InputSelect
                      searchEnabled={true}
                      control={control}
                      errors={errors}
                      name="billingPlace"
                      label="청구처"
                      flex={true}
                      dataSource={lookupData.billing}
                      displayExpr="company"
                      valueExpr="oid"
                    />
                  </div>
                </div>
                <div className="input-grid">
                  <div className="dx-field">
                    <InputSelect
                      searchEnabled={true}
                      control={control}
                      errors={errors}
                      name="forwarder"
                      label="선사"
                      flex={true}
                      dataSource={lookupData.forwarder}
                      valueExpr="code"
                      displayExpr="forwarder_Name"
                    />
                  </div>
                  <div className="dx-field">
                    <InputText
                      control={control}
                      errors={errors}
                      name="shipName"
                      label="선명"
                      flex={true}
                    />
                  </div>
                  <div className="dx-field">
                    <InputText
                      control={control}
                      errors={errors}
                      name="motherShip"
                      label="모선/항차"
                      flex={true}
                    />
                  </div>
                </div>
                {imEx === "2" && (
                  <div className="input-grid">
                    <div className="dx-field">
                      <InputDate
                        control={control}
                        errors={errors}
                        label="출항일자"
                        flex={true}
                        disable={true}
                      />
                    </div>
                    <div className="dx-field">
                      <InputDate
                        control={control}
                        errors={errors}
                        label="입항일자"
                        flex={true}
                        disable={true}
                      />
                    </div>
                    {/* 출항일자, 입항일자는 선석스케줄에서 가져오기 */}
                    <div className="dx-field">
                      <InputText
                        control={control}
                        errors={errors}
                        name="bkNo"
                        label="B/K No"
                        flex={true}
                      />
                    </div>
                  </div>
                )}
                {!isExport && (
                  <div className="input-grid">
                    <div className="dx-field">
                      <InputText
                        control={control}
                        errors={errors}
                        name="doNo"
                        label="DO No"
                        flex={true}
                      />
                    </div>
                    <div className="dx-field">
                      <InputText
                        control={control}
                        errors={errors}
                        name="blNo"
                        label="B/L No"
                        flex={true}
                      />
                    </div>
                    <div className="dx-field">
                      <InputRadioGroup
                        control={control}
                        errors={errors}
                        name="blType"
                        dataSource={[
                          { id: 1, name: "M" },
                          { id: 2, name: "H" },
                        ]}
                        label="B/L 구분"
                        flex={true}
                      />
                    </div>
                    <div className={`dx-field button-line`}>
                      <button
                        type="button"
                        className={`btn btn-connect  ${isLoading &&
                          "spinner spinner-white spinner-right"}`}
                        onClick={getUnipassInfo}
                        disabled={isLoading || isConfirm}
                      >
                        UNI-PASS 연동
                      </button>
                    </div>
                  </div>
                )}

                <div className="input-grid grid-4">
                  <div className="dx-field">
                    <InputDate
                      control={control}
                      errors={errors}
                      name="putDirect"
                      label="접안일"
                    />
                  </div>
                  <div className="dx-field">
                    <InputSelect
                      searchEnabled={true}
                      control={control}
                      errors={errors}
                      name="loadPlace"
                      label="상차지"
                      flex={true}
                      dataSource={lookupData.terminal}
                      valueExpr="code"
                      displayExpr="code"
                      disable={isConfirm}
                      onValueChanged={(e) => {
                        onValueChanged();
                        // onLoadPlaceValueChange(e);
                      }}
                    />
                  </div>
                  <div className="dx-field">
                    <InputSelect
                      searchEnabled={true}
                      control={control}
                      errors={errors}
                      name="unloadPlace"
                      label="하차지"
                      flex={true}
                      dataSource={lookupData.terminal}
                      valueExpr="code"
                      displayExpr="code"
                      disable={isConfirm}
                      onValueChange={() => {
                        onValueChanged();
                      }}
                    />
                  </div>
                </div>
                <div className="input-grid">
                  <div className="dx-field">
                    <InputSelect
                      searchEnabled={true}
                      control={control}
                      errors={errors}
                      name="cargoOwner"
                      label="화주"
                      flex={true}
                      dataSource={lookupData.cargoOwner}
                      displayExpr="company"
                      valueExpr="oid"
                    />
                  </div>
                  <div className="dx-field ">
                    <InputSelect
                      control={control}
                      errors={errors}
                      dataSource={otherDeliver}
                      name="otherDeliver"
                      label="타법인 배차"
                      valueExpr="id"
                      displayExpr="companyName"
                    />
                  </div>
                </div>
                <div className="input-grid grid-last">
                  <div className="dx-field checkbox-group">
                    <InputCheckBox
                      control={control}
                      errors={errors}
                      name="fridge"
                      text="냉동"
                      flex={true}
                      disabled={isConfirm}
                      onValueChanged={fridgeCheckBoxValueChanged}
                    />
                    <InputCheckBox
                      control={control}
                      errors={errors}
                      name="danger"
                      text="위험물"
                      flex={true}
                      disabled={isConfirm}
                      onValueChanged={dangerCheckBoxValueChanged}
                    />
                    <InputCheckBox
                      control={control}
                      errors={errors}
                      name="tank"
                      text="탱크"
                      flex={true}
                      disabled={isConfirm}
                      onValueChanged={tankCheckBoxValueChanged}
                    />
                    <InputCheckBox
                      control={control}
                      errors={errors}
                      name="direct"
                      text="직반입"
                      flex={true}
                      disabled={isConfirm}
                    />

                    <InputCheckBox
                      control={control}
                      errors={errors}
                      name="toxic"
                      text="유해화학물질"
                      flex={true}
                      disabled={isConfirm}
                    />
                    <InputCheckBox
                      control={control}
                      errors={errors}
                      name="keep"
                      text="보관"
                      flex={true}
                      disabled={isConfirm}
                    />
                  </div>
                </div>
                <div className="input-grid grid-1">
                  <div className="dx-field">
                    <InputTextArea
                      control={control}
                      errors={errors}
                      name="remark"
                      label="비고"
                      flex={true}
                    />
                  </div>
                </div>
              </div>

              <CreateSimpleModalDetail
                isConfirm={isConfirm}
                control={control}
                errors={errors}
                rowData={rowData}
                setValue={setValue}
                watch={watch}
                setExtraChargeModal={setExtraChargeModal}
                onValueChanged={onValueChanged}
              />
              <div className="modal-form-footer order-modal">
                <div>
                  <button
                    type="submit"
                    className={`btn btn-modal btn-save  ${isLoading &&
                      "spinner spinner-white spinner-right"}`}
                    disabled={isLoading}
                  >
                    저장
                  </button>
                  {!isConfirm && (
                    <button
                      type="button"
                      onClick={onClickConfirm}
                      className={`btn btn-modal btn-save  ${isLoading &&
                        "spinner spinner-white spinner-right"}`}
                      disabled={isLoading}
                    >
                      오더확정
                    </button>
                  )}
                </div>
              </div>
            </form>
          </Modal.Body>
        </Modal.Dialog>
      </div>
      {extraChargeModal && (
        <Modals.ExtraChargeModal
          onClose={closeModal}
          closable={true}
          visible={true}
          maskClosable={false}
          rowData={rowData}
          setValue={setValue}
          datas={datas}
        />
      )}
    </>
  );
}

export default CreateSimpleModal;
