import axios from "axios";

const apiUrl = `${process.env.REACT_APP_API_URL}/order`;
const apiSimple = `${process.env.REACT_APP_API_URL}/simpledispatch`;
const apiUrlLookup = `${process.env.REACT_APP_API_URL}`;

export function getLookup(param) {
  return axios.get(apiUrlLookup + `/${param}`);
}

export function getUnipassInfo(data) {
  return axios.post(`${apiUrl}/unipass`, data);
}

export function createOrder(data) {
  return axios.post(apiSimple, data);
}

export function updateOrder(data) {
  return axios.put(apiSimple, data);
}

export function onClickConfirmCreate(data) {
  return axios.post(apiSimple + `/confirmcreate`, data);
}

export function onClickConfirmUpdate(data) {
  return axios.put(apiSimple + `/confirmupdate`, data);
}

export function getDistancePrice(data) {
  return axios.post(apiUrl + `/distanceprice`, data);
}

export function updateExtraCharge(data) {
  return axios.post(apiSimple + `/extracharge`, data);
}

export function changeCarNo(key, data) {
  return axios.put(apiUrlLookup + `/dispatch/carno`, { key, data });
}

export function confirmDispatch(data) {
  return axios.put(apiUrlLookup + `/dispatch/finish`, { data });
}

export function deliverFinish(data) {
  return axios.put(apiUrlLookup + `/dispatch/deliver`, { data });
}

export function cancelDispatch(data) {
  return axios.put(apiUrlLookup + `/dispatch/cancel`, { data });
}

export function setHabul(data) {
  return axios.put(apiUrlLookup + `/dispatch/habul`, { data });
}
