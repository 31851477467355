import { yupResolver } from "@hookform/resolvers/yup";
import moment from "moment";
import { useForm } from "react-hook-form";
import { shallowEqual, useSelector } from "react-redux";
import * as yup from "yup";
import { validateContainerNo } from "../utils/shuttleFunctions";

export const useShuttleForm = () => {
  const { user } = useSelector(
    (state) => ({ user: state.auth.user.user }),
    shallowEqual
  );

  const initialState = {
    code: "",
    receiveDate: moment().format("YYYY-MM-DD"),
    receiveCo: user.companyCode,
    deliverDate: moment().format("YYYY-MM-DD"),
    returnDate: undefined,
    cargoOwner: undefined,
    forwarder: null,
    realCargoOwner: undefined,
    importExport: 1,
    billingPlace: undefined,
    containerType: undefined,
    containerNo: "",
    loadPlace: undefined,
    toggle1: 1,
    carNo_01: null,
    yongchasa_01: undefined,
    yongchaCarNo_01: undefined,
    workPlace: null,
    workPlaceArrive: undefined,
    toggle2: 1,
    carNo_02: null,
    yongchasa_02: undefined,
    yongchaCarNo_02: undefined,
    unloadPlace: undefined,
    color: null,
    freight: 0,
    habul_01: 0,
    habul_02: 0,
    blNo: "",
    bkNo: "",
    doNo: "",
    manager: undefined,
    motherShip: undefined,
    putDirect: undefined,
    putTime: undefined,
    sailDay: undefined,
    sailTime: undefined,
    extraInfo: undefined,
    remark: undefined,
  };

  const schema = yup
    .object({
      receiveDate: yup
        .string()
        .nullable()
        .required("접수일자를 선택해주세요"),
      receiveCo: yup
        .string()
        .nullable()
        .required("접수법인을 선택해주세요"),
      deliverDate: yup.string().nullable(),
      returnDate: yup.string().nullable(),
      cargoOwner: yup
        .string()
        .nullable()
        .required("화주를 선택해주세요"),
      forwarder: yup.string().nullable(),
      realCargoOwner: yup.string().nullable(),
      importExport: yup
        .string()
        .nullable()
        .required("수출입 구분을 선택해주세요"),
      billingPlace: yup
        .string()
        .nullable()
        .required("청구처를 선택해주세요"),
      containerType: yup.string().nullable(),
      // .required("컨규격을 선택해주세요"),
      containerNo: yup
        .string()
        .nullable()
        .test(
          "containerNo",
          "컨테이너 번호가 올바르지 않습니다",
          validateContainerNo
        ),
      loadPlace: yup
        .string()
        .nullable()
        .required("상차지를 선택해주세요"),
      carNo_01: yup.string().nullable(),
      yongchasa_01: yup.string().nullable(),
      yongchaCarNo_01: yup.string().nullable(),
      workPlace: yup.string().nullable(),
      workPlaceArrive: yup.string().nullable(),
      carNo_02: yup.string().nullable(),
      yongchasa_02: yup.string().nullable(),
      yongchaCarNo_02: yup.string().nullable(),
      unloadPlace: yup
        .string()
        .nullable()
        .required("하차지를 선택해주세요"),
      color: yup.string().nullable(),
      freight: yup
        .string()
        .nullable()
        .required("청구운임을 선택해주세요"),
      habul_01: yup.string().nullable(),
      habul_02: yup.string().nullable(),
      blNo: yup.string().nullable(),
      bkNo: yup.string().nullable(),
      doNo: yup.string().nullable(),
      manager: yup.string().nullable(),
      motherShip: yup.string().nullable(),
      putDirect: yup.string().nullable(),
      putTime: yup.string().nullable(),
      sailDay: yup.string().nullable(),
      sailTime: yup.string().nullable(),
      extraInfo: yup.string().nullable(),
      remark: yup.string().nullable(),
    })
    .required();

  const form = useForm({
    resolver: yupResolver(schema),
  });

  return [form, () => form.reset(initialState)];
};
