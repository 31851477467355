import styled, { css } from "styled-components";

export const PanelGridWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  position: ${(props) => (props.position ? props.position : "")};
`;

export const PanelGridTopBar = styled.div`
  display: grid;
  grid-template-columns: ${(props) => (props.noBtn ? "100%" : "36px auto")};
`;

export const PanelGridBtnBar = styled.div`
  position: relative;
  display: flex;
  border-right: 1px solid ${(props) => props.theme.colors.layoutBorder};
  border-bottom: 1px solid ${(props) => props.theme.colors.layoutBorder};
  align-items: center;
  justify-content: center;

  button {
    &::after {
      position: absolute;
      content: "입력열기";
      display: block;
      padding: 4px 6px;
      left: 0;
      bottom: -20px;
      font-size: 1rem;
      text-align: center;
      word-break: keep-all;
      background: ${(props) => props.theme.colors.white};
      border: 1px solid ${(props) => props.theme.colors.layoutBorder};
      box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1);
      opacity: 0;
      transition: all 0.2s ease-in-out;
    }

    &:hover {
      &::after {
        opacity: 1;
      }
    }
  }
`;

export const PanelGridSearchBar = styled.form`
  display: flex;
  padding: 5px 10px;
  border-bottom: 1px solid ${(props) => props.theme.colors.layoutBorder};
  background-color: ${(props) => props.theme.colors.searchBarBg};
  align-items: center;
  flex-wrap: wrap;
  gap: 5px 26px;
`;

export const SearchItems = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;

  label {
    min-width: 40px;
    font-size: 1.1rem;
  }

  input {
    padding: 0 5px;
  }
`;

export const PanelMultiGrid = styled.div`
  overflow: auto;
  display: grid;
  padding: ${(props) => (props.row ? "0 10px" : "0")};
  grid-template-columns: ${(props) =>
    props.openInputBar === false
      ? "0 100%"
      : props.row
      ? props.left + ` ` + props.right
      : "1fr"};
  grid-template-rows: ${(props) =>
    props.openInputBar === false
      ? "100% 0"
      : props.column
      ? props.top + ` ` + props.bottom
      : "100%"};
  flex-grow: 1;
  grid-gap: ${(props) => (props.column ? "0" : "10px")};
`;

export const PanelMultiGridTit = styled.div`
  display: flex;
  height: 26px;
  margin-bottom: 5px;
  font-size: 1.4rem;
  font-weight: 600;
  align-items: center;
  justify-content: space-between;
`;

export const PanelGridInit = styled.div`
  overflow: hidden;
  position: relative;
  display: flex;
  height: 100%;
  padding: ${(props) =>
    props.gridItems && props.row ? "10px 0px 10px" : "10px"};
  border-top: ${(props) =>
    props.borderTop ? "1px solid " + props.theme.colors.gridBorder : "none"};
  background: ${(props) =>
    props.borderTop ? props.theme.colors.panelInputBg : "none"};
  flex-grow: 1;
  flex-direction: column;
  gap: ${(props) => (props.tabItems ? "0" : "10px")};

  .datagrid-wrap {
    position: relative;
    height: ${(props) =>
      props.gridHeight ? "calc(" + props.gridHeight + "% - 10px)" : "100%"};

    ${(props) =>
      props.gridItems &&
      css`
        max-height: ${(props) =>
          props.itemTop ? "calc(100% - 17px)" : "unset"};
      `}

    .dx-treelist-container,
    .dx-datagrid {
      background-color: transparent;

      .dx-datagrid-header-panel,
      .dx-treelist-header-panel {
        .dx-toolbar {
          background-color: transparent;

          .dx-toolbar-items-container {
            .dx-toolbar-after {
              padding-left: 0;
              right: unset;
            }
          }
        }
      }

      .dx-datagrid-headers,
      .dx-treelist-headers {
        color: ${(props) => props.theme.colors.white};
        background: ${(props) => props.theme.colors.gridScrollBg};

        .dx-datagrid-content,
        .dx-header-row {
          .dx-datagrid-filter-row {
            background: ${(props) => props.theme.colors.white};
          }

          td {
            background: ${(props) =>
              props.gridSub
                ? props.theme.colors.gridDetailHeader
                : props.theme.colors.gridHeader};
            color: ${(props) => props.theme.colors.white};
            font-size: 1.1rem;
            text-align: center !important;
            border-bottom: none;

            .dx-texteditor-input,
            .dx-dropdowneditor-button,
            .dx-datebox {
              border-radius: 0;
            }
          }
        }
      }

      .dx-scrollable-content {
        background-color: ${(props) => props.theme.colors.gridBorder};

        .dx-data-row {
          background-color: ${(props) => props.theme.colors.white};
        }

        .dx-master-detail-row {
          & > td {
            padding: 10px;

            .dx-datagrid-headers {
              .dx-datagrid-content {
                background: ${(props) => props.theme.colors.gridDetailHeader};
              }
            }

            .dx-datagrid-rowsview {
              background: ${(props) => props.theme.colors.white};
            }
          }

          .dx-cell-focus-disabled.dx-master-detail-cell {
            background-color: ${(props) => props.theme.colors.gridBorder};
          }
        }
      }

      .dx-datagrid-rowsview,
      .dx-treelist-rowsview {
        tr {
          td {
            border-top: none;
            border-color: ${(props) => props.theme.colors.gridBorder};
          }
        }
        .dx-freespace-row {
          background-color: ${(props) => props.theme.colors.gridBorder};
        }

        .dx-master-detail-row {
          .dx-master-detail-cell {
            .dx-header-row {
              background-color: ${(props) =>
                props.theme.colors.gridDetailHeader};
            }
          }
        }
      }

      .dx-row-focused {
        &.dx-data-row {
          td {
            background: ${(props) => props.theme.colors.gridHlt};
            color: ${(props) => props.theme.colors.white};

            .dx-datagrid-group-closed,
            .dx-datagrid-group-opened {
              color: ${(props) => props.theme.colors.white};
            }
          }
        }
      }

      .dx-master-detail-cell {
        .dx-row-focused {
          &.dx-data-row {
            td {
              background: ${(props) => props.theme.colors.gridHlt};
              color: ${(props) => props.theme.colors.white};

              .dx-datagrid-group-closed,
              .dx-datagrid-group-opened {
                color: ${(props) => props.theme.colors.white};
              }
            }
          }
        }
      }
    }
  }
`;

export const PanelGridBox = styled.div`
  display: flex;
  height: ${(props) =>
    props.gridHeight ? "calc(" + props.gridHeight + "% - 10px)" : "100%"};
  flex-grow: 1;
  gap: 10px;
`;

export const PanelGridInitLeft = styled.div`
  position: relative;
  width: ${(props) =>
    props.gridOpen ? "calc(100% - 430px)" : "calc(100% - 52px)"};
`;

export const PanelGridInitRight = styled.div`
  position: relative;
  display: grid;
  width: ${(props) => (props.gridOpen ? "420px" : "42px")};
  padding: 10px;
  background: ${(props) => props.theme.colors.asideDept02Bg};
  border: 1px solid ${(props) => props.theme.colors.layoutBorder};
  grid-template-columns: ${(props) => (props.gridOpen ? "1fr 20px" : "0 20px")};
  gap: ${(props) => (props.gridOpen ? "10px" : "0")};

  div {
    visibility: ${(props) => (props.gridOpen ? "visible" : "hidden")};
  }

  .datagrid-wrap {
    overflow: auto;
    width: 100%;

    .dx-toolbar .dx-toolbar-menu-container {
      padding: 0;

      .dx-button {
        margin-left: 0;
      }
    }

    .dx-treelist-container,
    .dx-datagrid {
      .dx-datagrid-headers,
      .dx-treelist-headers {
        .dx-datagrid-content,
        .dx-header-row {
          td {
            background: ${(props) => props.theme.colors.btnEsc};
            color: ${(props) => props.theme.colors.white};
            font-size: 1.1rem;
            text-align: center !important;
            border-bottom: none;

            .dx-texteditor-input,
            .dx-dropdowneditor-button,
            .dx-datebox {
              border-radius: 0;
            }
          }
        }
      }

      .dx-scrollable-content {
        background-color: ${(props) => props.theme.colors.gridBorder};

        .dx-data-row {
          background-color: ${(props) => props.theme.colors.white};
        }

        .dx-master-detail-row {
          & > td {
            padding: 10px;

            .dx-datagrid-headers {
              .dx-datagrid-content {
                background: ${(props) => props.theme.colors.gridDetailHeader};
              }
            }

            .dx-datagrid-rowsview {
              background: ${(props) => props.theme.colors.white};
            }
          }

          .dx-cell-focus-disabled.dx-master-detail-cell {
            background-color: ${(props) => props.theme.colors.gridBorder};
          }
        }
      }

      .dx-datagrid-rowsview,
      .dx-treelist-rowsview {
        tr {
          td {
            border-top: none;
            border-color: ${(props) => props.theme.colors.gridBorder};
          }
        }
        .dx-freespace-row {
          background-color: ${(props) => props.theme.colors.gridBorder};
        }

        .dx-master-detail-row {
          .dx-master-detail-cell {
            .dx-header-row {
              background-color: ${(props) =>
                props.theme.colors.gridDetailHeader};
            }
          }
        }
      }

      .dx-row-focused {
        &.dx-data-row {
          td {
            background: ${(props) => props.theme.colors.gridHlt};
            color: ${(props) => props.theme.colors.white};

            .dx-datagrid-group-closed,
            .dx-datagrid-group-opened {
              color: ${(props) => props.theme.colors.white};
            }
          }
        }
      }

      .dx-master-detail-cell {
        .dx-row-focused {
          &.dx-data-row {
            td {
              background: ${(props) => props.theme.colors.gridHlt};
              color: ${(props) => props.theme.colors.white};

              .dx-datagrid-group-closed,
              .dx-datagrid-group-opened {
                color: ${(props) => props.theme.colors.white};
              }
            }
          }
        }
      }
    }
  }
`;

export const PanelGridInitRightBtn = styled.button`
  width: 100%;
  padding: 0;
  border: none;
  border-radius: 4px;
  background: none;

  &:hover {
    background: ${(props) => props.theme.colors.asideBorder};

    svg {
      path {
        fill: ${(props) => props.theme.colors.third};
      }
    }
  }
`;

export const PanelGridInputWrapper = styled.div`
  display: grid;
  width: 100%;
  height: 100%;
  grid-template-columns: ${(props) =>
    props.openInputBar === false
      ? "0 100%"
      : props.row
      ? props.left + ` ` + props.right
      : "1fr"};
  grid-template-rows: ${(props) =>
    props.openInputBar === false
      ? "100% 0"
      : props.column
      ? props.top + ` ` + props.bottom
      : "1fr"};
  grid-gap: 10px;
`;

export const PanelGridInput = styled.form`
  display: flex;
  width: 100%;
  height: ${(props) => (props.height ? props.height + "%" : "auto")};
  padding: 15px;
  margin-top: ${(props) => (props.marginTop ? props.marginTop + "px" : "0")};
  background: ${(props) => props.theme.colors.panelInputBg};
  border: 1px solid ${(props) => props.theme.colors.layoutBorder};
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const PanelSubGridInit = styled.div`
  overflow: hidden;
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;

  .datagrid-wrap {
    overflow: auto;
    height: 100%;

    .dx-datagrid {
      background-color: transparent;

      .dx-datagrid-header-panel {
        .dx-toolbar {
          background-color: transparent;
        }
      }

      .dx-datagrid-headers {
        color: ${(props) => props.theme.colors.white};

        .dx-datagrid-content {
          background: ${(props) => props.theme.colors.gridHeader};
        }

        .dx-datagrid-filter-row {
          background: ${(props) => props.theme.colors.white};
        }
      }

      .dx-scrollable-content {
        background-color: ${(props) => props.theme.colors.gridBorder};

        .dx-data-row {
          background-color: ${(props) => props.theme.colors.white};
        }
      }

      .dx-datagrid-rowsview {
        tr {
          td {
            border-top: none;
            border-color: ${(props) => props.theme.colors.gridBorder};
          }
        }
        .dx-freespace-row {
          background-color: ${(props) => props.theme.colors.gridBorder};
        }
      }
    }
  }
`;

export const DataCount = styled.div`
  position: absolute;
  display: flex;
  height: 26px;
  top: ${(props) => (props.gridItems ? "0" : "10px")};
  right: ${(props) =>
    props.panelBtn ? "0" : props.gridRightItems ? "40px" : "10px"};
  font-size: 1.2rem;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
`;

export const GridTapWrapper = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  border-bottom: 1px solid ${(props) => props.theme.colors.gridBorder};
  align-items: flex-end;

  .grid-tap-item {
    position: relative;
    display: flex;
    width: 140px;
    height: 25px;
    bottom: -1px;
    font-size: 1.2rem;
    color: ${(props) => props.theme.colors.white};
    background: ${(props) => props.theme.colors.asideIconDefault};
    border-radius: 2px 2px 0 0;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: background 0.2s ease-in-out;

    &:hover {
      background: ${(props) => props.theme.colors.gridDetailHeader};
      transition: background 0.2s ease-in-out;
    }

    &.active {
      height: 30px;
      background: ${(props) => props.theme.colors.white};
      color: ${(props) => props.theme.colors.third};
      font-weight: 500;
      border-top: 1px solid ${(props) => props.theme.colors.gridBorder};
      border-right: 1px solid ${(props) => props.theme.colors.gridBorder};
      border-left: 1px solid ${(props) => props.theme.colors.gridBorder};
    }
  }
`;

export const GridTapContent = styled.div`
  width: 100%;
  height: 100%;
  padding: 10px;
  border-right: 1px solid ${(props) => props.theme.colors.gridBorder};
  border-left: 1px solid ${(props) => props.theme.colors.gridBorder};
  border-bottom: 1px solid ${(props) => props.theme.colors.gridBorder};
`;

export const GridButtonContent = styled.div`
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 100%;
`;

export const GridButtonRow = styled.div`
  display: flex;
  align-items: center;
  // border: 1px solid ${(props) => props.theme.colors.layoutBorder};
  // background-color: ${(props) => props.theme.colors.searchBarBg};
  flex-wrap: wrap;
  gap: 10px;

  span {
    font-size: 1.2rem;
    font-weight: 400;
  }
`;

export const GridTopText = styled.p`
  position: absolute;
  font-weight: 600;
  font-size: 1.2rem;
  top: ${(props) => props.top};
  right: 80px;
`;

export const GridBottomText = styled.p`
  position: absolute;
  font-weight: 700;
  font-size: 1.2rem;
  bottom: ${(props) => props.bottom};
  left: 250px;
`;

export const GridMulitpleBodyWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
`;

export const GridPaymentLedger = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
`;

export const PanelGridInitPayment = styled.div`
  overflow: hidden;
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;

  .datagrid-wrap {
    overflow: auto;
    height: 100%;

    .dx-datagrid {
      background-color: transparent;

      .dx-datagrid-header-panel {
        .dx-toolbar {
          background-color: transparent;
        }
      }

      .dx-datagrid-headers {
        color: ${(props) => props.theme.colors.white};

        .dx-datagrid-content {
          background: ${(props) => props.theme.colors.gridHeader};
        }

        .dx-datagrid-filter-row {
          background: ${(props) => props.theme.colors.white};
        }
      }

      .dx-scrollable-content {
        background-color: ${(props) => props.theme.colors.gridBorder};

        .dx-data-row {
          background-color: ${(props) => props.theme.colors.white};
        }
      }

      .dx-datagrid-rowsview {
        tr {
          td {
            border-top: none;
            border-color: ${(props) => props.theme.colors.gridBorder};
          }
        }
        .dx-freespace-row {
          background-color: ${(props) => props.theme.colors.gridBorder};
        }
      }
      .dx-row-focused {
        &.dx-data-row {
          td {
            background: ${(props) => props.theme.colors.gridHlt};
            color: ${(props) => props.theme.colors.white};

            .dx-datagrid-group-closed,
            .dx-datagrid-group-opened {
              color: ${(props) => props.theme.colors.white};
            }
          }
        }
      }

      .dx-master-detail-cell {
        .dx-row-focused {
          &.dx-data-row {
            td {
              background: ${(props) => props.theme.colors.gridHlt};
              color: ${(props) => props.theme.colors.white};

              .dx-datagrid-group-closed,
              .dx-datagrid-group-opened {
                color: ${(props) => props.theme.colors.white};
              }
            }
          }
        }
      }
    }
  }
`;
