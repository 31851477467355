import React from "react";
import ModalContainer from "../../../../components/Modal/ModalContainer";
import ModalHeader from "../../../../components/Modal/ModalHeader";
import { InputDate } from "../../../../components/InputForm";

const NextMonthModal = ({ nextMonthModalProps }) => {
  const { nextMonthForm, onClose, onSubmit } = nextMonthModalProps;

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = nextMonthForm;
  return (
    <>
      <ModalContainer
        onClose={onClose}
        maskClosable={false}
        visible={true}
        size="sm"
      >
        <form className="modal-form" onSubmit={handleSubmit(onSubmit)}>
          <ModalHeader title="이월" onClose={onClose} />
          <div className="modal-form-body">
            <div className="dx-field">
              <InputDate
                control={control}
                errors={errors}
                label="운송마감일자"
                name="deliverCompleteDate"
              />
            </div>
          </div>

          <div className="modal-form-footer">
            <button type="submit" className="btn btn-modal btn-save">
              저장
            </button>
          </div>
        </form>
      </ModalContainer>
    </>
  );
};

export default NextMonthModal;
