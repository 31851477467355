import { TextArea } from "devextreme-react";
import React from "react";
import { Controller } from "react-hook-form";
import InputLayout from "./InputLayout";

function InputTextArea({
  control,
  errors,
  name,
  label,
  labelWidth,
  mask,
  flex,
  height,
  width,
  required,
  disabled = false,
  onValueChange = () => {},
  maxLength,
  tabIndex,
  className,
}) {
  return (
    <InputLayout
      label={label}
      required={required}
      errors={errors}
      name={name}
      labelWidth={labelWidth}
      textArea
    >
      <Controller
        render={({ field: { onChange, value } }) => {
          if (value === "null" || value === null) value = "";
          return (
            <TextArea
              className={className}
              mask={mask}
              useMaskedValue={true}
              onValueChanged={(value) => {
                onChange(value.value);
                onValueChange(value);
              }}
              value={`${value}`}
              disabled={disabled}
              height={height}
              width={width}
              maxLength={maxLength}
              tabIndex={tabIndex}
            />
          );
        }}
        control={control}
        name={name}
        defaultValue=""
      />
    </InputLayout>
  );
}

export default InputTextArea;
