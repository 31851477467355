import { TextBox, Button } from "devextreme-react/text-box";
import React, { useState } from "react";
import { Controller } from "react-hook-form";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import InputLayout from "./InputLayout";

function InputPassword({
  control,
  errors,
  name,
  label,
  labelWidth,
  mask,
  flex,
  height,
  width,
  required,
  disabled = false,
  onValueChange = () => {},
  maxLength,
  tabIndex,
  className,
}) {
  const [passwordMode, setPasswordMode] = useState("password");
  return (
    <InputLayout
      label={label}
      required={required}
      errors={errors}
      name={name}
      labelWidth={labelWidth}
    >
      <Controller
        render={({ field: { onChange, value } }) => {
          return (
            <TextBox
              mode={passwordMode}
              className={className}
              mask={mask}
              useMaskedValue={true}
              onValueChanged={(value) => {
                onChange(value.value);
                onValueChange(value);
              }}
              value={`${value}`}
              disabled={disabled}
              height={height}
              width={width}
              maxLength={maxLength}
              tabIndex={tabIndex}
            >
              <Button
                name="password"
                location="after"
                options={{
                  icon: toAbsoluteUrl("/media/svg/icons/General/Binocular.svg"),
                  stylingMode: "text",
                  type: "default",
                  onClick: () => {
                    passwordMode === "password"
                      ? setPasswordMode("text")
                      : setPasswordMode("password");
                  },
                }}
              />
            </TextBox>
          );
        }}
        control={control}
        name={name}
        defaultValue=""
      />
    </InputLayout>
  );
}

export default InputPassword;
