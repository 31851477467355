import axios from "axios";

const apiUrl = `${process.env.REACT_APP_API_URL}`;
const apiUrlDispatchNew = `${process.env.REACT_APP_API_URL}/dispatchNew`;
// const appUrl = `https://fcm.googleapis.com/fcm/send`;

export function getLookupDatas(param) {
  return axios.get(apiUrl + `/${param}`);
}

export function getOrderDataById(id) {
  return axios.get(apiUrlDispatchNew + `/${id}`);
}

export function upsertDispatchData(data) {
  return axios.post(apiUrlDispatchNew, data);
}
