import React, { useState, useRef } from "react";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import * as requestServer from "./ManagerCrud";
import { shallowEqual, useSelector } from "react-redux";
import { confirm, alert } from "devextreme/ui/dialog";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import InputTel from "../../../components/InputForm/InputTel";
import InputText from "../../../components/InputForm/InputText";
import ManagerMaster from "./ManagerMaster";
import FunctionButton from "../../../components/FunctionButton/FunctionButton";

function ManagerPage(props) {
  const initialState = {
    code: "",
    division: "",
    client: "",
    dutyDivision: "",
    managerName: "",
    phoneNo: "",
    tel: "",
    email: "",
    remark: "",
  };
  const [selectedRowKey, setSelectedRowKey] = useState();
  const [inputDisable, setInputDisable] = useState(false);
  const dataGridRef = useRef();
  const { menuAll, openItem } = useSelector(
    (state) => ({
      menuAll: state.menu.menuAll,
      openItem: state.menu.openItem,
    }),
    shallowEqual
  );

  const schema = yup
    .object({
      code: yup
        .string()
        .required("코드를 입력하세요.")
        .max(20, "최대 20자리 까지 입력 가능"),
      division: yup.string().required("부서를 입력하세요."),
      client: yup.string().required("거래처를 입력하세요."),
      dutyDivision: yup
        .string()
        .required("업무구분을 입력하세요.")
        .max(20, "최대 20자리 까지 입력 가능"),
      managerName: yup
        .string()
        .required("성명을 입력하세요.")
        .max(20, "최대 20자리 까지 입력 가능"),
      phoneNo: yup
        .string()
        .required("전화번호를 입력하세요.")
        .max(13, "최대 13자리 까지 입력 가능"),
      tel: yup
        .string()
        .required("직통전화를 입력하세요.")
        .max(13, "최대 13자리 까지 입력 가능"),
      email: yup
        .string()
        .required("이메일을 입력하세요.")
        .max(50, "최대 50자리 까지 입력 가능"),
      remark: yup.string().max(100, "최대 100자리 까지 입력 가능"),
    })
    .required();
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onClickAdd = () => {
    if (!dataGridRef.current) return;
    reset(initialState);
    setSelectedRowKey(null);
    setInputDisable(false);
    dataGridRef.current.instance.option("focusedRowIndex", -1);
  };

  const onSubmit = async (data) => {
    delete data.id;
    delete data.password;
    delete data.createIP;
    delete data.createUserId;
    delete data.createdAt;
    delete data.updateIP;
    delete data.updateUserId;
    delete data.updatedAt;

    try {
      if (selectedRowKey) {
        await requestServer.updateManager(selectedRowKey, data);
      } else {
        await requestServer.createManager(data);
      }

      dataGridRef.current.instance.refresh();
      reset(initialState);
      setSelectedRowKey(null);
      dataGridRef.current.instance.option("focusedRowIndex", -1);
    } catch (err) {
      alert(err.response.data.message, "오류");
    }
  };

  const onClickDelete = () => {
    if (!dataGridRef.current || !selectedRowKey) return;
    let response = confirm("<i>삭제하시겠습니까?</i>", "삭제 메세지");

    response.then((res) => {
      if (!res) return;
      requestServer
        .deleteRow(selectedRowKey)
        .then(() => {
          dataGridRef.current.instance.refresh();
        })
        .catch((err) => {
          alert(err.response.data.message, "오류");
        });
    });
  };

  const onFocusedRowChanged = (e) => {
    if (!e.row) return;
    console.log(e.row);
    setInputDisable(true);
    setSelectedRowKey(e.row.key);
    reset({ ...e.row.data, remark: e.row.data.remark || undefined });
  };

  return (
    <>
      <div className="page-wrap">
        {/* 왼쪽 사이드 섹션 */}
        <div className="column-left fluid-left">
          {/* 페이지 헤더 */}
          <div className="panel-header flex-row">
            <div className="panel-header-init">
              <div className="panel-header-tit">{openItem?.menu?.menuName}</div>
              <ul className="breadcrumb-list">
                <li className="breadcrumb-item">
                  <Link to="/">
                    <i className="flaticon2-shelter icon-1x" />
                  </Link>
                </li>
                {openItem?.menu?.parentId && (
                  <li className="breadcrumb-item">
                    {
                      menuAll.find((menu) => {
                        return menu.menuId === openItem?.menu?.parentId;
                      })?.menu?.menuName
                    }
                  </li>
                )}
                {openItem?.menu?.menuName && (
                  <li className="breadcrumb-item current">
                    {openItem?.menu?.menuName}
                  </li>
                )}
              </ul>
            </div>
            <FunctionButton
              onClickAdd={onClickAdd}
              onClickDelete={onClickDelete}
            />
          </div>

          {/* 페이지 메인그리드 */}
          <ManagerMaster
            onFocusedRowChanged={onFocusedRowChanged}
            dataGridRef={dataGridRef}
          />
        </div>

        {/* 그리드 인풋 너비 고정 */}
        <div className="column-right fixed-right">
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="form-container column-input"
          >
            <div className="form-tit">담당자 등록</div>

            <InputText
              control={control}
              errors={errors}
              name="code"
              label="코드"
              required={true}
              disabled={inputDisable}
            />
            <InputText
              control={control}
              errors={errors}
              name="division"
              label="구분"
              required={true}
            />
            <InputText
              control={control}
              errors={errors}
              name="client"
              label="거래처"
              required={true}
            />
            <InputText
              control={control}
              errors={errors}
              name="dutyDivision"
              label="업무구분"
              required={true}
            />
            <InputText
              control={control}
              errors={errors}
              name="managerName"
              label="성명"
              required={true}
            />
            <InputTel
              control={control}
              errors={errors}
              name="phoneNo"
              label="휴대폰번호"
              required={true}
            />
            <InputTel
              control={control}
              errors={errors}
              name="tel"
              label="직통전화"
              required={true}
            />
            <InputText
              control={control}
              errors={errors}
              name="email"
              label="메일주소"
              required={true}
            />
            <InputText
              control={control}
              errors={errors}
              name="remark"
              label="비고"
            />

            <button type="submit" className="btn btn-form-save">
              저장
            </button>
          </form>
        </div>
      </div>
    </>
  );
}

export default ManagerPage;
