import axios from "axios";

const apiUrl = `${process.env.REACT_APP_API_URL}/chassis`;
const lookupUrl = `${process.env.REACT_APP_API_URL}`;

export function createChassis(data) {
  return axios.post(apiUrl, data);
}

export function updateChassis(key, data) {
  return axios.put(apiUrl, { key, data });
}

export function deleteRow(key) {
  return axios.delete(apiUrl + `/${key}`);
}

export function getLookup(param) {
  return axios.get(lookupUrl + `/${param}`);
}
