import { DataGrid } from "devextreme-react";
import {
  Column,
  ColumnChooser,
  FilterRow,
  Format,
  HeaderFilter,
  MasterDetail,
  Scrolling,
  StateStoring,
} from "devextreme-react/data-grid";
import CustomStore from "devextreme/data/custom_store";
import moment from "moment";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useForm } from "react-hook-form";
import { shallowEqual, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { sendRequest } from "../../../../_metronic/_helpers/DataSoureHelpers";
import OrderRefDetail from "./OrderRefDetail";

import * as requestServer from "./OrderRefCrud";

import InputDate from "../../../components/InputForm/InputDate";
import InputSelect from "../../../components/InputForm/InputSelect";
import StateStoringSaveAndDeleteButton from "../../../components/StateStoring";

function OrderRefPage(props) {
  const [lookupData, setLookupData] = useState({
    company: [],
  });
  const { user } = useSelector(
    (state) => ({ user: state.auth.user.user }),
    shallowEqual
  );

  const initialState = {
    importExport: "",
    division: "",
    receiveCo: "",
    searchType: 1,
    firstDay: moment(new Date())
      .subtract(30, "days")
      .format("YYYY-MM-DD"),
    lastDay: moment().format("YYYY-MM-DD"),
    authGroupId: user.authGroupId,
    dispatchTeam: user.deptCode_department?.deptName,
    orderStatus: "",
  };
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  // other state
  const [totalCount, setTotalCount] = useState(0);
  const [search, setSearch] = useState({});
  const [focusedComponent, setFocusedComponent] = useState();

  const dataGridRef = useRef();
  const detailGridRef = useRef();

  const storageKey = "orderRefMaster";

  // const [rowData, setRowData] = useState();
  // const [detailRowData, setDetailRowData] = useState();

  const { menuAll, openItem } = useSelector(
    (state) => ({
      menuAll: state.menu.menuAll,
      openItem: state.menu.openItem,
    }),
    shallowEqual
  );

  const dataSource = useMemo(() => {
    const apiUrl = `${process.env.REACT_APP_API_URL}/dispatch/orderref`;

    return new CustomStore({
      key: "id",
      load: () => sendRequest(apiUrl, "GET", search),
    });
  }, [search]);

  useEffect(() => {
    reset(initialState);
    setSearch(initialState);
    getLookup();
    // eslint-disable-next-line
  }, []);

  const onCellClick = useCallback((e) => {
    if (!e.row) return;
    // detailGridRef.current && detailGridRef.current.instance.option("focusedRowIndex", -1);
    const row = e.row;
    if (row.rowType === "data") {
      // setRowData(row.data);
    }
  }, []);

  const onFocusedRowChanged = useCallback(
    (e) => {
      if (e.rowIndex === -1) return;
      // eslint-disable-next-line
      if (focusedComponent && focusedComponent != e.component) {
        focusedComponent.option("focusedRowIndex", -1);
        if (e.element.id === "OrderRefMaster") {
          // setDetailRowData(null);
        } else if (e.element.id === "OrderRefDetail") {
          // setRowData(null);
        }
      }
      setFocusedComponent(e.component);
    },
    [focusedComponent]
  );

  const importExportRender = useCallback((e) => {
    if (e.row.data.importExport === 1) {
      return <div>수입</div>;
    } else {
      return <div>수출</div>;
    }
  }, []);

  const orderStatusRender = useCallback((e) => {
    if (e.data.longStorage === true) {
      return <div>장기보관</div>;
    } else {
      return <div>{e.text}</div>;
    }
  }, []);

  const onRowDblClick = (e) => {
    if (e.isExpanded) {
      e.component.collapseRow(e.key);
    } else {
      e.component.expandRow(e.key);
    }
  };

  const onSubmit = (data) => {
    for (const obj in data) {
      if (data[obj] === "") {
        data[obj] = null;
      }
    }
    const submit = {
      ...data,
      authGroupId: user.authGroupId,
      dispatchTeam: user.deptCode_department?.deptName,
    };

    setSearch(submit);
  };

  const divisionRender = useCallback((e) => {
    if (e.value === "order") return <div>오더접수</div>;
    if (e.value === "lclorder") return <div>lcl오더접수</div>;
    if (e.value === "simple") return <div>간편배차</div>;
    if (e.value === "shuttle") return <div>셔틀배차</div>;
    if (e.value === "otherCo") return <div>용차배차</div>;
  }, []);

  const onRowPrepared = (e) => {
    if (e.rowType !== "data") return;

    // 색깔 조정하기
    if (e.data.longStorage === true) {
      // 접수
      e.rowElement.style.background = "#eaeaea";
    }
  };

  const onCellPrepared = useCallback((e) => {
    if (e.rowType !== "data") return;
    if (e.column.caption !== "상차지" && e.column.caption !== "하차지") return;

    if (e.column.caption === "상차지") {
      e.cellElement.style.background = e.data.loadPlaceColor || "transparant";
    } else if (e.column.caption === "하차지") {
      e.cellElement.style.background = e.data.unloadPlaceColor || "transparant";
    }
  }, []);

  async function getLookup() {
    try {
      const companyData = await requestServer.getLookup("company");

      setLookupData({
        company: [{ id: "", companyName: "전체" }, ...companyData.data],
      });
    } catch (err) {
      console.log(err);
    }
  }

  return (
    <>
      <div className="page-wrap">
        <div className="column-left full-size">
          {/* 페이지 헤더 */}
          <div className="panel-header flex-row">
            <div className="panel-header-init">
              <div className="panel-header-tit">{openItem?.menu?.menuName}</div>
              <ul className="breadcrumb-list">
                <li className="breadcrumb-item">
                  <Link to="/">
                    <i className="flaticon2-shelter icon-1x" />
                  </Link>
                </li>
                {openItem?.menu?.parentId && (
                  <li className="breadcrumb-item">
                    {
                      menuAll.find((menu) => {
                        return menu.menuId === openItem?.menu?.parentId;
                      })?.menu?.menuName
                    }
                  </li>
                )}
                {openItem?.menu?.menuName && (
                  <li className="breadcrumb-item current">
                    {openItem?.menu?.menuName}
                  </li>
                )}
              </ul>
            </div>
          </div>

          {/* 페이지 메인그리드 검색 */}
          <div className="grid-search-wrap">
            <form onSubmit={handleSubmit(onSubmit)}>
              <span className="search-group">
                <label>접수법인</label>
                <InputSelect
                  searchEnabled={true}
                  control={control}
                  errors={errors}
                  name="receiveCo"
                  flex={true}
                  dataSource={lookupData.company}
                  valueExpr="id"
                  displayExpr="companyName"
                />
              </span>

              <span className="search-group">
                <label>수입/수출</label>
                <InputSelect
                  className="label-select"
                  control={control}
                  errors={errors}
                  name="importExport"
                  displayExpr="name"
                  valueExpr="id"
                  dataSource={[
                    { id: "", name: "전체" },
                    { id: 1, name: "수입" },
                    { id: 2, name: "수출" },
                  ]}
                  flex={true}
                  width="80"
                />
              </span>
              <span className="search-group">
                <label>상태구분</label>
                <InputSelect
                  className="label-select"
                  control={control}
                  errors={errors}
                  name="orderStatus"
                  displayExpr="name"
                  valueExpr="id"
                  dataSource={[
                    { id: "", name: "전체" },

                    { id: 62, name: "접수" },
                    { id: 4100, name: "상차지" },
                    { id: 4110, name: "작업경유지1" },
                    { id: 4120, name: "작업경유지2" },
                    { id: 4130, name: "작업경유지3" },
                    { id: 4200, name: "작업지" },
                    { id: 4210, name: "회수경유지1" },
                    { id: 4220, name: "회수경유지2" },
                    { id: 4230, name: "회수경유지3" },
                    { id: 80, name: "운송완료" },
                    { id: 81, name: "장기보관" },
                  ]}
                  flex={true}
                  width="100"
                />
              </span>

              <span className="search-group">
                <label>오더구분</label>
                <InputSelect
                  className="label-select"
                  control={control}
                  errors={errors}
                  name="division"
                  displayExpr="name"
                  valueExpr="id"
                  dataSource={[
                    { id: "", name: "전체" },
                    { id: "order", name: "오더접수" },
                    { id: "lclorder", name: "LCL오더접수" },
                    { id: "simple", name: "간편배차" },
                    { id: "shuttle", name: "셔틀배차" },
                    { id: "otherCo", name: "용차오더배차" },
                  ]}
                  flex={true}
                  width="100"
                />
              </span>

              <span className="search-group">
                <InputSelect
                  className="label-select"
                  control={control}
                  errors={errors}
                  name="searchType"
                  displayExpr="name"
                  valueExpr="id"
                  dataSource={[
                    { id: 1, name: "접수일자" },
                    { id: 2, name: "운송요청일자" },
                    { id: 3, name: "작업지도착시간" },
                    { id: 4, name: "반납일자" },
                  ]}
                  flex={true}
                  width="100"
                />

                <InputDate
                  control={control}
                  errors={errors}
                  name="firstDay"
                  flex={true}
                />

                <div className="to-mark">~</div>

                <InputDate
                  control={control}
                  errors={errors}
                  name="lastDay"
                  flex={true}
                />
              </span>

              <button type="submit" className="btn btn-grid-search">
                검색
              </button>
            </form>
          </div>

          {/* 페이지 메인그리드 */}
          <div className="grid-component order-page has-toolbar has-func-btn">
            <StateStoringSaveAndDeleteButton
              dataGridRef={dataGridRef}
              storageKey={storageKey}
            />
            <div className="grid-total">{`총 ${totalCount}건`}</div>
            <DataGrid
              className="datagrid-wrap"
              id="OrderRefMaster"
              dataSource={dataSource}
              ref={dataGridRef}
              keyExpr="id"
              showRowLines={true}
              showBorders={true}
              hoverStateEnabled={true}
              allowColumnResizing={true}
              allowColumnReordering={true}
              columnAutoWidth={true}
              columnResizingMode="widget"
              onRowDblClick={onRowDblClick}
              onFocusedRowChanged={onFocusedRowChanged}
              focusedRowEnabled={true}
              onCellPrepared={onCellPrepared}
              onCellClick={onCellClick}
              onRowPrepared={onRowPrepared}
              onContentReady={(e) => setTotalCount(e.component.totalCount())}
            >
              <FilterRow visible={true} />
              <HeaderFilter visible={true} />
              <ColumnChooser enabled={true} />
              <StateStoring
                enabled={true}
                type="custom"
                customLoad={useCallback(() => {
                  return JSON.parse(localStorage.getItem(storageKey));
                }, [])}
              />
              <Scrolling
                columnRenderingMode="virtual"
                mode="virtual"
                showScrollbar="always"
                useNative="false"
              />
              <Column dataField="orderCode" caption="접수번호" />
              <Column
                dataField="division"
                caption="구분"
                cellRender={divisionRender}
                alignment="center"
              />
              <Column
                dataField="orderStatusName"
                caption="상태"
                cellRender={orderStatusRender}
                alignment="center"
              />
              <Column
                dataField="importExport"
                caption="수입/수출"
                cellRender={importExportRender}
                alignment="center"
              />
              <Column dataField="forwarder" caption="선사" alignment="center" />
              <Column dataField="cargoOwnerName" caption="화주" />
              <Column dataField="billingPlaceName" caption="청구처" />
              <Column
                dataField="containerNo"
                caption="컨테이너 No"
                alignment="center"
              />
              <Column dataField="sealNo" caption="실 No" alignment="center" />
              <Column
                dataField="containerType"
                caption="컨/규격"
                alignment="center"
              />
              <Column
                dataField="deliverDate"
                caption="운송요청일자"
                alignment="center"
                dataType="date"
                format="yyyy-MM-dd"
              />
              <Column
                dataField="freight"
                caption="청구금액"
                alignment="right"
                dataType="number"
              >
                <Format type="fixedPoint" />
              </Column>
              <Column
                dataField="extraCharge"
                caption="할증금액"
                alignment="right"
                dataType="number"
              >
                <Format type="fixedPoint" />
              </Column>
              {/* <Column dataField="habul" caption="예상하불금액" alignment="right" dataType="number">
                <Format type="fixedPoint" />
              </Column> */}
              <Column
                dataField="habul"
                caption="하불금액"
                alignment="right"
                dataType="number"
              >
                <Format type="fixedPoint" />
              </Column>
              <Column
                dataField="dispatchTeam"
                caption="배차팀"
                alignment="center"
              />
              <Column
                dataField="loadPlace"
                caption="상차지"
                alignment="center"
              />
              <Column dataField="workWaypoint" caption="작업경유지" />
              <Column dataField="workPlace" caption="작업지" />
              <Column dataField="collectWaypoint" caption="회수경유지" />
              <Column
                dataField="unloadPlace"
                caption="하차지"
                alignment="center"
              />
              <Column
                dataField="workTime"
                caption="작업시간"
                alignment="center"
                dataType="date"
                format="HH:mm:ss"
              />
              <Column
                dataField="dem"
                caption="선사반출기한"
                alignment="center"
                dataType="date"
                format="yyyy-MM-dd"
              />
              <Column
                dataField="returnDate"
                caption="반납일자"
                alignment="center"
                dataType="date"
                format="yyyy-MM-dd"
              />
              <Column
                dataField="bobtail"
                caption="밥테일여부"
                dataType="boolean"
              />
              <Column
                dataField="fridge"
                caption="냉동여부"
                dataType="boolean"
              />
              <Column
                dataField="danger"
                caption="위험물여부"
                dataType="boolean"
              />
              <Column
                dataField="temperature"
                caption="온도"
                alignment="center"
              />
              <Column dataField="vent" caption="환풍구" alignment="center" />
              <Column dataField="unNo" caption="un번호" alignment="center" />
              <Column dataField="class" caption="클래스" alignment="center" />
              <Column
                dataField="extraInfo"
                caption="기타정보"
                alignment="center"
              />
              <MasterDetail
                enabled={true}
                render={(e) => (
                  <OrderRefDetail
                    data={e.data.id}
                    dataGridRef={dataGridRef}
                    detailGridRef={detailGridRef}
                    detail={e.data.detail}
                    // setDetailRowData={setDetailRowData}
                    onFocusedRowChanged={onFocusedRowChanged}
                  />
                )}
              />
            </DataGrid>
          </div>
        </div>
      </div>
    </>
  );
}

export default OrderRefPage;
