import { DataGrid } from "devextreme-react";
import {
  Column,
  ColumnChooser,
  ColumnFixing,
  FilterRow,
  Format,
  HeaderFilter,
  Paging,
  Scrolling,
  Selection,
  StateStoring,
} from "devextreme-react/data-grid";
import CustomStore from "devextreme/data/custom_store";
import { alert, confirm } from "devextreme/ui/dialog";
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import { useForm } from "react-hook-form";
import { sendRequest } from "../../../../../_metronic/_helpers/DataSoureHelpers";
import CustomButton from "../../../../components/FunctionButton/CustomButton";
import { ButtonGroup } from "../../../../components/FunctionButton/CustomButton/customButton.style";
import InputNumber from "../../../../components/InputForm/InputNumber";
import {
  DataCount,
  PanelGridBox,
  PanelGridInit,
  PanelGridInitLeft,
  PanelGridInitRight,
  PanelGridInitRightBtn,
  PanelGridInput,
} from "../../../../components/Layout/Panel/PanelGrid/panelGrid.style";
import {
  InputDivide,
  InputFullRow,
  PanelInput,
} from "../../../../components/Layout/Panel/PanelInput/panelInput.style";
import StateStoringSaveAndDeleteButton from "../../../../components/StateStoring";
import { getCompanyData, updateAllTaxSellManage } from "../ClosingCrud";
import OrderDetailModal from "./OrderDetailModal";
import { ReactComponent as IconArrow } from "../../../../../style/icons/Angle-right.svg";

const ClosingDetail = forwardRef(
  ({ closingRightRef, selectedRow, search }, ref) => {
    useImperativeHandle(ref, () => ({
      onSelectionChanged,
    }));
    const DetailStorageKey = "closingDetail";
    const habulStorageKey = "habulGrid";
    const [totalCount, setTotalCount] = useState(0);
    const [totalCount2, setTotalCount2] = useState(0);
    const [selectedRowCount, setSelectedRowCount] = useState(0);
    const [openOrderDetail, setOpenOrderDetail] = useState(false);
    // const [taxCount, setTaxCount] = useState(true);
    const [selectedKey, setSelectedKey] = useState();
    const [rowData, setRowData] = useState({});
    const [gridOpen, setGridOpen] = useState(false);
    const {
      watch,
      control,
      setValue,
      formState: { errors },
    } = useForm();

    const habulGridRef = useRef();

    useEffect(() => {
      // 청구처가아닌 데이터를 클릭했을때 오른쪽 그리드 닫힘
      if (rowData.division !== "청구처") {
        setGridOpen(false);
      }
    }, [rowData]);

    useEffect(() => {
      const instance = closingRightRef.current.instance;
      getCompany(selectedRow);
      // instance.clearFilter();
      // instance.clearSorting();
      // eslint-disable-next-line
    }, [setValue, selectedRow]);

    const dataSource = useMemo(() => {
      let apiUrl = `${process.env.REACT_APP_API_URL}`;
      if (selectedRow.division === "청구처")
        apiUrl += "/finish-sell-manage/billing";
      else if (selectedRow.division === "용차사")
        apiUrl += "/finish-sell-manage/yongcha";
      else if (selectedRow.division === "기사")
        apiUrl += "/finish-sell-manage/driver";

      return new CustomStore({
        key: "id",
        load: () => {
          if (apiUrl === `${process.env.REACT_APP_API_URL}`) return [];
          return sendRequest(apiUrl, "GET", {
            search: search,
            key: {
              oid: selectedRow.oid,
              receiveCo: selectedRow.receiveCoName,
              companyId: selectedRow.companyId,
              comOid: selectedRow.comOid,
              company: selectedRow.companyName,
              division: selectedRow.division,
            },
          });
        },
      });
    }, [search, selectedRow]);

    // 청구처sub클 클릭했을때 하불Data 불러오기
    const additionalDataSource = useMemo(() => {
      let apiUrl = `${process.env.REACT_APP_API_URL}/finish-sell-manage/habul`;

      return new CustomStore({
        key: "id",
        load: () => {
          return sendRequest(apiUrl, "GET", {
            rowData: rowData,
          });
        },
      });
    }, [rowData]);

    const onFocusedRowChanged = useCallback((e) => {
      setSelectedKey(e.row?.data?.id);
      setRowData(e.row?.data);
      setValue("selectedTax", parseInt(e.row?.data?.tax) || 0);
      // eslint-disable-next-line
    }, []);

    const onSelectionChanged = useCallback((selectedRowsData) => {
      setSelectedRowCount(selectedRowsData.length);
      let price = 0;
      let tax = 0;

      for (const obj of selectedRowsData) {
        price += Number(obj.price) || 0;
        tax += Number(obj.tax) || 0;
      }

      setValue("price", price);
      setValue("tax", tax);
      setValue("totalPrice", price + tax);
      // eslint-disable-next-line
    }, []);

    const onRowDblClick = useCallback(() => {
      setOpenOrderDetail(true);
    }, []);

    const onClickTaxAmendButton = async () => {
      const tax = watch("selectedTax") || 0;
      const instance = closingRightRef.current.instance;

      if (!selectedKey) {
        await alert("데이터를 선택해주세요", "확인");
        return;
      }

      if (tax === 0) {
        await alert("부가세를 입력해주세요", "확인");
        return;
      }

      const sendData = {
        extraDivision: rowData?.extraDivision,
        division: selectedRow.division,
        tax: tax,
        key: [selectedKey],
      };

      const result = await confirm("부가세를 수정하시겠습니까?", "확인");

      if (!result) return;

      try {
        await updateAllTaxSellManage(sendData);

        await instance.refresh();

        const selected = instance.getSelectedRowsData();
        onSelectionChanged(selected);
      } catch (error) {
        console.log(error);
        alert("에러", "오류");
      }
    };

    const handleRightGridOpen = () => {
      return setGridOpen(!gridOpen);
    };

    async function getCompany(selectedRow) {
      try {
        const { data } = await getCompanyData({
          comOid: selectedRow.comOid,
          division: selectedRow.division,
        });
        setValue("companyName", data[0]?.company);
        setValue("bizNo", data[0]?.bizNo);
        setValue("address", data[0]?.address);
        setValue("ceoName", data[0]?.ceoName);
      } catch (error) {
        console.log(error);
      }
    }

    return (
      <>
        <PanelGridInit gridSub gridItems row>
          <PanelGridBox gridHeight={78}>
            {/* 중앙 sub 그리드 */}
            <PanelGridInitLeft gridOpen={gridOpen}>
              <StateStoringSaveAndDeleteButton
                dataGridRef={closingRightRef}
                storageKey={DetailStorageKey}
                gridItems={true}
              />
              <DataCount gridItems={true}>{`총 ${totalCount}건`}</DataCount>
              <DataGrid
                className="datagrid-wrap multi-line"
                id="ClosingRight"
                dataSource={dataSource}
                ref={closingRightRef}
                showRowLines={true}
                showBorders={true}
                hoverStateEnabled={true}
                allowColumnResizing={true}
                allowColumnReordering={true}
                columnResizingMode="widget"
                keyExpr="id"
                focusedRowEnabled={true}
                onSelectionChanged={({ selectedRowsData }) =>
                  onSelectionChanged(selectedRowsData)
                }
                onFocusedRowChanged={onFocusedRowChanged}
                onRowDblClick={onRowDblClick}
                onContentReady={(e) => setTotalCount(e.component.totalCount())}
              >
                <ColumnFixing enabled={true} />
                <FilterRow visible={true} />
                <HeaderFilter visible={true} />
                <Selection
                  mode="multiple"
                  allowSelectAll={true}
                  showCheckBoxesMode="always"
                />
                <ColumnChooser enabled={true} />

                <StateStoring
                  enabled={true}
                  type="custom"
                  customLoad={useCallback(() => {
                    return JSON.parse(localStorage.getItem(DetailStorageKey));
                  }, [])}
                />
                <Scrolling showScrollbar="always" useNative="false" />
                <Paging enabled={true} pageSize={100} />
                <Column
                  caption="No"
                  width="28"
                  cellRender={(e) => e.row.loadIndex + 1}
                />
                <Column
                  dataField="extraDivision"
                  caption="구분"
                  width="110"
                  cellRender={(e) => {
                    const { value } = e;
                    if (value === "detail") return "오더";
                    if (value === "dis") return "배차";
                    if (value === "extra") return "할증";
                    if (value === "etc") return "기타하불";
                    return "";
                  }}
                />
                <Column
                  dataField="orderDivision"
                  caption="오더구분"
                  width="65"
                  cellRender={(e) => {
                    const { value } = e;
                    if (value === "order") return "오더접수";
                    if (value === "lclorder") return "LCL오더접수";
                    if (value === "simpleFinish") return "간편정산";
                    if (value === "otherCo") return "용차오더접수";
                    return "";
                  }}
                />
                <Column
                  dataField="companyName"
                  caption="접수법인"
                  width="110"
                />
                <Column dataField="code" caption="접수번호" width="110" />
                <Column
                  dataField="receiveDate"
                  caption="접수일자"
                  width="110"
                />
                <Column
                  dataField="deliverDate"
                  caption="운송일자"
                  width="110"
                />
                <Column
                  dataField="deliverCompleteDate"
                  caption="운송마감일자"
                  width="110"
                />
                <Column
                  dataField="workPlaceAddress"
                  caption="청구주소"
                  width="200"
                />
                <Column dataField="completeStatus" caption="마감" width="110" />
                <Column dataField="completeNo" caption="마감번호" width="110" />
                <Column
                  dataField="completeDate"
                  caption="마감일자"
                  width="110"
                />
                <Column
                  dataField="completeTime"
                  dataType="datetime"
                  format="yyyy-MM-dd hh:mm:ss"
                  caption="마감처리일자"
                  width="110"
                />
                <Column
                  dataField="completeManager"
                  caption="마감담당자"
                  width="110"
                />
                <Column
                  dataField="prtState"
                  caption="출력구분"
                  width="60"
                  cellRender={(e) => {
                    if (e.value !== false) {
                      return "Y";
                    } else {
                      return "N";
                    }
                  }}
                />
                <Column
                  dataField="closeStatus"
                  caption="발행상태"
                  width="110"
                />
                <Column
                  dataField="importExportName"
                  caption="수입/수출"
                  width="60"
                />
                <Column dataField="manager" caption="담당자" width="110" />
                <Column
                  dataField="price"
                  caption="금액"
                  dataType="number"
                  width="110"
                >
                  <Format type="fixedPoint" />
                </Column>
                <Column
                  dataField="tax"
                  caption="세액"
                  dataType="number"
                  width="110"
                >
                  <Format type="fixedPoint" />
                </Column>
                <Column
                  dataField="totalPrice"
                  caption="합계금액"
                  dataType="number"
                  width="110"
                >
                  <Format type="fixedPoint" />
                </Column>
                <Column
                  dataField="totalExtra"
                  caption="할증합계"
                  dataType="number"
                  width="110"
                >
                  <Format type="fixedPoint" />
                </Column>
                <Column
                  dataField="totalDetail"
                  caption="청구총금액"
                  dataType="number"
                  width="110"
                >
                  <Format type="fixedPoint" />
                </Column>
                <Column
                  dataField="habulTotal"
                  caption="하불총금액"
                  dataType="number"
                  width="110"
                >
                  <Format type="fixedPoint" />
                </Column>
                <Column
                  dataField="allTotalPrice"
                  caption="수익금"
                  dataType="number"
                  width="110"
                >
                  <Format type="fixedPoint" />
                </Column>
                <Column dataField="bizNo" caption="사업자번호" width="100" />
                <Column dataField="bizName" caption="상호" width="100" />
                <Column
                  dataField="containerNo"
                  caption="컨테이너No"
                  width="110"
                />
                <Column
                  dataField="containerType"
                  caption="컨규격"
                  width="110"
                />
                <Column dataField="sealNo" caption="씰번호" width="110" />
                <Column dataField="cargoWeight" caption="중량" width="110" />
                <Column dataField="amount" caption="수량" width="110" />
                <Column dataField="remark" caption="비고" width="110" />
                <Column dataField="innerMemo" caption="내부메모" width="110" />
                <Column dataField="division" caption="마감구분" width="110" />
                <Column
                  dataField="extraChargeDivision"
                  caption="청구구분"
                  width="110"
                />
                <Column
                  dataField="extraRemark"
                  caption="청구비고"
                  width="110"
                />
                <Column dataField="extraInfo" caption="특이사항" width="110" />
                <Column
                  dataField="etcHabulRemark"
                  caption="기타하불비고"
                  width="110"
                />
                <Column
                  dataField="dispatchTeamName"
                  caption="배차팀"
                  width="110"
                />
                <Column
                  dataField="billingPlaceName"
                  caption="청구처"
                  width="110"
                />
                <Column dataField="orderCompany" caption="발주처" width="110" />
                <Column dataField="forwarder" caption="선사" width="110" />
                <Column dataField="shipName" caption="선명" width="110" />
                <Column dataField="motherShip" caption="모선항차" width="110" />
                <Column dataField="cargoOwnerName" caption="화주" width="110" />
                <Column
                  dataField="realCargoOwner"
                  caption="실화주"
                  width="110"
                />
                <Column dataField="dem" caption="선사반출기한" width="110" />
                <Column
                  dataField="enterDay"
                  caption="입항일"
                  width="110"
                  dataType="datetime"
                  format="yyyy-MM-dd HH:mm:ss"
                />
                <Column
                  dataField="sailDay"
                  caption="출항일"
                  width="110"
                  dataType="datetime"
                  format="yyyy-MM-dd HH:mm:ss"
                />
                <Column
                  dataField="loadPlaceName"
                  caption="상차지"
                  width="110"
                />
                <Column
                  dataField="loadPlaceText"
                  caption="LCL 상차지"
                  width="110"
                />
                <Column dataField="workPlace" caption="작업지" width="110" />
                <Column
                  dataField="workPlaceAdd"
                  caption="작업지주소"
                  width="110"
                />
                <Column
                  dataField="unloadPlaceName"
                  caption="하차지"
                  width="110"
                />
                <Column
                  dataField="unloadPlaceText"
                  caption="LCL 하차지"
                  width="110"
                />
                <Column dataField="blNoM" caption="Master BL" width="110" />
                <Column dataField="blNoH" caption="House BL" width="110" />
                <Column dataField="bkNo" caption="BK NO" width="110" />
                <Column dataField="doNo" caption="DO NO" width="110" />
                <Column
                  dataField="createUsr"
                  caption="최초입력자"
                  alignment="center"
                  width="110"
                />
                <Column
                  dataField="updateUsr"
                  caption="최종수정자"
                  alignment="center"
                  width="110"
                />
                <Column
                  dataField="createDate"
                  caption="작성일"
                  alignment="center"
                  width="110"
                />
                <Column
                  dataField="createTime"
                  caption="작성시간"
                  alignment="center"
                  width="110"
                />
              </DataGrid>
            </PanelGridInitLeft>

            {/* 오른쪽 하불 그리드 */}
            <PanelGridInitRight gridHeight={78} gridOpen={gridOpen}>
              <StateStoringSaveAndDeleteButton
                gridRightItems={true}
                dataGridRef={habulGridRef}
                storageKey={habulStorageKey}
              />
              <DataCount
                gridRightItems={true}
              >{`총 ${totalCount2}건`}</DataCount>
              <DataGrid
                className="datagrid-wrap multi-line"
                id="ClosingRight"
                dataSource={additionalDataSource}
                ref={habulGridRef}
                showRowLines={true}
                showBorders={true}
                hoverStateEnabled={true}
                allowColumnResizing={true}
                allowColumnReordering={true}
                columnResizingMode="widget"
                keyExpr="id"
                onContentReady={(e) => setTotalCount2(e.component.totalCount())}
              >
                <ColumnFixing enabled={true} />
                <FilterRow visible={true} />
                <HeaderFilter visible={true} />
                <ColumnChooser enabled={true} />
                <StateStoring
                  enabled={true}
                  type="custom"
                  customLoad={useCallback(() => {
                    return JSON.parse(localStorage.getItem(habulStorageKey));
                  }, [])}
                />
                <Scrolling showScrollbar="always" useNative="false" />
                <Paging enabled={true} pageSize={1000} />
                <Column
                  caption="No"
                  width="28"
                  cellRender={(e) => e.row.loadIndex + 1}
                />
                <Column dataField="division" caption="구분" width="63" />
                <Column dataField="carNo" caption="차량번호" width="100" />
                <Column dataField="yongchasa" caption="용차사" width="100" />
                <Column
                  dataField="habul"
                  caption="하불"
                  width="100"
                  dataType="number"
                >
                  <Format type="fixedPoint" />
                </Column>
                <Column
                  dataField="tax"
                  caption="세액"
                  width="100"
                  dataType="number"
                >
                  <Format type="fixedPoint" />
                </Column>
                <Column dataField="remark" caption="비고" width="100" />
              </DataGrid>
              <PanelGridInitRightBtn onClick={handleRightGridOpen}>
                <IconArrow transform={gridOpen ? `rotate(0)` : `rotate(180)`} />
              </PanelGridInitRightBtn>
            </PanelGridInitRight>
          </PanelGridBox>
          <PanelGridInput height={22}>
            <PanelInput grid={3}>
              <div class="total-count">마감항목: {selectedRowCount}건 선택</div>
            </PanelInput>

            <InputFullRow grid={4}>
              <InputDivide />
            </InputFullRow>
            <PanelInput grid={3}>
              <InputNumber
                control={control}
                errors={errors}
                label="선택된부가세"
                name="selectedTax"
              />
              <ButtonGroup>
                <CustomButton
                  type="button"
                  width="60px"
                  height={24}
                  layout="solid"
                  color="common"
                  label="저장"
                  onClick={onClickTaxAmendButton}
                />
              </ButtonGroup>
            </PanelInput>

            <InputFullRow grid={4}>
              <InputDivide />
            </InputFullRow>

            <PanelInput grid={3}>
              <InputNumber
                control={control}
                errors={errors}
                label="매출금액"
                name="price"
                disabled={true}
              />
              <div />
              <div />
              <InputNumber
                control={control}
                errors={errors}
                label="부가세"
                name="tax"
                disabled={true}
              />
              <div />
              <div />
              <InputNumber
                control={control}
                errors={errors}
                label="합계금액"
                name="totalPrice"
                disabled={true}
              />
            </PanelInput>
          </PanelGridInput>
        </PanelGridInit>
        {openOrderDetail && (
          <OrderDetailModal
            onClose={() => setOpenOrderDetail(false)}
            closable={true}
            visible={true}
            maskClosable={false}
            rowData={rowData}
            selectedKey={selectedKey}
            closingRightRef={closingRightRef}
          />
        )}
      </>
    );
  }
);

export default ClosingDetail;
