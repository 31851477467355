import axios from "axios";
const apiUrl = `${process.env.REACT_APP_API_URL}/etccost`;

export function getLookup(param) {
  return axios.get(`${process.env.REACT_APP_API_URL}/${param}`);
}

export function updateEtcCode(key, data) {
  return axios.put(apiUrl, { key: key, data });
}

export function createEtcCode(data) {
  return axios.post(apiUrl, data);
}

export function getEtcCodeData(key) {
  return axios.get(apiUrl + `/${key}`);
}

export function deleteEtcCostRow(key) {
  return axios.delete(apiUrl + `/${key}`);
}
