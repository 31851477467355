import { yupResolver } from "@hookform/resolvers/yup";
import { Button } from "devextreme-react/button";
import { alert, confirm } from "devextreme/ui/dialog";
import React, { useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { shallowEqual, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import * as yup from "yup";
import * as requestServer from "./BillingCrud";
import BillingMaster from "./BillingMaster";

import { SelectModal } from "../../../../_metronic/_partials/modals/SelectModal";
import InputCheckBox from "../../../components/InputForm/InputCheckBox";
import InputText from "../../../components/InputForm/InputText";
import FunctionButton from "../../../components/FunctionButton/FunctionButton";

function BillingPage(props) {
  const initialState = {
    code: "",
    companyName: "",
    bizNo: "",
    ceoName: "",
    zipCode: "",
    address: "",
    address1: "",
    bizType: "",
    bizItem: "",
    email: "",
    accountNo: "",
    accountType: "",
    isShuttleDepot: false,
    remark: "",
  };
  const [selectedRowKey, setSelectedRowKey] = useState();
  const [selectModal, setSelectModal] = useState(false);
  const dataGridRef = useRef();
  const { menuAll, openItem } = useSelector(
    (state) => ({
      menuAll: state.menu.menuAll,
      openItem: state.menu.openItem,
    }),
    shallowEqual
  );

  const schema = yup
    .object({
      code: yup.string().max(20, "최대 20자리 까지 입력 가능"),
      companyName: yup
        .string()
        .required("회사명을 입력하세요.")
        .max(50, "최대 50자리 까지 입력 가능"),
      Biz_No: yup
        .string()
        .required("사업자번호를 입력하세요.")
        .max(20, "최대 20자리 까지 입력 가능"),
      isShuttleDepot: yup.bool(),
      remark: yup.string().max(100, "최대 100자리 까지 입력 가능"),
    })
    .required();

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onFocusedRowChanged = (e) => {
    if (!e.row) return;
    const data = e.row.data;
    const joinData = data.Biz_No_Sys_CompanyTax_Info;

    setSelectedRowKey(e.row.key);
    reset({ ...e.row.data, ...joinData });
  };

  const onClickAdd = () => {
    if (!dataGridRef.current) return;
    reset(initialState);
    setSelectedRowKey(null);
    dataGridRef.current.instance.option("focusedRowIndex", -1);
  };

  const onSubmit = async (data) => {
    delete data.createIP;
    delete data.createUserId;
    delete data.createdAt;
    delete data.updateIP;
    delete data.updateUserId;
    delete data.updatedAt;

    try {
      if (selectedRowKey) {
        await requestServer.updateBillingPlace(selectedRowKey, data);
      } else {
        await requestServer.createBillingPlace(data);
      }
      dataGridRef.current.instance.refresh();
      reset(initialState);
      setSelectedRowKey(null);

      dataGridRef.current.instance.option("focusedRowIndex", -1);
    } catch (err) {
      alert(err.response.data.message, "오류");
    }
  };

  const onClickDelete = () => {
    if (!dataGridRef.current || !selectedRowKey) return;
    let response = confirm("<i>삭제하시겠습니까?</i>", "삭제 메세지");

    response.then((res) => {
      if (!res) return;
      requestServer
        .deleteRow(selectedRowKey)
        .then(() => {
          dataGridRef.current.instance.refresh();
        })
        .catch((err) => {
          alert(err.response.data.message, "오류");
        });
    });
  };

  const onError = (e) => {};

  const closeModal = () => {
    setSelectModal(false);
    dataGridRef.current.instance.refresh();
  };

  const onSelect = (rowData) => {
    setValue("Biz_No", rowData.Biz_No);
    setValue("Ceo_Name", rowData.Ceo_Name);
    setValue("Zip_Code", rowData.Zip_Code);
    setValue("Address", rowData.Address);
    setValue("Address_Detail", rowData.Address_Detail);
    setValue("Biz_Type", rowData.Biz_Type);
    setValue("Biz_Item", rowData.Biz_Item);
    setValue("Tel", rowData.Tel);
    setValue("Fax", rowData.Fax);
    setValue("Email", rowData.Email);

    setSelectModal(false);
  };

  return (
    <>
      <div className="page-wrap">
        {/* 왼쪽 사이드 섹션 */}
        <div className="column-left fluid-left">
          {/* 페이지 헤더 */}
          <div className="panel-header flex-row">
            <div className="panel-header-init">
              <div className="panel-header-tit">{openItem?.menu?.menuName}</div>
              <ul className="breadcrumb-list">
                <li className="breadcrumb-item">
                  <Link to="/">
                    <i className="flaticon2-shelter icon-1x" />
                  </Link>
                </li>
                {openItem?.menu?.parentId && (
                  <li className="breadcrumb-item">
                    {
                      menuAll.find((menu) => {
                        return menu.menuId === openItem?.menu?.parentId;
                      })?.menu?.menuName
                    }
                  </li>
                )}
                {openItem?.menu?.menuName && (
                  <li className="breadcrumb-item current">
                    {openItem?.menu?.menuName}
                  </li>
                )}
              </ul>
            </div>
            <FunctionButton
              onClickAdd={onClickAdd}
              onClickDelete={onClickDelete}
            />
          </div>

          {/* 페이지 메인그리드 */}
          <BillingMaster
            onFocusedRowChanged={onFocusedRowChanged}
            dataGridRef={dataGridRef}
          />
        </div>

        {/* 그리드 인풋 너비 고정 */}
        <div className="column-right fixed-right">
          <form
            onSubmit={handleSubmit(onSubmit, onError)}
            className="form-container column-input"
          >
            <div className="form-tit">업체 등록</div>
            <div className="form-row">
              <InputText
                control={control}
                errors={errors}
                name="code"
                label="업체코드"
                required={true}
                disabled={true}
              />
              <InputText
                control={control}
                errors={errors}
                name="companyName"
                label="회사명"
                required={true}
              />
            </div>
            <div className="form-row row-button">
              <InputText
                control={control}
                errors={errors}
                name="Biz_No"
                label="사업자번호"
                mask="999-99-99999"
                required={true}
                disabled={true}
              />
              <Button
                onClick={() => setSelectModal(true)}
                className="button-dis"
                icon="find"
              ></Button>
            </div>
            <InputText
              control={control}
              errors={errors}
              name="Ceo_Name"
              label="대표자명"
              disabled={true}
            />
            <InputText
              control={control}
              errors={errors}
              name="Address"
              label="주소"
              disabled={true}
            />
            <InputText
              control={control}
              errors={errors}
              name="Address_Detail"
              label="상세주소"
              disabled={true}
            />
            <div className="form-row">
              <InputText
                control={control}
                errors={errors}
                name="Biz_Type"
                label="업태"
                disabled={true}
              />
              <InputText
                control={control}
                errors={errors}
                name="Biz_Item"
                label="종목"
                disabled={true}
              />
            </div>
            <div className="form-row">
              <InputText
                control={control}
                errors={errors}
                name="Tel"
                label="전화번호"
                disabled={true}
              />
              <InputText
                control={control}
                errors={errors}
                name="Fax"
                label="팩스"
                disabled={true}
              />
            </div>
            <InputText
              control={control}
              errors={errors}
              name="Email"
              label="메일주소"
              disabled={true}
            />
            <div className="form-row">
              <InputCheckBox
                control={control}
                errors={errors}
                name="isShuttleDepot"
                label="셔틀전용"
                text="셔틀배차 창고 여부"
              />
              <InputCheckBox
                control={control}
                errors={errors}
                name="isBilling"
                label="업체"
                text="업체"
              />
            </div>
            <div className="form-row">
              <InputCheckBox
                control={control}
                errors={errors}
                name="isOrderCo"
                label="발주"
                text="발주처"
              />
              <InputCheckBox
                control={control}
                errors={errors}
                name="isCargoOwner"
                label="화주"
                text="화주"
              />
            </div>
            <InputCheckBox
              control={control}
              errors={errors}
              name="isYongchasa"
              label="용차사"
              text="용차사"
            />

            <InputText
              control={control}
              errors={errors}
              name="remark"
              label="비고"
            />

            <button type="submit" className="btn btn-form-save">
              저장
            </button>
          </form>
        </div>
      </div>
      {selectModal && (
        <SelectModal
          onClose={closeModal}
          closable={true}
          visible={true}
          maskClosable={false}
          onSelect={onSelect}
          param="/Sys_CompanyTax_Info"
          keyExpr="Biz_No"
        />
      )}
    </>
  );
}

export default BillingPage;
