import axios from "axios";

const apiUrl = `${process.env.REACT_APP_API_URL}/manager`;

export function createManager(data) {
  return axios.post(apiUrl, data);
}

export function updateManager(key, data) {
  return axios.put(apiUrl, { key, data });
}

export function deleteRow(key) {
  return axios.delete(apiUrl + `/${key}`);
}
