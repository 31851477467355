import React, {
  createContext,
  Suspense,
  useEffect,
  useRef,
  useState,
} from "react";
import { Redirect, Switch } from "react-router-dom";
import { ContentRoute, LayoutSplashScreen } from "../_metronic/layout";
import { BuilderPage } from "./pages/BuilderPage";
import { DashboardPage } from "./pages/DashboardPage";
import { MyPage } from "./pages/MyPage";
import SystemPage from "./pages/System/SystemPage";
import TestPage from "./pages/Test";

// 새로 추가
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import * as actions from "../_metronic/layout/components/aside/aside-menu/_redux/menuActions";

import useDynTabs from "react-dyn-tabs";
import "react-dyn-tabs/style/react-dyn-tabs.css";
import "react-dyn-tabs/themes/react-dyn-tabs-card.css";
import { getMenu } from "../_metronic/layout/components/aside/aside-menu/_redux/menuList";
import { PanelWrapper } from "./components/Layout/Panel/panel.style";
import { TabListWrapper } from "./components/Layout/TabList/tabList.style";
import { Home } from "./pages/Home";
import { useReducer } from "react";

const reducerActions = {
  getDatagrid: "GET_DATAGRID_REF",
};

const initialState = [];

function reducer(state, action) {
  switch (action.type) {
    case reducerActions.getDatagrid:
      const returnArr = [...state].filter((element) => {
        return element.current !== undefined && element.current !== null;
      });

      if (action.datagrid) {
        // 중복검사
        returnArr.push(action.datagrid);
      }
      if (action.datagrid2) {
        // 중복검사
        returnArr.push(action.datagrid2);
      }
      return returnArr;
    default:
      return state;
  }
}

export const DataGridDispatch = createContext(null);

export default function BasePage() {
  const tabsRef = useRef();
  const [, gridDispatch] = useReducer(reducer, initialState);

  // useEffect(() => {
  //   console.log(state, "gridstate");
  // }, [state]);
  // 새로 추가
  const dispatch = useDispatch();
  const [chagedItemId, setChagedItemId] = useState({});
  // const [isChecked, setIsChecked] = useState(false);
  const { selectedItem, menuAll, openItem } = useSelector(
    (state) => ({
      selectedItem: state.menu.selectedItem,
      menuItems: state.menu.menuItems,
      menuAll: state.menu.menuAll,
      openItem: state.menu.openItem,
    }),
    shallowEqual
  );

  const homeTab = {
    className: "home-tab",
    id: "home",
    disable: false,
    iconClass: "fa fa-home",
    closable: false,
    panelComponent: (porps) => <Home />,
  };

  const options = {
    tabs: [homeTab],
    selectedTabID: "home",
    onChange: function(e) {
      setChagedItemId(e.currentData.selectedTabID);
    },
    tabComponent: (props) => {
      // const { id, isSelected, api: instance } = props;
      return (
        <>
          {/* 북마크 아이콘 해제 */}
          {/* <FormControlLabel
            className={`bookmark ${isChecked ? "marked" : ""}`}
            control={
              <Checkbox
                icon={<BookmarkBorderOutlined />}
                checkedIcon={<BookmarkOutlined />}
              />
            }
          ></FormControlLabel> */}
          <button {...props.tabProps}>
            {props.children}
            {props.iconProps && <span {...props.iconProps}></span>}
          </button>
        </>
      );
    },
  };

  const [Tablist, Panellist, ready] = useDynTabs(options);
  let _instance = null;

  ready((instance) => {
    _instance = instance;
  });

  useEffect(() => {
    if (openItem !== {} && openItem.menuId) {
      console.log(openItem.menuId);
      let menu = getMenu(openItem.menuId);
      if (menu) {
        menu.title = openItem.menuName;
      } else {
        menu = {
          id: openItem.menuId,
          title: "NotFound",
          panelComponent: (porps) => {
            return <>페이지가 없습니다.</>;
          },
        };
      }

      _instance.open(menu).then(() => {});
    }
    // eslint-disable-next-line
  }, [openItem]);

  useEffect(() => {
    if (selectedItem?.menuId) {
      // // context API ref 불러서 close
      // for (const obj of state) {
      //   obj?.current?.instance && obj.current.instance.hideColumnChooser();
      // }

      _instance.select(selectedItem.menuId).then((data) => {
        const tabs = tabsRef.current.firstChild;
        let currentIndex = 0;
        tabs.childNodes.forEach((tab, i) => {
          if (tab.getAttribute("aria-selected") === "true") {
            currentIndex = i;
          }
        });
        tabsRef.current.firstChild.scroll(currentIndex * 140 - 100, 0);
      });
    }
    // eslint-disable-next-line
  }, [selectedItem]);

  useEffect(() => {
    if (!chagedItemId) {
      dispatch(actions.setSelectedItem());
    }
    if (
      chagedItemId &&
      menuAll.length > 0 &&
      selectedItem?.menuId !== chagedItemId
    ) {
      const item = menuAll.filter((obj) => obj.menuId === chagedItemId) || [];
      dispatch(actions.setSelectedItem(item[0]));
    }
    // eslint-disable-next-line
  }, [chagedItemId]);

  return (
    <>
      <DataGridDispatch.Provider value={{ dispatch: gridDispatch }}>
        <Suspense fallback={<LayoutSplashScreen />}>
          <TabListWrapper ref={tabsRef}>
            <Tablist></Tablist>
          </TabListWrapper>
          <Switch>
            {
              /* Redirect from root URL to /dashboard. */
              <Redirect exact from="/" to="/dashboard" />
            }
            <ContentRoute path="/dashboard" component={DashboardPage} />
            <ContentRoute path="/system" component={SystemPage} />
            <ContentRoute path="/test1" component={TestPage} />
            <ContentRoute path="/builder" component={BuilderPage} />
            <ContentRoute path="/my-page" component={MyPage} />

            <Redirect to="error/error-v1" />
          </Switch>

          <PanelWrapper>
            <Panellist></Panellist>
          </PanelWrapper>
        </Suspense>
      </DataGridDispatch.Provider>
    </>
  );
}
