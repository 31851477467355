import { DataGrid } from "devextreme-react";
import {
  Column,
  ColumnChooser,
  FilterRow,
  HeaderFilter,
  Scrolling,
  StateStoring,
} from "devextreme-react/data-grid";
import CustomStore from "devextreme/data/custom_store";
import moment from "moment";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { shallowEqual, useSelector } from "react-redux";
import { sendRequest } from "../../../../_metronic/_helpers/DataSoureHelpers";

import { useCallback } from "react";
import CustomButton from "../../../components/FunctionButton/CustomButton";
import HeaderTitle from "../../../components/Layout/Header/HeaderTitle";
import {
  DataCount,
  PanelGridInit,
  PanelGridSearchBar,
  PanelGridWrapper,
} from "../../../components/Layout/Panel/PanelGrid/panelGrid.style";
import {
  PanelBody,
  PanelHeader,
  PanelInit,
} from "../../../components/Layout/Panel/panel.style";
import StateStoringSaveAndDeleteButton from "../../../components/StateStoring";
import { useDatagrid } from "../../../hooks/useDatagrid";
import { getLookupDatas } from "../Dispatch/DispatchCrud";

function ChassisRefPage(props) {
  const { handleSubmit, reset } = useForm();

  // useState
  const [totalCount, setTotalCount] = useState(0);
  const [search, setSearch] = useState({});
  const dataGridRef = useRef();

  const [lookupData, setLookupData] = useState({
    color: [],
  });

  useDatagrid(dataGridRef);

  const storageKey = "chassisRefMaster";

  // redux
  const { user } = useSelector(
    (state) => ({ user: state.auth.user.user }),
    shallowEqual
  );

  // useEffect
  useEffect(() => {
    initLookup();
    reset({
      firstDay: moment().format("YYYY-MM-DD"),
      lastDay: moment().format("YYYY-MM-DD"),
      authGroupId: user.authGroupId,
      dispatchTeam: user.deptCode_department?.deptName,
    });

    setSearch({
      firstDay: moment().format("YYYY-MM-DD"),
      lastDay: moment().format("YYYY-MM-DD"),
      authGroupId: user.authGroupId,
      dispatchTeam: user.deptCode_department?.deptName,
    });
    // eslint-disable-next-line
  }, []);

  // dataSource
  const dataSource = useMemo(() => {
    const apiUrl = `${process.env.REACT_APP_API_URL}/chassisRef`;

    return new CustomStore({
      key: "chassisNo",
      load: () => sendRequest(apiUrl, "GET", search),
    });
  }, [search]);

  const onSubmit = (data) => {
    for (const obj in data) {
      if (data[obj] === "") {
        data[obj] = null;
      }
    }
    setSearch(data);
  };

  // DataGrid 함수
  const onCellPrepared = useCallback(
    (e) => {
      if (e.rowType !== "data") return;
      if (e.column.caption !== "샤시종류") return;

      if (e.column.caption === "샤시종류" && e.data.chassisType === "라인") {
        const green = lookupData.color.filter(
          (cur) => cur.subCode === "CLOR-GREEN"
        )[0];

        e.cellElement.style.background = green?.extra1 || "transparant";
      }
    },
    [lookupData]
  );

  const importExportRender = useCallback((e) => {
    if (e.row.data.importExport === 1) {
      return <div>수입</div>;
    } else if (e.row.data.importExport === 2) {
      return <div>수출</div>;
    } else {
      return <div></div>;
    }
  }, []);

  const initLookup = async () => {
    try {
      const [colorData] = await Promise.all([
        getLookupDatas("comcode/detail/10900"),
      ]);

      setLookupData({
        color: colorData.data,
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <PanelInit>
        <PanelHeader>
          <HeaderTitle />
        </PanelHeader>

        <PanelBody row left="100%" right="0">
          <PanelGridWrapper>
            <PanelGridSearchBar onSubmit={handleSubmit(onSubmit)}>
              {/* <span className="search-group">
                <label>기준일자</label>
                <InputDate control={control} errors={errors} name="firstDay" flex={true} />
                <div className="to-mark">~</div>
                <InputDate control={control} errors={errors} name="lastDay" flex={true} />
              </span> */}
              <CustomButton
                type="submit"
                height={24}
                layout="solid"
                color="search"
                label="검색"
              />
            </PanelGridSearchBar>

            <PanelGridInit>
              <StateStoringSaveAndDeleteButton
                dataGridRef={dataGridRef}
                storageKey={storageKey}
              />
              <DataCount>{`총 ${totalCount}건`}</DataCount>
              <DataGrid
                className="datagrid-wrap"
                id="DispatchMaster"
                dataSource={dataSource}
                ref={dataGridRef}
                showRowLines={true}
                showBorders={true}
                hoverStateEnabled={true}
                allowColumnResizing={true}
                allowColumnReordering={true}
                columnAutoWidth={true}
                columnResizingMode="widget"
                focusedRowEnabled={true}
                onCellPrepared={onCellPrepared}
                onContentReady={(e) => setTotalCount(e.component.totalCount())}
              >
                <FilterRow visible={true} />
                <HeaderFilter visible={true} />
                <ColumnChooser enabled={true} />
                <StateStoring
                  enabled={true}
                  type="custom"
                  customLoad={useCallback(() => {
                    return JSON.parse(localStorage.getItem(storageKey));
                  }, [])}
                />
                <Scrolling mode="virtual" />
                <Column
                  caption="No"
                  cellRender={(e) => e.row.loadIndex + 1}
                  alignment="right"
                />
                <Column
                  dataField="chassisType"
                  caption="샤시종류"
                  alignment="center"
                />
                <Column
                  dataField="chassisNo"
                  caption="샤시번호"
                  alignment="center"
                />
                <Column
                  dataField="curLocation"
                  caption="현재위치"
                  alignment="center"
                />
                <Column
                  dataField="forwarder"
                  caption="선사"
                  alignment="center"
                />
                <Column
                  dataField="conType"
                  caption="컨규격"
                  alignment="center"
                />
                <Column
                  dataField="deliverDate"
                  caption="운송일"
                  alignment="center"
                />
                <Column
                  dataField="importExport"
                  caption="수출/수입"
                  alignment="center"
                  cellRender={importExportRender}
                />
              </DataGrid>
            </PanelGridInit>
          </PanelGridWrapper>
        </PanelBody>
      </PanelInit>
    </>
  );
}

export default ChassisRefPage;
