import { DataGrid } from "devextreme-react";
import {
  Column,
  ColumnChooser,
  FilterRow,
  HeaderFilter,
  Scrolling,
  StateStoring,
} from "devextreme-react/data-grid";
import CustomStore from "devextreme/data/custom_store";
import React, { useCallback, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { sendRequest } from "../../../../_metronic/_helpers/DataSoureHelpers";
import CustomButton from "../../../components/FunctionButton/CustomButton";
import {
  DataCount,
  PanelGridInit,
  PanelGridSearchBar,
  PanelGridWrapper,
  SearchItems,
} from "../../../components/Layout/Panel/PanelGrid/panelGrid.style";
import StateStoringSaveAndDeleteButton from "../../../components/StateStoring";

function TerminalMaster({
  onFocusedRowChanged,
  dataGridRef,
  areaList,
  divisionList,
}) {
  const [totalCount, setTotalCount] = useState(0);
  const [search, setSearch] = useState({});
  const storageKey = "terminalMaster";

  const dataSource = useMemo(() => {
    const apiUrl = `${process.env.REACT_APP_API_URL}/terminal`;

    return new CustomStore({
      key: "code",
      load: () => sendRequest(apiUrl, "GET", search),
      insert: (values) => sendRequest(apiUrl, "POST", values),
      update: (key, values) => sendRequest(apiUrl, "PUT", { key, values }),
      remove: (key) => sendRequest(apiUrl, "DELETE", { key }),
    });
  }, [search]);

  const { register, handleSubmit } = useForm();

  const onSubmit = (data) => {
    for (const obj in data) {
      if (data[obj] === "") {
        data[obj] = null;
      }
    }
    setSearch(data);
  };

  const onCellPrepared = useCallback((e) => {
    if (e.rowType !== "data") return;
    if (e.column.caption !== "터미널코드") return;

    e.cellElement.style.background = e.data.color || "transparant";
  }, []);

  return (
    <>
      <PanelGridWrapper>
        <PanelGridSearchBar onSubmit={handleSubmit(onSubmit)}>
          <SearchItems>
            <label>이름</label>
            <input type="text" {...register("twName")} />
          </SearchItems>
          <CustomButton
            type="submit"
            height={24}
            layout="solid"
            color="search"
            label="검색"
          />
        </PanelGridSearchBar>

        <PanelGridInit>
          <StateStoringSaveAndDeleteButton
            dataGridRef={dataGridRef}
            storageKey={storageKey}
          />
          <DataCount>{`총 ${totalCount}건`}</DataCount>
          <DataGrid
            id="TerminalMaster"
            className="datagrid-wrap"
            ref={dataGridRef}
            dataSource={dataSource}
            showRowLines={true}
            showBorders={true}
            hoverStateEnabled={true}
            allowColumnResizing={true}
            allowColumnReordering={true}
            columnResizingMode="widget"
            keyExpr="code"
            focusedRowEnabled={true}
            onCellPrepared={onCellPrepared}
            onFocusedRowChanged={onFocusedRowChanged}
            onContentReady={useCallback(
              (e) => setTotalCount(e.component.totalCount()),
              []
            )}
          >
            <FilterRow visible={true} />
            <HeaderFilter visible={true} />
            <ColumnChooser enabled={true} />
            <StateStoring
              enabled={true}
              type="custom"
              customLoad={useCallback(() => {
                return JSON.parse(localStorage.getItem(storageKey));
              }, [])}
            />
            <Scrolling mode="virtual" />
            <Column dataField="code" caption="터미널코드" alignment="center" />
            <Column
              dataField="division"
              caption="구분"
              alignment="center"
              calculateCellValue={useCallback(
                (e) =>
                  divisionList.filter((obj) => obj.id === e.division)[0]
                    ?.subName,
                [divisionList]
              )}
            />
            <Column dataField="twName" caption="이름" />
            <Column
              dataField="area"
              caption="지역"
              alignment="center"
              calculateCellValue={useCallback(
                (e) => areaList.filter((obj) => obj.id === e.area)[0]?.subName,
                [areaList]
              )}
            />
            <Column dataField="tel" caption="대표번호" />
            <Column dataField="fax" caption="팩스번호" />
            <Column dataField="address" caption="주소" />
            <Column dataField="address1" caption="상세주소" />
            <Column dataField="remark" caption="비고" alignment="center" />
          </DataGrid>
        </PanelGridInit>
      </PanelGridWrapper>
    </>
  );
}

export default TerminalMaster;
