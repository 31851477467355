import styled from "styled-components";

export const PanelWrapper = styled.div`
  overflow: hidden;
  max-height: calc(100vh - 70px);
  background: ${(props) => props.theme.colors.white};

  .rc-dyn-tabs-panellist {
    padding: 0;
    height: calc(100vh - 70px);

    .rc-dyn-tabs-panel {
      height: 100%;
    }
  }
`;

export const PanelInit = styled.div`
  display: flex;
  flex-direction: column;
`;

export const PanelHeader = styled.div`
  display: flex;
  min-height: 35px;
  padding: 5px 10px;
  border-bottom: 1px solid ${(props) => props.theme.colors.layoutBorder};
  justify-content: space-between;
  align-items: center;
`;

export const PanelBody = styled.div`
  display: grid;
  width: 100%;
  max-width: calc(100vw - 40px);
  min-width: calc(100vw - 260px);
  height: calc(100vh - 107px);
  grid-template-columns: ${(props) =>
    props.openInputBar === false
      ? "0 100%"
      : props.row
      ? props.left + ` ` + props.right
      : "0 100%"};
  grid-template-rows: ${(props) =>
    props.openInputBar === false
      ? "100% 0"
      : props.column
      ? props.top + ` ` + props.bottom
      : "100% 0"};
  transition: all 0.3s ease-in-out;
`;

export const PanelTitle = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;

  div {
    font-size: 1.4rem;
    font-weight: 600;
  }

  ul {
    display: flex;
    align-items: center;

    li {
      color: ${(props) => props.theme.colors.subHeaderTxt};
      font-size: 1.2rem;
      padding-left: 0.3rem;

      & + li {
        &::before {
          content: "•";
          padding-right: 0.3rem;
          color: ${(props) => props.theme.colors.subHeaderDotTxt};
        }
      }

      &:last-of-type {
        color: ${(props) => props.theme.colors.third};
      }

      i {
        transition: color 0.3s ease-in-out;

        &:hover {
          color: ${(props) => props.theme.colors.third};
          transition: color 0.3s ease;
        }
      }
    }
  }
`;

export const PanelBtn = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  z-index: 1;
`;
