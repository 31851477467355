import React from "react";
import { shallowEqual, useSelector } from "react-redux";

export function Home() {
  const { openItem } = useSelector(
    (state) => ({
      menuAll: state.menu.menuAll,
      openItem: state.menu.openItem,
    }),
    shallowEqual
  );

  return (
    <div className="dashboard-wrap page-wrap">
      <div className="column-left full-size">
        <div className="panel-header">
          {!openItem?.menu?.menuName && (
            <div className="panel-header-init">
              <div className="panel-header-tit">홈</div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
