import { DataGrid } from "devextreme-react";
import {
  Column,
  ColumnChooser,
  FilterRow,
  HeaderFilter,
  Scrolling,
} from "devextreme-react/data-grid";
import CustomStore from "devextreme/data/custom_store";
import React, { useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { sendRequest } from "../../../../_metronic/_helpers/DataSoureHelpers";
import CustomButton from "../../../components/FunctionButton/CustomButton";
import {
  DataCount,
  PanelGridInit,
  PanelGridSearchBar,
  PanelGridWrapper,
  SearchItems,
} from "../../../components/Layout/Panel/PanelGrid/panelGrid.style";

function ForwarderMaster({ onFocusedRowChanged, dataGridRef }) {
  const [totalCount, setTotalCount] = useState(0);
  const [search, setSearch] = useState({});
  const dataSource = useMemo(() => {
    const apiUrl = `${process.env.REACT_APP_API_URL}/forwarder`;

    return new CustomStore({
      key: "code",
      load: () => sendRequest(apiUrl, "GET", search),
      insert: (values) => sendRequest(apiUrl, "POST", values),
      update: (key, values) => sendRequest(apiUrl, "PUT", { key, values }),
      remove: (key) => sendRequest(apiUrl, "DELETE", { key }),
    });
  }, [search]);

  const { register, handleSubmit } = useForm();

  const onSubmit = (data) => {
    for (const obj in data) {
      if (data[obj] === "") {
        data[obj] = null;
      }
    }
    setSearch(data);
  };

  return (
    <>
      <PanelGridWrapper>
        <PanelGridSearchBar onSubmit={handleSubmit(onSubmit)}>
          <SearchItems>
            <label>선사명(한국어)</label>
            <input type="text" {...register("forwarderNameKo")} />
          </SearchItems>
          <CustomButton
            type="submit"
            height={24}
            layout="solid"
            color="search"
            label="검색"
          />
        </PanelGridSearchBar>

        <PanelGridInit>
          <DataCount>{`총 ${totalCount}건`}</DataCount>
          <DataGrid
            className="datagrid-wrap"
            id="ForwarderMaster"
            ref={dataGridRef}
            dataSource={dataSource}
            showRowLines={true}
            showBorders={true}
            hoverStateEnabled={true}
            allowColumnResizing={true}
            // allowColumnReordering={true}
            columnResizingMode="widget"
            keyExpr="code"
            focusedRowEnabled={true}
            onFocusedRowChanged={onFocusedRowChanged}
            onContentReady={(e) => setTotalCount(e.component.totalCount())}
          >
            <ColumnChooser enabled={true} />
            {/* <StateStoring
            enabled={true}
            type="localStorage"
            storageKey="forwarderStorage"
          /> */}
            <FilterRow visible={true} />
            <Scrolling mode="virtual" />
            <HeaderFilter visible={true} />
            <Column dataField="code" caption="코드" alignment="center" />
            <Column dataField="forwarderNameEn" caption="선사명(영어)" />
            <Column dataField="forwarderNameKo" caption="선사명(한국어)" />
            <Column dataField="remark" caption="비고" alignment="center" />
          </DataGrid>
        </PanelGridInit>
      </PanelGridWrapper>
    </>
  );
}

export default ForwarderMaster;
