export default function setupAxios(axios, store) {
  axios.interceptors.request.use(
    (config) => {
      const ip = localStorage.getItem("userIP");
      const {
        auth: { authToken, user },
      } = store.getState();

      if (authToken) {
        config.headers.Authorization = `Bearer ${authToken}`;
        if (user) {
          config.headers.ip = ip;
          config.headers.userId = user.user.id;
        }
      }

      return config;
    },
    (err) => Promise.reject(err)
  );
}
