import React from "react";
import {
  InputDate,
  InputNumber,
  InputNumberMinus,
  InputRadioGroup,
  InputSelect,
  InputText,
} from "../../../../components/InputForm";
import InputLookup from "../../../../components/InputForm/InputLookup";
import {
  InputDivide,
  InputFullRow,
  InputRadioSet,
  PanelInput,
} from "../../../../components/Layout/Panel/PanelInput/panelInput.style";

const DispatchNewInputList = ({
  index,
  name,
  label,
  useForm,
  lookupData,
  disabled,
  onClick,
}) => {
  const key = index + 1;
  const {
    watch,
    control,
    formState: { errors },
  } = useForm;

  return (
    <>
      <PanelInput grid={4}>
        {name === "loadPlace" && (
          <InputLookup
            control={control}
            errors={errors}
            name={name}
            label={label}
            dataSource={lookupData.terminal}
            valueExpr="code"
            displayExpr="twName"
            required={true}
          />
        )}
        {name !== "loadPlace" && name !== "workPlace" && (
          <InputLookup
            control={control}
            errors={errors}
            name={name}
            label={label}
            dataSource={lookupData.waypoint}
            valueExpr="code"
            displayExpr="twName"
          />
        )}

        {name === "workPlace" && (
          <InputLookup
            control={control}
            errors={errors}
            name="workPlace"
            label="작업지"
            dataSource={lookupData.workplace}
            valueExpr="code"
            displayExpr="twName"
          />
        )}
        <InputSelect
          searchEnabled={true}
          control={control}
          errors={errors}
          name={`dispatchStatus_0${key}`}
          label="상태"
          labelWidth="45px"
          dataSource={lookupData.status}
          valueExpr="id"
          displayExpr="subName"
        />
        <InputDate
          control={control}
          errors={errors}
          name={`deliverDate_0${key}`}
          label="운송일자"
          labelWidth="45px"
        />
        <InputNumberMinus
          control={control}
          errors={errors}
          name={`Habul_0${key}`}
          label="하불금액"
          labelWidth="45px"
          disabled={watch(`completeNo_0${key}`)}
        />
      </PanelInput>
      <PanelInput grid={5}>
        <InputFullRow grid={2}>
          <InputRadioSet width="full">
            <InputRadioGroup
              control={control}
              errors={errors}
              name={`toggle_0${key}`}
              dataSource={[
                { key: 1, value: "차량번호" },
                { key: 2, value: "용차사" },
              ]}
              valueExpr="key"
              displayExpr="value"
              disable={watch(`completeNo_0${key}`)}
            />
          </InputRadioSet>
        </InputFullRow>

        {watch(`toggle_0${key}`) === 1 && (
          <InputLookup
            control={control}
            errors={errors}
            name={`carNo_0${key}`}
            label="배차차량"
            labelWidth="45px"
            dataSource={lookupData.car}
            valueExpr="oid"
            displayExpr="carNo_Name"
            disable={
              watch(`toggle_0${key}`) === 2 || watch(`completeNo_0${key}`)
            }
          />
        )}
        {watch(`toggle_0${key}`) === 2 && (
          <>
            <InputLookup
              control={control}
              errors={errors}
              name={`yongchasa_0${key}`}
              label="용차사"
              labelWidth="45px"
              dataSource={lookupData.yongchasa}
              valueExpr="oid"
              displayExpr="company"
              disable={
                watch(`toggle_0${key}`) === 1 || watch(`completeNo_0${key}`)
              }
            />
            <InputText
              control={control}
              errors={errors}
              name={`yongchaCarNo_0${key}`}
              label="차량번호"
              labelWidth="45px"
              disabled={
                watch(`toggle_0${key}`) === 1 || watch(`completeNo_0${key}`)
              }
            />
          </>
        )}
        {/* <CustomButton
          type="button"
          width="100%"
          height={36}
          layout="solid"
          color="common"
          label="파일첨부"
          disabled={disabled}
          onClick={() => {
            onClick(index, name);
          }}
        /> */}
      </PanelInput>
      <InputFullRow grid={5}>
        <InputDivide />
      </InputFullRow>
    </>
  );
};

export default DispatchNewInputList;
