import React from "react";
import { InputErrMsg, InputInit, InputWrapper } from "./inputLayout.style";

export const InputLayout = ({
  children,
  label,
  labelWidth,
  required,
  errors,
  name,
  zipCode,
  address,
  address1,
  checkbox,
  radio,
  textArea,
  addressInput,
  comboSelectBox,
}) => {
  return (
    <InputWrapper
      label={label}
      checkbox={checkbox}
      address={address}
      radio={radio}
      textArea={textArea}
      labelWidth={labelWidth}
      addressInput={addressInput}
      comboSelectBox={comboSelectBox}
    >
      {label && (
        <label className={required ? "required" : "default"}>{label}</label>
      )}
      <InputInit>
        {children}
        {errors[name]?.message && (
          <InputErrMsg>{errors[name]?.message}</InputErrMsg>
        )}
        {errors[zipCode]?.message && (
          <InputErrMsg>{errors[zipCode]?.message}</InputErrMsg>
        )}
        {errors[address]?.message && (
          <InputErrMsg>{errors[address]?.message}</InputErrMsg>
        )}
        {errors[address1]?.message && (
          <InputErrMsg>{errors[address1]?.message}</InputErrMsg>
        )}
      </InputInit>
    </InputWrapper>
  );
};

export default InputLayout;
