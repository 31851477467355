import axios from "axios";

const apiUrl = `${process.env.REACT_APP_API_URL}`;
const paymentUrl = `${process.env.REACT_APP_API_URL}/paymentLedger`;
const paymentLawUrl = `${paymentUrl}/lawData`;
const paymentOwnerUrl = `${paymentUrl}/ownerData`;
const paymentDriverUrl = `${paymentUrl}/driverData`;

export function getLookup(param) {
  return axios.get(apiUrl + `/${param}`);
}

export function paymentLawData(lawData, userName) {
  return axios.post(`${paymentLawUrl}/${userName}`, lawData);
}

export function paymentOwnerData(ownerData, userName) {
  return axios.post(`${paymentOwnerUrl}/${userName}`, ownerData);
}

export function paymentDriverData(driverData, userName) {
  return axios.post(`${paymentDriverUrl}/${userName}`, driverData);
}
