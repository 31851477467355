import { DataGrid } from "devextreme-react";
import {
  Column,
  ColumnChooser,
  FilterRow,
  Format,
  HeaderFilter,
  Scrolling,
  StateStoring,
} from "devextreme-react/data-grid";
import React, { useCallback, useState } from "react";
import {
  DataCount,
  PanelGridInit,
} from "../../../../components/Layout/Panel/PanelGrid/panelGrid.style";
import StateStoringSaveAndDeleteButton from "../../../../components/StateStoring";

const CalculateProfitUpperGrid = ({ state, upperGridRef }) => {
  const [totalCount, setTotalCount] = useState(0);
  const storageKey = "calculateProfitUpper";

  const { upperDataSource } = state;
  return (
    <>
      <PanelGridInit gridItems column>
        <StateStoringSaveAndDeleteButton
          dataGridRef={upperGridRef}
          storageKey={storageKey}
        />
        <DataCount>{`총 ${totalCount}건`}</DataCount>
        <DataGrid
          className="datagrid-wrap"
          id={storageKey}
          dataSource={upperDataSource}
          ref={upperGridRef}
          showRowLines={true}
          showBorders={true}
          hoverStateEnabled={true}
          allowColumnResizing={true}
          allowColumnReordering={true}
          columnResizingMode="widget"
          keyExpr="id"
          focusedRowEnabled={true}
          onContentReady={(e) => setTotalCount(e.component.totalCount())}
        >
          <FilterRow visible={true} />
          <HeaderFilter visible={true} />
          <Scrolling
            columnRenderingMode="virtual"
            mode="virtual"
            showScrollbar="always"
            useNative="false"
          />
          <StateStoring
            enabled={true}
            type="custom"
            customLoad={useCallback(() => {
              return JSON.parse(localStorage.getItem(storageKey));
            }, [])}
          />
          <ColumnChooser enabled={true} />
          <Column dataField="receiveCoName" caption="접수법인" />
          <Column dataField="division" caption="구분" />
          <Column dataField="companyName" caption="청구처/용차사/차량번호" />
          <Column dataField="countTotal" caption="총건수" dataType="number" />
          <Column
            dataField="totalPriceBilling"
            caption="매출액"
            dataType="number"
          >
            <Format type="fixedPoint" />
          </Column>
          <Column
            dataField="totalPriceOther"
            caption="매입액"
            dataType="number"
          >
            <Format type="fixedPoint" />
          </Column>
        </DataGrid>
      </PanelGridInit>
    </>
  );
};

export default CalculateProfitUpperGrid;
