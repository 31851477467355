import React from "react";

import CustomButton from "../../../components/FunctionButton/CustomButton";
import HeaderTitle from "../../../components/Layout/Header/HeaderTitle";
import {
  PanelBody,
  PanelBtn,
  PanelHeader,
  PanelInit,
} from "../../../components/Layout/Panel/panel.style";
import EtcCostInput from "./components/EtcCostInput";
import EtcCostMaster from "./components/EtcCostMaster";
import useEtcCost from "./useEtcCost";

const EtcCostPage = () => {
  const { onClickAdd, onClickDelete, inputProps, gridProps } = useEtcCost();

  return (
    <>
      <PanelInit>
        <PanelHeader>
          <HeaderTitle />
          <PanelBtn>
            <CustomButton
              type="button"
              height={24}
              layout="solid"
              color="new"
              label="신규"
              onClick={onClickAdd}
            />
            <CustomButton
              type="button"
              height={24}
              layout="solid"
              color="esc"
              onClick={onClickDelete}
            >
              삭제
            </CustomButton>
          </PanelBtn>
        </PanelHeader>
        <PanelBody row left="400px" right="calc(100% - 400px)">
          {/* 왼쪽 사이드 섹션 */}
          <EtcCostInput inputProps={inputProps} />
          <EtcCostMaster gridProps={gridProps} />
        </PanelBody>
      </PanelInit>
    </>
  );
};

export default EtcCostPage;
