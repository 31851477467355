import { DataGrid } from "devextreme-react";
import {
  Column,
  ColumnChooser,
  FilterRow,
  HeaderFilter,
  MasterDetail,
  Scrolling,
} from "devextreme-react/data-grid";
import CustomStore from "devextreme/data/custom_store";
import moment from "moment";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { shallowEqual, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { sendRequest } from "../../../../_metronic/_helpers/DataSoureHelpers";

import InputCheckBox from "../../../components/InputForm/InputCheckBox";
import InputDate from "../../../components/InputForm/InputDate";
import InputSelect from "../../../components/InputForm/InputSelect";
import OrderSummaryDetail from "./OrderSummaryDetail";

function OrderSummary(props) {
  const initialState = {
    firstDay: moment()
      .startOf("month")
      .format("YYYY-MM-DD"),
    lastDay: moment()
      .endOf("month")
      .format("YYYY-MM-DD"),
    longStorage: false,
  };
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const { menuAll, openItem } = useSelector(
    (state) => ({
      menuAll: state.menu.menuAll,
      openItem: state.menu.openItem,
    }),
    shallowEqual
  );

  const [totalCount, setTotalCount] = useState(0);
  const [search, setSearch] = useState(initialState);
  const [focusedComponent, setFocusedComponent] = useState();
  const dataGridRef = useRef();
  const detailGridRef = useRef();

  const dataSource = useMemo(() => {
    const apiUrl = `${process.env.REACT_APP_API_URL}/order/summaryMaster`;

    return new CustomStore({
      key: "code",
      load: () => sendRequest(apiUrl, "GET", search),
    });
  }, [search]);

  useEffect(() => {
    reset(initialState);
    setSearch(initialState);
    // eslint-disable-next-line
  }, []);

  const onSubmit = (data) => {
    for (const obj in data) {
      if (data[obj] === "") {
        data[obj] = null;
      }
    }

    // const submit = {
    //   ...data,
    //   authGroupId: user.authGroupId,
    //   dispatchTeam: user.deptCode_department.code,
    // }

    setSearch(data);
  };

  const onRowDblClick = (e) => {
    if (e.isExpanded) {
      e.component.collapseRow(e.key);
    } else {
      e.component.expandRow(e.key);
    }
  };

  const onCellClick = (e) => {
    if (!e.row) return;
    // detailGridRef.current && detailGridRef.current.instance.option("focusedRowIndex", -1);
    const row = e.row;
    if (row.rowType === "data") {
    }
  };

  const onFocusedRowChanged = (e) => {
    console.log(e);
    if (e.rowIndex === -1) return;
    // eslint-disable-next-line
    if (focusedComponent && focusedComponent != e.component) {
      focusedComponent.option("focusedRowIndex", -1);
      if (e.element.id === "dispatchMaster") {
      } else if (e.element.id === "dispatchDetail") {
      }
    }
    setFocusedComponent(e.component);
  };

  // const freightCellRender = async ({ data }) => {
  //   try {
  //     console.log(data);
  //     const result = await requestServer.getSummaryDetail({
  //       receiveCo: data.receiveCo,
  //       billingPlace: data.billingPlace,
  //     });

  //     console.log(result);
  //     return <div>test</div>;
  //   } catch (err) {
  //     console.log(err);
  //   }
  // };

  const priceCellRender = (e) => {
    if (!e.text) return <div>0</div>;
    return <div>{e.text.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</div>;
  };

  return (
    <>
      <div className="page-wrap">
        <div className="column-left full-size">
          {/* 페이지 헤더 */}
          <div className="panel-header flex-row">
            <div className="panel-header-init">
              <div className="panel-header-tit">{openItem?.menu?.menuName}</div>
              <ul className="breadcrumb-list">
                <li className="breadcrumb-item">
                  <Link to="/">
                    <i className="flaticon2-shelter icon-1x" />
                  </Link>
                </li>
                {openItem?.menu?.parentId && (
                  <li className="breadcrumb-item">
                    {
                      menuAll.find((menu) => {
                        return menu.menuId === openItem?.menu?.parentId;
                      })?.menu?.menuName
                    }
                  </li>
                )}
                {openItem?.menu?.menuName && (
                  <li className="breadcrumb-item current">
                    {openItem?.menu?.menuName}
                  </li>
                )}
              </ul>
            </div>

            {/* 기본 btn set */}
            <div className="d-flex align-items-center button-group"></div>
          </div>

          {/* 페이지 메인그리드 검색 */}
          <div className="grid-search-wrap">
            <form onSubmit={handleSubmit(onSubmit)}>
              <span className="search-group">
                <div className="dx-field">
                  <InputSelect
                    className="label-select"
                    control={control}
                    errors={errors}
                    name="searchType"
                    displayExpr="name"
                    valueExpr="id"
                    dataSource={[
                      { id: 1, name: "접수일자" },
                      { id: 2, name: "운송요청일자" },
                      { id: 3, name: "작업지도착시간" },
                    ]}
                    flex={true}
                  />
                </div>
                <InputDate
                  control={control}
                  errors={errors}
                  name="firstDay"
                  flex={true}
                />
                <div className="to-mark">~</div>
                <InputDate
                  control={control}
                  errors={errors}
                  name="lastDay"
                  flex={true}
                />
                {/* <input type="text" {...register("managerName")} /> */}
              </span>
              <span className="search-group">
                <label>장기보관</label>
                <InputCheckBox
                  control={control}
                  errors={errors}
                  name="longStorage"
                />
              </span>
              <button type="submit" className="btn btn-grid-search">
                검색
              </button>
            </form>
          </div>

          {/* 기능 버튼 set */}
          {/* <div className="function-btn-wrap"></div> */}

          {/* 페이지 메인그리드 */}
          <div className="grid-component order-page has-toolbar">
            <div className="grid-total">{`총 ${totalCount}건`}</div>
            <DataGrid
              className="datagrid-wrap"
              id="DispatchMaster"
              dataSource={dataSource}
              ref={dataGridRef}
              showRowLines={true}
              showBorders={true}
              hoverStateEnabled={true}
              allowColumnResizing={true}
              allowColumnReordering={true}
              columnAutoWidth={true}
              columnResizingMode="widget"
              onRowDblClick={onRowDblClick}
              focusedRowEnabled={true}
              onCellClick={onCellClick}
              onFocusedRowChanged={onFocusedRowChanged}
              onContentReady={(e) => setTotalCount(e.component.totalCount())}
            >
              <FilterRow visible={true} />
              <HeaderFilter visible={true} />
              <ColumnChooser enabled={true} />
              <Scrolling
                columnRenderingMode="virtual"
                mode="virtual"
                showScrollbar="always"
                useNative="false"
              />
              <Column
                dataField="importExport"
                caption="수입/수출"
                cellRender={(e) => {
                  if (e.text === "1") return <div>수입</div>;
                  else return <div>수출</div>;
                }}
                alignment="center"
              />
              <Column
                dataField="receiveCoName"
                caption="접수법인"
                alignment="center"
              />
              <Column
                dataField="billingPlaceName"
                caption="청구처"
                alignment="center"
              />
              <Column
                dataField="freight"
                caption="청구금액"
                alignment="right"
                cellRender={priceCellRender}
              />
              <Column
                dataField="chargedAmount"
                caption="할증금액"
                alignment="right"
                cellRender={priceCellRender}
              />
              {/* <Column dataField="habul" caption="예상하불금액" alignment="right" cellRender={priceCellRender} /> */}
              <Column
                dataField="realHabul"
                caption="실제하불금액"
                alignment="right"
                cellRender={priceCellRender}
              />

              <MasterDetail
                enabled={true}
                render={(e) => (
                  <OrderSummaryDetail
                    search={search}
                    data={e.data}
                    dataGridRef={dataGridRef}
                    // setDetailRowData={setDetailRowData}
                    detailGridRef={detailGridRef}
                    onFocusedRowChanged={onFocusedRowChanged}
                  />
                )}
              />
            </DataGrid>
          </div>
        </div>
      </div>
    </>
  );
}

export default OrderSummary;
