import React from "react";
import {
  PanelGridBtnBar,
  PanelGridSearchBar,
  PanelGridTopBar,
  SearchItems,
} from "../../../../components/Layout/Panel/PanelGrid/panelGrid.style";
import CustomButton from "../../../../components/FunctionButton/CustomButton/CustomButton";
import { ReactComponent as IconEdit } from "../../../../../style/icons/edit-note.svg";
import {
  InputCheckBox,
  InputDate,
  InputSelect,
} from "../../../../components/InputForm";

const DispatchNewSearch = ({
  lookupData,
  searchForm,
  onToggleInputBar,
  onSubmit,
}) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = searchForm;
  return (
    <PanelGridTopBar>
      <PanelGridBtnBar>
        <CustomButton
          type="button"
          height={36}
          width="full"
          layout="icon"
          color="transp"
          onClick={onToggleInputBar}
        >
          <IconEdit />
        </CustomButton>
      </PanelGridBtnBar>
      <PanelGridSearchBar onSubmit={handleSubmit(onSubmit)}>
        <SearchItems>
          <label>접수법인</label>
          <InputSelect
            control={control}
            errors={errors}
            name="company"
            dataSource={lookupData.company}
            valueExpr="id"
            displayExpr="companyName"
            flex={true}
          />
        </SearchItems>
        <SearchItems>
          <label>배차팀</label>
          <InputSelect
            control={control}
            errors={errors}
            name="dispatchTeam"
            dataSource={lookupData.dispatch}
            valueExpr="code"
            displayExpr="deptName"
            flex={true}
          />
        </SearchItems>
        <SearchItems>
          <label>수출/수입</label>
          <InputSelect
            control={control}
            errors={errors}
            name="importExport"
            dataSource={[
              { id: "", name: "전체" },
              { id: 1, name: "수입" },
              { id: 2, name: "수출" },
            ]}
            valueExpr="id"
            displayExpr="name"
            flex={true}
          />
        </SearchItems>
        <SearchItems>
          <label>일정검색</label>
          <InputSelect
            className="label-select"
            control={control}
            errors={errors}
            name="searchType"
            displayExpr="name"
            valueExpr="id"
            dataSource={[
              { id: 2, name: "운송요청일자" },
              { id: 1, name: "접수일자" },
              { id: 3, name: "작업지도착시간" },
            ]}
            flex={true}
          />
          <InputDate
            control={control}
            errors={errors}
            name="firstDay"
            flex={true}
          />
          <div className="to-mark">~</div>
          <InputDate
            control={control}
            errors={errors}
            name="lastDay"
            flex={true}
          />
        </SearchItems>
        <SearchItems>
          <label>밥테일 여부</label>
          <InputSelect
            className="label-select"
            control={control}
            errors={errors}
            name="isBobtail"
            valueExpr="id"
            displayExpr="name"
            dataSource={[
              { id: 0, name: "전체" },
              { id: 1, name: "밥테일" },
              { id: 2, name: "밥테일제외" },
            ]}
            flex={true}
          />
        </SearchItems>

        <SearchItems>
          <InputCheckBox
            className="label-select"
            control={control}
            errors={errors}
            label="하불손실조회"
            name="isLoss"
          />
        </SearchItems>
        <CustomButton
          type="submit"
          height={24}
          layout="solid"
          color="search"
          label="검색"
        />
      </PanelGridSearchBar>
    </PanelGridTopBar>
  );
};

export default DispatchNewSearch;
