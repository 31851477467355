import React, { useState, useRef } from "react";
import CargoOwnerMaster from "./CargoOwnerMaster";
import * as requestServer from "./CargoOwnerCrud";
import { confirm, alert } from "devextreme/ui/dialog";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import InputText from "../../../components/InputForm/InputText";
import FunctionButton from "../../../components/FunctionButton/FunctionButton";

function CargoOwnerPage(props) {
  const initialState = {
    code: "",
    companyName: "",
    remark: "",
  };
  const [selectedRowKey, setSelectedRowKey] = useState();
  const [inputDisable, setInputDisable] = useState(false);
  const dataGridRef = useRef();

  const schema = yup
    .object({
      code: yup
        .string()
        .required("코드를 입력하세요.")
        .max(20, "최대 20자리 까지 입력 가능"),
      companyName: yup
        .string()
        .required("회사명을 입력하세요.")
        .max(50, "최대 50자리 까지 입력 가능"),
      remark: yup.string().max(100, "최대 100자리 까지 입력 가능"),
    })
    .required();

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onFocusedRowChanged = (e) => {
    console.log(e.row);
    if (!e.row) return;
    setSelectedRowKey(e.row.key);
    setInputDisable(true);
    reset({ ...e.row.data, remark: e.row.data.remark || undefined });
  };

  const onClickAdd = () => {
    if (!dataGridRef.current) return;
    reset(initialState);
    setSelectedRowKey(null);
    setInputDisable(false);
    dataGridRef.current.instance.option("focusedRowIndex", -1);
  };

  const onSubmit = async (data) => {
    delete data.id;
    delete data.password;
    delete data.createIP;
    delete data.createUserId;
    delete data.createdAt;
    delete data.updateIP;
    delete data.updateUserId;
    delete data.updatedAt;

    try {
      if (selectedRowKey) {
        await requestServer.updateCargoOwner(selectedRowKey, data);
      } else {
        await requestServer.createCargoOwner(data);
      }
      dataGridRef.current.instance.refresh();
      reset(initialState);
      setSelectedRowKey(null);
      dataGridRef.current.instance.option("focusedRowIndex", -1);
    } catch (err) {
      alert(err.response.data.message, "오류");
    }
  };

  const onClickDelete = () => {
    if (!dataGridRef.current || !selectedRowKey) return;
    let response = confirm("<i>삭제하시겠습니까?</i>", "삭제 메세지");

    response.then((res) => {
      if (!res) return;
      requestServer
        .deleteRow(selectedRowKey)
        .then(() => {
          dataGridRef.current.instance.refresh();
        })
        .catch((err) => {
          alert(err.response.data.message, "오류");
        });
    });
  };

  return (
    <>
      <div className="d-flex flex-column h-100 system-page">
        <div className="d-flex flex-row flex-column-fluid">
          <div className="d-flex flex-column w-100 overflow-hidden">
            <CargoOwnerMaster
              onFocusedRowChanged={onFocusedRowChanged}
              dataGridRef={dataGridRef}
            />
          </div>
          <div className="d-flex flex-row-auto w-260px">
            <div className="form-container w-100 ml-3">
              <div className="form-btn-group single-line">
                <FunctionButton
                  onClickAdd={onClickAdd}
                  onClickDelete={onClickDelete}
                />
              </div>
              <form
                onSubmit={handleSubmit(onSubmit)}
                className="bg-white single-line"
              >
                <div className="form-tit">화주 등록</div>

                <InputText
                  control={control}
                  errors={errors}
                  name="code"
                  label="화주코드"
                  required={true}
                  disabled={inputDisable}
                />
                <InputText
                  control={control}
                  errors={errors}
                  name="companyName"
                  label="회사명"
                  required={true}
                />
                <InputText
                  control={control}
                  errors={errors}
                  name="remark"
                  label="비고"
                />

                <button type="submit" className="btn btn-form-save">
                  저장
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CargoOwnerPage;
