import { yupResolver } from "@hookform/resolvers/yup";
import { alert, confirm } from "devextreme/ui/dialog";
import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import CustomButton from "../../../components/FunctionButton/CustomButton";
import { ButtonGroup } from "../../../components/FunctionButton/CustomButton/customButton.style";
import FunctionButton from "../../../components/FunctionButton/FunctionButton";
import InputLookup from "../../../components/InputForm/InputLookup";
import InputText from "../../../components/InputForm/InputText";
import InputTextArea from "../../../components/InputForm/InputTextArea";
import HeaderTitle from "../../../components/Layout/Header/HeaderTitle";
import {
  InputFullRow,
  PanelInput,
  PanelInputTitle,
  PanelInputWrapper,
} from "../../../components/Layout/Panel/PanelInput/panelInput.style";
import {
  PanelBody,
  PanelBtn,
  PanelHeader,
  PanelInit,
} from "../../../components/Layout/Panel/panel.style";
import { useDatagrid } from "../../../hooks/useDatagrid";
import * as requestServer from "./MotherShipCrud";
import MotherShipMaster from "./MotherShipMaster";

function MotherShipPage(props) {
  const initialState = {
    code: "",
    motherShipNameEn: "",
    forwarderCode: "",
    remark: "",
  };
  const [selectedRowKey, setSelectedRowKey] = useState(null);
  const [inputDisable, setInputDisable] = useState(false);
  const [forwarder, setForwarder] = useState();
  const dataGridRef = useRef();
  useDatagrid(dataGridRef);

  const schema = yup
    .object({
      code: yup
        .string()
        .required("코드를 입력하세요")
        .max(20, "최대 20자리 까지 입력 가능"),
      motherShipNameEn: yup
        .string()
        .required("모선명을 입력하세요")
        .max(50, "최대 50자리까지 입력 가능"),
      forwarderCode: yup.string().required("선사코드를 선택하세요"),
      remark: yup.string().max(100, "최대 100자리 까지 입력 가능"),
    })
    .required();

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    getLookup();
  }, []);

  useEffect(() => {
    initInputData(selectedRowKey);
    // eslint-disable-next-line
  }, [selectedRowKey]);

  const initInputData = async (key) => {
    try {
      const { data } = await requestServer.getMotherShip(key);
      reset({ ...data, remark: data.remark || undefined });
    } catch (error) {
      console.log(error);
    }
  };

  const onFocusedRowChanged = (e) => {
    if (!e.row) return;
    setSelectedRowKey(e.row.key);
    setInputDisable(true);
    // reset({ ...e.row.data, remark: e.row.data.remark || undefined });
  };

  const onClickAdd = () => {
    if (!dataGridRef.current) return;
    reset(initialState);
    setSelectedRowKey(null);
    setInputDisable(false);
    dataGridRef.current.instance.option("focusedRowIndex", -1);
  };

  const onSubmit = async (data) => {
    delete data.createIP;
    delete data.createUserId;
    delete data.createdAt;
    delete data.updateIP;
    delete data.updateUserId;
    delete data.updatedAt;

    try {
      let code;
      if (selectedRowKey) {
        await requestServer.updateMotherShip(selectedRowKey, data);
      } else {
        const {
          data: { result },
        } = await requestServer.createMotherShip(data);

        if (result?.message) {
          alert(result?.message, "오류");
          return;
        }

        code = result?.code;
      }
      dataGridRef.current.instance.refresh();
      selectedRowKey && initInputData(selectedRowKey);
      code && dataGridRef.current.instance.option("focusedRowKey", code);
    } catch (err) {
      alert(err.response.data.message, "오류");
    }
  };

  const onClickDelete = () => {
    if (!dataGridRef.current || !selectedRowKey) return;
    let response = confirm("<i>삭제하시겠습니까?</i>", "삭제 메세지");

    response.then((res) => {
      if (!res) return;
      requestServer
        .deleteRow(selectedRowKey)
        .then(() => {
          dataGridRef.current.instance.refresh();
        })
        .catch((err) => {
          alert(err.response.data.message, "오류");
        });
    });
  };

  async function getLookup() {
    try {
      const forwarderLookup = await requestServer.getForwarder();
      setForwarder(forwarderLookup.data);
    } catch (err) {
      console.log(err);
    }
  }

  return (
    <>
      <PanelInit>
        <PanelHeader>
          <HeaderTitle />
          <PanelBtn>
            <FunctionButton
              onClickAdd={onClickAdd}
              onClickDelete={onClickDelete}
            />
          </PanelBtn>
        </PanelHeader>

        <PanelBody row left="400px" right="calc(100% - 400px)">
          <PanelInputWrapper
            onSubmit={handleSubmit(onSubmit)}
            openInputBar={true}
          >
            <PanelInputTitle>모선 등록</PanelInputTitle>

            <PanelInput grid={2}>
              <InputText
                control={control}
                errors={errors}
                name="code"
                label="모선코드"
                required={true}
                disabled={inputDisable}
              />
              <InputText
                control={control}
                errors={errors}
                name="motherShipNameEn"
                label="모선명(영문)"
                required={true}
              />
              <InputLookup
                control={control}
                errors={errors}
                name="forwarderCode"
                label="선사코드"
                dataSource={forwarder}
                displayExpr="forwarderNameKo"
                valueExpr="code"
                required={true}
              />
              <InputFullRow grid={2}>
                <InputTextArea
                  control={control}
                  errors={errors}
                  name="remark"
                  label="비고"
                />
              </InputFullRow>
            </PanelInput>

            <ButtonGroup marginTop={20}>
              <CustomButton
                type="submit"
                width="100%"
                height={36}
                layout="solid"
                color="common"
                label="저장"
              />
            </ButtonGroup>
          </PanelInputWrapper>

          <MotherShipMaster
            onFocusedRowChanged={onFocusedRowChanged}
            dataGridRef={dataGridRef}
          />
        </PanelBody>
      </PanelInit>
    </>
  );
}

export default MotherShipPage;
