import { yupResolver } from "@hookform/resolvers/yup";
import { alert, confirm } from "devextreme/ui/dialog";
import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { shallowEqual, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import * as yup from "yup";
import * as requestServer from "./ShuttleCrud";

import InputCheckBox from "../../../components/InputForm/InputCheckBox";
import InputLookup from "../../../components/InputForm/InputLookup";
import InputNumber from "../../../components/InputForm/InputNumber";
import InputText from "../../../components/InputForm/InputText";
import ShuttleMaster from "./ShuttleMaster";
import FunctionButton from "../../../components/FunctionButton/FunctionButton";

function ShuttlePage(props) {
  const initialState = {
    serialNo: "",
    loadPlace: "",
    unloadPlace: "",
    isOneWay: false,
    containerType: "",
    chargedCost: "",
    habulCost: "",
    remark: "",
  };
  const [selectedRowKey, setSelectedRowKey] = useState(null);
  const [terminal, setTerminal] = useState(null);
  const [conType, setConType] = useState(null);
  const dataGridRef = useRef();
  const { menuAll, openItem } = useSelector(
    (state) => ({
      menuAll: state.menu.menuAll,
      openItem: state.menu.openItem,
    }),
    shallowEqual
  );

  const schema = yup
    .object({
      serialNo: yup.string().required("일련번호를 입력하세요"),
      loadPlace: yup.string().required("상차지를 입력하세요"),
      unloadPlace: yup.string().required("하차지를 입력하세요"),
      isOneWay: yup.bool(),
      containerType: yup.string().required("컨/규격을 입력하세요"),
      chargedCost: yup
        .string()
        .nullable(true)
        .max(11, "11자리 수까지 입력 가능"),
      habulCost: yup
        .string()
        .nullable(true)
        .max(11, "11자리 수까지 입력 가능"),
      remark: yup
        .string()
        .nullable(true)
        .max(100, "최대 100자리 까지 입력 가능"),
    })
    .required();

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    async function getLookup() {
      try {
        const terminalData = await requestServer.getLookup("terminal/terminal");
        const conTypeData = await requestServer.getLookup("code/conType");

        setTerminal(terminalData.data);
        setConType(conTypeData.data);
      } catch (err) {
        console.log(err);
      }
    }

    getLookup();
  }, []);

  const onClickAdd = () => {
    if (!dataGridRef.current) return;
    reset(initialState);
    setSelectedRowKey(null);
    dataGridRef.current.instance.option("focusedRowIndex", -1);
  };

  const onClickDelete = () => {
    if (!dataGridRef.current || !selectedRowKey) return;
    let response = confirm("<i>삭제하시겠습니까?</i>", "삭제 메세지");

    response.then((res) => {
      if (!res) return;
      requestServer
        .deleteRow(selectedRowKey)
        .then(() => {
          dataGridRef.current.instance.refresh();
        })
        .catch((err) => {
          alert(err.response?.data?.message || "ERROR", "오류");
        });
    });
  };

  const onFocusedRowChanged = (e) => {
    console.log("focus", e.row);
    if (!e.row) return;
    setSelectedRowKey(e.row.key);
    reset({ ...e.row.data, remark: e.row.data.remark || undefined });
  };

  const onSubmit = async (data) => {
    delete data.createdAt;
    delete data.updateIP;
    delete data.updateUserId;
    delete data.updatedAt;

    try {
      if (selectedRowKey) {
        await requestServer.updateShuttleCharge(selectedRowKey, data);
      } else {
        await requestServer.createShuttleCharge(data);
      }

      dataGridRef.current.instance.refresh();
    } catch (err) {
      alert(err.response?.data?.message || "ERROR", "오류");
    }
  };
  return (
    <>
      <div className="page-wrap">
        {/* 왼쪽 사이드 섹션 */}
        <div className="column-left fluid-left">
          {/* 페이지 헤더 */}
          <div className="panel-header flex-row">
            <div className="panel-header-init">
              <div className="panel-header-tit">{openItem?.menu?.menuName}</div>
              <ul className="breadcrumb-list">
                <li className="breadcrumb-item">
                  <Link to="/">
                    <i className="flaticon2-shelter icon-1x" />
                  </Link>
                </li>
                {openItem?.menu?.parentId && (
                  <li className="breadcrumb-item">
                    {
                      menuAll.find((menu) => {
                        return menu.menuId === openItem?.menu?.parentId;
                      })?.menu?.menuName
                    }
                  </li>
                )}
                {openItem?.menu?.menuName && (
                  <li className="breadcrumb-item current">
                    {openItem?.menu?.menuName}
                  </li>
                )}
              </ul>
            </div>
            <FunctionButton
              onClickAdd={onClickAdd}
              onClickDelete={onClickDelete}
            />
          </div>

          {/* 페이지 메인그리드 */}
          <ShuttleMaster
            onFocusedRowChanged={onFocusedRowChanged}
            dataGridRef={dataGridRef}
          />
        </div>

        {/* 그리드 인풋 너비 고정 */}
        <div className="column-right fixed-right">
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="form-container column-input"
          >
            <div className="form-tit">셔틀 등록</div>

            <InputText
              control={control}
              errors={errors}
              name="serialNo"
              label="일련번호"
              required={true}
            />
            <InputLookup
              control={control}
              errors={errors}
              name="loadPlace"
              label="상차지"
              required={true}
              dataSource={terminal}
              valueExpr="code"
              displayExpr="code"
            />
            <InputLookup
              control={control}
              errors={errors}
              name="unloadPlace"
              label="하차지"
              required={true}
              dataSource={terminal}
              valueExpr="code"
              displayExpr="code"
            />
            <InputCheckBox
              control={control}
              errors={errors}
              name="isOneWay"
              label="편도 여부"
              text="편도"
            />
            <InputLookup
              control={control}
              errors={errors}
              name="containerType"
              label="컨/규격"
              required={true}
              dataSource={conType}
              valueExpr="code"
              displayExpr="codeName"
            />
            <InputNumber
              control={control}
              errors={errors}
              name="chargedCost"
              label="청구금액"
            />
            <InputNumber
              control={control}
              errors={errors}
              name="habulCost"
              label="하불금액"
            />
            <InputText
              control={control}
              errors={errors}
              name="remark"
              label="비고"
            />

            <button type="submit" className="btn btn-form-save">
              저장
            </button>
          </form>
        </div>
      </div>
    </>
  );
}

export default ShuttlePage;
