import dayjs from "dayjs";
import { useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { cancelNextMonthUpdate, nextMonthUpdate } from "./ClosingCrud";
import { alert, confirm } from "devextreme/ui/dialog";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const useClosing = () => {
  const [openNextMonthModal, setOpenNextMonthModal] = useState(false);

  const closingLeftRef = useRef();
  const closingRightRef = useRef();
  const closingDetailRef = useRef();

  const nextMonthForm = useForm({
    defaultValues: {
      deliverCompleteDate: dayjs().format("YYYY-MM-DD"),
    },
    resolver: yupResolver(
      yup.object().shape({
        deliverCompleteDate: yup
          .string()
          .matches(
            /^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/,
            "날짜 형식을 맞춰주세요"
          ),
      })
    ),
  });
  const { reset: nextMonthReset } = nextMonthForm;

  const onClickNextMonth = () => {
    const instance = closingRightRef.current.instance;
    const selectedKeys = instance.getSelectedRowKeys();

    if (selectedKeys.length === 0) {
      alert("데이터를 선택해주세요", "오류");
      return;
    }

    setOpenNextMonthModal(true);
  };

  const onClickCancelNextMonth = async () => {
    const instance = closingRightRef.current.instance;
    const selectedKeys = instance.getSelectedRowKeys();

    if (selectedKeys.length === 0) {
      await alert("데이터를 선택해주세요", "오류");
      return;
    }

    const check = await confirm("이월취소 하시겠습니까?", "확인");
    if (!check) return;

    const keyTypeArr = selectedKeys.map((key) => {
      let oidType = key.split("-")[0];
      if (!isNaN(Number(oidType))) oidType = "dispatch";
      return {
        key: key,
        type: oidType,
      };
    });

    const sendData = {
      keys: keyTypeArr,
    };

    try {
      await cancelNextMonthUpdate(sendData);

      instance.refresh();
    } catch (error) {
      alert("이월 실패", "오류");
      console.log(error);
    }
  };

  const onNextMonthSubmit = async (data) => {
    const instance = closingRightRef.current.instance;

    const selectedKeys = instance.getSelectedRowKeys();

    const keyTypeArr = selectedKeys.map((key) => {
      let oidType = key.split("-")[0];
      if (!isNaN(Number(oidType))) oidType = "dispatch";
      return {
        key: key,
        type: oidType,
      };
    });

    const sendData = {
      keys: keyTypeArr,
      data: data,
    };

    try {
      await nextMonthUpdate(sendData);
      nextMonthReset();

      instance.refresh();
      setOpenNextMonthModal(false);
    } catch (error) {
      alert("이월 실패", "오류");
      console.log(error);
    }
  };

  const nextMonthModalProps = {
    nextMonthForm,
    onClose: () => setOpenNextMonthModal(false),
    onSubmit: onNextMonthSubmit,
  };

  return {
    openNextMonthModal,

    closingLeftRef,
    closingRightRef,
    closingDetailRef,

    onClickNextMonth,
    onClickCancelNextMonth,

    nextMonthModalProps,
  };
};

export default useClosing;
