import React from "react";
import { ButtonWrapper } from "./customButton.style";

function CustomButton({
  onClick,
  label,
  type,
  width,
  height,
  layout,
  color,
  isLoading,
  disabled,
  className,
  children,
}) {
  return (
    <ButtonWrapper
      onClick={onClick}
      type={type}
      width={width}
      height={height}
      isLoading={isLoading}
      disabled={disabled}
      className={`c-${color} l-${layout} ${className}`}
    >
      {label}
      {children}
    </ButtonWrapper>
  );
}

export default CustomButton;
