import axios from "axios";

const apiUrl = `${process.env.REACT_APP_API_URL}`;

export function getLookup(param) {
  return axios.get(apiUrl + `/${param}`);
}

export async function initLookupData() {
  const [
    divisionData,
    habulDivisionData,
    billingPlaceData,
    carData,
  ] = await Promise.all([
    getLookup("comcode/detail/10300"),
    getLookup("comcode/detail/10700"),
    getLookup("companyManage/lookup/isYongchasa"),
    getLookup("carManage/lookup"),
  ]);
  return {
    division: divisionData.data,
    habulDivision: habulDivisionData.data,
    billingPlace: [{ oid: null, company: "" }, ...billingPlaceData.data],
    car: [{ oid: null, carNo_Name: "" }, ...carData.data],
  };
}
