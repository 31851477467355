import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { initLookupData } from "./ExtraChargeModalCrud";

const useExtraChargeModal = (etcChargeHabulRef) => {
  const [lookupData, setLookupData] = useState({
    division: [],
    habulDivision: [],
    billingPlace: [],
    car: [],
  });

  const extraChargeForm = useForm();

  useEffect(() => {
    _initLookupData();
  }, []);

  const _initLookupData = async () => {
    const lookupData = await initLookupData();
    setLookupData(lookupData);
  };

  const onExtraChargeToolbarPreparing = useCallback((e) => {
    e.toolbarOptions.items.forEach((item) => {
      if (item.name === "saveButton") {
        item.visible = false;
      }
    });
  }, []);

  const onEditingStart = useCallback((e) => {
    if (e.data.completeNo) {
      e.cancel = true;
      return;
    }
    const 업체 = 4304;
    const 기사 = 4305;
    const {
      data: { habulDivision },
      column: { dataField },
    } = e;

    if (
      (Number(habulDivision) === 업체 && dataField === "fromDirverNo") ||
      (Number(habulDivision) === 기사 && dataField === "fromCompany") ||
      (!habulDivision &&
        (dataField === "fromDirverNo" || dataField === "fromCompany"))
    ) {
      e.cancel = true;
    }
  }, []);

  const onOptionChanged = useCallback(
    (e) => {
      const 업체 = 4304;
      const 기사 = 4305;
      const { fullName, value } = e;
      if (fullName !== "editing.changes") return;
      if (!Array.isArray(value)) return;

      const instance = etcChargeHabulRef.current.instance;

      for (const obj of value) {
        const { data, key, type } = obj;
        if (type === "remove") continue;
        const { habulDivision, fromDirverNo, fromCompany } = data;

        const rowIndex = instance.getRowIndexByKey(key);

        if (Number(habulDivision) === 업체 && fromDirverNo !== null) {
          instance.cellValue(rowIndex, "fromDirverNo", null);
        } else if (Number(habulDivision) === 기사 && fromCompany !== null) {
          instance.cellValue(rowIndex, "fromCompany", null);
        }
      }
    },
    [etcChargeHabulRef]
  );

  return {
    lookupData,
    extraChargeForm,
    onExtraChargeToolbarPreparing,
    onEditingStart,
    onOptionChanged,
  };
};

export default useExtraChargeModal;
