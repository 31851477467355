import axios from "axios";

const apiUrl = `${process.env.REACT_APP_API_URL}/authgroup/master`;

export function createAuthGroup(data) {
  return axios.post(apiUrl, data);
}

export function updateAuthGroup(data) {
  return axios.put(apiUrl, data);
}

export function deleteAuthGroup(id) {
  return axios.delete(apiUrl, { params: { id } });
}
