import axios from "axios";

const apiUrl = `${process.env.REACT_APP_API_URL}`;
const apiUrlCompany = `${process.env.REACT_APP_API_URL}/companyManage`;

export function getLookup(param) {
  return axios.get(apiUrl + `/${param}`);
}

export function getRowDataById(oid) {
  return axios.get(apiUrlCompany + `/${oid}`);
}

export function createRowData(data) {
  return axios.post(apiUrlCompany, data);
}

export function updateRowData(key, data) {
  return axios.put(apiUrlCompany, { key, data });
}

export function deleteRowData(key) {
  return axios.delete(apiUrlCompany + `/${key}`);
}
