import React from "react";
import { AsideWrapper, AsideHeader, AsideNavigator } from "./aside.style";
import { ReactComponent as LogoImgIcon } from "../../../../style/icons/logo-dongryun-icon.svg";
import { ReactComponent as LogoTxtIcon } from "../../../../style/icons/logo-dongryun-txt-en.svg";
import AsideList from "./AsideList";

function Aside({ toggleMenu, hoverMenu, isOpen }) {
  return (
    <AsideWrapper
      className={`${isOpen ? "active" : "default"} no-print`}
      onMouseEnter={hoverMenu}
      onMouseLeave={hoverMenu}
    >
      <AsideHeader>
        <LogoImgIcon className="brand-logo-icon" />
        <LogoTxtIcon className="brand-logo-txt" />
      </AsideHeader>

      <AsideNavigator>
        <AsideList isOpen={isOpen} />
      </AsideNavigator>
    </AsideWrapper>
  );
}

export default Aside;
