import { yupResolver } from "@hookform/resolvers/yup";
import { alert, confirm } from "devextreme/ui/dialog";
import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import * as requestServer from "./ChassisCrud";
import ChassisMaster from "./ChassisMaster";

import CustomButton from "../../../components/FunctionButton/CustomButton";
import { ButtonGroup } from "../../../components/FunctionButton/CustomButton/customButton.style";
import FunctionButton from "../../../components/FunctionButton/FunctionButton";
import InputDate from "../../../components/InputForm/InputDate";
import InputLookup from "../../../components/InputForm/InputLookup";
import InputNumber from "../../../components/InputForm/InputNumber";
import InputRadioGroup from "../../../components/InputForm/InputRadioGroup";
import InputSelect from "../../../components/InputForm/InputSelect";
import InputText from "../../../components/InputForm/InputText";
import InputTextArea from "../../../components/InputForm/InputTextArea";
import HeaderTitle from "../../../components/Layout/Header/HeaderTitle";
import {
  InputFullRow,
  PanelInput,
  PanelInputTitle,
  PanelInputWrapper,
} from "../../../components/Layout/Panel/PanelInput/panelInput.style";
import {
  PanelBody,
  PanelBtn,
  PanelHeader,
  PanelInit,
} from "../../../components/Layout/Panel/panel.style";
import { useDatagrid } from "../../../hooks/useDatagrid";

function ChassisPage(props) {
  const [lookupData, setLookupData] = useState({
    chassisType: [],
    chassisDivision: [],
    assignDivison: [],
    actStatusData: [],
  });

  const [selectedRowKey, setSelectedRowKey] = useState();
  const dataGridRef = useRef();
  useDatagrid(dataGridRef);

  const initialState = {
    chassisNo: "",
    assignDivison: "",
    manageAssign: "",
    chassisType: "",
    manufacturer: "",
    underframe: "",
    registerDate: undefined,
    firRegisterDate: undefined,
    company: "",
    length: 0,
    width: 0,
    height: 0,
    ton: 0,
    confrimDate: undefined,
    checkDate: undefined,
    actStatus: "",
    editDate: undefined,
    assignWorker: "",
    remark: "",
  };

  const schema = yup
    .object({
      chassisNo: yup
        .string()
        .required("샤시번호를 입력하세요")
        .max(20, "최대 20자리까지 입력 가능"),
      chassisType: yup.string().required("샤시종류를 입력해주세요"),
      // chassisDivision: yup.string().required("구분을 입력해주세요"),
      remark: yup.string().max(100, "최대 100자리까지 입력 가능"),
    })
    .required();

  useEffect(() => {
    getLookup();
  }, []);

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: initialState,
  });

  const onFocusedRowChanged = (e) => {
    if (!e.row) return;
    setSelectedRowKey(e.row.key);
    // 여기서 key로 불러온다음 reset시키기
    const rowData = { ...e.row.data };

    for (const key in rowData) {
      if (!rowData[key]) rowData[key] = undefined;
    }
    reset(rowData);
  };

  const onClickAdd = () => {
    if (!dataGridRef.current) return;
    reset(initialState);
    setSelectedRowKey(null);
    dataGridRef.current.instance.option("focusedRowIndex", -1);
    dataGridRef.current.instance.refresh();
  };

  const onSubmit = async (data) => {
    delete data.createIP;
    delete data.createUserId;
    delete data.createdAt;
    delete data.updateIP;
    delete data.updateUserId;
    delete data.updatedAt;

    try {
      if (selectedRowKey) {
        await requestServer.updateChassis(selectedRowKey, data);
      } else {
        await requestServer.createChassis(data);
      }
      dataGridRef.current.instance.refresh();
      reset(initialState);
      setSelectedRowKey(null);
      dataGridRef.current.instance.option("focusedRowIndex", -1);
    } catch (err) {
      alert(err.response.data.message, "오류");
    }
  };

  const onClickDelete = () => {
    if (!dataGridRef.current || !selectedRowKey) return;
    let response = confirm("<i>삭제하시겠습니까?</i>", "삭제 메세지");

    response.then((res) => {
      if (!res) return;
      requestServer
        .deleteRow(selectedRowKey)
        .then(() => {
          dataGridRef.current.instance.refresh();
        })
        .catch((err) => {
          alert(err.response.data.message, "오류");
        });
    });
  };

  async function getLookup() {
    const chassisTypeData = await requestServer.getLookup(
      "comcode/detail/30300"
    );
    const chassisDivisionData = await requestServer.getLookup(
      "comcode/detail/30400"
    );
    const assignDivisonData = await requestServer.getLookup(
      "comcode/detail/30600"
    );
    const actStatusData = await requestServer.getLookup("comcode/detail/30700");

    setLookupData({
      chassisType: chassisTypeData.data,
      chassisDivision: chassisDivisionData.data,
      assignDivison: assignDivisonData.data,
      actStatusData: actStatusData.data,
    });
  }

  return (
    <>
      <PanelInit>
        <PanelHeader>
          <HeaderTitle />
          <PanelBtn>
            <FunctionButton
              onClickAdd={onClickAdd}
              onClickDelete={onClickDelete}
            />
          </PanelBtn>
        </PanelHeader>

        <PanelBody row left="400px" right="calc(100% - 400px)">
          <PanelInputWrapper
            onSubmit={handleSubmit(onSubmit)}
            openInputBar={true}
          >
            <PanelInputTitle>샤시 등록</PanelInputTitle>
            <PanelInput grid={2}>
              <InputText
                control={control}
                errors={errors}
                name="chassisNo"
                label="샤시번호"
                required={true}
              />
              <InputFullRow grid={2}>
                <InputRadioGroup
                  control={control}
                  errors={errors}
                  name="assignDivison"
                  label="소속구분"
                  dataSource={lookupData.assignDivison}
                  displayExpr="subName"
                  valueExpr="subCode"
                  required={true}
                />
              </InputFullRow>
              <InputText
                control={control}
                errors={errors}
                name="manageAssign"
                label="관리소속"
              />
              <InputLookup
                control={control}
                errors={errors}
                name="chassisType"
                label="샤시종류"
                required={true}
                dataSource={lookupData.chassisType}
                valueExpr="id"
                displayExpr="subName"
              />
              <InputLookup
                control={control}
                errors={errors}
                name="manufacturer"
                label="제조사"
                required={true}
                dataSource={[
                  { id: "0", subName: "예시 제조사1" },
                  { id: "1", subName: "예시 제조사2" },
                ]}
                valueExpr="id"
                displayExpr="subName"
              />
              <InputText
                control={control}
                errors={errors}
                name="underframe"
                label="차대번호"
              />
              <InputDate
                control={control}
                errors={errors}
                name="registerDate"
                label="차량등록일"
              />
              <InputDate
                control={control}
                errors={errors}
                name="firRegisterDate"
                label="최초등록일"
              />
              <InputLookup
                control={control}
                errors={errors}
                name="company"
                label="사업장"
                required={true}
                dataSource={[
                  { id: "0", subName: "예시 사업장1" },
                  { id: "1", subName: "예시 사업장2" },
                ]}
                valueExpr="id"
                displayExpr="subName"
              />

              <InputNumber
                control={control}
                errors={errors}
                name="length"
                label="길이 (Cm)"
              />
              <InputNumber
                control={control}
                errors={errors}
                name="width"
                label="너비 (Cm)"
              />
              <InputNumber
                control={control}
                errors={errors}
                name="height"
                label="높이 (Cm)"
              />
              <InputNumber
                control={control}
                errors={errors}
                name="ton"
                label="중량 (Ton)"
              />
              <InputDate
                control={control}
                errors={errors}
                name="confrimDate"
                label="검사일자"
              />
              <InputDate
                control={control}
                errors={errors}
                name="checkDate"
                label="점검일자"
              />
              <InputSelect
                control={control}
                errors={errors}
                name="actStatus"
                label="활동상태"
                required={true}
                dataSource={lookupData.actStatusData}
                valueExpr="subCode"
                displayExpr="subName"
              />
              <InputDate
                control={control}
                errors={errors}
                name="editDate"
                label="변경일자"
              />
              <InputText
                control={control}
                errors={errors}
                name="assignWorker"
                label="등록직원"
              />
              <InputFullRow grid={2}>
                <InputTextArea
                  control={control}
                  errors={errors}
                  name="remark"
                  label="비고"
                />
              </InputFullRow>
            </PanelInput>

            <ButtonGroup marginTop={20}>
              <CustomButton
                type="submit"
                width="100%"
                height={36}
                layout="solid"
                color="common"
                label="저장"
              />
            </ButtonGroup>
          </PanelInputWrapper>

          <ChassisMaster
            onFocusedRowChanged={onFocusedRowChanged}
            dataGridRef={dataGridRef}
          />
        </PanelBody>
      </PanelInit>
    </>
  );
}

export default ChassisPage;
