import {
  Column,
  DataGrid,
  Editing,
  Format,
  Lookup,
  RequiredRule,
} from "devextreme-react/data-grid";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Modal } from "react-bootstrap";

import { useForm } from "react-hook-form";
import SVG from "react-inlinesvg";

import CustomStore from "devextreme/data/custom_store";
import { alert } from "devextreme/ui/dialog";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { sendRequest } from "../../../../_metronic/_helpers/DataSoureHelpers";
import * as requestServer from "./ContainerCostCrud";

export function ContainerCostModal({
  onClose,
  maskClosable,
  closable,
  visible,
  rowData,
  dataGridRef,
}) {
  const containerCostRef = useRef();
  const [lookup, setLookup] = useState({
    division: [],
  });

  const { handleSubmit } = useForm();

  // dataSource
  const dataSource = useMemo(() => {
    const apiUrl = `${process.env.REACT_APP_API_URL}/containercost/modal`;

    return new CustomStore({
      key: "oid",
      load: () => sendRequest(apiUrl, "GET", { orderDetailCode: rowData.id }),
    });
    // eslint-disable-next-line
  }, []);

  const onSubmit = async () => {
    const containerCost = containerCostRef.current.instance;

    const containerCostValid = await containerCost
      .getController("validating")
      .validate(true);
    if (!containerCostValid) return false;

    const changes = containerCost.option("editing.changes");

    try {
      await requestServer.ContainerCostCRUD({
        key: rowData.id,
        data: changes,
      });
      dataGridRef && dataGridRef.current.instance.refresh();
      onClose();
    } catch (err) {
      alert(err.response.data.message, "오류");
    }
  };

  // useEffect
  useEffect(() => {
    getLookup();
  }, []);

  async function getLookup() {
    try {
      const divisionData = await requestServer.getLookup(
        "comcode/detail/10800"
      );

      setLookup({
        division: divisionData.data,
      });
    } catch (err) {
      console.log(err);
    }
  }

  // dataGrid function

  const onToolbarPreparing = useCallback((e) => {
    e.toolbarOptions.items.forEach((item) => {
      if (item.name === "saveButton") {
        item.visible = false;
      }
    });
  }, []);
  const onInitNewRow = () => {};

  const onMaskClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  return (
    <>
      <div className={"modal-overlay " + (visible ? "active" : "")} />
      <div
        className={"modal-wrap user-list-modal " + (visible ? "active" : "")}
        onClick={maskClosable ? onMaskClick : null}
        tabIndex="-1"
        visible={`${visible}`}
      >
        <Modal.Dialog centered tabIndex="0" size="md" animation="true">
          <Modal.Body>
            <form className="modal-form" onSubmit={handleSubmit(onSubmit)}>
              <div className="modal-form-header">
                <div className="modal-form-tit">
                  <span className="modal-tit">컨테이너비용 등록</span>
                </div>
                <button
                  type="button"
                  className="btn-modal btn-modal-close"
                  onClick={onClose}
                >
                  <SVG
                    src={toAbsoluteUrl(
                      "/media/svg/icons/Custom/close_black_24dp.svg"
                    )}
                    className="close-icon"
                  />
                </button>
              </div>
              <div className="modal-form-body">
                <DataGrid
                  dataSource={dataSource}
                  onToolbarPreparing={onToolbarPreparing}
                  ref={containerCostRef}
                  onInitNewRow={onInitNewRow}
                >
                  <Editing
                    mode="batch"
                    allowAdding={true}
                    allowDeleting={true}
                    allowUpdating={true}
                  />
                  <Column dataField="division" caption="구분">
                    <Lookup
                      dataSource={lookup.division}
                      valueExpr="id"
                      displayExpr="subName"
                    />
                    <RequiredRule message="구분을 선택해주세요" />
                  </Column>
                  <Column dataField="content" caption="내용">
                    <RequiredRule message="내용을 입력해주세요" />
                  </Column>
                  <Column dataField="cost" caption="비용" dataType="number">
                    <Format type="fixedPoint" />
                    <RequiredRule message="비용을 입력해주세요" />
                  </Column>
                </DataGrid>
              </div>
              <div className="modal-form-footer">
                <button type="submit" className="btn btn-modal btn-save">
                  저장
                </button>
              </div>
            </form>
          </Modal.Body>
        </Modal.Dialog>
      </div>
    </>
  );
}
