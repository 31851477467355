import { yupResolver } from "@hookform/resolvers/yup";
import { alert, confirm } from "devextreme/ui/dialog";
import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import * as requestServer from "./CarCrud";
import CarMaster from "./CarMaster";

import CustomButton from "../../../components/FunctionButton/CustomButton";
import { ButtonGroup } from "../../../components/FunctionButton/CustomButton/customButton.style";
import FunctionButton from "../../../components/FunctionButton/FunctionButton";
import InputCheckBox from "../../../components/InputForm/InputCheckBox";
import InputLookup from "../../../components/InputForm/InputLookup";
import InputText from "../../../components/InputForm/InputText";
import HeaderTitle from "../../../components/Layout/Header/HeaderTitle";
import {
  PanelInput,
  PanelInputTitle,
  PanelInputWrapper,
} from "../../../components/Layout/Panel/PanelInput/panelInput.style";
import {
  PanelBody,
  PanelBtn,
  PanelHeader,
  PanelInit,
} from "../../../components/Layout/Panel/panel.style";

function CarPage(props) {
  const initialState = {
    code: "",
    carNo: "",
    carName: "",
    catType: "",
    isPaidEntry: false,
    driverId: "",
    chassisNo: "",
    carPark: "",
    remark: "",
  };
  const [selectedRowKey, setSelectedRowKey] = useState();
  const [inputDisable, setInputDisable] = useState(false);
  const [driver, setDriver] = useState();
  const [chassis, setChassis] = useState();
  const [carPark, setCarPark] = useState();
  const [carKind, setCarKind] = useState();
  const dataGridRef = useRef();

  const schema = yup
    .object({
      code: yup
        .string()
        .required("코드를 입력하세요")
        .max(20, "최대 20자리까지 입력 가능"),
      carNo: yup
        .string()
        .required("차량 번호를 입력하세요")
        .max(10, "최대 10자리 까지 입력 가능"),
      carName: yup
        .string()
        .required("차량명을 입력하세요")
        .max(50, "최대 50자리까지 입력 가능"),
      catType: yup
        .string()
        .required("차량 종류를 선택하세요")
        .max(11, "차량종류 코드가 너무 깁니다"),
      isPaidEntry: yup.bool(),
      driverId: yup
        .string()
        .required("기사 이이디를 선택하세요")
        .max(11, "기사아이디 코드가 너무 깁니다"),
      chassisNo: yup
        .string()
        .required("샤시 번호를 선택하세요")
        .max(20, "샤시번호가 너무 깁니다"),
      carPark: yup
        .string()
        .required("주차장을 선택하세요")
        .max(11, "주차장 코드가 너무 깁니다"),
      remark: yup.string().max(100, "최대 100자리 까지 입력 가능"),
    })
    .required();

  const {
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    //기사, 샤시 데이터 들고오기
    async function getLookup() {
      try {
        const driverLookup = await requestServer.getLookup("driver");
        const chassisLookup = await requestServer.getLookup("chassis");
        const carParkLookup = await requestServer.getLookup(
          "comcode/detail/30200"
        );
        const carNoLookup = await requestServer.getLookup(
          "comcode/detail/30500"
        );

        setDriver(driverLookup.data);
        setChassis(chassisLookup.data);
        setCarPark(carParkLookup.data);
        setCarKind(carNoLookup.data);
      } catch (err) {
        console.log(err);
      }
    }
    getLookup();
  }, []);

  const onFocusedRowChanged = (e) => {
    if (!e.row) return;
    setSelectedRowKey(e.row.key);
    setInputDisable(true);
    reset({ ...e.row.data, remark: e.row.data.remark || undefined });
  };

  const onClickAdd = () => {
    if (!dataGridRef.current) return;
    reset(initialState);
    setSelectedRowKey(null);
    setInputDisable(false);
    dataGridRef.current.instance.option("focusedRowIndex", -1);
  };

  const onSubmit = async (data) => {
    delete data.createIP;
    delete data.createUserId;
    delete data.createdAt;
    delete data.updateIP;
    delete data.updateUserId;
    delete data.updatedAt;

    try {
      if (selectedRowKey) {
        await requestServer.updateCar(selectedRowKey, data);
      } else {
        await requestServer.createCar(data);
      }
      dataGridRef.current.instance.refresh();
      reset(initialState);
      setSelectedRowKey(null);
      dataGridRef.current.instance.option("focusedRowIndex", -1);
    } catch (err) {
      alert(err.response.data.message, "오류");
    }
  };

  const onClickDelete = () => {
    if (!dataGridRef.current || !selectedRowKey) return;
    let response = confirm("<i>삭제하시겠습니까?</i>", "삭제 메세지");

    response.then((res) => {
      if (!res) return;
      requestServer
        .deleteRow(selectedRowKey)
        .then(() => {
          dataGridRef.current.instance.refresh();
        })
        .catch((err) => {
          alert(err.response.data.message, "오류");
        });
    });
  };

  return (
    <>
      <PanelInit>
        <PanelHeader>
          <HeaderTitle />

          <PanelBtn>
            <FunctionButton
              onClickAdd={onClickAdd}
              onClickDelete={onClickDelete}
            />
          </PanelBtn>
        </PanelHeader>

        <PanelBody row left="400px" right="calc(100% - 400px)">
          <PanelInputWrapper
            onSubmit={handleSubmit(onSubmit)}
            className="form-container column-input"
          >
            <PanelInputTitle>차량 등록</PanelInputTitle>
            {/* 중복체크하기 */}

            <PanelInput grid={2}>
              <InputText
                control={control}
                errors={errors}
                name="code"
                label="차량코드"
                required={true}
                disabled={inputDisable}
              />
              <InputText
                control={control}
                errors={errors}
                name="carNo"
                label="차량번호"
                required={true}
              />
              <InputText
                control={control}
                errors={errors}
                name="carName"
                label="차량명"
                required={true}
              />
              <InputLookup
                control={control}
                errors={errors}
                name="catType"
                label="차량종류"
                dataSource={carKind}
                displayExpr="subName"
                valueExpr="id"
                required={true}
              />
              <InputCheckBox
                control={control}
                errors={errors}
                name="isPaidEntry"
                label="지입여부"
              />
              <InputLookup
                control={control}
                errors={errors}
                name="driverId"
                label="기사"
                dataSource={driver}
                displayExpr="driverName"
                valueExpr="id"
                required={true}
              />

              <InputLookup
                control={control}
                errors={errors}
                name="chassisNo"
                label="샤시"
                dataSource={chassis}
                displayExpr="chassisNo"
                valueExpr="chassisNo"
                required={true}
              />

              <InputLookup
                control={control}
                errors={errors}
                name="carPark"
                label="주차장"
                required={true}
                dataSource={carPark}
                valueExpr="id"
                displayExpr="subName"
              />
              <InputText
                control={control}
                errors={errors}
                name="remark"
                label="비고"
              />
            </PanelInput>

            <ButtonGroup marginTop={20}>
              <CustomButton
                type="submit"
                width="100%"
                height={36}
                layout="solid"
                color="common"
                label="저장"
              />
            </ButtonGroup>
          </PanelInputWrapper>

          <CarMaster
            onFocusedRowChanged={onFocusedRowChanged}
            dataGridRef={dataGridRef}
          />
        </PanelBody>
      </PanelInit>
    </>
  );
}

export default CarPage;
