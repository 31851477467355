import axios from "axios";
const apiUrl = `${process.env.REACT_APP_API_URL}`;
const carDeadlineApiUrl = `${process.env.REACT_APP_API_URL}/car-deadline`;

function getLookupData(param) {
  return axios.get(apiUrl + `/${param}`);
}

export async function fetchLookupData() {
  const [companyData, carData, carDivisionData] = await Promise.all([
    getLookupData("company"),
    getLookupData("carManage/lookup"),
    getLookupData("comcode/detail/30800"),
  ]);

  return {
    company: companyData.data,
    car: [{ oid: null, carNo_Name: "" }, ...carData.data],
    carDivision: [{ subCode: "", subName: "전체" }, ...carDivisionData.data],
  };
}

export function getDriverFee(data) {
  return axios.get(carDeadlineApiUrl + `/driver`, { params: data });
}
