import React, { useRef } from "react";
import InputLayout from "./InputLayout/InputLayout";
import { Controller } from "react-hook-form";
import { DropDownBox, TreeView } from "devextreme-react";

const InputComboSelectBox = ({
  control,
  errors,
  name,
  label,
  labelWidth,
  flex,
  dataSource,
  keyExpr,
  displayExpr,
  valueExpr,
  required,
  disable,
  searchEnabled = true,
  onValueChanged = () => {},
  onInitialized = () => {},
  width,
}) => {
  const treeView = useRef();

  return (
    <InputLayout
      label={label}
      required={required}
      errors={errors}
      name={name}
      labelWidth={labelWidth}
      comboSelectBox={true}
    >
      <Controller
        render={({ field: { onChange, value } }) => {
          const syncTreeViewSelection = (e) => {
            const treeView2 =
              (e.component.selectItem && e.component) ||
              (treeView.current && treeView.current.instance);

            if (treeView2) {
              if (e.value === null) {
                treeView2.unselectAll();
              } else {
                const values = e.value;
                values &&
                  values.forEach((value) => {
                    treeView2.selectItem(value);
                  });
              }
            }

            if (
              e.value !== undefined &&
              JSON.stringify(e.value) !== JSON.stringify(value)
            ) {
              onChange(e.value);
            }
          };

          const treeViewItemSelectionChanged = (e) => {
            onChange(e.component.getSelectedNodeKeys());
          };

          const treeViewRender = () => {
            return (
              <TreeView
                ref={treeView}
                dataSource={dataSource}
                dataStructure="plain"
                keyExpr={keyExpr}
                selectionMode="multiple"
                showCheckBoxesMode="normal"
                selectNodesRecursive={false}
                displayExpr={displayExpr}
                selectByClick={true}
                onContentReady={syncTreeViewSelection}
                onItemSelectionChanged={treeViewItemSelectionChanged}
              />
            );
          };

          return (
            <DropDownBox
              value={value}
              valueExpr={valueExpr}
              displayExpr={displayExpr}
              showClearButton={true}
              dataSource={dataSource}
              onValueChanged={syncTreeViewSelection}
              contentRender={treeViewRender}
              width="200px"
            />
          );
        }}
        control={control}
        name={name}
        defaultValue={null}
      />
    </InputLayout>
  );
};

export default InputComboSelectBox;
