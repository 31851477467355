import { DataGrid } from "devextreme-react";
import {
  Column,
  ColumnChooser,
  FilterRow,
  Format,
  HeaderFilter,
  Scrolling,
  Selection,
  StateStoring,
} from "devextreme-react/data-grid";
import React, { useCallback, useState } from "react";
import CustomButton from "../../../../components/FunctionButton/CustomButton";
import {
  InputNumber,
  InputNumberMinus,
  InputSelect,
} from "../../../../components/InputForm";
import InputDate from "../../../../components/InputForm/InputDate";
import {
  DataCount,
  PanelGridInit,
  PanelGridInput,
  PanelGridSearchBar,
  PanelGridWrapper,
  SearchItems,
} from "../../../../components/Layout/Panel/PanelGrid/panelGrid.style";
import { PanelInput } from "../../../../components/Layout/Panel/PanelInput/panelInput.style";
import StateStoringSaveAndDeleteButton from "../../../../components/StateStoring";

function EtcCostMaster({ gridProps }) {
  const {
    lookupData,
    dataGridRef,
    searchForm,
    dataSource,
    onSubmit,
    onSelectionChanged,
    onFocusedRowChanged,
  } = gridProps;

  const storageKey = "EtcCostMaster";
  const [totalCount, setTotalCount] = useState(0);

  const {
    control,
    formState: { errors },
    handleSubmit,
  } = searchForm;

  return (
    <>
      <PanelGridWrapper>
        <PanelGridSearchBar onSubmit={handleSubmit(onSubmit)}>
          <SearchItems>
            <label>업체구분</label>
            <InputSelect
              className="label-select"
              control={control}
              errors={errors}
              name="companyOid"
              displayExpr="companyName"
              valueExpr="id"
              dataSource={lookupData.company}
              flex={true}
            />
          </SearchItems>
          <SearchItems>
            <label>등록일</label>
            <InputDate
              control={control}
              errors={errors}
              name="firstDay"
              flex={true}
            />
            <div className="to-mark">~</div>
            <InputDate
              control={control}
              errors={errors}
              name="lastDay"
              flex={true}
            />
          </SearchItems>

          <CustomButton
            type="submit"
            height={24}
            layout="solid"
            color="search"
            label="검색"
          />
        </PanelGridSearchBar>

        <PanelGridInit gridSub gridItems row gridHeight={89}>
          <StateStoringSaveAndDeleteButton
            dataGridRef={dataGridRef}
            storageKey={storageKey}
          />
          <DataCount>{`총 ${totalCount}건`}</DataCount>
          <DataGrid
            id="EtcCostMaster"
            className="datagrid-wrap"
            ref={dataGridRef}
            dataSource={dataSource}
            showRowLines={true}
            showBorders={true}
            hoverStateEnabled={true}
            allowColumnResizing={true}
            allowColumnReordering={true}
            columnResizingMode="widget"
            keyExpr="Oid"
            focusedRowEnabled={true}
            onFocusedRowChanged={onFocusedRowChanged}
            onSelectionChanged={onSelectionChanged}
            onContentReady={(e) => setTotalCount(e.component.totalCount())}
          >
            <FilterRow visible={true} />
            <HeaderFilter visible={true} />
            <ColumnChooser enabled={true} allowSearch={true} />
            <StateStoring
              enabled={true}
              type="custom"
              customLoad={useCallback(() => {
                return JSON.parse(localStorage.getItem(storageKey));
              }, [])}
            />
            <Scrolling mode="virtual" />
            <Selection
              mode="multiple"
              allowSelectAll="true"
              showCheckBoxesMode="always"
            />
            <Column dataField="companyName" caption="업체구분" />
            <Column
              dataField="regDate"
              caption="등록일"
              alignment="center"
              dataType="date"
              format="yyyy-MM-dd"
            />
            <Column
              dataField="companyName"
              caption="업체명"
              alignment="center"
            />
            <Column dataField="sales" caption="매출" alignment="center">
              <Format type="fixedPoint" />
            </Column>
            <Column dataField="purchase" caption="매입" alignment="center">
              <Format type="fixedPoint" />
            </Column>
            <Column dataField="remark01" caption="비고1" alignment="center" />
            <Column dataField="remark02" caption="비고2" alignment="center" />
            <Column
              dataField="AccountConection"
              caption="계좌연결여부"
              alignment="center"
            />
            <Column
              dataField="ActualPaymentDate"
              caption="실지급일"
              alignment="center"
            />
          </DataGrid>
          <PanelGridInput height={11}>
            <PanelInput grid={4}>
              <InputNumber
                control={control}
                errors={errors}
                label="매출"
                name="test1"
                disabled={true}
              />
              <InputNumber
                control={control}
                errors={errors}
                label="매입"
                name="test2"
                disabled={true}
              />
              <InputNumberMinus
                control={control}
                errors={errors}
                label="상계"
                name="test3"
                disabled={true}
              />
            </PanelInput>
          </PanelGridInput>
        </PanelGridInit>
      </PanelGridWrapper>
    </>
  );
}

export default EtcCostMaster;
