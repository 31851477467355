import dayjs from "dayjs";
import * as yup from "yup";
import { stringRequired } from "../../../lib/yupRequired";

export const initialDataSource = [
  { oid: "2", chargeCode: "수수료", title: "관리비", money: 0 },
  { oid: "1", chargeCode: "수수료", title: "고용보험료", money: 0 },
  { oid: "3", chargeCode: "수수료", title: "산재보험료", money: 0 },
  { oid: "4", chargeCode: "수수료", title: "샤시관리비", money: 0 },
  { oid: "5", chargeCode: "수수료", title: "샤시임대로", money: 0 },
  { oid: "6", chargeCode: "수수료", title: "차량주차비", money: 0 },
  { oid: "7", chargeCode: "기타공제", title: "가불공제", money: 0 },
  { oid: "8", chargeCode: "기타공제", title: "대납", money: 0 },
  { oid: "9", chargeCode: "기타공제", title: "선결제", money: 0 },
];

export const initialSearch = {
  firstDay: dayjs(new Date())
    .subtract(30, "days")
    .format("YYYY-MM-DD"),
  lastDay: dayjs().format("YYYY-MM-DD"),
};

export const getInitialInput = (user) => {
  return {
    receiveCo: user.companyCode,
    carDeadlineDate: dayjs().format("YYYY-MM"),
    comCarOid: null,
    money01: 0,
    money02: 0,
    money03: 0,
    money04: 0,
    money05: 0,
    money06: 0,
    money07: 0,
    money08: 0,
    money09: 0,
    money10: 0,
  };
};

export const initialState = {
  search: initialSearch,

  lookupData: {
    company: [],
    car: [],
    carDivision: [],
  },

  rowData: {},

  dataSource: [],
  detailDataSource: initialDataSource,
  detailEtcDataSource: [],

  isUpdate: false,
  isLoading: false,
};

export const schema = yup.object({
  carDeadlineDate: yup
    .string()
    .nullable()
    .required(stringRequired("하불마감일자")),
  comCarOid: yup
    .string()
    .nullable()
    .required(stringRequired("차량번호")),
  money01: yup.number(),
  money02: yup.number(),
  money03: yup.number(),
  money04: yup.number(),
  money05: yup.number(),
  money06: yup.number(),
  money07: yup.number(),
  money08: yup.number(),
  money09: yup.number(),
  money10: yup.number(),
});
