import styled from "styled-components";

export const ButtonWrapper = styled.button`
  width: ${(props) =>
    props.width === undefined
      ? "unset"
      : props.width === "full"
      ? "100%"
      : props.width};
  height: ${(props) => props.height + "px"};
  padding: 0 10px;
  border-radius: 2px;
  white-space: nowrap;
  font-size: ${(props) => (props.height <= 30 ? "1.2rem" : "1.4rem")};
  letter-spacing: -0.04rem;
  transition: all 0.2s ease-in-out;

  &.c-common {
    &.l-solid {
      color: ${(props) => props.theme.colors.white};
      background: ${(props) => props.theme.colors.btnCommon};
      border: none;

      &:hover {
        background: ${(props) => props.theme.colors.btnCommonHover};
      }

      &:active {
        background: ${(props) => props.theme.colors.btnCommonActive};
      }
    }

    &.l-line {
      color: ${(props) => props.theme.colors.btnCommon};
      background: ${(props) => props.theme.colors.white};
      border: 1px solid ${(props) => props.theme.colors.btnCommon};

      &:hover {
        color: ${(props) => props.theme.colors.btnCommonHover};
        background: ${(props) => props.theme.colors.whiteHover};
        border: 1px solid ${(props) => props.theme.colors.btnCommonHover};
      }

      &:active {
        color: ${(props) => props.theme.colors.btnCommonActive};
        border: 1px solid ${(props) => props.theme.colors.btnCommonActive};
      }
    }
  }

  &.c-new {
    &.l-solid {
      color: ${(props) => props.theme.colors.white};
      background: ${(props) => props.theme.colors.btnNew};
      border: none;

      &:hover {
        background: ${(props) => props.theme.colors.btnNewHover};
      }

      &:active {
        background: ${(props) => props.theme.colors.btnNewActive};
      }
    }

    &.l-line {
      color: ${(props) => props.theme.colors.btnNew};
      background: ${(props) => props.theme.colors.white};
      border: 1px solid ${(props) => props.theme.colors.btnNew};

      &:hover {
        color: ${(props) => props.theme.colors.btnNewHover};
        background: ${(props) => props.theme.colors.whiteHover};
        border: 1px solid ${(props) => props.theme.colors.btnNewHover};
      }

      &:active {
        color: ${(props) => props.theme.colors.btnNewActive};
        border: 1px solid ${(props) => props.theme.colors.btnNewActive};
      }
    }
  }

  &.c-esc {
    &.l-solid {
      color: ${(props) => props.theme.colors.white};
      background: ${(props) => props.theme.colors.btnEsc};
      border: none;

      &:hover {
        background: ${(props) => props.theme.colors.btnEscHover};
      }

      &:active {
        background: ${(props) => props.theme.colors.btnEscActive};
      }
    }

    &.l-line {
      color: ${(props) => props.theme.colors.btnEsc};
      background: ${(props) => props.theme.colors.white};
      border: 1px solid ${(props) => props.theme.colors.btnEsc};

      &:hover {
        color: ${(props) => props.theme.colors.btnEscHover};
        background: ${(props) => props.theme.colors.whiteHover};
        border: 1px solid ${(props) => props.theme.colors.btnEscHover};
      }

      &:active {
        color: ${(props) => props.theme.colors.btnEscActive};
        border: 1px solid ${(props) => props.theme.colors.btnEscActive};
      }
    }
  }

  &.c-search {
    &.l-solid {
      color: ${(props) => props.theme.colors.white};
      background: ${(props) => props.theme.colors.btnSearch};
      border: none;

      &:hover {
        background: ${(props) => props.theme.colors.btnSearchHover};
      }

      &:active {
        background: ${(props) => props.theme.colors.btnSearchActive};
      }
    }

    &.l-line {
      color: ${(props) => props.theme.colors.btnSearch};
      background: ${(props) => props.theme.colors.white};
      border: 1px solid ${(props) => props.theme.colors.btnSearch};

      &:hover {
        color: ${(props) => props.theme.colors.btnSearchHover};
        background: ${(props) => props.theme.colors.whiteHover};
        border: 1px solid ${(props) => props.theme.colors.btnSearchHover};
      }

      &:active {
        color: ${(props) => props.theme.colors.btnSearchActive};
        border: 1px solid ${(props) => props.theme.colors.btnSearchActive};
      }
    }
  }

  &.c-transp {
    &.l-icon {
      position: relative;
      padding: 7px;
      background: none;
      border: none;
      cursor: pointer;
      z-index: 11;

      &:hover {
        background: ${(props) => props.theme.colors.whiteShade};
      }
    }
  }
`;

export const ButtonGroup = styled.div`
  display: flex;
  position: ${(props) =>
    props.gridBtn ? "absolute" : props.zIndex ? "relative" : "initial"};
  margin-top: ${(props) =>
    props.marginTop ? props.marginTop + "px" : "0"} !important;
  margin-right: ${(props) =>
    props.marginRight ? props.marginRight + "px" : "0"} !important;
  margin-bottom: ${(props) =>
    props.marginBottom ? props.marginBottom + "px" : "0"} !important;
  margin-left: ${(props) =>
    props.marginLeft ? props.marginLeft + "px" : "0"} !important;
  align-items: center;
  justify-content: ${(props) =>
    props.justifyContent ? props.justifyContent : "initial"};
  gap: 5px;
  z-index: ${(props) => (props.zIndex ? props.zIndex : "initial")};
  top: ${(props) => (props.top ? props.top + "px" : "unset")};
  left: ${(props) => (props.left ? props.left + "px" : "unset")};
  right: ${(props) => (props.right ? props.right + "px" : "unset")};
  bottom: ${(props) => (props.bottom ? props.bottom + "px" : "unset")};
`;
